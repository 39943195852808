import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const original_width = 24;
  const original_height = 24;

  const width = props.height
    ? (parseInt(props.height + '') * original_width) / original_height
    : original_width;
  const height = props.height ? props.height : original_height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <g>
        <path
          d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"
          stroke={'#ff9800'}
        ></path>
        <path
          d="M13,15H11v-.743a3.954,3.954,0,0,1,1.964-3.5,2,2,0,0,0,1-2.125,2.024,2.024,0,0,0-1.6-1.595A2,2,0,0,0,10,9H8a4,4,0,1,1,5.93,3.505A1.982,1.982,0,0,0,13,14.257Z"
          stroke={'#ff9800'}
        ></path>
        <rect x="11" y="17" width="2" height="2" stroke={'#ff9800'}></rect>
      </g>
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
