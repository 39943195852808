import { Button, Col, Row } from 'antd';
import React from 'react';
import { PublicSessionStudentInfo } from '@components/public/session/PublicSessionStudentInfo';
import styled from 'styled-components';
import { PublicSessionMetadata } from '@components/public/session/PublicSessionMetadata';
import { useTranslation } from 'react-i18next';
import NavLink from '@components/button/NavLink';
import { PublicSessionLessonInfo } from '@components/public/session/PublicSessionLessonInfo';
import { Default_Gutter } from '@components/grid';
import ButtonGroup from '@components/button/ButtonGroup';
import { SiteMap } from '@router/SiteMap';
import DOMPurify from 'dompurify';
import { IconUtils } from '@utils/IconUtils';
import { SessionRes } from '@services/model/session';

export default function PublicSessionInfo(props: {
  item: SessionRes;
  type?: 'view' | 'join';
}) {
  const { t } = useTranslation();

  return (
    <PublicSessionInfoStyle>
      <div className={'public-session-header'}>
        {props.item.note && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.item.note),
            }}
          />
        )}
      </div>

      <div className={'public-session-content'}>
        <PublicSessionMetadata title={t('session.header')} item={props.item} />

        <PublicSessionLessonInfo
          title={t('session.lessons')}
          items={props.item.lessons}
        />

        <PublicSessionStudentInfo
          title={t('session.candidate')}
          items={props.item.students}
        />

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            {props.type != null && props.type === 'view' ? (
              <div className={'public-session-info-footer'}></div>
            ) : (
              <ButtonGroup type={'right'}>
                <NavLink
                  href={SiteMap.class_room.class_room_gen(
                    props.item.code,
                    props.item.publicCode,
                    'calendar'
                  )}
                >
                  <Button
                    type="primary"
                    shape={'round'}
                    size={'large'}
                    icon={IconUtils.class_room.join_classroom}
                  >
                    {t('class_room.actions.join')}
                  </Button>
                </NavLink>
              </ButtonGroup>
            )}
          </Col>
        </Row>
      </div>
    </PublicSessionInfoStyle>
  );
}

export const PublicSessionInfoStyle = styled.div`
  .public-session-info {
    margin-bottom: 1em;
  }

  .public-session-content {
    > * {
      margin-bottom: 1.5em;
    }
  }
`;
