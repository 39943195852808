import { useEffect, useState } from 'react';
import { ResponsiveBump } from '@nivo/bump';

export interface CoordinatePointProps {
  x: string | number;
  y: string | number;
}

export interface LineGraphChardRecordProps {
  id: string;
  // color: string;
  data: CoordinatePointProps[];
}

export interface LineGraphChartProps<T> {
  className: string;
  size: number;
  xAxis?: string;
  yAxis?: string;
  content: T[];
  render: (data: T, index: number) => LineGraphChardRecordProps;
}

const PumpChart = (props: LineGraphChartProps<any>) => {
  const [data, setData] = useState<LineGraphChardRecordProps[]>([]);

  useEffect(() => {
    setData(
      props.content.map((it: any, index: number) => {
        return props.render(it, index);
      })
    );
  }, [JSON.stringify(props.content)]);

  return (
    <div
      className={props.className}
      style={{ width: '100%', height: props.size }}
    >
      <ResponsiveBump
        data={data as any[]}
        colors={{ scheme: 'spectral' }}
        lineWidth={2}
        activeLineWidth={4}
        inactiveLineWidth={3}
        inactiveOpacity={0.15}
        pointSize={8}
        activePointSize={10}
        inactivePointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        activePointBorderWidth={2}
        pointBorderColor={{ from: 'serie.color' }}
        // axisTop={{
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: '',
        //   legendPosition: 'middle',
        //   legendOffset: -36,
        // }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.yAxis ? props.yAxis : 'Date',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.xAxis ? props.xAxis : 'Scores',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
        axisRight={null}
      />
    </div>
  );
};

export default PumpChart;
