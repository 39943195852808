import env from '@utils/config';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '@services/baseQuery';
import { LanguageEnum } from '@components/language/LanguageIcon';
import {
  CoursePublicRes,
  ProductTableOfContentRes,
  PublicLessonRes,
} from '@services/publish/PublishSchool';
import { PaginationRes } from '@services/model/PaginationRes';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import {
  SavePageAnswerReq,
  SaveSurveyAnswerReq,
  SurveyResponseRes,
} from '@services/model/survey';
import { BasicMessageRes } from '@services/model/Response';
import { LessonRes } from '@modules/product/services/lesson_model';
import { SurveyRes } from '@modules/assignments/service/survey_model';

export interface FilterPageReq {
  path: string;
  lang: LanguageEnum;
}

export interface ViewProductLessonReq {
  productId: number | string;
  lessonId: number | string;
  productTocId: number | string;
}

export const PublicPageAPI = createApi({
  reducerPath: 'PublicPageAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    viewPageContent: builder.query<LessonRes, FilterPageReq>({
      query: (params) => ({
        url: `/public/school/${env.CLIENT_ID}/pages/${params.path}`,
        params,
      }),
    }),

    viewProduct: builder.query<ProductTableOfContentRes, number | string>({
      query: (productId) => ({
        url: `/public/school/${env.CLIENT_ID}/products/${productId}`,
      }),
    }),

    viewProductLesson: builder.query<PublicLessonRes, ViewProductLessonReq>({
      query: (params) => ({
        url: `/public/school/${env.CLIENT_ID}/products/${params.productId}/lessons/${params.lessonId}`,
        method: 'GET',
        params,
      }),
    }),

    viewLessons: builder.query<PaginationRes<LessonRes>, unknown>({
      query: () => ({
        url: `/public/school/${env.CLIENT_ID}/lessons`,
      }),
    }),

    viewLessonContent: builder.query<LessonRes, number>({
      query: (lessonId) => ({
        url: `/public/school/${env.CLIENT_ID}/lessons/${lessonId}/content`,
      }),
    }),

    viewTeachers: builder.query<PaginationRes<UserProfileRes>, unknown>({
      query: () => ({
        url: `/public/school/${env.CLIENT_ID}/teachers`,
      }),
    }),

    viewTeacher: builder.query<UserProfileRes, string>({
      query: (shortName) => ({
        url: `/public/school/${env.CLIENT_ID}/teachers/${shortName}`,
      }),
    }),

    viewCourse: builder.query<CoursePublicRes, number>({
      query: (courseId) => ({
        url: `/public/school/${env.CLIENT_ID}/courses/${courseId}`,
      }),
    }),

    viewSurvey: builder.query<SurveyRes, number | string>({
      query: (surveyId) => ({
        url: `/public/school/${env.CLIENT_ID}/surveys/${surveyId}`,
      }),
    }),

    viewSurveyContent: builder.query<LessonRes, number | string>({
      query: (surveyId) => ({
        url: `/public/school/${env.CLIENT_ID}/surveys/${surveyId}/content`,
      }),
    }),

    savePageResponse: builder.mutation<BasicMessageRes, SavePageAnswerReq>({
      query: (body) => ({
        url: `/public/school/${env.CLIENT_ID}/pages/${body.lessonId}/exercise`,
        method: 'POST',
        body,
      }),
    }),

    saveSurveyResponse: builder.mutation<
      SurveyResponseRes,
      SaveSurveyAnswerReq
    >({
      query: (body) => ({
        url: `/public/school/${env.CLIENT_ID}/surveys/${body.surveyId}/exercise`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
