import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { EntityStatusEnum } from '@services/model/common';

export interface LessonTemplateRes {
  lessonTemplateId: number;
  name: string;
  description: string;
  content: string;

  singleMode: boolean;
  singleQuestionMode: boolean;
  stepByStep: boolean;
  layout: LessonTemplateLayoutEnum;

  status: EntityStatusEnum;

  createdDate: string;
  modifiedDate: string;
  owner: boolean;
}

export interface CreateLessonTemplateReq {
  lessonTemplateId?: number;
  name: string;
  description: string;
  content?: string;
}

export interface UpdateLessonTemplateContentReq {
  lessonTemplateId?: number;
  content: string;
  singleMode: boolean;
  singleQuestionMode: boolean;
  stepByStep: boolean;
  layout: LessonTemplateLayoutEnum;
}

export enum LessonTemplateLayoutEnum {
  responsive = 'responsive',

  letter = 'letter',
  letter_horizontal = 'letter-horizontal',
  two_page = 'two-pages',
  presentation = 'presentation',
}

export const LessonTemplateAPI = createApi({
  reducerPath: 'LessonTemplateAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<LessonTemplateRes>, PaginationReq>({
      query: (params) => ({
        url: `/lesson-template/`,
        params,
      }),
    }),

    findById: builder.query<LessonTemplateRes, number>({
      query: (id) => ({
        url: `/lesson-template/${id}`,
        method: 'GET',
      }),
    }),

    createItem: builder.mutation<LessonTemplateRes, CreateLessonTemplateReq>({
      query: (body: CreateLessonTemplateReq) => ({
        url: '/lesson-template',
        method: 'POST',
        body,
      }),
    }),

    updateItem: builder.mutation<LessonTemplateRes, CreateLessonTemplateReq>({
      query: (body: CreateLessonTemplateReq) => ({
        url: `/lesson-template/${body.lessonTemplateId}`,
        method: 'PUT',
        body,
      }),
    }),

    updateContent: builder.mutation<
      LessonTemplateRes,
      UpdateLessonTemplateContentReq
    >({
      query: (body: UpdateLessonTemplateContentReq) => ({
        url: `/lesson-template/${body.lessonTemplateId}/content`,
        method: 'PUT',
        body,
      }),
    }),

    cloneItem: builder.mutation<LessonTemplateRes, unknown>({
      query: (id) => ({
        url: `/lesson-template/${id}/clone`,
        method: 'PUT',
      }),
    }),

    publishItem: builder.mutation<BasicMessageRes, unknown>({
      query: (id) => ({
        url: `/lesson-template/${id}/publish`,
        method: 'PUT',
      }),
    }),

    deleteItem: builder.mutation<BasicMessageRes, unknown>({
      query: (id) => ({
        url: `/lesson-template/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
