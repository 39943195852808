import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { DropDownCompContextProvider } from '@cms/comps/interact/dropdown/DropdownCompContext';
import { DropDownEditable } from '@cms/comps/interact/dropdown/DropdownEditable';
import DropdownGroupItems from '@cms/comps/interact/dropdown/DropdownGroupItems';

export interface DropDownSettingProps extends CompInteractSettingProps {
  onlyOne: boolean;
}

export interface DropDownOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface DropDownProps extends CompProps {
  type: CompTypeEnum.DROPDOWN;

  configuration: {
    sourceItems: DropDownOptionProps[];
    targetItems: DropDownOptionProps[];
  };
  setting: DropDownSettingProps;
}

export interface DropDownAnsProps extends CompAnswerProps {
  answer: string[];
}

export const DropdownComp = (props: {
  disabled: boolean;
  item: DropDownProps;
  answer: DropDownAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  return (
    <DropDownCompContextProvider
      item={props.item}
      disabled={props.disabled}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <DropDownEditable>
        <DropdownCompStyle
          className={`comp comp-dropdown ${props.item.setting?.className}`}
        >
          <DropdownGroupItems />

          {props.feedback && (
            <ComponentGrading
              className={ComponentGradingClassName.absolute_position}
              feedback={props.feedback}
            />
          )}
        </DropdownCompStyle>
      </DropDownEditable>
    </DropDownCompContextProvider>
  );
};

const DropdownCompStyle = styled.div`
  position: relative;

  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }
`;
