import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const LessonAccessibleDirection = () => {
  const { t } = useTranslation();
  return (
    <LessonAccessibleDirectionStyle className={'lesson-accessible'}>
      <p>
        <b>{t('product.label.accessible.shareable')}</b>:{' '}
        {t('product.direction.printable')}
      </p>

      <p>
        <b>{t('product.label.accessible.assignable')}</b>:{' '}
        {t('product.direction.printable')}
      </p>

      <p>
        <b>{t('product.label.accessible.printable')}</b>:{' '}
        {t('product.direction.printable')}
      </p>
    </LessonAccessibleDirectionStyle>
  );
};

const LessonAccessibleDirectionStyle = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;
