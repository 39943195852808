import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  PageAndFilterWithSchoolId,
  PaginationRes,
} from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';

export interface EmailTemplateRes {
  emailTemplateId: number;

  code: string;
  subject: string;
  description: string;
  content: string;
}

export interface CreateEmailTemplateCommand {
  code: string;
  subject: string;
  description: string;
  content: string;
}

export interface UpdateEmailTemplateCommand extends CreateEmailTemplateCommand {
  emailTemplateId: number;
}

export const EmailTemplateAPI = createApi({
  reducerPath: 'EmailTemplateAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<
      PaginationRes<EmailTemplateRes>,
      PageAndFilterWithSchoolId
    >({
      query: (params) => ({
        url: `/email-template`,
        params,
      }),
    }),

    findById: builder.query<EmailTemplateRes, number>({
      query: (emailTemplateId) => ({
        url: `/email-template/${emailTemplateId}`,
      }),
    }),

    create: builder.mutation<BasicMessageRes, CreateEmailTemplateCommand>({
      query: (body: CreateEmailTemplateCommand) => ({
        url: '/email-template',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<BasicMessageRes, UpdateEmailTemplateCommand>({
      query: (body: UpdateEmailTemplateCommand) => ({
        url: `/email-template/${body.emailTemplateId}`,
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (emailTemplateId) => ({
        url: `/email-template/${emailTemplateId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
