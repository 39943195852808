import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AreaMultiplicationCompProps } from '@cms/comps/math/operation/area-multiplication/AreaMultiplicationComp';

export const AreaMultiplicationExampleData: AreaMultiplicationCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.AREA_MULTIPLICATION,

  configuration: {
    multiplier: '35',
    multiplicand: '12',
    expression: [
      {
        type: 'header',
        exp: ['_', '20', '7'],
      },
      {
        type: 'expression',
        exp: ['10', 'x', 'x'],
      },
      {
        type: 'expression',
        exp: ['3', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    removeZero: true,
  },
};
