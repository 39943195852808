import React, { useEffect } from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';
import { PublishLesson } from '@modules/public/components/lessons/PublishLessonInfo';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import styled from 'styled-components';

const Lessons = () => {
  const [viewLessons, { data, isSuccess }] =
    PublicPageAPI.endpoints.viewLessons.useLazyQuery();

  useEffect(() => {
    viewLessons({});
  }, []);

  return (
    <PublicLayout className={'view-public-lessons-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_LESSONS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'Lessons'}
        description={'Lessons'}
      />

      <PublicPageContainer>
        <CollectionTableStyle>
          {data &&
            isSuccess &&
            data.content.map((ls) => {
              return <PublishLesson key={ls.lessonId} item={ls} />;
            })}
        </CollectionTableStyle>
      </PublicPageContainer>
    </PublicLayout>
  );
};

export default Lessons;

const CollectionTableStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`;
