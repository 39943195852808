import React from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { ClassRes } from '@modules/users/services/clazz';
import { Default_Gutter } from '@components/grid';
import { Col, Row } from 'antd';
import Card, { CardClassEnum } from '@components/card';
import { MediumColorCircle } from '@components/input/ColorCircle';

export const ClassOverview = (props: { item: ClassRes }) => {
  return (
    <div className={'class-overview'}>
      <HeaderGroup className={'class-report-header-group mt-0'}>
        <H1>
          <MediumColorCircle color={props.item.color}>
            {props.item.code}
          </MediumColorCircle>{' '}
          &nbsp;&nbsp;{props.item.name}
        </H1>
      </HeaderGroup>

      <p>{props.item.description}</p>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Card className={CardClassEnum.round_box}>
            Load incoming class session
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Card className={CardClassEnum.round_box}>
            Load activate assignment
          </Card>
        </Col>
      </Row>
    </div>
  );
};
