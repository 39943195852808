import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateUserReq,
  DisabledUserReq,
} from '@modules/users/services/StudentAPI';
import { LinkToStudentReq } from '@modules/authorization/services/model';
import { UserRes } from '@modules/users/services/model';

export const UserParentAPI = createApi({
  reducerPath: 'UserParentAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<UserRes>, PaginationReq>({
      query: (params: PaginationReq) => ({
        url: `/users/parents`,
        method: 'GET',
        params,
      }),
    }),

    getUserData: builder.query<UserRes, number>({
      query: (userId: number) => ({
        url: `/users/parents/${userId}`,
        method: 'GET',
      }),
    }),

    createUser: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: '/users/parents',
        method: 'POST',
        body,
      }),
    }),

    updateUser: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: `/users/parents/${body.userId}`,
        method: 'PUT',
        body,
      }),
    }),

    disableUser: builder.mutation<BasicMessageRes, DisabledUserReq>({
      query: (body) => ({
        url: `/users/parents/${body.userId}/activated`,
        method: 'PUT',
        body,
      }),
    }),

    // for parent only
    getChildren: builder.query<UserRes[], unknown>({
      query: () => ({
        url: `/users/parents/children`,
        method: 'GET',
      }),
    }),

    linkToStudent: builder.mutation<UserRes, LinkToStudentReq>({
      query: (body) => ({
        url: '/users/parents/children/link',
        method: 'POST',
        body,
      }),
    }),

    // for teacher or school
    getParentStudents: builder.query<UserRes[], number>({
      query: (parentId: number) => ({
        url: `/users/parents/${parentId}/children`,
        method: 'GET',
      }),
    }),

    linkToStudentForTeacher: builder.mutation<UserRes, LinkToStudentReq>({
      query: (body) => ({
        url: `/users/parents/${body.parentId}/children/link`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
