import React from 'react';
import { ClassRes } from '@modules/users/services/clazz';

import { ManageClassCertificate } from '@modules/users/component/classes/certificate/ClassTeacherCertificate';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ClassStudentCertificate } from '@modules/users/component/classes/certificate/ClassStudentCertificate';

export const ClassCertificate = (props: { item: ClassRes }) => {
  if (
    props.item.certificate != null &&
    props.item.certificate.certificateId > 0
  ) {
    return (
      <>
        <Authorization
          type={'ifAnyGranted'}
          roles={[RoleEnum.STUDENT, RoleEnum.PARENT]}
        >
          <ClassStudentCertificate
            item={props.item}
            certificate={props.item.certificate}
          />
        </Authorization>

        <Authorization
          type={'ifNotGranted'}
          roles={[RoleEnum.STUDENT, RoleEnum.PARENT]}
        >
          <ManageClassCertificate
            item={props.item}
            certificate={props.item.certificate}
          />
        </Authorization>
      </>
    );
  } else {
    return <div>Class chưa được liên két chứng chỉ.</div>;
  }
};
