import { AssignmentName } from '@components/common/assignment/teacher/AssignmentTableTeacher';
import { InputGroup } from '@components/input/InputGroup';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import AssignmentActionTeacher from '@components/common/assignment/teacher/AssignmentTeacherActions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import {
  ExerciseAction,
  StudentExerciseStatus,
} from '@components/exercise/ExerciseStatus';
import { H4 } from '@components/typography';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  AssignmentInstanceRes,
  AssignmentInstanceStatusRes,
} from '@modules/assignments/service/assignment_model';

export const AssignmentMobileItem = (props: {
  item: AssignmentInstanceStatusRes;
}) => {
  const { t } = useTranslation();

  return (
    <AssignmentInstanceStyle className={'assignment-container'}>
      <div className={'assignment-info'}>
        <AssignmentName item={props.item} />

        <InputGroup label={t('assignment.unlockAt')}>
          <DateAndTimeLabel
            type={'inline'}
            label={props.item.assignmentInstance.unlockAt}
          />
        </InputGroup>

        <InputGroup label={t('assignment.lockAt')}>
          <DateAndTimeLabel
            type={'inline'}
            label={props.item.assignmentInstance.lockAt}
          />
        </InputGroup>
      </div>

      <div className={'assignment-actions'}>
        <AssignmentActionTeacher item={props.item} />
      </div>
    </AssignmentInstanceStyle>
  );
};

export const AssignmentStudentMobileItem = (props: {
  item: AssignmentInstanceRes;
}) => {
  const { t } = useTranslation();

  return (
    <AssignmentInstanceStudentStyle className={'assignment-student-container'}>
      <div className={'assignment-instance-info'}>
        <div className={'assignment-instance-thumbnail'}>
          <ThumbnailsImg
            type={'thumbnails'}
            src={props.item.lesson.thumbnails}
            ratio={ImageScale.lesson.ratio}
            minWidth={100}
          />

          <ButtonGroup type={'center'}>
            <StudentExerciseStatus
              unlockInMs={props.item.unlockInMs}
              expiredInMs={props.item.expiredInMs}
              status={props.item.exercise.status}
            />
          </ButtonGroup>
        </div>

        <div className={'assignment-instance-status-display'}>
          <H4>{props.item.name}</H4>

          <InputGroup label={t('assignment.unlockAt')}>
            <DateAndTimeLabel type={'inline'} label={props.item.unlockAt} />
          </InputGroup>

          <InputGroup label={t('assignment.lockAt')}>
            <DateAndTimeLabel type={'inline'} label={props.item.lockAt} />
          </InputGroup>
        </div>
      </div>

      <div className={'assignment-instance-actions'}>
        <ExerciseAction item={props.item} />
      </div>
    </AssignmentInstanceStudentStyle>
  );
};

const AssignmentInstanceStudentStyle = styled.div`
  position: relative;

  .assignment-instance-info {
    display: flex;
    gap: 15px;

    .assignment-instance-thumbnail {
      min-width: 100px;
      max-width: 100px;
    }

    .assignment-instance-status-display {
      flex-grow: 1;
      max-width: calc(100% - 115px);
    }
  }

  .assignment-instance-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const AssignmentInstanceStyle = styled.div`
  position: relative;

  .assignment-thumbnail {
    width: 100px;
    max-width: 100px;
  }

  .assignment-status-display {
    flex-grow: 1;
  }

  .assignment-description {
    margin-bottom: 1em;

    .ant-typography {
      font-size: 135%;
    }
  }

  .assignment-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
