import React from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { RegisterAPI } from '@modules/authorization/services/RegisterAPI';
import env from '@utils/config';
import { RoleEnum } from '@app/redux/slices/roles';
import LinkToStudentForm from '@modules/authorization/components/LinkToStudent';
import { H4 } from '@components/typography';
import Alert from '@components/alert';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { LinkToStudentProps } from '../services/model';

export const userNameRegex = /^[a-zA-Z0-9_.-]*$/; // /^[a-z0-9_.]+$/;
export const phoneNumberRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const RegisterUserForm = (props: {
  disabled: boolean;
  role: RoleEnum.PARENT | RoleEnum.TEACHER;
  successHandle: () => void;
}) => {
  const { t } = useTranslation();

  const [parentRegister, { isLoading: parentLoading }] =
    RegisterAPI.endpoints.parentRegister.useMutation({});
  const [teacherRegister, { isLoading: teacherLoading }] =
    RegisterAPI.endpoints.teacherRegister.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,

    initialValues: {
      req_username: '',
      reg_email: '',
      reg_phone: '',
      reg_password: '',
      reg_repeatPassword: '',
      students: [],
    },

    validationSchema: yup.object().shape({
      req_username: yup
        .string()
        .required(t('validation.required.field'))
        .matches(userNameRegex, 'Please enter valid username'),

      reg_email: yup
        .string()
        .email(t('registration.validation.email_invalid'))
        .required(t('validation.required.field')),

      reg_phone: yup
        .string()
        .matches(phoneNumberRegex, 'Please enter valid phone number'),

      reg_password: yup.string().required(t('validation.required.field')),

      reg_repeatPassword: yup
        .string()
        .required(t('validation.required.field'))
        .oneOf([yup.ref('reg_password')], t('errors.password_must_match')),
    }),

    onSubmit: (values) => {
      if (props.role === RoleEnum.TEACHER) {
        const requestTch = {
          username: values.req_username,
          email: values.reg_email,
          phone: values.reg_phone,
          password: values.reg_password,
          clientId: env.CLIENT_ID,
        };

        teacherRegister(requestTch)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('registration.teacher.warning.success'),
              description: t('registration.teacher.warning.success_message'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })
          .catch((error) => {
            if (error.data.error === 'user.name.exited') {
              notification.error({
                message: t('registration.teacher.warning.error'),
                description: t('registration.warning.user_name_exited'),
                placement: 'bottomRight',
              });
            } else {
              notification.error({
                message: t('registration.teacher.warning.error'),
                description: t('registration.teacher.warning.error_message'),
                placement: 'bottomRight',
              });
            }
          });
      } else {
        const requestParent = {
          username: values.req_username,
          email: values.reg_email,
          phone: values.reg_phone,
          password: values.reg_password,
          clientId: env.CLIENT_ID,
          students: values.students,
        };

        parentRegister(requestParent)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('registration.teacher.warning.success'),
              description: t('registration.teacher.warning.success_message'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })
          .catch((error) => {
            if (error.data.error === 'user.name.exited') {
              notification.error({
                message: t('registration.teacher.warning.error'),
                description: t('registration.warning.user_name_exited'),
                placement: 'bottomRight',
              });
            } else {
              notification.error({
                message: t('registration.teacher.warning.error'),
                description: t('registration.teacher.warning.error_message'),
                placement: 'bottomRight',
              });
            }
          });
      }
    },
  });

  const handleOnStudentChangeChange = (students: LinkToStudentProps[]) => {
    formik.setFieldValue('students', students);
  };

  return (
    <FormikProvider value={formik}>
      {props.disabled && (
        <Alert
          type={'error'}
          message={<H4>{t('registration.warning.disabled_register')}</H4>}
          closable={true}
          description={t('registration.warning.disabled_register_message')}
        />
      )}

      <Input
        required
        name="req_username"
        type="text"
        label={t('label.username')}
        placeholder={t('form.enter_user_name')}
        onChange={formik.handleChange}
        value={formik.values.req_username}
      />

      <Input
        required
        name="reg_email"
        type="email"
        label={t('label.email')}
        placeholder={t('form.enter_email')}
        onChange={formik.handleChange}
        value={formik.values.reg_email}
      />

      <Input
        name="reg_phone"
        type="phone"
        label={t('label.phone')}
        placeholder={t('label.phone')}
        onChange={formik.handleChange}
        value={formik.values.reg_phone}
      />

      <Input
        required
        name="reg_password"
        type="password"
        label={t('label.password')}
        placeholder={t('form.enter_password')}
        onChange={formik.handleChange}
        value={formik.values.reg_password}
      />

      <Input
        required
        name="reg_repeatPassword"
        type="password"
        label={t('label.password_again')}
        placeholder={t('form.enter_password_again')}
        onChange={formik.handleChange}
        value={formik.values.reg_repeatPassword}
      />

      {props.role === RoleEnum.PARENT && (
        <LinkToStudentForm onChange={handleOnStudentChangeChange} />
      )}

      <div className="submit-container">
        <ButtonGroup>
          <Button
            loading={parentLoading || teacherLoading}
            type={'primary'}
            shape={'round'}
            onClick={formik.submitForm}
            disabled={props.disabled}
          >
            {t('button.registration')}
          </Button>

          <NavLink href={SiteMap.index}>
            <Button type="default" shape={'round'}>
              {t('button.cancel')}
            </Button>
          </NavLink>
        </ButtonGroup>
      </div>
    </FormikProvider>
  );
};
export default RegisterUserForm;
