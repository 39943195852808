import { Button, Divider, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H3 } from '@components/typography';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { useProductTableOfContentContext } from '@modules/product/context/ProductTableOfContent';
import {
  TableOfContentProgress,
  TableOfContentProgressSmall,
} from './TableOfContentProgress';
import { CompMode } from '@cms/ComponentInteface';
import { getTocNavigation } from '@modules/product/components/view-toc/ViewTableOfContentContext';
import ButtonGroup from '@components/button/ButtonGroup';
import NavLink from '@components/button/NavLink';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';

export const TableOfContentProgressToolbar = (props: {
  type: CompMode;
  lessonId: number;
  productTocId: number;
}) => {
  const [showTableOfContent, setShowTableOfContent] = useState({
    isShow: false,
    timestamp: -1,
  });
  const { t } = useTranslation();

  const { product, tableOfContent, selectLesson } =
    useProductTableOfContentContext();

  const [navigation, setNavigation] = useState(() => {
    return getTocNavigation(props.lessonId, props.productTocId, tableOfContent);
  });

  useEffect(() => {
    setNavigation(
      getTocNavigation(props.lessonId, props.productTocId, tableOfContent)
    );
  }, [props.lessonId, props.productTocId, tableOfContent]);

  // const viewPreviousLesson = () => {
  //   if (navigation.previous != null) {
  //     selectLesson(
  //       navigation.previous.productToc.productTocId,
  //       navigation.previous.lesson.lessonId
  //     );
  //     setShowTableOfContent({
  //       isShow: false,
  //       timestamp: -1,
  //     });
  //   }
  // };

  const viewNextLesson = () => {
    if (navigation.next != null) {
      selectLesson(
        navigation.next.productToc.productTocId,
        navigation.next.lesson.lessonId
      );
      setShowTableOfContent({
        isShow: false,
        timestamp: -1,
      });
    }
  };

  return (
    <ViewTableOfContentToolbarStyle>
      {product && product.productId != null && product.productId > 0 && (
        <Button
          size="large"
          type={'default'}
          title={t('product.table_of_content')}
          onClick={() =>
            setShowTableOfContent({
              isShow: true,
              timestamp: DateAndTimeUtils.getCurrentTime(),
            })
          }
          icon={IconUtils.table_of_content}
        />
      )}

      <div className={'view-table-of-content-toolbar'}>
        {props.type !== CompMode.DO_EXERCISE &&
        props.type !== CompMode.GRADING_EXERCISE ? (
          <span />
        ) : (
          <span />
        )}

        {navigation && navigation.current && (
          <H3>
            {navigation.currentToc?.name}: {navigation.current?.lesson.name}
          </H3>
        )}

        {props.type === CompMode.REVIEW_EXERCISE ? (
          <Button
            size="large"
            type={'default'}
            shape={'round'}
            disabled={navigation.next == null}
            onClick={viewNextLesson}
          >
            {navigation.next?.lesson.name} {IconUtils.actions.next}
          </Button>
        ) : (
          <span />
        )}
      </div>

      <Drawer
        title={t('product.table_of_content')}
        placement={'left'}
        width={900}
        onClose={() => setShowTableOfContent({ isShow: false, timestamp: -1 })}
        open={showTableOfContent.isShow}
      >
        <TableOfContentProgressSmall>
          <TableOfContentProgress
            lessonId={props.lessonId}
            productTocId={props.productTocId}
          />

          <Divider />

          <ButtonGroup type={'center'}>
            <NavLink
              href={SiteMap.private.my_library.view_product_gen(
                product.productId
              )}
            >
              <Button
                type={'default'}
                shape={'round'}
                danger
                icon={IconUtils.table_of_content}
              >
                Back to Product
              </Button>
            </NavLink>
          </ButtonGroup>
        </TableOfContentProgressSmall>
      </Drawer>
    </ViewTableOfContentToolbarStyle>
  );
};

const ViewTableOfContentToolbarStyle = styled.div`
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: #fff;
  padding: 0.5em 1em;
  border-top: 1px solid #ccc;
  z-index: 2;

  .left-group {
    border-right: 1px solid #ccc;
  }

  .right-group {
    border-left: 1px solid #ccc;
  }

  .view-table-of-content-toolbar {
    flex-grow: 1;
    padding: 0 1em;
    margin: 0 1em;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-btn {
      min-width: 135px;
    }

    .ant-typography {
      margin-bottom: 0px;
    }
  }

  .ant-popover-inner-content {
    background: rgba(0, 0, 0, 0.025);
    padding-right: 5px;
  }
`;
