import { LessonAPI } from '@modules/product/services/LessonAPI';
import React, { useEffect } from 'react';
import { Button, Popover } from 'antd';
import { H4, H5 } from '@components/typography';
import {
  CheckSquareOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { Tooltip } from '@components/tooltip/Tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ContentValidationResponse } from '@modules/product/services/resource_model';

export const LessonValidation = (props: { lessonId: number }) => {
  const { t } = useTranslation();

  const [getLessonValidation, { data }] =
    LessonAPI.endpoints.getLessonValidation.useLazyQuery({});

  useEffect(() => {
    if (props.lessonId != null && props.lessonId > 0) {
      getLessonValidation(props.lessonId);
    }
  }, [props.lessonId]);

  return (
    <>
      {data && (
        <Popover
          placement="bottomLeft"
          title={<H4>{t('lesson_compose.actions.lesson_validation')}</H4>}
          content={<ResourceValidationContent item={data} />}
          trigger="click"
        >
          <Button
            shape={'circle'}
            type={'text'}
            icon={data.hasError ? <WarningOutlined /> : <InfoCircleOutlined />}
          />
        </Popover>
      )}
    </>
  );
};

export const ResourceValidation = (props: { resourceId: number }) => {
  const { t } = useTranslation();

  const [getResourceValidation, { data }] =
    ResourceAPI.endpoints.getResourceValidation.useLazyQuery({});

  useEffect(() => {
    if (props.resourceId != null && props.resourceId > 0) {
      getResourceValidation(props.resourceId);
    }
  }, [props.resourceId]);

  return (
    <>
      {data && (
        <Popover
          placement="bottomLeft"
          title={t('lesson_compose.actions.resource_validation')}
          content={<ResourceValidationContent item={data} />}
          trigger="click"
        >
          <Button
            shape={'circle'}
            type={'default'}
            icon={data.hasError ? <WarningOutlined /> : <InfoCircleOutlined />}
          />
        </Popover>
      )}
    </>
  );
};

export const ResourceValidationInfo = (props: { resourceId: number }) => {
  const [getResourceValidation, { data }] =
    ResourceAPI.endpoints.getResourceValidation.useLazyQuery({});

  useEffect(() => {
    if (props.resourceId != null && props.resourceId > 0) {
      getResourceValidation(props.resourceId);
    }
  }, [props.resourceId]);

  return <>{data && <ResourceValidationContent item={data} />}</>;
};

export const ResourceValidationContent = (props: {
  item: ContentValidationResponse;
}) => {
  const { t } = useTranslation();

  return (
    <ResourceValidationContentStyle className={'content-validation-item'}>
      <Tooltip
        placement="right"
        title={t('lesson_compose.actions.validation.interact_hint')}
      >
        <H5 className={props.item.interact ? 'success' : 'warning'}>
          {t('lesson_compose.actions.validation.interact_hint')}

          {props.item.interact ? (
            <CheckSquareOutlined />
          ) : (
            <ExclamationCircleOutlined />
          )}
        </H5>
      </Tooltip>

      <Tooltip
        placement="right"
        title={t('lesson_compose.actions.validation.auto_score_hint')}
      >
        <H5
          className={
            props.item.manualScore != null && !props.item.manualScore
              ? 'success'
              : 'warning'
          }
        >
          {t('lesson_compose.actions.validation.auto_score')}
          {props.item.manualScore != null && !props.item.manualScore ? (
            <CheckSquareOutlined />
          ) : (
            <ExclamationCircleOutlined />
          )}
        </H5>
      </Tooltip>

      <Tooltip
        placement="right"
        title={t('lesson_compose.actions.validation.completed_hint')}
      >
        <H5 className={props.item.completed ? 'success' : 'error'}>
          {t('lesson_compose.actions.validation.completed')}
          {props.item.completed ? (
            <CheckSquareOutlined />
          ) : (
            <ExclamationCircleOutlined />
          )}
        </H5>
      </Tooltip>

      <Tooltip
        placement="right"
        title={t('lesson_compose.actions.validation.support_standard_hint')}
      >
        <H5 className={props.item.supportStandard ? 'success' : 'warning'}>
          {t('lesson_compose.actions.validation.support_standard')}
          {props.item.supportStandard ? (
            <CheckSquareOutlined />
          ) : (
            <ExclamationCircleOutlined />
          )}
        </H5>
      </Tooltip>

      {/*<Tooltip*/}
      {/*  placement="right"*/}
      {/*  title={*/}
      {/*    'Question is added feedback. When student submit the lesson, they can see the feedback you provided here.'*/}
      {/*  }*/}
      {/*>*/}
      {/*  <H5 className={props.item.supportFeedback ? 'success' : 'warning'}>*/}
      {/*    Support Feedback*/}
      {/*    {props.item.supportFeedback ? (*/}
      {/*      <CheckSquareOutlined />*/}
      {/*    ) : (*/}
      {/*      <ExclamationCircleOutlined />*/}
      {/*    )}*/}
      {/*  </H5>*/}
      {/*</Tooltip>*/}
    </ResourceValidationContentStyle>
  );
};

const ResourceValidationContentStyle = styled.div`
  min-width: 400px;

  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;

    &.success {
      color: ${(props) => props.theme.warning.success};
    }

    &.warning {
      color: ${(props) => props.theme.warning.warning};
    }

    &.error {
      color: ${(props) => props.theme.warning.error};
    }
  }
`;
