import { ClassAPI } from '@modules/users/services/ClassAPI';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ClassFilterReq,
  ClassRes,
  ClassResourceRes,
} from '@modules/users/services/clazz';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import Card, { CardClassEnum } from '@components/card';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1, H4 } from '@components/typography';
import TablePagination from '@components/table/TablePagination';
import { ResponsiveScreen } from '@hooks/useMedia';
import { useTranslation } from 'react-i18next';
import { ProductLicenseAPI } from '@modules/license/services/ProductLicenseAPI';
import { ProductLicenseDisplay } from './ProductLicenseDisplay';
import { SiteMap } from '@router/SiteMap';
import { Button, Col, Row } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import NavLink from '@components/button/NavLink';
import ButtonGroup from '@components/button/ButtonGroup';
import { Default_Gutter } from '@components/grid';
import styled from 'styled-components';
import { ProgramDisplay } from '@modules/admin/component/ProgramDisplay';
import { ProductLicenseRes } from '@modules/license/services/model';

export const ClassStudentProgram = (props: { item: ClassRes }) => {
  const { t } = useTranslation();

  const [getClassResource, { data, isSuccess, isFetching }] =
    ClassAPI.endpoints.getClassResource.useLazyQuery({});

  const [checkLicenseForUser, { data: licenseData }] =
    ProductLicenseAPI.endpoints.checkLicenseForUser.useLazyQuery({});

  const [params, setParams] = useState<ClassFilterReq>({
    size: MAX_PAGE_SIZE,
    classId: props.item.classId,
  });

  const [productIds, setProductIds] = useState<number[]>([]);

  const [productLicenses, setProductLicenses] = useState<
    Record<number, ProductLicenseRes>
  >({});

  useEffect(() => {
    getClassResource(params);
  }, [params]);

  useEffect(() => {
    if (data && isSuccess) {
      const _productIds = data.content.map((rc) => {
        return rc.product.productId;
      });
      setProductIds(_productIds);
    }
  }, [data]);

  useEffect(() => {
    if (productIds.length > 0) {
      checkLicenseForUser(productIds);
    }
  }, [productIds]);

  useEffect(() => {
    if (licenseData != null && licenseData.length > 0) {
      const mapLicense: Record<number, ProductLicenseRes> = {};
      licenseData.forEach((rc) => {
        mapLicense[rc.product.productId] = rc;
      });
      setProductLicenses(mapLicense);
    }
  }, [licenseData]);

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...prev, params };
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'product.thumbnails',
      key: 'product.thumbnails',
      sorter: true,
      align: 'center',
      width: 110,
    },
    {
      title: t('label.name'),
      dataIndex: 'product.name',
      key: 'product.name',
      sorter: true,
      width: 300,
    },
    {
      title: 'License',
      dataIndex: 'license',
      key: 'license',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 220,
    },
  ];

  const processDataRow = useCallback(
    (item: ClassResourceRes) => {
      const license = productLicenses[item.product.productId];

      return {
        key: item.classResourceId,
        product: item.product,
        productId: item.product.productId,
        'product.thumbnails': (
          <ThumbnailsImg
            type={'icon'}
            src={item.product.thumbnails}
            ratio={ImageScale.product.ratio}
            width={52}
          />
        ),
        'product.name': item.product.name,
        license:
          license != null ? (
            <ProductLicenseDisplay item={license} />
          ) : (
            <p>Student is not assigned license.</p>
          ),

        actions: (
          <>
            {license && (
              <NavLink
                href={SiteMap.private.my_library.view_product_gen(
                  item.product.productId
                )}
              >
                <Button
                  shape={'round'}
                  type={'primary'}
                  icon={IconUtils.actions.start_exercise}
                >
                  {t('lesson.actions.practice')}
                </Button>
              </NavLink>
            )}
          </>
        ),
      };
    },
    [productLicenses]
  );

  const renderMobile = (item: ClassResourceRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box} mb-5`}>
        <StudentClassResourceItem
          item={item}
          license={productLicenses[item.product.productId]}
        />
      </Card>
    );
  };

  return (
    <StudentClassResourceStyle className={'student-class-resources'}>
      <HeaderGroup className={'mt-0'}>
        <H1>Class Programs</H1>

        {props.item.program && (
          <ProgramDisplay className={'bordered'} item={props.item.program} />
        )}
      </HeaderGroup>

      <p>
        Class Resources hiển thị các giáo trình được giảng dạy trong chương
        trình này.
      </p>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </StudentClassResourceStyle>
  );
};

const StudentClassResourceItem = ({
  item,
  license,
}: {
  item: ClassResourceRes;
  license: ProductLicenseRes | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'student-class-resource-item'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col flex={'72px'}>
          <ThumbnailsImg
            type={'icon'}
            src={item.product.thumbnails}
            ratio={ImageScale.product.ratio}
            width={72}
          />
        </Col>

        <Col flex={'auto'}>
          <H4>{item.product.name}</H4>

          {license != null ? (
            <ProductLicenseDisplay item={license} />
          ) : (
            <p>Student is not assigned license.</p>
          )}

          {license && (
            <ButtonGroup type={'right'}>
              <NavLink
                href={SiteMap.private.my_library.view_product_gen(
                  item.product.productId
                )}
              >
                <Button
                  shape={'round'}
                  type={'primary'}
                  icon={IconUtils.actions.start_exercise}
                >
                  {t('lesson.actions.practice')}
                </Button>
              </NavLink>
            </ButtonGroup>
          )}
        </Col>
      </Row>
    </div>
  );
};

const StudentClassResourceStyle = styled.div`
  .program-display {
    border: 1px solid #ccc;
    padding: 0.25em 0.75em;
    border-radius: 1.5em;
  }
`;
