import { CompAnswerProps, CompMode, CompProps } from '@cms/ComponentInteface';
import React from 'react';
import styled from 'styled-components';
import { PdfContentProps } from '@cms/content/ContentType';

import { AssetsRes } from '@modules/product/services/assets_model';
import {
  ContentViewer,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { useDispatch } from 'react-redux';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { updateComponent } from '@app/redux/slices/composeResource';

export interface PdfCompProps extends CompProps {
  configuration: {
    content: PdfContentProps;
  };
}

const PdfCompStyle = styled.div`
  word-break: break-all;
`;

export function PdfComp(props: {
  item: PdfCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: AssetsRes) => {
      const newData = {
        ...props.item,

        configuration: {
          content: data,
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <PdfCompStyle className={`pdf-comp ${props.item.setting?.className}`}>
        <ContentViewerEditable
          contentData={props.item.configuration.content}
          onChange={handleOnChange}
        />
      </PdfCompStyle>
    );
  } else {
    return (
      <PdfCompStyle className={`pdf-comp ${props.item.setting?.className}`}>
        <ContentViewer contentData={props.item.configuration.content} />
      </PdfCompStyle>
    );
  }
}
