import React, { useState } from 'react';

import { LessonActionEnum } from '@modules/product/container/actions';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from 'antd/lib/button';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { LessonExampleTable } from '../container/lesson-example/LessonExampleTable';
import LessonExampleEditForm from '../container/lesson-example/LessonExampleEditForm';
import { H1 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonExampleRes, LessonTypeEnum } from '@modules/admin/service/model';

export default function LessonExamplePage(props: { category: LessonTypeEnum }) {
  const { t } = useTranslation();
  const router = useRouter();
  const proUser = useProUser();

  const [action, setAction] = useState({
    action: LessonActionEnum.DEFAULT,
    timestamp: DateAndTimeUtils.getCurrentTime(),
  });

  const handleAddLessonExample = () => {
    setAction({
      action: LessonActionEnum.ADD_LESSON,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCancel = () => {
    setAction({
      action: LessonActionEnum.DEFAULT,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCreateLessonSuccess = (
    isAdded: boolean,
    lessonData: LessonExampleRes
  ) => {
    router.push(
      SiteMap.content.lesson.compose_gen(lessonData.lesson.lessonId, proUser)
    );
  };

  const getTitle = () => {
    if (props.category === LessonTypeEnum.collection) {
      return t('lesson_example.collection.title');
    } else if (props.category === LessonTypeEnum.lesson) {
      return t('lesson_example.lesson.title');
    } else if (props.category === LessonTypeEnum.page) {
      return t('lesson_example.page.title');
    } else {
      return t('lesson_example.header');
    }
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {getTitle()}
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={handleAddLessonExample}
          />
        </H1>
      </HeaderGroup>

      <LessonExampleTable category={props.category} />

      {action.action === LessonActionEnum.ADD_LESSON && (
        <CustomModal
          header={t('lesson_example.actions.add')}
          className={CustomModalClassEnum.default}
          content={
            <LessonExampleEditForm
              category={props.category}
              onSuccess={handleOnCreateLessonSuccess}
              onClose={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
