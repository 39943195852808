import { ref, child, get, set, push, remove, onValue } from 'firebase/database';
import { database } from '@utils/firebase';
import { Timestamp } from 'firebase/firestore';

export class FireBaseService {
  created_by: string;
  channel: string;
  db: any;
  actionUUIDs: string[];
  timestamp: number;

  constructor(created_by: string, collection: string, channel: string) {
    this.created_by = created_by;
    this.channel = collection + channel;
    this.db = database;
    this.actionUUIDs = [];
    this.timestamp = Timestamp.now().seconds;
  }

  getServerTime = () => {
    return Timestamp.now().seconds;
  };

  onChange = (handleOnChange: (data: any[]) => void) => {
    onValue(ref(this.db, this.channel), (snapshot) => {
      const results = snapshot.val();

      if (results && typeof results == 'object') {
        const items = Object.values(results) as any[];

        try {
          const newData = items.filter((item: { uid: string }) => {
            return !this.actionUUIDs.includes(item.uid);
          });

          const currentIds: string[] = newData.map((item: { uid: string }) => {
            return item.uid;
          });

          this.actionUUIDs = this.actionUUIDs.concat(currentIds);
          handleOnChange(newData);
        } catch (e) {
          console.log(results);
        }
      }
    });
  };

  onSelectAll = (handleOnChange: (data: any[]) => void) => {
    onValue(ref(this.db, this.channel), (snapshot) => {
      const results = snapshot.val();
      console.log('onSelectAll', results);

      if (results && typeof results == 'object') {
        const items = Object.values(results) as any[];
        handleOnChange(items);
      }
    });
  };

  onNotify = (handleOnChange: (data: any[]) => void) => {
    onValue(ref(this.db, this.channel), (snapshot) => {
      const results = snapshot.val();

      if (results && typeof results == 'object') {
        const items = Object.values(results) as any[];

        try {
          const newData = items.filter(
            (item: { uid: string; timestamp: number }) => {
              return item.timestamp < this.timestamp;
            }
          );

          handleOnChange(newData);
          this.timestamp = this.getServerTime();
        } catch (e) {
          console.log(results);
        }
      }
    });
  };

  findById = (id: string) => {
    const dbRef = ref(this.db, this.channel);
    return get(child(dbRef, `/${id}`));
  };

  create = (data: Record<any, any>, guid?: any) => {
    if (guid != null) {
      return set(ref(this.db, this.channel + '/' + guid), {
        ...data,
        uid: guid,
        created_by: this.created_by,
        timestamp: this.getServerTime(),
      });
    } else {
      const newId = push(ref(this.db, this.channel));

      return set(newId, {
        ...data,
        uid: newId.key,
        created_by: this.created_by,
        timestamp: this.getServerTime(),
      });
    }
  };

  update = (record_uuid: string, newData: Record<any, any>) => {
    return set(ref(this.db, this.channel + '/' + record_uuid), newData);
  };

  delete = (record_uuid: string) => {
    return remove(ref(this.db, this.channel + '/' + record_uuid));
  };

  deleteAll = () => {
    return remove(ref(this.db, this.channel));
  };
}
