import produce from 'immer';
import { WordSearchCompProps } from '@cms/comps/game/word-search/WordSearchComp';
import { CompScoringProps } from '@cms/ComponentInteface';

class WordSearchValidation {
  getScoring = (comp: WordSearchCompProps): CompScoringProps => {
    const totalPart = comp.configuration.sourceItems.length;

    return {
      id: comp.id,
      type: comp.type,
      manualScore: false,
      maxScore: totalPart,
    };
  };

  validation = (comp: WordSearchCompProps): WordSearchCompProps => {
    const newComps = produce(comp, (draft) => {
      const sourceItems = draft.configuration.sourceItems;

      let min_char = 0;
      sourceItems.forEach((wd) => {
        wd.word.data = wd.word.data.trim();

        if (wd.word.data.trim().length > min_char) {
          min_char = wd.word.data.trim().length;
        }
      });

      if (min_char > draft.setting.maxColumns) {
        draft.setting.maxColumns = min_char;
      }

      if (min_char > draft.setting.maxRows) {
        draft.setting.maxRows = min_char;
      }
    });

    return newComps;
  };
}

export const WordSearchValidationUtils = new WordSearchValidation();
