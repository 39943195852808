import { TeacherClassSelect } from '@components/input/teacher/TeacherClassSelect';
import Input from '@components/input/input';
import React, { useContext, useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import { StudentInfoStatic } from '@components/info/StudentInfo';
import { useTranslation } from 'react-i18next';
import { SelectStudentContext } from '@modules/calendar/context/SelectStudentContext';
import { Default_Gutter } from '@components/grid';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { UserRes } from '@modules/users/services/model';

export const SelectStudentForm = (props: {
  selectIds: number[];
  onSelect: (data: UserRes) => void;
  onRemove: (userId: number) => void;
}) => {
  const { t } = useTranslation();

  const [getStudentInClass, { data, isSuccess }] =
    ClassAPI.endpoints.getStudentInClass.useLazyQuery({});

  const { selectedStudents } = useContext(SelectStudentContext);
  const [selectClassId, setSelectClassId] = useState(-1);
  const [keyword, setKeyword] = useState('');
  const [filterStudents, setFilterStudents] = useState<UserRes[]>([]);

  useEffect(() => {
    getStudentInClass({
      classId: selectClassId,
    });
  }, [selectClassId]);

  useEffect(() => {
    if (isSuccess && data) {
      setFilterStudents(data);
    }
  }, [data]);

  const handleSelectClass = (classIds: number[]) => {
    setSelectClassId(classIds[0]);
  };
  return (
    <div className={'select-student'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <TeacherClassSelect
            label={t('label.class')}
            handleSelectClass={handleSelectClass}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Input
            label={t('invitation.student_invitation.student_name')}
            addedClass={'single'}
            name="keyword"
            placeholder={t('label.keyword')}
            onChange={(event) => setKeyword(event.target.value)}
            defaultValue={keyword}
          />
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <List
            bordered
            dataSource={filterStudents}
            renderItem={(item) => (
              <List.Item>
                <StudentInfoStatic
                  key={item.userId}
                  selected={
                    selectedStudents.includes(item.userId) ||
                    (props.selectIds != null &&
                      props.selectIds.includes(item.userId))
                  }
                  item={item}
                  onSelect={props.onSelect}
                  onRemove={props.onRemove}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
