import React from 'react';
import { useTranslation } from 'react-i18next';

import { OauthAppRes } from '@services/private/OauthAppAPI';
import { InputGroup } from '@components/input/InputGroup';
import styled from 'styled-components';
import Button from '@components/button';
import { NavLinkNewTab } from '@components/button/NavLink';
import Typography from 'antd/lib/typography';
import { SiteMap } from '@router/SiteMap';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';

const ViewOAuthApp = (props: { data: OauthAppRes }) => {
  const { t } = useTranslation();

  return (
    <ViewOAuthAppStyle>
      <InputGroup label={t('oauth_app.label.consumer')}>
        <Typography.Paragraph copyable className={'group-info'}>
          {props.data.clientId}
        </Typography.Paragraph>
      </InputGroup>

      <InputGroup label={t('oauth_app.label.secret')}>
        <Typography.Paragraph copyable className={'group-info'}>
          {props.data.clientSecret}
        </Typography.Paragraph>
      </InputGroup>

      <InputGroup label={t('oauth_app.label.scopes')}>
        <p className={'group-info'}>{props.data.scopes}</p>
      </InputGroup>

      <InputGroup label={t('oauth_app.label.redirect_url')}>
        <p className={'group-info'}>{props.data.redirectUrl}</p>
      </InputGroup>

      <ButtonGroup type={'right'} className={'submit-container'}>
        <NavLinkNewTab href={SiteMap.lti.login_testing}>
          <Button
            type={'default'}
            shape={'round'}
            size={'large'}
            icon={IconUtils.login}
          >
            {t('oauth_app.actions.testing_login')}
          </Button>
        </NavLinkNewTab>

        <NavLinkNewTab href={SiteMap.lti.product_test}>
          <Button
            type={'default'}
            shape={'round'}
            size={'large'}
            icon={IconUtils.menu.products}
          >
            {t('oauth_app.actions.testing_access_product')}
          </Button>
        </NavLinkNewTab>
      </ButtonGroup>
    </ViewOAuthAppStyle>
  );
};

export default ViewOAuthApp;

export const ViewOAuthAppStyle = styled.div`
  .group-info {
    min-height: 2em;
    word-break: break-word;
    width: 600px;
    max-width: calc(100vw - 48px);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: ${(props) => props.theme.event.background};
    max-height: 10em;
    overflow-y: auto;
  }
`;
