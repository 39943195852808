import React, { useEffect, useState } from 'react';

import { getDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { PageHeader } from '@app/header/PageHeader';
import { SitePath } from '@router/SitePath';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageContent } from '@cms/lesson-template/pages/PageContent';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { LessonRes } from '@modules/product/services/lesson_model';

const Home = () => {
  const [viewPageContent, { data, isSuccess }] =
    PublicPageAPI.endpoints.viewPageContent.useLazyQuery();

  const [pageContent, setPageContent] = useState<LessonRes | null>(null);

  useEffect(() => {
    viewPageContent({
      lang: getDefaultLanguage(),
      path: SitePath.index,
    });
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setPageContent(data);
    }
  }, [data]);

  return (
    <EmptyLayout className={'home-page'}>
      {pageContent && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.EMPTY}
            subMenu={NavigationMenuEnum.EMPTY}
            title={pageContent.name}
            description={pageContent.description}
          />

          <PageContent path={SitePath.index} page={pageContent} />
        </>
      )}
    </EmptyLayout>
  );
};

export default Home;
