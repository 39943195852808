import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import { ManageSurveyPage } from '@modules/assignments/pages/ManageSurveyPage';

const ManageSurvey = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-survey-page'}>
      <PageHeader
        menu={NavigationMenuEnum.COURSES}
        subMenu={NavigationMenuEnum.COURSES_SURVEY}
        title={t('survey.header')}
        description={t('survey.header')}
      />
      <ManageSurveyPage />
    </ManagementLayout>
  );
};

export default ManageSurvey;
