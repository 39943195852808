import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { ErrMessageStyle, StyledInput } from '@components/input/style';
import { get as getPath } from 'lodash';
import { connect } from 'formik';
import React from 'react';

export interface SelectProps extends AntSelectProps {
  name: string;
  inputGroupClass?: string;
  className?: string;
  label?: string;
  formik?: any;
}

const Select = ({
  name,
  inputGroupClass,
  className,
  label,
  formik,
  ...props
}: SelectProps) => {
  return (
    <StyledInput
      className={`form-group ${inputGroupClass ? inputGroupClass : ''}`}
    >
      {label && <div className={'ant-form-label'}>{label}</div>}

      <div className={'ant-form-controls'}>
        <AntSelect
          className={`gstudy-select ${className ? className : ''}`}
          {...props}
        />

        {getPath(formik?.errors, name) && getPath(formik?.touched, name) && (
          <ErrMessageStyle className="error-message">
            {getPath(formik?.errors, name)}
          </ErrMessageStyle>
        )}
      </div>
    </StyledInput>
  );
};

export default connect(Select);

export const Option = AntSelect.Option;
