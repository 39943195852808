import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import Input from '@components/input/input';
import { FibWordSettingProps } from '@cms/comps/interact/inline/FibWordComp';
import Checkbox from '@components/input/Checkbox';
import { useTranslation } from 'react-i18next';

export const FibWordSetting = (props: {
  setting: FibWordSettingProps;
  onSettingChange: (newSetting: FibWordSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <Input
          name="maxLength"
          type="number"
          label={t('component.label.max_length')}
          placeholder={t('component.label.max_length')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.maxLength}
          min={1}
          max={20}
        />

        <Checkbox
          name={'autoCapital'}
          checked={formik.values.autoCapital}
          value={formik.values.autoCapital}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.label.auto_capital')}
        </Checkbox>
      </FormikProvider>
    </SettingStyle>
  );
};
