import React from 'react';
import { CompProps } from '@cms/ComponentInteface';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

import { StaticSampleData } from '@cms/comps/content/StaticContentComp';

import { MultipleChoiceExampleData } from '@cms/comps/interact/multiple-choice/MultipleChoiceSampleData';
import { MatchingDragNDropExampleData } from '@cms/comps/interact/matching/MatchingSampleData';
import { FibNumberExampleData } from '@cms/comps/math/fib/FibNumberSampleData';
import { FibEssayExampleData } from '@cms/comps/interact/fill-in-blank/FibEssaySampleData';
import {
  DragNDropExampleData,
  DragNDropVerticalExampleData,
} from '@cms/comps/interact/drag-n-drop/DragNDropSampleData';
import { FibParagraphExampleData } from '@cms/comps/interact/fill-in-blank/FibParagraphSampleData';
import { DropDownExampleData } from '@cms/comps/interact/dropdown/DropdownSampleData';
import { FibWordExampleData } from '@cms/comps/interact/inline/FibWordSampleData';
import { ComponentItem } from '../context/ComponentItem';
import { OrderingExampleData } from '@cms/comps/interact/ordering/OrderingSampleData';
import {
  LongAdditionExampleData,
  PlaceValueAdditionExampleData,
} from '@cms/comps/math/operation/addition/LongAdditionSampleData';
import {
  LongSubtractionExampleData,
  PlaceValueSubtractionExampleData,
} from '@cms/comps/math/operation/subtraction/LongSubtractionSampleData';
import { LongMultiplicationExampleData } from '@cms/comps/math/operation/multiplication/LongMultiplicationSampleData';
import { LongDivisionExampleData } from '@cms/comps/math/operation/division/LongDivisionSampleData';
import { FractionExampleData } from '@cms/comps/math/frac/FractionSampleData';
import { CoordinateExampleData } from '@cms/comps/interact/coordinate/CoordinateSampleData';
import { DragNDropInlineExampleData } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineSampleData';
import { AreaMultiplicationExampleData } from '@cms/comps/math/operation/area-multiplication/AreaMultiplicationSampleData';
import { AreaDivisionExampleData } from '@cms/comps/math/operation/area-division/AreaDivisionSampleData';
import {
  PlaceValueExampleBlockData,
  PlaceValueExampleData,
} from '@cms/comps/math/operation/place-value/PlaceValueSampleData';
import {
  StaticAudioData,
  StaticAudioInlineData,
} from '@cms/comps/content/audio/AudioCompExampleData';
import { StaticVideoData } from '@cms/comps/content/VideoCompExampleData';
import { AudioRecordingExampleData } from '@cms/comps/recording/AudioRecordingSampleData';
import {
  CheckBoxExampleData,
  CheckBoxImageExampleData,
} from '@cms/comps/interact/checkbox/CheckBoxSampleData';

import { ContentBanksStyle } from '@cms/comps-bank/ContentBanks';
import { DynamicContentTemplateData } from '@cms/comps/content/dynamic/DynamicContentCompExampleData';

import { FlashCardExampleData } from '../comps/game/flashcard/FlashCardSampleData';
import { MatchingGameExampleData } from '@cms/comps/game/matching/FlashCardMatchingSampleData';
import { WordBoxSampleData } from '@cms/comps/game/word-box/WordBoxSampleData';
import { WordScrambleSampleData } from '@cms/comps/game/word-scramble/WordScrambleSampleData';
import { WordSearchSampleData } from '@cms/comps/game/word-search/WordSearchSampleData';
import { WordPuzzleSampleData } from '@cms/comps/game/word-puzzle/WordPuzzleSampleData';
import { SocialMediaPlayerSampleData } from '@cms/comps/presentation/social-video/SocialVideoCompSampleData';
import { GlossarySampleData } from '../comps/game/glossary/GlossarySampleData';
import { SampleImageData } from '../comps/content/ImageCompSampleData';
import { StaticPdfData } from '../comps/content/PdfCompExampleData';
import { CircleUnderlineExampleData } from '@cms/comps/interact/circle-underline/CircleUnderlineWordSampleData';

const LIST_OBJECTS: CompProps[] = [
  StaticSampleData,
  DynamicContentTemplateData,
  // TemplateExampleData,
  SampleImageData,
  StaticAudioData,
  StaticAudioInlineData,
  StaticVideoData,
  StaticPdfData,
  SocialMediaPlayerSampleData,

  FibWordExampleData,
  CircleUnderlineExampleData,
  DropDownExampleData,
  DragNDropInlineExampleData,
  AudioRecordingExampleData,
  FibParagraphExampleData,
  FibEssayExampleData,

  MultipleChoiceExampleData,
  CheckBoxExampleData,
  CheckBoxImageExampleData,
  MatchingDragNDropExampleData,
  DragNDropExampleData, // ERROR HERE........
  DragNDropVerticalExampleData,
  OrderingExampleData,

  FibNumberExampleData,
  FractionExampleData,
  // MathEquationExampleData,

  LongAdditionExampleData,
  LongSubtractionExampleData,
  LongMultiplicationExampleData,
  LongDivisionExampleData,
  AreaMultiplicationExampleData,
  AreaDivisionExampleData,

  PlaceValueExampleData,
  PlaceValueExampleBlockData,
  PlaceValueAdditionExampleData,
  PlaceValueSubtractionExampleData,

  CoordinateExampleData,

  GlossarySampleData,
  FlashCardExampleData,
  MatchingGameExampleData,
  WordBoxSampleData,
  WordScrambleSampleData,
  WordSearchSampleData,
  WordPuzzleSampleData,
];

export default function CompBank(props: {
  onSelect: (compData: CompProps[]) => void;
}) {
  const { t } = useTranslation();

  return (
    <ContentBanksStyle className={'content-bank object-bank'}>
      {LIST_OBJECTS.map((data) => {
        return (
          <div className={'resource-selectable'} key={data.id}>
            <div className={'resource-comps'}>
              <ComponentItem
                key={data.id}
                item={data}
                answer={null}
                feedback={null}
                onChange={(data) => console.log(data)}
              />
            </div>

            <div className={'resource-action'}>
              <Button
                size={'small'}
                shape={'round'}
                type={'primary'}
                onClick={() => props.onSelect([data])}
              >
                {t('component.actions.add')}
              </Button>
            </div>
          </div>
        );
      })}
    </ContentBanksStyle>
  );
}
