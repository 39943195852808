import React, { useEffect, useState } from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import { PermissionTableStyle } from '@modules/users/component/permission/styled';
import { HintText } from '@components/text/HintText';
import { PermissionCheckBox } from '@modules/users/component/permission/PermissionCheckBox';
import {
  UserHasPermission,
  UserPermissionProvider,
} from '@modules/users/component/permission/PermissionContext';

export const UserPermission = (props: {
  value: RoleEnum[];
  onChange: (roles: RoleEnum[]) => void;
}) => {
  const [selectRoles, setSelectRoles] = useState<RoleEnum[]>([]);

  useEffect(() => {
    setSelectRoles(props.value);
  }, [props.value]);
  const handleChange = (value: RoleEnum, activated: boolean) => {
    if (activated) {
      const selectedRoles = [...selectRoles, value];
      setSelectRoles(selectedRoles);
      props.onChange(selectedRoles);
    } else {
      const selectedRoles = selectRoles.filter((role) => {
        return role !== value;
      });
      setSelectRoles(selectedRoles);
      props.onChange(selectedRoles);
    }
  };

  return (
    <UserPermissionProvider>
      <PermissionTableStyle className={'permission-table-form'}>
        <table
          className={'permission-table'}
          style={{ width: '100%', textAlign: 'center' }}
          border={1}
        >
          <thead>
            <tr>
              <td style={{ borderTop: 'none', borderLeft: 'none' }} />
              <th style={{ width: 180 }}>Support</th>
              <th style={{ width: 180 }}>Admin</th>
            </tr>
          </thead>

          <tbody>
            <UserHasPermission roles={[RoleEnum.MANAGE_COURSE]}>
              <tr>
                <th colSpan={3} className={'group'}>
                  MANAGER USER AND CLASS
                </th>
              </tr>

              <tr>
                <td>
                  Manage Course
                  <HintText>Allow user create and publish courses</HintText>
                </td>
                <td />
                <td>
                  <PermissionCheckBox
                    value={RoleEnum.MANAGE_COURSE}
                    selectValues={selectRoles}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </UserHasPermission>

            <UserHasPermission roles={[RoleEnum.MANAGE_CLASS]}>
              <tr>
                <td>
                  Manage Class And Students
                  <HintText>Allow user to manage classes and students</HintText>
                </td>
                <td>
                  <PermissionCheckBox
                    value={RoleEnum.MANAGE_CLASS}
                    selectValues={selectRoles}
                    onChange={handleChange}
                  />
                </td>
                <td />
              </tr>
            </UserHasPermission>

            <UserHasPermission roles={[RoleEnum.CREATE_CALENDAR]}>
              <tr>
                <td>
                  Create Calendar Event
                  <HintText>Allow user to create Calendar events.</HintText>
                </td>
                <td>
                  <PermissionCheckBox
                    value={RoleEnum.CREATE_CALENDAR}
                    selectValues={selectRoles}
                    onChange={handleChange}
                  />
                </td>
                <td />
              </tr>
            </UserHasPermission>

            <UserHasPermission
              roles={[
                RoleEnum.COMPOSE_LESSON,
                RoleEnum.CREATE_PRODUCT,
                RoleEnum.CREATE_LESSON_TEMPLATE,
              ]}
            >
              <tr>
                <th className={'group'} colSpan={3}>
                  MANAGER CMS (BOOK AND LESSON)
                </th>
              </tr>

              <UserHasPermission roles={[RoleEnum.COMPOSE_LESSON]}>
                <tr>
                  <td>
                    Compose Lesson
                    <HintText>
                      Allow user to compose lesson. User can access all lesson
                      created by him.
                    </HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.COMPOSE_LESSON}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>

              <UserHasPermission roles={[RoleEnum.CREATE_PRODUCT]}>
                <tr>
                  <td>
                    Create product
                    <HintText>
                      Allow user to create product. User can access all products
                      created by him.
                    </HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.CREATE_PRODUCT}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>

              <UserHasPermission roles={[RoleEnum.CREATE_PRODUCT]}>
                <tr>
                  <td>
                    Create Lesson Template
                    <HintText>Allow user to create lesson template.</HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.CREATE_LESSON_TEMPLATE}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>
            </UserHasPermission>

            <UserHasPermission
              roles={[RoleEnum.BOOK_LICENSE, RoleEnum.PUBLISH_LICENSE]}
            >
              <tr>
                <th className={'group'} colSpan={3}>
                  MANAGER LICENSE
                </th>
              </tr>

              <UserHasPermission roles={[RoleEnum.BOOK_LICENSE]}>
                <tr>
                  <td>
                    Assign Book License
                    <HintText>
                      Allow user to assign completed product to teachers. User
                      can access all products and all lessons.
                    </HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.BOOK_LICENSE}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>

              <UserHasPermission roles={[RoleEnum.BOOK_LICENSE]}>
                <tr>
                  <td>
                    Publish Books
                    <HintText>
                      Allow user to publish products. User can access all
                      products and all lessons.
                    </HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.PUBLISH_LICENSE}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>
            </UserHasPermission>

            <UserHasPermission
              roles={[RoleEnum.DESIGN_PAGE, RoleEnum.PUBLISH_PAGE]}
            >
              <tr>
                <th className={'group'} colSpan={3}>
                  MANAGER SCHOOL
                </th>
              </tr>

              <UserHasPermission roles={[RoleEnum.DESIGN_PAGE]}>
                <tr>
                  <td>
                    Design Page Content
                    <HintText>Design landing page and other pages.</HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.DESIGN_PAGE}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>

              <UserHasPermission roles={[RoleEnum.PUBLISH_PAGE]}>
                <tr>
                  <td>
                    Publish Page Content
                    <HintText>Publish Page content after completed..</HintText>
                  </td>
                  <td />
                  <td>
                    <PermissionCheckBox
                      value={RoleEnum.PUBLISH_PAGE}
                      selectValues={selectRoles}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </UserHasPermission>
            </UserHasPermission>

            <UserHasPermission roles={[RoleEnum.UPDATE_SCHOOL_SETTING]}>
              <tr>
                <td>
                  Update School Setting
                  <HintText>
                    Allow user to update school setting includes
                  </HintText>
                </td>
                <td />
                <td>
                  <PermissionCheckBox
                    value={RoleEnum.UPDATE_SCHOOL_SETTING}
                    selectValues={selectRoles}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </UserHasPermission>

            <UserHasPermission roles={[RoleEnum.MANAGE_OAUTH_APP]}>
              <tr>
                <th className={'group'} colSpan={3}>
                  MANAGER LTI
                </th>
              </tr>

              <tr>
                <td>
                  Manage Oauth App
                  <HintText>Manage Oauth App.</HintText>
                </td>
                <td />
                <td>
                  <PermissionCheckBox
                    value={RoleEnum.MANAGE_OAUTH_APP}
                    selectValues={selectRoles}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </UserHasPermission>

            <UserHasPermission roles={[RoleEnum.MANAGE_PAYMENT]}>
              <tr>
                <th className={'group'} colSpan={3}>
                  MANAGER Payment
                </th>
              </tr>

              <tr>
                <td>
                  Manage Payment Info
                  <HintText>Manage Payment Info.</HintText>
                </td>
                <td />
                <td>
                  <PermissionCheckBox
                    value={RoleEnum.MANAGE_PAYMENT}
                    selectValues={selectRoles}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </UserHasPermission>
          </tbody>
        </table>
      </PermissionTableStyle>
    </UserPermissionProvider>
  );
};
