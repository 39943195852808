import React, { useEffect, useState } from 'react';

import { Button, notification, Skeleton } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import {
  CourseAPI,
  CourseRes,
  CreateCourseReq,
} from '@services/private/CourseAPI';
import InviteStudentToCourse from '@components/form/InviteStudentFormToCourse';
import { CourseActionEnum } from '@modules/course/pages/MyCoursePage';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import { ImageScale } from '@utils/ImageScale';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import YoutubeInput from '@components/input/YoutubeInput';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';

const initialValue = {
  name: '',
  description: '',
  video: '',
  thumbnails: '',
};

export function CreateOrUpdateCourseForm(props: {
  courseId?: number;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [getCourse, { data, isSuccess }] =
    CourseAPI.endpoints.getCourse.useLazyQuery({});
  const [create, { isLoading: createLoading }] =
    CourseAPI.endpoints.create.useMutation({});
  const [update, { isLoading: updateLoading }] =
    CourseAPI.endpoints.update.useMutation({});

  const [isReady, setIsReady] = useState(false);
  const [thumbnails, setThumbnails] = useState('');

  useEffect(() => {
    if (props.courseId != null && props.courseId > 0) {
      getCourse(props.courseId);
    } else {
      setIsReady(true);
    }
  }, [props.courseId]);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setValues({
        name: data.name,
        description: data.description,
        thumbnails: data.thumbnails,
        video: data.video,
      });

      setIsReady(true);
      setThumbnails(data.thumbnails);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.courseId != null && props.courseId > 0) {
        const payload: CreateCourseReq = {
          courseId: props.courseId,
          name: values.name,
          description: values.description,

          video: values.video,
          thumbnails: thumbnails,
        };

        update(payload)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('course.warning.update_success'),
              description: t('course.warning.update_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('course.warning.update_error'),
              description: t('course.warning.update_error_message'),
              placement: 'bottomRight',
            });
          });
      } else {
        const payload: CreateCourseReq = {
          name: values.name,
          description: values.description,
          video: values.video,
          thumbnails: thumbnails,
        };

        create(payload)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('course.warning.create_success'),
              description: t('course.warning.create_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('course.warning.create_error'),
              description: t('course.warning.create_error_message'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  const handleOnFileUploaded = (thumbnails: string) => {
    setThumbnails(thumbnails);
  };

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <Input
                required={true}
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <InputGroup label={t('label.description')}>
                <Input
                  type={'textarea'}
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  maxLength={512}
                  style={{ minHeight: 120 }}
                />
              </InputGroup>

              <InputGroup label={t('label.thumbnails')}>
                <SingleUpload
                  scale={ImageScale.course.scale}
                  accept={'image/jpg, image/jpeg, image/png'}
                  multiple={false}
                  onSuccessFunc={handleOnFileUploaded}
                >
                  <IconImage
                    src={thumbnails}
                    width={ImageScale.course.width}
                    height={ImageScale.course.height}
                  />
                </SingleUpload>
              </InputGroup>

              <YoutubeInput
                label={t('label.introduce_video')}
                name="video"
                onChange={formik.handleChange}
                value={formik.values.video}
              />

              <ButtonGroup className="submit-container" type={'right'}>
                <Button
                  type="default"
                  shape={'round'}
                  size={'large'}
                  onClick={props.onCloseFunc}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  type={'primary'}
                  shape={'round'}
                  size={'large'}
                  loading={updateLoading || createLoading}
                  onClick={formik.submitForm}
                  icon={IconUtils.actions.save}
                >
                  {props.courseId != null && props.courseId > 0
                    ? t('button.update')
                    : t('button.create')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}

export default function CreateCourseForm(props: {
  type: CourseActionEnum;
  courseData?: CourseRes;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [addStudent] = CourseAPI.endpoints.addStudent.useMutation();
  const [removeStudent] = CourseAPI.endpoints.removeStudent.useMutation();

  const onAddStudent = (
    studentId: number,
    callBack: (success: boolean) => void
  ) => {
    if (props.courseData != null && props.courseData.courseId > 0) {
      const request = {
        courseId: props.courseData.courseId,
        studentId: studentId,
      };

      addStudent(request)
        .unwrap()
        .then(() => {
          callBack(true);
        })
        .catch(() => {
          callBack(false);
        });
    }
  };

  const onRemoveStudent = (
    studentId: number,
    callBack: (success: boolean) => void
  ) => {
    if (props.courseData != null && props.courseData.courseId > 0) {
      const request = {
        courseId: props.courseData.courseId,
        studentId: studentId,
      };

      removeStudent(request)
        .unwrap()
        .then(() => {
          callBack(true);
        })
        .catch(() => {
          callBack(false);
        });
    }
  };

  return (
    <div>
      {!props.courseData && (
        <CreateOrUpdateCourseForm
          successHandle={props.successHandle}
          onCloseFunc={props.onCloseFunc}
        />
      )}

      {props.courseData && props.courseData.courseId > 0 && (
        <ResponsiveTabs
          activeKey={props.type}
          items={[
            {
              key: CourseActionEnum.edit_course,
              label: t('course.info'),
              children: (
                <CreateOrUpdateCourseForm
                  courseId={props.courseData.courseId}
                  successHandle={props.successHandle}
                  onCloseFunc={props.onCloseFunc}
                />
              ),
            },
            {
              key: CourseActionEnum.view_student,
              label: t('course.students'),
              children: (
                <InviteStudentToCourse
                  courseId={props.courseData.courseId}
                  selectedStudents={props.courseData.students}
                  onAddStudent={onAddStudent}
                  onRemoveStudent={onRemoveStudent}
                  successHandle={() => {}}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
}
