import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { TablePaginationRes } from '@components/table/TablePagination';
import { SchoolStatusEnum } from '@services/model/common';

export interface PublishSchoolRes extends SchoolRes {
  schoolId: number;
}

export interface SchoolRes {
  schoolId: number;
  orgSourceId: string;

  clientId: string;
  clientUrl: string;

  name: string;
  address: string;
  city: string;
  district: string;
  zipCode: string;
  country: string;
  introduce: '';
  shortIntroduce: '';

  avatarUrl: '';
  thumbnailsUrl: '';
  youtubeUrl: '';

  facebookAccount: '';
  googleAccount: '';

  status: SchoolStatusEnum;
  publishedDate: string;
}

export interface SchoolReq {
  schoolId?: number;

  domain: string;
  shortName: string;
  displayName: string;

  avatarUrl: string;
  thumbnailsUrl: string;
  youtubeUrl: string;

  introduce: string;
  shortIntroduce: string;

  facebookAccount: string;
  googleAccount: string;
}

export interface CreateSchoolReq {
  schoolId?: number;
  // orgSourceId?: string;

  clientId?: string;
  clientUrl: string;
  name: string;
  address: string;
  city: string;
  district: string;
  zipCode: string;
  country: string;
}

export interface UpdateSchoolInfoRes {
  schoolId: number;
  introduce: '';
  shortIntroduce: '';

  avatarUrl: '';
  thumbnailsUrl: '';
  youtubeUrl: '';

  facebookAccount: '';
  googleAccount: '';
}

export interface PublicSchoolRes {
  schoolId: number;

  shortName: string;
  displayName: string;

  avatarUrl: string;
  thumbnailsUrl: string;
  youtubeUrl: string;

  introduce: string;
  shortIntroduce: string;

  facebookAccount: string;
  googleAccount: string;
}

export const SchoolAPI = createApi({
  reducerPath: 'SchoolAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<TablePaginationRes<SchoolRes>, unknown>({
      query: () => ({
        url: `/school/all`,
      }),
    }),

    findById: builder.query<SchoolRes, number>({
      query: (schoolId) => ({
        url: `/school/${schoolId}`,
      }),
    }),

    getSchool: builder.query<SchoolRes, unknown>({
      query: () => ({
        url: `/school`,
      }),
    }),

    createSchool: builder.mutation<SchoolRes, CreateSchoolReq>({
      query: (body) => ({
        url: `/school`,
        method: 'POST',
        body,
      }),
    }),

    updateSchool: builder.mutation<SchoolRes, CreateSchoolReq>({
      query: (body) => ({
        url: `/school`,
        method: 'PUT',
        body,
      }),
    }),

    updateSchoolInfo: builder.mutation<SchoolRes, UpdateSchoolInfoRes>({
      query: (body: UpdateSchoolInfoRes) => ({
        url: `/school/${body.schoolId}/metadata`,
        method: 'PUT',
        body,
      }),
    }),

    publishSchool: builder.mutation<SchoolRes, unknown>({
      query: () => ({
        url: '/school/publish',
        method: 'PUT',
      }),
    }),
  }),
});
