import { ExerciseReportRes } from '@services/reports/LessonReport';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { ReportUtils } from '@modules/reports/ReportUtils';
import ExerciseScore from '@modules/product/container/exercise/ExerciseScore';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { useTranslation } from 'react-i18next';
import { H3 } from '@components/typography';

const ExerciseScoreReport = (props: { reports: ExerciseReportRes[] }) => {
  const { t } = useTranslation();
  const [sourceData, setSourceData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableSize, setTableSize] = useState({ width: 600, height: 100 });

  useEffect(() => {
    const columnData = getColumnDisplay();
    setColumns(columnData.columns);
    setTableSize({ width: columnData.maxLength, height: columnData.maxHeight });

    const sourceData = getDataSource();
    setSourceData(sourceData);
  }, []);

  const getColumnDisplay = () => {
    let maxLength = 0;
    const maxHeight = 50 * (props.reports.length + 1);

    const columns: ColumnsType<any> = [];
    columns.push({
      title: t('label.startDate'),
      width: 235,
      dataIndex: 'createdDate',
      key: 'createdDate',
      fixed: 'left',
    });
    maxLength += 235;

    columns.push({
      title: t('label.score'),
      width: 100,
      dataIndex: 'score',
      key: 'score',
      fixed: 'left',
      align: 'center',
    });

    maxLength += 100;

    props.reports[0].responses.forEach((item, index) => {
      columns.push({
        title: 'Q' + (index + 1),
        width: 70,
        dataIndex: 'q' + index,
        key: 'q' + index,
        align: 'center',
      });

      maxLength += 50;
    });

    return {
      columns,
      maxLength,
      maxHeight,
    };
  };

  const getDataSource = () => {
    const data: any[] = [];

    props.reports.forEach((exercise) => {
      const exerciseData = {
        createdDate: (
          <span style={{ textAlign: 'center' }}>
            <DateAndTimeLabel label={exercise.exercise.createdDate} />
          </span>
        ),
        score: `${ReportUtils.percent(
          exercise.exercise.score!,
          exercise.exercise.maxScore!
        )}%`,
      };

      exercise.responses.forEach((res, index) => {
        // @ts-ignore
        exerciseData['q' + index] = (
          <ExerciseScore
            skip={res.skip}
            score={res.score}
            maxScore={res.maxScore}
          />
        );
      });

      data.push(exerciseData);
    });

    return data;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Exercise Score Report
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Exercise Score Report</h3>}
          content={
            <>
              <p>Report này cho biết kết quả chi tiết của học sinh.</p>
            </>
          }
        />
      </H3>

      <Table
        columns={columns}
        dataSource={sourceData}
        scroll={{ x: tableSize.width }}
        pagination={false}
      />
    </LessonReportChartStyle>
  );
};

export default ExerciseScoreReport;
