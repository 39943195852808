import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { SchoolPaymentMethodAPI } from '@modules/payment/services/SchoolPaymentMethodAPI';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { CreateSchoolPaymentMethodForm } from '../container/CreateSchoolPaymentMethodForm';
import Card, { CardClassEnum } from '@components/card';
import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTheme } from '@app/styled/StyledProvider';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import { SchoolPaymentMethodRes } from '@modules/payment/services/model';

const SchoolPaymentMethodManagementPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [findAll, { data, isSuccess }] =
    SchoolPaymentMethodAPI.endpoints.findAll.useLazyQuery();

  const [activeTab, setActiveTab] = useState('');

  const [paymentMethods, setPaymentMethods] = useState<
    SchoolPaymentMethodRes[]
  >([]);

  useEffect(() => {
    findAll({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setPaymentMethods(data);

      if (activeTab === '' && data.length > 0) {
        setActiveTab(data[0].code);
      }
    }
  }, [data]);

  const refresh = () => {
    findAll({});
  };

  const paymentMethodTabs = useMemo(() => {
    if (data != null && data.length > 0) {
      return data.map((pm) => {
        return {
          key: pm.code,
          label: (
            <>
              {pm.activated ? (
                <CheckCircleOutlined
                  style={{ color: theme.status.activated }}
                />
              ) : (
                <QuestionCircleOutlined
                  style={{ color: theme.status.inactivated }}
                />
              )}
              {pm.code} - {pm.name}
            </>
          ),
          children: (
            <CreateSchoolPaymentMethodForm
              paymentMethodId={pm.paymentMethodId}
              successHandle={refresh}
            />
          ),
        };
      });
    } else {
      return [];
    }
  }, [paymentMethods]);

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('payment_method.header')}</H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={18}>
          <Card
            className={CardClassEnum.rectangle_box}
            style={{ minHeight: 500 }}
          >
            <ResponsiveTabs
              onChange={(actTab) => setActiveTab(actTab)}
              items={paymentMethodTabs}
              activeKey={activeTab}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SchoolPaymentMethodManagementPage;
