import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import UserRequestPage from '@modules/users/pages/UserRequestPage';
import { PageHeader } from '@app/header/PageHeader';

const ManageRequest = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-request-page'}>
      <PageHeader
        menu={NavigationMenuEnum.COURSES}
        subMenu={NavigationMenuEnum.COURSES_REQUESTS}
        title={t('user_requests.header')}
        description={t('user_requests.header')}
      />

      <UserRequestPage />
    </ManagementLayout>
  );
};

export default ManageRequest;
