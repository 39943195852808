import React from 'react';

import en from './icons/us.svg';
import cn from './icons/cn.svg';
import jp from './icons/jp.svg';
import fr from './icons/fr.svg';
import vi from './icons/vn.svg';

export const CnFlag = (props: any) => {
  const size = getFlagSize(props.width, props.height);
  return (
    <img
      className={'flag-icon'}
      src={cn}
      alt="China"
      width={size.width}
      height={size.height}
    />
  );
};

export const JpFlag = (props: any) => {
  const size = getFlagSize(props.width, props.height);
  return (
    <img
      className={'flag-icon'}
      src={jp}
      alt="Japanese"
      width={size.width}
      height={size.height}
    />
  );
};

export const FnFlag = (props: any) => {
  const size = getFlagSize(props.width, props.height);
  return (
    <img
      className={'flag-icon'}
      src={fr}
      alt="French"
      width={size.width}
      height={size.height}
    />
  );
};

export const VnFlag = (props: any) => {
  const size = getFlagSize(props.width, props.height);
  return (
    <img
      className={'flag-icon'}
      src={vi}
      alt="Vietnamese"
      width={size.width}
      height={size.height}
    />
  );
};

export const EnFlag = (props: any) => {
  const size = getFlagSize(props.width, props.height);
  return (
    <img
      className={'flag-icon'}
      src={en}
      alt="English"
      width={size.width}
      height={size.height}
    />
  );
};

const getFlagSize = (width: number, height: number) => {
  const original_width = 40;
  const original_height = 30;

  if (width != null && width > 0) {
    return { width: width, height: (width * original_height) / original_width };
  } else if (height != null && height > 0) {
    return {
      width: (height * original_width) / original_height,
      height: height,
    };
  } else {
    return { width: original_width, height: original_height };
  }
};
