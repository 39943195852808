import React, { useEffect } from 'react';
import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';
import { PublicCourseDetail } from '@modules/public/components/courses/PublicCourseDetail';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import { SiteMap } from '@router/SiteMap';

const ViewCourse = () => {
  const router = useRouter();
  const { courseId } = router.query as unknown as {
    courseId: number;
  };

  const [viewCourse, { data, error, isError }] =
    PublicPageAPI.endpoints.viewCourse.useLazyQuery();

  useEffect(() => {
    viewCourse(courseId);
  }, [courseId]);

  useEffect(() => {
    if (error && isError) {
      router.push(SiteMap.public.page_404);
    }
  }, [error, isError]);

  return (
    <PublicLayout className={'course-detail-page'}>
      {data && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PUBLIC_COURSE}
            subMenu={NavigationMenuEnum.EMPTY}
            title={data.course.name}
            keywords={data.course.keyword}
            description={data.course.description}
          />

          <PublicPageContainer>
            <PublicCourseDetail
              school={data.school}
              course={data.course}
              author={data.author}
            />
          </PublicPageContainer>
        </>
      )}
    </PublicLayout>
  );
};

export default ViewCourse;
