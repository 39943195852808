import React, { useEffect } from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { SurveyViewerPage } from '@modules/public/pages/SurveyViewerPage';

const ViewSurvey = () => {
  const router = useRouter();

  const { surveyId } = router.query as unknown as {
    surveyId: string;
    surveyName: string;
  };

  const [viewSurvey, { data, error, isError }] =
    PublicPageAPI.endpoints.viewSurvey.useLazyQuery();

  useEffect(() => {
    viewSurvey(surveyId);
  }, [surveyId]);

  useEffect(() => {
    if (error && isError) {
      router.push(SiteMap.public.page_404);
    }
  }, [error, isError]);

  return (
    <PublicLayout className={'teacher-info-page'}>
      {data && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PUBLIC_COURSE}
            subMenu={NavigationMenuEnum.EMPTY}
            title={data.name}
            keywords={data.lesson.keyword}
            description={data.lesson.description}
          />

          <SurveyViewerPage item={data} />
        </>
      )}
    </PublicLayout>
  );
};

export default ViewSurvey;
