import { useState } from 'react';
import useDebounce from '@hooks/useDebounce';

export default function useFilterOptions<T>(
  options: T[],
  filterFunc: (option: T, keyword: string) => boolean
) {
  const [filterOptions, setFilterOptions] = useState<T[]>([]);
  const [keyword, setKeyword] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useDebounce(() => {
    setIsSearching(false);
    const filterOpts = options.filter((opt) => {
      return filterFunc(opt, keyword);
    });

    setFilterOptions(filterOpts);
    setIsSearching(false);
  }, [keyword, options]);

  const onSearch = (value: string) => {
    setKeyword(value);
    setIsSearching(true);
  };

  const onPageChange = () => {
    console.log('reach bottom....');
  };

  return {
    keyword,
    options: filterOptions,
    isSearching,
    onSearch,
    onPageChange,
  };
}
