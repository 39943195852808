import { MyProfileAPI } from '@services/private/MyProfileAPI';
import { useEffect } from 'react';
import { updateUserInfo } from '@app/redux/slices/userInfomationSlice';
import { useDispatch, useSelector } from '@app/redux/hook';
import { updateDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { changeTheme } from '@app/redux/slices/appLayoutSlice';
import {
  changeToLogout,
  getAccessTokenData,
} from '@app/redux/slices/profileSlice';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';

const useRefreshUserData = () => {
  const dispatch = useDispatch();
  const { onGetUserSuccess } = useLoginSuccess();
  const { defaultLanguage } = useSelector((state) => state.appLanguage);

  const [loadUserProfile, { data, isSuccess, error, isError }] =
    MyProfileAPI.endpoints.loadUserProfile.useLazyQuery();

  const [getMyProfile, { data: profileData, isSuccess: profileSuccess }] =
    MyProfileAPI.endpoints.getMyProfile.useLazyQuery();

  const { isAuth, isReady } = useSelector((state) => state.profile);

  useEffect(() => {
    // if is auth -> get user profile...
    if (isAuth) {
      getMyProfile({});

      // if not : check access token.
      if (!isReady) {
        loadUserProfile({});
      }
    } else {
      const accessToken = getAccessTokenData();
      // if access token is provided, get login data again...
      if (accessToken != null && accessToken.trim() !== '') {
        loadUserProfile({});
      }
    }
  }, [isAuth, isReady, getAccessTokenData()]);

  useEffect(() => {
    if (error && isError) {
      dispatch(changeToLogout());
    } else if (data && isSuccess) {
      onGetUserSuccess(data);
    }
  }, [error, data]);

  useEffect(() => {
    if (profileData && profileSuccess) {
      if (
        profileData.language != null &&
        profileData.language.code !== defaultLanguage
      ) {
        dispatch(updateDefaultLanguage(profileData.language.code));
      }
      dispatch(changeTheme(profileData.theme));
      dispatch(updateUserInfo(profileData));
    }
  }, [profileData, profileSuccess]);
};

export default useRefreshUserData;
