import { Button, notification, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import ButtonGroup from '@components/button/ButtonGroup';
import TablePagination from '@components/table/TablePagination';
import { AdminPublishSchoolAPI } from '@modules/admin/service/AcceptPublishSchoolAPI';
import { SchoolRes } from '@services/private/SchoolAPI';
import { useTranslation } from 'react-i18next';
import { DateLabel } from '@components/text/DateLabel';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { H4 } from '@components/typography';
import { InputGroup, InputValue } from '@components/input/InputGroup';

export const useViewPublishSchool = (
  params: PaginationReq,
  setParams: (params: PaginationReq) => void
) => {
  const [findAll, { data, isFetching }] =
    AdminPublishSchoolAPI.endpoints.findAll.useLazyQuery();
  const [acceptRequest] =
    AdminPublishSchoolAPI.endpoints.acceptRequest.useMutation({});
  const [rejectRequest] =
    AdminPublishSchoolAPI.endpoints.rejectRequest.useMutation({});

  useEffect(() => {
    findAll(params);
  }, [params]);

  const decideAcceptRequest = (
    schoolId: number,
    isAccept: boolean,
    callBackFunc: (isSuccess: boolean, accepted: boolean) => void
  ) => {
    if (isAccept) {
      acceptRequest(schoolId)
        .unwrap()
        .then(() => {
          callBackFunc(true, isAccept);
        })
        .catch(() => {
          callBackFunc(false, isAccept);
        });
    } else {
      rejectRequest(schoolId)
        .unwrap()
        .then(() => {
          callBackFunc(true, isAccept);
        })
        .catch(() => {
          callBackFunc(false, isAccept);
        });
    }
  };

  const refreshData = (params: any) => {
    setParams({ ...params });
  };

  return {
    data,
    isFetching,
    findAll,
    decideAcceptRequest,
    refreshData,
  };
};

export const ViewPublishSchoolTable = (props: { maxItems?: number }) => {
  const { t } = useTranslation();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: props.maxItems ? props.maxItems : MAX_PAGE_SIZE,
    sort: '',
    direction: '',
  });

  const { data, isFetching, decideAcceptRequest, refreshData } =
    useViewPublishSchool(params, setParams);

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('label.address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Sent Request',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      width: 120,
    },

    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      assign: 'center',
      width: 180,
    },
  ];

  const onView = (item: SchoolRes) => {
    console.log(item);
  };

  const onAccept = (schoolId: number, isAccepted: boolean) => {
    decideAcceptRequest(schoolId, isAccepted, (isSuccess, accepted) => {
      const warningMsg = accepted ? 'Chấp nhận lời mời' : 'Hủy lời mời';

      if (isSuccess) {
        notification.success({
          message: warningMsg,
          description: accepted
            ? 'Đã xác nhận thành công'
            : 'Đã hủy lời yêu cầu',
          placement: 'bottomRight',
        });

        setParams(params);
      } else {
        notification.error({
          message: warningMsg,
          description: accepted
            ? 'Xác nhận yêu cầu thất bại'
            : 'Xóa yêu cầu thất bại',
          placement: 'bottomRight',
        });
      }
    });
  };

  const processDataRow = (item: SchoolRes) => {
    return {
      key: item.schoolId,
      name: item.name,
      address: `${item.address} ${item.district} ${item.city} ${item.country}`,
      publishedDate: <DateLabel label={item.publishedDate} />,
      actions: (
        <PublicSchoolAction item={item} onView={onView} onAccept={onAccept} />
      ),
    };
  };

  const renderMobile = (item: SchoolRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <RequestPublicSchool item={item} onView={onView} onAccept={onAccept} />
      </Card>
    );
  };

  return (
    <TablePagination
      params={params}
      isLoading={isFetching}
      columns={columns}
      data={data}
      refresh={refreshData}
      processDataRow={processDataRow}
      responsive={{
        screen: [ResponsiveScreen.xs, ResponsiveScreen.sm, ResponsiveScreen.md],
        render: renderMobile,
      }}
    />
  );
};

const RequestPublicSchool = (props: {
  item: SchoolRes;
  onView: (item: SchoolRes) => void;
  onAccept: (schoolId: number, isAccepted: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'request-public-school'}>
      <H4>{props.item.name}</H4>

      <InputGroup label={t('label.address')}>
        <InputValue type={'textarea'}>{props.item.address}</InputValue>
      </InputGroup>

      <InputGroup label="Sent Request">
        <InputValue type={'textarea'}>
          <DateLabel label={props.item.publishedDate} />
        </InputValue>
      </InputGroup>

      <InputGroup>
        <PublicSchoolAction
          item={props.item}
          onView={props.onView}
          onAccept={props.onAccept}
        />
      </InputGroup>
    </div>
  );
};

const PublicSchoolAction = (props: {
  item: SchoolRes;
  onView: (item: SchoolRes) => void;
  onAccept: (schoolId: number, isAccepted: boolean) => void;
}) => {
  return (
    <ButtonGroup>
      <Button
        type={'default'}
        shape={'circle'}
        onClick={() => props.onView(props.item)}
        icon={IconUtils.actions.view_info}
      />

      <Popconfirm
        placement="topRight"
        title={'Bạn có muốn cho phép school được hiển thị công khai?'}
        onConfirm={() => props.onAccept(props.item.schoolId, true)}
        onCancel={() => props.onAccept(props.item.schoolId, false)}
        okText="Đồng ý"
        cancelText="Hủy bỏ"
      >
        <Button
          type={'primary'}
          shape={'circle'}
          icon={IconUtils.actions.update}
        />
      </Popconfirm>
    </ButtonGroup>
  );
};
