import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import LessonTemplatePage from '@modules/product/pages/LessonTemplatePage';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const PageTemplate = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'page-template-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PAGES}
        subMenu={NavigationMenuEnum.PAGES_PAGE_TEMPLATE}
        title={t('menu.page_template')}
        description={t('menu.page_template')}
      />

      <LessonTemplatePage />
    </ManagementLayout>
  );
};

export default PageTemplate;
