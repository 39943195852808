import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import styled from 'styled-components';

export enum ButtonClassEnum {
  default = '',
  hover_text = 'hover-text-action',
}

const AntButtonStyle = styled(AntButton)`
  &.hover-text-action {
    .hover-text {
      display: none;
    }

    &:hover {
      .hover-text {
        display: inline;
      }
    }
  }
`;

const Button = ({ className, ...props }: AntButtonProps) => {
  return (
    <AntButtonStyle
      className={`gstudy-button ${className ? className : ''}`}
      {...props}
    />
  );
};

export default Button;
