export const MAX_PAGE_SIZE = 25;

export interface PaginationReq {
  page?: number;
  size?: number;
  sort?: string;
  direction?: 'ascend' | 'descend' | '';

  // extend attributes
  selectAll?: boolean;
  searchTerm?: string;
  status?: number;
  excludedIds?: number[];
}

export interface PaginationAndFilterReq<T> extends PaginationReq {
  filterBy: T;
}

export interface PaginationAndFilterReq<T> extends PaginationReq {
  filterBy: T;
}

export interface PaginationRes<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    sort: {
      empty: true;
      sorted: false;
      unsorted: true;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
  };
  size: number;
  sort: {
    empty: true;
    sorted: false;
    unsorted: true;
  };
  totalElements: number;
  totalPages: number;
}

export interface PageAndFilterWithSchoolId extends PaginationReq {
  schoolId: number;
}
