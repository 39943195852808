import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { LongAdditionCompProps } from '@cms/comps/math/operation/addition/LongAdditionComp';
import { LongOperationLayoutEnum } from '@cms/comps/math/operation/OperationUtils';

export const LongAdditionExampleData: LongAdditionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.LONG_ADDITION,

  configuration: {
    addend: ['3523', '649'],
    expression: [
      {
        type: 'addend',
        exp: ['3', '5', '2', '3'],
      },
      {
        type: 'addend',
        exp: ['+', '6', '4', '9'],
      },
      {
        type: 'sum',
        exp: ['x', 'x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: LongOperationLayoutEnum.vertical,
  },
};

export const PlaceValueAdditionExampleData: LongAdditionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.LONG_ADDITION,

  configuration: {
    addend: ['3523', '649'],
    expression: [
      {
        type: 'place-value',
        exp: ['_', 'U', 'U', 'U', 'U'],
      },
      {
        type: 'addend',
        exp: ['_', '3', '5', '2', '3'],
      },
      {
        type: 'addend',
        exp: ['+', '_', '6', '4', '9'],
      },
      {
        type: 'sum',
        exp: ['_', 'x', 'x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: LongOperationLayoutEnum.place_value,
  },
};
