import React, { useEffect, useMemo, useState } from 'react';
import { Button, Space, Table } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import NavLink from '@components/button/NavLink';
import i18next from 'i18next';
import { DateLabel } from '@components/text/DateLabel';
import { LessonResult } from '@modules/product/components/toc/TableOfContentProgress';
import { IconUtils } from '@utils/IconUtils';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import {
  ExerciseRes,
  ExerciseStatus,
} from '@modules/assignments/service/exercise_model';

export const ExerciseStatusDisplay = (props: { status: ExerciseStatus }) => {
  const { t } = useTranslation();

  if (props.status === ExerciseStatus.VIEWED) {
    return <>{t('exercise.status.viewed')}</>;
  } else if (props.status === ExerciseStatus.IN_PROGRESS) {
    return <>{t('exercise.status.in_progress')}</>;
  } else if (props.status === ExerciseStatus.SUBMITTED) {
    return <>{t('exercise.status.submitted')}</>;
  } else if (props.status === ExerciseStatus.COMPLETED) {
    return <>{t('exercise.status.completed')}</>;
  } else if (props.status === ExerciseStatus.NEED_GRADING) {
    return <>{t('exercise.status.need_grading')}</>;
  } else {
    return <>{t('exercise.status.not_start')}</>;
  }
};

const ExerciseReportTable = (props: {
  lessonId: number;
  productId?: number;
  productTocId?: number;
  exercises: ExerciseRes[];
  onCloseFunc: () => void;
  onStartExercise: () => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [inCompleteAssignment, setIncompleteAssignment] =
    useState<ExerciseRes | null>(null);

  const [hasReport, setHasReport] = useState(false);

  useEffect(() => {
    if (props.exercises && props.exercises.length > 0) {
      const incomplete = props.exercises
        .filter((ex) => {
          return (
            ex.status === ExerciseStatus.NOT_START ||
            ex.status === ExerciseStatus.VIEWED ||
            ex.status === ExerciseStatus.IN_PROGRESS
          );
        })
        .sort((a, b) => {
          return a.createdDate.localeCompare(b.createdDate);
        });

      if (incomplete.length > 0) {
        setIncompleteAssignment(incomplete[0]);
      }

      // then check report....
      const completed = props.exercises.filter((ex) => {
        return (
          ex.status === ExerciseStatus.COMPLETED ||
          ex.status === ExerciseStatus.NEED_GRADING
        );
      });
      setHasReport(completed.length > 0);
    }
  }, [props.exercises]);

  const columns = [
    {
      title: t('label.start_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: t('label.completed_date'),
      dataIndex: 'completedDate',
      key: 'completedDate',
    },
    {
      title: t('label.score'),
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  const dataSource = useMemo(() => {
    if (props.exercises) {
      return props.exercises.map((item) => {
        return {
          createdDate: <DateLabel label={item.createdDate} />,
          completedDate: <DateLabel label={item.completedDate} />,
          status: (
            <>
              {item.status === ExerciseStatus.COMPLETED &&
              item.score != null &&
              item.maxScore != null ? (
                <p>
                  <LessonResult score={item.score} maxScore={item.maxScore} />
                </p>
              ) : (
                <ExerciseStatusDisplay status={item.status} />
              )}
            </>
          ),
          actions: (
            <>
              {item.status === ExerciseStatus.NOT_START && (
                <NavLink
                  href={SiteMap.private.my_exercise.start_gen(
                    item.exerciseId,
                    props.productId,
                    props.productTocId
                  )}
                >
                  <Button
                    shape={'round'}
                    type={'primary'}
                    icon={IconUtils.assignment.start}
                  >
                    {t('exercise.actions.continuous')}
                  </Button>
                </NavLink>
              )}

              {(item.status === ExerciseStatus.VIEWED ||
                item.status === ExerciseStatus.IN_PROGRESS) && (
                <NavLink
                  href={SiteMap.private.my_exercise.start_gen(
                    item.exerciseId,
                    props.productId,
                    props.productTocId
                  )}
                >
                  <Button
                    shape={'round'}
                    type={'primary'}
                    icon={IconUtils.assignment.start}
                  >
                    {t('exercise.actions.continuous')}
                  </Button>
                </NavLink>
              )}

              {item.status === ExerciseStatus.COMPLETED && (
                <Space>
                  <NavLink
                    href={SiteMap.private.my_exercise.review_gen(
                      item.exerciseId,
                      props.productId,
                      props.productTocId
                    )}
                  >
                    <Button
                      shape={'round'}
                      type={'default'}
                      icon={IconUtils.exercise.review}
                    >
                      {t('exercise.actions.review')}
                    </Button>
                  </NavLink>
                </Space>
              )}

              {item.status === ExerciseStatus.NEED_GRADING && (
                <NavLink
                  href={SiteMap.private.my_exercise.grade_gen(
                    item.exerciseId,
                    props.productId,
                    props.productTocId
                  )}
                >
                  <Button
                    type={'primary'}
                    shape={'round'}
                    icon={IconUtils.assignment.grade}
                  >
                    {t('exercise.actions.grade')}
                  </Button>
                </NavLink>
              )}
            </>
          ),
        };
      });
    } else {
      return [];
    }
  }, [props.exercises, i18next.language]);

  const continuousExercise = () => {
    if (inCompleteAssignment) {
      router.push(
        SiteMap.private.my_exercise.start_gen(
          inCompleteAssignment.exerciseId,
          props.productId,
          props.productTocId
        )
      );
    }
  };

  const viewExerciseReport = () => {
    router.push(
      SiteMap.reports.view_lesson_gen(
        props.lessonId,
        props.productId,
        props.productTocId
      )
    );
  };

  return (
    <>
      <Table dataSource={dataSource} columns={columns} pagination={false} />

      <div style={{ marginTop: '2.5em' }}>
        {hasReport && (
          <NavLink
            href={SiteMap.reports.view_lesson_gen(
              props.lessonId,
              props.productId,
              props.productTocId
            )}
          >
            <Button
              type={'primary'}
              shape={'round'}
              style={{ float: 'left' }}
              onClick={viewExerciseReport}
              icon={IconUtils.assignment.report}
            >
              {t('button.view_report')}
            </Button>
          </NavLink>
        )}

        <ButtonGroup type={'right'}>
          {inCompleteAssignment && (
            <Button
              type="primary"
              shape={'round'}
              icon={IconUtils.actions.start_exercise}
              onClick={continuousExercise}
            >
              {t('exercise.actions.continuous')}
            </Button>
          )}

          {!inCompleteAssignment && (
            <Button
              type="primary"
              shape={'round'}
              onClick={props.onStartExercise}
              icon={IconUtils.actions.start_exercise}
            >
              {hasReport
                ? t('exercise.actions.start_new')
                : t('exercise.actions.start')}
            </Button>
          )}

          <Button type="default" shape="round" onClick={props.onCloseFunc}>
            {t('button.cancel')}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};

export default ExerciseReportTable;
