import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const original_width = 22;
  const original_height = 22;

  const width = props.height
    ? (parseInt(props.height + '') * original_width) / original_height
    : original_width;
  const height = props.height ? props.height : original_height;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#D93939"></circle>
      <path
        d="M11.8757 11.0339L14.8527 8.05919C14.9531 7.9421 15.0055 7.79148 14.9995 7.63743C14.9936 7.48338 14.9297 7.33725 14.8206 7.22824C14.7115 7.11923 14.5653 7.05537 14.4111 7.04942C14.2569 7.04347 14.1062 7.09587 13.989 7.19615L11.012 10.1403L8.01657 7.14718C7.89938 7.0469 7.74865 6.99451 7.59448 7.00046C7.44032 7.00641 7.29407 7.07027 7.18498 7.17928C7.07589 7.28829 7.01198 7.43442 7.00602 7.58846C7.00007 7.74251 7.05251 7.89313 7.15286 8.01023L10.1421 11.0339L7.21412 13.923C7.14999 13.9779 7.09791 14.0454 7.06114 14.1213C7.02438 14.1973 7.00371 14.28 7.00046 14.3643C6.9972 14.4486 7.01141 14.5326 7.04221 14.6112C7.07301 14.6897 7.11972 14.7611 7.17941 14.8207C7.23911 14.8804 7.3105 14.9271 7.38911 14.9578C7.46772 14.9886 7.55184 15.0028 7.6362 14.9995C7.72056 14.9963 7.80334 14.9756 7.87933 14.9389C7.95533 14.9022 8.02291 14.8501 8.07782 14.786L10.9997 11.8664L13.9032 14.7677C14.0204 14.868 14.1712 14.9204 14.3253 14.9144C14.4795 14.9085 14.6257 14.8446 14.7348 14.7356C14.8439 14.6266 14.9078 14.4805 14.9138 14.3264C14.9197 14.1724 14.8673 14.0217 14.7669 13.9046L11.8757 11.0339Z"
        fill="white"
      ></path>
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
