import React, { useEffect, useRef, useState } from 'react';
import ContentEditor from '@components/editor/WysyEditor';
import { Button } from 'antd';
import styled from 'styled-components';
import { TemplateEditableUtils } from '@cms/comps/template/TemplateEditableUtils';
import useJquery from '@cms/script/useJqueryLib';
import { DynamicContentProps } from '@cms/comps/content/dynamic/DynamicContentComp';
import { IconUtils } from '@utils/IconUtils';
import DOMPurify from 'dompurify';

export const DynamicContentCompEditable = (props: {
  item: DynamicContentProps;
  onChange: (template: string) => void;
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const editableUtils = useRef(new TemplateEditableUtils());

  const [template, setTemplate] = useState(
    props.item.configuration.content.data
  );
  const [editType, setEditType] = useState('simple');
  const [ready, setReady] = useState(false);

  useJquery(() => {
    setReady(true);
  });

  useEffect(() => {
    if (containerRef.current && ready) {
      editableUtils.current.init(containerRef.current, handleOnChange);
    }
  }, [containerRef, template, ready, editType]);

  useEffect(() => {
    if (containerRef.current && ready) {
      editableUtils.current.update(template);
    }
  }, [containerRef, template, ready, editType]);

  const handleOnChange = (template: string) => {
    setTemplate(template);
    props.onChange(template);
  };

  const handleSwapMode = () => {
    setEditType((prev) => {
      return prev === 'simple' ? 'editor' : 'simple';
    });
  };

  return (
    <TemplateEditableStyle className={'comp comp-dynamic-editable'}>
      {editType === 'simple' ? (
        <div
          ref={containerRef}
          className={`comp-dynamic-content`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(template) }}
        />
      ) : (
        <ContentEditor onChange={handleOnChange} initValue={template} />
      )}

      <Button
        className={'cms-switch-button switch-text'}
        type={'default'}
        size={'small'}
        shape={'circle'}
        onClick={handleSwapMode}
        icon={IconUtils.component.switch_to_other}
      />
    </TemplateEditableStyle>
  );
};

const TemplateEditableStyle = styled.div`
  position: relative;

  .comp-dynamic-content {
    [contenteditable='true'] {
      background: rgba(0, 0, 0, 0.03) !important;
      padding-right: 30px;

      &:focus,
      &:hover {
        outline: none;
        background: rgba(0, 0, 0, 0.15) !important;
        color: ${(props) => props.theme.color.black};
      }
    }

    [editable='group'] {
      position: relative;

      &:hover {
        outline: 1px solid ${(props) => props.theme.component.primary};
      }

      &:first-child {
        &:last-child {
          .remove-group-button {
            display: none;
          }
        }
      }
    }

    .editable-button-group {
      position: absolute;
      bottom: 5px;
      right: 5px;
      display: flex;
      font-size: 14px;

      .add-group-button {
        margin-right: 0.25em;
      }

      .add-group-button,
      .remove-group-button {
        border-radius: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.color.white};
        border: 1px solid ${(props) => props.theme.color.grey};
        width: 1.6em;
        height: 1.6em;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  .cms-switch-button.switch-text {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
`;
