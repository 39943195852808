import React from 'react';
import { Col, notification, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useTranslation } from 'react-i18next';
import ActiveAccountForm from '@modules/authorization/container/ActivateAccountForm';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import { InvitationAPI } from '@services/private/InvitationAPI';
import ErrorBox from '@components/alert/ErrorBox';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';

export default function ActivateAccountPage({
  token,
  invitedCode,
}: {
  token: string;
  invitedCode: string;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { onLoginSuccess } = useLoginSuccess();

  const { data, isSuccess, isError, error } =
    InvitationAPI.endpoints.findInvitation.useQuery(
      {
        token: decodeURIComponent(token),
        invitedCode: decodeURIComponent(invitedCode),
      },
      { skip: !token && !invitedCode }
    );

  const [activeAccount, { isLoading }] =
    AuthAPI.endpoints.activeAccount.useMutation({});

  const updateUserInfo = (newPassword: string) => {
    const payload = {
      token: token,
      invitedCode: invitedCode,
      password: newPassword,
    };

    activeAccount(payload)
      .unwrap()
      .then((data) => {
        try {
          onLoginSuccess(data);
          router.push(SiteMap.private.dashboard);

          notification.success({
            message: t('registration.active.warning.success'),
            description: t('registration.active.warning.success_message'),
            placement: 'bottomRight',
          });
        } catch (e) {
          notification.error({
            message: t('registration.active.warning.error'),
            description: t('registration.active.warning.error_message'),
            placement: 'bottomRight',
          });
        }
      })
      .catch(() => {
        notification.error({
          message: t('registration.active.warning.error'),
          description: t('registration.active.warning.error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <>
      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            {isError && error && (
              <ErrorBox>
                <h1>{t('registration.active.warning.expired_message')}</h1>
              </ErrorBox>
            )}

            {data && isSuccess && (
              <>
                <H1>{t('registration.active.header')}</H1>

                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                    <p>
                      {t('registration.active.warning.teacher_create_message', {
                        teacherLink: data.teacher.email,
                      })}
                    </p>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                    <ActiveAccountForm
                      onSubmit={updateUserInfo}
                      loading={isLoading}
                      email={data.email}
                    />
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </AuthorizeStyle>
    </>
  );
}
