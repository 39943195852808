import React from 'react';

import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { IconUtils } from '@utils/IconUtils';
import { ComposeToolbarStyle } from '../layout';
import { ExitComposeBtn } from '@cms/lesson-template/compose/buttons/ExitComposeBtn';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { useSelector } from '@app/redux/hook';

const ComposeWysiResourceToolbar = () => {
  const { t } = useTranslation();

  const service = useLessonServiceContext();
  const { newComponents, newAnswers, scoring } = useSelector(
    (state) => state.composeResource
  );

  const { lessonId, dispatchAction } = useLessonEditableContext();

  const handleUpdateLesson = () => {
    dispatchAction(LessonEditableActionEnum.save_resource, -1);
  };

  const handleCreateLesson = () => {
    service.onCreateOrUpdate(
      service.resourceId,
      newComponents,
      newAnswers,
      scoring,
      () => {
        dispatchAction(LessonEditableActionEnum.create_lesson, -1);
      }
    );
  };

  return (
    <>
      <ComposeToolbarStyle className={'lesson-toolbar'}>
        <div className={'toolbar-group left-group'}>
          <ExitComposeBtn />
        </div>

        <div className={'toolbar-group right-group'}>
          <Space>
            {lessonId && lessonId > 0 ? (
              <LessonButton
                shape={'round'}
                type={'primary'}
                loading={service.loading}
                onClick={handleUpdateLesson}
                icon={IconUtils.actions.update}
              >
                {t('lesson.actions.update')}
              </LessonButton>
            ) : (
              <LessonButton
                shape={'round'}
                type={'primary'}
                onClick={handleCreateLesson}
                icon={IconUtils.actions.save}
                loading={service.loading}
              >
                {t('lesson.actions.create')}
              </LessonButton>
            )}
          </Space>
        </div>
      </ComposeToolbarStyle>
    </>
  );
};

export default ComposeWysiResourceToolbar;
