import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { EntityStatusEnum } from '@services/model/common';
import { LanguageEnum } from '@components/language/LanguageIcon';
import { LanguageRes } from '@services/setting/LanguageAPI';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface PageSiteLanguage {
  language: LanguageRes;
  lesson: LessonRes;
}

export interface PageSiteRes {
  pageSiteId: number;

  path: string;
  name: string;
  description: string;
  status: EntityStatusEnum;
  createdDate: string;
  modifiedDate: string;
  publishedDate: string;
  lesson: LessonRes;
  pages: PageSiteLanguage[];
}

export interface CreatePageSiteReq {
  path: string;
  name: string;
  description: string;
  language?: LanguageEnum;
}

export interface UpdatePageSiteReq extends CreatePageSiteReq {
  pageSiteId: number;
}

export interface LinkToLanguageReq {
  pageSiteId: number;
  language: LanguageEnum;
  lessonId?: number;
}

export const PageSiteAPI = createApi({
  reducerPath: 'PageSiteAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<PageSiteRes>, PaginationReq>({
      query: (params) => ({
        url: `/pages-site`,
        params,
      }),
    }),

    viewPageSite: builder.query<PageSiteRes, number>({
      query: (pageSiteId) => ({
        url: `/pages-site/${pageSiteId}`,
        method: 'GET',
      }),
    }),

    createPageSite: builder.mutation<PageSiteRes, CreatePageSiteReq>({
      query: (body) => ({
        url: '/pages-site',
        method: 'POST',
        body,
      }),
    }),

    linkToLanguage: builder.mutation<LessonRes, LinkToLanguageReq>({
      query: (body) => ({
        url: `/pages-site/${body.pageSiteId}/languages`,
        method: 'POST',
        body,
      }),
    }),

    updatePageSite: builder.mutation<PageSiteRes, UpdatePageSiteReq>({
      query: (body) => ({
        url: `/pages-site/${body.pageSiteId}`,
        method: 'PUT',
        body,
      }),
    }),

    deletePageSite: builder.mutation<BasicMessageRes, number>({
      query: (pageSiteId: number) => ({
        url: `/pages-site/${pageSiteId}`,
        method: 'DELETE',
      }),
    }),

    publishPageSite: builder.mutation<PageSiteRes, number>({
      query: (lessonId) => ({
        url: `/pages-site/${lessonId}/publish`,
        method: 'PUT',
      }),
    }),
  }),
});
