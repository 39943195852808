import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import React from 'react';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompMode } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { H5 } from '@components/typography';
import {
  SectionDropColumn,
  SectionResourceDragItem,
} from '@cms/section-bank/components/SectionDropColumn';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import {
  getColumnStyle,
  SectionColumnSettingProps,
} from '@cms/section-bank/columns/OneColumnSection';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import { useTranslation } from 'react-i18next';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { SingleQuestionToolbarHandle } from '@cms/lesson-template/components/toolbar/SingeQuestionToolbar';

export interface ThreeColumnSectionProps extends LessonSectionProps {
  type: SectionTypeEnum.three_column;
  setting: SectionColumnSettingProps;
  group: {
    header: ResourceProps[];
    content: ResourceProps[];
    footer: ResourceProps[];
  };
}

export const ThreeColumnSection = (props: {
  item: ThreeColumnSectionProps;
  mode: CompMode;
}) => {
  const { t } = useTranslation();

  const { config } = useLessonTemplateContext();
  const { resource } = useLessonNavigationContext();

  return (
    <ThreeColumnSectionStyle
      className={`lesson-section three-columns-section section_${props.mode.toLowerCase()}`}
    >
      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.header && (
          <div className={'lesson-section-header-container'}>
            <ContentViewer contentData={props.item.content.header} />
          </div>
        )}

      <div
        className={'lesson-section-container'}
        style={{ gap: props.item.setting.gap }}
      >
        {(props.mode === CompMode.COMPOSE ||
          props.item.group.header.length > 0) && (
          <div
            className={'header-group'}
            style={getColumnStyle(
              props.item.setting.width[0],
              3,
              props.item.setting.gap
            )}
          >
            <SectionDropColumn
              title={'First Column'}
              group={'header'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.header.map((item, index) => {
                const visible = Boolean(
                  !config.singleQuestionMode ||
                    resource.resourceId === item.resourceId
                );

                if (visible) {
                  return (
                    <SectionResourceDragItem
                      key={item.resourceId + '_' + index + '_' + visible}
                      index={index}
                      item={item}
                      mode={props.mode}
                    />
                  );
                } else {
                  return null;
                }
              })}
              {props.mode === CompMode.COMPOSE && (
                <H5 className={'empty-space'}>
                  {t('section.direction.drag_question')}
                </H5>
              )}
            </SectionDropColumn>
          </div>
        )}

        {(props.mode === CompMode.COMPOSE ||
          props.item.group.content.length > 0) && (
          <div
            className={'content-group'}
            style={getColumnStyle(
              props.item.setting.width[1],
              3,
              props.item.setting.gap
            )}
          >
            <SectionDropColumn
              title={'Second Column'}
              group={'content'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.content.map((item, index) => {
                const visible = Boolean(
                  !config.singleQuestionMode ||
                    resource.resourceId === item.resourceId
                );

                if (visible) {
                  return (
                    <SectionResourceDragItem
                      key={item.resourceId + '_' + index + '_' + visible}
                      index={index}
                      item={item}
                      mode={props.mode}
                    />
                  );
                } else {
                  return null;
                }
              })}
              {props.mode === CompMode.COMPOSE && (
                <H5 className={'empty-space'}>
                  {t('section.direction.drag_question')}
                </H5>
              )}
            </SectionDropColumn>
          </div>
        )}

        {(props.mode === CompMode.COMPOSE ||
          props.item.group.footer.length > 0) && (
          <div
            className={'footer-group'}
            style={getColumnStyle(
              props.item.setting.width[2],
              3,
              props.item.setting.gap
            )}
          >
            <SectionDropColumn
              title={'Third Column'}
              group={'footer'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.footer.map((item, index) => {
                const visible = Boolean(
                  !config.singleQuestionMode ||
                    resource.resourceId === item.resourceId
                );

                if (visible) {
                  return (
                    <SectionResourceDragItem
                      key={item.resourceId + '_' + index + '_' + visible}
                      index={index}
                      item={item}
                      mode={props.mode}
                    />
                  );
                } else {
                  return null;
                }
              })}
              {props.mode === CompMode.COMPOSE && (
                <H5 className={'empty-space'}>
                  {t('section.direction.drag_question')}
                </H5>
              )}

              <SingleQuestionToolbarHandle
                sectionId={props.item.lessonSectionId}
                resources={props.item.group.footer}
              />
            </SectionDropColumn>
          </div>
        )}
      </div>

      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.footer && (
          <div className={'lesson-section-footer-container'}>
            <ContentViewer contentData={props.item.content.footer} />
          </div>
        )}
    </ThreeColumnSectionStyle>
  );
};

const ThreeColumnSectionStyle = styled.div`
  .lesson-section-header-container {
    margin-bottom: 0.5em;
  }

  .lesson-section-container {
    display: flex;
    gap: 1em;
  }

  .header-group,
  .content-group,
  .footer-group {
    flex-grow: 1;
  }
`;

export const ThreeColumnSectionData: ThreeColumnSectionProps = {
  lessonSectionId: -1,
  code: COMPONENT_UTILS.generateUID(),
  type: SectionTypeEnum.three_column,
  name: 'Three-columns layout',
  setting: {
    width: [33.33, 33.33, 33.33],
    gap: '1em',
  },
  group: {
    header: [],
    content: [],
    footer: [],
  },
};
