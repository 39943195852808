import React, { useEffect, useMemo, useState } from 'react';

import { StandardSetAPI } from '@modules/product/services/StandardSetAPI';
import { PaginationReq } from '@services/model/PaginationRes';
import { Select, Tag } from 'antd';
import ColorUtils from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { useSelector } from '@app/redux/hook';
const { Option } = Select;

const SelectStandardSet = (props: {
  formik: any;
  name: string;
  value?: number; // using standard group to create an standard set....
  onChange: (value: number) => void;
}) => {
  const { t } = useTranslation();

  const [findAll, { data, isFetching }] =
    StandardSetAPI.endpoints.findAll.useLazyQuery();

  const { isReady } = useSelector((state) => state.userInformation);

  const [params] = useState<PaginationReq>({
    page: 0,
    size: 0,
    selectAll: true,
    sort: 'displayOrder',
    direction: 'descend',
  });

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (isReady) {
      reloadData();
    }
  }, [isReady]);

  const reloadData = () => {
    findAll({ ...params });
  };

  const handleOnSearch = (keyword: string) => {
    setKeyword(keyword);
  };

  const handleValueChange = (value: any) => {
    setKeyword('');
    props.onChange(value);
  };

  const children = useMemo(() => {
    if (data && data.content) {
      const options: any[] = [];
      const kw = keyword.trim().toLowerCase();

      data.content.forEach((stSet) => {
        const matching =
          keyword === '' ||
          stSet.code.toLowerCase().indexOf(kw) > -1 ||
          stSet.name.toLowerCase().indexOf(kw) > -1 ||
          stSet.description.toLowerCase().indexOf(kw) > -1;

        if (matching) {
          const optionGroup = (
            <Option value={stSet.standardSetId} key={stSet.standardSetId}>
              <Tag
                color={ColorUtils.getDefaultColor(stSet.standardSetId).color}
              >
                {stSet.code}
              </Tag>

              <b>{stSet.name}: &nbsp;</b>

              <LanguageContentTag content={stSet.description} />
            </Option>
          );

          options.push(optionGroup);
        }
      });

      return options;
    } else {
      return [];
    }
  }, [data, keyword]);

  return (
    <Select
      size={'large'}
      placeholder={t('standard_set.actions.select')}
      style={{ width: '100%' }}
      loading={isFetching}
      showSearch
      value={props.value ? props.value : props.formik.values[props.name]}
      onSearch={handleOnSearch}
      onChange={handleValueChange}
    >
      <Option value={-1} key={-1}>
        {t('standard_set.actions.select_a_standard_set')}
      </Option>

      {children}
    </Select>
  );
};

export default SelectStandardSet;
