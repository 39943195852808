import { ClassAPI } from '@modules/users/services/ClassAPI';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import {
  ClassFilterReq,
  ClassRes,
  ClassResourceRes,
} from '@modules/users/services/clazz';
import { useTranslation } from 'react-i18next';
import { ResponsiveScreen } from '@hooks/useMedia';
import TablePagination from '@components/table/TablePagination';
import Card, { CardClassEnum } from '@components/card';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';
import { ClassProductLicenseView } from '../resource/ClassProductLicenseView';
import { AddProductToClassForm } from '@modules/users/component/classes/resource/AddProductToClassForm';
import { ClassResourceLicense } from './ClassResourceLicense';
import { ClassResourceActions } from './ClassResourceActions';
import { ProgramDisplay } from '@modules/admin/component/ProgramDisplay';

enum ClassResourceAction {
  default = '',
  add_product = 'add-product',
}

export const ClassTeacherProgram = (props: { item: ClassRes }) => {
  const { t } = useTranslation();

  const [getClassResource, { data, isFetching }] =
    ClassAPI.endpoints.getClassResource.useLazyQuery({});

  const [action, setAction] = useState(ClassResourceAction.default);
  const [params, setParams] = useState<ClassFilterReq>({
    size: MAX_PAGE_SIZE,
    classId: props.item.classId,
  });

  useEffect(() => {
    getClassResource(params);
  }, [params]);

  const columns = [
    {
      title: '#',
      dataIndex: 'product.thumbnails',
      key: 'product.thumbnails',
      sorter: true,
      align: 'center',
      width: 110,
    },
    {
      title: t('label.name'),
      dataIndex: 'product.name',
      key: 'product.name',
      sorter: true,
      width: 300,
    },
    {
      title: 'Licenses',
      dataIndex: 'licenses',
      key: 'licenses',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 220,
    },
  ];

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...prev, params };
    });
  };

  const processDataRow = (item: ClassResourceRes) => {
    return {
      key: item.classResourceId,
      product: item.product,
      productId: item.product.productId,
      'product.thumbnails': (
        <ThumbnailsImg
          type={'icon'}
          src={item.product.thumbnails}
          ratio={ImageScale.product.ratio}
          width={52}
        />
      ),
      'product.name': item.product.name,
      licenses: <ClassResourceLicense item={item} />,
      actions: (
        <ClassResourceActions
          classId={props.item.classId}
          item={item}
          onSuccess={handleOnSuccess}
        />
      ),
    };
  };

  const renderMobile = (item: ClassResourceRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        {JSON.stringify(item)}
      </Card>
    );
  };

  const onAddProduct = () => {
    setAction(ClassResourceAction.add_product);
  };

  const handleOnSuccess = () => {
    getClassResource(params);
    setAction(ClassResourceAction.default);
  };

  return (
    <ClassResourceStyle className={'class-resources'}>
      <HeaderGroup className={'mt-0'}>
        <H1>
          Class Programs
          <Button
            shape={'circle'}
            type={'primary'}
            icon={IconUtils.add_new}
            onClick={onAddProduct}
          />
        </H1>

        {props.item.program && (
          <ProgramDisplay className={'bordered'} item={props.item.program} />
        )}
      </HeaderGroup>

      <p>
        Class Resources hiển thị các giáo trình được giảng dạy trong chương
        trình này.
      </p>

      {action === ClassResourceAction.add_product && (
        <AddProductToClassForm
          classId={props.item.classId}
          onSuccess={handleOnSuccess}
        />
      )}

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        expandable={{
          expandedRowRender: (res) => {
            return (
              <ClassProductLicenseView
                classId={props.item.classId}
                product={res.product}
              />
            );
          },
        }}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </ClassResourceStyle>
  );
};

const ClassResourceStyle = styled.div`
  .ant-table-wrapper {
    background: ${(props) => props.theme.app.backgroud};

    .ant-table-tbody > tr.ant-table-expanded-row > td {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
`;
