import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ContentEditor from '@components/editor/WysyEditor';
import { InputGroup } from '@components/input/InputGroup';
import { StringUtils } from '@utils/StringUtils';
import useContainerMediaQuery from '@hooks/useContainerMedia';
import Input from '@components/input/input';
import { HintText } from '@components/text/HintText';
import { useTranslation } from 'react-i18next';
import { LessonTemplateConfigProps, LessonTemplateSettingProps } from './props';

export const getDefaultPageStyle = (): LessonTemplateSettingProps => {
  return {
    fontSizeSetting: {
      default: 16,
    },

    spacingSetting: {
      option: '0.5em',
      component: '0.75em',
      question: '1.5em',
      section: '2em',
      content: '1em 0em',
      page: '1.5em 2em 1.5em 2.5em',
    },

    colorSetting: {
      default: '#333',
      primary: '#6366f1',
      secondary: '#cccccc',
      showCorrectAnswer: '#d424ff',
      borderColor: '#666',
    },

    stylesheet: '',
    script: '',
    header: '',
    footer: '',
  };
};

export const getDefaultTemplateStyle = (): LessonTemplateSettingProps => {
  return {
    fontSizeSetting: {
      default: 16,
    },

    spacingSetting: {
      option: '0.5em',
      component: '0.75em',
      question: '1.5em',
      section: '2em',
      content: '1em 0em',
      page: '1.5em 2em 1.5em 2.5em',
    },

    colorSetting: {
      default: '#333',
      primary: '#6366f1',
      secondary: '#cccccc',
      showCorrectAnswer: '#d424ff',
      borderColor: '#666',
    },

    stylesheet: '',
    script: '',
    header: '{LESSON_TITLE}',
    footer: '',
  };
};

export const getPresentationTemplate = () => {
  return {};
};

export const getTemplateStyle = (
  wrapClass: string,
  setting?: LessonTemplateSettingProps
) => {
  if (setting == null || setting.spacingSetting == null) {
    return null;
  }

  return (
    <style>
      {`
      .${wrapClass}{
        --cms-padding-option: ${setting.spacingSetting.option};
        --cms-padding-component: ${setting.spacingSetting.component};
        --cms-padding-question: ${setting.spacingSetting.question};
        --cms-padding-section: ${setting.spacingSetting.section};
        --cms-padding-content: ${setting.spacingSetting.content};
        --cms-padding-page: ${setting.spacingSetting.page};
      
        --cms-color: ${setting.colorSetting.default};
        --cms-primary: ${setting.colorSetting.primary};
        --cms-secondary: ${setting.colorSetting.secondary};
        --cms-show-correct-answer: ${setting.colorSetting.showCorrectAnswer};
        --cms-border-color: ${setting.colorSetting.borderColor};
      }
    `}
    </style>
  );
};

export const LessonTemplateLayoutEditable = ({
  setting,
  onChange,
}: {
  setting: LessonTemplateSettingProps;
  onChange: (
    value: string,
    type: 'header' | 'footer' | 'script' | 'stylesheet'
  ) => void;
}) => {
  const { t } = useTranslation();

  const [stylesheet, setStylesheet] = useState(setting.stylesheet);
  const [initScript, setInitScript] = useState(setting.script);

  const handleOnHeaderChange = (value: string) => {
    onChange(value, 'header');
  };

  const handleOnFooterChange = (value: string) => {
    onChange(value, 'footer');
  };

  const handleOnStylesheetChange = (value: string) => {
    setStylesheet(value);
  };

  const triggerStylesheetChange = (value: string) => {
    onChange(value, 'stylesheet');
  };

  const handleOnScriptChange = (value: string) => {
    setInitScript(value);
  };

  const triggerScriptChange = (value: string) => {
    onChange(value, 'script');
  };

  return (
    <div className={'lesson-layout-editable'}>
      <InputGroup label={'Header'}>
        <Input
          type={'textarea'}
          name="stylesheet"
          onChange={(val) => handleOnStylesheetChange(val.target.value)}
          onBlur={(val) => triggerStylesheetChange(val.target.value)}
          value={stylesheet}
          style={{ minHeight: 120 }}
          maxLength={512}
        />
        <HintText>{t('lesson_template.label.add_stylesheet_here')}</HintText>
      </InputGroup>

      <InputGroup label={'Header Content'}>
        <ContentEditor
          onChange={handleOnHeaderChange}
          initValue={setting.header}
        />
      </InputGroup>

      <InputGroup label={'Footer Content'}>
        <ContentEditor
          onChange={handleOnFooterChange}
          initValue={setting.footer}
        />
      </InputGroup>

      <InputGroup label={'Footer'}>
        <Input
          type={'textarea'}
          name="script"
          onChange={(val) => handleOnScriptChange(val.target.value)}
          onBlur={(val) => triggerScriptChange(val.target.value)}
          value={initScript}
          style={{ minHeight: 120 }}
          maxLength={512}
        />
        <HintText>{t('lesson_template.label.add_script_here')}</HintText>
      </InputGroup>
    </div>
  );
};

const getClassName = (config: LessonTemplateConfigProps) => {
  if (config.stepByStep) {
    return 'lesson-layout step-by-step';
  } else if (config.singleQuestionMode) {
    return 'lesson-layout single-question';
  } else if (config.singleMode) {
    return 'lesson-layout single-section';
  } else {
    return 'lesson-layout multiple-section';
  }
};

export const LessonTemplateLayout = (props: {
  title: string;
  config: LessonTemplateConfigProps;
  setting: LessonTemplateSettingProps;
  children: any;
}) => {
  const containerRef = useRef(null);
  const screen = useContainerMediaQuery(containerRef);

  return (
    <LessonTemplateLayoutStyle
      className={` ${getClassName(props.config)} lesson-screen-${screen} `}
      ref={containerRef}
    >
      {getTemplateStyle('lesson-layout', props.setting)}

      {props.setting.stylesheet && (
        <div
          className={'lesson-stylesheet'}
          style={{ display: 'none' }}
          dangerouslySetInnerHTML={{ __html: props.setting.stylesheet }}
        />
      )}

      {props.setting.header && (
        <div
          className={'lesson-header'}
          dangerouslySetInnerHTML={{
            __html: generateHeaderDisplay(
              props.setting.header,
              props.title,
              ''
            ),
          }}
        />
      )}

      <div className={'lesson-content'}>{props.children}</div>

      {props.setting.footer && (
        <div
          className={'lesson-footer'}
          dangerouslySetInnerHTML={{
            __html: generateHeaderDisplay(
              props.setting.footer,
              props.title,
              ''
            ),
          }}
        />
      )}

      {props.setting.script && (
        <div
          className={'lesson-script'}
          dangerouslySetInnerHTML={{ __html: props.setting.script }}
        />
      )}
    </LessonTemplateLayoutStyle>
  );
};

const generateHeaderDisplay = (
  contentHTML: string,
  title: string,
  productToc: string
) => {
  const titleContent = StringUtils.replaceAll(
    contentHTML,
    '{LESSON_TITLE}',
    title
  );
  const unitContent = StringUtils.replaceAll(
    titleContent,
    '{UNIT_NAME}',
    productToc
  );
  return unitContent;
};

const LessonTemplateLayoutStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 3em 5em;

  .single-question {
    max-width: 80%;
    margin: 2em auto;
  }

  .lesson-template-content {
    color: var(--cms-color, #000);
  }

  [contenteditable='true'] {
    padding: 0.25em;
    min-height: 1.6em;
  }
`;
