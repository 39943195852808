import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';

import {
  CreateLessonAndResourceReq,
  CreateLessonReq,
  FilterLessonReq,
  LessonRes,
  LessonResourceProps,
  LessonResourceStandards,
  ReorderResourcesReq,
  UpdateLessonReq,
} from '@modules/product/services/lesson_model';

import {
  AddResourceToLessonReq,
  ContentValidationResponse,
  LessonResourcesRes,
  ResourceRes,
  UpdateResourceLessonReq,
} from '@modules/product/services/resource_model';

export const LessonAPI = createApi({
  reducerPath: 'LessonAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyLessons: builder.query<PaginationRes<LessonRes>, FilterLessonReq>({
      query: (params) => ({
        url: `/lessons`,
        params,
      }),
    }),

    getLesson: builder.query<LessonRes, unknown>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}`,
        method: 'GET',
      }),
    }),

    getLessonResources: builder.query<LessonResourceProps, unknown>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/resources`,
        method: 'GET',
      }),
    }),

    getLessonValidation: builder.query<ContentValidationResponse, number>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/validate`,
        method: 'GET',
      }),
    }),

    // view lesson by id. If lesson is created by current user or lesson is shared with user -> return data, else return forbidden error
    viewLesson: builder.query<LessonRes, unknown>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/view`,
        method: 'GET',
      }),
    }),

    getComposeTemplates: builder.query<LessonRes[], unknown>({
      query: () => ({
        url: `/lessons/compose-templates`,
        method: 'GET',
      }),
    }),

    viewComposeTemplate: builder.query<LessonRes, unknown>({
      query: (path) => ({
        url: `/lessons/compose-templates/${path}/view`,
        method: 'GET',
      }),
    }),

    findByIds: builder.query<LessonRes[], number[]>({
      query: (params) => ({
        url: `/lessons/find-by-ids`,
        method: 'GET',
        params: { ids: params },
      }),
    }),

    findAll: builder.query<LessonRes[], unknown>({
      query: () => ({
        url: `/lessons/find-all`,
        method: 'GET',
      }),
    }),

    createLesson: builder.mutation<LessonRes, CreateLessonReq>({
      query: (body) => ({
        url: '/lessons',
        method: 'POST',
        body,
      }),
    }),

    createLessonAndResources: builder.mutation<
      LessonResourcesRes,
      CreateLessonAndResourceReq
    >({
      query: (body) => ({
        url: '/lessons/create-resources',
        method: 'POST',
        body,
      }),
    }),

    // updateLesson: builder.mutation<LessonRes, CreateLessonReq>({
    //   query: (body: CreateLessonReq) => ({
    //     url: `/lessons/${body.lessonId}`,
    //     method: 'PUT',
    //     body,
    //   }),
    // }),

    deleteLesson: builder.mutation<BasicMessageRes, number>({
      query: (lessonId: number) => ({
        url: `/lessons/${lessonId}`,
        method: 'DELETE',
      }),
    }),

    updateLessonMetadata: builder.mutation<LessonRes, UpdateLessonReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/metadata`,
        method: 'PUT',
        body,
      }),
    }),

    updateStatus: builder.mutation<
      BasicMessageRes,
      { lessonId: number; status: 'published' | 'composing' }
    >({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/status`,
        method: 'PUT',
        body,
      }),
    }),

    validateLesson: builder.mutation<BasicMessageRes, number>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/validate`,
        method: 'PUT',
      }),
    }),

    getResourceStandards: builder.query<LessonResourceStandards, number>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/resources/standards`,
        method: 'GET',
      }),
    }),

    addResourceToLesson: builder.mutation<
      BasicMessageRes,
      AddResourceToLessonReq
    >({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/resources`,
        method: 'POST',
        body,
      }),
    }),

    reorderResources: builder.mutation<LessonRes, ReorderResourcesReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/resources/reorder`,
        method: 'PUT',
        body,
      }),
    }),

    moveResourceTop: builder.mutation<ResourceRes[], UpdateResourceLessonReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/resources/move-top`,
        method: 'PUT',
        body,
      }),
    }),

    moveResourceUp: builder.mutation<ResourceRes[], UpdateResourceLessonReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/resources/move-up`,
        method: 'PUT',
        body,
      }),
    }),

    moveResourceDown: builder.mutation<ResourceRes[], UpdateResourceLessonReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/resources/move-down`,
        method: 'PUT',
        body,
      }),
    }),

    moveResourceBottom: builder.mutation<
      ResourceRes[],
      UpdateResourceLessonReq
    >({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/resources/move-bottom`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
