import React, { useState } from 'react';
import { ViewLessonContentProvider } from './context/ViewLessonContext';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { ResourceAnswerProps } from '@app/redux/slices/viewResource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { SelfCheckServiceProvider } from '@cms/service/SelfCheckServiceProvider';
import { LessonRes } from '@modules/product/services/lesson_model';

export enum ReviewComposeLessonTypeEnum {
  default = '',
  compose = 'compose',
  review = 'review',
}

interface ReviewComposeLessonProps {
  type?: ReviewComposeLessonTypeEnum;
  data: LessonRes;
  productId?: number;
  productTocId?: number;
}

const getDefaultAnswers = (
  lesson: LessonRes,
  type?: ReviewComposeLessonTypeEnum
): ResourceAnswerProps[] => {
  if (type && type === ReviewComposeLessonTypeEnum.compose) {
    const resources = ResourceUtils.getResources(lesson.sections);

    const answers: ResourceAnswerProps[] = [];
    resources.forEach((res) => {
      if (res.validation?.interact) {
        answers.push({
          resourceId: res.resourceId,
          answers: res.correctAnswer,
        });
      }
    });
    return answers;
  } else {
    return [];
  }
};

export const ReviewComposeLesson = ({
  type,
  data,
  productId,
  productTocId,
}: ReviewComposeLessonProps) => {
  const router = useRouter();

  const [answers] = useState<ResourceAnswerProps[]>(() => {
    return getDefaultAnswers(data, type);
  });

  const goBack = () => {
    if (productId && productId > 0) {
      router.push(SiteMap.content.product.toc_gen(productId));
    } else {
      router.push(SiteMap.content.lesson.list);
    }
  };

  return (
    <ViewLessonContentProvider
      type={CompMode.SELF_CHECK}
      lesson={data}
      lessonType={data.type ?? LessonTypeEnum.lesson}
      answers={answers}
      feedBacks={[]}
      gradings={[]}
      exitExercise={goBack}
    >
      <SelfCheckServiceProvider>
        <LessonTemplateLayoutProvider lesson={data}>
          <ViewLessonContent />
        </LessonTemplateLayoutProvider>
      </SelfCheckServiceProvider>
    </ViewLessonContentProvider>
  );
};
