import React, { useState } from 'react';
import { Col, Row, Steps } from 'antd';

import { useTranslation } from 'react-i18next';
import SchoolEditForm from '../container/school/SchoolEditForm';
import SchoolInstructionForm from '@modules/setting/container/school/SchoolInstructionForm';
import Card, { CardClassEnum } from '@components/card';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const { Step } = Steps;

export enum RegisterSchoolStepEnum {
  INFORMATION = 1,
  INTRODUCTION = 2,
  REVIEW = 3,
}

export default function CreateSchoolPage() {
  const router = useRouter();
  const { t } = useTranslation();
  const [currentState, setCurrentState] = useState<RegisterSchoolStepEnum>(
    RegisterSchoolStepEnum.INFORMATION
  );

  const getStatusType = (status: RegisterSchoolStepEnum) => {
    return status === currentState
      ? 'process'
      : status < currentState
      ? 'finish'
      : 'wait';
  };

  const viewPublishSite = () => {
    router.push(SiteMap.management.school.info_review);
  };

  const cancel = () => {
    console.log('..........................');
  };

  return (
    <>
      <Steps>
        <Step
          status={getStatusType(RegisterSchoolStepEnum.INFORMATION)}
          title={t('publish.basic_info.header')}
        />

        <Step
          status={getStatusType(RegisterSchoolStepEnum.INTRODUCTION)}
          title={t('publish.basic_info.introduction')}
        />

        <Step
          status={getStatusType(RegisterSchoolStepEnum.REVIEW)}
          title={'Review'}
        />
      </Steps>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
          <div style={{ marginTop: '3em' }}>
            {currentState === RegisterSchoolStepEnum.INFORMATION && (
              <Card className={CardClassEnum.rectangle_box} key={currentState}>
                <SchoolEditForm
                  onSuccess={() =>
                    setCurrentState(RegisterSchoolStepEnum.INTRODUCTION)
                  }
                  onCancel={cancel}
                />
              </Card>
            )}

            {currentState === RegisterSchoolStepEnum.INTRODUCTION && (
              <Card className={CardClassEnum.rectangle_box} key={currentState}>
                <SchoolInstructionForm
                  onSuccess={viewPublishSite}
                  onCancel={() =>
                    setCurrentState(RegisterSchoolStepEnum.INFORMATION)
                  }
                />
              </Card>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
