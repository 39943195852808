import styled from 'styled-components';
import React, { useMemo } from 'react';

const WordBoxTryTimesStyle = styled.div`
  display: flex;
  justify-content: flex-end;

  .try-time-item {
    width: 0.5em;
    height: 0.5em;
    display: inline-flex;
    border-radius: 100%;
    border: 1px solid #ccc;
    margin-left: 0.125em;

    &.incorrect {
      background: ${(props) => props.theme.component.incorrect};
    }

    &.correct {
      background: ${(props) => props.theme.component.correct};
    }
  }
`;

const WordBoxTryTimes = (props: {
  type: 'correct' | 'incorrect';
  current: number;
  total: number;
}) => {
  const tryTimes = useMemo(() => {
    if (props.total) {
      const tryTimesEl = [];

      for (let i = 0; i < props.total; i++) {
        if (i < props.current) {
          if (props.type === 'incorrect') {
            tryTimesEl.push(
              <span
                className={'try-time-item incorrect'}
                key={`${i}-index-incorrect`}
              />
            );
          } else {
            tryTimesEl.push(
              <span
                className={'try-time-item correct'}
                key={`${i}-index-correct`}
              />
            );
          }
        } else {
          tryTimesEl.push(
            <span className={'try-time-item'} key={`${i}-index-not-start`} />
          );
        }
      }

      return tryTimesEl;
    } else {
      return null;
    }
  }, [props.current, props.total]);

  if (props.total == null || props.total <= 0) {
    return null;
  } else {
    return (
      <WordBoxTryTimesStyle className={'try-times-container'}>
        {tryTimes}
      </WordBoxTryTimesStyle>
    );
  }
};

export default WordBoxTryTimes;
