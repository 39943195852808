import React from 'react';
import ComposeResourceTemplate from '@cms/lesson-template/compose/resource/ComposeResourceTemplate';
import { ComponentLibrary } from '@cms/comps-bank/ComponentLibrary';
import { CompProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { addComponents } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import ComposeResourceToolbar from '@cms/lesson-template/compose/resource/ComposeResourceToolbar';

import {
  LessonTemplateLayoutProvider,
  LessonTemplateWrapper,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import {
  ComposeContentLayout,
  ComposeFooterLayout,
  ComposeLayout,
  LessonBankLayout,
} from '../layout';
import { ViewExerciseWrapper } from '@cms/lesson-template/exercise/ViewExerciseWrapper';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { LessonTypeEnum } from '@modules/admin/service/model';

export const ComposeSingleQuestionTemplate = () => {
  const dispatch = useDispatch();
  const service = useLessonServiceContext();

  const handleOnSelectComponents = (components: CompProps[]) => {
    const newComps = components.map((compData) => {
      return COMPONENT_UTILS.generateComponent(compData);
    });
    dispatch(addComponents(newComps));
  };

  return (
    <>
      <LessonBankLayout className={'lesson-sections'}>
        <div className={'component-bank'}>
          <ComponentLibrary
            type={LessonTypeEnum.lesson}
            onSelect={handleOnSelectComponents}
          />
        </div>
      </LessonBankLayout>

      <ComposeLayout className={'show-correct-answer compose-single-question'}>
        <ComposeContentLayout className={'compose-content-layout'}>
          <LessonTemplateLayoutProvider>
            <ViewExerciseWrapper
              className={'exercise-content-display'}
              ratio={{ width: 16, height: 9 }}
              minFontSize={12}
            >
              <LessonTemplateWrapper>
                <ComposeResourceTemplate resourceId={service.resourceId} />
              </LessonTemplateWrapper>
            </ViewExerciseWrapper>
          </LessonTemplateLayoutProvider>
        </ComposeContentLayout>

        <ComposeFooterLayout className={'compose-footer-layout'}>
          <ComposeResourceToolbar />
        </ComposeFooterLayout>
      </ComposeLayout>
    </>
  );
};
