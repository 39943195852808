import React from 'react';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ComposingWithStatus } from '@components/common/lesson/ComposingStatus';
import { H3 } from '@components/typography';
import styled from 'styled-components';
import { ProductRes } from '@modules/product/services/product_model';

export const ProductDisplay = (props: {
  showStatus?: boolean;
  size?: number;
  item: ProductRes;
}) => {
  return (
    <ProductDisplayStyle className={'product-display'}>
      <ThumbnailsImg
        type={'icon'}
        src={props.item.icon}
        ratio={ImageScale.product.ratio}
        width={props.size ?? 64}
      />

      {props.showStatus ? (
        <H3>
          <ComposingWithStatus status={props.item.status}>
            {props.item.name}
          </ComposingWithStatus>
        </H3>
      ) : (
        <span>{props.item.name}</span>
      )}
    </ProductDisplayStyle>
  );
};

const ProductDisplayStyle = styled.div`
  display: flex;
  align-items: center;

  gap: 1ch;
`;
