import {
  LongOperationLayoutEnum,
  MathOperationEnum,
  MathOperationProps,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import Button from '@components/button';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { MinusOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import styled from 'styled-components';
import { LongOperationTableEditable } from '../table/LongOperationTableEditable';
import Input from 'antd/lib/input/Input';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { useTranslation } from 'react-i18next';

export const LongAdditionEditable = (props: {
  layout: LongOperationLayoutEnum;
  addends: string[];
  expression: OperationExpProps[];
  onChange: (
    addends: string[],
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => void;
}) => {
  const { t } = useTranslation();
  const [addends, setAddends] = useState<string[]>([]);
  const [expression, setExpression] = useState<OperationExpProps[]>([]);
  const [answerExpression, setAnswerExpression] = useState<OperationExpProps[]>(
    []
  );

  useEffect(() => {
    setExpression([...props.expression]);
  }, [props.expression]);

  useEffect(() => {
    const addends = [...props.addends];

    let answer: MathOperationProps;

    if (props.layout === LongOperationLayoutEnum.vertical) {
      answer = OperationUtils.parseAddition(addends, false);
    } else if (props.layout === LongOperationLayoutEnum.horizontal) {
      answer = OperationUtils.parseAdditionInline(addends);
    } else {
      answer = OperationUtils.parseAddition(addends, true);
    }

    setAddends(addends);
    setAnswerExpression(answer.results);
  }, [props.addends, props.layout]);

  const handleOnClick = () => {
    let answer: MathOperationProps;

    if (props.layout === LongOperationLayoutEnum.vertical) {
      answer = OperationUtils.parseAddition(addends, false);
    } else if (props.layout === LongOperationLayoutEnum.horizontal) {
      answer = OperationUtils.parseAdditionInline(addends);
    } else {
      answer = OperationUtils.parseAddition(addends, true);
    }

    setExpression(answer.results);
    setAnswerExpression(answer.results);
  };

  const handleOnExpressionChange = (
    index: number,
    expression: OperationExpProps
  ) => {
    setExpression((prev) => {
      return COMPONENT_UTILS.updateAtIndex(prev, index, expression);
    });
  };

  const handleOnChange = (val: string, index: number) => {
    setAddends((prev) => {
      return [...prev].map((v, idx) => {
        return idx === index ? val : v;
      });
    });
  };

  const removeAddends = (index: number) => {
    setAddends((prev) => {
      return COMPONENT_UTILS.removeAtIndex(prev, index);
    });
  };

  const insertAddend = (index: number) => {
    setAddends((prev) => {
      return COMPONENT_UTILS.insertAtIndex(prev, index, prev[index]);
    });
  };

  const onUpdateEditableData = () => {
    props.onChange(addends, expression, answerExpression);
  };

  return (
    <>
      <LongEditableStyle className={'long-addition-editable'}>
        <Row>
          <Col flex={'250px'}>
            {addends.map((val, index) => {
              return (
                <InputGroup
                  label={t('component.operation.addend_n', { n: index + 1 })}
                  key={'Addends ' + (index + 1)}
                >
                  <Space>
                    <Input
                      name="addends"
                      type="number"
                      onChange={(val) =>
                        handleOnChange(val.target.value, index)
                      }
                      value={val}
                    />

                    <Button
                      type={'default'}
                      shape={'circle'}
                      size={'small'}
                      danger
                      disabled={addends.length === 2}
                      onClick={() => removeAddends(index)}
                    >
                      <MinusOutlined />
                    </Button>
                    <Button
                      type={'default'}
                      shape={'circle'}
                      size={'small'}
                      onClick={() => insertAddend(index)}
                    >
                      <PlusOutlined />
                    </Button>
                  </Space>
                </InputGroup>
              );
            })}
          </Col>

          <Col flex={'100px'}>
            <div className={'button-center-group'}>
              <Button type={'primary'} shape={'circle'} onClick={handleOnClick}>
                <SyncOutlined />
              </Button>
            </div>
          </Col>

          <Col flex={'auto'}>
            <LongOperationTableEditable
              layout={props.layout}
              operation={MathOperationEnum.addition}
              expression={expression}
              answerExpression={answerExpression}
              onChange={handleOnExpressionChange}
            />
          </Col>
        </Row>
      </LongEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={onUpdateEditableData}
      />
    </>
  );
};

export const LongEditableStyle = styled.div`
  position: relative;
  padding: 0.5em;

  .form-group {
    margin-bottom: 0.5em;

    .ant-form-controls {
      display: flex;
      align-items: baseline;

      .form-group {
        margin-bottom: 0;
      }
    }
  }

  .long-operation-table {
    margin-bottom: 1em;
  }

  .button-center-group {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;
