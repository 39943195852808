import React, { useMemo, useState } from 'react';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { AudioPlayer } from '@cms/content/AudioViewer';
import { VideoPlayer } from '@cms/content/VideoViewer';
import { AssetsRes } from '@modules/product/services/assets_model';
import { AssetsLibraryComp } from '@cms/utils/AssetsLibraryComp';
import { Button, Dropdown } from 'antd';
import ContentEditor from '@components/editor/WysyEditor';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import styled from 'styled-components';
import PDFViewer from '@cms/content/PdfViewer';
import { CompMode } from '@cms/ComponentInteface';
import { CmsImageViewer } from '@cms/content/ImageViewer';
import { ImageContentProps } from '@cms/content/ContentType';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { TextAreaEditable } from '@cms/editable/TextareEditable';
import EpubViewer from '@cms/content/EpubViewer';
import DocViewerComp from '@cms/content/DocViewer';
import { useTranslation } from 'react-i18next';
import ImageContentEditable from './image/ImageContentEditable';
import { IconUtils } from '@utils/IconUtils';
import { ContentInlineEditable } from '@cms/comps/content/ContentViewerInlineEditable';
import { useProUser } from '@components/button/RequireProUserButton';

export enum ContentOperationEnum {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum ContentActionEnum {
  CHANGE_TO_TEXT = 'change-to-text',
  CHANGE_TO_IMAGE = 'change-to-image',
  CHANGE_TO_VIDEO = 'change-to-video',
  CHANGE_TO_AUDIO = 'change-to-audio',
}

export const ContentViewerInlineEditable = (props: {
  hideInput?: boolean;
  mode?: CompMode;
  contentData: AssetsRes;
  onChange: (data: AssetsRes) => void;
  onCancel: () => void;
}) => {
  const handleOnChange = (value: string) => {
    props.onChange({
      id: props.contentData.id ?? COMPONENT_UTILS.generateUID(),
      type: AssetsTypeEnum.TEXT,
      data: value,
    });
  };

  return (
    <ContentInlineEditable
      hideInput={props.hideInput ?? true}
      value={props.contentData.data}
      onChange={handleOnChange}
      onCancel={props.onCancel}
    />
  );
};

export const ContentViewerEditable = (props: {
  mode?: CompMode;
  contentData: AssetsRes;
  onChange: (data: AssetsRes) => void;
  onComponentChange?: (data: any) => void;
}) => {
  const { t } = useTranslation();
  const proUser = useProUser();
  const { mode } = useResourceContext();

  const [showLibrary, setShowLibrary] = useState(false);

  const [editType, setEditType] = useState('simple');

  const handleOnSelectFile = (newData: AssetsRes) => {
    props.onChange(newData);
    setShowLibrary(false);
  };

  const handleOnChange = (value: string) => {
    if (props.onChange) {
      props.onChange({
        id: COMPONENT_UTILS.generateUID(),
        type: AssetsTypeEnum.TEXT,
        data: value,
        editType: editType,
      });
    }
  };

  const handleOnImageChange = (value: ImageContentProps) => {
    if (props.onChange) {
      props.onChange({
        id: value.id,
        type: value.type,
        data: value.data,
        width: value.width,
        align: value.align,
      });
    }
  };

  if (CompMode.COMPOSE === mode || CompMode.COMPOSE === props.mode) {
    if (props.contentData.type !== AssetsTypeEnum.TEXT) {
      return (
        <ContentViewerEditableStyle
          className={'content-viewer-editable'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {props.contentData.type === AssetsTypeEnum.IMAGE ? (
            <ImageContentEditable
              contentData={props.contentData as ImageContentProps}
              onChange={handleOnImageChange}
            />
          ) : (
            <ContentViewer contentData={props.contentData} />
          )}

          {showLibrary && (
            <AssetsLibraryComp
              type={props.contentData.type}
              selectFile={props.contentData}
              onSelectFile={handleOnSelectFile}
              onCancel={() => setShowLibrary(false)}
            />
          )}

          <Button
            className={'cms-switch-button upload-file-button'}
            type={'default'}
            shape={'round'}
            onClick={() => setShowLibrary(true)}
            icon={IconUtils.component.upload}
          >
            {t('component.actions.upload_file')}
          </Button>
        </ContentViewerEditableStyle>
      );
    } else {
      const handleSwapMode = () => {
        setEditType(editType === 'simple' ? 'editor' : 'simple');
      };

      return (
        <ContentViewerEditableStyle
          className={'content-viewer-editable'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {editType === 'simple' ? (
            <TextAreaEditable
              initValue={props.contentData.data}
              onChange={handleOnChange}
            />
          ) : (
            <ContentEditor
              onChange={handleOnChange}
              initValue={props.contentData.data}
            />
          )}

          {proUser && (
            <Button
              className={'cms-switch-button switch-text'}
              type={'default'}
              size={'small'}
              shape={'circle'}
              onClick={handleSwapMode}
              icon={IconUtils.component.switch_to_other}
            />
          )}
        </ContentViewerEditableStyle>
      );
    }
  } else {
    return <ContentViewer contentData={props.contentData} />;
  }
};

export const ContentViewer = (props: {
  contentData: AssetsRes;
  // trigger event for play audio, play video, view pdf, view doc....
  onChange?: (value: any) => void;
}) => {
  switch (props.contentData.type) {
    case AssetsTypeEnum.AUDIO:
      return (
        <AudioPlayer
          audioSrc={props.contentData.data}
          title={props.contentData.name}
          onChange={props.onChange}
        />
      );

    case AssetsTypeEnum.VIDEO:
      return (
        <VideoPlayer
          videoSrc={props.contentData.data}
          title={props.contentData.name}
          onChange={props.onChange}
        />
      );

    case AssetsTypeEnum.IMAGE:
      return (
        <CmsImageViewer
          contentData={props.contentData as ImageContentProps}
          width={props.contentData.width}
          align={props.contentData.align}
        />
      );

    case AssetsTypeEnum.PDF:
      return (
        <PDFViewer
          pdfSrc={props.contentData.data}
          title={props.contentData.name}
        />
      );

    case AssetsTypeEnum.EPUB:
      return (
        <EpubViewer
          fileUrl={props.contentData.data}
          title={props.contentData.name}
        />
      );

    case AssetsTypeEnum.DOC:
      return (
        <DocViewerComp
          fileUrl={props.contentData.data}
          title={props.contentData.name}
        />
      );

    default:
      return (
        <span dangerouslySetInnerHTML={{ __html: props.contentData.data }} />
      );
  }
};

export const ContentAction = (props: {
  type?: AssetsTypeEnum;
  disabled?: { addOption: boolean; removeOption: boolean };
  actions?: ContentActionEnum[];
  operations?: ContentOperationEnum[];

  onChangeType?: (newType: AssetsTypeEnum) => void;
  onClick?: (operation: ContentOperationEnum) => void;
  children: any;
}) => {
  const { t } = useTranslation();

  const handleOnClick = (operation: ContentOperationEnum) => {
    if (props.onClick) {
      props.onClick(operation);
    }
  };

  const handleChangeType = (newType: AssetsTypeEnum) => {
    if (props.onChangeType) {
      props.onChangeType(newType);
    }
  };

  const getContentActionMenu = (
    action: ContentActionEnum,
    onClick: (type: AssetsTypeEnum) => void,
    type?: AssetsTypeEnum
  ) => {
    if (action === ContentActionEnum.CHANGE_TO_TEXT) {
      return {
        key: AssetsTypeEnum.TEXT,
        disabled: type === AssetsTypeEnum.TEXT,
        onClick: () => onClick(AssetsTypeEnum.TEXT),
        label: (
          <>
            {IconUtils.component.change_to_text}{' '}
            {t('content.actions.change_to_text')}
          </>
        ),
      };
    } else if (action === ContentActionEnum.CHANGE_TO_IMAGE) {
      return {
        key: AssetsTypeEnum.IMAGE,
        disabled: type === AssetsTypeEnum.IMAGE,
        onClick: () => onClick(AssetsTypeEnum.IMAGE),
        label: (
          <>
            {IconUtils.component.change_to_image}{' '}
            {t('content.actions.change_to_image')}
          </>
        ),
      };
    } else if (action === ContentActionEnum.CHANGE_TO_AUDIO) {
      return {
        key: AssetsTypeEnum.AUDIO,
        disabled: type === AssetsTypeEnum.AUDIO,
        onClick: () => onClick(AssetsTypeEnum.AUDIO),
        label: (
          <>
            {IconUtils.component.change_to_audio}{' '}
            {t('content.actions.change_to_audio')}
          </>
        ),
      };
    } else if (action === ContentActionEnum.CHANGE_TO_VIDEO) {
      return {
        key: AssetsTypeEnum.VIDEO,
        disabled: type === AssetsTypeEnum.VIDEO,
        onClick: () => onClick(AssetsTypeEnum.VIDEO),
        label: (
          <>
            {IconUtils.component.change_to_video}{' '}
            {t('content.actions.change_to_video')}
          </>
        ),
      };
    } else {
      return null;
    }
  };

  const getContentOperationMenu = (
    operation: ContentOperationEnum,
    onClick: (action: ContentOperationEnum) => void
  ) => {
    if (operation === ContentOperationEnum.ADD) {
      return {
        key: 'add-more',
        onClick: () => onClick(operation),
        disabled: props.disabled != null && props.disabled.addOption,
        label: (
          <>
            {IconUtils.component.add_more} {t('content.actions.add_more')}
          </>
        ),
      };
    } else if (operation === ContentOperationEnum.REMOVE) {
      return {
        key: 'remove',
        onClick: () => onClick(operation),
        disabled: props.disabled != null && props.disabled.removeOption,
        label: (
          <>
            {IconUtils.component.remove} {t('content.actions.remove')}
          </>
        ),
      };
    } else {
      return null;
    }
  };

  const menuItems = useMemo(() => {
    const menuOptions = [];
    if (props.actions && props.actions.length > 1) {
      props.actions.forEach((act) => {
        menuOptions.push(
          getContentActionMenu(act, handleChangeType, props.type)
        );
      });
    }

    if (menuOptions.length > 0) {
      menuOptions.push({ key: 'separator', type: 'divider' });
    }

    if (props.operations) {
      props.operations.forEach((op) => {
        menuOptions.push(getContentOperationMenu(op, handleOnClick));
      });
    }
    return menuOptions;
  }, [props.operations, props.actions, props.type]);

  return (
    <ContentActionStyle className={'cms-content-actions'}>
      <div className={'cms-content-display'}>{props.children}</div>

      <div className={'cms-content-action'}>
        <Dropdown
          menu={{ items: menuItems }}
          placement="bottomRight"
          trigger={['click']}
          arrow
        >
          <Button
            className={'cms-content-action-btn'}
            type={'default'}
            shape={'circle'}
            size={'small'}
            icon={IconUtils.more_icon}
          />
        </Dropdown>
      </div>
    </ContentActionStyle>
  );
};

const ContentActionStyle = styled.div`
  display: flex;
  align-items: center;

  .cms-content-display {
    flex-grow: 1;
  }

  .cms-content-action {
    margin-left: 0.25em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn {
    font-weight: bold;
  }

  .cms-content-action-btn {
    border-color: ${(props) => props.theme.app.primary};
    color: ${(props) => props.theme.app.primary};
  }
`;

const ContentViewerEditableStyle = styled.div`
  position: relative;
  width: 100%;

  .cms-switch-button {
    &.switch-text {
      position: absolute;
      bottom: 2px;
      right: 2px;
    }

    &.upload-file-button {
      margin-top: 0.5em;
    }
  }

  .content-viewer-actions {
    display: flex;
    justify-content: right;
  }

  .cms-switch-button {
    border-color: ${(props) => props.theme.component.primary};
    color: ${(props) => props.theme.component.primary};
  }

  [contenteditable='true'] {
    background: rgba(0, 0, 0, 0.03) !important;
    border-radius: 6px;
    padding: 0px 24px 0px 6px;
    min-width: 6em;

    &:focus,
    &:hover {
      border: none;
      background: rgba(0, 0, 0, 0.15) !important;
      color: ${(props) => props.theme.color.black};
    }
  }
`;
