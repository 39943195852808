import { useTranslation } from 'react-i18next';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { Button, Progress } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { H5 } from '../typography';
import { ProductItemStyle } from './style';
import { ViewProductTrackingRes } from '@services/reports/ProgressReport';
import { SiteMap } from '@router/SiteMap';
import NavLink from '@components/button/NavLink';
import { InputGroup } from '@components/input/InputGroup';
import { DateLabel } from '@components/text/DateLabel';
import ButtonGroup from '@components/button/ButtonGroup';
import { ProductLicenseRes } from '@modules/license/services/model';

export const ProductLicenseItem = (props: { item: ProductLicenseRes }) => {
  const { t } = useTranslation();

  return (
    <ProductItemStyle className={`product-student-item`}>
      <div className={'product-info'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.product.icon}
          ratio={ImageScale.product.ratio}
        />
      </div>

      <div className={'product-title'}>
        <H5>{props.item.product.name}</H5>

        <InputGroup label={t('label.expired_date')}>
          <DateLabel label={props.item.expiredDate} />
        </InputGroup>

        <div className={'button-group'} style={{ marginTop: '1em' }}>
          <ButtonGroup type={'right'}>
            <NavLink
              href={SiteMap.private.my_library.review_product_gen(
                props.item.product.productId
              )}
            >
              <Button
                shape={'round'}
                type={'primary'}
                icon={IconUtils.actions.view_info}
              >
                {t('product.actions.review')}
              </Button>
            </NavLink>
          </ButtonGroup>
        </div>
      </div>
    </ProductItemStyle>
  );
};

export const ProductProgressItem = (props: {
  item: ProductLicenseRes;
  progress?: ViewProductTrackingRes;
}) => {
  const { t } = useTranslation();

  return (
    <ProductItemStyle className={`product-student-item`}>
      <div className={'product-info'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.product.icon}
          ratio={ImageScale.product.ratio}
        />
      </div>

      <div className={'product-title'}>
        <H5>{props.item.product.name}</H5>

        <InputGroup label={t('label.expired_date')}>
          <DateLabel label={props.item.expiredDate} />
        </InputGroup>

        {props.progress && (
          <Progress percent={props.progress.progress} format={() => ``} />
        )}

        <div className={'button-group'} style={{ marginTop: '1em' }}>
          <NavLink
            href={SiteMap.private.my_library.view_product_gen(
              props.item.product.productId
            )}
          >
            <Button
              shape={'round'}
              type={'primary'}
              icon={IconUtils.actions.start_exercise}
            >
              {t('lesson.actions.practice')}
            </Button>
          </NavLink>
        </div>
      </div>
    </ProductItemStyle>
  );
};
