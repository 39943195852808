import React, { useContext } from 'react';
import { PublishProductStyle } from '@modules/product/container/publish/ProductInformationStep';
import ScrollToTop from '@components/feature/ScrollToTop';
import { ReviewProductContext } from '@modules/product/context/BuildProductContext';
import ProductMetadata from '@modules/product/components/product/ProductMetadata';
import { useTranslation } from 'react-i18next';
import { H2 } from '@components/typography';

export default function ProductIntroduceStep(props: {
  goPrevious: () => void;
  goNext: () => void;
}) {
  const { t } = useTranslation();
  const { productId } = useContext(ReviewProductContext);

  return (
    <>
      <ScrollToTop visible={false} />

      <PublishProductStyle className={'publish-product-section'}>
        <H2>{t('publish.basic_info.introduction')}</H2>

        <div className={'product-publish-content'}>
          <ProductMetadata
            key={productId}
            type={'publish'}
            productId={productId}
            onSuccess={() => props.goNext()}
            onCancel={() => props.goPrevious()}
          />
        </div>
      </PublishProductStyle>
    </>
  );
}
