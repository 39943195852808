import styled from 'styled-components';

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5em;
  margin-bottom: 1em;

  h1,
  h2,
  h3,
  h4 {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 0px;

    button {
      margin-left: 1em;
    }
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.25em;
  }

  .button-group {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  }

  .primary-text {
    color: ${(props) => props.theme.app.primary};
  }

  .default-text {
    font-weight: 100;
  }
`;
