import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Button, Divider, notification, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import ContentEditor from '@components/editor/WysyEditor';
import KeywordInput from '@components/input/KeywordInput';

import {
  CourseAPI,
  CourseRes,
  UpdateCourseMetadataReq,
} from '@services/private/CourseAPI';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import { ImageScale } from '@utils/ImageScale';
import Card from '@components/card';
import YoutubeInput from '@components/input/YoutubeInput';
import { IconUtils } from '@utils/IconUtils';

const initData = {
  courseId: -1,
  name: '',
  description: '',
  video: '',
  thumbnails: '',
};

export default function CourseMetadata(props: {
  type: 'publish' | 'edit';
  courseId: number;
  onCancel: () => void;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();

  const [getCourse, { data, isSuccess, isFetching }] =
    CourseAPI.endpoints.getCourse.useLazyQuery({});
  const [updateMetadata, { isLoading }] =
    CourseAPI.endpoints.updateMetadata.useMutation({});

  const [ready, setReady] = useState(false);

  const [introduce, setIntroduce] = useState('');
  const [shortIntroduce, setShortIntroduce] = useState('');
  const [keywords, setListKeywords] = useState<string[]>([]);

  useEffect(() => {
    setReady(false);
    getCourse(props.courseId);
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      updateCourseData(data);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initData,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request: UpdateCourseMetadataReq = {
        courseId: values.courseId,
        name: values.name,

        keyword: keywords.join(','),
        description: values.description,

        instruction: introduce,
        shortInstruction: shortIntroduce,

        video: values.video,
        thumbnails: values.thumbnails,
      };

      updateMetadata(request)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('course.warning.update_success'),
            description: t('course.warning.update_success_message'),
            placement: 'bottomRight',
          });

          if (props.onSuccess) {
            props.onSuccess();
          }
        })
        .catch(() => {
          notification.error({
            message: t('course.warning.update_error'),
            description: t('course.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  const updateCourseData = (data: CourseRes) => {
    formik.setValues({
      courseId: data.courseId,
      name: data.name,
      description: data.description,
      thumbnails: data.thumbnails,
      video: data.video,
    });

    setIntroduce(data.introduce);
    setShortIntroduce(data.shortIntroduce);

    setListKeywords(
      data.keyword != null && data.keyword.trim() !== ''
        ? data.keyword.split(',')
        : []
    );
    setReady(true);
  };

  return (
    <>
      <div className={'update-course-info'}>
        <FormikProvider value={formik}>
          {!ready && (
            <>
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
            </>
          )}

          {ready && (
            <Card>
              <Input
                required={true}
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <InputGroup label={t('label.thumbnails')}>
                <SingleUpload
                  scale={ImageScale.scale169.scale}
                  accept={'image/jpg, image/jpeg, image/png'}
                  multiple={false}
                  onSuccessFunc={(val) =>
                    formik.setFieldValue('thumbnails', val)
                  }
                >
                  <IconImage
                    src={formik.values.thumbnails}
                    width={ImageScale.scale169.width}
                    height={ImageScale.scale169.height}
                  />
                </SingleUpload>
              </InputGroup>

              <YoutubeInput
                label={t('label.introduce_video')}
                name="video"
                onChange={formik.handleChange}
                value={formik.values.video}
              />

              <InputGroup label={t('label.keyword')}>
                <KeywordInput
                  keywords={data ? data.keyword : ''}
                  placeholder={t('label.enter_keyword')}
                  enterButton={t('label.add')}
                  size={'large'}
                  onKeywordChange={(values) => setListKeywords(values)}
                />
              </InputGroup>

              <InputGroup label={t('label.description')}>
                <Input
                  type={'textarea'}
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  maxLength={512}
                  style={{ minHeight: 120 }}
                />
              </InputGroup>

              <Divider />

              <InputGroup label={t('label.instruction')}>
                <ContentEditor
                  initValue={data ? data.introduce : ''}
                  onChange={(value: string) => setIntroduce(value)}
                />
              </InputGroup>

              <InputGroup label={t('label.short_instruction')}>
                <ContentEditor
                  initValue={data ? data.shortIntroduce : ''}
                  onChange={(value: string) => setShortIntroduce(value)}
                />
              </InputGroup>
            </Card>
          )}

          {props.type === 'publish' && (
            <div className={'course-publish-step'}>
              <Button
                type={'default'}
                shape="round"
                onClick={props.onCancel}
                icon={IconUtils.actions.previous}
              >
                {t('button.step.previous')}
              </Button>

              <Button
                type={'primary'}
                shape="round"
                loading={isLoading || isFetching}
                onClick={formik.submitForm}
                icon={IconUtils.actions.next}
              >
                {t('button.step.next')}
              </Button>
            </div>
          )}

          {props.type === 'edit' && (
            <div className="submit-container " style={{ marginTop: '5em' }}>
              <ButtonGroup>
                <Button
                  type={'primary'}
                  loading={isLoading || isFetching}
                  onClick={formik.submitForm}
                  icon={IconUtils.actions.save}
                >
                  {t('button.update')}
                </Button>

                <Button type="default" onClick={props.onCancel}>
                  {t('button.cancel')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </FormikProvider>
      </div>
    </>
  );
}
