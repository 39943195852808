import React from 'react';
import { StudentInfoSimple } from '@components/info/StudentInfo';
import { Select } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';

export const ExerciseFilter = (props: {
  name: string;
  value: number;
  items: ExerciseRes[];
  onChange: (exerciseId: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <InputGroup label={t('select.select_a_student')}>
      <Select size={'large'} onChange={props.onChange} value={props.value}>
        <Select.Option key={-1} value={-1}>
          {t('select.select_a_student')}
        </Select.Option>

        {props.items.map((op) => {
          return (
            <Select.Option key={op.exerciseId} value={op.exerciseId}>
              <StudentInfoSimple item={op.student!} />
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};
