import React, { useEffect, useRef, useState } from 'react';
import { HTMLUtils } from '@cms/utils/HtmlUtils';
import styled from 'styled-components';
import { OperationUtils } from '@cms/comps/math/operation/OperationUtils';
import { StringUtils } from '@utils/StringUtils';

export const InputNumberComp = (props: {
  className?: string;
  digit: string;
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
}) => {
  const contentRef = useRef<any>(null);
  const [answer, setAnswer] = useState({
    value:
      props.value === OperationUtils.VARIABLE_CHARACTER
        ? ''
        : HTMLUtils.formatTheInputNumber(props.value),
    triggerChange: false,
  });
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setAnswer({
      value:
        props.value === OperationUtils.VARIABLE_CHARACTER
          ? ''
          : HTMLUtils.formatTheInputNumber(props.value),
      triggerChange: false,
    });
  }, [props.value, contentRef.current]);

  useEffect(() => {
    if (answer.triggerChange) {
      props.onChange(StringUtils.replaceAll(answer.value, ',', ''));
    }
  }, [answer]);

  const handleChange = (value: string, triggerChange: boolean) => {
    setAnswer({
      value: HTMLUtils.formatTheInputNumber(value),
      triggerChange: triggerChange,
    });
  };

  const pasteAsPlainText = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.offsetWidth);
    }
  }, [answer.value, contentRef]);

  return (
    <FibNumberStyle className={props.className}>
      {props.disabled ? (
        <span
          className={'input-field'}
          dangerouslySetInnerHTML={{ __html: answer.value + ' ' }}
        />
      ) : (
        <>
          <input
            style={{ width }}
            className={'input-field'}
            value={answer.value}
            onKeyDown={HTMLUtils.validateNumber}
            onChange={(evt) => handleChange(evt.target.value, false)}
            onBlur={(evt) => handleChange(evt.target.value, true)}
            onPaste={pasteAsPlainText}
          />

          <span className={'input-field template'} ref={contentRef}>
            {answer.value}
          </span>
        </>
      )}
    </FibNumberStyle>
  );
};

const FibNumberStyle = styled.div`
  display: inline-flex;

  input.input-field {
    min-height: 1.6em;
    min-width: 2em;
  }

  span.input-field {
    min-height: 1.6em;
    min-width: 2em;
  }

  .input-field {
    display: inline-block;
    padding: 0em 0.25em;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 0.25em;
    outline: none !important;
    font-weight: 600;
    text-align: center;

    &.template {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }

    &:focus {
      //border: none !important;
      outline: none !important;
    }

    .show-correct-answer & {
      background: ${(props) => props.theme.component.primary_bgr}; // #e2d8f9;
      color: ${(props) => props.theme.component.correct_answer};
    }
  }
`;
