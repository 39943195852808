import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceRes } from '@modules/product/services/resource_model';
import { LessonSectionRes } from '@modules/product/services/lesson_model';

const getResourceData = (
  resource: ResourceRes[],
  group: string,
  sectionId: number
) => {
  return resource.map((item) => {
    return ResourceUtils.convert(item, group, sectionId);
  });
};

export class SectionSupportUtils {
  convert = (data: LessonSectionRes): LessonSectionProps => {
    const headerRes: ResourceProps[] = getResourceData(
      data.header,
      'header',
      data.lessonSectionId
    );
    const contentRes: ResourceProps[] = getResourceData(
      data.content,
      'content',
      data.lessonSectionId
    );
    const footerRes: ResourceProps[] = getResourceData(
      data.footer,
      'footer',
      data.lessonSectionId
    );

    return {
      lessonSectionId: data.lessonSectionId,
      code: data.code,
      name: data.name,
      type: data.type,
      setting: data.setting ? JSON.parse(data.setting) : {},
      content: data.data ? JSON.parse(data.data) : null,
      group: {
        header: headerRes,
        content: contentRes,
        footer: footerRes,
      },
    };
  };

  convertSection = (
    data: LessonSectionRes,
    questionNumber: number
  ): {
    section: LessonSectionProps;
    totalQuestion: number;
  } => {
    const headerRes: ResourceProps[] = getResourceData(
      data.header,
      'header',
      data.lessonSectionId
    );
    const contentRes: ResourceProps[] = getResourceData(
      data.content,
      'content',
      data.lessonSectionId
    );
    const footerRes: ResourceProps[] = getResourceData(
      data.footer,
      'footer',
      data.lessonSectionId
    );

    let qNumb = questionNumber;
    let totalQ = 0;

    const header = headerRes.map((rs) => {
      if (rs.validation && rs.validation.interact) {
        const resource = { ...rs, questionNumber: qNumb };
        qNumb += 1;
        totalQ += 1;
        return resource;
      } else {
        return rs;
      }
    });

    const content = contentRes.map((rs) => {
      if (rs.validation && rs.validation.interact) {
        const resource = { ...rs, questionNumber: qNumb };
        qNumb += 1;
        totalQ += 1;
        return resource;
      } else {
        return rs;
      }
    });

    const footer = footerRes.map((rs) => {
      if (rs.validation && rs.validation.interact) {
        const resource = { ...rs, questionNumber: qNumb };
        qNumb += 1;
        totalQ += 1;
        return resource;
      } else {
        return rs;
      }
    });

    return {
      section: {
        lessonSectionId: data.lessonSectionId,
        code: data.code,
        name: data.name,
        type: data.type,
        setting: data.setting ? JSON.parse(data.setting) : {},
        content: data.data ? JSON.parse(data.data) : null,
        group: {
          header: header,
          content: content,
          footer: footer,
        },
      },
      totalQuestion: totalQ,
    };
  };

  getFirstResourceInSection = (
    section: LessonSectionRes
  ): ResourceRes | null => {
    let firstResource = null;

    if (section.header != null && section.header.length > 0) {
      firstResource = section.header[0];
    } else if (section.content != null && section.content.length > 0) {
      firstResource = section.content[0];
    } else if (section.footer != null && section.footer.length > 0) {
      firstResource = section.footer[0];
    }
    return firstResource;
  };

  getFirstSection = (
    sections: LessonSectionProps[],
    resourceId: number
  ): LessonSectionProps | null => {
    let result: LessonSectionProps | null = null;
    let matched = false;

    sections.forEach((sec) => {
      if (result == null) {
        if (!matched && sec.group.header != null) {
          matched = sec.group.header.some((rs) => {
            return rs.resourceId === resourceId;
          });
        }

        if (!matched && sec.group.content != null) {
          matched = sec.group.content.some((rs) => {
            return rs.resourceId === resourceId;
          });
        }

        if (!matched && sec.group.footer != null) {
          matched = sec.group.footer.some((rs) => {
            return rs.resourceId === resourceId;
          });
        }

        if (matched) {
          result = sec;
        }
      }
    });

    return result;
  };

  getTotalResourceInSection = (section: LessonSectionRes): number => {
    let total = 0;
    if (section.header != null && section.header.length > 0) {
      total += section.header.length;
    }

    if (section.content != null && section.content.length > 0) {
      total += section.content.length;
    }

    if (section.footer != null && section.footer.length > 0) {
      total += section.footer.length;
    }
    return total;
  };
}

const SectionUtils = new SectionSupportUtils();
export default SectionUtils;
