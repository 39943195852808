import { useTranslation } from 'react-i18next';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import Button from '@components/button';
import styled from 'styled-components';
import SelectProductHasLicense from '@modules/common/components/SelectProductHasLicense';

export const AddProductToClassForm = (props: {
  classId: number;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const [addResource] = ClassAPI.endpoints.addResource.useMutation({});

  const [selectProductId, setSelectProductId] = useState(-1);

  const onSelectProduct = (productId: number) => {
    setSelectProductId(productId);
  };

  const handleOnAddLicense = () => {
    addResource({
      productId: selectProductId,
      classId: props.classId,
    })
      .unwrap()
      .then((res) => {
        props.onSuccess();
      })
      .catch(() => {
        // add warning message....
      });
  };

  return (
    <AddProductToClassFormStyle className={'add-resource-to-class-form'}>
      <Row gutter={[Default_Gutter, Default_Gutter]} align={'bottom'}>
        <Col flex={'auto'}>
          <SelectProductHasLicense
            label={t('select.select_a_product')}
            placeholder={t('select.select_a_product')}
            name={'productId'}
            value={selectProductId}
            onChange={onSelectProduct}
          />
        </Col>

        <Col flex={'150px'}>
          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            block
            onClick={handleOnAddLicense}
          >
            Add Product
          </Button>
        </Col>
      </Row>
    </AddProductToClassFormStyle>
  );
};

const AddProductToClassFormStyle = styled.div`
  margin-bottom: 1.5em;

  .form-group {
    margin-bottom: 0;
  }
`;
