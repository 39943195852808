import { UserProfileRes } from '@services/private/UserProfileAPI';
import { FaultTolerantImg } from '@components/public/FaultTolerantImg';
import { Card } from 'antd';
import React from 'react';
import NavLink from '@components/button/NavLink';
import {
  ClockCircleOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { SiteMap } from '@router/SiteMap';

const { Meta } = Card;

export const TeacherProfile = (props: { item: UserProfileRes }) => {
  return (
    <Card
      hoverable
      style={{ width: '100%' }}
      cover={
        <FaultTolerantImg
          width={'100%'}
          height={'100%'}
          src={props.item.avatarUrl}
          alt={props.item.shortIntroduce}
        />
      }
      actions={[
        <ClockCircleOutlined key="sessions" />,
        <UsergroupAddOutlined key={'students'} />,
        <EllipsisOutlined key="ellipsis" />,
      ]}
    >
      <NavLink href={SiteMap.public.teacher_view_gen(props.item.name)}>
        <Meta
          title={<h3>{props.item.name}</h3>}
          description={
            <div
              className={'school-introduction'}
              dangerouslySetInnerHTML={{ __html: props.item.shortIntroduce }}
            />
          }
        />
      </NavLink>
    </Card>
  );
};
