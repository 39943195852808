import React, { useCallback, useEffect, useState } from 'react';
import { ProductLicenseAPI } from '@modules/license/services/ProductLicenseAPI';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import Card, { CardClassEnum } from '@components/card';
import { DateLabel } from '@components/text/DateLabel';
import { ResponsiveScreen } from '@hooks/useMedia';
import TablePagination from '@components/table/TablePagination';
import { IconUtils } from '@utils/IconUtils';
import Button from 'antd/lib/button';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import ViewProductInfo from '@cms/resource-bank/ViewProduct';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { AssignProductLicense } from '@modules/license/components/AssignProductLicense';
import { InputGroup } from '@components/input/InputGroup';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import styled from 'styled-components';
import { FilterProductLicenseReq, ProductLicenseRes } from '../services/model';

enum AssignBookLicenseActionEnum {
  default = '',
  assign_license = 'assign-license',
}

export const AssignBookLicensePage = (props: { productId: number }) => {
  const { t } = useTranslation();

  const [getProductLicense, { data, isFetching }] =
    ProductLicenseAPI.endpoints.getProductLicense.useLazyQuery({});

  const [action, setAction] = useState(AssignBookLicenseActionEnum.default);

  const [params, setParams] = useState<FilterProductLicenseReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'expiredDate',
    direction: 'ascend',
    productId: props.productId,
  });

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'forUser',
      key: 'forUser',
      sorter: true,
    },

    {
      title: t('label.available_date'),
      dataIndex: 'availableDate',
      key: 'availableDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.expired_date'),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 120,
    },
  ];

  useEffect(() => {
    refreshPage();
  }, [params]);

  const refreshPage = useCallback(() => {
    getProductLicense(params);
  }, [params]);

  const renderMobile = (item: ProductLicenseRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <UserProductStyle className={'user-product-license-item'}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col flex={'150px'}>
              <UserThumbnailDisplay
                layout={'column'}
                size={72}
                item={item.user}
              />
            </Col>

            <Col flex={'auto'}>
              <InputGroup label={t('label.available_date')}>
                <DateLabel label={item.availableDate} />
              </InputGroup>

              <InputGroup label={t('label.expired_date')}>
                <DateLabel label={item.expiredDate} />
              </InputGroup>
            </Col>
          </Row>

          <div className={'user-product-actions'}>...</div>
        </UserProductStyle>
      </Card>
    );
  };

  const processDataRow = (item: ProductLicenseRes) => {
    return {
      key: item.productLicenseId,
      forUser: <UserThumbnailDisplay size={60} item={item.user} />,
      availableDate: <DateLabel label={item.availableDate} />,
      expiredDate: <DateLabel label={item.expiredDate} />,
      actions: <>...</>,
    };
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...prev, ...params };
    });
  };

  const handleOnSuccess = () => {
    setAction(AssignBookLicenseActionEnum.default);
    refreshPage();
  };

  const onAddLicense = () => {
    setAction(AssignBookLicenseActionEnum.assign_license);
  };

  const onCancel = () => {
    setAction(AssignBookLicenseActionEnum.default);
  };

  return (
    <div className={'assign-book-license-page'}>
      <HeaderGroup className="header-group">
        <H1>
          <ViewProductInfo size={64} productId={props.productId} />
          &nbsp;License
        </H1>

        <Button
          type={'primary'}
          shape={'round'}
          icon={IconUtils.menu.license}
          onClick={onAddLicense}
        >
          {t('license.actions.assign_license')}
        </Button>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action === AssignBookLicenseActionEnum.assign_license && (
        <CustomModal
          header={t('license.actions.assign_license')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <AssignProductLicense
              productId={props.productId}
              onSuccess={handleOnSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}
    </div>
  );
};

const UserProductStyle = styled.div`
  position: relative;

  .user-n-thumbnails {
    display: flex;
    align-items: center;
    justify-content: center;

    .user-display-name {
      word-break: break-word;
    }
  }

  .user-product-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
