import React from 'react';

import { RoleEnum } from '@app/redux/slices/roles';
import { useSelector } from '@app/redux/hook';

interface IAuthorizationType {
  type: 'ifNotGranted' | 'ifAllGranted' | 'ifAnyGranted';
  roles: RoleEnum[];
  children: any;
}

export function useSecurity() {
  const { authorities } = useSelector((state) => state.profile);

  const ifAnyGranted = (roles: RoleEnum[]) => {
    let exits = false;

    if (authorities != null) {
      roles.forEach((role: RoleEnum) => {
        if (authorities.indexOf(role) > -1) {
          exits = true;
        }
      });
    }
    return exits;
  };

  const ifAllGranted = (roles: RoleEnum[]) => {
    let exits = true;

    roles.forEach((role: RoleEnum) => {
      if (authorities != null && authorities.indexOf(role) < 0) {
        exits = false;
      }
    });

    return exits;
  };

  const ifNotGranted = (roles: RoleEnum[]) => {
    let exits = false;

    if (authorities != null) {
      roles.forEach((role: RoleEnum) => {
        if (authorities.indexOf(role) > -1) {
          exits = true;
        }
      });
    }
    return !exits;
  };

  // const accessClass = () => {
  //   const canAccess = ifAnyGranted([
  //     RoleEnum.STUDENT,
  //     RoleEnum.PARENT,
  //     RoleEnum.TEACHER,
  //   ]);
  //   const canCreateOrEdit = ifAnyGranted([
  //     RoleEnum.SUPPORT,
  //     RoleEnum.SCHOOL,
  //     RoleEnum.MANAGE_CLASS,
  //   ]);
  //
  //   return {
  //     canAccess: canAccess,
  //     canCreateOrEdit: canCreateOrEdit,
  //   };
  // };
  //
  // const accessAssignment = () => {
  //   const canAccess = ifAnyGranted([RoleEnum.STUDENT, RoleEnum.PARENT]);
  //   const canCreateOrEdit = ifAnyGranted([
  //     RoleEnum.SUPPORT,
  //     RoleEnum.SCHOOL,
  //     RoleEnum.TEACHER,
  //   ]);
  //
  //   return {
  //     canAccess: canAccess,
  //     canCreateOrEdit: canCreateOrEdit,
  //   };
  // };
  //
  // const accessSession = () => {
  //   const canAccess = ifAnyGranted([RoleEnum.STUDENT, RoleEnum.PARENT]);
  //   const canCreateOrEdit = ifAnyGranted([
  //     RoleEnum.SUPPORT,
  //     RoleEnum.SCHOOL,
  //     RoleEnum.CREATE_CALENDAR,
  //   ]);
  // };
  //
  // const accessLicense = () => {
  //   const canAccess = ifAnyGranted([RoleEnum.STUDENT, RoleEnum.PARENT]);
  //   const canCreateOrEdit = ifAnyGranted([
  //     RoleEnum.SUPPORT,
  //     RoleEnum.SCHOOL,
  //     RoleEnum.CREATE_CALENDAR,
  //   ]);
  // };

  return {
    ifAnyGranted,
    ifAllGranted,
    ifNotGranted,
    authorities,
    // accessClass,
    // accessAssignment,
    // accessSession,
  };
}

const Authorization = (props: IAuthorizationType) => {
  const { authorities } = useSelector((state) => state.profile);

  const ifAnyGranted = (roles: RoleEnum[]) => {
    let exits = false;

    roles.forEach((role: RoleEnum) => {
      if (authorities != null && authorities.includes(role)) {
        exits = true;
      }
    });

    return exits;
  };

  const ifAllGranted = (roles: RoleEnum[]) => {
    let exits = true;

    roles.forEach((role: RoleEnum) => {
      if (authorities != null && !authorities.includes(role)) {
        exits = false;
      }
    });
    return exits;
  };

  const ifNotGranted = (roles: RoleEnum[]) => {
    let exits = false;

    roles.forEach((role: RoleEnum) => {
      if (authorities != null && authorities.includes(role)) {
        exits = true;
      }
    });

    return !exits;
  };

  if (props.type === 'ifNotGranted' && ifNotGranted(props.roles)) {
    return <>{props.children}</>;
  } else if (props.type === 'ifAllGranted' && ifAllGranted(props.roles)) {
    return <>{props.children}</>;
  } else if (props.type === 'ifAnyGranted' && ifAnyGranted(props.roles)) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export default Authorization;
