import { DoubleLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useTranslation } from 'react-i18next';
import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';

export const PreviousSectionButton = () => {
  const { t } = useTranslation();

  const { config } = useLessonTemplateContext();
  const { section, updateNavigation } = useLessonNavigationContext();
  const { triggerBeforeNavigate } = useViewLessonContext();

  const handlePreviousSection = () => {
    triggerBeforeNavigate();
    updateNavigation(LessonNavigationAction.PREVIOUS_SECTION, {
      sectionId: section.previousSectionId,
      type: LessonNavigationType.last,
    });
  };

  if (config.layout === LessonTemplateLayoutEnum.presentation) {
    return (
      <LessonButton
        shape={'circle'}
        size={'large'}
        className={'previous-section-button'}
        onClick={handlePreviousSection}
        disabled={section.previousSectionId < 0}
        icon={<DoubleLeftOutlined />}
      />
    );
  } else {
    return (
      <LessonButton
        className={'previous-section-button'}
        type={'primary'}
        shape={'round'}
        onClick={handlePreviousSection}
        disabled={section.previousSectionId < 0}
      >
        <DoubleLeftOutlined /> {t('exercise.button.previous')}
      </LessonButton>
    );
  }
};
