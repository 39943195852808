import { Space } from 'antd';
import React from 'react';
import { CompleteExerciseButton } from '../buttons/exercise/CompleteExerciseButton';

export const DoAssignmentCompleteBtn = () => {
  return (
    <Space align={'end'} size={10}>
      <CompleteExerciseButton />
    </Space>
  );
};
