import { GameUtils } from '@cms/comps/game/GameUtils';
import React, { useEffect, useRef, useState } from 'react';
import { GridWordProps } from '@cms/comps/game/word-search/WordSearchUtils';
import styled from 'styled-components';
import { CompMode } from '@cms/ComponentInteface';
import { WordPuzzleSettingProps } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { useWordPuzzleContext } from '@cms/comps/game/word-puzzle/WordPuzzleCompProvider';

const WordPuzzleWordBankStyle = styled.div`
  margin: -0.125em;

  .word-search-row {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export interface WordPuzzleData {
  id: string;
  word: string;
  questionNumber: number;
  offset?: { row: number; column: number };
}

const WordPuzzleWordBank = (props: {
  placeWordAtIndex: (box: GridWordProps) => void;
  setting: WordPuzzleSettingProps;
}) => {
  const { mode } = useResourceContext();
  const { gridWords } = useWordPuzzleContext();

  return (
    <WordPuzzleWordBankStyle className={'word-puzzle-word-bank'}>
      {gridWords.length > 0 && (
        <>
          {mode === CompMode.COMPOSE && (
            <div className={'word-search-row'} key={'words-row-empty-row-0'}>
              <WordPuzzleCharacterStyle
                className={'word-search-column place-holder'}
              >
                <span>&nbsp;</span>
              </WordPuzzleCharacterStyle>

              {gridWords[0].map((column, index) => {
                return (
                  <WordPuzzleCharacterStyle
                    className={'word-search-column place-holder'}
                    key={`words-row-empty-row-column-${index}`}
                  >
                    <span>{index}</span>
                  </WordPuzzleCharacterStyle>
                );
              })}
            </div>
          )}

          {gridWords.map((words, row) => {
            return (
              <div className={'word-search-row'} key={'words-row-' + row}>
                {words.map((word, column) => {
                  return (
                    <React.Fragment
                      key={`${word.wordId}-word-row-${row}-words-column-${column}-${word.correct}`}
                    >
                      {mode === CompMode.COMPOSE && column === 0 && (
                        <WordPuzzleCharacterStyle
                          className={'word-search-column place-holder'}
                        >
                          <span>{row}</span>
                        </WordPuzzleCharacterStyle>
                      )}

                      <WordPuzzleCharacter
                        data={word}
                        setWordIndex={props.placeWordAtIndex}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}

          {mode === CompMode.COMPOSE && (
            <div
              className={'word-search-row'}
              key={'words-row-empty-row-edit-0'}
            >
              <WordPuzzleCharacterStyle
                className={'word-search-column place-holder'}
              >
                <span>&nbsp;</span>
              </WordPuzzleCharacterStyle>
            </div>
          )}
        </>
      )}
    </WordPuzzleWordBankStyle>
  );
};

export default WordPuzzleWordBank;

export const WordPuzzleCharacterStyle = styled.div`
  cursor: pointer;
  width: 1.6em;
  height: 1.6em;
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0.125em;
  background: #fff;

  &.place-holder {
    border: 1px solid transparent;

    span {
      font-size: 66%;
    }

    .remove-button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }

  &.empty-box {
    border: 1px solid transparent;
  }

  &.highlight-word {
    background: #ccc;
    color: ${(props) => props.theme.component.incorrect};
    font-weight: bold;
  }

  &.correct-word {
    background: ${(props) => props.theme.component.correct};
    color: ${(props) => props.theme.color.white};
  }

  &.error-word {
    background: ${(props) => props.theme.component.incorrect};
    color: ${(props) => props.theme.color.white};

    .hidden-input {
      font-size: 66%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .hidden-input {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    text-align: center;
    border: none;
    background: transparent;

    &.correct-word {
      background: ${(props) => props.theme.component.correct};
      color: ${(props) => props.theme.color.white};
    }

    &:hover,
    &:active {
      outline: none;
      border: none;
    }
  }
`;

const WordPuzzleCharacter = (props: {
  data: GridWordProps;
  setWordIndex: (data: GridWordProps) => void;
}) => {
  const { mode } = useResourceContext();
  const { selectedWord } = useWordPuzzleContext();

  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    const isHighlight =
      selectedWord != null &&
      selectedWord !== '' &&
      props.data.wordId != null &&
      props.data.wordId.includes(selectedWord);
    setHighlight(isHighlight);
  }, [selectedWord]);

  const handleOnEditPlaceOfWord = () => {
    props.setWordIndex(props.data);
  };

  if (mode === CompMode.COMPOSE) {
    return (
      <WordPuzzleCharacterStyle
        className={`word-search-column 
        ${highlight ? 'correct-word' : ''}
        ${props.data.char.length > 1 ? 'error-word' : ''}
        `}
        onClick={handleOnEditPlaceOfWord}
      >
        {props.data.char !== GameUtils.hiddenCharacter && (
          <span className={'hidden-input'}>{props.data.char}</span>
        )}
      </WordPuzzleCharacterStyle>
    );
  } else {
    if (props.data.char !== GameUtils.hiddenCharacter) {
      return (
        <WordPuzzleCharacterStyle
          className={`word-search-column 
          ${highlight ? 'highlight-word' : ''}
      `}
        >
          <WordPuzzleInput data={props.data} />
        </WordPuzzleCharacterStyle>
      );
    } else {
      return (
        <WordPuzzleCharacterStyle className={`word-search-column empty-box`} />
      );
    }
  }
};

const WordPuzzleInput = (props: { data: GridWordProps }) => {
  const inputRef = useRef<any>();

  const { currentBox, setCurrentBox, handleOnKeyPress } =
    useWordPuzzleContext();

  useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [currentBox, props.data]);

  const handleOnClick = () => {
    setCurrentBox(props.data);
  };

  if (props.data.correct) {
    return (
      <span className={`hidden-input correct-word`} onClick={handleOnClick}>
        {props.data.answer}
      </span>
    );
  } else if (currentBox != null && currentBox.id === props.data.id) {
    return (
      <input
        className={`hidden-input ${
          currentBox && currentBox.id === props.data.id ? 'focused' : ''
        }`}
        onKeyDown={handleOnKeyPress}
        defaultValue={props.data.answer}
        ref={inputRef}
      />
    );
  } else {
    return (
      <span className={`hidden-input`} onClick={handleOnClick}>
        {props.data.answer}
      </span>
    );
  }
};
