import { CircleUnderlineSettingProps } from '@cms/comps/interact/circle-underline/CircleUnderlineComp';
import React, { useRef } from 'react';
import useOnClickOutside from '@hooks/useOnClickOutside';
import Button from '@components/button';
import {
  BorderOutlined,
  CloseCircleOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

export const SelectTextAction = (props: {
  data: any;
  setting: CircleUnderlineSettingProps;
  onChange: (type: string) => void;
}) => {
  const actionRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(actionRef, () => {
    props.onChange('cancel');
  });

  return (
    <UnderlineSelectTextActionStyle
      ref={actionRef}
      className={'select-text-action'}
      style={{
        left: props.data.x + props.data.width / 2,
        top: props.data.y + props.data.height,
      }}
    >
      <span className={'select-text-arrow'} />

      {props.setting.actions.map((act) => {
        return (
          <Button
            key={act + '-' + props.data.type}
            type={act === props.data.type ? 'primary' : 'text'}
            onClick={() => props.onChange(act)}
            icon={act === 'circle' ? <BorderOutlined /> : <UnderlineOutlined />}
          />
        );
      })}

      <Button
        type={'text'}
        danger
        onClick={() => props.onChange('remove')}
        icon={<CloseCircleOutlined />}
      />
    </UnderlineSelectTextActionStyle>
  );
};

const UnderlineSelectTextActionStyle = styled.div`
  position: absolute;
  z-index: 2;
  transform: translate(-50%, 10px);
  padding: 0.25em 0.5em;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 8px;
  display: flex;
  gap: 0.25em 0.5em;

  .select-text-arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -100%);

      content: '';
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-right-width: 8px;
      border-left-width: 8px;
    }

    &:before {
      top: 0px;
      border-bottom: 8px solid #ccc;
    }

    &:after {
      top: 2px;
      border-bottom: 8px solid #fff;
    }
  }
`;
