import { MyProfileAPI } from '@services/private/MyProfileAPI';
import { useEffect } from 'react';

import { updateUserInfo } from '@app/redux/slices/userInfomationSlice';
import { useDispatch, useSelector } from '@app/redux/hook';
import { updateDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { changeTheme } from '@app/redux/slices/appLayoutSlice';

const useLoadMyProfile = (force?: boolean) => {
  const dispatch = useDispatch();

  const { defaultLanguage } = useSelector((state) => state.appLanguage);

  const { isAuth } = useSelector((state) => state.profile);
  const { isReady } = useSelector((state) => state.userInformation);

  const [getMyProfile, { data, isSuccess }] =
    MyProfileAPI.endpoints.getMyProfile.useLazyQuery();

  useEffect(() => {
    if (isAuth && (force || !isReady)) {
      getMyProfile({});
    }
  }, [isAuth, force]);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.language != null && data.language.code !== defaultLanguage) {
        dispatch(updateDefaultLanguage(data.language.code));
      }
      dispatch(changeTheme(data.theme));
      dispatch(updateUserInfo(data));
    }
  }, [data]);
};

export default useLoadMyProfile;
