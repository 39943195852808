import EditAssignmentForm from '../container/assignment/EditAssignment';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export const EditAssignmentPage = (props: { assignmentInstanceId: number }) => {
  const router = useRouter();

  const handleGoBack = () => {
    router.push(SiteMap.assignments.list);
  };

  return (
    <EditAssignmentForm
      assignmentInstanceId={props.assignmentInstanceId}
      onSuccess={handleGoBack}
      onCancel={handleGoBack}
    />
  );
};
