import React, { useState } from 'react';
import { CompGroupEnum } from '@cms/utils/CompProps';
import OptionEditable from '@cms/editable/OptionEditable';
import MatchingOption from '@cms/comps/interact/matching/MactchingOption';
import { ComponentColorEnum } from '@cms/script/ComponentUtils';
import {
  generatorMatchingAns,
  matchingColors,
} from '@cms/comps/interact/matching/MatchingComp';
import { useMatchingCompContext } from '@cms/comps/interact/matching/MatchingCompContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';

const MatchingClickComps = () => {
  const {
    modifiedTime,
    disableSource,
    disableTarget,
    sourceItems,
    targetItems,
    onAddAnswer,
    originalData,
    updateOptionData,
    updateGroupOptions,
    updateOptionType,
    updateComponent,
  } = useMatchingCompContext();

  const [selectSource, setSelectSource] = useState<string>('');
  const [selectTarget, setSelectTarget] = useState<string>('');

  const handleClick = (label: string, type: CompGroupEnum) => {
    if (
      (CompGroupEnum.source === type && !disableSource.includes(label)) ||
      (CompGroupEnum.target === type && !disableTarget.includes(label))
    ) {
      if (CompGroupEnum.source === type && selectTarget.trim() !== '') {
        addCorrectAnswer(label, selectTarget);
      } else if (CompGroupEnum.target === type && selectSource.trim()) {
        addCorrectAnswer(selectSource, label);
      } else {
        if (CompGroupEnum.source === type) {
          setSelectSource(label);
        } else {
          setSelectTarget(label);
        }
      }
    }
  };

  const addCorrectAnswer = (source: string, target: string) => {
    setSelectSource('');
    setSelectTarget('');

    const ansPart = generatorMatchingAns(source, target);
    onAddAnswer(ansPart);
  };

  return (
    <>
      <div className={'matching-panel source-items'}>
        {sourceItems &&
          sourceItems.map((source, index) => {
            return (
              <div
                key={JSON.stringify(source) + modifiedTime}
                className={`matching-option 
                ${
                  disableSource.includes(source.label) ? 'disabled-option' : ''
                }`}
              >
                <OptionEditable
                  type={source.content.type}
                  disabled={{
                    addOption: false,
                    removeOption: sourceItems.length === 1,
                  }}
                  onChangeType={(newType) =>
                    updateOptionType(
                      newType,
                      index,
                      source,
                      CompGroupEnum.source
                    )
                  }
                  onOperation={(operation) =>
                    updateGroupOptions(
                      operation,
                      index,
                      source,
                      CompGroupEnum.source
                    )
                  }
                >
                  <MatchingOption
                    compId={originalData.id}
                    target={source}
                    type={CompGroupEnum.source}
                    selectTarget={selectSource}
                    onClick={handleClick}
                    color={matchingColors[index % matchingColors.length]}
                    handleOnContentChange={(data) =>
                      updateOptionData(
                        data,
                        index,
                        source,
                        CompGroupEnum.source
                      )
                    }
                  />
                </OptionEditable>
              </div>
            );
          })}
      </div>

      <div className={'matching-panel target-items'}>
        {targetItems.map((target, index) => {
          return (
            <div
              key={JSON.stringify(target) + modifiedTime}
              className={`matching-option ${
                disableTarget.includes(target.label) ? 'disabled-option' : ''
              }`}
            >
              <OptionEditable
                type={target.content.type}
                disabled={{
                  addOption: false,
                  removeOption: targetItems.length === 1,
                }}
                onChangeType={(newType) =>
                  updateOptionType(newType, index, target, CompGroupEnum.target)
                }
                onOperation={(operation) =>
                  updateGroupOptions(
                    operation,
                    index,
                    target,
                    CompGroupEnum.target
                  )
                }
              >
                <MatchingOption
                  compId={originalData.id}
                  target={target}
                  type={CompGroupEnum.target}
                  selectTarget={selectTarget}
                  onClick={handleClick}
                  color={ComponentColorEnum.grey}
                  handleOnContentChange={(data) =>
                    updateOptionData(data, index, target, CompGroupEnum.target)
                  }
                />
              </OptionEditable>
            </div>
          );
        })}
      </div>

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </>
  );
};

export default MatchingClickComps;
