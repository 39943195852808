import { createSlice } from '@reduxjs/toolkit';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { BreadCrumbsProps } from '@components/breadcrumbs/GlobalBreadcrumb';

const initialState = {
  isIframe: false,
  menu: [NavigationMenuEnum.EMPTY],
  submenu: [NavigationMenuEnum.EMPTY],
  breadcrumb: [] as BreadCrumbsProps[],

  selectUserType: '' as 'teacher' | 'student' | '',
  selectUserId: -1,
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,

  reducers: {
    updateMenu(
      state,
      action: {
        payload: {
          menu: NavigationMenuEnum;
          submenu: NavigationMenuEnum;
        };
      }
    ) {
      state.menu = [action.payload.menu];
      state.submenu = [action.payload.submenu];
    },

    updateBreadcrumb(state, action: { payload: BreadCrumbsProps[] }) {
      state.breadcrumb = action.payload;
    },

    updateSelectMenu(state, action: { payload: NavigationMenuEnum[] }) {
      state.menu = action.payload;
    },

    updateSelectSubMenu(state, action: { payload: NavigationMenuEnum[] }) {
      state.submenu = action.payload;
    },

    checkIframe(state) {
      state.isIframe = inIframe();
    },

    showSelectUser(state, action: { payload: 'teacher' | 'student' | '' }) {
      state.selectUserType = action.payload;
    },

    selectUser(state, action: { payload: number }) {
      state.selectUserId = action.payload;
    },
  },
});

const { actions, reducer } = appStateSlice;

export const {
  updateMenu,
  updateSelectMenu,
  updateSelectSubMenu,
  checkIframe,
  updateBreadcrumb,
  showSelectUser,
  selectUser,
} = actions;

export default reducer;
