import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';

import {
  AssignmentInstanceStatusRes,
  FilterAssignmentReq,
} from '@modules/assignments/service/assignment_model';

export const TeacherAssignmentAPI = createApi({
  reducerPath: 'TeacherAssignmentAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    filterAssignments: builder.query<
      PaginationRes<AssignmentInstanceStatusRes>,
      FilterAssignmentReq
    >({
      query: (params) => ({
        url: `/teacher-assignments`,
        params,
      }),
    }),

    uncompletedAssignments: builder.query<
      PaginationRes<AssignmentInstanceStatusRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: `/teacher-assignments/uncompleted`,
        params,
      }),
    }),

    getNeedGradingAssignments: builder.query<
      PaginationRes<AssignmentInstanceStatusRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: `/teacher-assignments/need-grading`,
        params,
      }),
    }),
  }),
});
