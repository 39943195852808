import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import EmailTemplate from '@router/pages/management/setting/email_template';
import { RoleEnum } from '@app/redux/slices/roles';
import LanguageSetting from '@router/pages/management/setting/language_setting';
import SchoolSetting from '@router/pages/management/setting/school_setting';
import CurrencySetting from '@router/pages/management/setting/payment/currency_setting';
import PaymentMethodSetting from '@router/pages/management/setting/payment/payment_method_setting';

export const SettingRouters: PageRouterProps[] = [
  {
    path: SiteMap.management.setting.setting,
    element: (
      <PrivateRouter roles={[RoleEnum.SCHOOL]} element={<SchoolSetting />} />
    ),
  },
  {
    path: SiteMap.management.setting.setting_email_template,
    element: (
      <PrivateRouter roles={[RoleEnum.SCHOOL]} element={<EmailTemplate />} />
    ),
  },

  {
    path: SiteMap.management.setting.language,
    element: (
      <PrivateRouter roles={[RoleEnum.ADMIN]} element={<LanguageSetting />} />
    ),
  },

  {
    path: SiteMap.management.setting.currency,
    element: (
      <PrivateRouter
        roles={[RoleEnum.SCHOOL, RoleEnum.ADMIN]}
        element={<CurrencySetting />}
      />
    ),
  },

  {
    path: SiteMap.management.setting.payment_method,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN, RoleEnum.SCHOOL]}
        element={<PaymentMethodSetting />}
      />
    ),
  },
];
