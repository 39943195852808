import React from 'react';

import { AuthorizeStyle } from '../styled/PageStyle';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import RegisterUserForm from '@modules/authorization/container/RegisterStudentForm';
import { H1 } from '@components/typography';
import Card from '@components/card';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export default function StudentRegistration(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();

  const handleRegisterSuccess = () => {
    router.push(SiteMap.auth.login);
  };

  return (
    <AuthorizeStyle>
      <div className={'home-block-container'}>
        <div className={'gstudy-container'}>
          <H1>{t('header.student_registration')}</H1>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              <p>
                Gstudy cung cấp các công cụ mạnh mẽ giúp các học viên có thể tự
                học, tự theo dõi và đánh giá kết quả học tập của mình cũng như
                giúp bạn cải thiện kết quả học tập với sự giúp đỡ của hệ thống.
              </p>

              <p>Gói cơ bản của học sinh là miễn phí trên Gstudy.</p>

              <p>
                Ngoài ra, Gstudy đặc biệt quan tâm đến sự tương tác giữa giáo
                viên và học sinh, học sinh và phụ huynh.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <Card>
                <H1>{t('header.student_registration')}</H1>

                <RegisterUserForm
                  disabled={props.disabled}
                  successHandle={handleRegisterSuccess}
                  autoLogin={false}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </AuthorizeStyle>
  );
}
