import {
  CompTypeEnum,
  CompProps,
  CompMode,
  CompAnswerProps,
} from '@cms/ComponentInteface';
import React from 'react';
import { DocContentProps } from '@cms/content/ContentType';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { AssetsRes } from '@modules/product/services/assets_model';
import {
  ContentViewer,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { updateComponent } from '@app/redux/slices/composeResource';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

export interface DocCompProps extends CompProps {
  configuration: {
    content: DocContentProps;
  };
}

export const StaticDocData: DocCompProps[] = [
  {
    id: 'simple_doc_component',
    type: CompTypeEnum.WORD,

    configuration: {
      content: {
        id: 'simple_doc_component',
        type: AssetsTypeEnum.DOC,
        data: '/src/image-content',
      },
    },
  },
];

const DocCompStyle = styled.div`
  position: relative;
`;

export function DocComp(props: {
  item: DocCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: AssetsRes) => {
      const newData = {
        ...props.item,

        configuration: {
          content: data,
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <DocCompStyle
        className={`comp comp-doc ${props.item.setting?.className}`}
      >
        <ContentViewerEditable
          contentData={props.item.configuration.content}
          onChange={handleOnChange}
        />
      </DocCompStyle>
    );
  } else {
    return (
      <DocCompStyle
        className={`comp comp-doc ${props.item.setting?.className}`}
      >
        <ContentViewer contentData={props.item.configuration.content} />
      </DocCompStyle>
    );
  }
}
