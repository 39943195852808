import React from 'react';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { SingleSectionToolbar } from '../components/toolbar/SingleSectionToolbar';
import { StepByStepQuestionToolbar } from '@cms/lesson-template/components/toolbar/StepByStepQuestionToolbar';
import { SingleQuestionToolbar } from '@cms/lesson-template/components/toolbar/SingleQuestionToolbar';
import { MultipleSectionToolbar } from '@cms/lesson-template/components/toolbar/MultipleSectionToolbar';
import { SelfCheckExerciseMode } from '@cms/lesson-template/components/mode/ExerciseMode';

export const ExerciseToolbar = (props: { className: string }) => {
  const { config } = useLessonTemplateContext();

  if (config.singleMode) {
    if (config.singleQuestionMode) {
      if (config.stepByStep) {
        return (
          <div
            className={`exercise-toolbar step-by-step-toolbar ${
              props.className ?? ''
            }`}
          >
            <StepByStepQuestionToolbar />
          </div>
        );
      } else {
        return (
          <div
            className={`exercise-toolbar single-question-toolbar ${
              props.className ?? ''
            }`}
          >
            <SingleQuestionToolbar />
          </div>
        );
      }
    } else {
      return (
        <div
          className={`exercise-toolbar single-section-toolbar ${
            props.className ?? ''
          }`}
        >
          <SingleSectionToolbar />
        </div>
      );
    }
  } else {
    return (
      <SelfCheckExerciseMode>
        <div
          className={`exercise-toolbar multiple-section-toolbar ${
            props.className ?? ''
          }`}
        >
          <MultipleSectionToolbar />
        </div>
      </SelfCheckExerciseMode>
    );
  }
};
