import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { SchoolRes } from '@services/private/SchoolAPI';
import { PaginationRes } from '@services/model/PaginationRes';

export const AdminPublishSchoolAPI = createApi({
  reducerPath: 'AdminPublishSchoolAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<SchoolRes>, unknown>({
      query: () => ({
        url: `/admin/publish-school`,
      }),
    }),

    acceptRequest: builder.mutation<any, number>({
      query: (body) => ({
        url: `/admin/publish-school/accept/${body}`,
        body,
        method: 'PUT',
      }),
    }),

    rejectRequest: builder.mutation<any, number>({
      query: (body) => ({
        url: `/admin/publish-school/reject/${body}`,
        body,
        method: 'PUT',
      }),
    }),
  }),
});
