import { useTranslation } from 'react-i18next';
import React from 'react';
import { PageSiteActionEnum } from '@modules/page-site/containers/PageSiteActions';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import LessonMetadata from '@modules/product/components/lesson/lesson-data/LessonMetadata';
import CreatePageSiteForm from './CreatePageSiteForm';

export const PageSiteActionsHandle = (props: {
  action: { action: PageSiteActionEnum; params: number };
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const handleOnUpdate = () => {};
  return (
    <>
      {props.action.action === PageSiteActionEnum.EDIT && (
        <CustomModal
          header={t('page_site.actions.edit')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreatePageSiteForm
              pageSiteId={props.action.params}
              onSuccess={handleOnUpdate}
              onClose={props.onCancel}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}

      {props.action.action === PageSiteActionEnum.EDIT_METADATA && (
        <CustomModal
          header={t('page_site.actions.edit_metadata')}
          className={CustomModalClassEnum.large_modal}
          content={
            <LessonMetadata
              lessonId={props.action.params}
              onSuccess={props.onCancel}
              onCancel={props.onCancel}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}
    </>
  );
};
