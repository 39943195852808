import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Segmented,
  Space,
  Switch,
  Typography,
} from 'antd';

import React, { useEffect, useState } from 'react';
import AddStaticResource, {
  AssetsTypeEnum,
} from '@modules/product/components/lesson/AddStaticResource';
import { CMSAssetsAPI } from '@modules/product/services/AssetsAPI';
import Search from 'antd/lib/input/Search';
import TablePagination from '@components/table/TablePagination';
import {
  MAX_PAGE_SIZE,
  PaginationReq,
  PaginationRes,
} from '@services/model/PaginationRes';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { UnorderedListOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { IconUtils } from '@utils/IconUtils';
import Card, { CardClassEnum } from '@components/card';
import { ResponsiveScreen } from '@hooks/useMedia';
import { H5 } from '@components/typography';
import styled from 'styled-components';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import {
  AssetsRes,
  UpdateAssetMetadataReq,
} from '@modules/product/services/assets_model';
const { Text } = Typography;

enum AssetsTypeModeEnum {
  table = 'table',
  upload = 'upload',
}

interface AssetsRecordType {
  key: number;
  assetsId?: number;

  id: string;
  type: AssetsTypeEnum;
  name: string;
  data: string;
  keyword?: string;
  description?: string;
  free?: boolean;
  createdDate?: string;
}

export const AssetsLibraryComp = (props: {
  type: AssetsTypeEnum;
  selectFile: AssetsRes;
  onSelectFile: (assets: AssetsRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<AssetsTypeModeEnum>(
    AssetsTypeModeEnum.table
  );

  return (
    <CustomModal
      header={
        <>
          {t('assets.header')} ({props.type})
        </>
      }
      className={CustomModalClassEnum.full_size_modal}
      content={
        <>
          <div style={{ marginBottom: '2em' }}>
            <Segmented
              block
              value={viewMode}
              onChange={(val) => setViewMode(val as AssetsTypeModeEnum)}
              options={[
                {
                  value: AssetsTypeModeEnum.table,
                  label: (
                    <>
                      <UnorderedListOutlined />
                      &nbsp;{t('assets.find_in_library')}
                    </>
                  ),
                },
                {
                  value: AssetsTypeModeEnum.upload,
                  label: (
                    <>
                      <CloudUploadOutlined />
                      &nbsp;
                      {t('assets.actions.upload_file', {
                        fileType: props.type,
                      })}
                    </>
                  ),
                },
              ]}
            />
          </div>

          {viewMode === AssetsTypeModeEnum.table ? (
            <AssetsLibraryFilterTable
              mode={'select'}
              type={props.type}
              selectFile={props.selectFile}
              onCancel={props.onCancel}
              onSelectFile={props.onSelectFile}
            />
          ) : (
            <AddStaticResource
              fileType={props.type}
              onUploadFile={props.onSelectFile}
            >
              <p className="ant-upload-text">
                {t('assets.warning.select_file')}
              </p>
            </AddStaticResource>
          )}
        </>
      }
      onCloseFunc={props.onCancel}
    />
  );
};

export const AssetsLibraryFilter = (props: {
  type: AssetsTypeEnum;
  selectFile: AssetsRes;
  onSelectFile: (file: AssetsRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('find-in-library');

  const handleOnUploadFiles = (file: AssetsRes) => {
    props.onSelectFile(file);
  };

  return (
    <div className={'assets-library-filter'} style={{ minHeight: 320 }}>
      <ResponsiveTabs
        onChange={(actTab) => setActiveTab(actTab)}
        items={[
          {
            key: 'find-in-library',
            label: t('assets.find_in_library'),
            children: (
              <AssetsLibraryFilterTable
                mode={'select'}
                type={props.type}
                selectFile={props.selectFile}
                onCancel={props.onCancel}
                onSelectFile={props.onSelectFile}
              />
            ),
          },
          {
            key: 'upload',
            label: t('assets.actions.upload_file', { fileType: props.type }),
            children: (
              <AddStaticResource
                fileType={props.type}
                onUploadFile={handleOnUploadFiles}
              >
                <p className="ant-upload-text">
                  {t('assets.warning.select_file')}
                </p>
              </AddStaticResource>
            ),
          },
        ]}
        activeKey={activeTab}
      />
    </div>
  );
};

export const AssetsLibraryFilterTable = (props: {
  type?: AssetsTypeEnum;
  mode: 'edit' | 'select';
  selectFile: AssetsRes | undefined;

  onSelectFile: (file: AssetsRes) => void;
  onCancel: () => void;
  onTypeChange?: (type: AssetsTypeEnum) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [filter, { data: tableData, isFetching }] =
    CMSAssetsAPI.endpoints.filter.useLazyQuery({});
  const [updateMetadata] = CMSAssetsAPI.endpoints.updateMetadata.useMutation(
    {}
  );

  const [type, setType] = useState<AssetsTypeEnum>(
    props.type ?? AssetsTypeEnum.IMAGE
  );

  const [editingKey, setEditingKey] = useState(0);

  const [dataSource, setDataSource] =
    useState<PaginationRes<AssetsRecordType> | null>(null);

  const [selectedAssets, setSelectedAssets] = useState<AssetsRes | null>(null);

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
  });

  const isEditing = (record: AssetsRecordType) => record.key === editingKey;

  const edit = (_record: Partial<AssetsRecordType> & { key: React.Key }) => {
    form.setFieldsValue({
      assetsId: _record.key,
      name: _record.name,
      keyword: _record.keyword,
      description: _record.description,
      free: _record.free,
      createdDate: _record.createdDate,
      ..._record,
    });

    setEditingKey(_record.key);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const selectFileAndClose = () => {
    if (selectedAssets) {
      props.onSelectFile(selectedAssets);
    }
  };

  useEffect(() => {
    onSearch('');
  }, [params]);

  useEffect(() => {
    if (props.onTypeChange) {
      props.onTypeChange(type);
    }
  }, [type]);

  useEffect(() => {
    if (tableData) {
      const records: AssetsRecordType[] = tableData.content.map((r) => {
        return processDataRow(r);
      });

      setDataSource({ ...tableData, content: records });
    }
  }, [tableData]);

  const onSearch = (value: string) => {
    filter({ ...params, type: type, keyword: value });
  };

  const columns = [
    {
      title: t('assets.form.file_url'),
      dataIndex: 'data',
      sorter: true,
      render: (text: string, record: AssetsRecordType) => {
        return (
          <div className={'component-preview'}>
            <ContentViewer contentData={record} />
          </div>
        );
      },
    },

    {
      title: t('assets.form.file_name'),
      dataIndex: 'name',
      sorter: true,
      editable: props.mode === 'select',
      width: 250,
      render: (text: string, record: AssetsRecordType) => {
        return (
          <Text
            copyable={{
              text: record.data,
            }}
          >
            <span
              className={'file-name'}
              style={{ wordBreak: 'break-word' }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Text>
        );
      },
    },

    {
      title: t('assets.form.free'),
      dataIndex: 'free',
      width: 100,
      align: 'center',
      sorter: true,
      editable: true,
      render: (_: any, record: AssetsRecordType) => {
        return record.free ? t('label.public') : t('label.private');
      },
    },

    {
      title: t('assets.form.keyword'),
      dataIndex: 'keyword',
      sorter: false,
      editable: true,
      width: 200,
    },

    {
      title: t('assets.form.description'),
      dataIndex: 'description',
      sorter: true,
      editable: true,
      width: 250,
    },

    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      sorter: true,
      editable: true,
      width: 200,
      render: (_: any, record: AssetsRecordType) => {
        return <DateAndTimeLabel label={record.createdDate!} />;
      },
    },

    {
      title: t('label.actions'),
      dataIndex: 'action',
      sorter: false,
      width: 240,
      align: 'center',
      render: (_: any, record: AssetsRecordType) => {
        const editable = isEditing(record);

        return editable ? (
          <Space>
            <Button
              type={'primary'}
              shape={'round'}
              onClick={() => save(record.key)}
              icon={IconUtils.save}
            >
              {t('button.save')}
            </Button>

            <Button type={'default'} shape={'round'} onClick={cancel}>
              {t('button.cancel')}
            </Button>
          </Space>
        ) : (
          <Button
            type={'primary'}
            shape={'round'}
            onClick={() => edit(record)}
            disabled={editingKey !== 0}
            icon={IconUtils.edit}
          >
            {t('button.edit')}
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AssetsRecordType) => ({
        record,
        inputType: col.dataIndex === 'free' ? 'switch' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as AssetsRecordType;

      const payload: UpdateAssetMetadataReq = {
        assetsId: key as number,
        id: row.id,
        keyword: row.keyword ? row.keyword : '',
        description: row.description ? row.description : '',
        free: !!row.free,
      };

      updateMetadata(payload)
        .unwrap()
        .then(() => {
          if (dataSource) {
            const newData = [...dataSource.content];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
              const item = newData[index];
              newData.splice(index, 1, {
                ...item,
                ...row,
              });
              setDataSource({ ...dataSource, content: newData });
              setEditingKey(0);
            } else {
              newData.push(row);
              setDataSource({ ...dataSource, content: newData });
              setEditingKey(0);
            }
          }
        })
        .catch(() => {
          setEditingKey(0);
        });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const processDataRow = (record: AssetsRes): AssetsRecordType => {
    return {
      key: record.assetsId!,
      assetsId: record.assetsId,

      id: record.id,
      type: record.type,
      name: record.name ?? '',
      data: record.data,
      keyword: record.keyword ?? '',
      description: record.description ?? '',
      createdDate: record.createdDate,
    };
  };

  const renderMobile = (record: AssetsRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <AssetItemStyle className={'assets-file'}>
          <H5>{record.name}</H5>

          <div className={'assets-file-thumbnail'}>
            <div className={'component-preview'}>
              <ContentViewer contentData={record} />
            </div>
          </div>

          <div className={'assets-filen-action'}>
            <Button type={'primary'} shape={'round'} icon={IconUtils.edit}>
              {t('button.edit')}
            </Button>
          </div>
        </AssetItemStyle>
      </Card>
    );
  };

  const rowSelection: TableRowSelection<AssetsRecordType> = {
    type: 'radio',

    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: AssetsRecordType[]
    ) => {
      const firstRow = selectedRows[0];

      const selectRecord: AssetsRes = {
        key: firstRow.assetsId,
        assetsId: firstRow.assetsId,

        id: firstRow.id,
        type: firstRow.type,

        data: firstRow.data,
        name: firstRow.name,
        keyword: firstRow.keyword,
        description: firstRow.description,
        createdDate: firstRow.createdDate,
      };

      setSelectedAssets(selectRecord);
    },
    getCheckboxProps: (record: AssetsRecordType) => ({
      name: record.name,
    }),
  };

  return (
    <div className={'filter-file-container'}>
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        {!props.type && (
          <InputGroup label={t('assets.file_type')}>
            <Radio.Group
              size={'large'}
              onChange={(e) => setType(e.target.value as AssetsTypeEnum)}
              value={type}
            >
              <Radio value={AssetsTypeEnum.IMAGE}>IMAGE File&nbsp;</Radio>
              <Radio value={AssetsTypeEnum.AUDIO}>AUDIO File&nbsp;</Radio>
              <Radio value={AssetsTypeEnum.VIDEO}>VIDEO File&nbsp;</Radio>
              <Radio value={AssetsTypeEnum.DOC}>DOC File&nbsp;</Radio>
              <Radio value={AssetsTypeEnum.PDF}>PDF File&nbsp;</Radio>
              <Radio value={AssetsTypeEnum.EPUB}>EPUB File&nbsp;</Radio>
            </Radio.Group>
          </InputGroup>
        )}

        <InputGroup label={t('label.keyword')}>
          <Search
            placeholder={t('label.enter_keyword')}
            onSearch={onSearch}
            enterButton
          />
        </InputGroup>
      </Card>

      {dataSource && (
        <Form form={form} component={false}>
          <TablePagination
            rowSelection={props.mode === 'select' ? rowSelection : undefined}
            params={params}
            data={dataSource}
            isLoading={isFetching}
            refresh={setParams}
            columns={mergedColumns}
            components={{
              body: {
                cell: AssetsLibraryEditable,
              },
            }}
            responsive={{
              screen: [
                ResponsiveScreen.xs,
                ResponsiveScreen.sm,
                ResponsiveScreen.md,
              ],
              render: renderMobile,
            }}
          />
        </Form>
      )}

      {selectedAssets && props.mode === 'select' && (
        <Space align={'end'}>
          <Button
            type={'primary'}
            onClick={selectFileAndClose}
            icon={IconUtils.actions.select}
          >
            {t('button.select')}
          </Button>

          <Button type={'default'} onClick={props.onCancel}>
            {t('button.cancel')}
          </Button>
        </Space>
      )}
    </div>
  );
};

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text' | 'switch';
  record?: T;
  index?: number;
  children: React.ReactNode;
}

const AssetsLibraryEditable: React.FC<EditableCellProps<AssetsRecordType>> = ({
  editing,
  dataIndex,
  inputType,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : inputType === 'switch' ? (
      <Switch />
    ) : (
      <Input />
    );

  return (
    <td {...restProps} className={'edit-input-' + index}>
      {editing ? <Form.Item name={dataIndex}>{inputNode}</Form.Item> : children}
    </td>
  );
};

const AssetItemStyle = styled.div`
  position: relative;

  .assets-filen-action {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
