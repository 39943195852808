import { ButtonProps as AntButtonProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@app/redux/hook';
import Button from '@components/button/index';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { RequireLoginForm } from '@modules/public/components/RequireLoginForm';

const RequireLoginButton = ({ onClick, ...props }: AntButtonProps) => {
  const { t } = useTranslation();
  const { isAuth } = useSelector((state) => state.profile);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleOnClick = (e: any) => {
    if (isAuth) {
      if (onClick) {
        onClick(e);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const handleWhenLoginSuccess = () => {
    onClose();
  };

  const onClose = () => {
    setShowLoginModal(false);
  };

  return (
    <>
      <Button onClick={handleOnClick} {...props} />

      {showLoginModal && (
        <CustomModal
          header={t('button.registration')}
          maskClosable={false}
          className={CustomModalClassEnum.default}
          content={<RequireLoginForm loginSuccess={handleWhenLoginSuccess} />}
          onCloseFunc={onClose}
        />
      )}
    </>
  );
};

export default RequireLoginButton;
