import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Divider } from 'antd';
import { SessionAPI } from '@services/private/SessionAPI';
import ClipboardCopy from '@components/text/ClickToCopy';
import { HintText } from '@components/text/HintText';
import QRCodeComp from '@components/text/QRCode';
import { SiteMap } from '@router/SiteMap';
import { SessionRes } from '@services/model/session';

export default function PublicSessionForm(props: {
  readonly?: boolean;
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean) => void;
  onActive?: () => void;
}) {
  const { t } = useTranslation();

  const [publicCode, setPublicCode] = useState('');

  const [generatePublicCode, { data, isSuccess, isFetching }] =
    SessionAPI.endpoints.generatePublicCode.useLazyQuery({});

  useEffect(() => {
    setPublicCode(props.sessionData.publicCode);
  }, [props.sessionData]);

  useEffect(() => {
    if (props.onActive) {
      props.onActive();
    }
  }, [props.onActive]);

  useEffect(() => {
    if (data && isSuccess) {
      setPublicCode(data.publicCode);
    }
  }, [data]);

  const generatePublicCodeForSession = () => {
    generatePublicCode(props.sessionData.sessionId);
  };

  return (
    <>
      <div className={'info-group'} style={{ marginBottom: '2em' }}>
        <h3>{t('session.public_link')}</h3>
        <HintText>{t('session.warning.public_link')}</HintText>
        <blockquote>
          <ClipboardCopy
            layout={'inline'}
            copyText={
              window.location.origin +
              SiteMap.class_room.class_room_gen(
                props.sessionData.code,
                '',
                'calendar'
              )
            }
          />
        </blockquote>

        <blockquote>
          <QRCodeComp
            url={
              window.location.origin +
              SiteMap.class_room.class_room_gen(
                props.sessionData.code,
                '',
                'calendar'
              )
            }
          />
        </blockquote>
      </div>

      <Divider />

      {publicCode ? (
        <>
          <div className={'info-group'} style={{ marginBottom: '2em' }}>
            <h3>{t('session.direct_link')}</h3>
            <HintText>{t('session.warning.direct_link_hint')}</HintText>
            <blockquote>
              <ClipboardCopy
                layout={'inline'}
                copyText={
                  window.location.origin +
                  SiteMap.class_room.join_gen(publicCode)
                }
              />
            </blockquote>
            <blockquote>
              <QRCodeComp
                url={
                  window.location.origin +
                  SiteMap.class_room.join_gen(publicCode)
                }
              />
            </blockquote>
          </div>

          {(props.readonly == null || !props.readonly) && (
            <Button
              type={'primary'}
              shape={'round'}
              loading={isFetching}
              onClick={generatePublicCodeForSession}
            >
              {t('session.actions.recreate_public_link')}
            </Button>
          )}
        </>
      ) : (
        <>
          {(props.readonly == null || !props.readonly) && (
            <>
              <div className={'info-group'} style={{ marginBottom: '2em' }}>
                <h3>{t('session.direct_link')}</h3>

                <HintText>{t('session.warning.direct_link')}</HintText>
              </div>

              <Button
                type={'primary'}
                shape={'round'}
                loading={isFetching}
                onClick={generatePublicCodeForSession}
              >
                {t('session.actions.create_public_link')}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}
