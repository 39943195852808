import styled from 'styled-components';

export const WelComeStyle = styled.div`
  font-size: 18px;

  .home-block-container {
    padding: 50px 0px;

    .is-iframe & {
      padding: 10px 0px;
    }
  }
`;
