import React, { useEffect, useState } from 'react';
import { PageContent } from '@cms/lesson-template/pages/PageContent';
import { getDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { useRouter } from '@hooks/useRouter';
import { PageHeader } from '@app/header/PageHeader';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { SitePath } from '@router/SitePath';
import { LanguageEnum } from '@components/language/LanguageIcon';
import { LessonRes } from '@modules/product/services/lesson_model';

const DynamicPage = () => {
  const router = useRouter();

  const { path, lang } = router.query as unknown as {
    path: string;
    lang?: LanguageEnum;
  };

  const [viewPageContent, { data, isSuccess }] =
    PublicPageAPI.endpoints.viewPageContent.useLazyQuery();

  const [pageContent, setPageContent] = useState<LessonRes | null>(null);

  useEffect(() => {
    viewPageContent({
      lang: lang ?? getDefaultLanguage(),
      path: path,
    });
  }, [path, lang]);

  useEffect(() => {
    if (data && isSuccess) {
      setPageContent(data);
    }
  }, [data]);

  return (
    <>
      {pageContent && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.EMPTY}
            subMenu={NavigationMenuEnum.EMPTY}
            title={pageContent.name}
            description={pageContent.description}
          />

          <PageContent path={SitePath.index} page={pageContent} />
        </>
      )}
    </>
  );
};

export default DynamicPage;
