import Button from '@components/button';
import React from 'react';
import styled from 'styled-components';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { IconUtils } from '@utils/IconUtils';
import { useTranslation } from 'react-i18next';

export const ComponentSettingToolbar = ({
  showComponent,
  onClick,
}: {
  showComponent: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ComposeModeContent>
      {showComponent ? (
        <ComponentSettingButtonStyle
          className={`update-component-setting component-review`}
          type={'primary'}
          shape={'circle'}
          onClick={onClick}
          icon={IconUtils.actions.edit}
        />
      ) : (
        <ButtonGroupWrapper className={'update-component-setting-group'}>
          <ComponentSettingButtonStyle
            className={`update-component-setting  component-setting`}
            type={'primary'}
            shape={'round'}
            onClick={onClick}
            icon={IconUtils.lesson.save}
          >
            {t('button.update')}
          </ComponentSettingButtonStyle>
        </ButtonGroupWrapper>
      )}
    </ComposeModeContent>
  );
};

export const ComponentSettingButtonStyle = styled(Button)`
  &.component-review {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(0%, 0%);
    z-index: 99;
  }

  &.component-setting {
    position: relative;
  }
`;

const ButtonGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
`;
