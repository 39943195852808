import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import {FilterProductLicenseReq, FilterUserProductReq, ProductLicenseRes} from "@modules/license/services/model";

export const ProductLicenseAPI = createApi({
  reducerPath: 'ProductLicenseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getLicense: builder.query<
      PaginationRes<ProductLicenseRes>,
      FilterUserProductReq
    >({
      query: (params) => ({
        url: `/licenses/products`,
        params,
      }),
    }),

    getProductLicense: builder.query<
      PaginationRes<ProductLicenseRes>,
      FilterProductLicenseReq
    >({
      query: (params) => ({
        url: `/licenses/products/${params.productId}`,
        params,
      }),
    }),

    checkLicenseForUser: builder.query<ProductLicenseRes[], number[]>({
      query: (productIds) => ({
        url: `/licenses/products/users`,
        params: { productIds: productIds },
      }),
    }),

    checkLicenseForProducts: builder.query<
      ProductLicenseRes[],
      { productId: number; userIds: number[] }
    >({
      query: (params) => ({
        url: `/licenses/products/${params.productId}/users`,
        params: { userIds: params.userIds },
      }),
    }),
  }),
});
