import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import {
  TriggerLessonAction,
  ViewLessonContentProvider,
  ViewLessonDataChange,
} from '@cms/lesson-template/context/ViewLessonContext';
import { SessionAPI } from '@services/private/SessionAPI';
import { CompMode } from '@cms/ComponentInteface';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { AnnotationWorkspace } from '@classroom/container/annotation/AnnotationWorkspace';
import { PresentServiceProvider } from '@cms/service/PresentServiceProvider';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { ReviewStudentExercise } from '@classroom/container/task/ReviewStudentTask';
import { LessonTypeEnum } from '@modules/admin/service/model';
import {StringUtils} from "@utils/StringUtils";

export const PresentationWorkspace = () => {
  const { sectionId, exerciseId } = useClassRoomContext();

  return (
    <div className={'presentation-workspace'}>
      <SwitchCondition
        conditions={[
          {
            condition: sectionId > 0,
            children: <ViewSingleSection />,
          },
          {
            condition: exerciseId > 0,
            children: <ViewSingleExercise exerciseId={exerciseId} />,
          },
          {
            condition: sectionId < 0 && exerciseId < 0,
            children: <AnnotationWorkspace />,
          },
        ]}
      />
    </div>
  );
};

const ViewSingleExercise = (props: { exerciseId: number }) => {
  return (
    <ViewSingleExerciseStyle className={'class-room-review-exercise'}>
      <ReviewStudentExercise exerciseId={props.exerciseId} />
      <AnnotationWorkspace />
    </ViewSingleExerciseStyle>
  );
};

const ViewSingleSection = () => {
  const [viewSectionSlide, { data, isSuccess }] =
    SessionAPI.endpoints.viewSectionSlide.useLazyQuery();

  const { session, sectionId, sectionData, onQuestionChange } =
    useClassRoomContext();

  const [action, setAction] = useState<TriggerLessonAction>({
    type: '',
    params: null,
  });

  const [currentState, setCurrentState] = useState<ViewLessonDataChange>({
    checkAnswerMode: false,
    showCorrectAnswerMode: false,
    answers: {},
    feedbacks: {},
    gradings: {},
  });

  useEffect(() => {
    viewSectionSlide({
      sessionId: session.sessionId,
      sectionId: sectionId,
    });
  }, [session.sessionId, sectionId]);

  useEffect(() => {
    if (sectionData != null && sectionData.question) {
      setAction({
        type: 'restore',
        params: JSON.parse(sectionData.question),
      });
    }
  }, [sectionData]);

  useEffect(() => {
    onQuestionChange(currentState);
  }, [StringUtils.toStr(currentState)]);

  const handleOnChange = (data: ViewLessonDataChange) => {
    setCurrentState(data);
  };

  return (
    <>
      {data && isSuccess && (
        <ViewLessonContentProvider
          key={JSON.stringify(data)}
          type={CompMode.PRESENTATION}
          lesson={{ ...data.lesson, sections: [data.section] }}
          lessonType={LessonTypeEnum.presentation}
          answers={[]}
          feedBacks={[]}
          gradings={[]}
          exitExercise={() => {}}
          onChange={handleOnChange}
          action={action}
        >
          <PresentServiceProvider>
            <LessonTemplateLayoutProvider lesson={data.lesson}>
              <ViewLessonContent />
              <AnnotationWorkspace />
            </LessonTemplateLayoutProvider>
          </PresentServiceProvider>
        </ViewLessonContentProvider>
      )}
    </>
  );
};

const ViewSingleExerciseStyle = styled.div`
  .review-exercise-toolbar {
    display: none;
  }

  .exercise-score-result {
    display: none;
  }

  .exercise-content-display {
    max-width: 100% !important;
    margin: 0;
  }

  .exercise-template-container {
    padding-bottom: 0;
  }
`;

export const PresentationWorkspaceStyle = styled.div`
  .class-room-section-presentation {
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    max-width: 100%;
    overflow-x: hidden;

    .view-lesson-content-container {
      z-index: 3;
      position: relative;
    }

    .view-lesson-content-container {
      width: 100%;

      .question-number {
        .question-number-display {
          display: none;
        }
      }
    }
  }

  .prevent-action-bgr {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    h1 {
      max-width: 60%;
      text-align: center;
      cursor: pointer;
      padding: 2vw 3vw;
      border-radius: 1vw;
      background: rgba(0, 0, 0, 0.25);
      color: ${(props) => props.theme.app.primary};
      font-size: 4vw;
      line-height: 1.6;
      letter-spacing: 0.0125ch;
    }
  }
`;
