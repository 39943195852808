import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import ViewStudentExercisePage from '@modules/reports/exercises/ViewStudentExercisePage';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const ExerciseReports = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'exercise-reports-page'}>
      <PageHeader
        menu={NavigationMenuEnum.REPORTS}
        subMenu={NavigationMenuEnum.REPORTS_EXERCISES}
        title={t('report.view_lesson_record')}
        description={t('report.view_lesson_record')}
      />

      <ViewStudentExercisePage />
    </ManagementLayout>
  );
};

export default ExerciseReports;
