import { Button, Menu, Popover } from 'antd';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import React, { ReactNode } from 'react';
import { useSelector } from '@app/redux/hook';
import { MenuItem } from '@components/template/common/MenuUtils';

export const HeaderMenu = (props: {
  group: NavigationMenuEnum;
  groupIcon: ReactNode;
  className: string;
  menuItems: MenuItem[] | any[];
}) => {
  const { menu, submenu } = useSelector((state) => state.appState);

  const hasMenu = props.menuItems.some((e) => {
    return e != null && e.type !== 'divider';
  });

  if (hasMenu) {
    return (
      <Popover
        placement="bottom"
        content={
          <div className={props.className}>
            <Menu
              mode="inline"
              multiple={false}
              style={{ borderRight: 0 }}
              openKeys={submenu}
              selectedKeys={[...submenu]}
              items={props.menuItems}
            />
          </div>
        }
        trigger={['click']}
        overlayClassName={'menu-group-content'}
      >
        <Button
          shape="circle"
          size={'large'}
          icon={props.groupIcon}
          type={menu.includes(props.group) ? 'primary' : 'default'}
        />
      </Popover>
    );
  } else {
    return null;
  }
};
