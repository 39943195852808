import { Space } from 'antd';
import { ToggleCorrectAnswer } from '@cms/lesson-template/components/buttons/compose/ToggleCorrectAnswerButton';
import React from 'react';
import { SingleSelfCheckBtn } from '../components/button-wrapper/SingleSelfCheckBtn';
import styled from 'styled-components';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';

export const PresentationToolbar = () => {
  const { section } = useLessonNavigationContext();

  return (
    <LessonPresentationToolbar
      className={'lesson-toolbar lesson-presentation-toolbar'}
    >
      {section.interact && (
        <>
          <div className={'left-group'}>
            <ToggleCorrectAnswer />
          </div>

          <div className={'right-group'}>
            <Space align={'center'} size={10}>
              <SingleSelfCheckBtn />
            </Space>
          </div>
        </>
      )}
    </LessonPresentationToolbar>
  );
};

export const LessonPresentationToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  .ant-btn.ant-btn-icon-only {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .anticon {
      svg {
        width: 1.4em;
        height: 1.4em;
      }
    }
  }
`;
