import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  StudentInvitationRes,
  TeacherInvitationRes,
} from '@modules/users/services/model';

export enum InvitationType {
  SUBSCRIBLE = 0,
  CLASS = 1,
  COURSE = 2,
  SESSION = 3,
  SHARE_LESSON = 4,
  SHARE_PRODUCT = 5,
}

export interface FindInvitationReq {
  token: string | any;
  invitedCode: string | any;
}

export interface InviteStudentReq {
  type: number;
  params: number;
  studentId: number;
}

export interface InviteClassReq {
  classId: number;
  email: string;
}

export interface InviteCourseReq {
  courseId: number;
  email: string;
}

export interface InviteSessionReq {
  sessionId: number;
  email: string;
}

export interface AcceptInvitationReq {
  invitedId: number;
  accepted: boolean;
}

export const InvitationAPI = createApi({
  reducerPath: 'InvitationAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    findInvitation: builder.query<TeacherInvitationRes, unknown>({
      query: (params: FindInvitationReq) => ({
        url: '/public/invitation',
        method: 'GET',
        params,
      }),
    }),

    getClassInvitation: builder.query<StudentInvitationRes[], number>({
      query: (classId: number) => ({
        url: `/invite/class/${classId}`,
        method: 'GET',
      }),
    }),

    getCourseInvitation: builder.query<StudentInvitationRes[], number>({
      query: (courseId: number) => ({
        url: `/invite/course/${courseId}`,
        method: 'GET',
      }),
    }),

    getShareLessonInvitation: builder.query<StudentInvitationRes[], number>({
      query: (lessonId: number) => ({
        url: `/invite/lesson/${lessonId}`,
        method: 'GET',
      }),
    }),

    getShareProductInvitation: builder.query<StudentInvitationRes[], number>({
      query: (productId: number) => ({
        url: `/invite/product/${productId}`,
        method: 'GET',
      }),
    }),

    // invite student to list student of teacher. teacher can send invite class, invite course, invite session later.
    inviteStudent: builder.mutation<BasicMessageRes, InviteStudentReq>({
      query: (body: InviteStudentReq) => ({
        url: '/invite/student',
        method: 'POST',
        body,
      }),
    }),

    inviteClass: builder.mutation<BasicMessageRes, InviteClassReq>({
      query: (body: InviteClassReq) => ({
        url: '/invite/class',
        method: 'POST',
        body,
      }),
    }),

    inviteCourse: builder.mutation<BasicMessageRes, InviteCourseReq>({
      query: (body: InviteCourseReq) => ({
        url: '/invite/course',
        method: 'POST',
        body,
      }),
    }),

    inviteSession: builder.mutation<BasicMessageRes, InviteSessionReq>({
      query: (body: InviteSessionReq) => ({
        url: '/invite/session',
        method: 'POST',
        body,
      }),
    }),

    // #STUDENT
    getTeacherInvitation: builder.query<
      PaginationRes<TeacherInvitationRes>,
      unknown
    >({
      query: (params: PaginationReq) => ({
        url: '/invite/my-invitation',
        method: 'GET',
        params,
      }),
    }),

    acceptInvitation: builder.mutation<BasicMessageRes, unknown>({
      query: (body: AcceptInvitationReq) => ({
        url: `/invite/accept-invited`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
