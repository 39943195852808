import { createContext, ReactNode, useContext, useState } from 'react';
import {
  ExerciseActionEnum,
  ShowQuestionFeedbackProps,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { ResourceAnswerProps } from '@app/redux/slices/viewResource';
import {
  ExerciseResponseRes,
  ExerciseResponseStatusEnum,
} from '@modules/assignments/service/exercise_model';

export enum PracticeResourceState {
  DEFAULT = '',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_PROGRESS_INCORRECT = 'IN_PROGRESS_INCORRECT',
  COMPLETED_CORRECT = 'COMPLETED_CORRECT',
  COMPLETED_INCORRECT = 'COMPLETED_INCORRECT',
  EXIT = 'EXIT',
}

export enum PracticeActionEnum {
  checkAnswer = 'check-answer',
  clearAnswer = 'clear-answer',
  reset = 'reset',
}

export interface PracticeQuestionState {
  interactive: boolean;
  answered: boolean;
  tryTimes: number;
  state: PracticeResourceState;
}

export enum ExerciseEventEnum {
  init = '', // when view an question
  view_question = 'view-question', // when view an question

  check_answer = 'check-answer',
  incorrect = 'incorrect', // answer with incorrect
  clear_answer = 'clear-answer-question', // clear answer after incorrect

  correct_question = 'correct-question', // answer correctly
  incorrect_question = 'incorrect-question', // answer three times and incorrect

  exit_question = 'exit-question', // trigger after click next question or next section, params is current resourceid
  exit_exercise = 'exit-exercise', // answer all question/
}

const PracticeContext = createContext({
  state: {
    interactive: false,
    answered: false,
    tryTimes: 0,
    state: PracticeResourceState.DEFAULT,
  } as PracticeQuestionState,

  initState: (
    resourceIds: number[],
    interactive: boolean,
    state: PracticeResourceState,
    answered: boolean,
    tryTimes: number
  ) => {
    console.log(interactive, state, answered, tryTimes);
  },

  onAnswerChange: (answers: ResourceAnswerProps[]) => {
    console.log(answers);
  },

  showAnswerResult: (
    res: ExerciseResponseRes,
    lastSection: boolean,
    lastQuestion: boolean
  ) => {
    console.log(res, lastSection, lastQuestion);
  },

  complete: (lastSection: boolean, lastQuestion: boolean) => {
    console.log(lastSection, lastQuestion);
  },

  clearAnswer: (resourceIds: number[]) => {
    console.log('clear answer', resourceIds);
  },

  event: {
    event: ExerciseEventEnum.init,
    resourceIds: [] as number[],
  },

  triggerEvent: (event: ExerciseEventEnum, resourceIds: number[]) => {
    console.log(event, resourceIds);
  },
});

export const PracticeContextProvider = (props: { children: ReactNode }) => {
  const { dispatchExerciseAction } = useViewLessonContext();

  const [event, setEvent] = useState({
    event: ExerciseEventEnum.init,
    resourceIds: [] as number[],
  });

  const [state, changeState] = useState({
    interactive: false,
    answered: false,
    tryTimes: 0,
    state: PracticeResourceState.DEFAULT,
  });

  const initState = (
    resourceIds: number[],
    interactive: boolean,
    state: PracticeResourceState,
    answered: boolean,
    tryTimes: number
  ) => {
    changeState({
      interactive: false,
      state: state,
      answered: answered,
      tryTimes: tryTimes,
    });
    triggerEvent(ExerciseEventEnum.view_question, resourceIds);
  };

  const complete = (lastSection: boolean, lastQuestion: boolean) => {
    changeState((prev) => {
      return {
        ...prev,
        state:
          lastSection && lastQuestion
            ? PracticeResourceState.EXIT
            : PracticeResourceState.COMPLETED_CORRECT,
      };
    });
    triggerEvent(ExerciseEventEnum.exit_exercise, []);
  };

  const clearAnswer = (resourceIds: number[]) => {
    changeState((prev) => {
      return { ...prev, state: PracticeResourceState.IN_PROGRESS };
    });
    dispatchExerciseAction(ExerciseActionEnum.reset_all, resourceIds);
    triggerEvent(ExerciseEventEnum.clear_answer, resourceIds);
  };

  const onAnswerChange = (answers: ResourceAnswerProps[]) => {
    const isSkip = answers.some((ans) => {
      return ResourceUtils.isSkip(ans.answers);
    });

    changeState((prev) => {
      return { ...prev, answered: answers.length > 0 && !isSkip };
    });
  };

  const showAnswerResult = (
    res: ExerciseResponseRes,
    lastSection: boolean,
    lastQuestion: boolean
  ) => {
    if (res.status === ExerciseResponseStatusEnum.COMPLETED) {
      const params: ShowQuestionFeedbackProps = {
        resourceId: res.resourceId,
        feedBack: res,
        answers: res.answer,
      };

      dispatchExerciseAction(ExerciseActionEnum.show_question_feedback, [
        params,
      ]);

      if (lastSection && lastQuestion) {
        changeState((prev) => {
          return { ...prev, state: PracticeResourceState.EXIT };
        });
        triggerEvent(ExerciseEventEnum.exit_exercise, [res.resourceId]);
      } else if (res.score != null) {
        const state =
          res.score! >= res.maxScore
            ? PracticeResourceState.COMPLETED_CORRECT
            : PracticeResourceState.COMPLETED_INCORRECT;

        changeState((prev) => {
          return {
            ...prev,
            state: state,
          };
        });

        triggerEvent(
          state === PracticeResourceState.COMPLETED_CORRECT
            ? ExerciseEventEnum.correct_question
            : ExerciseEventEnum.incorrect_question,
          [res.resourceId]
        );
      }
    } else {
      changeState((prev) => {
        return {
          ...prev,
          state: PracticeResourceState.IN_PROGRESS_INCORRECT,
          tryTimes: prev.tryTimes + 1,
        };
      });

      triggerEvent(ExerciseEventEnum.incorrect, [res.resourceId]);
    }
  };

  const triggerEvent = (event: ExerciseEventEnum, resourceIds: number[]) => {
    setEvent({
      event: event,
      resourceIds: resourceIds,
    });
  };

  return (
    <PracticeContext.Provider
      value={{
        state,
        initState,
        onAnswerChange,
        clearAnswer,
        showAnswerResult,
        complete,

        event,
        triggerEvent,
      }}
    >
      {props.children}
    </PracticeContext.Provider>
  );
};

export const usePracticeContext = () => {
  const context = useContext(PracticeContext);
  if (!context) {
    throw new Error(
      'You must wrap container by GradingExerciseContextProvider'
    );
  }
  return context;
};
