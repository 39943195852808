import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SiteMap } from '@router/SiteMap';
import Home from '@router/pages';
import Page404 from '@router/pages/404';

import Incomplete from '@router/pages/incomplete';
import AppVersion from '@router/pages/version';
import JoinClassRoom from '@router/pages/classroom/join_classroom';
import ClassRoomPage from '@router/pages/classroom/class_room';
import DynamicPage from '@router/pages/dynamic_page';

import { LoginRouters, RegisterRouter } from '@router/modules/login';
import { PublicRouters } from '@router/modules/public';
import { PrivateUserRouters } from '@router/modules/user_router';
import { MyLibraryRouters } from '@router/modules/my_library_routers';
import { MyExerciseRouters } from '@router/modules/my_assignment_routers';
import { MyAccountRouters } from '@router/modules/my_account';
import { ContentMetadataRouter } from '@router/modules/content/metadata';
import { ProductRouters } from '@router/modules/content/lesson';
import { PageContentRouter } from '@router/modules/page_content';
import { ManageUserRouters } from '@router/modules/management/users';
import { ManageSchoolRouter } from '@router/modules/management/school';
import { SettingRouters } from '@router/modules/management/setting';
import { TemplateRouters } from '@router/modules/template';
import { SyncRouters } from '@router/modules/sync';
import ReportRouters from '@router/modules/reports';
import { LTIRouters } from '@router/modules/lti_routers';
import { LicenseRouters } from '@router/modules/license/license';
import { ProgramRouters } from '@router/modules/program';

const MainRouter = () => {
  return (
    <Routes>
      <Route path={SiteMap.index} element={<Home />} />

      {LoginRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {RegisterRouter.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {PublicRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {PrivateUserRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {MyLibraryRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {MyExerciseRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {MyAccountRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {ReportRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {ContentMetadataRouter.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {ProgramRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {ProductRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {PageContentRouter.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {/*MANAGEMENT */}

      {ManageUserRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {ManageSchoolRouter.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {LicenseRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {SettingRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {TemplateRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {SyncRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      {LTIRouters.map((rt) => {
        return <Route key={rt.path} path={rt.path} element={rt.element} />;
      })}

      <Route path={SiteMap.class_room.join} element={<JoinClassRoom />} />
      <Route path={SiteMap.class_room.class_room} element={<ClassRoomPage />} />

      <Route path={SiteMap.public.version} element={<AppVersion />} />
      <Route path={SiteMap.public.page_404} element={<Page404 />} />
      <Route path={SiteMap.public.page_403} element={<Page404 />} />
      <Route path={SiteMap.public.page_incomplete} element={<Incomplete />} />
      <Route path={SiteMap.dynamic_page} element={<DynamicPage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default MainRouter;
