import {
  ExerciseActionEnum,
  ShowQuestionFeedbackProps,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React, { useEffect, useState } from 'react';
import { PracticeExerciseAPI } from '@modules/assignments/service/PracticeExerciseAPI';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { Space } from 'antd';
import { CheckCircleOutlined, RedoOutlined } from '@ant-design/icons';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import {
  PracticeActionEnum,
  PracticeResourceState,
} from '@cms/lesson-template/context/PracticeContext';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';
import {
  CheckAnswerReq,
  ExerciseRes,
  ExerciseResponseStatusEnum,
  SelfCheckQuestionReq,
} from '@modules/assignments/service/exercise_model';

export const SelfCheckSectionBtn = () => {
  const [selfCheckAnswer] =
    PracticeExerciseAPI.endpoints.selfCheckAnswer.useMutation();

  const { section, lastSection, lastQuestion } = useLessonNavigationContext();

  const { exerciseId } = useExerciseServiceContext();

  const { lessonId, getResourceAnswers, dispatchExerciseAction } =
    useViewLessonContext();

  const answers = getResourceAnswers(section.resourceIds);

  const [questionState, setQuestionState] = useState<PracticeResourceState>(
    PracticeResourceState.IN_PROGRESS
  );
  const [tryTimes, setTryTimes] = useState(0);
  const [currentTime, setCurrentTime] = useState(
    DateAndTimeUtils.getCurrentTime()
  );

  //01.  reset data
  useEffect(() => {
    setQuestionState(PracticeResourceState.IN_PROGRESS);
    setTryTimes(0);
    setCurrentTime(DateAndTimeUtils.getCurrentTime());
  }, [section.sectionId]);

  const handleClick = (action: PracticeActionEnum) => {
    if (action === PracticeActionEnum.checkAnswer) {
      checkAnswerQuestion();
    } else if (action === PracticeActionEnum.reset) {
      resetAllState();
    }
  };

  const resetAllState = () => {
    setQuestionState(PracticeResourceState.IN_PROGRESS);
    setTryTimes(0);
    setCurrentTime(DateAndTimeUtils.getCurrentTime());
    dispatchExerciseAction(ExerciseActionEnum.hide_question_feedback, null);
  };

  const checkAnswerQuestion = () => {
    const duration = DateAndTimeUtils.getCurrentTime() - currentTime;

    const studentAns: CheckAnswerReq[] = [];

    answers.forEach((ans) => {
      studentAns.push({
        exerciseId: exerciseId,
        sectionId: section.sectionId,
        resourceId: ans.resourceId,

        duration: duration,
        answer: JSON.stringify(ans.answers, null, 2),
        submitted: lastQuestion && lastSection,
        tryTimes: tryTimes,
      });
    });

    const request: SelfCheckQuestionReq = {
      lessonId: lessonId,
      answers: studentAns,
    };

    selfCheckAnswer(request)
      .unwrap()
      .then((result) => {
        showAnswerFeedback(result);
      })
      .catch(() => {
        alert('Have error when save exercise');
      });
  };

  const showAnswerFeedback = (exerciseResult: ExerciseRes) => {
    // correct if manual score or auto_score or try time > max try time

    const questionFeedback: ShowQuestionFeedbackProps[] = [];
    const questionState: string[] = [];

    exerciseResult.answers.forEach((result) => {
      const feedback: ShowQuestionFeedbackProps = {
        resourceId: result.resourceId,
        feedBack: result,
        answers: result.answer,
      };
      questionFeedback.push(feedback);

      if (result.status === ExerciseResponseStatusEnum.COMPLETED) {
        if (result.score != null) {
          if (result.score >= result.maxScore) {
            questionState.push(PracticeResourceState.COMPLETED_CORRECT);
          } else {
            questionState.push(PracticeResourceState.COMPLETED_INCORRECT);
          }
        }
      } else {
        questionState.push(PracticeResourceState.IN_PROGRESS_INCORRECT);
      }
    });

    const wholeCorrect = questionState.every((res) => {
      return res === PracticeResourceState.COMPLETED_CORRECT;
    });

    if (wholeCorrect) {
      setQuestionState(PracticeResourceState.COMPLETED_CORRECT);
    } else {
      setQuestionState(PracticeResourceState.IN_PROGRESS_INCORRECT);

      setTryTimes((pre) => {
        return pre + 1;
      });
    }

    dispatchExerciseAction(
      ExerciseActionEnum.show_question_feedback,
      questionFeedback
    );
  };

  return (
    <Space align={'end'} style={{ width: '100%' }}>
      {questionState === PracticeResourceState.IN_PROGRESS && (
        <LessonButton
          className={'check-answer-btn'}
          type={'primary'}
          shape={'round'}
          onClick={() => handleClick(PracticeActionEnum.checkAnswer)}
          icon={<CheckCircleOutlined />}
        >
          Check Answer
        </LessonButton>
      )}

      {questionState === PracticeResourceState.COMPLETED_CORRECT && (
        <LessonButton
          className={'start-over-btn'}
          type={'primary'}
          shape={'round'}
          onClick={() => handleClick(PracticeActionEnum.reset)}
          icon={<CheckCircleOutlined />}
        >
          Start Over
        </LessonButton>
      )}

      {questionState === PracticeResourceState.IN_PROGRESS_INCORRECT && (
        <LessonButton
          className={'try-again-btn'}
          type={'primary'}
          shape={'round'}
          danger={true}
          onClick={() => handleClick(PracticeActionEnum.reset)}
          icon={<RedoOutlined />}
        >
          Try Again
        </LessonButton>
      )}
    </Space>
  );
};
