import { ReactNode } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SpinIndicator } from 'antd/es/spin';
import styled from 'styled-components';

export const Loading = (props: {
  icon?: SpinIndicator;
  className?: string;
  loading: boolean;
  children: ReactNode;
}) => {
  if (props.loading) {
    return (
      <LoadingStyle className={`loading-screen ${props.className ?? ''}`}>
        <Spin
          indicator={
            props.icon ?? <LoadingOutlined style={{ fontSize: 32 }} spin />
          }
        />
      </LoadingStyle>
    );
  } else {
    return <>{props.children}</>;
  }
};

const LoadingStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
