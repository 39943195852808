import useScript from './useScript';
import { useEffect, useState } from 'react';
import { JsLib } from '@cms/script/LoadLibraryUtils';

const useJquery = (onSuccess: () => void) => {
  const [state, setState] = useState<Record<string, boolean>>({
    [JsLib.jquery.name]: false,
  });

  useEffect(() => {
    let allCompleted = true;

    Object.keys(state).forEach((key) => {
      if (!state[key]) {
        allCompleted = false;
      }
    });

    if (allCompleted) {
      setTimeout(() => {
        onSuccess();
      }, 250);
    }
  }, [state]);

  useScript(JsLib.jquery.url, JsLib.jquery.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.jquery.name]: true } };
    });
  });
};

export default useJquery;
