import { SessionAPI } from '@services/private/SessionAPI';
import { useEffect } from 'react';
import { Loading } from '@components/loading/Loading';
import { SessionRes, SessionStudentRes } from '@services/model/session';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import styled from 'styled-components';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { ClassRoomHost } from '@classroom/context/ClassRoomMode';

export const AttendanceSession = (props: {
  session: SessionRes;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();

  const [viewSessionAttendances, { data, isLoading }] =
    SessionAPI.endpoints.viewSessionAttendances.useLazyQuery();

  useEffect(() => {
    viewSessionAttendances(props.session.sessionId);
  }, [props.session.sessionId]);

  return (
    <AttendanceSessionStyle className={'attendance-session'}>
      <Loading loading={isLoading}>
        <div className={'session-attendance-status'}>
          {data &&
            data.length > 0 &&
            data.map((userStatus) => {
              return (
                <AttendanceUserStatus
                  key={userStatus.user.userId + '-' + userStatus.lastJoinDate}
                  item={userStatus}
                />
              );
            })}
        </div>
      </Loading>

      {props.onClose && (
        <ButtonGroup className={'submit-container'} type={'center'}>
          <Button size={'large'} shape={'round'} onClick={props.onClose}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      )}
    </AttendanceSessionStyle>
  );
};

const AttendanceUserStatus = (props: { item: SessionStudentRes }) => {
  const isJoin = props.item.joinDate != null && props.item.joinDate !== '';

  return (
    <AttendanceUserStatusStyle
      className={`attendance-user-status ${isJoin ? 'active' : 'inactive'}`}
    >
      <UserThumbnailDisplay layout={'column'} item={props.item.user} />

      <ClassRoomHost>
        {props.item.joinDate ? (
          <span className={'date-n-time'}>
            <Button
              size={'small'}
              shape={'round'}
              type={'primary'}
              icon={IconUtils.actions.alert}
              disabled
            >
              Joined
            </Button>
          </span>
        ) : (
          <span className={'date-n-time'}>
            <Button
              size={'small'}
              shape={'round'}
              type={'primary'}
              icon={IconUtils.actions.alert}
            >
              Alert
            </Button>
          </span>
        )}
      </ClassRoomHost>
    </AttendanceUserStatusStyle>
  );
};

const AttendanceUserStatusStyle = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;

  padding: 0.5em 1em;

  text-align: center;
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;

  &.active {
    border: 2px solid ${(props) => props.theme.app.primary};

    .user-n-thumbnails {
      filter: grayscale(0);
    }
  }

  &.inactive {
    border: 2px solid #ccc;

    .user-n-thumbnails {
      filter: grayscale(1);
    }
  }

  .user-n-thumbnails {
    align-items: center;

    .user-display-name {
      white-space: nowrap;
    }
  }

  .date-n-time {
    font-size: 10px;
    text-align: center;
    margin-top: 0.5em;
  }
`;

const AttendanceSessionStyle = styled.div`
  .session-attendance-status {
    gap: 1em;
    display: flex;
    flex-wrap: wrap;
  }
`;
