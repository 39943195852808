import React from 'react';
import ScrollToTop from '@components/feature/ScrollToTop';
import { useTranslation } from 'react-i18next';
import { CourseRes } from '@services/private/CourseAPI';
import CourseMetadata from '@modules/course/components/CourseMetadata';
import { PublishCourseStyle } from '@modules/course/container/publish/CourseInformationStep';

export default function CourseIntroduceStep(props: {
  courseData: CourseRes;
  goPrevious: () => void;
  goNext: () => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop visible={false} />

      <PublishCourseStyle className={'publish-course-section'}>
        <h1>{t('publish.basic_info.introduction')}</h1>

        <div
          className={'product-publish-content'}
          style={{ marginBottom: '2em' }}
        >
          <CourseMetadata
            key={props.courseData.courseId}
            courseId={props.courseData.courseId}
            type={'publish'}
            onSuccess={props.goNext}
            onCancel={props.goPrevious}
          />
        </div>
      </PublishCourseStyle>
    </>
  );
}
