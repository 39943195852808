import React, { useEffect, useState } from 'react';
import { Col, Divider, Input, Row } from 'antd';

import { filterKeyword } from '@app/redux/slices/filterProductAndCourse';
import {
  FilterProductAndCourseAPI,
  FilterProductAndCourseReq,
} from '@services/public/FilterProductAndCourseAPI';
import { PublicProductInfo } from '@modules/public/components/products/PublicProductInfo';
import Title from 'antd/lib/typography/Title';
import { PublicCourseInfo } from '@modules/public/components/courses/PublicCourseInfo';
import { useDispatch, useSelector } from '@app/redux/hook';

const { Search } = Input;

const SearchProduct = (props: { type: 'courses' | 'products' }) => {
  const dispatch = useDispatch();

  const [filterProducts, { data: productData, isFetching: productFetching }] =
    FilterProductAndCourseAPI.endpoints.filterProducts.useLazyQuery({});

  const [filterCourses, { data: courseData, isFetching: courseFetching }] =
    FilterProductAndCourseAPI.endpoints.filterCourses.useLazyQuery({});

  const filter = useSelector((state) => state.filterProductAndCourse);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  useEffect(() => {
    if (filter) {
      const request: FilterProductAndCourseReq = {
        keyword: filter.keyword,
        grades: filter.grades,
        subjects: filter.subjects,
        categories: filter.categories,
      };

      if (props.type === 'products') {
        filterProducts(request);
      } else {
        filterCourses(request);
      }
    }
  }, [filter]);

  const handleOnSearch = (keyword: any) => {
    dispatch(filterKeyword(keyword));
  };

  const handleOnFocus = (isFocus: boolean) => {
    setShowAdvancedSearch(isFocus);
  };

  return (
    <div className={'search-product'}>
      <div className={'filter-product-group'}>
        <Search
          placeholder="input search text"
          enterButton="Search"
          size="large"
          loading={productFetching || courseFetching}
          onSearch={handleOnSearch}
          onFocus={() => handleOnFocus(true)}
          onBlur={() => handleOnFocus(false)}
        />

        {showAdvancedSearch && (
          <div className={'filter-product-advanced'}>
            {/*<FilterProduct/>*/}
          </div>
        )}
      </div>

      <div className={'product-result'}>
        {productData != null && productData.content != null && (
          <>
            <Divider />

            <div className={'filter-product-results'}>
              <Title level={2}>Products</Title>

              <Row gutter={[40, 20]} align="top">
                {productData.content.map((prod) => {
                  return (
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                      <PublicProductInfo item={prod} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        )}

        {courseData != null && courseData.content != null && (
          <>
            <Divider />
            <div className={'filter-product-results'}>
              <Title level={2}>Courses</Title>

              <Row gutter={[40, 20]} align="top">
                {courseData.content.map((crs) => {
                  return (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                      <PublicCourseInfo item={crs} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchProduct;
