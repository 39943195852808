import React from 'react';

import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import ManageStudentsPage from '@modules/users/pages/ManageStudentsPage';
import { PageHeader } from '@app/header/PageHeader';

const StudentManagement = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'school-student-page'}>
      <PageHeader
        menu={NavigationMenuEnum.USERS}
        subMenu={NavigationMenuEnum.USERS_STUDENTS}
        title={t('school_admin.header')}
        description={t('school_admin.header')}
      />

      <ManageStudentsPage />
    </ManagementLayout>
  );
};

export default StudentManagement;
