import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components/tooltip/Tooltip';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import CreateAssignment from '@modules/assignments/container/assignment/CreateAssignment';
import { IconUtils } from '@utils/IconUtils';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useProductTableOfContentContext } from '@modules/product/context/ProductTableOfContent';

export const CreateAssignmentButton = () => {
  const { t } = useTranslation();
  const { lesson, lessonId } = useViewLessonContext();

  const { productId, currentProductTocId: productTocId } =
    useProductTableOfContentContext();

  const [action, setAction] = useState({
    action: '',
    timestamp: -1,
  });

  const onCreate = () => {
    setAction({
      action: 'create-assignment',
      timestamp: Math.random(),
    });
  };

  const onClose = () => {
    setAction({
      action: '',
      timestamp: -1,
    });
  };

  if (lesson.assignable) {
    return (
      <>
        <Tooltip title={t('exercise.actions.create_assignment')}>
          <LessonButton
            type={'default'}
            shape={'circle'}
            onClick={onCreate}
            icon={IconUtils.assign}
          />
        </Tooltip>

        {action.action === 'create-assignment' && (
          <CustomModal
            header={t('lesson.actions.create_assignment')}
            className={CustomModalClassEnum.full_size_modal}
            closable={false}
            maskClosable={false}
            content={
              <CreateAssignment
                lessonId={lessonId}
                productTocId={productTocId}
                productId={productId}
                onSuccess={onClose}
                onCancel={onClose}
              />
            }
            onCloseFunc={onClose}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};
