import React, { useEffect, useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import styled from 'styled-components';
import { LanguageEnum, LanguageIcon } from '@components/language/LanguageIcon';
import { LanguageAPI } from '@services/setting/LanguageAPI';
import env from '@utils/config';
import type { MenuProps } from 'antd';

import {
  getDefaultLanguage,
  updateDefaultLanguage,
  updateLanguage,
} from '@app/redux/slices/appLanguageSlice';
import { useDispatch, useSelector } from '@app/redux/hook';

const SwitchLanguage = (props: {
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'bottom';
}) => {
  const dispatch = useDispatch();

  const { ready, defaultLanguage, languages } = useSelector(
    (state) => state.appLanguage
  );

  const [findByClientId, { data, isSuccess }] =
    LanguageAPI.endpoints.findByClientId.useLazyQuery();

  useEffect(() => {
    if (!ready) {
      findByClientId(env.CLIENT_ID);
    }
  }, [env.CLIENT_ID, ready]);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.defaultLanguage != null && data.languages.length > 0) {
        dispatch(
          updateLanguage({
            defaultLanguage: data.defaultLanguage.code,
            languages: data.languages.map((lang) => {
              return lang.code;
            }),
          })
        );
      } else {
        dispatch(
          updateLanguage({
            defaultLanguage: getDefaultLanguage(),
            languages: [getDefaultLanguage()],
          })
        );
      }
    }
  }, [data]);

  const changeLanguage = (lang: LanguageEnum) => {
    dispatch(updateDefaultLanguage(lang));
  };

  const menuItems: MenuProps['items'] = useMemo(() => {
    if (ready && languages != null && languages.length > 0) {
      return languages.map((lang) => {
        return {
          key: lang,
          label: (
            <SwitchLanguageStyle
              className={lang === defaultLanguage ? 'active' : ''}
              onClick={() => changeLanguage(lang)}
            >
              <LanguageIcon showLabel language={lang} height={20} />
            </SwitchLanguageStyle>
          ),
        };
      });
    } else {
      return [];
    }
  }, [ready, defaultLanguage, languages]);

  return (
    <>
      {ready && defaultLanguage != null && (
        <Dropdown
          menu={{ items: menuItems }}
          placement={props.placement ?? 'top'}
          arrow
        >
          <Button
            type={'text'}
            shape="default"
            size={'large'}
            style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
            icon={
              <span className={'anticon'}>
                <LanguageIcon language={defaultLanguage} height={16} />
              </span>
            }
          />
        </Dropdown>
      )}
    </>
  );
};

export default SwitchLanguage;

const SwitchLanguageStyle = styled.a`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 4.5em;
  padding: 0px 5px;

  &.active {
    font-weight: bold;
  }
`;
