import React, { ReactNode, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import { Select, SelectProps, Space } from 'antd';
import { UserTeacherAPI } from '@modules/users/services/UserTeacherAPI';
import { UserRes } from '@modules/users/services/model';
import { InputGroup } from '@components/input/InputGroup';
import { UserDisplayName } from '@components/avatar/UserDisplayName';
import { UserAvatar } from '@components/avatar/UserAvatar';

interface SelectTeacherProps extends SelectProps {
  formik?: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  disabled?: boolean;
  value: number;
  onChange: (value: number) => void;
}

export const SelectTeacher = (props: SelectTeacherProps) => {
  const [options, setOptions] = useState<UserRes[]>([]);

  const [findAll, { data, isSuccess, isFetching }] =
    UserTeacherAPI.endpoints.findAll.useLazyQuery({});

  useEffect(() => {
    findAll({
      page: 1,
      size: MAX_PAGE_SIZE,
      selectAll: true,
    });
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setOptions(data.content);
    }
  }, [data]);

  const handleOnInputChange = (value: number) => {
    props.onChange(value);
  };

  return (
    <InputGroup label={props.label ? props.label : 'Select a teacher'}>
      <Select
        style={{ width: '100%' }}
        size={'large'}
        placeholder={props.placeholder}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value}
        loading={isFetching}
        disabled={props.disabled}
      >
        <Select.Option key={-1} value={-1}>
          <Space>
            <UserAvatar src={''} size={32} />

            <span>Select a teacher</span>
          </Space>
        </Select.Option>

        {options.map((op) => {
          return (
            <Select.Option key={op.userId} value={op.userId}>
              <Space>
                <UserAvatar src={op.avatar} size={32} />

                <UserDisplayName
                  username={op.username}
                  firstName={op.firstName}
                  lastName={op.lastName}
                  email={op.email}
                />
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};
