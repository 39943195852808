import { FeatureGroup } from '@modules/public/components/features/FeatureGroup';
import { SiteMap } from '@router/SiteMap';
import { LinkSample } from '@modules/public/features/Link';
import { RoleEnum } from '@app/redux/slices/roles';

export const UserFeatures = () => {
  return (
    <div className={'group'}>
      <FeatureGroup header={'Quản lý học sinh'} roles={[RoleEnum.MANAGE_CLASS]}>
        <div>
          Mỗi giáo viên có 1 danh sách các học sinh khác nhau. Và giáo viên chỉ
          có thể thêm các học sinh vào lớp hoặc chia sẻ sách hoặc chia sẻ bài
          học hay giao bài tập nếu học sinh đó nằm trong danh sách.
        </div>

        <div>
          Nếu học viên chưa tồn tại trong hệ thống, giáo viên có thể tạo tài
          khoản mới cho học sinh.
        </div>

        <div>
          Nếu học sinh đã tồn tại, giáo viên gửi lời mời và chờ đợi học sinh
          đồng ý.
        </div>

        <dd>
          <dt>Tạo tài khoản học sinh đầy đủ thông tin</dt>
          <dl>
            <ol>
              <li>
                Truy cập quản lý học sinh
                <LinkSample href={SiteMap.management.students.path} />
              </li>

              <li>Click vào dấu + để tạo tài khoản</li>

              <li>Nhập thông tin đầy đủ</li>

              <li>Chọn create dể tạo tài khoản</li>

              <li>Đăng nhập với thông tin tài khoản này.</li>
              <li>Đăng nhập thành công.</li>

              <li>
                Expectation với trường hợp tạo tài khoản: <br />
                Đăng nhập với thông tin tài khoản thành công.
                <br />
                Giáo viên có thể thêm học sinh vào lớp học của mình.
                <br />
              </li>

              <li>
                Expectation với trường hợp gửi lời mời.
                <br />
                Trạng thái của học sinh là đang pending. Trong danh sách Student
                Invitation có thông tin lời mời này.
              </li>
            </ol>
          </dl>

          <dt>Tạo tài khoản nhanh với username và password ngẫu nhiên</dt>
          <dl>
            <ol>
              <li>Click vào Invite students</li>
              <li>
                Nhập thông tin của các users cần tạo, và ấn vào find students
              </li>
              <li>
                Hiển thị danh sách users và trạng thái, chưa tạo hoặc đã tạo
              </li>
              <li>
                Nếu username chưa được sử dụng, click vào tạo tài khoản để tạo
                nhanh.
              </li>
              <li>Nếu username đã tồn tại, gửi lời mời đăng kí.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Quản lý lớp học'} roles={[RoleEnum.MANAGE_CLASS]}>
        <div>Lớp học là một nhóm học sinh có liên quan.</div>
      </FeatureGroup>

      <FeatureGroup header={'Kích hoạt tài khoản'}>
        <div className={'incompleted'}>
          Chức năng này giúp học sinh đăng nhập nhanh vào tài khoản của mình sau
          khi giáo viên tạo tài khoản cho họ
        </div>

        <dd className={'incompleted'}>
          <dt>Active Account</dt>
          <dl>
            <ol>
              <li>....</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Quản lý học sinh'}
        roles={SiteMap.management.students.roles}
      >
        <div>Tạo tài khoản cho học sinh</div>

        <dd>
          <dt>Tạo tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý học sinh{' '}
                <LinkSample href={SiteMap.management.students.path} />
              </li>
              <li>Click tạo tài khoản</li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Cập nhật tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý học sinh{' '}
                <LinkSample href={SiteMap.management.students.path} />
              </li>
              <li>Click icon edit account </li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Lock/Unlock account</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý học sinh{' '}
                <LinkSample href={SiteMap.management.students.path} />
              </li>
              <li>Click icon lock/unlock account </li>
              <li>Xác nhận</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Quản lý phụ huynh'}
        roles={SiteMap.management.parents.roles}
      >
        <div>Quản lý tài khoản phụ huynh</div>

        <dd>
          <dt>Tạo tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý phụ huynh
                <LinkSample href={SiteMap.management.parents.path} />
              </li>
              <li>Click tạo tài khoản</li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Cập nhật tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý phụ huynh
                <LinkSample href={SiteMap.management.parents.path} />
              </li>
              <li>Click icon edit account </li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Lock/Unlock account</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý phụ huynh
                <LinkSample href={SiteMap.management.parents.path} />
              </li>
              <li>Click icon lock/unlock account </li>
              <li>Xác nhận</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt className={'incompleted'}>Liên kết với học sinh</dt>
          <dl className={'incompleted'}>
            <ol>
              <li>
                Truy cập vào trang quản lý phụ huynh
                <LinkSample href={SiteMap.management.parents.path} />
              </li>
              <li>Chọn học sinh </li>
              <li>Lưu lại</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Quản lý giáo viên'}
        roles={SiteMap.management.teachers.roles}
      >
        <div>Quản lý tài khoản Giáo viên</div>

        <dd>
          <dt>Tạo tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý Giáo viên
                <LinkSample href={SiteMap.management.teachers.path} />
              </li>
              <li>Click tạo tài khoản</li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Cập nhật tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý Giáo viên
                <LinkSample href={SiteMap.management.teachers.path} />
              </li>
              <li>Click icon edit account </li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Lock/Unlock account</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý Giáo viên
                <LinkSample href={SiteMap.management.teachers.path} />
              </li>
              <li>Click icon lock/unlock account </li>
              <li>Xác nhận</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Phân quyền</dt>
          <dl>
            <div>
              Chức năng này cho phép giáo viên có các quyền của support school.
              Mặc định giáo viên chỉ có thể xem các lớp học mà mình được quản
              lý, tham gia các buổi học mà mình được assign và tạo assignment
              cũng như xem reports.
            </div>

            <div>
              Mô hình này triển khai giống mô hình của trường học do đó cần giới
              hạn các chức năng này lại và cần có cơ chế để mở khóa các tính
              năng này.
            </div>

            <ol>
              <li>
                Truy cập vào trang quản lý Giáo viên
                <LinkSample href={SiteMap.management.teachers.path} />
              </li>
              <li>Chọn Update permission</li>
              <li>Lưu lại</li>
              <li>
                Login vào tài khoản giáo viên, click vào menu My account, click
                vào avatar để hiển thị các permission hiện hữu và đối chiếu với
                bảng permisson ở View Teacher Info.
              </li>
            </ol>
          </dl>

          <dt>Các menu bổ sung</dt>
          <dl>
            <p>
              Có thể đưa nhiều hơn các menu vào trang này, ví dụ, xem số class
              mà teacher đang quản lý, xem session đang được tạo cho teacher
              này, xem assignment và kết quả, xem license của teacher.
            </p>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Quản lý School Support'}
        roles={SiteMap.management.supports.roles}
      >
        <p>School Support là tài khoản được tạo bởi school.</p>

        <p>
          School Support là School với lược giản các quyền cho phép School
          Support có thể hỗ trợ giáo viên trong việc dạy và học. School Support
          có thể truy xuất danh sách giáo viên, danh sách học sinh, lớp học, tạo
          event...
        </p>

        <p>
          School Support không quản lý các page site, quản lý thanh toán và các
          setting của school nếu không được phép.
        </p>

        <p>
          School Support không có class, không có học sinh, không có calendar,
          cũng như assignment nhưng school support có thể tạo class, học sinh,
          calendar assignment cho giáo viên.
        </p>

        <dd>
          <dt>Tạo tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Click tạo tài khoản</li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Cập nhật tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Click icon edit account </li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Lock/Unlock account</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Click icon lock/unlock account </li>
              <li>Xác nhận</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt className={'incompleted'}>Phân quyền</dt>
          <dl className={'incompleted'}>
            <div>Phân quyền của School xuống cho School Support.</div>

            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Chọn Update permission</li>
              <li>Lưu lại</li>
              <li>
                Login vào tài khoản giáo viên, click vào menu My account, click
                vào avatar để hiển thị các permission hiện hữu và đối chiếu với
                bảng permisson ở View Teacher Info.
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Quản lý School Admin'}
        roles={SiteMap.management.school_admins.roles}
      >
        <p>
          School Support là tài khoản được tạo bởi school. School Support là
          School với lược giản các quyền cho phép School Support có thể hỗ trợ
          giáo viên trong việc dạy và học.
        </p>

        <p>
          School Support được toàn quyền quản lý tài khoản học sinh, giáo viên,
          parent, class. Toàn quyền = Thêm, cập nhật, disabled/enable các thông
          tin.
        </p>
        <p>
          School Support có thể tạo event(lên lịch dạy) cũng như assignment cho
          giáo viên.
        </p>

        <dd>
          <dt>Tạo tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Click tạo tài khoản</li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Cập nhật tài khoản</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Click icon edit account </li>
              <li>Nhập thông tin và lưu lại</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt>Lock/Unlock account</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Click icon lock/unlock account </li>
              <li>Xác nhận</li>
              <li>Xem thông tin cập nhật. </li>
            </ol>
          </dl>

          <dt className={'incompleted'}>Phân quyền</dt>
          <dl className={'incompleted'}>
            <div>Phân quyền của School xuống cho School Support.</div>

            <ol>
              <li>
                Truy cập vào trang quản lý School Support
                <LinkSample href={SiteMap.management.supports.path} />
              </li>
              <li>Chọn Update permission</li>
              <li>Lưu lại</li>
              <li>
                Login vào tài khoản giáo viên, click vào menu My account, click
                vào avatar để hiển thị các permission hiện hữu và đối chiếu với
                bảng permisson ở View Teacher Info.
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>
    </div>
  );
};
