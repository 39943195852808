import { FeatureGroup } from '@modules/public/components/features/FeatureGroup';
import { SiteMap } from '@router/SiteMap';
import { LinkSample } from '@modules/public/features/Link';
import { RoleEnum } from '@app/redux/slices/roles';

export const UserProfileFeatures = () => {
  return (
    <div className={'group'}>
      <FeatureGroup
        header={'User Profile'}
        roles={[
          RoleEnum.STUDENT,
          RoleEnum.PARENT,
          RoleEnum.TEACHER,
          RoleEnum.SCHOOL,
        ]}
      >
        <div>
          Tính năng này giúp học viên, giáo viên quản lý thông tin của mình
        </div>

        <dd>
          <dt>Cập nhật thông tin tài khoản.</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào my account.
                <LinkSample href={SiteMap.private.my_account.index} />
              </li>

              <li>Cập nhật thông tin tài khoản (tên, email, avatar).</li>

              <li>Cập nhật ngôn ngữ mặc định hoặc giao diện.</li>

              <li>
                Sau khi cập nhật thì avatar thay đổi, tên hiển thị thay đổi
              </li>
              <li>
                Sau khi cập nhật thì ngôn ngữ thay đổi (= switch language)
              </li>
              <li>Sau khi cập nhật thì giao diện thay đổi.</li>
            </ol>
          </dl>
        </dd>

        <dd>
          <dt>Đổi mật khẩu</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào my account.
                <LinkSample href={SiteMap.private.my_account.index} />
              </li>

              <li>Nhập mật khẩu cũ</li>

              <li>Nhập mật khẩu mới và xác nhận</li>

              <li>Logout và đăng nhập bằng mật khẩu mới</li>
              <li>Đăng nhập thành công.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Zoom Account'}>
        <div>
          Chức năng này cho phép giáo viên có thể sử dụng dịch vụ của Zoom để
          dạy học online
        </div>
        <dd>
          <dt>Zoom Account</dt>
          <dl>
            <ol>
              <li>Hiển thị nút help ở đây.</li>
              <li>
                Click nút help sẽ hiển thị màn hình cửa sổ hướng dẫn lấy thông
                tin của Zoom
              </li>
              <li>
                Giáo viên thực hiện theo hướng dẫn và điền các thông tin vào và
                save lại.
              </li>
              <li>Vào Calendar thấy có enable Zoom</li>
              <li>Vào Class Room có thể click vào icon video để mở Zoom.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Become Pro User'} roles={[RoleEnum.TEACHER]}>
        <div>Chức năng này để chuyển đổi giao diện đơn giản sang phức tạp</div>

        <dd>
          <dt>Become Pro User</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào my account.
                <LinkSample href={SiteMap.private.my_account.index} />
              </li>
              <li>Click become Pro User</li>
              <li>Hiển thị màn hình hướng dẫn bao gồm: </li>
              <li>
                Chuyển đổi giữa giao diện đơn giản, sang tạo bài học từ thư viện
                (create lesson feature)
              </li>
              <li>Custom lại thông tin điểm số mặc định (thêm image)</li>
              <li>
                Hiển thị standard và mở khóa standard reports (thêm image)
              </li>
              <li>
                Chức năng xây dựng bài học từ thư viện câu hỏi (thêm image)
              </li>
              <li>
                Chức năng xây dựng bài học nâng cao (section) (thêm image).
              </li>
              <li>Xác nhận chuyển đổi</li>
              <li>Các menu và tính năng nâng cao được hiển thị</li>
            </ol>
          </dl>
        </dd>

        <dd>
          <dt>Tắt chức năng Pro user</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào my account.
                <LinkSample href={SiteMap.private.my_account.index} />
              </li>
              <li>Click Confirm ở màn hình Change to Simple Users</li>
              <li>Các menu và tính năng nâng cao bị ẩn.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Chức năng phân quyền'}
        roles={[RoleEnum.SCHOOL, RoleEnum.ADMIN]}
      >
        <div>
          Chức năng này được sinh ra để giới hạn tính năng của giáo viên.
        </div>

        <dd>
          <dt>Phân quyền</dt>
          <dl>
            <ol>
              <li>Login bằng tài khoản school admin/ admin</li>
              <li>
                Truy cập vào trang quản lý giáo viên
                <LinkSample href={SiteMap.management.teachers.path} />
              </li>
              <li>Click Update Permission</li>
              <li>Check và uncheck các permission của teacher đó</li>
            </ol>
          </dl>

          <dt>Check list</dt>
          <dl>
            <ol>
              <li>Login vào tài khoản của giáo viên.</li>
              <li>Click vào my Account icon, click vào logo.</li>
              <li>hiển thị danh sách permission của giáo viên.</li>
              <li>Kiểm tra permissions xem đã đúng với check list hay chưa.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Chức năng public profile'}
        roles={[RoleEnum.TEACHER]}
      >
        <div>
          Chức năng này cho phép các học viên tìm thấy bạn trong trang{' '}
          <LinkSample href={SiteMap.public.teachers} />{' '}
        </div>

        <dd>
          <dt>Tạo public profile</dt>
          <dl>
            <ol>
              <li>Login bằng tài khoản giáo viên</li>
              <li>
                Truy cập vào trang public profile
                <LinkSample href={SiteMap.private.my_account.public_profile} />
              </li>
              <li>Nhập thông tin cần thiết</li>
              <li>Click Review</li>
              <li className={'incompleted'}>
                Xem review page{' '}
                <LinkSample href={SiteMap.private.my_account.review} /> và xác
                nhận{' '}
              </li>
            </ol>
          </dl>

          <dt>Check list</dt>
          <dl>
            <ol>
              <li>
                Quay lại trang public teachers{' '}
                <LinkSample href={SiteMap.public.teachers} />
              </li>
              <li>Profile của giáo viên xuất hiện với đầy đủ thông tin</li>
              <li>Click vào và hiển thị chi tiết thông tin giáo viên.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>
    </div>
  );
};
