import React, { ReactNode, useRef, useState } from 'react';
import { useWindowSizeChange } from '@hooks/useWindowSize';
import styled from 'styled-components';

export const DynamicContainer = (props: {
  className?: string;
  setting: {
    width: number;
    height: number;
    fontSize: number;
  };
  onChange?: (width: number, height: number) => void;
  children: ReactNode;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // const [parentProps, setParentProps] = useState({
  //   width: props.setting.width,
  //   height: props.setting.height,
  // });

  const [ctnProps, setCtnProps] = useState({
    width: props.setting.width,
    height: props.setting.height,
    fontSize: props.setting.fontSize,
  });

  useWindowSizeChange(() => {
    handleWindowChange();
  }, 500);

  const handleWindowChange = () => {
    if (elementRef.current) {
      const width = elementRef.current.offsetWidth;
      const height = elementRef.current.offsetHeight;

      const scale_x = width / props.setting.width;
      const scale_y = height / props.setting.height;

      const scale = scale_x < scale_y ? scale_x : scale_y;

      const newFontSize = Math.round(scale * props.setting.fontSize);

      const newWidth =
        (newFontSize * props.setting.width) / props.setting.fontSize;

      const newHeight =
        (newFontSize * props.setting.height) / props.setting.fontSize;

      // setParentProps({
      //   width: width,
      //   height: height,
      // });

      setCtnProps({
        width: newWidth,
        height: newHeight,
        fontSize: newFontSize,
      });

      if (props.onChange) {
        props.onChange(newWidth, newHeight);
      }
    }
  };

  return (
    <DynamicContainerStyle className={props.className} ref={elementRef}>
      <div
        className={'dynamic-content-container'}
        style={{
          width: ctnProps.width,
          height: ctnProps.height,
          fontSize: ctnProps.fontSize,
        }}
      >
        {props.children}
      </div>
    </DynamicContainerStyle>
  );
};

const DynamicContainerStyle = styled.div`
  position: relative;

  .dynamic-content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
