import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
  TimelineProps,
} from '@cms/ComponentInteface';
import React, { useState } from 'react';
import { AudioContentProps } from '@cms/content/ContentType';
import styled from 'styled-components';

import AudioCompEditable from './AudioCompEditable';
import { AudioCompContextProvider } from './AudioCompContext';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { AudioViewerComp } from './AudioCompViewer';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

export interface AudioCompSettingProps extends CompInteractSettingProps {
  layout: '' | 'subtitle';
  showSubTitle?: boolean;
}

export interface AudioCompProps extends CompProps {
  configuration: {
    content: AudioContentProps;
    sourceItems: TimelineProps[];
  };
  setting: AudioCompSettingProps;
}

const AudioCompStyle = styled.div`
  audio {
    max-width: 100%;
  }
`;

export function AudioComp(props: {
  item: AudioCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const [showComponent, setShowComponent] = useState(true);

  const handleOnChange = (time: number) => {
    props.onChange({
      id: props.item.id,
      type: props.item.type,
      answer: time,
    });
  };

  return (
    <AudioCompStyle className={'comp comp-audio'}>
      <AudioCompContextProvider item={props.item}>
        {showComponent && (
          <>
            <AudioViewerComp onChange={handleOnChange} />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </>
        )}

        <ComposeModeContent>
          {!showComponent && (
            <AudioCompEditable onClose={() => setShowComponent(true)} />
          )}
        </ComposeModeContent>
      </AudioCompContextProvider>
    </AudioCompStyle>
  );
}
