import { createContext } from 'react';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';

export const ReviewProductContext = createContext({
  productId: -1 as number,
  selectedProductTocId: -1 as number,
  selectLessonId: -1 as number,

  setSelectLesson: (lesson: LessonRes, productToc: ProductTocRes) => {
    console.log(lesson, productToc);
  },
  productTocs: [] as ProductTocRes[],
  setProductTocs: (data: any) => {
    console.log(data);
  },
});

export const BuildProductContext = createContext({
  productId: -1 as number,

  productTocs: [] as ProductTocRes[],
  setProductTocs: (data: any) => {
    console.log(data);
  },

  selectedLessons: [] as number[],
  setSelectedLessons: (data: any) => {
    console.log(data);
  },
});
