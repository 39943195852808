import React from 'react';

import FindProductPage from '@modules/public/pages/FindProductPage';
import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';

const Products = () => {
  return (
    <PublicLayout className={'products-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_PRODUCTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'All Products'}
        description={'Products'}
      />
      <PublicPageContainer>
        <FindProductPage />
      </PublicPageContainer>
    </PublicLayout>
  );
};

export default Products;
