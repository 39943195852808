import { CompTypeEnum } from '@cms/ComponentInteface';
import { VideoCompProps } from '@cms/comps/content/VideoComp';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';

export const StaticVideoData: VideoCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.VIDEO,

  configuration: {
    content: {
      id: 'video_comp_example',
      type: AssetsTypeEnum.VIDEO,
      data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/Wc4bsDCGQ4s7EjJU.file_example_MP4_1280_10MG.mp4',
      width: 300,
      height: 200,
    },
  },
};
