import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { LongMultiplicationCompProps } from '@cms/comps/math/operation/multiplication/LongMultiplicationComp';
import { LongOperationLayoutEnum } from '@cms/comps/math/operation/OperationUtils';

export const LongMultiplicationExampleData: LongMultiplicationCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.LONG_MULTIPLICATION,

  configuration: {
    multiplier: '35',
    multiplicand: '12',
    expression: [
      {
        type: 'multiplier',
        exp: ['_', '_', '3', '5'],
      },
      {
        type: 'multiplicand',
        exp: ['*', '_', '1', '2'],
      },
      {
        type: 'template',
        exp: ['_', '_', 'x', 'x'],
      },
      {
        type: 'template',
        exp: ['+', 'x', 'x', '_'],
      },
      {
        type: 'product',
        exp: ['_', 'x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    removeZero: true,
    layout: LongOperationLayoutEnum.vertical,
  },
};
