import { FormikProvider, useFormik } from 'formik';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import Checkbox from '@components/input/Checkbox';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import React, { useEffect } from 'react';
import { useLoadSchoolSetting } from '@modules/setting/container/school-setting/useLoadSchoolSetting';
import { useTranslation } from 'react-i18next';
import {
  SchoolSettingAPI,
  UpdateSchoolUserSettingReq,
} from '@services/private/SchoolSettingAPI';
import { H2 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

export const SchoolUserSettingForm = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onError: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();
  const { data, isSuccess, isFetching } = useLoadSchoolSetting(props.schoolId);

  const [updateUserSetting, { isLoading }] =
    SchoolSettingAPI.endpoints.updateUserSetting.useMutation({});

  const [updateUserSettingAdmin, { isLoading: isAdminLoading }] =
    SchoolSettingAPI.endpoints.updateUserSettingAdmin.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      accessStudentInSchool: false,
    },

    onSubmit: (values) => {
      if (props.schoolId != null && props.schoolId > 0) {
        updateSchoolSettingAdmin({ ...values, schoolId: props.schoolId });
      } else {
        updateSchoolSetting(values);
      }
    },
  });

  useEffect(() => {
    if (data && isSuccess) {
      formik.setFieldValue('accessStudentInSchool', data.accessStudentInSchool);
    }
  }, [data]);

  const updateSchoolSetting = (request: UpdateSchoolUserSettingReq) => {
    updateUserSetting(request)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  const updateSchoolSettingAdmin = (
    setting: Required<UpdateSchoolUserSettingReq>
  ) => {
    updateUserSettingAdmin(setting)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  return (
    <>
      <HeaderGroup style={{ marginTop: 0, marginBottom: 20 }}>
        <H2>Quản lý truy cập</H2>
      </HeaderGroup>

      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <Checkbox
              name={'accessStudentInSchool'}
              checked={formik.values.accessStudentInSchool}
              value={formik.values.accessStudentInSchool}
              formik={formik}
              onChange={formik.handleChange}
            >
              Teacher can access all students in school.
            </Checkbox>
          </Col>
        </Row>
        <div
          className="d-flex submit-container"
          style={{ justifyContent: 'space-between' }}
        >
          <ButtonGroup>
            <Button
              type="primary"
              shape={'round'}
              onClick={formik.submitForm}
              icon={IconUtils.save}
              loading={isFetching || isLoading || isAdminLoading}
            >
              <>{t('button.update')}</>
            </Button>

            {props.onCancel && (
              <Button type="default" shape={'round'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </FormikProvider>
    </>
  );
};
