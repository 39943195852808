import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import {
  CompConfiguration,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { FractionTypeEnum } from '@cms/comps/math/frac/FractionInlineComp';
import { FractionCompProps } from '@cms/comps/math/frac/FractionComp';

export const FractionExampleData: FractionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.FIB_FRACTION,

  configuration: {
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'x/y <= ' + CompConfiguration.INLINE_COMPONENT + ' <= x/y',
        },
      },
    ],
  },

  setting: {
    autoScore: false,
    digit: 'xxx',
    type: FractionTypeEnum.mixed_number,
  },
};
