import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { ClassRes, FilterClassReq } from '@modules/users/services/clazz';

export const MyClassAPI = createApi({
  reducerPath: 'MyClassAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyClasses: builder.query<PaginationRes<ClassRes>, FilterClassReq>({
      query: (params) => ({
        url: `/user/classes`,
        params: params,
      }),
    }),
  }),
});
