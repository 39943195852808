import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import React from 'react';
import {
  AssignmentCountDown,
  getAssignmentClass,
  getAssignmentScoreColor,
} from '../container/StudentAssignmentTable';
import { StudentExerciseStatus } from '@components/exercise/ExerciseStatus';
import styled from 'styled-components';
import Progress from 'antd/lib/progress';
import { PercentScoreIcon } from '@cms/icons/ComponentScoreIcon';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { ExerciseStatus } from '@modules/assignments/service/exercise_model';
import { AssignmentInstanceRes } from '@modules/assignments/service/assignment_model';

export const AssignmentItem = (props: { item: AssignmentInstanceRes }) => {
  const router = useRouter();

  const handleOnClick = () => {
    const isActive = props.item.unlockInMs <= 0;
    const isExpired = props.item.expiredInMs < 0;

    if (props.item.exercise.status === ExerciseStatus.COMPLETED) {
      viewAssignmentReport();
    } else if (isActive && !isExpired) {
      startAssignment();
    }
  };

  const startAssignment = () => {
    router.push(
      SiteMap.private.my_assignment.start_gen(props.item.assignmentInstanceId)
    );
  };

  const viewAssignmentReport = () => {
    router.push(
      SiteMap.private.my_assignment.review_gen(props.item.exercise.exerciseId)
    );
  };

  return (
    <AssignmentItemStyle className={'assignmentItem'} onClick={handleOnClick}>
      <div className={'assignment-thumbnail'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.lesson.thumbnails}
          ratio={ImageScale.lesson.ratio}
          minWidth={64}
        />
      </div>

      <div className={'assignment-info'}>
        <div>
          <b>{props.item.lesson.name}</b>
        </div>

        <StudentExerciseStatus
          unlockInMs={props.item.unlockInMs}
          expiredInMs={props.item.expiredInMs}
          status={props.item.exercise.status}
        />
      </div>

      <div
        className={`assignment-expired ${getAssignmentClass(
          props.item.expiredInMs
        )}`}
      >
        {props.item.exercise.status === ExerciseStatus.COMPLETED ? (
          <Progress
            format={() => {
              return (
                <PercentScoreIcon
                  score={props.item.exercise.score!}
                  maxScore={props.item.exercise.maxScore!}
                />
              );
            }}
            type={'circle'}
            percent={
              (props.item.exercise.score! * 100) / props.item.exercise.maxScore!
            }
            strokeWidth={5}
            strokeColor={getAssignmentScoreColor(
              (props.item.exercise.score! * 100) / props.item.exercise.maxScore!
            )}
            trailColor={'#ccc'}
            size={64}
          />
        ) : (
          <AssignmentCountDown item={props.item} />
        )}
      </div>
    </AssignmentItemStyle>
  );
};

const AssignmentItemStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .assignment-thumbnail {
    width: 72px;
    display: flex;

    .image-thumbnail-wrapper {
      border-radius: 6px;
      overflow: hidden;
    }
  }

  .assignment-info {
    flex-grow: 1;
    margin-left: 12px;
    margin-right: 12px;

    b {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 125%;
      overflow: hidden;
    }
  }

  .assignment-expired {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
`;
