import {
  ProgressReportAPI,
  ViewProductLessonTrackingRes,
} from '@services/reports/ProgressReport';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { useTranslation } from 'react-i18next';
import { StartExerciseContainer } from '@modules/product/container/student/StartExerciseContainer';
import {
  ProductRes,
  ProductTocRes,
} from '@modules/product/services/product_model';

const ProductTableOfContentContext = createContext({
  productId: -1 as number,
  product: {} as ProductRes,
  tableOfContent: [] as ProductTocRes[],
  progress: [] as ViewProductLessonTrackingRes[],
  currentLessonId: -1,
  currentProductTocId: -1,
  selectLesson: (productTocId: number, lessonId: number) => {
    console.log(productTocId, lessonId);
  },
});

export const ProductTableOfContentProvider = (props: {
  productId: number;
  productTocId: number;
  lessonId: number;
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  const [
    getProductProgress,
    {
      data: productProgress,
      isSuccess: progressSuccess,
      isLoading: progressLoading,
    },
  ] = ProgressReportAPI.endpoints.getProductProgress.useLazyQuery();

  const [getProductTocs, { data, isSuccess, isLoading }] =
    ProductAPI.endpoints.viewProduct.useLazyQuery();

  const [product, setProduct] = useState<ProductRes>({} as ProductRes);
  const [productTocs, setProductTocs] = useState<ProductTocRes[]>([]);
  const [progress, setProgress] = useState<ViewProductLessonTrackingRes[]>([]);

  const [selectedProductTocId, setSelectedProductTocId] = useState(
    props.productTocId
  );
  const [selectedLessonId, setSelectedLessonId] = useState(props.lessonId);

  useEffect(() => {
    if (props.productId > 0) {
      getProductTocs(props.productId);
      getProductProgress(props.productId);
    }
  }, [props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      setProduct(data.product);
      setProductTocs(data.tableOfContent);
    }
  }, [data]);

  useEffect(() => {
    if (productProgress && progressSuccess) {
      setProgress(productProgress);
    }
  }, [productProgress]);

  const setSelectedLesson = useCallback(
    (productTocId: number, lessonId: number) => {
      setSelectedProductTocId(productTocId);
      setSelectedLessonId(lessonId);
    },
    [props.productId]
  );

  const handleOnClose = () => {
    setSelectedLesson(-1, -1);
  };

  return (
    <ProductTableOfContentContext.Provider
      value={{
        productId: product.productId,
        product: product,
        tableOfContent: productTocs,
        progress: progress,
        currentProductTocId: selectedProductTocId,
        currentLessonId: selectedLessonId,
        selectLesson: setSelectedLesson,
      }}
    >
      {isLoading || progressLoading ? (
        <>{t('label.loading')}</>
      ) : (
        <>{props.children}</>
      )}

      {selectedLessonId > 0 && selectedProductTocId > 0 && (
        <StartExerciseContainer
          lessonId={selectedLessonId}
          productId={product.productId}
          productTocId={selectedProductTocId}
          onCancel={handleOnClose}
        />
      )}
    </ProductTableOfContentContext.Provider>
  );
};

export const useProductTableOfContentContext = () => {
  const context = useContext(ProductTableOfContentContext);
  if (!context) {
    throw new Error('You must wrap container by ProductTableOfContentProvider');
  }
  return context;
};
