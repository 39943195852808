import { SessionAPI } from '@services/private/SessionAPI';
import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Segmented, Space, Tabs } from 'antd';
import { SelectResourceBankComp } from '@cms/resource-bank/ResourceBank';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ClassRoomTemplate } from '@cms/lesson-template/classroom/ClassRoomTemplate';
import { useTranslation } from 'react-i18next';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { SessionRes } from '@services/model/session';
import { LessonRes } from '@modules/product/services/lesson_model';

const ClassRoomLibrary = (props: {
  item: SessionRes;
  onSelectResources: (res: ResourceProps[]) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [selectTabs, setSelectTabs] = useState('session-lesson');
  const [selectResourceIds, setSelectResourceIds] = useState<number[]>([]);
  const [selectResources, setSelectResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    const resourceIds = selectResources.map((rs) => {
      return rs.resourceId;
    });
    setSelectResourceIds(resourceIds);
  }, [selectResources]);

  const handleOnSelectResource = (resource: ResourceProps) => {
    // if uncheck
    if (selectResourceIds.includes(resource.resourceId)) {
      setSelectResources((prev) => {
        return [...prev].filter((item) => {
          return item.resourceId !== resource.resourceId;
        });
      });
    } else {
      setSelectResources((prev) => {
        return [...prev, resource];
      });
    }
  };

  const handleOnClick = () => {
    props.onSelectResources(selectResources);
  };

  const handleOnSelectTab = (tab: string) => {
    setSelectTabs(tab);
  };

  return (
    <ClassRoomLibraryStyle className={'class-room-library'}>
      <Segmented
        block
        size={'large'}
        value={selectTabs}
        onChange={(val) => handleOnSelectTab(val as string)}
        options={[
          {
            value: 'session-lesson',
            label: t('class_room.label.lesson_in_session'),
          },
          {
            value: 'library-lesson',
            label: t('class_room.label.lesson_in_library'),
          },
        ]}
        style={{ minWidth: 415, marginBottom: '2em' }}
      />

      {selectTabs === 'session-lesson' ? (
        <SessionLessonLibrary
          item={props.item}
          selectedResources={selectResourceIds}
          handleOnClick={handleOnSelectResource}
        />
      ) : (
        <SelectResourceBankComp
          selectedResources={selectResourceIds}
          handleOnClick={handleOnSelectResource}
        />
      )}

      <div className={'library-action'} style={{ marginTop: '2em' }}>
        <Space size={20} align={'end'}>
          <Button
            type={'default'}
            size={'large'}
            shape={'round'}
            onClick={props.onClose}
          >
            {t('button.back')}
          </Button>

          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            disabled={selectResourceIds.length === 0}
            onClick={handleOnClick}
          >
            {t('class_room.actions.add_slide')}
          </Button>
        </Space>
      </div>
    </ClassRoomLibraryStyle>
  );
};

export default ClassRoomLibrary;

const SessionLessonLibrary = (props: {
  item: SessionRes;
  selectedResources: number[];
  handleOnClick: (resources: ResourceProps) => void;
}) => {
  const [findById, { data: sessionData }] =
    SessionAPI.endpoints.findById.useLazyQuery({});

  useEffect(() => {
    findById(props.item.sessionId);
  }, [props.item.sessionId]);

  const tabItems: any[] = useMemo(() => {
    if (sessionData && sessionData.lessons) {
      return sessionData.lessons.map((ls: LessonRes) => {
        return {
          key: ls.lessonId,
          label: (
            <Space size={10}>
              <ThumbnailsImg
                type={'icon'}
                src={ls.thumbnails}
                ratio={ImageScale.product.ratio}
                width={ImageScale.product.icon}
              />
              <LessonNameStyle>{ls.name}</LessonNameStyle>
            </Space>
          ),
          children: (
            <ClassRoomTemplate
              lessonId={ls.lessonId}
              selectedResources={props.selectedResources}
              onSelectResource={props.handleOnClick}
            />
          ),
        };
      });
    } else {
      return [];
    }
  }, [sessionData, props.selectedResources.join('')]);

  return (
    <SessionLessonLibraryStyle>
      <div className={'session-lesson'}>
        {sessionData &&
          sessionData.lessons &&
          sessionData.lessons.length > 0 && (
            <Tabs
              tabPosition={'left'}
              className={'session-lesson-items'}
              items={tabItems}
            />
          )}
      </div>
    </SessionLessonLibraryStyle>
  );
};

const ClassRoomLibraryStyle = styled.div`
  > .ant-tabs.ant-tabs-top {
    > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        width: 50%;
        text-align: center;
        font-size: 18px;

        &:first-child {
          justify-content: flex-end !important;
        }
      }
    }
  }

  .library-action {
    margin-top: 2em;
    display: flex;
    justify-content: flex-end;
  }

  .resource-bank-container {
    flex-direction: row;

    .filter-resource-bank {
      width: 25%;
      min-width: 25%;
      max-width: 25%;
      padding-right: 20px;
      border-right: 1px solid #ccc;
      margin-right: 20px;
      border-bottom: 0px;
    }
  }
`;

const LessonNameStyle = styled.span`
  display: inline-block;
  max-width: 250px;
  text-align: left;
  white-space: normal;
`;

const SessionLessonLibraryStyle = styled.div`
  min-height: 33vh;

  .session-lesson-items.ant-tabs {
    .ant-tabs-nav {
      min-width: 30%;

      .ant-tabs-tab {
        width: 100%;
        padding-left: 0px;
        justify-content: flex-start;
        margin-top: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.ant-tabs-tab-active {
          font-weight: bold;
        }
      }
    }
  }
`;
