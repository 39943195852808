import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import React from 'react';
import { CompMode } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceItemViewer } from '@cms/context/ResourceContextProvider';

export interface PresentationSectionProps extends LessonSectionProps {
  type: SectionTypeEnum.presentation;
  group: {
    content: ResourceProps[];
  };
}

export const PresentationSection = (props: {
  item: PresentationSectionProps;
  mode: CompMode;
}) => {
  return (
    <PresentationSectionStyle className={`lesson-section presentation-section`}>
      {props.item.group.content &&
        props.item.group.content.map((rs) => {
          return <ResourceItemViewer key={rs.resourceId} item={rs} />;
        })}
    </PresentationSectionStyle>
  );
};

const PresentationSectionStyle = styled.div``;
