import { EntityStatusEnum } from '@services/model/common';
import { PaginationReq } from '@services/model/PaginationRes';
import { CertificateRes, ProgramRes } from '@modules/admin/service/program';
import { UserInfoRes } from '@modules/users/services/model';
import { ProductRes } from '@modules/product/services/product_model';

export enum ClassRosterEnumType {
  STUDENT = 0,
  MAIN_TEACHER = 1,
  SECONDARY_TEACHER = 2,
}

export interface ClassRosterReq {
  classId: number;
  userId: number;
}

export interface ClassRes {
  classId: number;
  code: string;
  name: string;
  description: string;
  createdDate: string;
  status: EntityStatusEnum;
  color: string;
  rosters: ClassRosterRes[];

  program?: ProgramRes;
  certificate?: CertificateRes;
}

export interface SimpleClassRosterRes {
  user: UserInfoRes;
  clazz: ClassRes;
}

export interface ClassRosterRes extends SimpleClassRosterRes {
  classRosterId: number;
  type: ClassRosterEnumType;
  status: EntityStatusEnum;
  joinDate: string;
}

export interface CreateOrUpdateClass {
  classId?: number;
  code: string;
  name: string;
  description: string;
  color: string;
}

export interface InviteStudentReq {
  classId: number;
  studentId?: number;
  email?: string;
}

export interface AddStudentToClassReq {
  classId: number;
  studentId: number;
}

export interface DisabledClassReq {
  classId: number;
  disabled: boolean;
}

export interface FilterClassReq extends PaginationReq {
  teacherId?: number;
}

export interface FilterStudentClassReq extends PaginationReq {
  classId?: number;
  keyword?: string;
}

export interface ViewClassRes extends ClassRes {
  mainTeacher: UserInfoRes;
  secondTeacher: UserInfoRes;
}

export interface ClassFilterReq extends PaginationReq {
  classId: number;
}

export interface ClassResourceRes {
  classResourceId: number;
  product: ProductRes;
  clazz: ClassRes;
  createdDate: string;
  hasLicenseIds: number[];
  licenses: number;
  totalStudents: number;
}

export interface CreateClassResourceReq {
  classId: number;
  productId: number;
}
