import { EntityStatusEnum } from '@services/model/common';
import { PaginationReq } from '@services/model/PaginationRes';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface CategoryRes {
  categoryId: number;
  name: string;
  description: string;
  displayOrder: number;

  createdDate: string;
  modifiedDate: string;
}

export interface CreateCategoryReq {
  categoryId: number;
  name: string;
  description: string;
  displayOrder: number;
}

export interface GradeRes {
  gradeId: number;
  name: string;
  description: string;
  displayOrder: number;

  createdDate: string;
  modifiedDate: string;
}

export interface CreateGradeReq {
  gradeId: number;
  name: string;
  description: string;
  displayOrder: number;
}

export interface SubjectRes {
  subjectId: number;
  name: string;
  description: string;
  displayOrder: number;
  createdDate: string;
  modifiedDate: string;
}

export interface CreateSubjectReq {
  subjectId: number;
  name: string;
  description: string;
  displayOrder: number;
}

export interface LessonTypeRes {
  lessonTypeId: number;
  name: LessonTypeEnum;
  description: string;
  displayOrder: number;

  status: EntityStatusEnum;

  createdDate: string;
  modifiedDate: string;
}

export interface CreateLessonTypeReq {
  lessonTypeId: number;
  status: EntityStatusEnum;
  name: string;
  description: string;
  displayOrder: number;
}

export interface FilterLessonExampleRes extends PaginationReq {
  category: LessonTypeEnum;
}

export interface LessonExampleRes {
  lessonExampleId: number;

  category: LessonTypeEnum;
  code: string;
  name: string;
  description: string;
  displayOrder: number;

  status: EntityStatusEnum;
  createdDate: string;
  modifiedDate: string;
  publishedDate: string;
  lesson: LessonRes;
}

export interface CreateLessonExampleReq {
  lessonExampleId?: number;
  lessonId?: number;

  category: LessonTypeEnum;
  code: string;
  name: string;
  description: string;
  displayOrder: number;
}

export enum LessonTypeEnum {
  empty = '',

  lesson = 'lesson', // the resource is lesson type
  collection = 'collection', // create by student.
  game = 'game',
  template = 'template', // lesson example created by system admin.

  page = 'page', // the resource is page type
  presentation = 'presentation', // the resource is presentation in classroom
  survey = 'survey', // the resource is survey type.
}
