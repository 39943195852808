import React, { ReactNode } from 'react';
import { Select, SelectProps, Space } from 'antd';
import { LanguageEnum, LanguageIcon } from '@components/language/LanguageIcon';
import { useSelector } from '@app/redux/hook';

interface SelectLanguageProps extends SelectProps {
  value?: string;
  placeholder?: ReactNode;
  onChange?: (value: LanguageEnum) => void;
}

export const SelectLanguage = (props: SelectLanguageProps) => {
  const { defaultLanguage, languages } = useSelector(
    (state) => state.appLanguage
  );

  const handleOnInputChange = (value: LanguageEnum) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      size={'large'}
      placeholder={props.placeholder}
      onChange={(_value) => handleOnInputChange(_value as LanguageEnum)}
      value={props.value ? props.value : defaultLanguage}
    >
      <Select.Option key={''} value={defaultLanguage}>
        <Space>
          <span>Select Language</span>
        </Space>
      </Select.Option>

      {languages &&
        languages.map((lang) => {
          return (
            <Select.Option key={lang} value={lang}>
              <LanguageIcon height={22} language={lang} showLabel={true} />
            </Select.Option>
          );
        })}
    </Select>
  );
};
