import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import Card, { CardClassEnum } from '@components/card';

import { DateLabel } from '@components/text/DateLabel';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';

import { UserProductLicenseAPI } from '@modules/license/services/UserProductLicenseAPI';
import { ResponsiveScreen } from '@hooks/useMedia';
import TablePagination from '@components/table/TablePagination';
import { H1, H5 } from '@components/typography';
import { InputGroup } from '@components/input/InputGroup';
import styled from 'styled-components';
import { UserRes } from '@modules/users/services/model';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { UserDisplayName } from '@components/avatar/UserDisplayName';
import ButtonGroup from '@components/button/ButtonGroup';
import { Button, Divider, Dropdown, notification } from 'antd';
import { IconUtils } from '@utils/IconUtils';

import useConfirmModal from '@components/modal/ConfirmModal';
import { EditProductLicenseForm } from '../components/EditProductLicenseForm';
import { AddLicenseForUser } from '@modules/license/components/AddLicenseForUser';
import { ProductLicenseRes, ViewUserProductLicense } from '../services/model';

export const UserProductLicense = (props: {
  user: UserRes;
  schoolId?: number;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [getProductLicense, { data, isFetching, isLoading }] =
    UserProductLicenseAPI.endpoints.getUserProductLicense.useLazyQuery({});

  const [removeLicense] =
    UserProductLicenseAPI.endpoints.removeLicense.useMutation({});

  const [params, setParams] = useState<ViewUserProductLicense>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'expiredDate',
    direction: 'ascend',
    userId: props.user.userId,
  });

  const [action, setAction] = useState({
    action: '' as string,
    params: null as null | ProductLicenseRes,
  });

  useEffect(() => {
    refreshPage();
  }, [params]);

  const refreshPage = useCallback(() => {
    getProductLicense(params);
  }, [params]);

  const columns = [
    {
      title: t('label.thumbnails'),
      dataIndex: 'product.thumbnails',
      key: 'product.thumbnails',
      width: 90,
    },
    {
      title: t('label.name'),
      dataIndex: 'product.name',
      key: 'product.name',
      sorter: true,
    },
    {
      title: t('label.available_date'),
      dataIndex: 'availableDate',
      key: 'availableDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.expired_date'),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: true,
      align: 'center',
      width: 200,
    },

    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      sorter: false,
      align: 'center',
      width: 120,
    },
  ];

  const renderMobile = (item: ProductLicenseRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <ProductLicenseItem item={item} onEdit={onEdit} onRemove={onRemove} />
      </Card>
    );
  };

  const processDataRow = (item: ProductLicenseRes) => {
    return {
      key: item.product.productId,
      'product.thumbnails': (
        <ThumbnailsImg
          type={'icon'}
          src={item.product.icon}
          ratio={ImageScale.product.ratio}
        />
      ),
      'product.name': item.product.name,
      availableDate: <DateLabel label={item.availableDate} />,
      expiredDate: <DateLabel label={item.expiredDate} />,
      actions: (
        <ProductLicenseDropDown
          item={item}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ),
    };
  };

  const onAddLicense = () => {
    setAction({
      action: 'assign-license',
      params: null,
    });
  };

  const onEdit = (productLicense: ProductLicenseRes) => {
    setAction({
      action: 'edit-license',
      params: productLicense,
    });
  };

  const onRemove = (productLicenseId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('page_site.warning.remove_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          removeLicense({
            userId: props.user.userId,
            productLicenseId: productLicenseId,
          })
            .unwrap()
            .then(() => {
              notification.success({
                message: t('license.warning.remove_success'),
                description: t('license.warning.remove_success_message'),
                placement: 'bottomRight',
              });

              refreshPage();
            })
            .catch(() => {
              notification.error({
                message: t('license.warning.remove_error'),
                description: t('license.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return {
        ...prev,
        ...params,
      };
    });
  };

  const handleOnSuccess = () => {
    refreshPage();
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setAction({
      action: '',
      params: null,
    });
  };

  return (
    <div className={'licenses'}>
      <HeaderGroup>
        <H1>
          <UserDisplayName
            username={props.user.username}
            firstName={props.user.firstName}
            lastName={props.user.lastName}
            email={props.user.email}
          />
          <span className={'default'}>&nbsp;Licenses</span>
        </H1>

        {action.action === '' && (
          <Button
            type={'primary'}
            shape={'round'}
            icon={IconUtils.menu.license}
            onClick={onAddLicense}
          >
            {t('license.actions.assign_license')}
          </Button>
        )}
      </HeaderGroup>

      {action.action === 'assign-license' && (
        <>
          <AddLicenseForUser
            userId={props.user.userId}
            onSuccess={handleOnSuccess}
            onCancel={handleOnCancel}
          />
          <Divider plain>{t('license.actions.assign_license')}</Divider>
        </>
      )}

      {action.action === 'edit-license' && action.params != null && (
        <>
          <EditProductLicenseForm
            userId={props.user.userId}
            data={action.params as ProductLicenseRes}
            onSuccess={handleOnSuccess}
            onCancel={handleOnCancel}
          />
        </>
      )}

      {action.action !== 'edit-license' && (
        <>
          <TablePagination
            params={params}
            isLoading={isFetching || isLoading}
            columns={columns}
            data={data}
            refresh={handleOnRefresh}
            processDataRow={processDataRow}
            responsive={{
              screen: [
                ResponsiveScreen.xs,
                ResponsiveScreen.sm,
                ResponsiveScreen.md,
              ],
              render: renderMobile,
            }}
          />

          <ButtonGroup type={'right'} className={'submit-container'}>
            <Button
              type={'default'}
              size={'large'}
              shape={'round'}
              onClick={props.onCancel}
            >
              <>{t('button.close')}</>
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
};

const ProductLicenseItem = (props: {
  item: ProductLicenseRes;
  onEdit: (item: ProductLicenseRes) => void;
  onRemove: (productLicenseId: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <ProductLicenseStyle className={`product-license-item`}>
      <div className={'product-info'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.product.icon}
          ratio={ImageScale.product.ratio}
        />
      </div>

      <div className={'product-title'}>
        <H5>{props.item.product.name}</H5>

        <InputGroup label={t('label.available_date')}>
          <DateLabel label={props.item.availableDate} />
        </InputGroup>

        <InputGroup label={t('label.expired_date')}>
          <DateLabel label={props.item.expiredDate} />
        </InputGroup>
      </div>

      <div className={'product-actions'}>
        <ProductLicenseDropDown
          item={props.item}
          onEdit={props.onEdit}
          onRemove={props.onRemove}
        />
      </div>
    </ProductLicenseStyle>
  );
};

const ProductLicenseDropDown = (props: {
  item: ProductLicenseRes;
  onEdit: (item: ProductLicenseRes) => void;
  onRemove: (productLicenseId: number) => void;
}) => {
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'edit',
            icon: IconUtils.actions.edit,
            label: 'Update License',
            onClick: () => props.onEdit(props.item),
          },
          {
            key: 'remove',
            icon: IconUtils.actions.delete,
            label: 'Remove License',
            onClick: () => props.onRemove(props.item.productLicenseId),
          },
        ],
      }}
      trigger={['click']}
    >
      <Button type="default" shape="circle" icon={IconUtils.more_icon} />
    </Dropdown>
  );
};

export const ProductLicenseStyle = styled.div`
  position: relative;
  display: flex;
  gap: 1em;

  .product-info {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    display: flex;
    overflow: hidden;

    width: ${ImageScale.product.icon * 3}px;
    height: ${ImageScale.product.icon * 3}px;
    min-width: ${ImageScale.product.icon * 3}px;
  }

  .product-title {
    cursor: pointer;
    flex-grow: 1;

    .ant-typography {
      color: ${(props) => props.theme.app.primary};
      white-space: normal;
    }

    .ant-progress {
      width: 100%;
    }
  }

  .product-actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;
