import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';

export enum OptionTypeEnum {
  EMPTY_ABC = 'EMPTY_ABC',
  ABC = 'ABC',
  NUMBER = 'NUMBER',
}

class OptionGroupSupportUtils {
  updateOption(index: number, option: any, options: any[]) {
    return COMPONENT_UTILS.updateAtIndex(options, index, option);
  }

  insertOption(
    index: number,
    option: any,
    options: any[],
    type: OptionTypeEnum
  ) {
    const cloneOption = {
      label: option.label,
      content: { ...option.content, id: COMPONENT_UTILS.generateUID() },
    };

    const newOptions = COMPONENT_UTILS.insertAtIndex(
      options,
      index,
      cloneOption
    );

    return newOptions.map((op, optIndex) => {
      return { ...op, label: this.getLabelByIndex(optIndex, type) };
    });
  }

  removeOption(index: number, options: any[], type: OptionTypeEnum) {
    const newOptions = COMPONENT_UTILS.removeAtIndex(options, index);

    return newOptions.map((op, optIndex) => {
      return { ...op, label: this.getLabelByIndex(optIndex, type) };
    });
  }

  getLabelByIndex(index: number, type: OptionTypeEnum) {
    if (type === OptionTypeEnum.EMPTY_ABC) {
      if (index === 0) {
        return '';
      } else {
        return COMPONENT_UTILS.getABC(index - 1);
      }
    } else if (type === OptionTypeEnum.ABC) {
      return COMPONENT_UTILS.getABC(index);
    } else {
      return `${index + 1}`;
    }
  }
}

const OptionGroupUtils = new OptionGroupSupportUtils();
export default OptionGroupUtils;
