import React from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';

import { CompMode } from '@cms/ComponentInteface';
import {
  ResourceContextProvider,
  ResourceWysiCompose,
} from '@cms/context/ResourceContextProvider';
import { ResourceAnswerProps } from '@app/redux/slices/viewResource';
import { updateAnswers } from '@app/redux/slices/composeResource';
import { useDispatch, useSelector } from '@app/redux/hook';

const ResourceWysiEditable = (props: { item: ResourceProps }) => {
  const dispatch = useDispatch();

  const { components, answers } = useSelector((state) => state.composeResource);

  const onChange = (newAns: ResourceAnswerProps) => {
    dispatch(updateAnswers(newAns.answers));
  };

  return (
    <ResourceContextProvider
      mode={CompMode.COMPOSE}
      resourceId={props.item.resourceId}
      comps={components}
      answer={answers}
      onChange={onChange}
    >
      <ResourceWysiCompose />
    </ResourceContextProvider>
  );
};

export default ResourceWysiEditable;
