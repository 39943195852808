import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import {
  CompFeedbackProps,
  CompFeedbackResultIcon,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { TextEditable } from '@cms/editable/TextEditable';

export interface FibParagraphProps extends CompProps {
  type: CompTypeEnum.FIB_PARAGRAPH;
  setting: CompInteractSettingProps;
}

export interface FibParagraphAnsProps extends CompAnswerProps {
  answer: string;
}

export const FibParagraphComp = (props: {
  disabled: boolean;
  item: FibParagraphProps;
  answer: FibParagraphAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [answer, setCompAnswer] = useState({
    value: '' as string,
    triggerChange: false,
  });

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (props.answer) {
      setCompAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: '',
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  const handleOnChange = (textAns: string) => {
    if (!props.disabled) {
      updateAnswer(textAns, true);
    }
  };

  const updateAnswer = (ans: string, triggerChange: boolean) => {
    setCompAnswer({
      value: ans,
      triggerChange: triggerChange,
    });
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      <CompFeedbackResultIcon answer={answer.value} />

      <FibParagraphStyle
        className={`comps-paragraph ${props.item.setting?.className}`}
      >
        <TextEditable
          key={props.item.id + '_' + answer.value}
          initValue={answer.value ?? ''}
          onChange={handleOnChange}
          disabled={props.disabled}
        />

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </FibParagraphStyle>
    </CompFeedbackContextProvider>
  );
};

const FibParagraphStyle = styled.div`
  display: block;
  padding: 0.125em 0.5em;
  border: 1px solid #ccc;
  border-radius: 0.25em;
  position: relative;

  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }

  .cms-content-editable {
    color: ${(props) => props.theme.component.primary};
    min-height: 1.6em;

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
    }
  }
`;
