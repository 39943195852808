import {
  MathOperationEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
// import { useLongOperationTableContext } from '@cms/comps/math/operation/table/LongOperationTableContext';
import { InputNumberComp } from '@cms/comps/math/fib/InputNumberComp';

export const AreaOperationTable = (props: {
  operation: MathOperationEnum;
  disabled: boolean;
  answer: string[];
  expression: OperationExpProps[];
  onChange: (ans: OperationExpProps[]) => void;
}) => {
  // const { focusNext } = useLongOperationTableContext();
  const [answerExpression, setAnswerExpression] = useState<{
    value: OperationExpProps[];
    triggerChange: boolean;
  }>({ value: [], triggerChange: false });

  useEffect(() => {
    if (answerExpression && answerExpression.triggerChange) {
      props.onChange(answerExpression.value);
    }
  }, [answerExpression]);

  useEffect(() => {
    if (props.answer != null && props.answer.length > 0) {
      const answers: OperationExpProps[] = props.answer.map((ans) => {
        const rows = ans.split('|');
        return {
          type: 'empty',
          exp: rows,
        };
      });
      setAnswerExpression({
        value: answers,
        triggerChange: false,
      });
    } else {
      const answers = [...props.expression];
      setAnswerExpression({
        value: answers,
        triggerChange: false,
      });
    }
  }, [props.answer]);

  const updateOperationValue = (
    rowIndex: number,
    columnIndex: number,
    value: string
  ) => {
    setAnswerExpression((prev) => {
      const newValues = produce(prev.value, (draft) => {
        draft[rowIndex].exp[columnIndex] =
          value != null && value.trim() !== ''
            ? value
            : OperationUtils.VARIABLE_CHARACTER;
      });

      return {
        value: newValues,
        triggerChange: true,
      };
    });
    // focusNext(rowIndex, columnIndex);
  };

  return (
    <AreaOperationTableWrapper
      className={`area-operation-table-wrapper area-operation-table-${props.operation}`}
    >
      <AreaOperationTableType className={`area-operation-table`}>
        {props.expression.map((row, rowIndex) => {
          return (
            <React.Fragment key={JSON.stringify(row) + '_' + rowIndex}>
              <AreaOperationRow
                rowIndex={rowIndex}
                disabled={props.disabled}
                expression={row}
                answer={answerExpression.value[rowIndex]}
                onChange={(colIndex, val) =>
                  updateOperationValue(rowIndex, colIndex, val)
                }
              />
            </React.Fragment>
          );
        })}
      </AreaOperationTableType>
    </AreaOperationTableWrapper>
  );
};

const AreaOperationRow = (props: {
  rowIndex: number;
  disabled: boolean;
  expression: OperationExpProps;
  answer: OperationExpProps;
  onChange: (columnIndex: number, value: string) => void;
}) => {
  const handleOnInputChange = (value: string, columnIndex: number) => {
    props.onChange(columnIndex, value);
  };

  return (
    <AreaOperationRowType type={props.expression.type}>
      {props.expression.exp.map((exp, colIndex) => {
        const answer =
          props.answer != null && props.answer.exp != null
            ? props.answer.exp[colIndex]
            : exp;

        if (exp === OperationUtils.HIDDEN_CHARACTER) {
          return (
            <AreaOperationColumnType
              className={'area-operation-field area-operation-hidden'}
              key={exp + '-' + colIndex}
            >
              <span>&nbsp;</span>
            </AreaOperationColumnType>
          );
        } else if (exp === OperationUtils.VARIABLE_CHARACTER) {
          return (
            <AreaOperationColumnType
              className={'area-operation-field area-operation-input'}
              key={exp + '-' + colIndex}
            >
              <span style={{ minWidth: exp.length + 'em' }}>
                <InputNumberComp
                  className={'mixed-number-field'}
                  disabled={props.disabled}
                  value={answer}
                  onChange={(val) => handleOnInputChange(val, colIndex)}
                  digit={exp}
                />
              </span>
            </AreaOperationColumnType>
          );
        } else {
          return (
            <AreaOperationColumnType
              className={'area-operation-field area-operation-label'}
              key={exp + '-' + colIndex}
            >
              <span>
                <span>{answer}</span>
              </span>
            </AreaOperationColumnType>
          );
        }
      })}
    </AreaOperationRowType>
  );
};

export const AreaOperationTableType = (props: {
  className: string;
  children: ReactNode;
}) => {
  return (
    <AreaOperationTableStyle className={props.className}>
      <tbody>{props.children}</tbody>
    </AreaOperationTableStyle>
  );
};

export const AreaOperationRowType = (props: {
  type: string;
  children: ReactNode;
}) => {
  return (
    <tr className={`area-operation-row area-operation-type-${props.type}`}>
      {props.children}
    </tr>
  );
};

export const AreaOperationColumnType = (props: {
  className: string;
  children: ReactNode;
}) => {
  return <td className={props.className}>{props.children}</td>;
};

export const AreaOperationTableWrapper = styled.div`
  &.area-operation-table-editable {
    .area-operation-row .area-operation-field {
      &.area-operation-label {
        > span {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -4px;
            right: -4px;
            border-radius: 5px;
            background: rgb(240, 240, 240) !important;
            z-index: 1;
          }

          span {
            color: inherit !important;
            z-index: 2;
            position: relative;
            min-width: 1.4em;
            height: 1.4em;
            display: inline-block;
            cursor: pointer;
          }

          .show-correct-answer & {
            &:before {
              color: ${(props) => props.theme.component.correct_answer};
            }
          }
        }
      }

      &.area-operation-input {
        > span {
          span {
            color: ${(props) =>
              props.theme.component.correct_answer} !important;
            font-weight: 600;
          }

          .show-correct-answer & {
            &:before {
              background: ${(props) => props.theme.component.primary_bgr};
            }
          }
        }
      }
    }
  }

  .area-operation-row .area-operation-field {
    position: relative;
    text-align: center;

    > * {
      z-index: 2;
    }
  }

  &.area-operation-table-area-division {
    .area-operation-type-header {
      .area-operation-field {
        &:not(:first-child):not(:last-child) {
          &:before {
            content: '+';
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(50%, -50%);
            display: inline-block;
            font-size: 125%;
          }
        }
      }
    }
  }

  .area-operation-type-template,
  .area-operation-type-expression {
    .area-operation-field {
      &:not(:first-child) {
        border-left: 1px solid #666;
        border-top: 1px solid #666;

        &:last-child {
          border-right: 1px solid #666;
        }
      }
    }

    &:last-child {
      .area-operation-field {
        &:not(:first-child) {
          border-bottom: 1px solid #666;
        }
      }
    }
  }
`;

const AreaOperationTableStyle = styled.table`
  .area-operation-row {
    .area-operation-field {
      padding: 0.125em 0.75em;
      position: relative;
      text-align: center;

      .input-field {
        outline: none !important;
        min-width: 3em;
        height: 1.4em !important;
        min-height: 1.4em !important;
        line-height: 1.4em;
        text-align: center;
        color: ${(props) => props.theme.component.primary};
        background-color: ${(props) => props.theme.component.primary_bgr};
        border-radius: 4px;
        border: 1px solid #ccc;
        font-weight: 600;
        z-index: 2;
        padding: 0 0.125em;

        &:focus {
          border: 1px solid #ccc;
          outline: none !important;
        }

        .show-correct-answer & {
          color: ${(props) => props.theme.component.correct_answer};
        }
      }

      &:first-child {
        padding: 0.125em 0.25em;
      }

      > * {
        z-index: 2;
      }

      .area-operation-input {
        > span {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            border-radius: 4px;
            border: 1px solid #ccc;
            background: #fafafa;
            z-index: 1;
          }

          .show-correct-answer & {
            &:before {
              background: ${(props) => props.theme.component.primary_bgr};
            }
          }

          span {
            z-index: 2;
            position: relative;
            display: inline-block;
          }
        }
      }
    }
  }
`;
