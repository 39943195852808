import { ClassAPI } from '@modules/users/services/ClassAPI';
import React, { useEffect, useState } from 'react';
import TablePagination from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { InputGroup } from '@components/input/InputGroup';
import { DateLabel } from '@components/text/DateLabel';
import { StudentInfoDisplayMobile } from '@modules/users/container/users/StudentInfor';
import Card, { CardClassEnum } from '@components/card';
import { ResponsiveScreen } from '@hooks/useMedia';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { CreateStudentUserForm } from '@modules/users/component/users/CreateStudentUserForm';
import {
  ClassRosterRes,
  FilterStudentClassReq,
} from '@modules/users/services/clazz';
import SelectStudent from '@modules/common/components/SelectStudent';

export const ClassRoster = (props: { classId: number }) => {
  const { t } = useTranslation();

  const [getClassRoster, { data, isFetching }] =
    ClassAPI.endpoints.getClassRoster.useLazyQuery({});

  const [addStudentToClass] = ClassAPI.endpoints.addStudent.useMutation();

  const [params, setParams] = useState<FilterStudentClassReq>({
    size: MAX_PAGE_SIZE,
    selectAll: true,
    classId: props.classId,
  });

  const [excludedIds, setExcludeIds] = useState<number[]>([]);

  const [action, setAction] = useState('');

  useEffect(() => {
    reloadStudentData();
  }, [params]);

  useEffect(() => {
    if (data) {
      const currentUserId = data.content.map((st) => {
        return st.user.userId;
      });

      setExcludeIds(currentUserId);
    }
  }, [data]);

  const onParamChange = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...prev, ...params };
    });
  };

  const reloadStudentData = () => {
    getClassRoster(params);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 72,
    },
    {
      title: t('label.username'),
      dataIndex: 'user.username',
      key: 'user.username',
      width: '15%',
      sorter: true,
    },
    {
      title: t('label.firstName'),
      dataIndex: 'user.firstName',
      key: 'user.firstName',
      width: 180,
      sorter: true,
    },
    {
      title: t('label.lastName'),
      dataIndex: 'user.lastName',
      key: 'user.lastName',
      sorter: true,
      width: 180,
    },
    {
      title: t('label.email'),
      dataIndex: 'user.email',
      key: 'user.email',
      width: 180,
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.phone'),
      dataIndex: 'user.phone',
      key: 'user.phone',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Join Date',
      dataIndex: 'joinDate',
      key: 'joinDate',
      sorter: true,
      align: 'center',
    },
  ];

  const processDataRow = (record: ClassRosterRes) => {
    return {
      key: record.classRosterId,
      avatar: <UserAvatar src={record.user.avatar} size={64} />,
      'user.username': <>{record.user.username}</>,
      'user.firstName': record.user.firstName,
      'user.lastName': record.user.lastName,
      'user.email': record.user.email,
      'user.phone': record.user.phone,
      joinDate: <DateLabel label={record.joinDate} />,
    };
  };

  const renderMobile = (record: ClassRosterRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <StudentInfoDisplayMobile item={record.user} />
        <InputGroup label={'JoinDate'}>
          <DateLabel label={record.joinDate} />
        </InputGroup>
      </Card>
    );
  };

  const onAddStudent = () => {
    setAction('add-student');
  };

  const onInviteStudent = () => {
    setAction('invite-student');
  };

  const onCreateStudent = (studentId: number) => {
    addStudentToClass({
      classId: props.classId,
      studentId: studentId,
    })
      .unwrap()
      .then(() => {
        reloadStudentData();
        onCancel();
      })
      .catch(() => {});
  };

  const onAddStudentToClass = (studentId: number) => {
    addStudentToClass({
      classId: props.classId,
      studentId: studentId,
    })
      .unwrap()
      .then(() => {
        reloadStudentData();
      })
      .catch(() => {});
  };

  const onCancel = () => {
    setAction('');
  };

  return (
    <div className={'class-rosters'}>
      <HeaderGroup className={'mt-0'}>
        <H1>
          Members
          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.SCHOOL, RoleEnum.MANAGE_CLASS]}
          >
            <Button
              type={'primary'}
              shape="circle"
              icon={IconUtils.add_new}
              onClick={onAddStudent}
            />
          </Authorization>
        </H1>

        <Authorization
          type={'ifAnyGranted'}
          roles={[RoleEnum.SCHOOL, RoleEnum.MANAGE_CLASS]}
        >
          <Button
            type={'primary'}
            shape="round"
            icon={IconUtils.actions.invite_student}
            onClick={onInviteStudent}
          >
            {t('class.actions.invite_students')}
          </Button>
        </Authorization>
      </HeaderGroup>

      {action === 'invite-student' && (
        <div>
          <SelectStudent
            name={'studentId'}
            value={-1}
            excludedIds={excludedIds}
            onChange={onAddStudentToClass}
          />
        </div>
      )}

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={onParamChange}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action === 'add-student' && (
        <CustomModal
          header={'Add Student to Class'}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateStudentUserForm
              onSuccess={(user) => onCreateStudent(user.userId)}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}
    </div>
  );
};
