import React, { ReactNode, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import { Select, SelectProps, Space } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { CertificateRes } from '@modules/admin/service/program';
import { CertificateAPI } from '@modules/admin/service/CertificateAPI';
import { ImageScale } from '@utils/ImageScale';
import IconImage from '@components/images/IconImage';

interface SelectCertificateProps extends SelectProps {
  formik?: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  disabled?: boolean;
  value: number;
  onChange: (value: number) => void;
}

export const SelectCertificate = (props: SelectCertificateProps) => {
  const [findAll, { data, isSuccess, isFetching }] =
    CertificateAPI.endpoints.findAll.useLazyQuery();

  const [options, setOptions] = useState<CertificateRes[]>([]);

  useEffect(() => {
    findAll({
      page: 1,
      size: MAX_PAGE_SIZE,
      selectAll: true,
    });
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setOptions(data.content);
    }
  }, [data]);

  const handleOnInputChange = (value: number) => {
    props.onChange(value);
  };

  return (
    <InputGroup label={props.label ? props.label : 'Select a Certificate'}>
      <Select
        style={{ width: '100%' }}
        size={'large'}
        placeholder={props.placeholder}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value}
        loading={isFetching}
        disabled={props.disabled}
      >
        <Select.Option key={-1} value={-1}>
          <Space>
            <IconImage
              src={''}
              width={ImageScale.program.icon}
              height={ImageScale.program.icon}
            />
            <span>Select Certificate</span>
          </Space>
        </Select.Option>

        {options.map((op) => {
          return (
            <Select.Option key={op.certificateId} value={op.certificateId}>
              <Space>
                <IconImage
                  src={op.icon}
                  width={ImageScale.program.icon}
                  height={ImageScale.program.icon}
                />
                <span>
                  {op.code} - {op.name}
                </span>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};
