import { SchoolAPI, SchoolRes } from '@services/private/SchoolAPI';
import { useEffect, useState } from 'react';

interface FetchSchoolRes {
  ready: boolean;
  data: SchoolRes | null;
  error: any;
  isSuccess: boolean;
  isError: boolean;
  isFetching: boolean;
}

export const useLoadSchool = (schoolId?: number) => {
  const [findById, res] = SchoolAPI.endpoints.findById.useLazyQuery({});
  const [getSchool, resSchool] = SchoolAPI.endpoints.getSchool.useLazyQuery({});

  const [state, setState] = useState<FetchSchoolRes>({
    ready: false,
    data: null,
    error: null,
    isSuccess: false,
    isError: false,
    isFetching: false,
  });

  useEffect(() => {
    if (schoolId != null && schoolId > 0) {
      findById(schoolId);
    } else {
      getSchool({});
    }
    setState((prev) => {
      return { ...prev, isFetching: true };
    });
  }, [schoolId]);

  useEffect(() => {
    if (res.data) {
      setState({
        ready: true,
        data: res.data,
        error: res.error,
        isSuccess: res.isSuccess,
        isError: res.isError,
        isFetching: false,
      });
    }
  }, [res.data]);

  useEffect(() => {
    if (resSchool.data) {
      setState({
        ready: true,
        data: resSchool.data,
        error: resSchool.error,
        isSuccess: resSchool.isSuccess,
        isError: resSchool.isError,
        isFetching: false,
      });
    }
  }, [resSchool.data]);

  return state;
};
