import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import SyncEvents from '@router/pages/sync/SyncEvents';

export const SyncRouters: PageRouterProps[] = [
  {
    path: SiteMap.sync.events,
    element: (
      <PrivateRouter
        roles={[RoleEnum.SCHOOL, RoleEnum.ADMIN]}
        element={<SyncEvents />}
      />
    ),
  },
];
