import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { SketchPicker } from 'react-color';
import ColorSquare from '@components/input/ColorSquare';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { StandardColors } from '@utils/ColorUtils';

interface SelectColorPicker {
  value: any;
  onChange: (value: any) => void;
}
const ColorPickerInput = (props: SelectColorPicker) => {
  const [color, setColor] = useState(props.value ?? '#000000');

  useEffect(() => {
    if (props.value !== color) {
      setColor(props.value);
    }
  }, [props.value]);

  const handleOnChange = (color: string) => {
    setColor(color);
  };

  const handleOnChangeComplete = (_color: string) => {
    props.onChange(_color);
  };

  return (
    <Dropdown
      overlay={
        <SketchPicker
          width={'320px'}
          color={color}
          onChange={(col) => handleOnChange(col.hex)}
          onChangeComplete={(col) => handleOnChangeComplete(col.hex)}
          presetColors={StandardColors.map((col) => col.color)}
        />
      }
      trigger={['click']}
    >
      <ColorWrapperStyle className={'color-picker-placeholder'}>
        <ColorSquare color={color}>{color}</ColorSquare>
        <DownOutlined />
      </ColorWrapperStyle>
    </Dropdown>
  );
};

export default ColorPickerInput;

const ColorWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 340px;
  width: 100%;

  border: 1px solid #ced4da;
  border-radius: 4px;
  min-height: 40px;
  padding: 6.5px 11px;
  font-size: 16px;
`;

interface IColors {
  primaryColor: string;
  whiteColor: string;

  themeColor: string;
  blueColor: string;
  redColor: string;
  bodyTextColor: string;
  textMutedColor: string;

  success: string;
  primary: string;
  secondary: string;
  dark: string;
  warning: string;
  infor: string;
  light: string;

  inputDefault: string;
  inputSuccess: string;
  inputError: string;
}

export const colors: IColors = {
  primaryColor: '#115272',
  whiteColor: '#ffffff',

  themeColor: '#115272',
  blueColor: '#0038a8',
  redColor: '#d93939',
  bodyTextColor: '#212529',
  textMutedColor: '#909090',

  success: '#198754',
  primary: '#0D6EFD',
  secondary: '#6c757d',
  dark: '#212529',
  warning: '#ffc107',

  infor: '#17a2b8',
  light: '#f8f9fa',

  inputDefault: '#CED4DA',
  inputError: '#DC3545',
  inputSuccess: '#28A745',
};
