import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { StandardRes } from '@modules/product/services/standard_model';
import { BasicMessageRes } from '@services/model/Response';
import {
  ContentValidationResponse,
  CreateLessonAndResourceReq,
  CreateResourceReq,
  FilterResourceReq,
  LessonResourcesRes,
  ResourceRes,
  UpdateResourceLessonReq,
  UpdateResourceMetadataReq,
  UpdateResourceReq,
} from '@modules/product/services/resource_model';

export const ResourceAPI = createApi({
  reducerPath: 'ResourceAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyResources: builder.query({
      query: () => ({
        url: `/resources`,
      }),
    }),

    findById: builder.query<ResourceRes, number>({
      query: (resourceId: number) => ({
        url: `/resources/${resourceId}`,
        method: 'GET',
      }),
    }),

    getResourceValidation: builder.query<ContentValidationResponse, number>({
      query: (resourceId: number) => ({
        url: `/resources/${resourceId}/validate`,
        method: 'GET',
      }),
    }),

    getResourceStandards: builder.query<StandardRes[], number>({
      query: (resourceId: number) => ({
        url: `/resources/${resourceId}/standards`,
        method: 'GET',
      }),
    }),

    filterResource: builder.query<
      PaginationRes<ResourceRes>,
      FilterResourceReq
    >({
      query: (params) => ({
        url: `/resources/filter`,
        method: 'GET',
        params,
      }),
    }),

    createResource: builder.mutation<ResourceRes, CreateResourceReq>({
      query: (body) => ({
        url: '/resources',
        method: 'POST',
        body,
      }),
    }),

    createLessonAndResource: builder.mutation<
      LessonResourcesRes,
      CreateLessonAndResourceReq
    >({
      query: (body) => ({
        url: '/resources/lesson',
        method: 'POST',
        body,
      }),
    }),

    cloneResource: builder.mutation<ResourceRes, UpdateResourceLessonReq>({
      query: (body) => ({
        url: '/resources/clone',
        method: 'PUT',
        body,
      }),
    }),

    removeFromLesson: builder.mutation<ResourceRes[], UpdateResourceLessonReq>({
      query: (body) => ({
        url: '/resources/remove',
        method: 'PUT',
        body,
      }),
    }),

    updateResourceContent: builder.mutation<ResourceRes, UpdateResourceReq>({
      query: (body) => ({
        url: `/resources/${body.resourceId}`,
        method: 'PUT',
        body,
      }),
    }),

    updateResourceMetadata: builder.mutation<
      ResourceRes,
      UpdateResourceMetadataReq
    >({
      query: (body) => ({
        url: `/resources/${body.resourceId}/metadata`,
        method: 'PUT',
        body,
      }),
    }),

    updateResourceScoringForm: builder.mutation<
      ResourceRes,
      UpdateResourceMetadataReq
    >({
      query: (body) => ({
        url: `/resources/${body.resourceId}/scoring`,
        method: 'PUT',
        body,
      }),
    }),

    syncData: builder.mutation<BasicMessageRes, unknown>({
      query: () => ({
        url: '/resources/sync',
        method: 'POST',
      }),
    }),
  }),
});
