import styled from 'styled-components';
import React from 'react';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';

import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import {
  CheckBoxItemProps,
  CheckBoxSettingProps,
} from '@cms/comps/interact/checkbox/CheckBoxComp';

const CheckBoxOption = (props: {
  disabled: boolean;
  checked: boolean;
  option: CheckBoxItemProps;
  answer: string[];
  setting: CheckBoxSettingProps;
  onClick: (label: string) => void;
}) => {
  const handleOnClick = () => {
    if (!props.disabled) {
      props.onClick(props.option.label);
    }
  };
  return (
    <CheckBoxOptionStyle
      className={`comps-checkbox-item ${props.checked ? ' active ' : ''}`}
      key={props.option.label}
      onClick={handleOnClick}
    >
      <CompFeedbackResultIcon answer={props.option.label} />

      {props.setting.showCheckbox && (
        <span className={'comps-checkbox-checkbox'} />
      )}

      {props.setting && props.setting.showLabel && (
        <span className={'comps-checkbox-label'}>
          {props.option.label}.&nbsp;
        </span>
      )}

      {props.setting.showOption && (
        <>
          <div className={'comps-checkbox-content single-option'}>
            <ContentViewer contentData={props.option.content} />
          </div>
        </>
      )}
    </CheckBoxOptionStyle>
  );
};

export default CheckBoxOption;

const CheckBoxOptionStyle = styled.div`
  display: inline-flex;
  align-items: baseline;
  position: relative;
  cursor: pointer;

  .comps-checkbox-content {
    display: inline-flex;
  }

  .feedback-icon {
    transform: translate(-30px, 0px);
    position: absolute;
  }

  &:not(:first-child) {
    margin-top: 0.125em;
  }

  .comps-checkbox-label {
    min-width: 1.5em;
  }

  //&.single-option {
  //  .comps-checkbox-content {
  //    border: 1pt solid #ccc;
  //    border-radius: 6px;
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    position: relative;
  //    margin-top: 10px;
  //    margin-bottom: 10px;
  //    background: #fff;
  //    padding: 0.25em 0.5em;
  //    cursor: pointer;
  //  }
  //
  //  &.active .comps-checkbox-content {
  //    border: 1pt solid #6366f1;
  //
  //    .show-correct-answer & {
  //      border: 1pt solid #d424ff;
  //    }
  //  }
  //}

  .cms-image-viewer img {
    filter: grayscale(1);
  }

  &.active {
    color: ${(props) => props.theme.component.primary};

    .cms-image-viewer img {
      filter: grayscale(0);
    }

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
    }

    .comps-checkbox-checkbox {
      border-color: ${(props) => props.theme.component.primary};

      .show-correct-answer & {
        border-color: ${(props) => props.theme.component.correct_answer};
      }

      &:before {
        content: '✔';
        color: ${(props) => props.theme.component.primary};

        .show-correct-answer & {
          color: ${(props) => props.theme.component.correct_answer};
        }
      }
    }
  }

  .comps-checkbox-checkbox {
    cursor: pointer;
    display: inline-flex;
    width: 1.2em;
    min-width: 1.2em;
    height: 1.2em;
    border: 2px solid #ccc;
    margin-right: 0.5em;
    position: relative;
    transform: translate(0%, 20%);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
