import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import { MyAccountAPI } from '@services/private/MyAccountAPI';

const UserPermissionContext = createContext({
  permissions: [] as RoleEnum[],
});

export const UserPermissionProvider = (props: { children: ReactNode }) => {
  const [getPermissions, { data, isSuccess }] =
    MyAccountAPI.endpoints.getPermissions.useLazyQuery({});

  useEffect(() => {
    getPermissions({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setCurrentPermission(data);
    }
  }, [data]);

  const [currentPermission, setCurrentPermission] = useState<RoleEnum[]>([]);

  return (
    <UserPermissionContext.Provider value={{ permissions: currentPermission }}>
      {props.children}
    </UserPermissionContext.Provider>
  );
};

export const useUserPermissionContext = () => {
  const context = useContext(UserPermissionContext);

  if (!context) {
    throw new Error('You must wrap container by UserPermissionProvider');
  }
  return context;
};

export const UserHasPermission = (props: {
  roles: RoleEnum[];
  children: ReactNode;
}) => {
  const { permissions } = useUserPermissionContext();

  const hasPermission = permissions.some((role) => {
    return props.roles.includes(role);
  });

  if (hasPermission) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};
