import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { SelectTeacher } from '@components/select/SelectTeacher';
import React from 'react';
import { useDispatch, useSelector } from '@app/redux/hook';
import { selectUser } from '@app/redux/slices/appSlice';
import styled from 'styled-components';

export const SelectTeacherGroup = () => {
  const dispatch = useDispatch();

  const { selectUserType, selectUserId } = useSelector(
    (state) => state.appState
  );

  const handleOnSelectTeacher = (teacherId: number) => {
    dispatch(selectUser(teacherId));
  };

  if (selectUserType === 'teacher') {
    return (
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <SelectTeacherStyle className={'select-teacher-group'}>
          <SelectTeacher
            value={selectUserId}
            onChange={handleOnSelectTeacher}
          />
        </SelectTeacherStyle>
      </Authorization>
    );
  } else {
    return null;
  }
};

const SelectTeacherStyle = styled.div`
  position: relative;
  min-width: 240px;

  .ant-form-label {
    display: none;
  }

  .form-group {
    margin-bottom: 0;
  }
`;
