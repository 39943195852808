import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LessonButton from '../style';
import { IconUtils } from '@utils/IconUtils';
import { useTheme } from '@app/styled/StyledProvider';

export const ReviewExerciseButton = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { dispatchExerciseAction, lesson } = useViewLessonContext();

  if (lesson.validation != null && lesson.validation.interact) {
    return (
      <LessonButton
        className={'review-exercise-btn'}
        type={'default'}
        shape={'round'}
        style={{ background: theme.exercise.review }}
        onClick={() =>
          dispatchExerciseAction(ExerciseActionEnum.request_review, '')
        }
        icon={IconUtils.exercise.review}
      >
        {t('exercise.button.review')}
      </LessonButton>
    );
  } else {
    return null;
  }
};
