import React, { useEffect, useState } from 'react';
import { Avatar, Button, notification, Space } from 'antd';
import TablePagination from '@components/table/TablePagination';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import ButtonGroup from '@components/button/ButtonGroup';
import { InvitationAPI } from '@services/private/InvitationAPI';
import InvitationTypeComp from '@components/text/InvitationType';
import { useTranslation } from 'react-i18next';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { TeacherInvitationRes } from '@modules/users/services/model';

export const useViewTeacherInvitation = (
  params: PaginationReq,
  setParams: (params: PaginationReq) => void
) => {
  const [getTeacherInvitation, { data, isFetching }] =
    InvitationAPI.endpoints.getTeacherInvitation.useLazyQuery();

  const [acceptInvitation] =
    InvitationAPI.endpoints.acceptInvitation.useMutation({});

  useEffect(() => {
    getTeacherInvitation(params);
  }, []);

  const acceptTheInvitation = (
    invitationId: number,
    isAccept: boolean,
    callBackFunc: (isSuccess: boolean, accepted: boolean) => void
  ) => {
    const payload = {
      invitedId: invitationId,
      accepted: isAccept,
    };

    acceptInvitation(payload)
      .unwrap()
      .then(() => {
        callBackFunc(true, isAccept);
      })
      .catch(() => {
        callBackFunc(false, isAccept);
      });
  };

  const refreshData = (params: any) => {
    setParams(params);
    getTeacherInvitation(params);
  };

  return {
    data,
    isFetching,
    getTeacherInvitation,
    acceptTheInvitation,
    refreshData,
  };
};

export default function TeacherInvitation() {
  const { t } = useTranslation();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'invitedDate',
    direction: 'descend',
  });

  const {
    data,
    isFetching,
    acceptTheInvitation,
    refreshData,
    getTeacherInvitation,
  } = useViewTeacherInvitation(params, setParams);

  const columns = [
    {
      title: t('invitation.invitation.type'),
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      sorter: true,
    },
    {
      title: t('invitation.invitation.teacher'),
      dataIndex: 'teacher.firstName',
      key: 'teacher.firstName',
      width: '25%',
      sorter: true,
    },
    {
      title: t('label.email'),
      dataIndex: 'teacher.email',
      key: 'teacher.email',
      width: '20%',
      sorter: true,
    },

    {
      title: t('invitation.invitation.invited_date'),
      dataIndex: 'invitedDate',
      key: 'invitedDate',
      defaultSortOrder: 'ascend',
      width: '20%',
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      align: 'center',
    },
  ];

  const handleAcceptInvitation = (
    studentSubscriberId: number,
    isAccepted: boolean
  ) => {
    acceptTheInvitation(
      studentSubscriberId,
      isAccepted,
      (isSuccess, accepted) => {
        const warningMsg = accepted
          ? t('invitation.invitation.warning.accept')
          : t('invitation.invitation.warning.reject');

        if (isSuccess) {
          notification.success({
            message: warningMsg,
            description: accepted
              ? t('invitation.invitation.warning.accept_success_message')
              : t('invitation.invitation.warning.reject_success_message'),
            placement: 'bottomRight',
          });

          getTeacherInvitation(params);
        } else {
          notification.error({
            message: warningMsg,
            description: accepted
              ? t('invitation.invitation.warning.accept_error_message')
              : t('invitation.invitation.warning.reject_error_message'),
            placement: 'bottomRight',
          });
        }
      }
    );
  };

  const processDataRow = (item: TeacherInvitationRes) => {
    return {
      key: item.studentSubscriberId,
      type: (
        <b>
          <InvitationTypeComp type={item.type} params={item.params} />
        </b>
      ),
      'teacher.firstName': (
        <Space>
          <Avatar src={item.teacher.avatar} />

          <span>
            {item.teacher.firstName != null
              ? item.teacher.firstName + ' ' + item.teacher.lastName
              : ''}
          </span>
        </Space>
      ),
      'teacher.email': item.teacher.email,

      invitedDate: (
        <DateAndTimeLabel type={'inline'} label={item.invitedDate} />
      ),
      actions: (
        <>
          <ButtonGroup type={'center'}>
            <Button
              size={'small'}
              type={'primary'}
              shape={'round'}
              onClick={() =>
                handleAcceptInvitation(item.studentSubscriberId, true)
              }
            >
              {t('invitation.invitation.actions.accept')}
            </Button>
            <Button
              size={'small'}
              type={'primary'}
              shape={'round'}
              danger={true}
              onClick={() =>
                handleAcceptInvitation(item.studentSubscriberId, false)
              }
            >
              {t('invitation.invitation.actions.reject')}
            </Button>
          </ButtonGroup>
        </>
      ),
    };
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('invitation.teacher_invitation.header')}</H1>
      </HeaderGroup>

      {(data == null || data.content.length === 0) && (
        <h2>{t('invitation.teacher_invitation.warning.empty')}</h2>
      )}

      {data && data.content.length > 0 && (
        <TablePagination
          params={params}
          isLoading={isFetching}
          columns={columns}
          data={data}
          refresh={refreshData}
          processDataRow={processDataRow}
        />
      )}
    </>
  );
}
