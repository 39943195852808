import React, { useMemo } from 'react';
import { IconUtils } from '@utils/IconUtils';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useSecurity } from '@utils/authorization';
import { useSelector } from '@app/redux/hook';
import { getMenuItem } from '@components/template/common/MenuUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import i18next from 'i18next';
import { useProUser } from '@components/button/RequireProUserButton';
import { HeaderMenu } from '@components/template/app-layout/header/header-menu/HeaderMenuDisplay';
import { RoleEnum } from '@app/redux/slices/roles';

const LicenseMenu = () => {
  const proUser = useProUser();
  const { ifAnyGranted } = useSecurity();
  const { authorities } = useSelector((state) => state.profile);

  const menuItems = useMemo(() => {
    return [
      getMenuItem({
        key: NavigationMenuEnum.LICENSE_BOOKS,
        label: <NavLink href={SiteMap.license.book}>Licenses</NavLink>,
        icon: IconUtils.menu.license,
        visible: ifAnyGranted([RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL]),
      }),
    ];
  }, [authorities, i18next.language, proUser]);

  return (
    <HeaderMenu
      group={NavigationMenuEnum.LICENSE}
      groupIcon={IconUtils.menu.license}
      className={'license-menu'}
      menuItems={menuItems}
    />
  );
};

export default LicenseMenu;
