import { WysyEditorProps } from '@components/editor/WysyEditor';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import useDebounce from '@hooks/useDebounce';
import { CMSAssetsAPI } from '@modules/product/services/AssetsAPI';
import { AssetsRes } from '@modules/product/services/assets_model';

export default function ReactQuillEditor(props: WysyEditorProps) {
  const [uploadFile] = CMSAssetsAPI.endpoints.uploadFile.useMutation({});

  const quillRef = useRef<any>();
  const [value, setValue] = useState<{ value: string; triggerChange: boolean }>(
    {
      value: '',
      triggerChange: false,
    }
  );

  const imageHandler = (e: any) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        // @ts-ignore
        const file = input.files[0];
        if (/^image\//.test(file.type)) {
          const formData = new FormData();
          formData.append('file', file);

          uploadFile(formData)
            .unwrap()
            .then((data: AssetsRes) => {
              const url = data.data;
              editor.insertEmbed(editor.getSelection(), 'image', url);
            })
            .catch((err) => {
              alert('Cannot upload image');
              console.log(err);
            });
        } else {
          alert('Cannot upload image');
        }
      };
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['blockquote', 'code-block'],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  useEffect(() => {
    if (props.initValue !== value.value) {
      setValue({
        value: props.initValue ? props.initValue : '',
        triggerChange: false,
      });
    }
  }, [props.initValue]);

  const onEditorChange = (value: string | undefined) => {
    setValue({
      value: value ?? '',
      triggerChange: false,
    });
  };

  useDebounce(() => {
    if (value.triggerChange) {
      onEditorBlur();
    }
  }, [value]);

  const onEditorBlur = () => {
    props.onChange(value.value);
  };

  return (
    <ReactQuillEditorStyle className={'text-editor'}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value.value}
        onChange={onEditorChange}
        onBlur={onEditorBlur}
      />
    </ReactQuillEditorStyle>
  );
}

const ReactQuillEditorStyle = styled.div`
  position: relative;
  font-size: 16px;

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
  }

  .ql-toolbar.ql-snow {
    border-radius: 8px 8px 0 0;
    padding: 7px 11px;
  }

  .ql-container.ql-snow {
    border-radius: 0 0 8px 8px;

    .ql-editor {
      min-height: 5em;
      padding: 7px 11px;
    }
  }

  &.text-editor {
    .ql-editor {
      font-size: 16px;
      line-height: 1.6;
    }
  }
`;

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];
