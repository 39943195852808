import { CompGroupEnum } from '@cms/utils/CompProps';
import {
  ContentViewer,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { DragItemTypeEnum } from '@components/react-dnd/DragNDropTypes';
import OptionEditable from '@cms/editable/OptionEditable';
import React from 'react';
import {
  generatorDragNDropAns,
  getDragNDropAns,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import styled from 'styled-components';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import { useDragNDropCompContext } from '@cms/comps/interact/drag-n-drop/DragNDropCompContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import AutoUpdateToolbar from '../../common/AutoUpdateToolbar';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { useTranslation } from 'react-i18next';

const DnDDropItems = () => {
  const { t } = useTranslation();

  const {
    modifiedTime,
    answers,
    sourceItems,
    targetItems,
    updateOptionType,
    updateOptionData,
    updateGroupOptions,
    updateComponent,
  } = useDragNDropCompContext();

  return (
    <DnDDropItemsStyle className={'dnd-droppable-items'} key={modifiedTime}>
      {targetItems &&
        targetItems.map((drop, index) => {
          const answerParts = answers.filter((ans) => {
            const answer = getDragNDropAns(ans);
            return answer.drop === drop.label;
          });

          const dragItems = sourceItems.filter((drag) => {
            const ansLabel = generatorDragNDropAns(drop.label, drag.label);
            return answerParts.includes(ansLabel);
          });

          return (
            <div
              className={`dnd-drop-item drop-${targetItems.length}-items`}
              key={JSON.stringify(drop)}
            >
              <div className={'dnd-drop-header'}>
                <OptionEditable
                  type={drop.content.type}
                  disabled={{
                    addOption: false,
                    removeOption: targetItems.length === 1,
                  }}
                  onChangeType={(newType) =>
                    updateOptionType(newType, index, drop, CompGroupEnum.target)
                  }
                  onOperation={(operation) =>
                    updateGroupOptions(
                      operation,
                      index,
                      drop,
                      CompGroupEnum.target
                    )
                  }
                >
                  <ContentViewerEditable
                    contentData={drop.content}
                    onChange={(data) =>
                      updateOptionData(data, index, drop, CompGroupEnum.target)
                    }
                  />
                </OptionEditable>
              </div>

              <div className={'dnd-drop-content'}>
                <DropItem
                  acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM}
                  droppableId={drop.label}
                >
                  {dragItems.length === 0 && (
                    <div className={'dnd-placeholder'}>
                      {t('component.label.drag_here')}
                    </div>
                  )}

                  {dragItems.length > 0 &&
                    dragItems.map((drag) => {
                      const answerPart = generatorDragNDropAns(
                        drop.label,
                        drag.label
                      );

                      return (
                        <DragItem
                          key={JSON.stringify(drag) + '_' + index}
                          acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM}
                          index={index}
                          draggableId={drag.label}
                        >
                          <div className={`dnd-option`}>
                            <CompFeedbackResultIcon answer={answerPart} />
                            <ContentViewer contentData={drag.content} />
                          </div>
                        </DragItem>
                      );
                    })}
                </DropItem>
              </div>
            </div>
          );
        })}

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </DnDDropItemsStyle>
  );
};

export default DnDDropItems;

const DnDDropItemsStyle = styled.div`
  .dnd-option {
    &.correct-part {
      color: ${(props) => props.theme.component.correct};
    }

    &.incorrect-part {
      color: ${(props) => props.theme.component.incorrect};
    }
  }
`;
