import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { MatchingGameSettingProps } from '@cms/comps/game/matching/FlashCardMatchingComp';
import Input from '@components/input/input';
import { useTranslation } from 'react-i18next';

export const FlashCardMatchingSetting = (props: {
  setting: MatchingGameSettingProps;
  onSettingChange: (newSetting: MatchingGameSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <Input
          name="columns"
          type="number"
          label={t('component.label.number_column')}
          placeHolder={t('component.label.number_column')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.columns}
          min={1}
          max={5}
        />

        <Input
          name="rows"
          type="number"
          label={t('component.label.number_row')}
          placeHolder={t('component.label.number_row')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.rows}
          min={1}
          max={5}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
