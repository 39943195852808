import { Progress } from 'antd';
import styled from 'styled-components';
import React from 'react';
import { ProductLicenseRes } from '@modules/license/services/model';

export const ProductLicenseDisplay = (props: { item: ProductLicenseRes }) => {
  return (
    <ProductLicenseDisplayStyle className={'product-license-display'}>
      <Progress
        percent={(props.item.passedDate * 100) / props.item.totalDate}
        format={() =>
          props.item.passedDate + '/' + props.item.totalDate + ' days'
        }
      />
      <div>Remains {props.item.totalDate - props.item.passedDate} days.</div>
    </ProductLicenseDisplayStyle>
  );
};

const ProductLicenseDisplayStyle = styled.div`
  min-width: 250px;
  margin-right: 50px;
`;
