import React, { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { LanguageEnum } from '@components/language/LanguageIcon';
import styled from 'styled-components';

interface SelectThemeProps extends SelectProps {
  value?: string;
  placeholder?: ReactNode;
  onChange?: (value: string) => void;
}

export const SelectTheme = (props: SelectThemeProps) => {
  const handleOnInputChange = (value: LanguageEnum) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      size={'large'}
      placeholder={props.placeholder}
      onChange={(_value) => handleOnInputChange(_value as LanguageEnum)}
      value={props.value ?? ''}
    >
      <Select.Option key={''} value={''}>
        <ThemeOption>
          <span className={'theme-color'} style={{ background: '#884ffb' }} />
          <span className={'theme-label'}>Select Theme</span>
        </ThemeOption>
      </Select.Option>

      <Select.Option key={'purple'} value={'purple'}>
        <ThemeOption>
          <span className={'theme-color'} style={{ background: '#362036' }} />
          <span className={'theme-label'}>Purple</span>
        </ThemeOption>
      </Select.Option>

      <Select.Option key={'blue'} value={'blue'}>
        <ThemeOption>
          <span className={'theme-color'} style={{ background: '#1c375b' }} />
          <span className={'theme-label'}>Blue</span>
        </ThemeOption>
      </Select.Option>
    </Select>
  );
};

const ThemeOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;

  .theme-color {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }
`;
