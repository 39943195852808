import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CategoryRes,
  GradeRes,
  SubjectRes,
} from '@modules/admin/service/model';
import {
  ProductRes,
  TableOfContentRes,
} from '@modules/product/services/product_model';

export interface UpdateProductInfoReq {
  productId: number;
  subjectIds: number[];
  categoryIds: number[];
  gradeIds: number[];
}

export interface LessonPriceProps {
  lessonId: number;
  price: number;

  threeMonth?: number;
  sixMonth?: number;
  oneYear?: number;
  forever?: number;
}

export interface UpdateProductPriceReq {
  productId: number;
  price: number;

  threeMonth?: number;
  sixMonth?: number;
  oneYear?: number;
  forever?: number;

  lessons: LessonPriceProps[];
}

export interface ProductInfoRes {
  product: ProductRes;
  grades: GradeRes[];
  categories: CategoryRes[];
  subjects: SubjectRes[];
}

export const PublishProductAPI = createApi({
  reducerPath: 'PublishProductAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getProductInfo: builder.query<ProductInfoRes, unknown>({
      // => PublishProductInfoRes
      query: (productId) => ({
        url: `/publish/product/${productId}`,
      }),
    }),

    updateProductInfo: builder.mutation<ProductInfoRes, UpdateProductInfoReq>({
      query: (body: UpdateProductInfoReq) => ({
        url: '/publish/product',
        method: 'PUT',
        body,
      }),
    }),

    updateProductPrices: builder.mutation<
      ProductInfoRes,
      UpdateProductPriceReq
    >({
      query: (body: UpdateProductPriceReq) => ({
        url: '/publish/product/prices',
        method: 'PUT',
        body,
      }),
    }),

    getReviewProductData: builder.query<ProductInfoRes, unknown>({
      query: (productId) => ({
        url: `/publish/product/${productId}/review`,
      }),
    }),

    getReviewProductTocData: builder.query<TableOfContentRes, unknown>({
      query: (productId) => ({
        url: `/publish/product/${productId}/toc/review`,
      }),
    }),

    publishProduct: builder.mutation<
      BasicMessageRes,
      { productId: number; free: boolean }
    >({
      query: (body) => ({
        url: `/publish/product/${body.productId}/publish`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
