import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Col, Divider, notification, Row } from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';

import {
  EmailSettingAPI,
  EmailSettingRes,
} from '@services/setting/EmailSettingAPI';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';

const initialValue = {
  apikey: '',
  username: '',
  password: '',
  host: '',
  port: '',
  fromName: '',
};

export const EmailSettingManage = (props: {
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getEmailSetting, { data, isSuccess, isFetching }] =
    EmailSettingAPI.endpoints.getEmailSetting.useLazyQuery();

  const [update, { isLoading }] = EmailSettingAPI.endpoints.update.useMutation(
    {}
  );

  useEffect(() => {
    getEmailSetting({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      restoreEmailSetting(data);
    }
  }, [data]);

  const restoreEmailSetting = (data: EmailSettingRes) => {
    formik.setFieldValue('apikey', data.apikey);
    formik.setFieldValue('username', data.username);
    formik.setFieldValue('password', data.password);
    formik.setFieldValue('host', data.host);
    formik.setFieldValue('host', data.host);
    formik.setFieldValue('fromName', data.fromName);
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      fromName: yup.string().required(t('validation.required.field')),
      apikey: yup.string(),
      username: yup.string(),
      password: yup.string(),
      host: yup.string(),
      port: yup.string(),
    }),

    onSubmit: (values) => {
      update(values)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('alert.success'),
            description: t('alert.update_success_message'),
            placement: 'bottomRight',
          });
          props.onSuccess();
        })
        .catch(() => {
          notification.error({
            message: t('alert.warning'),
            description: t('alert.cannotUpdate'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            required={true}
            name="fromName"
            type="text"
            label={t('setting.email_setting.label.from_name')}
            placeholder={t('setting.email_setting.label.from_name')}
            onChange={formik.handleChange}
            value={formik.values.fromName}
          />

          <Divider plain>
            {t('setting.email_setting.warning.provide_api_key')}
          </Divider>

          <Input
            required={true}
            name="apikey"
            type="text"
            label={t('setting.email_setting.label.apikey')}
            placeholder={t('setting.email_setting.label.apikey')}
            onChange={formik.handleChange}
            value={formik.values.apikey}
          />

          <Divider plain>{t('label.or')}</Divider>

          <Input
            required={true}
            name="username"
            type="text"
            label={t('label.username')}
            placeholder={t('label.username')}
            onChange={formik.handleChange}
            value={formik.values.username}
          />

          <Input
            required={true}
            name="password"
            type="password"
            label={t('label.password')}
            placeholder={t('label.password')}
            onChange={formik.handleChange}
            value={formik.values.password}
          />

          <Input
            required={true}
            name="host"
            type="text"
            label={t('setting.email_setting.label.host')}
            placeholder={t('setting.email_setting.label.host')}
            onChange={formik.handleChange}
            value={formik.values.host}
          />

          <Input
            required={true}
            name="port"
            type="text"
            label={t('setting.email_setting.label.port')}
            placeholder={t('setting.email_setting.label.port')}
            onChange={formik.handleChange}
            value={formik.values.port}
          />

          <ButtonGroup type={'right'} className="submit-container">
            <Button
              type={'default'}
              shape={'round'}
              size={'large'}
              onClick={props.onCancel}
            >
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              loading={isFetching || isLoading}
              onClick={formik.submitForm}
              shape={'round'}
              size={'large'}
              icon={IconUtils.actions.save}
            >
              {data ? t('button.update') : t('button.create')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};
