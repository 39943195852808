export const getProductParams = (
  productId?: number,
  productTocId?: number
): string => {
  return productId && productId > 0 && productTocId && productTocId > 0
    ? '?' +
        URL_PARSE.convertParams({
          productId: productId,
          productTocId: productTocId,
        })
    : '';
};

export const URL_PARSE = {
  removeAccents: (str: string) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      .toLowerCase()
      .replace(/ /g, '-');
  },

  convertParams: (params: any) => {
    const objs = { ...params };

    Object.keys(objs).forEach((key) => {
      if (objs[key] === undefined || objs[key] === null) {
        delete objs[key];
      }
    });

    return new URLSearchParams(objs);
  },
};
