import React, { ReactNode } from 'react';
import styled from 'styled-components';
import AssignmentUtils, {
  StudentAssignmentEnum,
} from '@modules/assignments/utils/AssignmentUtils';
import { useTranslation } from 'react-i18next';
import Button from 'antd/lib/button';
import { AssignmentCountDown } from '@modules/assignments/container/StudentAssignmentTable';
import { useRouter } from '@hooks/useRouter';
import { Tooltip } from '@components/tooltip/Tooltip';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SiteMap } from '@router/SiteMap';
import { ExerciseStatus } from '@modules/assignments/service/exercise_model';
import { AssignmentInstanceRes } from '@modules/assignments/service/assignment_model';

const AssignmentButtonStyle = styled.span`
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  &.exercise-with-status {
    white-space: initial;
    cursor: pointer;
  }

  .exercise-status {
    display: inline-flex;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    margin-right: 0.33em;
  }

  &.pending-assignment {
    color: ${(props) => props.theme.assignment.pending};

    .exercise-status {
      background-color: ${(props) => props.theme.assignment.pending};
    }
  }

  &.submitted-assignment {
    color: ${(props) => props.theme.assignment.completed};
    .exercise-status {
      background-color: ${(props) => props.theme.assignment.completed};
    }
  }

  &.completed-assignment {
    color: ${(props) => props.theme.assignment.completed};
    .exercise-status {
      background-color: ${(props) => props.theme.assignment.completed};
    }
  }

  &.expired-assignment {
    color: ${(props) => props.theme.assignment.expired};
    .exercise-status {
      background-color: ${(props) => props.theme.assignment.expired};
    }
  }

  &.not-start-assignment {
    color: ${(props) => props.theme.assignment.pending};

    .exercise-status {
      background-color: ${(props) => props.theme.assignment.pending};
    }
  }

  &.in-progress-assignment {
    color: ${(props) => props.theme.assignment.in_progress};

    .exercise-status {
      background-color: ${(props) => props.theme.assignment.in_progress};
    }
  }
`;

export const StudentAssignmentStatus = (props: { status: ExerciseStatus }) => {
  const { t } = useTranslation();

  const exerciseStatus: StudentAssignmentEnum = AssignmentUtils.getStatus(
    props.status
  );

  switch (exerciseStatus) {
    case StudentAssignmentEnum.notStart:
      return (
        <AssignmentButtonStyle
          className={'student-assignment-status not-start-assignment'}
        >
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.not_start')}
        </AssignmentButtonStyle>
      );

    case StudentAssignmentEnum.viewed:
      return (
        <AssignmentButtonStyle
          className={'student-assignment-status in-progress-assignment'}
        >
          <span className={'exercise-status'} /> {t('exercise.status.viewed')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.inProgress:
      return (
        <AssignmentButtonStyle
          className={'student-assignment-status in-progress-assignment'}
        >
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.in_progress')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.submitted:
      return (
        <AssignmentButtonStyle
          className={'student-assignment-status submitted-assignment'}
        >
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.submitted')}
        </AssignmentButtonStyle>
      );

    case StudentAssignmentEnum.needGrading:
      return (
        <AssignmentButtonStyle
          className={'student-assignment-status submitted-assignment'}
        >
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.submitted')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.completed:
      return (
        <AssignmentButtonStyle
          className={'student-assignment-status completed-assignment'}
        >
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.completed')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.default:
      return null;
    default:
      return null;
  }
};

export const StudentExerciseStatus = (props: {
  unlockInMs: number;
  expiredInMs: number;
  status: ExerciseStatus;
}) => {
  const { t } = useTranslation();

  const exerciseStatus: StudentAssignmentEnum =
    AssignmentUtils.getExerciseStatus(
      props.unlockInMs,
      props.expiredInMs,
      props.status
    );

  switch (exerciseStatus) {
    case StudentAssignmentEnum.pending:
      return (
        <AssignmentButtonStyle className={'pending-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.not_start')}
        </AssignmentButtonStyle>
      );

    case StudentAssignmentEnum.notStart:
      return (
        <AssignmentButtonStyle className={'not-start-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.not_start')}
        </AssignmentButtonStyle>
      );

    case StudentAssignmentEnum.viewed:
      return (
        <AssignmentButtonStyle className={'in-progress-assignment'}>
          <span className={'exercise-status'} /> {t('exercise.status.viewed')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.inProgress:
      return (
        <AssignmentButtonStyle className={'in-progress-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.in_progress')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.submitted:
      return (
        <AssignmentButtonStyle className={'submitted-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.submitted')}
        </AssignmentButtonStyle>
      );

    case StudentAssignmentEnum.needGrading:
      return (
        <AssignmentButtonStyle className={'submitted-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.submitted')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.completed:
      return (
        <AssignmentButtonStyle className={'completed-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.completed')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.expired:
      return (
        <AssignmentButtonStyle className={'expired-assignment'}>
          <span className={'exercise-status'} /> {t('exercise.status.expired')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.default:
      return null;
    default:
      return null;
  }
};

export const StudentExerciseWithStatus = (props: {
  unlockInMs: number;
  expiredInMs: number;
  status: ExerciseStatus;
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  const exerciseStatus: StudentAssignmentEnum =
    AssignmentUtils.getExerciseStatus(
      props.unlockInMs,
      props.expiredInMs,
      props.status
    );

  switch (exerciseStatus) {
    case StudentAssignmentEnum.pending:
      return (
        <Tooltip placement="top" title={t('exercise.status.not_start')}>
          <AssignmentButtonStyle
            className={'exercise-with-status pending-assignment'}
          >
            <span className={'exercise-status'} />
            {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );

    case StudentAssignmentEnum.notStart:
      return (
        <Tooltip placement="top" title={t('exercise.status.not_start')}>
          <AssignmentButtonStyle
            className={'exercise-with-status not-start-assignment'}
          >
            <span className={'exercise-status'} /> {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );

    case StudentAssignmentEnum.viewed:
      return (
        <Tooltip placement="top" title={t('exercise.status.viewed')}>
          <AssignmentButtonStyle
            className={'exercise-with-status in-progress-assignment'}
          >
            <span className={'exercise-status'} />
            {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );
    case StudentAssignmentEnum.inProgress:
      return (
        <Tooltip placement="top" title={t('exercise.status.in_progress')}>
          <AssignmentButtonStyle
            className={'exercise-with-status in-progress-assignment'}
          >
            <span className={'exercise-status'} /> {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );
    case StudentAssignmentEnum.submitted:
      return (
        <Tooltip placement="top" title={t('exercise.status.submitted')}>
          <AssignmentButtonStyle
            className={'exercise-with-status submitted-assignment'}
          >
            <span className={'exercise-status'} /> {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );

    case StudentAssignmentEnum.needGrading:
      return (
        <Tooltip placement="top" title={t('exercise.status.submitted')}>
          <AssignmentButtonStyle
            className={'exercise-with-status submitted-assignment'}
          >
            <span className={'exercise-status'} /> {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );
    case StudentAssignmentEnum.completed:
      return (
        <Tooltip placement="top" title={t('exercise.status.completed')}>
          <AssignmentButtonStyle
            className={'exercise-with-status completed-assignment'}
          >
            <span className={'exercise-status'} /> {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );
    case StudentAssignmentEnum.expired:
      return (
        <Tooltip placement="top" title={t('exercise.status.expired')}>
          <AssignmentButtonStyle
            className={'exercise-with-status expired-assignment'}
          >
            <span className={'exercise-status'} /> {props.children}
          </AssignmentButtonStyle>
        </Tooltip>
      );
    case StudentAssignmentEnum.default:
      return <>{props.children}</>;
    default:
      return <>{props.children}</>;
  }
};

export const ExerciseActionGroup = (props: {
  size?: SizeType;
  item: AssignmentInstanceRes;
  label: {
    start: ReactNode;
    viewResult: ReactNode;
  };
}) => {
  const router = useRouter();

  const exerciseStatus: StudentAssignmentEnum =
    AssignmentUtils.getExerciseStatus(
      props.item.unlockInMs,
      props.item.expiredInMs,
      props.item.exercise ? props.item.exercise.status : 0
    );

  const startAssignment = () => {
    router.push(
      SiteMap.private.my_assignment.start_gen(props.item.assignmentInstanceId)
    );
  };

  const viewAssignmentResult = () => {
    router.push(
      SiteMap.private.my_assignment.review_gen(props.item.exercise.exerciseId)
    );
  };

  switch (exerciseStatus) {
    case StudentAssignmentEnum.pending:
      return (
        <Button
          type={'primary'}
          shape={'round'}
          size={props.size ?? 'middle'}
          disabled
          className={'pending-assignment-btn'}
        >
          <AssignmentCountDown item={props.item} />
        </Button>
      );

    case StudentAssignmentEnum.notStart:
      return (
        <Button
          size={props.size ?? 'middle'}
          type={'primary'}
          shape={'round'}
          onClick={startAssignment}
          className={'start-assignment-btn'}
        >
          {props.label.start}
        </Button>
      );

    case StudentAssignmentEnum.viewed:
      return (
        <Button
          size={props.size ?? 'middle'}
          type={'primary'}
          shape={'round'}
          onClick={startAssignment}
          className={'start-assignment-btn'}
        >
          {props.label.start}
        </Button>
      );
    case StudentAssignmentEnum.inProgress:
      return (
        <Button
          size={props.size ?? 'middle'}
          type={'primary'}
          shape={'round'}
          onClick={startAssignment}
          className={'start-assignment-btn'}
        >
          {props.label.start}
        </Button>
      );
    case StudentAssignmentEnum.submitted:
      return null;

    case StudentAssignmentEnum.completed:
      return (
        <Button
          size={props.size ?? 'middle'}
          type={'primary'}
          shape={'round'}
          onClick={viewAssignmentResult}
          className={'view-assignment-report-btn'}
        >
          {props.label.viewResult}
        </Button>
      );
    case StudentAssignmentEnum.expired:
      return null;
    case StudentAssignmentEnum.default:
      return null;
    default:
      return null;
  }
};

export const ExerciseAction = (props: {
  size?: SizeType;
  item: AssignmentInstanceRes;
}) => {
  const { t } = useTranslation();

  return (
    <ExerciseActionGroup
      size={props.size}
      item={props.item}
      label={{
        start: t('assignment.actions.start_assignment'),
        viewResult: t('assignment.actions.view_result'),
      }}
    />
  );
};

export const TeacherExerciseStatus = (props: {
  // unlockInMs: number;
  // expiredInMs: number;
  status: ExerciseStatus;
}) => {
  const { t } = useTranslation();

  const exerciseStatus: StudentAssignmentEnum =
    AssignmentUtils.getTeacherExerciseStatus(props.status);

  switch (exerciseStatus) {
    case StudentAssignmentEnum.notStart:
      return (
        <AssignmentButtonStyle className={'not-start-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.not_start')}
        </AssignmentButtonStyle>
      );

    case StudentAssignmentEnum.viewed:
      return (
        <AssignmentButtonStyle className={'in-progress-assignment'}>
          <span className={'exercise-status'} /> {t('exercise.status.viewed')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.inProgress:
      return (
        <AssignmentButtonStyle className={'in-progress-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.in_progress')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.submitted:
      return (
        <AssignmentButtonStyle className={'submitted-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.need_grading')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.completed:
      return (
        <AssignmentButtonStyle className={'completed-assignment'}>
          <span className={'exercise-status'} />{' '}
          {t('exercise.status.completed')}
        </AssignmentButtonStyle>
      );
    case StudentAssignmentEnum.default:
      return null;
    default:
      return null;
  }
};
