import React from 'react';
import styled from 'styled-components';
import { Modal as AntModal, ModalProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useModal from '@hooks/useModal';
import useMediaQuery from '@hooks/useMedia';

export const HeaderStyle = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  position: relative;
  color: ${(props) => props.theme.app.primary};

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
`;

export const ContentStyle = styled.div`
  padding: 32px 48px;

  .no-padding & {
    padding: 0px !important;
  }

  .screen-xs & {
    padding: 8px 16px;
  }

  .screen-sm & {
    padding: 16px 24px;
  }

  .screen-md & {
    padding: 24px 32px;
  }

  .screen-md & {
    padding: 32px 48px;
  }

  .screen-xl & {
    padding: 32px 48px;
  }
`;

export const FooterStyle = styled.div`
  border-top: 1px solid #dee2e6;

  padding: 12px 48px;

  .screen-xs & {
    padding: 4px 16px;
  }

  .screen-sm & {
    padding: 6px 24px;
  }

  .screen-md & {
    padding: 8px 32px;
  }

  .screen-md & {
    padding: 12px 48px;
  }

  .screen-xl & {
    padding: 12px 48px;
  }

  button:first-child {
    margin-right: 10px;
  }
`;

export type CustomModalSize =
  | 'large-modal'
  | 'medium-modal'
  | 'full-size-modal'
  | string;

export interface ICustomModal extends ModalProps {
  className?: CustomModalSize;
  header?: any;
  content?: any;
  footer?: any;

  onCloseFunc?: (isCancel: boolean) => void;
  props?: any;
}

export interface GstudyModalProps extends ICustomModal {
  modal: any;
}

export const GstudyModal = ({
  modal,
  className,
  header,
  content,
  footer,
  onCloseFunc,
  maskClosable,
  ...props
}: GstudyModalProps) => {
  const screen = useMediaQuery();

  const onOk = () => {
    modal.hide();

    if (onCloseFunc) {
      onCloseFunc(false);
    }
  };

  const onCancel = () => {
    modal.hide();

    if (onCloseFunc) {
      onCloseFunc(true);
    }
  };

  return (
    <ModalWrap
      wrapClassName={`custom-modal screen-${screen} ${
        className ? className : ''
      }`}
      title={header ? <HeaderStyle>{header}</HeaderStyle> : null}
      closeIcon={<CloseOutlined />}
      open={modal.visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      maskClosable={maskClosable ?? false}
      {...props}
    >
      {content && (
        <ContentStyle className={'content-modal-container'}>
          {content}
        </ContentStyle>
      )}

      {footer && <FooterStyle>{footer}</FooterStyle>}
    </ModalWrap>
  );
};

export enum CustomModalClassEnum {
  default = '',
  empty = 'empty-modal',
  medium_modal = 'medium-modal',
  large_modal = 'large-modal',
  full_size_modal = 'full-size-modal',
  no_padding = 'no-padding',
}

const CustomModal = (props: ICustomModal) => {
  const modal = useModal(true);

  return <GstudyModal modal={modal} {...props} />;
};

export default CustomModal;

const ModalWrap = styled(AntModal)`
  .ant-modal-title {
  }
`;
