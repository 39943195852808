import styled from 'styled-components';

export const SessionTaskGroupStyle = styled.div`
  border: 1px solid #ccc;
  border-radius: 0.5em;
  background: #fff;
  margin-bottom: 1.5em;

  .task-title-display {
    padding: 0.5em 1em;
    border-bottom: 1px solid #ccc;
    font-size: 125%;
  }

  .task-content {
    padding: 1em;
  }

  &.active {
    .task-title-display {
      font-weight: bold;
    }
  }

  &.inactive {
    .task-content {
      display: none;
    }
  }
`;

export const ClassRoomExerciseStyle = styled.div`
  position: relative;
  background: transparent !important;
  display: flex;
  justify-content: space-between;
  height: 100%;

  .class-room-instance-items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1em 1em;
    width: 40%;
    max-height: 100%;
    overflow-y: auto;

    .ant-list {
      width: 100%;
    }

    .exercise-status-display {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25em;

      &:hover {
        background: ${(props) => props.theme.app.primary_bgr};
        opacity: 0.67;
      }

      &.selected {
        color: ${(props) => props.theme.app.primary};
        background: ${(props) => props.theme.app.primary_bgr};
      }

      &.completed {
        font-weight: bold;
      }
    }

    .class-room-instance-item {
      display: inline-flex;
      width: calc(50% - 1em);
      //aspect-ratio: 16 / 9;
      padding-top: 25%;
      position: relative;
      border: 2px solid #ccc;
      background: #fff;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2;
      }

      &:hover {
        &:before {
          background: ${(props) => props.theme.app.primary_bgr};
          opacity: 0.67;
        }
      }

      &.selected {
        border: 2px solid ${(props) => props.theme.app.primary};

        &:before {
          background: ${(props) => props.theme.app.primary_bgr};
          opacity: 0.33;
        }
      }
    }
  }

  .class-room-exercise-item {
    width: 100%;
    position: relative;
  }

  .class-room-instance-review {
    width: calc(60% - 1em);
    max-height: 100%;
    overflow-y: auto;

    .view-lesson-content-container {
      border: 2px solid #ccc;
      min-height: 400px;
    }

    .do-exercise-toolbar {
      display: none !important;
    }

    .exercise-template-container {
      padding-bottom: 0;
    }

    .exercise-content-display {
      max-width: 100% !important;
      margin: 0;
    }
  }
`;
