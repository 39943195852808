import React from 'react';

import { RoleEnum } from '@app/redux/slices/roles';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import PaymentMethodManagementPage from '@modules/payment/payment-method/pages/PaymentMethodManagementPage';
import SchoolPaymentMethodManagementPage from '@modules/payment/payment-method/pages/SchoolPaymentMethodManagementPage';
import { PageHeader } from '@app/header/PageHeader';

const PaymentMethodSetting = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'payment-method-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_PAYMENT_METHOD}
        title={t('payment_method.header')}
        description={t('payment_method.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <PaymentMethodManagementPage />
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        <SchoolPaymentMethodManagementPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default PaymentMethodSetting;
