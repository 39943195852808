import React from 'react';
import ForgotPasswordPage from '@modules/authorization/pages/ForgotPasswordPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout className={'forgot-password-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('forgot_password.header')}
        description={t('forgot_password.header')}
      />

      <ForgotPasswordPage />
    </PublicLayout>
  );
};

export default ForgotPassword;
