import { ExerciseStatus } from '@modules/assignments/service/exercise_model';

export enum StudentAssignmentEnum {
  default = '',
  pending = 'Pending',
  notStart = 'Not Start',
  viewed = 'Viewed',
  inProgress = 'In-progress',
  submitted = 'Submitted',
  needGrading = 'Need Grading',
  completed = 'Completed',
  expired = 'Expired',
}

const AssignmentUtils = {
  getStatus: (status: ExerciseStatus) => {
    if (status === ExerciseStatus.SUBMITTED) {
      return StudentAssignmentEnum.submitted;
    } else if (status === ExerciseStatus.NEED_GRADING) {
      return StudentAssignmentEnum.needGrading;
    } else if (status === ExerciseStatus.COMPLETED) {
      return StudentAssignmentEnum.completed;
    } else {
      if (status === ExerciseStatus.NOT_START) {
        return StudentAssignmentEnum.notStart;
      } else if (status === ExerciseStatus.VIEWED) {
        return StudentAssignmentEnum.viewed;
      } else if (status === ExerciseStatus.IN_PROGRESS) {
        return StudentAssignmentEnum.inProgress;
      } else {
        return StudentAssignmentEnum.default;
      }
    }
  },

  getExerciseStatus: (
    unlockInMs: number,
    expiredInMs: number,
    status: ExerciseStatus
  ) => {
    if (status === ExerciseStatus.SUBMITTED) {
      return StudentAssignmentEnum.submitted;
    } else if (status === ExerciseStatus.NEED_GRADING) {
      return StudentAssignmentEnum.needGrading;
    } else if (status === ExerciseStatus.COMPLETED) {
      return StudentAssignmentEnum.completed;
    } else {
      if (unlockInMs > 0) {
        return StudentAssignmentEnum.pending;
      } else {
        // if assignment is expired...
        if (expiredInMs < 0) {
          return StudentAssignmentEnum.expired;
        } else {
          if (status === ExerciseStatus.NOT_START) {
            return StudentAssignmentEnum.notStart;
          } else if (status === ExerciseStatus.VIEWED) {
            return StudentAssignmentEnum.viewed;
          } else if (status === ExerciseStatus.IN_PROGRESS) {
            return StudentAssignmentEnum.inProgress;
          } else {
            return StudentAssignmentEnum.default;
          }
        }
      }
    }
  },

  getTeacherExerciseStatus: (status: ExerciseStatus) => {
    if (status === ExerciseStatus.NEED_GRADING) {
      return StudentAssignmentEnum.submitted;
    } else if (status === ExerciseStatus.COMPLETED) {
      return StudentAssignmentEnum.completed;
    } else if (status === ExerciseStatus.NOT_START) {
      return StudentAssignmentEnum.notStart;
    } else if (status === ExerciseStatus.VIEWED) {
      return StudentAssignmentEnum.viewed;
    } else if (status === ExerciseStatus.IN_PROGRESS) {
      return StudentAssignmentEnum.inProgress;
    } else {
      return StudentAssignmentEnum.default;
    }
  },
};

export default AssignmentUtils;
