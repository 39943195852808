import { SchoolRes } from '@services/private/SchoolAPI';
import styled from 'styled-components';

import { Button, Col, Divider, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseRes } from '@services/private/CourseAPI';
import { PublicProductInfo } from '@modules/public/components/products/PublicProductInfo';
import PublicCalendarInfo from '@modules/calendar/pages/PublicCalendarInfo';
import { SessionRes } from '@services/model/session';
import { ProductRes } from '@modules/product/services/product_model';

const SiteProfileStyle = styled.div`
  .site-introduction {
    .youtube-video-instruction {
      position: relative;
      padding-top: ${(9 / 16) * 100}%;

      iframe.video-instruction {
        border: none;
        overflow: hidden;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
      }
    }
  }

  .site-header {
    display: flex;
    align-items: baseline;
    padding: 1em;

    .site-avatar {
      width: 192px;
      height: 192px;
      border-radius: 100%;
      border: 1px solid #ccc;
      overflow: hidden;
    }

    .site-title-display {
      margin-left: 1em;
    }

    .site-title-actions {
      flex-grow: 1;
    }
  }

  .ant-divider {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .site-introduce {
    padding: 1em;
  }
`;

export function SiteProfile(props: { item: SchoolRes }) {
  const { t } = useTranslation();

  const coverImg = useMemo(() => {
    if (props.item && props.item.youtubeUrl != null && props.item.youtubeUrl) {
      return (
        <div className={'youtube-video-instruction'}>
          <iframe
            title={props.item.name}
            className="video-instruction"
            width="100%"
            height="100%"
            src={props.item.youtubeUrl}
          />
        </div>
      );
    } else if (
      props.item &&
      props.item.thumbnailsUrl != null &&
      props.item.thumbnailsUrl
    ) {
      return (
        <img
          alt={props.item.name}
          src={props.item.thumbnailsUrl}
          style={{ width: '100%', height: 'auto' }}
        />
      );
    } else {
      return null;
    }
  }, []);

  const subscribeUser = () => {};

  return (
    <SiteProfileStyle className={'site-profile'}>
      <div className={'site-introduction'}>{coverImg}</div>

      <div className={'site-header'}>
        <div className={'site-avatar'}>
          <img alt={props.item.shortIntroduce} src={props.item.avatarUrl} />
        </div>

        <div className={'site-title-display'}>
          <h2 dangerouslySetInnerHTML={{ __html: props.item.name }} />
        </div>

        <div className={'site-title-actions'}>
          <Button
            type="text"
            onClick={subscribeUser}
            style={{ float: 'right' }}
          >
            {t('label.subscribe')}
          </Button>
        </div>
      </div>

      <Divider />

      <div
        className={'site-introduce'}
        dangerouslySetInnerHTML={{ __html: props.item.introduce }}
      />
    </SiteProfileStyle>
  );
}

export function SiteCalendarProfile(props: { items: SessionRes[] }) {
  // const { t } = useTranslation();

  return (
    <div className={'site-calendar'}>
      <div className={'site-introduction'}>
        <PublicCalendarInfo events={props.items} onChangeDate={() => {}} />
      </div>
    </div>
  );
}

export function SiteProductProfile(props: {
  items: ProductRes[];
  school: SchoolRes;
}) {
  // const { t } = useTranslation();

  return (
    <div className={'site-calendar'}>
      <div className={'site-introduction'}>
        <Row gutter={[40, 20]} align="top">
          {props.items &&
            props.items.map((item) => {
              return (
                <Col span={6} key={item.productId}>
                  <PublicProductInfo item={item} />
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
}

export function SiteCourses(props: { items: CourseRes[]; school: SchoolRes }) {
  // const { t } = useTranslation();

  return (
    <div className={'site-calendar'}>
      <div className={'site-introduction'}>
        <Row gutter={[40, 20]} align="top">
          {props.items &&
            props.items.map((item) => {
              return <Col span={8} key={item.courseId}></Col>;
            })}
        </Row>
      </div>
    </div>
  );
}

export default function PublishProfileContainer(props: {
  schoolData: SchoolRes;
  // teachers: any[];
  products: ProductRes[];
  courses: CourseRes[];
  sessions: SessionRes[];
}) {
  // const { t } = useTranslation();

  console.log(props.schoolData);

  return (
    <PublicProfileStyle className={'site-information'}>
      <div className={'site-group site-profile'}>
        <SiteProfile item={props.schoolData} />
      </div>

      {props.courses.length > 0 && (
        <div className={'site-group course-group'}>
          <h2>Khóa học</h2>

          <SiteCourses school={props.schoolData} items={props.courses} />
        </div>
      )}

      {props.products.length > 0 && (
        <div className={'site-group product-group'}>
          <h2>Tài liệu</h2>

          <SiteProductProfile
            school={props.schoolData}
            items={props.products}
          />
        </div>
      )}

      {props.sessions.length > 0 && (
        <div className={'site-group calendar-group'}>
          <h2>Thời khóa biểu</h2>

          <SiteCalendarProfile items={props.sessions} />
        </div>
      )}
    </PublicProfileStyle>
  );
}

const PublicProfileStyle = styled.div`
  .site-profile {
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px 0px #ccc;
  }

  .site-group {
    margin-bottom: 3em;
  }
`;
