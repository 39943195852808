import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { FileResponse } from '@modules/product/services/assets_model';

export const UploadAPI = createApi({
  reducerPath: 'UploadAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileResponse, unknown>({
      query: (body) => ({
        url: '/files/upload',
        method: 'POST',
        body,
      }),
    }),
  }),
});
