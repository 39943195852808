import React from 'react';
import styled from 'styled-components';
import SearchProduct from '@modules/public/components/filter-product/SearchProduct';

const FindProductPageStyle = styled.div`
  font-size: 16px;
`;

const FindProductPage = () => {
  return (
    <FindProductPageStyle className={'find-product-page'}>
      <SearchProduct type={'products'} />
    </FindProductPageStyle>
  );
};

export default FindProductPage;
