import { WysyEditorProps } from '@components/editor/WysyEditor';
import React, { useEffect, useState } from 'react';
import useDebounce from '@hooks/useDebounce';
import styled from 'styled-components';

export default function RawTextArea(props: WysyEditorProps) {
  const [value, setValue] = useState<{ value: string; triggerChange: boolean }>(
    {
      value: '',
      triggerChange: false,
    }
  );

  useEffect(() => {
    if (props.initValue !== value.value) {
      setValue({
        value: props.initValue ? props.initValue : '',
        triggerChange: false,
      });
    }
  }, [props.initValue]);

  const onEditorChange = (value: string | undefined) => {
    setValue({
      value: value ?? '',
      triggerChange: false,
    });
  };

  useDebounce(() => {
    if (value.triggerChange) {
      onEditorBlur();
    }
  }, [value]);

  const onEditorBlur = () => {
    props.onChange(value.value);
  };

  return (
    <RawTextAreaStyle
      className={'raw-html-editor'}
      value={value.value}
      onChange={(evt) => onEditorChange(evt.target.value)}
      onBlur={onEditorBlur}
    />
  );
}

const RawTextAreaStyle = styled.textarea`
  width: 100%;
  font-size: 14px;
  min-height: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  z-index: 2;
  margin-bottom: 30px;
`;
