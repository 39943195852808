import styled from 'styled-components';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Checkbox, Col, Divider, notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import ButtonGroup from '@components/button/ButtonGroup';
import React, { useEffect, useState } from 'react';
import KeywordInput from '@components/input/KeywordInput';
import { Default_Gutter } from '@components/grid';
import {
  ResourceRes,
  UpdateResourceMetadataReq,
} from '@modules/product/services/resource_model';

const ResourceMetadataFormStyle = styled.div`
  width: 100%;
  font-size: 14px;
`;

const initialValue: UpdateResourceMetadataReq = {
  resourceId: -1,
  name: '',
  className: '',
  keyword: '',
  description: '',
  type: '',
  showQuestionNumber: false,
};

const ResourceMetadataForm = (props: {
  resourceId: number;
  lessonId?: number;
  onSuccess: (data: ResourceRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [findById, { data, isSuccess, isFetching }] =
    ResourceAPI.endpoints.findById.useLazyQuery();

  const [updateResourceMetadata, { isLoading }] =
    ResourceAPI.endpoints.updateResourceMetadata.useMutation({});

  const [keywords, setListKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (props.resourceId > 0) {
      findById(props.resourceId);
    }
  }, [props.resourceId]);

  useEffect(() => {
    if (data && isSuccess) {
      updateResourceData(data);
    }
  }, [data]);

  const updateResourceData = (data: ResourceRes) => {
    formik.setValues({
      resourceId: data.resourceId,
      name: data.name,
      className: data.className ?? '',
      keyword: keywords.join(','),
      description: data.description,
      type: data.type,
      showQuestionNumber: data.showQuestionNumber,
    });

    setListKeywords(
      data.keyword != null && data.keyword.trim() !== ''
        ? data.keyword.split(',')
        : []
    );
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request: UpdateResourceMetadataReq = {
        ...values,
        keyword: keywords.join(','),
        lessonId: props.lessonId ?? -1,
      };

      updateResourceMetadata(request)
        .unwrap()
        .then((res: any) => {
          notification.success({
            message: t('resource.warning.update_success'),
            description: t('resource.warning.update_success_message'),
            placement: 'bottomRight',
          });

          if (res.success) {
            props.onSuccess(res);
          }
        })
        .catch(() => {
          notification.error({
            message: t('resource.warning.update_error'),
            description: t('resource.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <ResourceMetadataFormStyle>
      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <Input
              required={true}
              name="name"
              type="text"
              label={t('label.name')}
              placeholder={t('label.name')}
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <InputGroup label={t('label.keyword')}>
              <KeywordInput
                keywords={data?.keyword}
                placeholder={t('label.enter_keyword')}
                enterButton={t('label.add')}
                size={'large'}
                onKeywordChange={(values) => setListKeywords(values)}
              />
            </InputGroup>

            <InputGroup label={t('label.description')}>
              <Input
                type={'textarea'}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                style={{ minHeight: 120 }}
                maxLength={512}
              />
            </InputGroup>

            <Divider />

            <Input
              name="className"
              label={t('label.class_name')}
              placeholder={t('label.class_name')}
              value={formik.values.className}
              onChange={formik.handleChange}
            />

            <InputGroup label={'Show question number'}>
              <Checkbox value={formik.values.showQuestionNumber}>
                &nbsp;
              </Checkbox>
            </InputGroup>
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <ButtonGroup className="submit-container" type={'right'}>
              <Button type={'default'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>

              <Button
                type={'primary'}
                loading={isFetching || isLoading}
                onClick={formik.submitForm}
              >
                {t('button.update')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </FormikProvider>
    </ResourceMetadataFormStyle>
  );
};

export default ResourceMetadataForm;
