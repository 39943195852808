import React, { useState } from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from 'antd/lib/button';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { IconUtils } from '@utils/IconUtils';
import { CreateSchoolSupportUser } from '../component/users/CreateSchoolSupportUserForm';
import SchoolSupportUserTable from '../container/users/SchoolSupportUserTable';

export enum ManageUserActionEnum {
  default = '',
  add = 'add',
  update = 'update',
  delete = 'delete',
}

const ManageSchoolSupportPage = (props: { schoolId?: number }) => {
  const { t } = useTranslation();

  const [reload, setReload] = useState({ reload: false, timestamp: -1 });

  const [action, setAction] = useState({
    action: ManageUserActionEnum.default,
    params: -1,
  });

  const reloadData = () => {
    setReload({ reload: true, timestamp: DateAndTimeUtils.getCurrentTime() });
    revertToDefault();
  };

  const revertToDefault = () => {
    setAction({ action: ManageUserActionEnum.default, params: -1 });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          School Supports
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() =>
              setAction({ action: ManageUserActionEnum.add, params: -1 })
            }
          />
        </H1>
      </HeaderGroup>

      <SchoolSupportUserTable reload={reload} schoolId={props.schoolId} />

      {action.action === ManageUserActionEnum.add && (
        <CustomModal
          header={t('school_admin.actions.add')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateSchoolSupportUser
              schoolId={props.schoolId}
              onSuccess={reloadData}
              onCancel={revertToDefault}
            />
          }
          onCloseFunc={revertToDefault}
        />
      )}
    </>
  );
};

export default ManageSchoolSupportPage;
