import EmptyLayout from '@components/Layout/EmptyLayout';
import React from 'react';
import { useRouter } from '@hooks/useRouter';
import {
  LoginComp,
  LogoutComp,
  RedirectLoginReq,
} from '@modules/authorization/components/LogoutComp';

const Launch = () => {
  const router = useRouter();

  const data = router.query as unknown as RedirectLoginReq;

  if (data.error) {
    return <LogoutComp />;
  } else if (data.access_token) {
    const request = {
      ...data,
      token: data.access_token,
      iat: data.iat,
      exp: data.exp,
    };

    return (
      <EmptyLayout>
        <LoginComp data={request} />
      </EmptyLayout>
    );
  } else {
    return null;
  }
};

export default Launch;
