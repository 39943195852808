import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '@app/redux/hook';
import { updateAnonymousUser } from '@app/redux/slices/userInfomationSlice';
import { Button } from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import React, { ReactNode } from 'react';

export const EnterAnonymousForm = (props: {
  buttonContent: ReactNode;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.userInformation);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      email: data.email ?? '',
      phone: data.phone ?? '',
    },

    validationSchema: yup.object().shape({
      firstName: yup.string().required(t('validation.required.field')),
      lastName: yup.string().required(t('validation.required.field')),
      email: yup.string(),
      phone: yup.string(),
    }),

    onSubmit: (values) => {
      dispatch(updateAnonymousUser(values));
      props.onSuccess();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Input
        required
        name="firstName"
        type="text"
        label={t('label.firstName')}
        placeholder={t('label.firstName')}
        onChange={formik.handleChange}
        value={formik.values.firstName}
      />

      <Input
        required
        name="lastName"
        type="text"
        label={t('label.lastName')}
        placeholder={t('label.lastName')}
        onChange={formik.handleChange}
        value={formik.values.lastName}
      />

      <Input
        name="email"
        type="text"
        label={t('label.email')}
        placeholder={t('label.email')}
        onChange={formik.handleChange}
        value={formik.values.email}
      />

      <Input
        name="phone"
        type="text"
        label={t('label.phone')}
        placeholder={t('label.phone')}
        onChange={formik.handleChange}
        value={formik.values.phone}
      />

      <ButtonGroup className="submit-container" type={'right'}>
        <Button
          type={'primary'}
          size={'large'}
          shape={'round'}
          onClick={formik.submitForm}
          icon={IconUtils.actions.save}
        >
          {props.buttonContent}
        </Button>
      </ButtonGroup>
    </FormikProvider>
  );
};
