import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import {
  CertificateRes,
  CreateCertificateReq,
  CreateUserCertificateReq,
  IssueCertificateReq,
  UpdateCertificateReq,
  UpdateCertificateScoreReq,
  UpdateCertificateTemplateReq,
  UserCertificateRes,
} from '@modules/admin/service/program';

export const CertificateAPI = createApi({
  reducerPath: 'CertificateAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<CertificateRes>, unknown>({
      query: () => ({
        url: `/certificates`,
      }),
    }),

    findById: builder.query<CertificateRes, unknown>({
      query: (id) => ({
        url: `/certificates/${id}`,
        method: 'GET',
      }),
    }),

    create: builder.mutation<CertificateRes, CreateCertificateReq>({
      query: (body) => ({
        url: '/certificates',
        body,
        method: 'POST',
      }),
    }),

    update: builder.mutation<CertificateRes, UpdateCertificateReq>({
      query: (body) => ({
        url: `/certificates/${body.certificateId}`,
        method: 'PUT',
        body,
      }),
    }),

    updateScores: builder.mutation<CertificateRes, UpdateCertificateScoreReq>({
      query: (body) => ({
        url: `/certificates/${body.certificateId}/scores`,
        method: 'PUT',
        body,
      }),
    }),

    updateTemplate: builder.mutation<
      CertificateRes,
      UpdateCertificateTemplateReq
    >({
      query: (body) => ({
        url: `/certificates/${body.certificateId}/template`,
        method: 'PUT',
        body,
      }),
    }),

    disabled: builder.mutation<CertificateRes, { certificateId: number }>({
      query: (body) => ({
        url: `/certificates/${body.certificateId}`,
        method: 'DELETE',
      }),
    }),

    getUserCertificate: builder.query<
      UserCertificateRes[],
      { certificateId: number; classId: number }
    >({
      query: (params) => ({
        url: `/certificates/${params.certificateId}/classes/${params.classId}`,
      }),
    }),

    createUserCertificate: builder.mutation<
      UserCertificateRes,
      CreateUserCertificateReq
    >({
      query: (body) => ({
        url: `/certificates/${body.certificateId}/classes/${body.classId}/users/${body.userId}`,
        body,
        method: 'POST',
      }),
    }),

    issueCertificate: builder.mutation<UserCertificateRes, IssueCertificateReq>(
      {
        query: (body) => ({
          url: `/certificates/${body.certificateId}/classes/${body.classId}/users/${body.userId}`,
          method: 'PUT',
          body,
        }),
      }
    ),

    viewUserCertificate: builder.query<UserCertificateRes, string>({
      query: (code) => ({
        url: `/public/certificate/${code}`,
      }),
    }),
  }),
});
