export const ClassRankingReport = (props: { classId: number }) => {
  return (
    <div className={'class-ranking-report'}>
      <p>Ranking report xếp hạng học sinh theo thứ tự của các bài kiểm tra.</p>

      <p>
        Mỗi class được link với 1 ciriculumn, thông qua kết quả mà học sinh sẽ
        được xếp hạng trong danh sách này.
      </p>
    </div>
  );
};
