import { useTranslation } from 'react-i18next';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '@app/redux/slices/profileSlice';
import { H1 } from '@components/typography';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import LoginForm from '@modules/authorization/container/LoginForm';

const LogoutPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  });

  const handleLoginSuccess = () => {};

  return (
    <AuthorizeStyle>
      <div className={'home-block-container'}>
        <div className={'gstudy-container'}>
          <H1>{t('login.header')}</H1>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              <p>{t('login.description')}</p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <LoginForm successHandle={handleLoginSuccess} />
            </Col>
          </Row>
        </div>
      </div>
    </AuthorizeStyle>
  );
};

export default LogoutPage;
