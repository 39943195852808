import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import FilterResource from '@cms/resource-bank/FilterResource';
import ListResourceBanks, {
  ListEditResourceBanks,
  ListSelectResourceBanks,
} from '@cms/resource-bank/ListResourceBank';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import Card, { CardClassEnum } from '@components/card';
import { Divider } from 'antd';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import {
  FilterResourceReq,
  ResourceRes,
} from '@modules/product/services/resource_model';

export enum ResourceBankCompMode {
  edit = 'edit',
  select = 'select',
}

export default function ResourceBankComp(props: {
  mode: ResourceBankCompMode;
  selectedResources: ResourceProps[];
  layout?: 'horizontal' | 'vertical';
  onSelect: (rs: ResourceProps) => void;
}) {
  const [params, setParams] = useState<FilterResourceReq>({
    page: 1,
    size: MAX_PAGE_SIZE * 3,
    keyword: '',
    lessonId: -1,
  });

  const [resources, setResources] = useState<ResourceProps[]>([]);

  const [filterResource, { data, isSuccess, isFetching }] =
    ResourceAPI.endpoints.filterResource.useLazyQuery();

  useEffect(() => {
    const filterParams = {
      ...params,
      selectAll: params.lessonId != null && params.lessonId > 0 ? true : false,
    };
    filterResource(filterParams);
  }, [params]);

  useEffect(() => {
    if (isSuccess && data) {
      const _resources = data.content.map((item: ResourceRes) => {
        return ResourceUtils.convert(item, '', -1);
      });

      setResources(_resources);
    }
  }, [data]);

  const handleOnFilter = (filterData: {
    keyword: string;
    lessonId: number;
  }) => {
    setParams((prev) => {
      return {
        ...prev,
        keyword: filterData.keyword,
        lessonId: filterData.lessonId,
      };
    });
  };

  const handleOnPageChange = (params: PaginationReq) => {
    setParams((prev) => {
      return {
        ...params,
        keyword: prev.keyword,
        lessonId: prev.lessonId,
      };
    });
  };

  return (
    <ResourceBankStyle
      className={'resource-bank-container show-correct-answer'}
    >
      {props.mode === ResourceBankCompMode.edit && (
        <>
          <Card className={CardClassEnum.rectangle_box}>
            <FilterResource layout={props.layout} onSubmit={handleOnFilter} />
          </Card>

          <ListEditResourceBanks
            isFetching={isFetching}
            data={data}
            params={params}
            refresh={handleOnPageChange}
          />
        </>
      )}

      {props.mode === ResourceBankCompMode.select && (
        <>
          <FilterResource layout={props.layout} onSubmit={handleOnFilter} />

          <Divider />

          <ListResourceBanks
            type={'add'}
            resources={resources}
            selectedResources={props.selectedResources}
            onSelect={props.onSelect}
          />
        </>
      )}
    </ResourceBankStyle>
  );
}

export const SelectResourceBankComp = (props: {
  selectedResources: number[];
  handleOnClick: (resource: ResourceProps) => void;
}) => {
  const [resources, setResources] = useState<ResourceProps[]>([]);

  const [filterResource, { data, isSuccess, isFetching }] =
    ResourceAPI.endpoints.filterResource.useLazyQuery();

  useEffect(() => {
    if (isSuccess && data) {
      const _resources = data.content.map((item: ResourceRes) => {
        return ResourceUtils.convert(item, '', -1);
      });

      setResources(_resources);
    }
  }, [isFetching]);

  const handleOnFilter = (params: { keyword: string; lessonId: number }) => {
    const filterData = {
      ...params,
      selectAll: params.lessonId != null && params.lessonId > 0,
    };
    filterResource(filterData);
  };

  return (
    <ResourceBankStyle className={'resource-bank-container'}>
      <FilterResource layout={'vertical'} onSubmit={handleOnFilter} />

      <ListSelectResourceBanks
        resources={resources}
        selectedResources={props.selectedResources}
        handleOnClick={props.handleOnClick}
      />
    </ResourceBankStyle>
  );
};

const ResourceBankStyle = styled.div`
  display: flex;
  flex-direction: column;

  > .ant-divider {
    margin: 12px 0;
  }

  .list-resource-bank {
    flex-grow: 1;
    overflow-y: auto;
  }
`;
