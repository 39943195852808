import React, { useEffect, useState } from 'react';

import { Button, Col, notification, Row } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { LessonCollectionAPI } from '@modules/product/services/CollectionAPI';
import { ImageScale } from '@utils/ImageScale';
import { InputGroup } from '@components/input/InputGroup';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { LessonTypeEnum } from '@modules/admin/service/model';
import SelectLessonExample from '@cms/resource-bank/SelectLessonExample';
import {
  CreateCollectionReq,
  LessonRes,
  UpdateCollectionReq,
} from '../services/lesson_model';

const initialValue = {
  name: '',
  description: '',
  thumbnails: '',
  lessonExampleId: -1,
};

export const CollectionCreateForm = (props: {
  onSelect: (exampleId: number) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [lessonExampleId, setLessonExampleId] = useState(-1);

  const handleOnSubmit = () => {
    if (lessonExampleId != null && lessonExampleId > 0) {
      props.onSelect(lessonExampleId);
    }
  };

  return (
    <Row gutter={[Default_Gutter, Default_Gutter]}>
      <Col span={24}>
        <SelectLessonExample
          category={LessonTypeEnum.collection}
          name={'lessonExampleId'}
          value={lessonExampleId}
          onChange={(value) => setLessonExampleId(value)}
        />
      </Col>

      <Col span={24}>
        <ButtonGroup className="submit-container" type={'right'}>
          <Button
            type="default"
            size={'large'}
            shape={'round'}
            onClick={props.onCancel}
          >
            {t('button.cancel')}
          </Button>

          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={handleOnSubmit}
            icon={IconUtils.save}
          >
            {t('button.create')}
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default function CollectionEditForm(props: {
  lessonId?: number;
  successHandle: (isAdded: boolean, data: LessonRes) => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [getLesson, { data, isSuccess, isFetching }] =
    LessonAPI.endpoints.getLesson.useLazyQuery({});

  const [createLesson, { isLoading: isCreateLoading }] =
    LessonCollectionAPI.endpoints.createLesson.useMutation({});

  const [updateLesson, { isLoading: isUpdateLoading }] =
    LessonCollectionAPI.endpoints.updateLesson.useMutation({});

  useEffect(() => {
    if (props.lessonId) {
      getLesson(props.lessonId);
    }
  }, [props.lessonId]);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setValues({
        name: data.name,
        description: data.description,
        thumbnails: data.thumbnails,
        lessonExampleId: data.lessonId,
      });
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,
    validationSchema: yup.object().shape({
      name: yup.string().trim().required(t('validation.required.field')),
      lessonExampleId: yup
        .number()
        .min(1, t('validation.required.field'))
        .required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.lessonId != null && props.lessonId > 0) {
        const payload: UpdateCollectionReq = {
          lessonId: props.lessonId,
          name: values.name,
          description: values.description,
          thumbnails: values.thumbnails,
        };

        updateLesson(payload)
          .unwrap()
          .then((data) => {
            props.successHandle(true, data);

            notification.success({
              message: t('lesson.warning.success'),
              description: t('lesson.warning.update_success'),
              placement: 'bottomRight',
            });
          })
          .catch(() => {
            notification.error({
              message: t('lesson.warning.error'),
              description: t('lesson.warning.update_error'),
              placement: 'bottomRight',
            });
          });
      } else {
        const payload: CreateCollectionReq = {
          type: LessonTypeEnum.collection,
          name: values.name,
          description: values.description,
          lessonExampleId: values.lessonExampleId,
        };

        createLesson(payload)
          .unwrap()
          .then((data) => {
            props.successHandle(true, data);

            notification.success({
              message: t('lesson.warning.success'),
              description: t('lesson.warning.add_success'),
              placement: 'bottomRight',
            });
          })
          .catch(() => {
            notification.error({
              message: t('lesson.warning.error'),
              description: t('lesson.warning.add_error'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      {props.lessonId != null && props.lessonId > 0 ? (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col flex={ImageScale.lesson.width + 15 + 'px'}>
            <InputGroup label={t('label.thumbnails')}>
              <SingleUpload
                scale={ImageScale.lesson.scale}
                accept={'image/jpg, image/jpeg, image/png'}
                multiple={false}
                onSuccessFunc={(value) =>
                  formik.setFieldValue('thumbnails', value)
                }
              >
                <IconImage
                  src={formik.values.thumbnails}
                  width={ImageScale.lesson.width}
                  height={ImageScale.lesson.height}
                />
              </SingleUpload>
            </InputGroup>
          </Col>

          <Col flex={'auto'}>
            <Input
              required
              name="name"
              type="text"
              label={t('label.name')}
              placeholder={t('label.name')}
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <Input
              label={t('label.description')}
              type={'textarea'}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              style={{ minHeight: 120 }}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <Input
              required
              name="name"
              type="text"
              label={t('label.name')}
              placeholder={t('label.name')}
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <Input
              label={t('label.description')}
              type={'textarea'}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              style={{ minHeight: 120 }}
            />

            <SelectLessonExample
              category={LessonTypeEnum.collection}
              formik={formik}
              name={'lessonExampleId'}
              value={formik.values.lessonExampleId}
              onChange={(value) =>
                formik.setFieldValue('lessonExampleId', value)
              }
              disabled={props.lessonId != null && props.lessonId > 0}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <ButtonGroup type={'right'} className="submit-container">
            <Button type="default" shape={'round'} onClick={props.onCloseFunc}>
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              loading={isFetching || isCreateLoading || isUpdateLoading}
              onClick={formik.submitForm}
              icon={IconUtils.save}
            >
              {props.lessonId != null && props.lessonId > 0
                ? t('button.update')
                : t('button.create')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
}
