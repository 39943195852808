import React, { useEffect } from 'react';

import { Button, notification, Switch } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { SchoolPaymentMethodAPI } from '@modules/payment/services/SchoolPaymentMethodAPI';
import { H2 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';
import {
  CreateSchoolPaymentMethodReq,
  SchoolPaymentMethodRes,
} from '@modules/payment/services/model';

const initialValue: CreateSchoolPaymentMethodReq = {
  paymentMethodId: -1,
  content: '',
  setting: '',
  activated: false,
};

export function CreateSchoolPaymentMethodForm(props: {
  paymentMethodId: number | null;
  successHandle: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess }] =
    SchoolPaymentMethodAPI.endpoints.findById.useLazyQuery();

  const [createOrUpdate, { isLoading }] =
    SchoolPaymentMethodAPI.endpoints.createOrUpdate.useMutation({});

  useEffect(() => {
    if (props.paymentMethodId != null && props.paymentMethodId > 0) {
      findById(props.paymentMethodId);
    }
  }, [props.paymentMethodId]);

  useEffect(() => {
    if (data && isSuccess) {
      setFormData(data);
    }
  }, [data]);

  const setFormData = (data: SchoolPaymentMethodRes) => {
    formik.setValues({
      paymentMethodId: data.paymentMethodId,
      content: data.content,
      setting: data.setting,
      activated: data.activated,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      content: yup.string().required(t('validation.required.field')),
      setting: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      createOrUpdate(values)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('payment_method.warning.update_success'),
            description: t('payment_method.warning.update_success_message'),
            placement: 'bottomRight',
          });

          props.successHandle();
        })
        .catch(() => {
          notification.error({
            message: t('payment_method.warning.update_error'),
            description: t('payment_method.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      {data && (
        <>
          <H2>
            {data.code} - {data.name}
          </H2>

          <p>{data.description}</p>
        </>
      )}

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            label={t('label.content')}
            required={true}
            type={'textarea'}
            name="content"
            onChange={formik.handleChange}
            value={formik.values.content}
            style={{ minHeight: 120 }}
          />

          <Input
            label={t('label.setting')}
            required={true}
            type={'textarea'}
            name="setting"
            onChange={formik.handleChange}
            value={formik.values.setting}
            style={{ minHeight: 120 }}
          />

          <InputGroup label={t('label.activated')}>
            <Switch
              checked={formik.values.activated}
              onChange={(val) => formik.setFieldValue('activated', val)}
            />
          </InputGroup>

          <ButtonGroup type={'right'} className="submit-container">
            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              loading={isLoading}
              onClick={formik.submitForm}
              icon={IconUtils.actions.save}
            >
              <>{t('button.update')}</>
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
}
