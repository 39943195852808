import { ClassRes } from '@modules/users/services/clazz';
import { CertificateRes } from '@modules/admin/service/program';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { CertificateDisplay } from '@modules/admin/component/CertificateDisplay';
import React from 'react';
import { CertificateScoreTable } from '@modules/admin/component/CertificateScoreTable';

export const ClassStudentCertificate = (props: {
  item: ClassRes;
  certificate: CertificateRes;
}) => {
  return (
    <div className={'class-student-certificate'}>
      <HeaderGroup className={'mt-0'}>
        <H1>Class Certificate</H1>

        <CertificateDisplay className={'bordered'} item={props.certificate} />
      </HeaderGroup>

      <p>
        Sau khi hoàn thành khóa học, dựa vào các điều kiện dưới đây, học sinh có
        thể được cấp chứng chỉ.
      </p>

      <CertificateScoreTable
        classId={props.item.classId}
        item={props.certificate}
      />
    </div>
  );
};
