import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
const { Content } = Layout;

const ContentWrapper = styled(Content)``;

const PublicContent = (props: { children: ReactNode }) => {
  return (
    <ContentWrapper className="site-layout">
      <div className="site-layout-content">{props.children}</div>
    </ContentWrapper>
  );
};

export default PublicContent;
