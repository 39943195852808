import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { CreateGradeReq, GradeRes } from '@modules/admin/service/model';

export const GradeAPI = createApi({
  reducerPath: 'GradeAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<GradeRes>, unknown>({
      query: () => ({
        url: `/grades/find-all`,
      }),
    }),

    findById: builder.query<GradeRes, unknown>({
      query: (id) => ({
        url: `/grades/${id}`,
        method: 'GET',
      }),
    }),

    createGrade: builder.mutation<GradeRes, CreateGradeReq>({
      query: (body: CreateGradeReq) => ({
        url: '/grades',
        body,
        method: 'POST',
      }),
    }),

    updateGrade: builder.mutation<GradeRes, CreateGradeReq>({
      query: (body: CreateGradeReq) => ({
        url: `/grades/${body.gradeId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteGrade: builder.mutation<BasicMessageRes, unknown>({
      query: (id) => ({
        url: `/grades/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
