import React, { ReactNode, useEffect, useState } from 'react';
import { SectionResourceBankStyle } from './SessionResourceBank';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import {
  LessonEditableActionEnum,
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import DragNDropContext, {
  DragNDropAction,
  DragNDropActionType,
} from '@components/react-dnd-bt/DragNDropContext';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { Spin } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import Space from 'antd/lib/space';
import Button from '@components/button';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import SectionUtils from '@cms/section-bank/SectionUtils';
import LessonSection from '@cms/section-bank/components/SectionItem';
import { CompMode } from '@cms/ComponentInteface';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { LessonSectionAPI } from '@modules/product/services/LessonSectionAPI';
import { Tooltip } from '@components/tooltip/Tooltip';
import useConfirmModal from '@components/modal/ConfirmModal';
import { LessonRes } from '@modules/product/services/lesson_model';

const DEFAULT_EMPTY_NAME = 'empty';

export const LessonSectionBank = (props: {
  lesson: LessonRes;
  section: LessonSectionProps | null;
  placeHolder?: ReactNode;
  onClick: (sec: LessonSectionProps) => void;
}) => {
  const { t } = useTranslation();

  const service = useLessonServiceContext();
  const { confirm } = useConfirmModal();

  const [reorderSections, { isLoading }] =
    LessonSectionAPI.endpoints.reorderSections.useMutation({});

  const [deleteSection] = LessonSectionAPI.endpoints.deleteSection.useMutation(
    {}
  );

  const { changeToMode, dispatchAction } = useLessonEditableContext();

  const [notDefaultSection, setNotDefaultSections] = useState<
    LessonSectionProps[]
  >([]);

  useEffect(() => {
    if (props.lesson && props.lesson.sections) {
      const sections: LessonSectionProps[] = [];
      props.lesson.sections.forEach((sec) => {
        if (sec.name !== DEFAULT_EMPTY_NAME) {
          sections.push(SectionUtils.convert(sec));
        }
      });
      setNotDefaultSections(sections);
      if (sections.length > 0 && props.section == null) {
        props.onClick(sections[0]);
      }
    } else {
      setNotDefaultSections([]);
    }
  }, [props.lesson]);

  const handleOnChange = (action: DragNDropAction) => {
    if (action.action === DragNDropActionType.order) {
      const sourceData = props.lesson.sections.filter((s) => {
        return s.lessonSectionId + '' === action.params?.new?.draggableId;
      });

      const newOrder = [...props.lesson.sections];
      newOrder.splice(action.params!.old!.index, 1);
      newOrder.splice(action.params!.new!.index, 0, sourceData[0]);

      const newSectionOrder: number[] = newOrder.map((dt) => {
        return dt.lessonSectionId;
      });

      const request = {
        lessonId: props.lesson.lessonId,
        sectionIds: newSectionOrder,
      };

      reorderSections(request)
        .unwrap()
        .then(() => {
          dispatchAction(LessonEditableActionEnum.refresh, -1);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  const handleOnSelect = () => {
    changeToMode(LessonMode.edit);
  };

  const handleRemoveSection = (lessonSectionId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('component.actions.delete_component'),
      t('button.confirm'),
      t('button.cancel'),
      (result) => {
        if (result) {
          deleteSection({
            lessonId: props.lesson.lessonId,
            lessonSectionId: lessonSectionId,
          })
            .unwrap()
            .then(() => {
              dispatchAction(LessonEditableActionEnum.refresh, -1);
            })
            .catch(() => {});
        }
      }
    );
  };

  return (
    <LessonSectionBanksStyle className={'lesson-sections-bank'}>
      {isLoading && (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
      )}

      <DragNDropContext
        id={props.lesson.lessonId + ''}
        onChange={handleOnChange}
      >
        <DropItem
          droppableId={'lesson'}
          acceptType={'section-item'}
          className={'draggable-container'}
        >
          {notDefaultSection.map((sec, index) => {
            return (
              <DragItem
                key={JSON.stringify(sec) + '_' + index}
                acceptType={'section-item'}
                index={index}
                draggableId={sec.lessonSectionId + ''}
                className={
                  props.section != null &&
                  props.section.lessonSectionId === sec.lessonSectionId
                    ? ' selected '
                    : ''
                }
              >
                <div
                  className={`section-item-group`}
                  key={sec.lessonSectionId + '-' + index}
                  onClick={() => props.onClick(sec)}
                >
                  <LessonSection
                    visible={true}
                    item={sec}
                    mode={CompMode.REVIEW}
                  />
                </div>

                <Space className={'section-actions bottom-left-menu'}>
                  <Button shape={'circle'} size={'small'} type={'primary'}>
                    <b>{index + 1}</b>
                  </Button>
                </Space>

                <Space className={'section-actions bottom-right-menu'}>
                  <Tooltip title={t('resource.actions.delete_question')}>
                    <Button
                      type={'primary'}
                      size={'small'}
                      shape={'circle'}
                      danger
                      onClick={() => handleRemoveSection(sec.lessonSectionId)}
                      icon={IconUtils.compose.delete}
                    />
                  </Tooltip>
                </Space>
              </DragItem>
            );
          })}
        </DropItem>
      </DragNDropContext>

      <SectionResourceBankStyle
        className={`section-resource-item blank-resource ${
          service.resourceId === 0 ? 'active' : ''
        }`}
        onClick={handleOnSelect}
      >
        <H1>{IconUtils.add_new}</H1>
        <p>{props.placeHolder ?? t('resource.actions.click_to_add_section')}</p>

        <Space className={'resource-actions bottom-left-menu'}>
          <Button shape={'circle'} size={'small'} type={'primary'}>
            <b>{props.lesson.sections.length + 1}</b>
          </Button>
        </Space>
      </SectionResourceBankStyle>
    </LessonSectionBanksStyle>
  );
};

const LessonSectionBanksStyle = styled.div`
  position: relative;

  .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: rgba(0, 0, 0, 0.25);
  }

  .draggable-item {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    margin-bottom: 1em;

    .section-item-group {
      max-width: 100%;
      aspect-ratio: 16 / 9;
      overflow: hidden;

      > .lesson-section {
        width: 800px;
        transform: scale(0.45); // 360 of max_width / 800;
        transform-origin: top left;
        padding: 1em;
      }
    }

    &.selected {
      outline: 1px solid ${(props) => props.theme.app.primary};
    }
  }

  .section-actions {
    position: absolute;
    z-index: 2;

    &.bottom-center-menu {
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    &.bottom-left-menu {
      left: 5px;
      bottom: 5px;
    }

    &.top-right-menu {
      top: 5px;
      right: 5px;
    }

    &.bottom-right-menu {
      right: 5px;
      bottom: 5px;
    }
  }
`;
