import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { EntityRequestEnum } from '@utils/Constant';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CategoryRes,
  GradeRes,
  SubjectRes,
} from '@modules/admin/service/model';
import { TeacherRes, UserRes } from '@modules/users/services/model';

export interface PublishCourseRes {
  course: CourseRes;
  author: TeacherRes;
  subjects: SubjectRes[];
  grades: GradeRes[];
  categories: CategoryRes[];
}

export interface CourseRes {
  courseId: number;

  code: string;

  name: string;
  description: string;
  keyword: string;
  introduce: string;
  shortIntroduce: string;

  startDate?: string;
  createdDate: string;
  modifiedDate?: string;
  publishedDate: string;
  status: number;
  price: number;

  video: string;
  thumbnails: string;

  students: UserRes[];
}

export interface CreateCourseReq {
  courseId?: number;

  video: string;
  thumbnails: string;
  name: string;
  description: string;
}

export interface AddStudentToCourseReq {
  courseId: number;
  studentId: number;
}

export interface UpdateCourseMetadataReq {
  courseId: number;
  name: string;

  video: string;
  thumbnails: string;

  keyword: string;
  description: string;

  instruction: string;
  shortInstruction: string;
}

export interface RegisterCourseReq {
  courseId: number;
  schoolId: number;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  message: string;
}

export interface RegistrationCourseRes {
  courseRegistrationId: number;
  course: CourseRes;
  student: UserRes;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  status: EntityRequestEnum;
  createdDate: string;
  confirmDate: string;
}

export interface AcceptRegisterCourseReq {
  courseRegistrationId: number;
  classIds: number[];
  accepted: boolean;
  removed: boolean;
}

export const CourseAPI = createApi({
  reducerPath: 'CourseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyCourses: builder.query<PaginationRes<CourseRes>, unknown>({
      query: () => ({
        url: `/courses`,
      }),
    }),

    getPublishCourses: builder.query<PaginationRes<CourseRes>, unknown>({
      query: () => ({
        url: `/courses/publish`,
      }),
    }),

    getCourse: builder.query<CourseRes, unknown>({
      query: (courseId) => ({
        url: `/courses/${courseId}`,
        method: 'GET',
      }),
    }),

    create: builder.mutation<CourseRes, CreateCourseReq>({
      query: (body: CreateCourseReq) => ({
        url: '/courses',
        body,
        method: 'POST',
      }),
    }),

    update: builder.mutation<CourseRes, CreateCourseReq>({
      query: (body: CreateCourseReq) => ({
        url: '/courses',
        body,
        method: 'PUT',
      }),
    }),

    updateMetadata: builder.mutation<CourseRes, UpdateCourseMetadataReq>({
      query: (body: UpdateCourseMetadataReq) => ({
        url: '/courses/metadata',
        body,
        method: 'PUT',
      }),
    }),

    deleteCourse: builder.mutation<BasicMessageRes, unknown>({
      query: (courseId: number) => ({
        url: `/courses/${courseId}`,
        method: 'DELETE',
      }),
    }),

    addStudent: builder.mutation<UserRes, AddStudentToCourseReq>({
      query: (body: AddStudentToCourseReq) => ({
        url: `/courses/${body.courseId}/students`,
        body,
        method: 'POST',
      }),
    }),

    removeStudent: builder.mutation<BasicMessageRes, AddStudentToCourseReq>({
      query: (body: AddStudentToCourseReq) => ({
        url: `/courses${body.courseId}/students`,
        body,
        method: 'DELETE',
      }),
    }),

    // register / accept reject courseRegistrationId

    getRegistrationCourses: builder.query<
      PaginationRes<RegistrationCourseRes>,
      unknown
    >({
      query: (params: any) => ({
        url: `/courses/register`,
        params,
      }),
    }),

    registerCourse: builder.mutation<BasicMessageRes, RegisterCourseReq>({
      query: (body: RegisterCourseReq) => ({
        url: '/courses/register',
        body,
        method: 'POST',
      }),
    }),

    acceptRegistration: builder.mutation<
      BasicMessageRes,
      AcceptRegisterCourseReq
    >({
      query: (body: AcceptRegisterCourseReq) => ({
        url: `/courses/register/accept`,
        body,
        method: 'PUT',
      }),
    }),
  }),
});
