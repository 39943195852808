// import { useTheme } from '@app/styled/StyledProvider';

export const getThemeColor = (theme: string) => {
  if (theme === 'purple') {
    return getColorArray('#362036', '#B785B7', '#212529');
  } else if (theme === 'blue') {
    return getColorArray('#1c375b', '#6f8197', '#212529');
  } else {
    return default_theme;
  }
};

const getColorArray = (
  primary: string,
  primaryBgr: string,
  secondary: string
) => {
  return {
    white: '#FFF',

    color: {
      white: '#fff',
      black: '#000',
      grey: '#ccc',
      dark_grey: '#333',
    },

    app: {
      primary: primary,
      primary_text: '#FFF',
      primary_bgr: primaryBgr,
      background: '#ebecf0', //'#fafafa'
      border: '#ccc',

      secondary: '#212529',
      secondary_text: '#FFF',
    },

    warning: {
      info: '#0474BB',
      success: '#00A14B',
      warning: '#f58220',
      error: '#ED1F24',
      error_bgr: '#f9d0d0',
    },

    menu: {
      bgr: '#FFF',
      border: '#ccc',

      text: primary,
      background: primaryBgr,

      active: {
        text: '#FFF',
        background: primary,
        submenu_bgr: primaryBgr,
      },
    },

    event: {
      primary: primary,
      primary_bgr: primaryBgr,
      default: '#e2d8f9',
      future: '#0474BB',
    },

    status: {
      activated: '#0474BB',
      inactivated: '#CCC',
      removed: '#ED1F24',

      pending: '#f58220',
      published: '#884ffb',
      empty: '#ccc',

      not_started: '#ccc',
      in_progress: '#0474BB',
      completed: '#884ffb',
    },

    exercise: {
      check_answer: '#00A14B',
      view_report: '#f58220',

      save: '#0474BB',
      submit: '#00A14B',
      review: '#f58220',

      exit_grading: '#333',
      exit_grading_text: '#FFF',

      need_grading_bgr: '#e2d8f9',
    },

    assignment: {
      pending: '#cccccc',
      pending_bgr: '#cccccc',

      active: '#0474BB',
      active_bgr: '#cde3f6',

      expired: '#ED1F24',
      expired_bgr: '#f9d0d0',

      not_started: '#ED1F24',
      in_progress: '#f58220',
      need_grading: '#0474BB',
      completed: '#00A14B',
    },

    component: {
      primary: primary,
      primary_bgr: primaryBgr,

      secondary: '#ccc',
      secondary_bgr: '#f5f5f5',

      disabled: '#ccc',

      //score
      correct: '#00A14B',
      incorrect: '#ED1F24',
      incorrect_bgr: '#f9d0d0',
      not_started: '#ccc',
      skip: '#f58220',
      warning: '#f58220',
      need_grading: '#0474BB',

      // show correct answer
      correct_answer: '#d424ff',
      correct_answer_bgr: 'rgba(212, 36, 255, 0.3)',
    },
  };
};

const default_theme = {
  white: '#FFF',

  color: {
    white: '#fff',
    black: '#000',
    grey: '#ccc',
    dark_grey: '#333',
  },

  app: {
    primary: '#884ffb',
    primary_text: '#FFF',
    primary_bgr: '#e2d8f9',
    background: '#ebecf0', //'#fafafa'
    border: '#ccc',

    secondary: '#212529',
    secondary_text: '#FFF',
  },

  warning: {
    info: '#0474BB',
    success: '#00A14B',
    warning: '#f58220',
    error: '#ED1F24',
    error_bgr: '#f9d0d0',
  },

  menu: {
    bgr: '#FFF',
    border: '#ccc',

    text: '#884ffb',
    background: '#e2d8f9',

    active: {
      text: '#FFF',
      background: '#884ffb',
      submenu_bgr: '#e2d8f9',
    },
  },

  event: {
    primary: '#884ffb',
    primary_bgr: '#e2d8f9',
    default: '#e2d8f9',
    future: '#0474BB',
  },

  status: {
    activated: '#0474BB',
    inactivated: '#CCC',
    removed: '#ED1F24',

    pending: '#f58220',
    published: '#884ffb',
    empty: '#ccc',

    not_started: '#ccc',
    in_progress: '#0474BB',
    completed: '#884ffb',
  },

  exercise: {
    check_answer: '#00A14B',
    view_report: '#f58220',

    save: '#0474BB',
    submit: '#00A14B',
    review: '#f58220',

    exit_grading: '#333',
    exit_grading_text: '#FFF',

    need_grading_bgr: '#e2d8f9',
  },

  assignment: {
    pending: '#cccccc',
    pending_bgr: '#cccccc',

    active: '#0474BB',
    active_bgr: '#cde3f6',

    expired: '#ED1F24',
    expired_bgr: '#f9d0d0',

    not_started: '#ED1F24',
    in_progress: '#f58220',
    need_grading: '#0474BB',
    completed: '#00A14B',
  },

  component: {
    primary: '#0474BB',
    primary_bgr: '#cde3f6',

    secondary: '#ccc',
    secondary_bgr: '#f5f5f5',

    disabled: '#ccc',

    //score
    correct: '#00A14B',
    incorrect: '#ED1F24',
    incorrect_bgr: '#f9d0d0',
    not_started: '#ccc',
    skip: '#f58220',
    warning: '#f58220',
    need_grading: '#0474BB',

    // show correct answer
    correct_answer: '#d424ff',
    correct_answer_bgr: 'rgba(212, 36, 255, 0.3)',
  },
};

//  background: ${(props) => props.theme.app.primary};
// const theme = useTheme();
// background: theme.exercise.review
