import { CompConfiguration } from '@cms/ComponentInteface';
import React from 'react';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import { InputWordComp } from '@cms/comps/interact/fill-in-blank/InputWordComp';
import { FibWordOptionProps } from '@cms/comps/interact/inline/FibWordComp';
import { useFibWordCompContext } from '@cms/comps/interact/inline/FibWordCompContext';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';
import {
  ContentActionEnum,
  ContentViewerInlineEditable,
} from '@cms/comps/content/ContentViewerComp';

import styled from 'styled-components';
import OptionEditable from '@cms/editable/OptionEditable';
import { useFibWordEditable } from '@cms/comps/interact/inline/FibWordEditableContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import DOMPurify from 'dompurify';

export const FibWordGroupItem = ({
  drop,
  index,
}: {
  index: number;
  drop: FibWordOptionProps;
}) => {
  const { disabled, answers, setting, targetItems, handleOnChange } =
    useFibWordCompContext();

  const {
    editItem,
    changeToEdit,
    updateOptionData,
    updateOptionType,
    updateGroupOptions,
  } = useFibWordEditable();

  const getInlineAnswer = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans.split(':')[1];
      }
    });

    return inlineAns;
  };

  const getAnswerDisplay = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans;
      }
    });

    return inlineAns;
  };

  const textArray = drop.content.data.split(CompConfiguration.INLINE_COMPONENT);

  const getClassName = () => {
    return [
      'fib-word-group-item',
      editItem === drop.label ? 'edit-mode' : 'default-mode',
      `group-${index}`,
      `${setting.autoCapital ? 'auto-capital' : ''}`,
    ].join(' ');
  };

  return (
    <FibWordGroupStyle className={getClassName()}>
      {editItem === drop.label ? (
        <div className={`fib-word-item`}>
          <ContentViewerInlineEditable
            contentData={drop.content}
            onChange={(data) => updateOptionData(data, index, drop)}
            onCancel={() => changeToEdit('')}
          />
        </div>
      ) : (
        <OptionEditable
          disabled={{
            addOption: targetItems.length === 15,
            removeOption: targetItems.length === 1,
          }}
          contentTypes={[ContentActionEnum.CHANGE_TO_TEXT]}
          type={drop.content.type}
          onChangeType={(newType) => updateOptionType(newType, index, drop)}
          onOperation={(operation) =>
            updateGroupOptions(operation, index, drop)
          }
        >
          <div className={`fib-word-item`}>
            {textArray.map((text, idx) => {
              const sourceData = drop.label + '|' + idx;
              return (
                <React.Fragment key={text + '_' + idx}>
                  {idx > 0 && (
                    <>
                      {idx > 1 && (
                        <span className={'fib-word-text'}>&nbsp;</span>
                      )}

                      <div
                        className={`component-and-feedback fib-word-part part-${idx}`}
                      >
                        <CompFeedbackResultIcon
                          answer={getAnswerDisplay(sourceData)}
                        />

                        <InputWordComp
                          disabled={disabled}
                          value={getInlineAnswer(sourceData)}
                          onChange={(val) =>
                            handleOnChange(sourceData + ':' + val)
                          }
                          maxLength={setting.maxLength}
                        />
                      </div>
                    </>
                  )}

                  {text.trim() !== '' && (
                    <>
                      {text
                        .split(CompConfiguration.SEPARATE_CONTENT)
                        .map((content, index) => {
                          return (
                            <span
                              key={content + '_' + index}
                              className={'fib-word-text'}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(content),
                              }}
                            />
                          );
                        })}
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>

          <ComposeModeContent>
            {editItem === '' && (
              <Button
                className={'edit-content-group'}
                size={'small'}
                type={'primary'}
                shape={'circle'}
                onClick={() => changeToEdit(drop.label)}
                icon={IconUtils.actions.edit}
              />
            )}
          </ComposeModeContent>
        </OptionEditable>
      )}
    </FibWordGroupStyle>
  );
};

const FibWordGroupStyle = styled.div`
  width: 100%;

  .cms-content-actions {
    width: 100%;

    .cms-content-display {
      padding-right: 30px;
      position: relative;

      .edit-content-group {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0%, -50%);
      }
    }
  }

  .fib-word-item {
    .content-inline-editor {
      width: 100%;
    }
  }

  &.auto-capital {
    .fib-word-item {
      .fib-word-part {
        :first-child {
          text-transform: capitalize;
        }
      }
    }
  }

  .fib-word-item {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .fib-word-text {
    display: inline-block;
    padding: 0.25em 0;
  }

  .component-and-feedback {
    position: relative;
    display: flex;
    align-items: baseline;

    .feedback-icon {
      position: relative;
    }
  }
`;
