import React from 'react';
import { GradingExerciseMode } from '@cms/lesson-template/components/mode/ExerciseMode';
import { useGradingExerciseContext } from '@cms/lesson-template/context/GradingExerciseContext';
import { useTranslation } from 'react-i18next';

const ExerciseGradingScoreStatus = () => {
  const { t } = useTranslation();

  const { unscoredQuestion, totalManualScore } = useGradingExerciseContext();

  return (
    <GradingExerciseMode>
      {unscoredQuestion != null && unscoredQuestion >= 0 && (
        <>
          {t('exercise.warning.need_grade_question', {
            remain: unscoredQuestion,
            total: totalManualScore,
          })}
        </>
      )}
    </GradingExerciseMode>
  );
};

export default ExerciseGradingScoreStatus;
