import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import React, { useEffect, useRef, useState } from 'react';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompMode, TimelineProps } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { H5 } from '@components/typography';
import {
  SectionDropColumn,
  SectionResourceDragItem,
} from '@cms/section-bank/components/SectionDropColumn';
import { ResourceProps } from '@modules/product/components/resource/Resource';

import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import { SingleQuestionToolbarHandle } from '@cms/lesson-template/components/toolbar/SingeQuestionToolbar';
import {
  SectionActionEventProps,
  SectionEventsProvider,
} from '@cms/section-bank/context/SectionGroupContext';
import { CompActionEnum, TriggerCompEventType } from '@cms/utils/CompProps';
import {
  ExerciseEventEnum,
  usePracticeContext,
} from '@cms/lesson-template/context/PracticeContext';

export interface InteractVideoSectionSettingProps {
  timeline: TimelineProps[];
}

export interface InteractVideoSectionProps extends LessonSectionProps {
  type: SectionTypeEnum.interact_video;
  setting: InteractVideoSectionSettingProps;

  group: {
    header: ResourceProps[];
    content: ResourceProps[];
  };
}

export const InteractVideoSection = (props: {
  item: InteractVideoSectionProps;
  mode: CompMode;
}) => {
  const { event, triggerEvent } = usePracticeContext();

  const eventHandler = useRef<TriggerCompEventType | any>(null);
  const [invisibleIds, setInvisibleIds] = useState<number[]>([]);
  const [visibleIds, setVisibleIds] = useState<number[]>([]);
  const [videoId, setVideoId] = useState('');
  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    if (event.event === ExerciseEventEnum.exit_question) {
      setInvisibleIds((prev) => {
        return [...prev, ...event.resourceIds];
      });

      if (eventHandler.current) {
        eventHandler.current(videoId, CompActionEnum.video_resume, -1);
      }
    }
  }, [event]);

  useEffect(() => {
    const visibleRes = props.item.group.content.filter((res) => {
      return (
        visibleIds.includes(res.resourceId) &&
        !invisibleIds.includes(res.resourceId)
      );
    });
    setResources(visibleRes);
  }, [JSON.stringify(invisibleIds), JSON.stringify(visibleIds)]);

  const eventChange = (eventHandle: TriggerCompEventType) => {
    eventHandler.current = eventHandle;
  };

  const handleOnChange = ({
    compId,
    eventType,
    params,
  }: SectionActionEventProps) => {
    console.log('handleOnChange', compId, eventType, params);

    if (eventType === CompActionEnum.video_play) {
      const time = params;
      const resourceIds: number[] = [];

      if (
        props.item.setting.timeline != null &&
        props.item.setting.timeline.length > 0
      ) {
        props.item.setting.timeline.forEach((timeline, index) => {
          const visible =
            timeline.from <= time && (timeline.to < 0 || time < timeline.to); // from <= current time < to

          if (visible && props.item.group.content[index] != null) {
            resourceIds.push(props.item.group.content[index].resourceId);
          }
        });

        console.log('resourceIds and visibleIds', resourceIds, visibleIds);

        if (JSON.stringify(resourceIds) !== JSON.stringify(visibleIds)) {
          setVisibleIds(resourceIds);
          triggerEvent(ExerciseEventEnum.view_question, resourceIds);
          setVideoId(compId);

          if (eventHandler.current && resourceIds.length > 0) {
            eventHandler.current(compId, CompActionEnum.video_pause, time);
          }
        }
      }
    }
  };

  return (
    <InteractVideoSectionStyle
      className={`lesson-section interact-video-section ${
        props.mode === CompMode.COMPOSE ? 'compose' : 'interact'
      }`}
    >
      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.header && (
          <div className={'lesson-section-header-container'}>
            <ContentViewer contentData={props.item.content.header} />
          </div>
        )}

      <div className={'lesson-section-container'}>
        {(props.mode === CompMode.COMPOSE ||
          props.item.group.header.length > 0) && (
          <SectionEventsProvider
            group={'header'}
            onChange={handleOnChange}
            eventChange={eventChange}
          >
            <div className={'header-group fixed-group'}>
              <SectionDropColumn
                title={'Video or Audio here'}
                group={'header'}
                code={props.item.code}
                disabled={props.mode !== CompMode.COMPOSE}
              >
                {props.item.group.header.map((item, index) => {
                  return (
                    <SectionResourceDragItem
                      key={item.resourceId + '_' + index}
                      index={index}
                      item={item}
                      mode={props.mode}
                    />
                  );
                })}

                {props.item.group.header.length === 0 &&
                  props.mode === CompMode.COMPOSE && (
                    <H5 className={'empty-space'}>
                      Add an video. When video playing, the relative question
                      will be displayed below.
                    </H5>
                  )}
              </SectionDropColumn>
            </div>
          </SectionEventsProvider>
        )}

        {props.mode === CompMode.COMPOSE && (
          <div className={`content-group dynamic-group`}>
            <SectionDropColumn
              title={'Questions'}
              group={'content'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.content.map((item, index) => {
                return (
                  <SectionResourceDragItem
                    key={item.resourceId + '_' + index + '_'}
                    index={index}
                    item={item}
                    mode={props.mode}
                  />
                );
              })}

              {props.item.group.content.length === 0 &&
                props.mode === CompMode.COMPOSE && (
                  <H5 className={'empty-space'}>Add questions here</H5>
                )}
            </SectionDropColumn>
          </div>
        )}

        {props.mode !== CompMode.COMPOSE && resources.length > 0 && (
          <div className={`content-group dynamic-group`}>
            {resources.map((item, index) => {
              return (
                <SectionResourceDragItem
                  key={item.resourceId + '_' + index}
                  index={index}
                  item={item}
                  mode={props.mode}
                />
              );
            })}

            <SingleQuestionToolbarHandle
              sectionId={props.item.lessonSectionId}
              resources={props.item.group.content}
            />
          </div>
        )}
      </div>

      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.footer && (
          <div className={'lesson-section-footer-container'}>
            <ContentViewer contentData={props.item.content.footer} />
          </div>
        )}
    </InteractVideoSectionStyle>
  );
};

export const InteractVideoSectionStyle = styled.div`
  .lesson-section-header-container {
    margin-bottom: 0.5em;
  }

  &.interact {
    .lesson-section-container {
      flex-direction: column;
      gap: 1em;
      position: relative;

      .header-group {
        .section-dropbox {
          .empty-space {
            aspect-ratio: 16/ 9;
          }
        }
      }

      .content-group {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        padding: var(--cms-padding-question, 1.5em);
        max-height: calc(100% - 90px);
        background: rgba(255, 255, 255, 1);
        overflow-y: auto;

        &.empty-group {
          background: transparent;
        }
      }
    }
  }

  &.compose {
    .lesson-section-container {
      flex-direction: column;
      gap: 1em;

      .fixed-group {
        .section-dropbox {
          .empty-space {
            aspect-ratio: 16/ 9;
          }
        }
      }

      .header-group {
        order: 1;
      }

      .content-group {
        order: 2;
      }
    }
  }
`;

export const InteractVideoSectionData: InteractVideoSectionProps = {
  lessonSectionId: -1,
  code: COMPONENT_UTILS.generateUID(),
  type: SectionTypeEnum.interact_video,
  name: 'interact-video',

  setting: {
    timeline: [],
  },

  group: {
    header: [],
    content: [],
  },
};
