import React from 'react';
import styled from 'styled-components';

import { ClassRoomSection } from '@classroom/container/section/ClassRoomSection';

export const ClassRoomOverview = () => {
  return (
    <ClassRoomOverviewStyle className={'class-room-overview'}>
      <ClassRoomSection />
    </ClassRoomOverviewStyle>
  );
};

const ClassRoomOverviewStyle = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;
