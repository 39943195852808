import React from 'react';

import { useFibWordCompContext } from '@cms/comps/interact/inline/FibWordCompContext';
import { FibWordGroupItem } from '@cms/comps/interact/inline/FibWordGroupItem';
import styled from 'styled-components';

const FibWordGroupItems = () => {
  const { modifiedTime, targetItems } = useFibWordCompContext();

  return (
    <FibWordGroupItemsStyle className={`fib-word-group-items`}>
      {targetItems &&
        targetItems.map((drop, index) => {
          return (
            <FibWordGroupItem
              key={drop.label + '_' + drop.content.id + '_' + modifiedTime}
              drop={drop}
              index={index}
            />
          );
        })}
    </FibWordGroupItemsStyle>
  );
};

export default FibWordGroupItems;

const FibWordGroupItemsStyle = styled.div`
  .fib-word-group-item {
    &:not(:first-child) {
      margin-top: var(--cms-padding-option, 0.5em);
    }
  }
`;
