import { useTranslation } from 'react-i18next';
import { TeacherAssignmentAPI } from '@modules/assignments/service/TeacherAssignmentAPI';
import React, { useEffect, useState } from 'react';
import { PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { H3 } from '@components/typography';
import AssignmentActionTeacher from '@components/common/assignment/teacher/AssignmentTeacherActions';
import { AssignmentName } from '@components/common/assignment/teacher/AssignmentTableTeacher';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { AssignmentMobileItem } from './AssignmentMobileItem';
import { AssignmentInstanceStatusRes } from '@modules/assignments/service/assignment_model';

export const IncompleteAssignmentTable = () => {
  const { t } = useTranslation();
  const [uncompletedAssignments, { data, isFetching }] =
    TeacherAssignmentAPI.endpoints.uncompletedAssignments.useLazyQuery();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: 5,
    sort: '',
    direction: '',
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  const refreshData = () => {
    uncompletedAssignments(params);
  };

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('assignment.unlockAt'),
      dataIndex: 'unlockAt',
      key: 'unlockAt',
      sorter: true,
      width: 150,
      align: 'center',
    },
    {
      title: t('assignment.lockAt'),
      dataIndex: 'lockAt',
      key: 'lockAt',
      sorter: true,
      width: 150,
      align: 'center',
    },

    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 180,
      align: 'center',
    },
  ];

  const renderMobile = (item: AssignmentInstanceStatusRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <AssignmentMobileItem item={item} />
      </Card>
    );
  };

  const processDataRow = (item: AssignmentInstanceStatusRes) => {
    return {
      key: item.assignmentInstanceStatusId,
      assignmentGroup: <>{item.assignment?.assignmentGroup?.name}</>,
      name: <AssignmentName item={item} />,
      unlockAt: <DateAndTimeLabel label={item.assignmentInstance.unlockAt} />,
      lockAt: <DateAndTimeLabel label={item.assignmentInstance.lockAt} />,
      actions: <AssignmentActionTeacher item={item} />,
    };
  };

  return (
    <>
      <H3>{t('dashboard.actions.incomplete_assignment')}</H3>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={setParams}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </>
  );
};
