import { collectExerciseAnswer } from '@cms/lesson-template/components/toolbar/handler/collectExerciseAnswer';
import { notification } from 'antd';
import { useSelector } from '@app/redux/hook';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import { ExerciseServiceContext } from './ExerciseServiceContext';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { SaveSurveyAnswerReq, SurveyResponseRes } from '@services/model/survey';

export const SurveyServiceProvider = (props: {
  surveyId: number;
  children: ReactNode;
  event?: {
    obSubmit?: () => void;
    onGradingComplete?: () => void;
    onExit: () => void;
  };
}) => {
  const { t } = useTranslation();

  const [saveSurveyResponse, { isLoading }] =
    PublicPageAPI.endpoints.saveSurveyResponse.useMutation();

  const { firstName, lastName, email, phone } = useSelector(
    (state) => state.userInformation
  );

  const { currentAnswer } = useSelector((state) => state.viewResource);

  const onSave = (callBack: (res?: ExerciseRes) => void) => {
    callBack();
  };

  const handleSubmit = (res: SurveyResponseRes) => {
    console.log('handleSubmitSurveyServiceProvider', res);
  };

  const onSubmit = () => {
    if (props.surveyId != null && props.surveyId > 0) {
      const request: SaveSurveyAnswerReq = {
        surveyId: props.surveyId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        answers: collectExerciseAnswer(currentAnswer),
      };

      saveSurveyResponse(request)
        .unwrap()
        .then((res) => {
          notification.success({
            message: t('exercise.warning.submit_success'),
            description: t('exercise.warning.submit_success_message'),
            placement: 'bottomRight',
          });

          handleSubmit(res);
        })
        .catch(() => {
          notification.error({
            message: t('exercise.warning.submit_error'),
            description: t('exercise.warning.submit_error_message'),
            placement: 'bottomRight',
          });
        });
    } else {
      if (props.event && props.event.onExit) {
        props.event.onExit();
      }
    }
  };

  const onGrading = (callBack: (res: ExerciseRes) => void) => {
    console.log(callBack);
  };

  return (
    <ExerciseServiceContext.Provider
      value={{
        isLoading: isLoading,
        params: props.surveyId,

        exerciseId: -1,
        lessonId: -1,

        viewStatus: () => {},

        onSave: onSave,
        onSubmit: onSubmit,
        onGrading: onGrading,

        viewScores: () => {},
      }}
    >
      {props.children}
    </ExerciseServiceContext.Provider>
  );
};
