import styled from 'styled-components';
import React from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { AssetsRes } from '@modules/product/services/assets_model';
import AddStaticResource from '@modules/product/components/lesson/AddStaticResource';

import { CompMode } from '@cms/ComponentInteface';
import {
  ResourceContextProvider,
  ResourceCompose,
} from '@cms/context/ResourceContextProvider';
import { ResourceAnswerProps } from '@app/redux/slices/viewResource';
import { updateAnswers } from '@app/redux/slices/composeResource';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '@app/redux/hook';

const ResourceEditableSingle = (props: {
  item: ResourceProps;
  allowDragToUpload: boolean;
  handleOnUploadFiles: (file: AssetsRes) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { components, answers } = useSelector((state) => state.composeResource);

  const onChange = (newAns: ResourceAnswerProps) => {
    dispatch(updateAnswers(newAns.answers));
  };

  return (
    <EditResourceItemStyle className={'cms-edit-content-item'}>
      <div className={'cms-edit-content-content'}>
        <ResourceContextProvider
          mode={CompMode.COMPOSE}
          resourceId={props.item.resourceId}
          comps={components}
          answer={answers}
          onChange={onChange}
        >
          <ResourceCompose />
        </ResourceContextProvider>
      </div>

      {props.allowDragToUpload && (
        <AddStaticResource onUploadFile={props.handleOnUploadFiles}>
          <p className="ant-upload-text">
            {t('resource.actions.drag_n_drop_hint_1')}
          </p>
          <p className="ant-upload-text">
            {t('resource.actions.drag_n_drop_hint_2')}
          </p>
        </AddStaticResource>
      )}
    </EditResourceItemStyle>
  );
};

export default ResourceEditableSingle;

export const EditResourceItemStyle = styled.div`
  margin-bottom: 1.5em;
`;
