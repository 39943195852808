import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { Default_Gutter } from '@components/grid';

const LinkToStudent = (props: {
  type: 'page' | 'modal';
  onSubmit: (userName: string, password: string) => void;
}) => {
  const { t } = useTranslation();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleChangeUsername = (event: any) => {
    setUserName(event.target.value);
  };

  const handleChangePassword = (event: any) => {
    setPassword(event.target.value);
  };

  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <Input
            required
            name="username"
            type="username"
            label={t('label.username')}
            placeholder={t('label.username')}
            onChange={handleChangeUsername}
            value={userName}
          />
        </Col>

        <Col span={12}>
          <Input
            required
            name="password"
            type="password"
            label={t('label.password')}
            placeholder={t('form.enter_password')}
            onChange={handleChangePassword}
            value={password}
          />
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <ButtonGroup type={'right'}>
            <Button
              disabled={userName.trim() === '' || password.trim() === ''}
              type={'primary'}
              shape={'round'}
              size={props.type === 'page' ? 'large' : 'small'}
              onClick={() => props.onSubmit(userName, password)}
              icon={<UsergroupAddOutlined />}
            >
              {t('login.actions.link_to_students')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
};
export default LinkToStudent;
