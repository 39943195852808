import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';

export enum UserProfileStatusEnum {
  ACTIVE = 0,
  INACTIVE = 1,
  PENDING = 2,
  PUBLISHED = 3,
  REJECT = 4,
}

export interface UserProfileRes {
  userProfileId: number;

  avatarUrl: string;
  name: string;
  displayName: string;
  keyword: string;
  description: string;

  introduce: string;
  shortIntroduce: string;

  thumbnailsUrl: string;
  youtubeUrl: string;
  facebookAccount: string;
  googleAccount: string;

  createdDate: string;
  modifiedDate: string;
  publishedDate: string;

  status: UserProfileStatusEnum;
}

export interface UpdateStudentProfileReq {
  avatarUrl: string;
  name: string;
  displayName: string;
}

export interface UpdateTeacherProfileReq extends UpdateStudentProfileReq {
  keyword: string;
  description: string;
  introduce: string;
  shortIntroduce: string;
  thumbnailsUrl: string;

  youtubeUrl: string;
  facebookAccount: string;
  googleAccount: string;
}

export const UserProfileAPI = createApi({
  reducerPath: 'UserProfileAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfileRes, unknown>({
      query: () => ({
        url: `/user-profile`,
      }),
    }),

    updateMyProfile: builder.mutation<UserProfileRes, UpdateStudentProfileReq>({
      query: (body: UpdateStudentProfileReq) => ({
        url: `/user-profile`,
        method: 'PUT',
        body,
      }),
    }),

    publishProfile: builder.mutation<BasicMessageRes, unknown>({
      query: () => ({
        url: '/user-profile/publish',
        method: 'PUT',
      }),
    }),
  }),
});
