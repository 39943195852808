import React, { useEffect, useState } from 'react';
import { StandardRes } from '@modules/product/services/standard_model';
import { ProgressReportAPI } from '@services/reports/ProgressReport';
import styled from 'styled-components';
import { StandardItemSimple } from '@app/interact/StandardItem';

export const StandardDefaultScore = (props: {
  item: StandardRes;
  isSelected: boolean;
  onClick: (standardId: number) => void;
}) => {
  const [getStandardHistoryScore, { data, isSuccess }] =
    ProgressReportAPI.endpoints.getStandardHistoryScore.useLazyQuery();

  const [scoreHistory, setScoreHistory] = useState<string[]>([]);

  useEffect(() => {
    if (props.isSelected) {
      getStandardHistoryScore(props.item.standardId);
    }
  }, [props.item, props.isSelected]);

  useEffect(() => {
    if (data && isSuccess) {
      const scores = data.map((sc) => {
        return `${parseDateStr(sc.createdDate)}: <b>${sc.percent}</b>%`;
      });
      setScoreHistory(scores);
    }
  }, [data, isSuccess]);

  return (
    <StandardDefaultScoreStyle
      className={`standard-item-data ${props.isSelected ? 'selected' : ''}`}
      key={props.item.standardId}
      onClick={() => props.onClick(props.item.standardId)}
    >
      <StandardItemSimple item={props.item} />

      {scoreHistory.length > 0 && (
        <div className={'score-history-detail'}>
          {scoreHistory.map((sc) => {
            return (
              <span
                key={sc}
                className={'score-history'}
                dangerouslySetInnerHTML={{ __html: sc }}
              />
            );
          })}
        </div>
      )}
    </StandardDefaultScoreStyle>
  );
};

const StandardDefaultScoreStyle = styled.div`
  display: flex;
  align-items: baseline;

  .score-history-detail {
    .score-history {
      display: inline-block;
      margin-left: 0.25em;
      border: 1px solid #000;
      padding: 0.125em 0.5em;
    }
  }
`;

const parseDateStr = (dateStr: string) => {
  const date = dateStr.split('T')[0];
  const datePart = date.split('-');
  return `${datePart[2]}`;
};
