import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { Select } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import Checkbox from '@components/input/Checkbox';
import { useTranslation } from 'react-i18next';
import { AudioCompSettingProps } from '@cms/comps/content/audio/AudioComp';
import Input from '@components/input/input';

export const AudioCompSetting = (props: {
  setting: AudioCompSettingProps;
  onSettingChange: (newSetting: AudioCompSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.label.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('component.label.select_layout')}
            onChange={(value) => formik.setFieldValue('layout', value)}
            value={formik.values.layout}
          >
            <Select.Option key={''} value={''}>
              {t('component.audio.default')}
            </Select.Option>

            <Select.Option key={'subtitle'} value={'subtitle'}>
              {t('component.audio.subtitle')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Checkbox
          name={'showSubTitle'}
          checked={
            formik.values.showSubTitle || formik.values.layout === 'subtitle'
          }
          value={formik.values.showSubTitle}
          formik={formik}
          onChange={formik.handleChange}
          disabled={formik.values.layout === 'subtitle'}
        >
          {t('component.audio.show_subtitle')}
        </Checkbox>
      </FormikProvider>
    </SettingStyle>
  );
};
