import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { WordDescriptionBank } from '@cms/comps/game/common/WordDescriptionBank';
import { useTranslation } from 'react-i18next';
import { useWordPuzzleContext } from '@cms/comps/game/word-puzzle/WordPuzzleCompProvider';
import { H4 } from '@components/typography';
import styled from 'styled-components';

const WordPuzzleDescription = (props: {
  across: GlossaryGameContent[];
  down: GlossaryGameContent[];
}) => {
  const { t } = useTranslation();

  const { selectedWord, setSelectedWords, correctWordIds } =
    useWordPuzzleContext();

  const handleOnClick = (selectWord: string) => {
    setSelectedWords(selectWord);
  };

  return (
    <WordPuzzleDescriptionStyle className={'word-puzzle-description-group'}>
      {props.across.length > 0 && (
        <div className={'word-puzzle-group'}>
          <H4>{t('component.word_puzzle.across')}</H4>

          <WordDescriptionBank
            questionNumber={1}
            items={props.across}
            correctAnswers={correctWordIds}
            selectedWord={selectedWord}
            onClick={handleOnClick}
          />
        </div>
      )}

      {props.down.length > 0 && (
        <div className={'word-puzzle-group'}>
          <H4>{t('component.word_puzzle.down')}</H4>

          <WordDescriptionBank
            questionNumber={1}
            items={props.down}
            correctAnswers={correctWordIds}
            selectedWord={selectedWord}
            onClick={handleOnClick}
          />
        </div>
      )}
    </WordPuzzleDescriptionStyle>
  );
};

export default WordPuzzleDescription;

const WordPuzzleDescriptionStyle = styled.div`
  .word-puzzle-group {
    &:not(:first-child) {
      margin-top: var(--cms-padding-question, 1.5em);
    }
  }
`;
