import TablePagination from '@components/table/TablePagination';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { LessonTypeInfo } from '@components/common/lesson/lesson-type/LessonType';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { DateLabel } from '@components/text/DateLabel';
import { LessonActionTeacher } from './actions/LessonActionTeacher';
import { useTranslation } from 'react-i18next';
import { LessonFilterForm } from '@components/common/lesson/LessonFilter';
import Card, { CardClassEnum } from '@components/card';
import { RoleEnum } from '@app/redux/slices/roles';
import Authorization from '@utils/authorization';
import { LessonActionAdmin } from '@components/common/lesson/actions/LessonActionAdmin';
import { ComposingStatus, ComposingWithStatus } from './ComposingStatus';
import { EntityStatusEnum } from '@services/model/common';
import { ResponsiveScreen } from '@hooks/useMedia';
import { H5 } from '@components/typography';
import styled from 'styled-components';
import { InputGroup } from '@components/input/InputGroup';
import { LessonTypeEnum } from '@modules/admin/service/model';
import {
  FilterLessonReq,
  LessonRes,
} from '@modules/product/services/lesson_model';

export const LessonManagementTable = (props: { types: LessonTypeEnum[] }) => {
  const { t } = useTranslation();

  const [params, setParams] = useState<FilterLessonReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
    productId: -1,
    keyword: '',
    types: props.types,
    status: EntityStatusEnum.ACTIVE,
  });

  const [getMyLessons, { data, isFetching, isLoading }] =
    LessonAPI.endpoints.getMyLessons.useLazyQuery({});

  const columns = [
    {
      title: t('label.thumbnails'),
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      width: 90,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('lesson_type.header'),
      dataIndex: 'lessonType',
      key: 'lessonType',
      sorter: true,
      align: 'center',
      width: 200,
    },
    // {
    //   title: t('lesson_template.header'),
    //   dataIndex: 'lessonTemplate',
    //   key: 'lessonTemplate',
    //   sorter: true,
    //   align: 'center',
    // },
    {
      title: t('label.created_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 180,
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      sorter: false,
      align: 'center',
      width: 120,
    },
  ];

  useEffect(() => {
    refreshPage();
  }, [params]);

  const refreshPage = useCallback(() => {
    getMyLessons(params);
  }, [params]);

  const renderMobile = (item: LessonRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <LessonMobileStyle className={'lesson-info'}>
          <div className={'lesson-thumbnails'}>
            <ThumbnailsImg
              type={'icon'}
              src={item.thumbnails}
              ratio={ImageScale.lesson.ratio}
              width={90}
            />
          </div>

          <div className={'lesson-info'}>
            <H5>
              <ComposingWithStatus status={item.status}>
                {item.name}
              </ComposingWithStatus>
            </H5>

            <InputGroup label={t('label.created_date')}>
              <DateLabel label={item.createdDate} />
            </InputGroup>
          </div>

          <div className={'lesson-actions'}>
            <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
              <LessonActionAdmin
                type={'box'}
                item={item}
                onChange={refreshPage}
              />
            </Authorization>

            <Authorization type={'ifNotGranted'} roles={[RoleEnum.ADMIN]}>
              <LessonActionTeacher
                type={'box'}
                item={item}
                onChange={refreshPage}
              />
            </Authorization>
          </div>
        </LessonMobileStyle>
      </Card>
    );
  };

  const processDataRow = (item: LessonRes) => {
    return {
      key: item.lessonId,
      thumbnails: (
        <ThumbnailsImg
          type={'icon'}
          src={item.thumbnails}
          ratio={ImageScale.lesson.ratio}
          width={90}
        />
      ),
      name: (
        <ComposingWithStatus status={item.status}>
          {item.name}
        </ComposingWithStatus>
      ),
      lessonType: <LessonTypeInfo showLabel={true} type={item.type} />,
      createdDate: <DateLabel label={item.createdDate} />,
      status: <ComposingStatus status={item.status} />,
      actions: (
        <>
          <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
            <LessonActionAdmin
              type={'table'}
              item={item}
              onChange={refreshPage}
            />
          </Authorization>

          <Authorization type={'ifNotGranted'} roles={[RoleEnum.ADMIN]}>
            <LessonActionTeacher
              type={'table'}
              item={item}
              onChange={refreshPage}
            />
          </Authorization>
        </>
      ),
    };
  };

  const handleOnSubmit = (
    productId: number,
    keyword: string,
    status: EntityStatusEnum
  ) => {
    setParams((prev) => {
      return {
        ...prev,
        productId: productId,
        keyword: keyword,
        status: status,
        page: 1,
      };
    });
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return {
        ...prev,
        ...params,
      };
    });
  };

  return (
    <>
      <Card className={CardClassEnum.rectangle_box}>
        <LessonFilterForm
          productId={params.productId}
          keyword={params.keyword}
          status={params.status!}
          onSubmit={handleOnSubmit}
        />
      </Card>

      <TablePagination
        params={params}
        isLoading={isFetching || isLoading}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </>
  );
};

const LessonMobileStyle = styled.div`
  position: relative;
  display: flex;
  gap: 1em;

  .lesson-thumbnails {
    min-width: 90px;
  }

  .lesson-info {
    flex-grow: 1;
    overflow: hidden;
  }

  .lesson-actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;
