import { useExerciseView } from '@cms/lesson-template/hook/useExerciseView';
import { ViewLessonContentProvider } from '@cms/lesson-template/context/ViewLessonContext';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import React, { useEffect, useState } from 'react';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { ExerciseServiceProvider } from '@cms/service/ExerciseServiceProvider';
import { Loading } from '@components/loading/Loading';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import Card, { CardClassEnum } from '@components/card';
import { H3 } from '@components/typography';
import { ViewExerciseProvider } from '@cms/lesson-template/context/ViewExerciseContext';
import { AssignmentAPI } from '@modules/assignments/service/AssignmentAPI';
import { AssignmentExpiredType } from '@modules/assignments/service/assignment_model';

export const DoAssignmentPage = (props: { assignmentInstanceId: number }) => {
  const [readyForStart, setReadyForStart] = useState(false);

  const [viewAssignmentInstance, { data, isSuccess, isFetching }] =
    AssignmentAPI.endpoints.viewAssignmentInstance.useLazyQuery({});

  useEffect(() => {
    viewAssignmentInstance(props.assignmentInstanceId);
  }, [props.assignmentInstanceId]);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.expiredType === AssignmentExpiredType.date_n_time) {
        setReadyForStart(true);
      } else if (
        data.expiredType === AssignmentExpiredType.duration &&
        data.unlockInMs != null
      ) {
        setReadyForStart(true);
      } else {
        setReadyForStart(false);
      }
    }
  }, [data]);

  const handleStartAssignment = () => {
    setReadyForStart(true);
  };

  return (
    <Loading loading={isFetching}>
      {data && (
        <>
          {readyForStart ? (
            <ViewExerciseAssignment exerciseId={data.exerciseId} />
          ) : (
            <WarningAssignmentDurationStyle className={'warning-expired-date'}>
              <div className={'warning-expired-content'}>
                <Card className={CardClassEnum.rectangle_box}>
                  <H3>{data.assignmentInstance!.name}</H3>

                  <div
                    className={'assignment-direction'}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        data.assignmentInstance!.direction
                      ),
                    }}
                  />

                  <div className={'assignment-duration-warning'}>
                    Đây là bài kiểm tra có tính giờ. Tổng thời gian để bạn làm
                    bài là {data.duration} phút kể từ lúc bắt đầu làm bài. Vui
                    lòng chuẩn bị mọi thứ sẵn sàng trước khi bắt đầu.
                  </div>

                  <ButtonGroup className={'submit-container'} type={'center'}>
                    <Button
                      type={'primary'}
                      shape={'round'}
                      size={'large'}
                      onClick={handleStartAssignment}
                    >
                      Start Assignment
                    </Button>
                  </ButtonGroup>
                </Card>
              </div>
            </WarningAssignmentDurationStyle>
          )}
        </>
      )}
    </Loading>
  );
};

const WarningAssignmentDurationStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .warning-expired-content {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const ViewExerciseAssignment = (props: { exerciseId: number }) => {
  const router = useRouter();

  const exitExercise = () => {
    router.push(SiteMap.private.my_assignment.list);
  };

  const { lesson, exercise, answers, feedBacks, gradings } = useExerciseView(
    props.exerciseId,
    CompMode.DO_ASSIGNMENT
  );

  return (
    <>
      {lesson && exercise && (
        <ViewExerciseProvider exercise={exercise} exitExercise={exitExercise}>
          <ViewLessonContentProvider
            type={CompMode.DO_ASSIGNMENT}
            exercise={exercise}
            lesson={lesson}
            lessonType={lesson.type ?? LessonTypeEnum.lesson}
            answers={answers}
            feedBacks={feedBacks}
            gradings={gradings}
            exitExercise={exitExercise}
          >
            <ExerciseServiceProvider
              lessonId={lesson.lessonId}
              exerciseId={props.exerciseId}
            >
              <LessonTemplateLayoutProvider lesson={lesson}>
                <ViewLessonContent />
              </LessonTemplateLayoutProvider>
            </ExerciseServiceProvider>
          </ViewLessonContentProvider>
        </ViewExerciseProvider>
      )}
    </>
  );
};
