import React, { useState } from 'react';
import { Button, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { TeacherAssignmentTable } from '@components/common/assignment/teacher/AssignmentTableTeacher';
import CustomModal from '@components/modal/CustomModal';
import { EditAssignmentGroupForm } from '@modules/assignments/container/assignment-group/EditAssignmentGroup';
import {
  AssignmentGroupActionEnum,
  AssignmentGroupTable,
} from '../container/assignment-group/AssignmentGroup';
import { IconUtils } from '@utils/IconUtils';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export enum MyTeacherAssignmentEnum {
  assignment = 'assignment',
  assignment_group = 'assignment-group',
}

export enum MyTeacherActionEnum {
  default = '',
  create_assignment_group = 'create-assignment-group',
  refresh_assignment_group = 'refresh-assignment-group',
}

const MyTeacherAssignmentPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const [action, setAction] = useState(MyTeacherActionEnum.default);

  const [activeTab, setActiveTab] = useState<MyTeacherAssignmentEnum>(
    MyTeacherAssignmentEnum.assignment
  );

  const handleOnChange = (value: MyTeacherAssignmentEnum) => {
    setActiveTab(value);
  };

  const createAssignment = () => {
    router.push(SiteMap.assignments.create_gen(-1, -1, -1));
  };

  const handleOnSuccess = () => {
    setAction(MyTeacherActionEnum.refresh_assignment_group);
  };

  const handleOnCancel = () => {
    setAction(MyTeacherActionEnum.default);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {activeTab === MyTeacherAssignmentEnum.assignment ? (
            <>
              {t('assignment.title')}
              <Button
                type={'primary'}
                shape="circle"
                icon={IconUtils.add_new}
                onClick={createAssignment}
              />
            </>
          ) : (
            <>
              {t('assignment.group')}
              <Button
                type={'primary'}
                shape="circle"
                icon={IconUtils.add_new}
                onClick={() =>
                  setAction(MyTeacherActionEnum.create_assignment_group)
                }
              />
            </>
          )}
        </H1>

        <SwitchAssignmentTab activeTab={activeTab} onChange={handleOnChange} />
      </HeaderGroup>

      {activeTab === MyTeacherAssignmentEnum.assignment && (
        <TeacherAssignmentTable />
      )}

      {activeTab === MyTeacherAssignmentEnum.assignment_group && (
        <AssignmentGroupTable
          action={
            action === MyTeacherActionEnum.refresh_assignment_group
              ? AssignmentGroupActionEnum.refresh
              : AssignmentGroupActionEnum.default
          }
        />
      )}

      {action === MyTeacherActionEnum.create_assignment_group && (
        <CustomModal
          header={t('assignment_group.actions.add')}
          content={
            <EditAssignmentGroupForm
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

export default MyTeacherAssignmentPage;

export const SwitchAssignmentTab = (props: {
  activeTab: MyTeacherAssignmentEnum;
  onChange: (tab: MyTeacherAssignmentEnum) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Segmented
      block
      size={'large'}
      value={props.activeTab}
      onChange={(val) => props.onChange(val as MyTeacherAssignmentEnum)}
      options={[
        {
          value: MyTeacherAssignmentEnum.assignment,
          label: t('assignment.title'),
        },
        {
          value: MyTeacherAssignmentEnum.assignment_group,
          label: t('assignment.group'),
        },
      ]}
      style={{ minWidth: 415 }}
    />
  );
};
