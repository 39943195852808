import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import LoginForm from '@modules/authorization/container/LoginForm';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { PublishSchoolAPI } from '@services/publish/PublishSchool';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const LoginPage = (props: { clientId: string }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [viewSchool, { data, isSuccess }] =
    PublishSchoolAPI.endpoints.viewSchool.useLazyQuery();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.clientId) {
      viewSchool(props.clientId);
    }
  }, [props.clientId]);

  useEffect(() => {
    if (data && isSuccess) {
      setReady(true);
    }
  }, [data]);

  const handleLoginSuccess = () => {
    router.push(SiteMap.private.dashboard);
  };

  return (
    <>
      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            <H1>{t('login.header')}</H1>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <p>{t('login.description')}</p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                {ready && (
                  <LoginForm
                    clientId={props.clientId}
                    successHandle={handleLoginSuccess}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </AuthorizeStyle>
    </>
  );
};

export default LoginPage;
