import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { PublishProductPriceContext } from '@modules/product/context/PublishProductPriceContext';
import { LessonPriceProps } from '@services/publish/PublishProductAPI';
import NavLink from '@components/button/NavLink';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { LessonInfoReviewStyle } from '@modules/public/components/lessons/style';
import { SiteMap } from '@router/SiteMap';
import { LessonRes } from '@modules/product/services/lesson_model';

const LessonStyle = styled.div`
  cursor: pointer;
  font-size: 16px;

  &.selected {
    font-weight: bold;
  }
`;

export const LessonInfo = (props: {
  className?: string;
  item: LessonRes;
  onClickHandle: (lessonId: number, lessonTitle: string) => void;
}) => {
  return (
    <LessonStyle
      className={'lesson-info ' + props.className ? props.className : ''}
      onClick={() => props.onClickHandle(props.item.lessonId, props.item.name)}
    >
      <Space align={'center'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.thumbnails}
          ratio={ImageScale.product.ratio}
          width={ImageScale.product.icon}
        />
        <span>{props.item.name}</span>
      </Space>
    </LessonStyle>
  );
};

export const ViewLessonInfo = (props: {
  className?: string;
  item: LessonRes;
  productId?: number;
}) => {
  return (
    <NavLink
      href={SiteMap.private.my_library.view_lesson_gen(
        props.item.lessonId,
        props.productId
      )}
    >
      <LessonInfoReviewStyle
        className={'lesson-info ' + (props.className ? props.className : '')}
      >
        {props.item.name}
      </LessonInfoReviewStyle>
    </NavLink>
  );
};

export const ViewLessonProductInfo = (props: {
  className?: string;
  item: LessonRes;
  onClick: () => void;
}) => {
  return (
    <LessonInfoReviewStyle
      className={'lesson-info ' + (props.className ? props.className : '')}
      onClick={props.onClick}
    >
      {props.item.name}
    </LessonInfoReviewStyle>
  );
};

const LessonInfoPriceStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .lesson-name {
    flex-grow: 1;
    font-weight: bold;
  }

  .lesson-price {
    max-width: 300px;
  }
`;

const PriceTagStyle = styled.div`
  &.free-lesson {
    .ant-input {
      font-weight: bold;
    }
  }

  .ant-input {
    text-align: center;
  }

  .price-tag {
    background: ${(props) => props.theme.app.primary};
    color: ${(props) => props.theme.color.white};
    display: inline-block;
    text-align: center;
    padding: 0.125em 1em;
    border-radius: 1em;
    font-size: 12px;
  }

  .free-tag {
    background: ${(props) => props.theme.app.secondary};
    color: ${(props) => props.theme.app.secondary_text};
    display: inline-block;
    text-align: center;
    padding: 0.125em 1em;
    border-radius: 1em;
    font-size: 12px;
  }
`;

export const PriceTag = (props: {
  className: string;
  price: number;
  onChange: (price: number) => void;
}) => {
  const { t } = useTranslation();

  const [price, setPrice] = useState(() => {
    return { value: props.price, triggerChange: false };
  });

  useEffect(() => {
    if (price.triggerChange) {
      props.onChange(price.value);
    }
  }, [price]);

  const handleInputChange = (event: any) => {
    const value = +event.target.value;
    setPrice({
      value: value,
      triggerChange: true,
    });
  };

  return (
    <PriceTagStyle
      className={
        props.className +
        (price.value === 0 ? ' free-lesson' : ' unfree-lesson')
      }
    >
      <Input
        size="large"
        min={0}
        defaultValue={price.value}
        type={'number'}
        suffix={
          price.value === 0 ? (
            <span className={'free-tag'}>{t('label.free')}</span>
          ) : (
            <span className={'price-tag'}>{t('label.g_points')}</span>
          )
        }
        onChange={handleInputChange}
      />
    </PriceTagStyle>
  );
};

export const LessonInfoPrice = (props: { item: LessonRes }) => {
  const [price, setPrice] = useState(() => {
    return props.item.price;
  });

  const { setLessonPrices } = useContext(PublishProductPriceContext);

  useEffect(() => {
    setLessonPrices((prev: LessonPriceProps[]) => {
      const current: LessonPriceProps[] = [...prev].filter((item) => {
        return item.lessonId !== props.item.lessonId;
      });
      current.push({ lessonId: props.item.lessonId, price: price });
      return current;
    });
  }, [price]);

  const handleInputChange = (price: number) => {
    setPrice(price);
  };

  return (
    <LessonInfoPriceStyle className={'lesson-info '}>
      <div className={'lesson-name'}>{props.item.name}</div>

      <PriceTag
        className={'lesson-price'}
        price={price}
        onChange={handleInputChange}
      />
    </LessonInfoPriceStyle>
  );
};

export const PriceTagDisplay = (props: {
  free: boolean;
  price: number;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  return props.free || props.price === 0 ? (
    <PriceTagDisplayStyle className={'free-tag'}>
      {t('label.free')}
    </PriceTagDisplayStyle>
  ) : (
    <PriceTagDisplayStyle className={'price-tag'} onClick={props.onClick}>
      {props.price}
      {t('label.gp')}
    </PriceTagDisplayStyle>
  );
};

const PriceTagDisplayStyle = styled.span`
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  padding: 0.125em 1em;
  border-radius: 1em;
  font-size: 12px;

  &.selected {
    &.price-tag {
      background: ${(props) => props.theme.app.primary};
      color: ${(props) => props.theme.color.white};
    }
  }

  &.price-tag {
    background: ${(props) => props.theme.app.primary_bgr};
    color: ${(props) => props.theme.color.black};
    cursor: pointer;
  }

  &.free-tag {
    background: #212529;
    color: ${(props) => props.theme.color.white};
  }
`;
