import React, { ReactNode } from 'react';
import {
  getLessonConfig,
  getLessonSetting,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import styled from 'styled-components';
import { getTemplateStyle } from '@cms/design-template/layout/LessonTemplateLayoutEditable';
import {
  LessonTemplateConfigProps,
  LessonTemplateSettingProps,
} from '@cms/design-template/layout/props';
import { LessonRes } from '@modules/product/services/lesson_model';

export const PageLayout = (props: { page: LessonRes; children: ReactNode }) => {
  return (
    <PageTemplateLayout
      config={getLessonConfig(props.page)}
      title={props.page.name}
      setting={getLessonSetting(props.page, 'page')}
    >
      {props.children}
    </PageTemplateLayout>
  );
};

export const PageTemplateLayout = (props: {
  title: string;
  config: LessonTemplateConfigProps;
  setting: LessonTemplateSettingProps;
  children: any;
}) => {
  return (
    <PageTemplateStyle
      className={`cms-page-layout ${props.config?.layout ?? 'empty'}-layout`}
    >
      <header
        dangerouslySetInnerHTML={{
          __html: `
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <link href="public/bootstrap-5.2.3/css/bootstrap.min.css" rel="stylesheet">
          <script src="public/bootstrap-5.2.3/js/bootstrap.min.js"></script>
        `,
        }}
      />

      {getTemplateStyle('cms-page-layout', props.setting)}

      {props.setting.stylesheet && (
        <div
          className={'page-stylesheet'}
          dangerouslySetInnerHTML={{ __html: props.setting.stylesheet }}
        />
      )}

      {props.setting.header && (
        <div
          className={'page-header'}
          dangerouslySetInnerHTML={{ __html: props.setting.header }}
        />
      )}

      <div className={'page-content'}>{props.children}</div>

      {props.setting.footer && (
        <div
          className={'page-footer'}
          dangerouslySetInnerHTML={{ __html: props.setting.footer }}
        />
      )}

      {props.setting.script && (
        <div
          className={'page-script'}
          dangerouslySetInnerHTML={{ __html: props.setting.script }}
        />
      )}
    </PageTemplateStyle>
  );
};

const PageTemplateStyle = styled.div``;
