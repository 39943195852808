import React from 'react';

import {
  CompAnswerProps,
  CompMode,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { StaticContentComp } from '@cms/comps/content/StaticContentComp';
import { ImageComp } from '@cms/comps/content/ImageComp';
import { AudioComp } from '@cms/comps/content/audio/AudioComp';
import { VideoComp } from '@cms/comps/content/VideoComp';
import { PdfComp } from '@cms/comps/content/PdfComp';
import { DocComp } from '@cms/comps/content/DocComp';
import { EpubComp } from '@cms/comps/content/EpubComp';
import {
  DragNDropAnsProps,
  DragNDropComp,
  DragNDropCompProps,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import {
  DropDownAnsProps,
  DropdownComp,
  DropDownProps,
} from '@cms/comps/interact/dropdown/DropdownComp';
import {
  MatchingComp,
  MatchingCompAnsProps,
  MatchingCompProps,
} from '@cms/comps/interact/matching/MatchingComp';
import {
  MultipleChoiceAnsProps,
  MultipleChoiceComp,
  MultipleChoiceCompProps,
} from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';
import { FlashCardMatchingComp } from '@cms/comps/game/matching/FlashCardMatchingComp';
import { WordPuzzleComp } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import { WordScrambleComp } from '@cms/comps/game/word-scramble/WordScrambleComp';
import { WordSearchComp } from '@cms/comps/game/word-search/WordSearchComp';

import {
  FibNumberAnsProps,
  FibNumberComp,
  FibNumberProps,
} from '@cms/comps/math/fib/FibNumberComp';
import {
  FibWordAnsProps,
  FibWordComp,
  FibWordProps,
} from '@cms/comps/interact/inline/FibWordComp';
import {
  FibParagraphAnsProps,
  FibParagraphComp,
  FibParagraphProps,
} from '@cms/comps/interact/fill-in-blank/FibParagraph';
import {
  FibEssayAnsProps,
  FibEssayComp,
  FibEssayProps,
} from '@cms/comps/interact/fill-in-blank/FibEssayComp';
import { WordBoxComp } from '@cms/comps/game/word-box/WordBoxComp';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import {
  OrderingAnsProps,
  OrderingComp,
  OrderingProps,
} from '@cms/comps/interact/ordering/OrderingComp';
import {
  LongAdditionAnsProps,
  LongAdditionComp,
  LongAdditionCompProps,
} from '../comps/math/operation/addition/LongAdditionComp';
import {
  LongSubtractionAnsProps,
  LongSubtractionComp,
  LongSubtractionCompProps,
} from '@cms/comps/math/operation/subtraction/LongSubtractionComp';
import {
  LongMultiplicationAnsProps,
  LongMultiplicationComp,
  LongMultiplicationCompProps,
} from '@cms/comps/math/operation/multiplication/LongMultiplicationComp';
import {
  LongDivisionAnsProps,
  LongDivisionComp,
  LongDivisionCompProps,
} from '@cms/comps/math/operation/division/LongDivisionComp';
import {
  FractionComp,
  FractionCompAnsProps,
  FractionCompProps,
} from '@cms/comps/math/frac/FractionComp';
import {
  MathEquationAnsProps,
  MathEquationComp,
  MathEquationProps,
} from '@cms/comps/math/equation/MathEquationComp';
import {
  CoordinateAnsProps,
  CoordinateComp,
  CoordinateProps,
} from '@cms/comps/interact/coordinate/CoordinateComp';
import {
  DragNDropInlineAnsProps,
  DragNDropInlineComp,
  DragNDropInlineCompProps,
} from '../comps/interact/drag-n-drop/inline/DragNDropInlineComp';
import {
  AreaMultiplicationAnsProps,
  AreaMultiplicationComp,
  AreaMultiplicationCompProps,
} from '../comps/math/operation/area-multiplication/AreaMultiplicationComp';
import {
  AreaDivisionAnsProps,
  AreaDivisionComp,
  AreaDivisionCompProps,
} from '../comps/math/operation/area-division/AreaDivisionComp';
import {
  PlaceValueAnsProps,
  PlaceValueComp,
  PlaceValueCompProps,
} from '@cms/comps/math/operation/place-value/PlaceValueComp';
import AudioRecordingComp, {
  AudioRecordingAnswerProps,
  AudioRecordingProps,
} from '@cms/comps/recording/AudioRecordingComp';
import {
  CheckBoxAnsProps,
  CheckBoxComp,
  CheckBoxCompProps,
} from '@cms/comps/interact/checkbox/CheckBoxComp';
import { TemplateComp } from '@cms/comps/template/TemplateComp';
import { DynamicContentComp } from '@cms/comps/content/dynamic/DynamicContentComp';
import {
  FlashCardComp,
  FlashCardCompProps,
} from '@cms/comps/game/flashcard/FlashCardComp';
import { SocialVideoComps } from '@cms/comps/presentation/social-video/SocialVideoComp';
import {
  GlossaryComp,
  GlossaryCompProps,
} from '../comps/game/glossary/GlossaryComp';
import {
  CircleUnderlineAnsProps,
  CircleUnderlineComp,
  CircleUnderlineProps,
} from '@cms/comps/interact/circle-underline/CircleUnderlineComp';

export const ComponentItem = (props: {
  disabled?: boolean;
  item: CompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const { mode, disabledMode } = useResourceContext();

  const disabledInteract =
    props.disabled ||
    disabledMode ||
    mode === CompMode.REVIEW ||
    mode === CompMode.GRADING_EXERCISE ||
    mode === CompMode.GRADING_ASSIGNMENT ||
    mode === CompMode.REVIEW_EXERCISE ||
    mode === CompMode.REVIEW_ASSIGNMENT;

  switch (props.item.type) {
    // STATIC CONTENT
    case CompTypeEnum.STATIC_CONTENT:
      return (
        <StaticContentComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.DYNAMIC_CONTENT:
      return (
        <DynamicContentComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.TEMPLATE:
      return (
        <TemplateComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.IMAGE:
      return (
        <ImageComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.AUDIO:
      return (
        <AudioComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.VIDEO:
      return (
        <VideoComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.SOCIAL_VIDEO:
      return (
        <SocialVideoComps
          disabled={props.disabled}
          item={props.item as any}
          answer={props.answer}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.PDF:
      return (
        <PdfComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.WORD:
      return (
        <DocComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.EPUB:
      return (
        <EpubComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_WORD:
      return (
        <FibWordComp
          disabled={disabledInteract}
          item={props.item as FibWordProps}
          answer={props.answer as FibWordAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.CIRCLE_UNDERLINE:
      return (
        <CircleUnderlineComp
          disabled={disabledInteract}
          item={props.item as CircleUnderlineProps}
          answer={props.answer as CircleUnderlineAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_PARAGRAPH:
      return (
        <FibParagraphComp
          disabled={disabledInteract}
          item={props.item as FibParagraphProps}
          answer={props.answer as FibParagraphAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_ESSAY:
      return (
        <FibEssayComp
          disabled={disabledInteract}
          item={props.item as FibEssayProps}
          answer={props.answer as FibEssayAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.AUDIO_RECORDING:
      return (
        <AudioRecordingComp
          disabled={disabledInteract}
          item={props.item as AudioRecordingProps}
          answer={props.answer as AudioRecordingAnswerProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    // INTERACTIVE CONTENT

    case CompTypeEnum.DROPDOWN:
      return (
        <DropdownComp
          disabled={disabledInteract}
          item={props.item as DropDownProps}
          answer={props.answer as DropDownAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.ORDERING:
      return (
        <OrderingComp
          disabled={disabledInteract}
          item={props.item as OrderingProps}
          answer={props.answer as OrderingAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.DRAG_N_DROP:
      return (
        <DragNDropComp
          disabled={disabledInteract}
          item={props.item as DragNDropCompProps}
          answer={props.answer as DragNDropAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.DRAG_N_DROP_INLINE:
      return (
        <DragNDropInlineComp
          disabled={disabledInteract}
          item={props.item as DragNDropInlineCompProps}
          answer={props.answer as DragNDropInlineAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.MATCHING:
      return (
        <MatchingComp
          disabled={disabledInteract}
          item={props.item as MatchingCompProps}
          answer={props.answer as MatchingCompAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceComp
          disabled={disabledInteract}
          item={props.item as MultipleChoiceCompProps}
          answer={props.answer as MultipleChoiceAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.CHECK_BOX:
      return (
        <CheckBoxComp
          disabled={disabledInteract}
          item={props.item as CheckBoxCompProps}
          answer={props.answer as CheckBoxAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    // GAME
    case CompTypeEnum.GLOSSARY:
      return (
        <GlossaryComp
          item={props.item as GlossaryCompProps}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FLASH_CARD:
      return (
        <FlashCardComp
          item={props.item as FlashCardCompProps}
          answer={props.answer as any}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.GAME_MATCHING:
      return (
        <FlashCardMatchingComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.WORD_BOX:
      return (
        <WordBoxComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.WORD_SEARCH:
      return (
        <WordSearchComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.WORD_SCRAMBLE:
      return (
        <WordScrambleComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.WORD_PUZZLE:
      return (
        <WordPuzzleComp
          item={props.item as any}
          answer={props.answer}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_NUMBER:
      return (
        <FibNumberComp
          disabled={disabledInteract}
          item={props.item as FibNumberProps}
          answer={props.answer as FibNumberAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_FRACTION:
      return (
        <FractionComp
          disabled={disabledInteract}
          item={props.item as FractionCompProps}
          answer={props.answer as FractionCompAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_EQUATION:
      return (
        <MathEquationComp
          disabled={disabledInteract}
          item={props.item as MathEquationProps}
          answer={props.answer as MathEquationAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.LONG_ADDITION:
      return (
        <LongAdditionComp
          disabled={disabledInteract}
          item={props.item as LongAdditionCompProps}
          answer={props.answer as LongAdditionAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.LONG_SUBTRACTION:
      return (
        <LongSubtractionComp
          disabled={disabledInteract}
          item={props.item as LongSubtractionCompProps}
          answer={props.answer as LongSubtractionAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );
    case CompTypeEnum.LONG_MULTIPLICATION:
      return (
        <LongMultiplicationComp
          disabled={disabledInteract}
          item={props.item as LongMultiplicationCompProps}
          answer={props.answer as LongMultiplicationAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.AREA_MULTIPLICATION:
      return (
        <AreaMultiplicationComp
          disabled={disabledInteract}
          item={props.item as AreaMultiplicationCompProps}
          answer={props.answer as AreaMultiplicationAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.AREA_DIVISION:
      return (
        <AreaDivisionComp
          disabled={disabledInteract}
          item={props.item as AreaDivisionCompProps}
          answer={props.answer as AreaDivisionAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.LONG_DIVISION:
      return (
        <LongDivisionComp
          disabled={disabledInteract}
          item={props.item as LongDivisionCompProps}
          answer={props.answer as LongDivisionAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.PLACE_VALUE:
      return (
        <PlaceValueComp
          disabled={disabledInteract}
          item={props.item as PlaceValueCompProps}
          answer={props.answer as PlaceValueAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    case CompTypeEnum.COORDINATE:
      return (
        <CoordinateComp
          disabled={disabledInteract}
          item={props.item as CoordinateProps}
          answer={props.answer as CoordinateAnsProps}
          feedback={props.feedback}
          onChange={props.onChange}
        />
      );

    default:
      return null;
  }
};
