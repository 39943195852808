import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import ComposeLessonLayout from '@components/template/compose-lesson-layout/ComposeLessonLayout';
import ComposeCollectionPage from '@modules/product/container/collection/ComposeCollectionPage';
import { PageHeader } from '@app/header/PageHeader';

const ComposeCollection = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: lessonId } = router.query as unknown as { id: string };

  return (
    <ComposeLessonLayout className={'compose-collection-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_LESSON}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('lesson.actions.compose')}
        description={t('lesson.actions.compose')}
      />
      <ComposeCollectionPage lessonId={Number(lessonId)} from={'simple'} />
    </ComposeLessonLayout>
  );
};

export default ComposeCollection;
