import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateStandardReq,
  StandardRes,
  UpdateStandardResourceReq,
} from '@modules/product/services/standard_model';

export const StandardAPI = createApi({
  reducerPath: 'StandardAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<StandardRes>, unknown>({
      query: () => ({
        url: `/standards`,
      }),
    }),

    findById: builder.query<StandardRes, number>({
      query: (standardId) => ({
        url: `/standards/${standardId}`,
      }),
    }),

    create: builder.mutation<StandardRes, CreateStandardReq>({
      query: (body) => ({
        url: '/standards',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<StandardRes, CreateStandardReq>({
      query: (body) => ({
        url: `/standards/${body.standardId}`,
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (standardId) => ({
        url: `/standards/${standardId}`,
        method: 'DELETE',
      }),
    }),

    getStandardResource: builder.query<StandardRes[], number>({
      query: (resourceId) => ({
        url: `/standards/resource/${resourceId}`,
      }),
    }),

    updateStandardResource: builder.mutation<
      BasicMessageRes,
      UpdateStandardResourceReq
    >({
      query: (body) => ({
        url: `/standards/resource/${body.resourceId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
