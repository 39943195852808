import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import EmailTemplateSettingPage from '@modules/setting/pages/EmailTemplateSettingPage';
import { RoleEnum } from '@app/redux/slices/roles';
import Authorization from '@utils/authorization';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const EmailTemplate = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'email-template-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_EMAIL_TEMPLATE}
        title={t('setting.email_template.header')}
        description={t('setting.email_template.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        <EmailTemplateSettingPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default EmailTemplate;
