import React, { ReactNode, useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { AssignmentGroupAPI } from '@modules/assignments/service/AssignmentGroupAPI';
import { useTranslation } from 'react-i18next';
import { AssignmentGroupRes } from '@modules/assignments/service/assignment_model';

interface SelectAssignmentGroupProps extends SelectProps {
  formik: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  name: string;
  value?: number;
  onChange?: (value: number) => void;
}

const SelectAssignmentGroup = (props: SelectAssignmentGroupProps) => {
  const { t } = useTranslation();

  const [findAll, { data, isSuccess, isFetching }] =
    AssignmentGroupAPI.endpoints.findAll.useLazyQuery();

  const [options, setOptions] = useState<AssignmentGroupRes[]>([]);

  const handleOnInputChange = (value: number) => {
    if (props.onChange) {
      props.onChange(value);
    } else {
      props.formik.setFieldValue(props.name, value);
    }
  };

  useEffect(() => {
    findAll({
      page: 1,
      size: 20,
      sort: 'name',
      direction: 'descend',
      selectAll: true,
    });
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setOptions(data.content);
    }
  }, [data]);

  return (
    <InputGroup
      label={props.label ? props.label : t('assignment_group.header')}
    >
      <Select
        style={{ width: '100%' }}
        size={'large'}
        placeholder={props.placeholder}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value ? props.value : props.formik.values[props.name]}
        loading={isFetching}
      >
        <Select.Option key={-1} value={-1}>
          {t('assignment_group.actions.select')}
        </Select.Option>

        {options.map((op) => {
          return (
            <Select.Option
              key={op.assignmentGroupId}
              value={op.assignmentGroupId}
            >
              {op.name}
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};

export default SelectAssignmentGroup;
