import { ResponsiveRadar } from '@nivo/radar';

export interface RadarChartProps<T> {
  className: string;
  size: number;
  getColor: (params: any) => string;
  keys: string[];
  content: T[];
}

const RadarChart = (props: RadarChartProps<any>) => {
  return (
    <div
      className={props.className}
      style={{ width: '100%', height: props.size }}
    >
      <ResponsiveRadar
        data={props.content}
        keys={props.keys}
        indexBy="group"
        colors={props.getColor}
        // valueFormat=""
        maxValue={100}
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        // colors={{ scheme: 'nivo' }}
        blendMode="multiply"
        motionConfig="wobbly"
        // legends={[
        //   {
        //     anchor: 'top-left',
        //     direction: 'column',
        //     translateX: -50,
        //     translateY: -40,
        //     itemWidth: 80,
        //     itemHeight: 20,
        //     itemTextColor: '#999',
        //     symbolSize: 12,
        //     symbolShape: 'circle',
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemTextColor: '#000',
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </div>
  );
};

export default RadarChart;
