import React from 'react';
import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import { ImageScale } from '@utils/ImageScale';
import SelectLessonTemplate from '@cms/resource-bank/SelectLessonTemplate';
import { RequireProUser } from '@components/button/RequireProUserButton';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '../services/lesson_model';

const initialValue = {
  name: '',
  description: '',
  lessonTypeId: -1,
  lessonTemplateId: -1,
  thumbnails: '',
};

export default function LessonEditForm(props: {
  type: LessonTypeEnum;
  successHandle: (isAdded: boolean, data: LessonRes) => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [createLesson, { isLoading }] =
    LessonAPI.endpoints.createLesson.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,
    validationSchema: yup.object().shape({
      name: yup.string().trim().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const payload = {
        type: props.type,
        name: values.name,
        description: values.description,
        thumbnails: values.thumbnails,
        lessonTypeId: values.lessonTypeId,
        lessonTemplateId: values.lessonTemplateId,
      };

      createLesson(payload)
        .unwrap()
        .then((data) => {
          props.successHandle(true, data);

          notification.success({
            message: t('lesson.warning.success'),
            description: t('lesson.warning.add_success'),
            placement: 'bottomRight',
          });
        })
        .catch(() => {
          notification.error({
            message: t('lesson.warning.error'),
            description: t('lesson.warning.add_error'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col flex={ImageScale.lesson.width + 15 + 'px'}>
          <InputGroup label={t('label.thumbnails')}>
            <SingleUpload
              scale={ImageScale.lesson.scale}
              accept={'image/jpg, image/jpeg, image/png'}
              multiple={false}
              onSuccessFunc={(val) => formik.setFieldValue('thumbnails', val)}
            >
              <IconImage
                src={formik.values.thumbnails}
                width={ImageScale.lesson.width}
                height={ImageScale.lesson.height}
              />
            </SingleUpload>
          </InputGroup>
        </Col>

        <Col flex={'auto'}>
          <Input
            required
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <RequireProUser>
            <SelectLessonTemplate
              formik={formik}
              name={'lessonTypeId'}
              value={formik.values.lessonTemplateId}
              onChange={(value) =>
                formik.setFieldValue('lessonTemplateId', value)
              }
            />
          </RequireProUser>

          <Input
            label={t('label.description')}
            type={'textarea'}
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            style={{ minHeight: 120 }}
          />

          <div className="submit-container">
            <ButtonGroup>
              <Button
                type={'primary'}
                shape={'round'}
                size={'large'}
                loading={isLoading}
                onClick={formik.submitForm}
                icon={IconUtils.actions.save}
              >
                {t('button.create')}
              </Button>

              <Button
                type="default"
                shape={'round'}
                size={'large'}
                onClick={props.onCloseFunc}
              >
                {t('button.cancel')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </FormikProvider>
  );
}
