import React, { useState } from 'react';

import { LessonActionEnum } from '@modules/product/container/actions';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from 'antd/lib/button';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';

import { ManagePageSiteTable } from '../containers/ManagePageSiteTable';
import CreatePageSiteForm from '../containers/CreatePageSiteForm';
import { PageSiteRes } from '@services/page-site/PageSiteAPI';
import { H1 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export default function SchoolContentPages() {
  const router = useRouter();
  const { t } = useTranslation();
  const proUser = useProUser();

  const [action, setAction] = useState({
    action: LessonActionEnum.DEFAULT,
    timestamp: DateAndTimeUtils.getCurrentTime(),
  });

  const handleAddPageSite = () => {
    setAction({
      action: LessonActionEnum.ADD_LESSON,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCancel = () => {
    setAction({
      action: LessonActionEnum.DEFAULT,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const onCreateSuccess = (isAdded: boolean, pageSite: PageSiteRes) => {
    if (isAdded) {
      router.push(
        SiteMap.content.lesson.compose_gen(pageSite.lesson.lessonId, proUser)
      );
    }
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('menu.school_content')}
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={handleAddPageSite}
          />
        </H1>
      </HeaderGroup>

      <ManagePageSiteTable />

      {action.action === LessonActionEnum.ADD_LESSON && (
        <CustomModal
          header={'Create Page Content'}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreatePageSiteForm
              onSuccess={onCreateSuccess}
              onClose={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
