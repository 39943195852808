import { useEffect, useState } from 'react';
import {
  ResourceAnswerProps,
  ResourceGradingProps,
} from '@app/redux/slices/viewResource';
import { CompMode } from '@cms/ComponentInteface';
import { CustomLessonSettingProps } from '@cms/lesson-template/context/props';
import {
  ExerciseRes,
  ExerciseResponseRes,
} from '@modules/assignments/service/exercise_model';
import {
  LessonRes,
  LessonSectionRes,
} from '@modules/product/services/lesson_model';
import { DoExerciseAPI } from '@modules/assignments/service/DoExerciseAPI';
import ExerciseUtils from '@modules/assignments/utils/ExerciseUtils';

export interface ExerciseViewProps {
  ready: boolean;
  productId: number;
  productTocId: number;

  mode: CompMode;
  exercise: ExerciseRes | null;
  lesson: LessonRes | null;
  sections: LessonSectionRes[];

  answers: ResourceAnswerProps[];
  feedBacks: ExerciseResponseRes[];
  gradings: ResourceGradingProps[];

  reload: () => void;
}

export function useExerciseView(
  exerciseId: number,
  type: CompMode,
  setting?: CustomLessonSettingProps
): ExerciseViewProps {
  const [viewExercise, { data: exData, isSuccess: viewSuccess }] =
    DoExerciseAPI.endpoints.viewExercise.useLazyQuery({});

  const [startExercise, { data, isSuccess }] =
    DoExerciseAPI.endpoints.startExercise.useMutation();

  const [ready, setReady] = useState(false);
  const [exercise, setExercise] = useState<ExerciseRes | null>(null);
  const [lesson, setLesson] = useState<LessonRes | null>(null);
  const [sections, setSections] = useState<LessonSectionRes[]>([]);
  const [answers, setAnswers] = useState<ResourceAnswerProps[]>([]);
  const [feedBacks, setFeedBacks] = useState<ExerciseResponseRes[]>([]);
  const [gradings, setGradings] = useState<ResourceGradingProps[]>([]);

  const [mode, setMode] = useState<CompMode>(CompMode.DO_EXERCISE);

  const [productId, setProductId] = useState(-1);
  const [productTocId, setProductTocId] = useState(-1);

  useEffect(() => {
    clearAllState();
    getExerciseData();
  }, [exerciseId, type]);

  useEffect(() => {
    if (data && isSuccess) {
      restoreExerciseData(data);
    }
  }, [data]);

  useEffect(() => {
    if (exData && viewSuccess) {
      restoreExerciseData(exData);
    }
  }, [exData]);

  const clearAllState = () => {
    setReady(false);
    setExercise(null);
    setLesson(null);
    setSections([]);
    setAnswers([]);
    setFeedBacks([]);
    setGradings([]);
    setProductId(-1);
    setProductTocId(-1);
  };

  const getExerciseData = () => {
    if (
      type === CompMode.GRADING_EXERCISE ||
      type === CompMode.GRADING_ASSIGNMENT ||
      type === CompMode.REVIEW_EXERCISE ||
      type === CompMode.REVIEW_ASSIGNMENT
    ) {
      viewExercise(exerciseId);
    } else if (exerciseId != null && exerciseId > 0) {
      startExercise(exerciseId);
    }
  };

  const restoreExerciseData = (data: ExerciseRes) => {
    const exerciseData = ExerciseUtils.restoreExercise(data, type, setting);

    setReady(true);
    setProductId(exerciseData.productId);
    setProductTocId(exerciseData.productTocId);
    setMode(exerciseData.mode);

    setExercise(exerciseData.exercise);
    setLesson(exerciseData.lesson);
    setSections(exerciseData.sections);

    setAnswers(exerciseData.answers);
    setFeedBacks(exerciseData.feedBacks);
    setGradings(exerciseData.gradings);
  };

  return {
    ready,
    mode,
    productId,
    productTocId,

    exercise,
    lesson,
    sections,

    answers,
    feedBacks,
    gradings,

    reload: getExerciseData,
  };
}
