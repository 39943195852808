import { StartSurvey } from '@modules/public/components/surveys/StartSurvey';
import { useEffect, useState } from 'react';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { SurveyLessonContent } from '@cms/lesson-template/pages/SurveyLessonContent';
import styled from 'styled-components';
import { LessonRes } from '@modules/product/services/lesson_model';
import { SurveyRes } from '@modules/assignments/service/survey_model';

export const SurveyViewerPage = (props: { item: SurveyRes }) => {
  const [viewSurveyContent, { data, isSuccess }] =
    PublicPageAPI.endpoints.viewSurveyContent.useLazyQuery();

  const [ready, setReady] = useState(false);
  const [lessonData, setLessonData] = useState<LessonRes | null>(null);

  const handleOnReady = () => {
    setReady(true);
    viewSurveyContent(props.item.surveyId);
  };

  useEffect(() => {
    if (data && isSuccess) {
      setLessonData(data);
    }
  }, [data]);

  return (
    <SurveyViewerStyle className={'survey-viewer'}>
      <div className={'survey-title'}>
        <h1>{props.item.name}</h1>

        <div className={'survey-direction'}>{props.item.direction}</div>
      </div>

      <div className={'survey-content'}>
        {ready && lessonData ? (
          <SurveyLessonContent survey={props.item} content={lessonData} />
        ) : (
          <StartSurvey onReady={handleOnReady} />
        )}
      </div>
    </SurveyViewerStyle>
  );
};

const SurveyViewerStyle = styled.div`
  .exercise-template-container {
    border: 1px solid #000;
  }
`;
