import React from 'react';
import { FeatureGroup } from './FeatureGroup';

export const ParentFeature = () => {
  return (
    <div className={'feature-group'}>
      <FeatureGroup header={'Chức năng phụ huynh.'}>
        <ol>
          <li>Đăng kí phụ huynh với link to children.</li>

          <li>Theo dõi các tài liệu mà con đã xem.</li>

          <li>Theo dõi kết quả học tâp</li>

          <li>Tạo session để học chung với con nếu ở xa.</li>
        </ol>
      </FeatureGroup>
    </div>
  );
};
