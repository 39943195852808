import { FeatureGroup } from '@modules/public/components/features/FeatureGroup';
import { RoleEnum } from '@app/redux/slices/roles';
import { LinkSample } from '@modules/public/features/Link';
import { SiteMap } from '@router/SiteMap';

export const LtiFeature = () => {
  return (
    <div className={'group'}>
      <FeatureGroup
        header={'LTI features'}
        roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
      >
        <div>
          Tính năng này cho phép các tổ chức, trung tâm khác sử dụng tính năng
          của Gstudy như 1 thành phần của hệ thống.
        </div>

        <dd>
          <dt>View Product LTI</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào My Account -&gt; Oauth APp
                <LinkSample href={SiteMap.lti.oauth_app} />{' '}
              </li>
              <li>
                Click dấu + để tạo oauth app. Nhập name, description, hệ thống
                tự động sinh ra consumer key và shared secret key.
              </li>
              <li>Ghi nhớ 2 key này và product code để test.</li>
            </ol>
          </dl>

          <dt>Kiểm tra token có hợp lệ</dt>
          <dl>
            <ol>
              <li>
                Click vào biểu tượng testing và đi tới trang testing{' '}
                <LinkSample href={SiteMap.lti.login_testing} /> để kiểm tra
              </li>
              <li>
                Tại trang login nhập client id và secret id để lấy access token
              </li>

              <li>
                Click Testing access product để kiểm tra khả năng đăng nhập
              </li>
            </ol>
          </dl>

          <dt>Kiểm tra tính năng xem sách</dt>
          <dl>
            <ol>
              <li>
                Tại bước Testing Token ở trên, click vào
                <LinkSample href={SiteMap.lti.product_test} /> để kiểm tra
              </li>
              <li>Nhập access token, email và product code.</li>

              <li>Nhập Testing</li>

              <li>
                Hiển thị xem biên soạn sách là thành công. với role teacher và
                xem sách với role students.
                <LinkSample href={SiteMap.lti.product_view} />
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>
    </div>
  );
};
