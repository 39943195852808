import { useViewProductContext } from '@modules/product/components/view-toc/ViewTableOfContentContext';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import React, { useEffect } from 'react';
import Result from 'antd/lib/result';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const ViewLessonInTableOfContent = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { selectLessonId, selectedProductTocId, productId, ready } =
    useViewProductContext();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [selectLessonId]);

  const goToTableOfContent = () => {
    router.push(SiteMap.content.product.toc_gen(productId));
  };

  return (
    <>
      {ready ? (
        <>
          {selectLessonId > 0 ? (
            <LessonReview
              key={selectLessonId}
              lessonId={selectLessonId}
              productId={productId}
              productTocId={selectedProductTocId}
            />
          ) : (
            <Result
              status="warning"
              title={t('product.warning.empty_toc')}
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={goToTableOfContent}
                  icon={IconUtils.table_of_content}
                >
                  {t('product.table_of_content')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <H1>{t('label.loading')}</H1>
      )}
    </>
  );
};

export default ViewLessonInTableOfContent;
