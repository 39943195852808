import { useState, useEffect } from 'react';
// Define general type for useWindowSize hook, which includes width and height

export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export default function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useWindowSizeChange(
  triggerEvent: (width: number | undefined, height: number | undefined) => void,
  delay: number
) {
  const size = useWindowSize();

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        triggerEvent(size.width, size.height);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [size.width, size.height] // Only re-call effect if value or delay changes
  );
}
