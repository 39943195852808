import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGradingExerciseContext } from '@cms/lesson-template/context/GradingExerciseContext';
import LessonButton from '../style';
import { IconUtils } from '@utils/IconUtils';
import { useTheme } from '@app/styled/StyledProvider';

export const ExitGradingButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { unscoredQuestion } = useGradingExerciseContext();
  const { dispatchExerciseAction } = useViewLessonContext();

  if (unscoredQuestion != null && unscoredQuestion === 0) {
    return (
      <LessonButton
        className={'complete-grading-btn'}
        type={'primary'}
        shape={'round'}
        size={'large'}
        style={{ background: theme.exercise.submit }}
        onClick={() =>
          dispatchExerciseAction(ExerciseActionEnum.request_exit_grading, true)
        }
        icon={IconUtils.exercise.grade}
      >
        {t('exercise.button.complete_grading')}
      </LessonButton>
    );
  } else {
    return (
      <LessonButton
        className={'exit-grading-btn'}
        type={'primary'}
        shape={'round'}
        style={{
          background: theme.exercise.exit_grading,
          color: theme.exercise.exit_grading_text,
        }}
        icon={IconUtils.exercise.grade}
        onClick={() =>
          dispatchExerciseAction(ExerciseActionEnum.request_exit_grading, true)
        }
      >
        {t('exercise.button.exit_grading')}
      </LessonButton>
    );
  }
};
