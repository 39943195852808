import React, { useEffect } from 'react';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import styled from 'styled-components';
import { ProductRes } from '@modules/product/services/product_model';

const ViewProductInfo = (props: { size?: number; productId: number }) => {
  const [getProduct, { data, isSuccess }] =
    ProductAPI.endpoints.getProduct.useLazyQuery();

  useEffect(() => {
    getProduct(props.productId);
  }, [props.productId]);

  if (data && isSuccess) {
    return <ViewProduct size={props.size} item={data} />;
  } else {
    return null;
  }
};

export default ViewProductInfo;

export const ViewProduct = (props: { size?: number; item: ProductRes }) => {
  return (
    <ViewProductStyle className={'product-display'}>
      <ThumbnailsImg
        type={'icon'}
        src={props.item.icon}
        ratio={ImageScale.product.ratio}
        width={props.size ?? ImageScale.product.icon}
      />
      <span>{props.item.name}</span>
    </ViewProductStyle>
  );
};

const ViewProductStyle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1ch;
`;
