import React, { useEffect, useState } from 'react';
import { ClassRoomAPI } from '@modules/calendar/service/ClassRoomAPI';
import useModal from '@hooks/useModal';
import { EnterClassRoomAccessCodeForm } from '@modules/calendar/components/class-room/EnterClassRoomAccessCodeForm';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import { useRouter } from '@hooks/useRouter';
import { useSelector } from '@app/redux/hook';
import { ClassRoomContextProvider } from '@classroom/context/ClassRoomContext';
import { SiteMap } from '@router/SiteMap';
import Card, { CardClassEnum } from '@components/card';
import { ClassRoomInteractProvider } from '@classroom/context/ClassRoomInteractContext';
import { ClassRoomWaiting } from '@classroom/components/ClassRoomWaiting';
import { SessionRes } from '@services/model/session';
import { ClassRoomErrorCode } from '@modules/calendar/service/model';

const ClassRoomPublic = (props: { code: string; token: string }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { error: warningErr } = useConfirmModal();
  const { isAuth } = useSelector((state) => state.profile);

  const [canAccessClassRoom, { data, error, isSuccess, isError }] =
    ClassRoomAPI.endpoints.canAccessClassRoom.useLazyQuery({});

  const [sessionData, setSessionData] = useState<SessionRes | null>(null);

  const showAccessModal = useModal();

  useEffect(() => {
    canAccessClassRoom({
      code: props.code,
      token: props.token,
      accessCode: '',
    });
  }, [props.code, props.token]);

  useEffect(() => {
    if (data && isSuccess) {
      showAccessModal.hide();
      setSessionData(data);
    } else {
      if (isError && error) {
        // @ts-ignore
        handleOnError(error.data.error);
      }
    }
  }, [data, error, isSuccess, isError]);

  const handleOnError = (error: ClassRoomErrorCode) => {
    if (error === ClassRoomErrorCode.access_code_require) {
      showAccessModal.show();
    } else if (error === ClassRoomErrorCode.access_deny) {
      warningErr(
        t('header.confirm'),
        t('class_room.warning.access_deny'),
        t('class_room.actions.go_back'),
        handleGoBack
      );
    } else if (error === ClassRoomErrorCode.session_not_found) {
      warningErr(
        t('header.confirm'),
        t('class_room.warning.session_not_found'),
        t('class_room.actions.go_back'),
        handleGoBack
      );
    }
  };

  const handleGoBack = () => {
    if (isAuth) {
      router.push(SiteMap.private.my_calendar);
    } else {
      router.push(SiteMap.index);
    }
  };

  const handleWhenLoginSuccess = (sessionData: SessionRes) => {
    setSessionData(sessionData);
    showAccessModal.hide();
  };

  return (
    <>
      {showAccessModal.visible && (
        <ClassRoomWaiting>
          <Card
            className={CardClassEnum.rectangle_box}
            style={{ maxWidth: 600, margin: '0 auto', padding: '2em 3em' }}
          >
            <EnterClassRoomAccessCodeForm
              code={props.code}
              onSuccess={handleWhenLoginSuccess}
            />
          </Card>
        </ClassRoomWaiting>
      )}

      {sessionData && (
        <ClassRoomInteractProvider session={sessionData}>
          <ClassRoomContextProvider session={sessionData} />
        </ClassRoomInteractProvider>
      )}
    </>
  );
};

export default ClassRoomPublic;
