import { Alert, Button, notification, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollToTop from '@components/feature/ScrollToTop';
import { useTranslation } from 'react-i18next';
import {
  PublishCourseAPI,
  PublishCourseInfoRes,
  UpdateCourseInfoReq,
} from '@services/publish/PublishCourseAPI';
import PublishGradeContainer from '@modules/product/container/publish/PubishGradeContainer';
import PublishCategoryContainer from '@modules/product/container/publish/PubishCategoryContainer';
import PublishSubjectContainer from '@modules/product/container/publish/PubishSubjectContainer';
import { CourseRes } from '@services/private/CourseAPI';

export default function CourseInformationStep(props: {
  courseData: CourseRes;
  goNext: () => void;
}) {
  const { t } = useTranslation();

  const [ready, setReady] = useState(false);
  const [errorMsg, setErrMessage] = useState('');

  const [subjectIds, setSubjectIds] = useState<number[]>([]);
  const [gradeIds, setGradeIds] = useState<number[]>([]);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);

  const [getCourseInfo, { data, isSuccess }] =
    PublishCourseAPI.endpoints.getCourseInfo.useLazyQuery();

  const [updateCourseInfo] =
    PublishCourseAPI.endpoints.updateCourseInfo.useMutation({});

  useEffect(() => {
    setReady(false);
    getCourseInfo(props.courseData.courseId);
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      updateCurrentData(data);
    }
  }, [data]);

  const updateCurrentData = (data: PublishCourseInfoRes) => {
    if (data.grades != null && data.grades.length > 0) {
      const ids = data.grades.map((item) => {
        return item.gradeId;
      });
      setGradeIds(ids);
    }

    if (data.subjects != null && data.subjects.length > 0) {
      const ids = data.subjects.map((item) => {
        return item.subjectId;
      });
      setSubjectIds(ids);
    }

    if (data.categories != null && data.categories.length > 0) {
      const ids = data.categories.map((item) => {
        return item.categoryId;
      });
      setCategoryIds(ids);
    }

    setReady(true);
  };

  const executeUpdateCourseInfo = () => {
    const request: UpdateCourseInfoReq = {
      courseId: props.courseData.courseId,
      subjectIds: subjectIds,
      categoryIds: categoryIds,
      gradeIds: gradeIds,
    };

    updateCourseInfo(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('publish.course.warning.success'),
          description: t('publish.course.warning.success_message'),
          placement: 'bottomRight',
        });
        props.goNext();
      })
      .catch(() => {
        notification.error({
          message: t('publish.course.warning.error'),
          description: t('publish.course.warning.error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const updateCourseInformation = () => {
    if (
      subjectIds.length === 0 ||
      gradeIds.length === 0 ||
      categoryIds.length === 0
    ) {
      setErrMessage(t('publish.basic_info.saving_error'));
    } else {
      executeUpdateCourseInfo();
    }
  };

  return (
    <PublishCourseStyle className={'publish-course-section'}>
      <ScrollToTop visible={false} />

      <h1>{t('publish.basic_info.header')}</h1>

      {errorMsg && (
        <Alert
          message={t('label.warning')}
          description={errorMsg}
          type="error"
          closable
          onClose={() => setErrMessage('')}
        />
      )}

      {!ready && (
        <>
          <Skeleton avatar paragraph={{ rows: 4 }} />
          <Skeleton avatar paragraph={{ rows: 4 }} />
          <Skeleton avatar paragraph={{ rows: 4 }} />
        </>
      )}

      {ready && (
        <div className={'course-publish-content'}>
          <PublishGradeContainer
            ids={gradeIds}
            onChange={(ids: number[]) => setGradeIds(ids)}
          />

          <PublishCategoryContainer
            ids={categoryIds}
            onChange={(ids: number[]) => setCategoryIds(ids)}
          />

          <PublishSubjectContainer
            ids={subjectIds}
            onChange={(ids: number[]) => setSubjectIds(ids)}
          />
        </div>
      )}

      <div className={'course-publish-step'}>
        <Button type={'default'} disabled={true} shape="round">
          {t('button.step.previous')}
        </Button>

        <Button
          type={'primary'}
          shape="round"
          onClick={updateCourseInformation}
        >
          {t('button.step.next')}
        </Button>
      </div>
    </PublishCourseStyle>
  );
}

export const PublishCourseStyle = styled.div`
  h1 {
    margin-top: 2em;
  }

  .publish-group {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .course-publish-step,
  .product-publish-step {
    display: flex;
    justify-content: space-between;

    margin-top: 2em;
    margin-bottom: 4em;
  }
`;
