import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { MyClassTable } from '../container/users/MyClassTable';
import { IconUtils } from '@utils/IconUtils';
import Button from 'antd/lib/button';
import { ManageClassActionEnum } from '@modules/users/pages/ManageClassPage';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ClassEditForm } from '@modules/users/component/classes/ClassEditForm';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';

export default function MyClassPage() {
  const { t } = useTranslation();
  const [action, setAction] = useState(ManageClassActionEnum.default);

  const onAddedSuccess = () => {
    setAction(ManageClassActionEnum.refresh);
  };

  const onCancel = () => {
    setAction(ManageClassActionEnum.default);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('classes.header')}

          <Authorization type={'ifAnyGranted'} roles={[RoleEnum.MANAGE_CLASS]}>
            <Button
              type={'primary'}
              shape="circle"
              icon={IconUtils.add_new}
              onClick={() => setAction(ManageClassActionEnum.add)}
            />
          </Authorization>
        </H1>
      </HeaderGroup>

      <MyClassTable action={action} />

      {action === ManageClassActionEnum.add && (
        <CustomModal
          header={t('classes.add_class')}
          className={CustomModalClassEnum.large_modal}
          content={
            <ClassEditForm
              successHandle={onAddedSuccess}
              onCloseFunc={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}
    </>
  );
}
