import { useTranslation } from 'react-i18next';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Button, Col, Row } from 'antd';
import Input from '@components/input/input';
import { SearchOutlined } from '@ant-design/icons';
import { InputGroup } from '@components/input/InputGroup';
import { Default_Gutter } from '@components/grid';

export const FilterPageSite = (props: {
  keyword?: string;
  onSubmit: (keyword: string) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      keyword: props.keyword || '',
    },

    onSubmit: (values) => {
      props.onSubmit(values.keyword);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="keyword"
            type="text"
            label={t('label.keyword')}
            placeholder={t('label.enter_keyword')}
            onChange={formik.handleChange}
            value={formik.values.keyword}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <InputGroup label={<>&nbsp;</>}>
            <Button type={'primary'} onClick={formik.submitForm}>
              <SearchOutlined /> {t('button.filter')}
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};

export default FilterPageSite;
