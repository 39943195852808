import React from 'react';
import { Col, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import LoginForm from '@modules/authorization/container/LoginForm';
// import SocialSignup from '@modules/authorization/components/SocialSignup';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const LoginPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleLoginSuccess = () => {
    router.push(SiteMap.private.dashboard);
  };

  return (
    <>
      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            <H1>{t('login.header')}</H1>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <p>{t('login.description')}</p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <LoginForm successHandle={handleLoginSuccess} />

                {/*<SocialSignup />*/}
              </Col>
            </Row>
          </div>
        </div>
      </AuthorizeStyle>
    </>
  );
};

export default LoginPage;
