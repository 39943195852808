import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';

import {
  FibWordAnsProps,
  FibWordOptionProps,
  FibWordProps,
  FibWordSettingProps,
} from '@cms/comps/interact/inline/FibWordComp';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { CompAnswerProps, CompProps } from '@cms/ComponentInteface';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import produce from 'immer';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';

const FibWordCompContext = createContext({
  modifiedTime: -1 as number,
  disabled: false as boolean,
  setting: {} as FibWordSettingProps,

  targetItems: [] as FibWordOptionProps[],
  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  updateAnswers: (newAnswers: string[]) => {
    console.log(newAnswers);
  },
  updateComponent: () => {},

  updateOptions: (options: FibWordOptionProps[]) => {
    console.log(options);
  },

  handleOnChange: (answer: string) => {
    console.log(answer);
  },
});

export const FibWordCompContextProvider = (props: {
  disabled: boolean;
  item: FibWordProps;
  answer: FibWordAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;

  children: any;
}) => {
  const dispatch = useDispatch();

  const [answers, setCompAnswers] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (props.answer != null && props.answer.answer) {
      setCompAnswers({
        value: props.answer && props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswers({
        value: [],
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answers && answers.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answers.value,
      });
    }
  }, [answers]);

  const handleOnChange = (answer: string) => {
    if (!props.disabled) {
      setCompAnswers((prev) => {
        const indexGroup = answer.split(':')[0];

        const isExits = [...prev.value].some((ans) => {
          return ans.split(':')[0] === indexGroup;
        });

        if (isExits) {
          const newAns = [...prev.value].map((ans) => {
            if (ans.split(':')[0] === indexGroup) {
              return answer;
            } else {
              return ans;
            }
          });

          return {
            value: newAns,
            triggerChange: true,
          };
        } else {
          return {
            value: [...prev.value, answer],
            triggerChange: true,
          };
        }
      });
    }
  };

  const [modifiedTime, setModifiedTime] = useState(-1);
  const [targetItems, setTargetItems] = useState(
    props.item.configuration.targetItems
  );

  useEffect(() => {
    setTargetItems(props.item.configuration.targetItems);
  }, [props.item]);

  const updateOptions = (options: FibWordOptionProps[]) => {
    setTargetItems(options);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const updateAnswers = (newAnswers: string[]) => {
    setCompAnswers({
      value: newAnswers,
      triggerChange: true,
    });
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        targetItems: targetItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answers.value,
        },
      })
    );
  };

  return (
    <FibWordCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        disabled: props.disabled,
        setting: props.item.setting,
        targetItems: targetItems,
        answers: answers.value,
        feedback: feedBack,

        updateOptions: updateOptions,
        updateComponent: updateComponent,
        updateAnswers: updateAnswers,

        handleOnChange: handleOnChange,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedBack}>
        {props.children}
      </CompFeedbackContextProvider>
    </FibWordCompContext.Provider>
  );
};

export const useFibWordCompContext = () => {
  const context = useContext(FibWordCompContext);
  if (!context) {
    throw new Error('You must wrap container by FibWordCompContextProvider');
  }
  return context;
};
