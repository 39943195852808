import { EnterAnonymousForm } from '@modules/public/components/surveys/EnterAnonymousForm';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card, { CardClassEnum } from '@components/card';

export const StartSurvey = (props: { onReady: () => void }) => {
  const [ready, setReady] = useState(false);

  const handleOnSuccess = () => {
    setReady(true);
  };

  useEffect(() => {
    if (ready) {
      props.onReady();
    }
  }, [ready]);

  return (
    <StartSurveyStyle className={'start-survey'}>
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <p>Please provide your information before start the survey.</p>

        <EnterAnonymousForm
          buttonContent={'Start Survey'}
          onSuccess={handleOnSuccess}
        />
      </Card>
    </StartSurveyStyle>
  );
};

const StartSurveyStyle = styled.div`
  max-width: 600px;
  margin: 30px auto;
`;
