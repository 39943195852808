import { useAudioCompContext } from '@cms/comps/content/audio/AudioCompContext';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AudioPlayer, AudioPlayerActionEnum } from '@cms/content/AudioViewer';
import { TimelineProps } from '@cms/ComponentInteface';

const AudioSubtitleComp = (props: { onChange: (time: number) => void }) => {
  const [action, setAction] = useState<{
    action: AudioPlayerActionEnum;
    params: any;
  }>({
    action: AudioPlayerActionEnum.default,
    params: null,
  });

  const [currentTime, setCurrentTime] = useState({
    time: 0,
    triggerChange: false,
  });

  useEffect(() => {
    if (currentTime && currentTime.triggerChange) {
      props.onChange(currentTime.time);
    }
  }, [currentTime]);

  const { modifiedTime, setting, audioContent, sourceItems } =
    useAudioCompContext();

  const handleOnChange = (time: number) => {
    setCurrentTime({
      time: time,
      triggerChange: true,
    });
  };

  const handleOnClickData = (data: TimelineProps) => {
    if (data.from && data.to) {
      setAction({
        action: AudioPlayerActionEnum.jumpTo,
        params: data.from,
      });
    }
  };

  return (
    <AudioSubtitleCompStyle className={'audio-inline-comp'} key={modifiedTime}>
      <div className={'audio-inline-comp-viewer'}>
        <AudioPlayer
          action={action}
          audioSrc={audioContent.data}
          title={audioContent.name}
          onChange={handleOnChange}
        />
      </div>

      {setting.showSubTitle && sourceItems && sourceItems.length > 0 && (
        <div className={'audio-subtitle-content'}>
          {sourceItems.map((data) => {
            const active =
              data.from <= currentTime.time &&
              (currentTime.time <= data.to || data.to < 0);

            return (
              <div
                className={`audio-inline-comp-content-item ${
                  active ? 'active' : ''
                }`}
                key={JSON.stringify(data)}
                onClick={() => handleOnClickData(data)}
              >
                <ContentViewer contentData={data.content} />
              </div>
            );
          })}
        </div>
      )}
    </AudioSubtitleCompStyle>
  );
};

export default AudioSubtitleComp;

const AudioSubtitleCompStyle = styled.div`
  .audio-subtitle-content {
    margin-top: 1em;
    border: 1px solid #ccc;

    .audio-inline-comp-content-item {
      cursor: pointer;
      padding: 0.125em 0.5em;

      &:not(:first-child) {
        border-top: 1px solid #ccc;
      }

      &.active {
        font-weight: 500;
        color: ${(props) => props.theme.component.primary};
      }
    }
  }
`;
