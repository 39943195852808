import {
  ExerciseRes,
  ExerciseResponseStatusEnum,
  ExerciseStatus,
} from '@modules/assignments/service/exercise_model';
import { CompGradingProps, CompMode } from '@cms/ComponentInteface';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';
import {
  ResourceAnswerProps,
  ResourceGradingProps,
} from '@app/redux/slices/viewResource';
import { CustomLessonSettingProps } from '@cms/lesson-template/context/props';

const ExerciseUtils = {
  restoreExercise: (
    data: ExerciseRes,
    type: CompMode,
    setting?: CustomLessonSettingProps
  ) => {
    const mode =
      data.status === ExerciseStatus.NEED_GRADING
        ? CompMode.GRADING_EXERCISE
        : data.status === ExerciseStatus.COMPLETED
        ? CompMode.REVIEW_EXERCISE
        : data.status === ExerciseStatus.NOT_START ||
          data.status === ExerciseStatus.IN_PROGRESS
        ? CompMode.DO_EXERCISE
        : type;

    const answers = data.answers.map((item) => {
      return {
        resourceId: item.resourceId,
        answers: item.answer,
      } as ResourceAnswerProps;
    });

    const feedBacks =
      data.status === ExerciseStatus.SUBMITTED ||
      data.status === ExerciseStatus.NEED_GRADING ||
      data.status === ExerciseStatus.COMPLETED
        ? data.answers
        : [];

    const gradings =
      data.status === ExerciseStatus.NEED_GRADING
        ? data.answers
            .filter((res) => {
              return res.status === ExerciseResponseStatusEnum.NEED_GRADING;
            })
            .map((question) => {
              const compGrading: CompGradingProps[] = question.result
                .filter((comp) => {
                  return comp.autoScore == null || !comp.autoScore;
                })
                .map((com) => {
                  return {
                    id: com.id,
                    score: com.score,
                    comment: com.comment,
                  };
                });

              return {
                resourceId: question.resourceId,
                grading: compGrading,
              };
            })
        : [];

    return {
      mode: mode,
      exercise: data,
      productId: data.productId ?? -1,
      productTocId: data.productTocId ?? -1,
      lesson:
        setting && setting.lessonType
          ? { ...data.lesson, type: setting.lessonType }
          : data.lesson,
      sections: data.lesson.sections,
      answers: answers,
      feedBacks: feedBacks,
      gradings: gradings,
    };
  },

  onSubmitAnonymous: (
    type: CompMode,
    lesson: LessonRes,
    data: ExerciseRes,
    router: any,
    event?: { onSubmit?: (data: ExerciseRes) => void }
  ) => {
    if (event && event.onSubmit) {
      event.onSubmit(data);
    } else {
      if (data.status === ExerciseStatus.NEED_GRADING) {
        router.push(
          SiteMap.public.exercise.grade_exercise_gen(data.exerciseId)
        );
      } else if (data.status === ExerciseStatus.COMPLETED) {
        router.push(
          SiteMap.public.exercise.review_exercise_gen(data.exerciseId)
        );
      } else {
        router.push(SiteMap.public.lessons);
      }
    }
  },

  onSubmit: (
    type: CompMode,
    lesson: LessonRes,
    data: ExerciseRes,
    router: any,
    event?: { onSubmit?: (data: ExerciseRes) => void }
  ) => {
    if (event && event.onSubmit) {
      event.onSubmit(data);
    } else {
      if (lesson && lesson.validation && lesson.validation.interact) {
        if (type === CompMode.DO_ASSIGNMENT) {
          router.push(SiteMap.private.my_assignment.list);
        } else {
          if (data.status === ExerciseStatus.NEED_GRADING) {
            // if not complete --> need student to grade exercise...
            router.push(SiteMap.private.my_exercise.grade_gen(data.exerciseId));

            // if is complete --> view report.
          } else if (data.status === ExerciseStatus.COMPLETED) {
            router.push(
              SiteMap.private.my_exercise.review_gen(data.exerciseId)
            );

            // otherwise  --> view report.
          } else {
            router.push(
              SiteMap.private.my_exercise.review_gen(data.exerciseId)
            );
          }
        }
      } else {
        if (lesson && lesson.type === LessonTypeEnum.collection) {
          router.push(SiteMap.private.my_lesson.list);
        } else if (
          data != null &&
          data.assignmentInstance != null &&
          data.assignmentInstance.assignmentInstanceId != null
        ) {
          router.push(SiteMap.private.my_assignment.list);
        } else {
          router.push(SiteMap.content.lesson.list);
        }
      }
    }
  },

  onExit: (
    exercise: ExerciseRes | null,
    router: any,
    lesson: LessonRes | null,
    productId: number,
    isStudent: boolean
  ) => {
    if (
      exercise &&
      exercise.assignmentInstance &&
      exercise.assignmentInstance.assignmentInstanceId
    ) {
      if (isStudent) {
        router.push(SiteMap.private.my_assignment.list);
      } else {
        router.push(SiteMap.assignments.list);
      }
    } else if (lesson && lesson.type === LessonTypeEnum.collection) {
      router.push(SiteMap.private.my_lesson.list);
    } else if (productId > 0) {
      router.push(SiteMap.private.my_library.view_product_gen(productId));
    } else {
      router.push(SiteMap.content.lesson.list);
    }
  },

  collectGrading: (
    gradings: Record<number, CompGradingProps[]>
  ): ResourceGradingProps[] => {
    const results: ResourceGradingProps[] = [];
    const resourceIds = Object.keys(gradings);

    resourceIds.forEach((rsId) => {
      const _questionGrading = gradings[+rsId];
      results.push({
        resourceId: +rsId,
        grading: _questionGrading,
      });
    });
    return results;
  },
};

export default ExerciseUtils;
