import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {RoleEnum} from "@app/redux/slices/roles";

export const MyAccountAPI = createApi({
  reducerPath: 'MyAccountAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getPermissions: builder.query<RoleEnum[], unknown>({
      query: () => ({
        url: `/my-account/permissions`,
      }),
    }),
  }),
});
