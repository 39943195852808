import React, { useEffect, useState } from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { LessonReportAPI } from '@services/reports/LessonReport';
import { Col, Row } from 'antd';
import { LessonStandardSetWeight } from './components/LessonStandardSetWeight';
import LessonStandardScoreHistory from './components/LessonStandardScoreHistory';
import { LessonStandardWeight } from '@modules/reports/lesson/components/LessonStandardWeight';
import LessonScoreHistory from '@modules/reports/lesson/components/LessonScoreHistory';
import LessonStandardGrowth from '@modules/reports/lesson/components/LessonStandardGrowth';
import LessonStandardGroupScoreHistory from '@modules/reports/lesson/components/LessonStandardStackScoreHistory';
import LessonStandardDistributed from '@modules/reports/lesson/components/LessonStandardDistributed';
import ExerciseScoreReport from '@modules/reports/lesson/components/ExerciseScoreReport';
import { LessonStandardGroupWeight } from './components/LessonStandardGroupWeight';
import Card, { CardClassEnum } from '@components/card';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { LessonReportTypeEnum } from '@services/reports/AssignmentReport';
import { SelectReportType } from '@modules/reports/components/SelectReportType';
import SwitchCondition from '@components/common/condition/SwitchCondition';

const LessonReportPage = (props: { lessonId: number }) => {
  const [viewType, setViewType] = useState(LessonReportTypeEnum.score);
  const [enableStandardReport, setEnableStandardReport] = useState(false);

  const [getLessonReport, { data, isSuccess }] =
    LessonReportAPI.endpoints.getLessonReport.useLazyQuery();

  useEffect(() => {
    getLessonReport(props.lessonId);
  }, [props.lessonId]);

  useEffect(() => {
    if (data) {
      setEnableStandardReport(
        data.standardSets.length > 0 && data.standards.length > 0
      );
    }
  }, [data]);

  const handleOnChange = (value: LessonReportTypeEnum) => {
    setViewType(value);
  };

  return (
    <div className={'view-lesson-report'} style={{ fontSize: 15 }}>
      <HeaderGroup className="header-group">
        <H1>
          Lesson Reports
          {data && isSuccess && (
            <>
              &nbsp;-&nbsp;<b>{data.lesson.name}</b>
            </>
          )}
        </H1>

        <div className={'header-buttons'}>
          <SelectReportType
            enableStandardReport={enableStandardReport}
            value={viewType}
            onChange={handleOnChange}
          />
        </div>
      </HeaderGroup>

      {data && isSuccess && (
        <>
          <SwitchCondition
            conditions={[
              {
                condition: viewType === LessonReportTypeEnum.score,
                children: (
                  <Row gutter={[Default_Gutter, Default_Gutter]}>
                    <Col span={24}>
                      <Card className={CardClassEnum.rectangle_box}>
                        <ExerciseScoreReport reports={data.exercises} />
                      </Card>
                    </Col>
                  </Row>
                ),
              },

              {
                condition: viewType === LessonReportTypeEnum.history,
                children: (
                  <Row gutter={[Default_Gutter, Default_Gutter]}>
                    <Col span={24}>
                      <Card className={CardClassEnum.rectangle_box}>
                        <LessonScoreHistory reports={data.exercises} />
                      </Card>
                    </Col>

                    {enableStandardReport && (
                      <>
                        <Col span={24}>
                          <Card className={CardClassEnum.rectangle_box}>
                            <LessonStandardScoreHistory
                              reports={data.exercises}
                            />
                          </Card>
                        </Col>

                        <Col span={24}>
                          <Card className={CardClassEnum.rectangle_box}>
                            <LessonStandardGroupScoreHistory
                              reports={data.exercises}
                            />
                          </Card>
                        </Col>

                        <Col span={24}>
                          <Card className={CardClassEnum.rectangle_box}>
                            <LessonStandardGrowth reports={data.exercises} />
                          </Card>
                        </Col>

                        <Col span={24}>
                          <Card>
                            <LessonStandardDistributed
                              reports={data.exercises}
                            />
                          </Card>
                        </Col>
                      </>
                    )}
                  </Row>
                ),
              },

              {
                condition: viewType === LessonReportTypeEnum.standard,
                children: (
                  <>
                    <Row gutter={[Default_Gutter, Default_Gutter]}>
                      <Col span={24}>
                        <Card className={CardClassEnum.rectangle_box}>
                          <LessonStandardSetWeight
                            lesson={data.lesson}
                            standardSet={data.standardSets}
                          />

                          <LessonStandardGroupWeight
                            lesson={data.lesson}
                            standards={data.standards}
                          />
                        </Card>
                      </Col>

                      <Col span={24}>
                        <Card className={CardClassEnum.rectangle_box}>
                          <LessonStandardWeight
                            lesson={data.lesson}
                            standards={data.standards}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default LessonReportPage;
