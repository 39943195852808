import styled from "styled-components";

export const PermissionTableStyle = styled.div`
  .direction-group {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;

    .direction-item {
      border: 1px solid #ccc;
      padding: 0.25em 1em 0.25em 0.5em;
      margin-left: 1em;
    }
  }

  table.permission-table {
    thead tr th,
    tbody tr th.group,
    tbody tr th.group-empty {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 13px;
    }

    thead tr th {
      text-align: center;
      background: ${(props) => props.theme.app.primary};
      color: #fff;
    }

    tr td,
    tr th {
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding: 8px 16px;

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }

    tr:last-child {
      th,
      td {
        border-bottom: 1px solid #ccc;
      }
    }

    tr td:first-child {
      text-align: left;
    }

    tr th {
      text-align: left;
      background: ${(props) => props.theme.app.primary_bgr};
      color: #000;
    }
  }

  .ant-checkbox-group {
    display: block;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }
`;
