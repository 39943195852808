import React from 'react';

import { Button, Divider, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { IconUtils } from '@utils/IconUtils';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';

const LoginAdminForm = (props: {
  successHandle: () => void;
  hideCancel?: boolean;
  hideForgotPassword?: boolean;
}) => {
  const { onLoginSuccess } = useLoginSuccess();

  const { t } = useTranslation();
  const [doLogin, { isLoading }] = AuthAPI.endpoints.login.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      username: '',
      password: '',
    },

    validationSchema: yup.object().shape({
      username: yup.string().required(t('validation.required.field')),
      password: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const payload = {
        grantType: 'password',
        username: values.username.trim(),
        password: values.password,
      };

      doLogin(payload)
        .unwrap()
        .then((data) => {
          onLoginSuccess(data);
          props.successHandle();

          notification.success({
            message: t('login.warning.login_success'),
            description: t('login.warning.login_success_message'),
            placement: 'bottomRight',
          });
        })
        .catch(() => {
          notification.error({
            message: t('login.warning.login_error'),
            description: t('errors.username_or_password_is_uncorrect'),
            placement: 'bottomRight',
          });
        });
    },
  });

  const handleOnKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      formik.submitForm();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Input
        name="username"
        type="username"
        label={t('label.username')}
        placeholder={t('label.username')}
        onChange={formik.handleChange}
        value={formik.values.username}
      />

      <Input
        name="password"
        type="password"
        label={t('label.password')}
        placeholder={t('label.password')}
        onChange={formik.handleChange}
        onKeyDown={handleOnKeyDown}
        value={formik.values.password}
      />

      <div className="submit-container">
        <ButtonGroup>
          <Button
            loading={isLoading}
            type={'primary'}
            shape={'round'}
            onClick={formik.submitForm}
            icon={IconUtils.login}
          >
            {t('button.login')}
          </Button>

          {!props.hideCancel && (
            <NavLink href={SiteMap.index}>
              <Button type="default" shape={'round'}>
                {t('button.back')}
              </Button>
            </NavLink>
          )}
        </ButtonGroup>
      </div>

      {!props.hideForgotPassword && (
        <>
          <Divider />

          <div style={{ textAlign: 'center' }}>
            <NavLink href={SiteMap.auth.forgot_password}>
              <Button type="link">{t('button.forgot_password')}</Button>
            </NavLink>

            <NavLink href={SiteMap.register.index}>
              <Button type="link">{t('button.register')}</Button>
            </NavLink>
          </div>
        </>
      )}
    </FormikProvider>
  );
};

export default LoginAdminForm;
