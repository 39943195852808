import styled from 'styled-components';

export const ExerciseToolbarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  background: #ccc;
  padding: 0.5em 1em;
  border-bottom: 1px solid #ccc;
  z-index: 2;

  .header-group {
    display: flex;
    align-items: center;

    .ant-btn {
      margin-right: 1em;
    }

    .ant-typography {
      margin-bottom: 0;
    }
  }

  .right-group {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: right;

    > * {
      margin-left: 0.75em;
    }
  }
`;
