import { StartOverButton } from '@cms/lesson-template/components/buttons/StartOverButton';
import { CheckAnswerButton } from '@cms/lesson-template/components/buttons/CheckAnswerButton';
import React from 'react';

export const SelfCheckBtn = () => {
  return (
    <>
      <StartOverButton />
      <CheckAnswerButton />
    </>
  );
};
