import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import {
  UserSubscriptionAPI,
  UserSubscriptionRes,
} from '@services/private/UserSubscriptionAPI';
import { RequestStatus } from '@components/status/EntityStatus';
import Button from '@components/button';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ViewUserRequest } from '../component/user-request/ViewUserRequest';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { InputGroup } from '@components/input/InputGroup';

export enum UserRequestActionEnum {
  default = '',
  view = 'view',
}

export function UserRequestManagementTable() {
  const { t } = useTranslation();

  const [findAll, { data, isFetching }] =
    UserSubscriptionAPI.endpoints.findAll.useLazyQuery();

  const [action, setAction] = useState({
    action: UserRequestActionEnum.default,
    params: -1,
  });

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
  });

  useEffect(() => {
    findAll(params);
  }, []);

  const columns = [
    {
      title: t('label.firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: t('label.lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: t('label.email'),
      dataIndex: 'email',
      key: 'email',
      width: 180,
      sorter: true,
    },
    {
      title: t('label.phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 180,
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      defaultSortOrder: 'ascend',
      width: 150,
      sorter: true,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      align: 'center',
      width: 120,
    },
  ];

  const showHistory = (userSubscriptionId: number) => {
    setAction({
      action: UserRequestActionEnum.view,
      params: userSubscriptionId,
    });
  };

  const renderMobile = (item: UserSubscriptionRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <div className={'user-request-display'}>
          <InputGroup
            label={
              <>
                {t('label.firstName')} {t('label.lastName')}
              </>
            }
          >
            {item.firstName} {item.lastName}
          </InputGroup>

          <InputGroup label={t('label.email')}>{item.email}</InputGroup>

          <InputGroup label={t('label.phone')}>{item.phoneNumber}</InputGroup>

          <InputGroup label={t('label.status')}>
            <Button
              type={'default'}
              onClick={() => showHistory(item.userSubscriptionId)}
            >
              <RequestStatus status={item.status} />
            </Button>
          </InputGroup>
        </div>
      </Card>
    );
  };

  const processDataRow = (item: UserSubscriptionRes) => {
    return {
      key: item.userSubscriptionId,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      status: (
        <Button
          type={'text'}
          onClick={() => showHistory(item.userSubscriptionId)}
        >
          <RequestStatus status={item.status} />
        </Button>
      ),
    };
  };

  const refreshData = (params: any) => {
    setParams(params);
    findAll(params);
  };

  const reloadData = () => {
    handleOnCancel();
    findAll(params);
  };

  const handleOnCancel = () => {
    setAction({
      action: UserRequestActionEnum.default,
      params: -1,
    });
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === UserRequestActionEnum.view && action.params > 0 && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('user_requests.actions.view_request')}
          content={
            <ViewUserRequest
              userSubscriptionId={action.params}
              onSuccess={reloadData}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
