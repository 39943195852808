import React, { useEffect } from 'react';
import { Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReviewComposeLesson } from '@cms/lesson-template/ReviewComposeLesson';
import { useViewLesson } from '@cms/lesson-template/hook/useLessonView';
import { LessonRes } from '@modules/product/services/lesson_model';

const { Paragraph, Text } = Typography;

export const LessonReview = (props: {
  lessonId: number;
  productId?: number;
  productTocId?: number;
  onLoadLesson?: (item: LessonRes) => void;
}) => {
  const {
    lessonData: data,
    isSuccess,
    error,
    isError,
  } = useViewLesson(props.lessonId, props.productId, props.productTocId);

  useEffect(() => {
    if (data && isSuccess && props.onLoadLesson) {
      props.onLoadLesson(data);
    }
  }, [data]);

  return (
    <>
      {data && isSuccess && (
        <>
          <ReviewComposeLesson
            data={data}
            productId={props.productId}
            productTocId={props.productTocId}
          />
        </>
      )}
      {isError && error && <ViewLessonError />}
    </>
  );
};

export function ViewLessonError() {
  const { t } = useTranslation();

  return (
    <Result
      status="error"
      title={t('lesson.warning.access_error')}
      subTitle={t('lesson.warning.access_error_message')}
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            {t('lesson.warning.access_error_explain')}
          </Text>
        </Paragraph>

        <div>
          <Paragraph>
            <Text>{t('lesson.warning.warning_access_direction_1')}</Text>
          </Paragraph>

          <Paragraph>
            <Text>{t('lesson.warning.warning_access_direction_2')}</Text>
          </Paragraph>

          <Paragraph>
            <Text>{t('lesson.warning.warning_access_direction_3')}</Text>
          </Paragraph>

          <Paragraph>
            <Text>{t('lesson.warning.warning_access_direction_4')}</Text>
          </Paragraph>
        </div>
      </div>
    </Result>
  );
}
