import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import {
  CreateProgramReq,
  ProgramRes,
  UpdateProgramReq,
} from '@modules/admin/service/program';

export const ProgramAPI = createApi({
  reducerPath: 'ProgramAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<ProgramRes>, PaginationReq>({
      query: (params) => ({
        url: `/programs`,
        method: 'GET',
        params,
      }),
    }),

    findById: builder.query<ProgramRes, unknown>({
      query: (id) => ({
        url: `/programs/${id}`,
        method: 'GET',
      }),
    }),

    create: builder.mutation<ProgramRes, CreateProgramReq>({
      query: (body) => ({
        url: '/programs',
        body,
        method: 'POST',
      }),
    }),

    update: builder.mutation<ProgramRes, UpdateProgramReq>({
      query: (body) => ({
        url: `/programs/${body.programId}`,
        method: 'PUT',
        body,
      }),
    }),

    disabled: builder.mutation<ProgramRes, { programId: number }>({
      query: (body) => ({
        url: `/programs/${body.programId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
