import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { CompMode } from '@cms/ComponentInteface';
import React, { ReactNode } from 'react';

export const ComposeModeContent = (props: { children: ReactNode }) => {
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};
