import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { useRouter } from '@hooks/useRouter';
import { UserCertificateDisplay } from '@modules/admin/container/UserCertificateDisplay';
import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';

const ViewCertificate = () => {
  const router = useRouter();

  const { code } = router.query as unknown as {
    code: string;
  };

  return (
    <PublicLayout className={'view-certificate-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_COURSE}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'certificate'}
        keywords={''}
        description={''}
      />

      <PublicPageContainer>
        <UserCertificateDisplay shareable={false} code={code} />
      </PublicPageContainer>
    </PublicLayout>
  );
};

export default ViewCertificate;
