import React, { useEffect, useMemo, useState } from 'react';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import {
  ClassFilterReq,
  ClassResourceRes,
} from '@modules/users/services/clazz';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import Table from '@components/table/Table';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import styled from 'styled-components';
import { DisplayCheckBox } from '@components/input/SimpleCheckBox';
import { IconUtils } from '@utils/IconUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ProductScoreReport } from '@modules/reports/product/ProductScoreReport';
import Button from '@components/button';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { AssignProductLicenseForUser } from '@modules/license/components/AssignProductLicenseForUser';
import { UserRes } from '@modules/users/services/model';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ProductRes } from '@modules/product/services/product_model';

const product_thumbnail_width = 110;
const product_name_width = 250;
const user_width = 100;

enum ClassStudentScoreAction {
  default = '',
  view_report = 'view-report',
  assign_license = 'assign-license',
}

export const ClassStudentProgressReport = (props: { classId: number }) => {
  const { t } = useTranslation();
  const { ifAnyGranted } = useSecurity();

  const [getStudentInClass, { data: studentData, isFetching }] =
    ClassAPI.endpoints.getStudentInClass.useLazyQuery({});

  const [
    getClassResource,
    { data: resourceData, isFetching: isResourceFetching },
  ] = ClassAPI.endpoints.getClassResource.useLazyQuery({});

  const [params] = useState<ClassFilterReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    classId: props.classId,
    selectAll: true,
  });

  const [action, setAction] = useState<{
    action: ClassStudentScoreAction;
    params: { product: ProductRes | undefined; user: UserRes | undefined };
  }>({
    action: ClassStudentScoreAction.default,
    params: { product: undefined, user: undefined },
  });

  useEffect(() => {
    getClassResource(params);
    getStudentInClass(params);
  }, [props.classId]);

  const [sourceData, setSourceData] = useState<any[]>([]);

  const tableColumns: any[] = useMemo(() => {
    const columns: any[] = [];
    columns.push({
      title: 'Thumbnails',
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      fixed: 'left',
      sort: true,
      width: product_thumbnail_width,
    });

    columns.push({
      title: 'Name',
      width: product_name_width,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sort: true,
    });

    if (studentData) {
      studentData.forEach((st) => {
        columns.push({
          title: <UserThumbnailDisplay layout={'column'} size={32} item={st} />,
          width: user_width,
          dataIndex: 'student' + st.userId,
          key: 'student' + st.userId,
          align: 'center',
        });
      });
    }

    return columns;
  }, [studentData]);

  const handleOnClick = (
    hasLicense: boolean,
    user: UserRes,
    product: ProductRes
  ) => {
    if (hasLicense) {
      setAction({
        action: ClassStudentScoreAction.view_report,
        params: { product: product, user: user },
      });
    } else {
      if (ifAnyGranted([RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL])) {
        setAction({
          action: ClassStudentScoreAction.assign_license,
          params: { product: product, user: user },
        });
      }
    }
  };

  const onCancel = () => {
    setAction({
      action: ClassStudentScoreAction.default,
      params: { product: undefined, user: undefined },
    });
  };

  useEffect(() => {
    if (studentData && resourceData) {
      const _sourceData = resourceData.content.map((res: ClassResourceRes) => {
        const record: Record<string, any> = {
          thumbnails: (
            <ThumbnailsImg
              type={'icon'}
              src={res.product.thumbnails}
              ratio={ImageScale.product.ratio}
              width={90}
            />
          ),
          name: res.product.name,
        };

        studentData.forEach((st) => {
          const hasLicense = res.hasLicenseIds.includes(st.userId);

          record['student' + st.userId] = (
            <DisplayCheckBox
              onClick={() => handleOnClick(hasLicense, st, res.product)}
              tooltip={{
                checked: 'Product Report',
                unchecked: '',
                empty: t('license.actions.assign_license'),
              }}
              icon={{
                checked: IconUtils.actions.report,
                unchecked: IconUtils.menu.license,
                empty: IconUtils.menu.license,
              }}
              value={hasLicense === true ? true : null}
            />
          );
        });

        return record;
      });
      setSourceData(_sourceData);
    }
  }, [studentData, resourceData]);

  return (
    <ClassLicenseReportStyle className={'class-student-score-report'}>
      <p>Progress Report thể hiện tiến độ học hành của từng học sinh.</p>

      <p>
        Các report hiển thị ở đây là các quyển sách được đưa vào chương trình
        học
      </p>

      {sourceData.length === 0 ? (
        <>
          <p>Lớp học chưa có chương trình học.</p>
        </>
      ) : (
        <Table
          className={'class-student-score-table'}
          columns={tableColumns}
          dataSource={sourceData}
          loading={isFetching || isResourceFetching}
          scroll={{
            x:
              product_thumbnail_width +
              product_name_width +
              (studentData?.length ?? 1) * user_width,
          }}
          pagination={false}
        />
      )}

      {action.action === ClassStudentScoreAction.view_report &&
        action.params.user &&
        action.params.product && (
          <CustomModal
            header={'View Product Report'}
            className={CustomModalClassEnum.full_size_modal}
            content={
              <>
                <ProductScoreReport
                  userId={action.params.user.userId}
                  productId={action.params.product.productId}
                />
                <ButtonGroup className={'submit-container'} type={'center'}>
                  <Button
                    shape={'round'}
                    type={'default'}
                    size={'large'}
                    onClick={onCancel}
                  >
                    {t('button.close')}
                  </Button>
                </ButtonGroup>
              </>
            }
            onCloseFunc={onCancel}
          />
        )}

      {action.action === ClassStudentScoreAction.assign_license &&
        action.params.user &&
        action.params.product && (
          <CustomModal
            header={t('license.actions.assign_license')}
            className={CustomModalClassEnum.large_modal}
            content={
              <AssignProductLicenseForUser
                user={action.params.user}
                product={action.params.product}
                onSuccess={onCancel}
              />
            }
            onCloseFunc={onCancel}
          />
        )}
    </ClassLicenseReportStyle>
  );
};

const ClassLicenseReportStyle = styled.div`
  .class-student-score-table {
    .user-n-thumbnails {
      align-items: center;

      .user-display-name {
        font-weight: normal;
        text-transform: none;
        margin-top: 0.5em;
      }
    }
  }
`;
