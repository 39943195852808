import { ResponsiveScreen } from '@hooks/useMedia';

const inch_to_px = 96;
const pt_to_px = 1.3333333333333333;

const percent = (pc: number) => {
  return pc * 100 + '%';
};

export const A4Size = {
  width: 8.3 * inch_to_px,
  height: 11.7 * inch_to_px,
  fontSize: Math.round(12 * pt_to_px),

  padding: `${percent(0.25 / 8.3)} ${percent(0.5 / 8.3)} ${percent(
    0.25 / 8.3
  )} ${percent(1 / 8.3)}`,

  paddingLS: `${percent(0.5 / 8.3)} ${percent(0.25 / 8.3)} ${percent(
    1 / 8.3
  )} ${percent(0.25 / 8.3)}`,
};

export const GameSize = {
  width: 800,
  height: 450,
  fontSize: Math.round(16),
  padding: `${percent(0.25 / 8.3)} `,
};

export const DEFAULT_SIZE = {
  width: 595,
  height: 842,
  objectBank: 480,
};

export const LessonTemplateUtil = {
  A4: {
    getStyle: (containerWidth: number, screen: ResponsiveScreen) => {
      if (screen === ResponsiveScreen.xs || screen === ResponsiveScreen.sm) {
        return {
          fontSize: 12,
          padding: '12px 12px',
        };
      } else {
        const scale = containerWidth / A4Size.width;

        const fontSize = scale * A4Size.fontSize;
        const padding = `${percent(0.25 / 8.3)} ${percent(
          0.75 / 8.3
        )} ${percent(0.25 / 8.3)} ${percent(1 / 8.3)}`;

        return {
          fontSize: fontSize,
          padding: padding,
        };
      }
    },
  },

  FULL_SCREEN: {
    getStyle: (containerWidth: number, screen: ResponsiveScreen) => {
      if (screen === ResponsiveScreen.xs || screen === ResponsiveScreen.sm) {
        return {
          fontSize: 12,
          padding: '12px 12px',
        };
      } else {
        return {
          fontSize: 16,
          padding: '16px 32px',
        };
      }
    },
  },

  AUTO_SCALE: {
    getStyle: (containerWidth: number) => {
      const defaultFontSize = 16;
      const defaultWidth = 1100;
      const scale = containerWidth / defaultWidth;

      return {
        fontSize: defaultFontSize * scale,
      };
    },
  },

  GAME: {
    getStyle: (width: number) => {
      // 8.3 * inch_to_px = Math.round(12 * pt_to_px)
      // width            =            x ?
      // => x = (width * Math.round(12 * pt_to_px)) / 8.3
      const fontSize = (width * GameSize.fontSize) / GameSize.width;
      const padding = GameSize.padding;

      return {
        fontSize: fontSize,
        padding: padding,
      };
    },
  },

  A4_Landscape: {
    getStyle: (width: number) => {
      // 8.3 * inch_to_px = Math.round(12 * pt_to_px)
      // width            =            x ?
      // => x = (width * Math.round(12 * pt_to_px)) / 8.3
      const fontSize = (width * A4Size.fontSize) / A4Size.height;
      const padding = A4Size.padding;
      return {
        fontSize: fontSize,
        padding: padding,
      };
    },
  },
};

export function LessonUtils() {}
