import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

export const TextAreaEditable = (props: {
  initValue: string;
  placeHolder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  style?: any;
}) => {
  const textAreaRef = useRef(null);
  const [latestText, setLatestText] = useState(props.initValue);
  const inputText = useRef(props.initValue);

  useEffect(() => {
    inputText.current = props.initValue;
    setLatestText(props.initValue);
  }, [props.initValue]);

  const handleChange = (innerHTML: any) => {
    if(innerHTML){
      inputText.current = innerHTML;
    }
  };

  const handleBlur = (innerHTML: string) => {
    if (innerHTML !== latestText) {
      props.onChange(innerHTML);
    }
  };

  const pasteAsPlainText = (event: any) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  return (
    <TextAreaEditableStyle
      className={'cms-content-editable'}
      style={props.style ? props.style : {}}
    >
      {props.placeHolder && !props.initValue && (
        <div className={'text-place-holder'}>{props.placeHolder}</div>
      )}

      {props.disabled ? (
        <div dangerouslySetInnerHTML={{ __html: props.initValue }} />
      ) : (
        <ContentEditable
          html={inputText.current}
          ref={textAreaRef}
          onPaste={pasteAsPlainText}
          onChange={(evt: any) => handleChange(evt.target.innerHTML)}
          onBlur={(evt: any) => handleBlur(evt.target.innerHTML)}
        />
      )}
    </TextAreaEditableStyle>
  );
};

const TextAreaEditableStyle = styled.div`
  position: relative;

  div[contenteditable] {
    border: none;

    &:focus {
      outline: none;
    }
  }

  textarea {
    width: 100%;
    resize: vertical;
    border: none;
    height: 1.5em;

    &:focus {
      outline: none;
      border: 1px solid #ccc;
    }
  }

  .text-place-holder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.component.disabled};
  }
`;
