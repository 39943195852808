import React, { useState } from 'react';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import Button from '@components/button';
import RequireLoginButton from '@components/button/RequireLoginButton';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { UnlockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ViewProductPrices } from '@modules/public/components/licenses/ViewProductPrices';
import { useTranslation } from 'react-i18next';
import { ProductRes } from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

enum ViewLessonAction {
  default = '',
  purchase_license = 'purchase_license',
}

export const PublicLessonDetail = (props: {
  lesson: LessonRes;
  productTocId: number;
  product: ProductRes;
  isFree: boolean;
}) => {
  const { t } = useTranslation();

  const [action, setAction] = useState({
    action: ViewLessonAction.default,
    timestamp: -1,
  });

  const getProductLessonLicenses = () => {
    setAction({
      action: ViewLessonAction.purchase_license,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnClose = () => {
    setAction({
      action: ViewLessonAction.default,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  return (
    <PublishLessonReviewStyle className={'lesson-review'}>
      <h1>{props.lesson.name}</h1>

      <div
        className={'lesson-instruction'}
        dangerouslySetInnerHTML={{ __html: props.lesson.instruction }}
      />

      <div className={'lesson-actions'}>
        {props.isFree ? (
          <Button size={'large'} shape={'round'} type={'primary'}>
            {t('label.view')}
          </Button>
        ) : (
          <RequireLoginButton
            size={'large'}
            shape={'round'}
            type={'primary'}
            onClick={getProductLessonLicenses}
          >
            <UnlockOutlined />{' '}
            {t('publish.lesson.spend_to_unlock', { price: props.lesson.price })}
          </RequireLoginButton>
        )}
      </div>

      {action.action === ViewLessonAction.purchase_license && (
        <CustomModal
          header={t('publish.lesson.purchase_license')}
          className={`${CustomModalClassEnum.large_modal} ${CustomModalClassEnum.no_padding}`}
          content={
            <ViewProductPrices
              lessonId={props.lesson.lessonId}
              productId={props.product.productId}
              productTocId={props.productTocId}
              onClose={handleOnClose}
            />
          }
          onCloseFunc={handleOnClose}
        />
      )}
    </PublishLessonReviewStyle>
  );
};

const PublishLessonReviewStyle = styled.div``;
