enum day_of_week {
  sun = 0,
  mon = 1,
  tue = 2,
  wed = 3,
  thu = 4,
  fri = 5,
  sat = 6,
}

const useCalendarConfig = () => {
  const workSpec = [
    {
      daysOfWeek: [
        day_of_week.sun,
        day_of_week.mon,
        day_of_week.tue,
        day_of_week.wed,
        day_of_week.thu,
        day_of_week.fri,
        day_of_week.sat,
      ],
      startTime: '00:00',
      endTime: '23:59:59',
    },
    // {
    //   daysOfWeek: [day_of_week.sat],
    //   startTime: '07:00',
    //   endTime: '12:00',
    // },
  ];

  const workMin = workSpec
    .map((item) => item.startTime)
    .sort()
    .shift();

  const workMax = workSpec
    .map((item) => item.endTime)
    .sort()
    .pop();

  const workDays = [...workSpec.flatMap((item) => item.daysOfWeek)];

  const hideDays = [
    day_of_week.sun,
    day_of_week.mon,
    day_of_week.tue,
    day_of_week.wed,
    day_of_week.thu,
    day_of_week.fri,
    day_of_week.sat,
  ].filter((day) => !workDays.includes(day));

  return {
    workSpec,
    workMin,
    workMax,
    workDays,
    hideDays,
  };
};

export default useCalendarConfig;
