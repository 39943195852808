import React, { useEffect, useState } from 'react';
import { ImageContentProps } from '@cms/content/ContentType';
import { CmsImageViewer } from '@cms/content/ImageViewer';
import { Slider, Radio } from 'antd';
import styled from 'styled-components';
import { SliderMarks } from 'antd/lib/slider';
import {
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from '@ant-design/icons';

const ImageContentEditable = (props: {
  contentData: ImageContentProps;
  onChange: (value: ImageContentProps) => void;
}) => {
  const [size, setImageSize] = useState({
    size: 100,
    triggerChange: false,
  });

  const [align, setAlign] = useState<{
    align: 'left' | 'right' | 'center';
    triggerChange: boolean;
  }>({
    align: 'center',
    triggerChange: false,
  });

  useEffect(() => {
    setImageSize({
      size:
        props.contentData.width != null && props.contentData.width > 0
          ? props.contentData.width
          : 0,
      triggerChange: false,
    });
  }, [props.contentData]);

  useEffect(() => {
    if (size && size.triggerChange) {
      props.onChange({
        ...props.contentData,
        width: size.size,
        align: align.align,
      });
    }
  }, [size]);

  const onChange = (newValue: number, triggerChange: boolean) => {
    setImageSize({
      size: newValue,
      triggerChange: triggerChange,
    });
  };

  const onAlignChange = (newValue: 'left' | 'right' | 'center') => {
    setAlign({
      align: newValue,
      triggerChange: true,
    });
  };

  const alignOptions = [
    {
      label: (
        <>
          <AlignLeftOutlined />
        </>
      ),
      value: 'left',
    },
    {
      label: (
        <>
          <AlignCenterOutlined />
        </>
      ),
      value: 'center',
    },
    {
      label: (
        <>
          <AlignRightOutlined />
        </>
      ),
      value: 'right',
    },
  ];

  const marks: SliderMarks = {
    0: '0',
    10: '',
    20: '',
    30: '',
    40: '',
    50: '',
    60: '',
    70: '',
    80: '',
    90: '',
    100: '100',
  };

  return (
    <ImageEditableStyle className={'cms-image-editable'}>
      <div className={'image-content-toolbar'}>
        <Slider
          min={0}
          max={100}
          step={1}
          included={true}
          marks={marks}
          onChange={(e) => onChange(e, false)}
          onAfterChange={(e) => onChange(e, true)}
          value={size.size}
        />

        <Radio.Group
          options={alignOptions}
          onChange={(e) => onAlignChange(e.target.value)}
          value={align.align}
        />
      </div>

      <CmsImageViewer
        contentData={props.contentData}
        align={align.align}
        width={props.contentData.width}
      />
    </ImageEditableStyle>
  );
};

export default ImageContentEditable;

const ImageEditableStyle = styled.div`
  position: relative;
  font-size: 12px;

  .cms-image-viewer {
    outline: 1px solid #ccc;
  }

  .image-content-toolbar {
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.05);
    padding: 5px;

    position: absolute;
    left: 0;

    .ant-slider {
      flex-grow: 1;
      margin-right: 50px;
      min-width: 50px;
    }

    .ant-radio-group {
      min-width: 280px;
    }
  }
`;
