import React from 'react';
import { useRouter } from '@hooks/useRouter';
import ClassRoomPublic from '@modules/calendar/container/ClassRoomPublic';
import { useTranslation } from 'react-i18next';
import ComposeLessonLayout from '@components/template/compose-lesson-layout/ComposeLessonLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';

const ClassRoomPage = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { code, token } = router.query as unknown as {
    code: string;
    token: string;
  };

  return (
    <ComposeLessonLayout className={'class-room-layout'}>
      <PageHeader
        menu={NavigationMenuEnum.CALENDAR}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('class_room.title')}
        description={t('class_room.title')}
      />

      <ClassRoomPublic
        code={code != null ? code : ''}
        token={token != null ? token : ''}
      />
    </ComposeLessonLayout>
  );
};

export default ClassRoomPage;
