import React, { useEffect } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import { ImageScale } from '@utils/ImageScale';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import IconImage from '@components/images/IconImage';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { UserTeacherAPI } from '@modules/users/services/UserTeacherAPI';
import { RoleEnum } from '@app/redux/slices/roles';
import { ViewUserPermission } from '../permission/ViewUserPermission';

export const ViewTeacherInfo = (props: {
  schoolId?: number;
  teacherId: number;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getUserData, { data, isLoading }] =
    UserTeacherAPI.endpoints.getUserData.useLazyQuery();

  const [
    getUserPermission,
    { data: userPermission, isLoading: isPermissionLoading },
  ] = UserTeacherAPI.endpoints.getUserPermission.useLazyQuery({});

  useEffect(() => {
    if (props.teacherId != null && props.teacherId > 0) {
      getUserData(props.teacherId);
      getUserPermission({
        userId: props.teacherId,
        schoolId: props.schoolId,
      });
    }
  }, [props.teacherId]);

  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col flex={ImageScale.avatar.width + 24 + 'px'}>
          <InputGroup label={t('label.avatar')}>
            <InputValue type={'textarea'}>
              <IconImage
                src={data?.avatar}
                width={ImageScale.avatar.width}
                height={ImageScale.avatar.height}
              />
            </InputValue>
          </InputGroup>
        </Col>

        <Col flex="auto">
          <InputGroup label={t('label.username')}>
            <InputValue type={'textarea'} copyable={true}>
              {data?.username}
            </InputValue>
          </InputGroup>

          <InputGroup label={t('label.password')}>
            <InputValue type={'textarea'} copyable={true}>
              {data?.password}
            </InputValue>
          </InputGroup>

          <Divider />

          <InputGroup label={t('label.firstName')}>
            <InputValue type={'textarea'}>{data?.firstName}</InputValue>
          </InputGroup>

          <InputGroup label={t('label.lastName')}>
            <InputValue type={'textarea'}>{data?.lastName}</InputValue>
          </InputGroup>

          <InputGroup label={t('label.email')}>
            <InputValue type={'textarea'}>{data?.email}</InputValue>
          </InputGroup>

          <InputGroup label={t('label.phone')}>
            <InputValue type={'textarea'}>{data?.phone}</InputValue>
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <InputGroup label={'Permission'}>
            {userPermission && (
              <ViewUserPermission
                roles={RoleEnum.TEACHER}
                permissions={userPermission}
              />
            )}
          </InputGroup>

          <div className="submit-container">
            <ButtonGroup type={'right'}>
              <Button
                loading={isLoading || isPermissionLoading}
                type={'default'}
                shape={'round'}
                onClick={props.onCancel}
              >
                {t('button.close')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </>
  );
};
