import React from 'react';
import ManageClassPage from '@modules/users/pages/ManageClassPage';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const ManageClass = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-class-page'} selectUser={'teacher'}>
      <PageHeader
        menu={NavigationMenuEnum.USERS}
        subMenu={NavigationMenuEnum.USERS_CLASSES}
        title={t('classes.my_class')}
        description={t('classes.my_class')}
      />

      <ManageClassPage />
    </ManagementLayout>
  );
};

export default ManageClass;
