import { InputGroup } from '@components/input/InputGroup';
import { StandardSetTag } from '@modules/product/components/standards/StandardSetDisplay';
import { H4, H5 } from '@components/typography';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { Button, Dropdown, List } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React, { useState } from 'react';
import { StandardSetAction } from '@modules/product/container/standards/useManageStandardSet';
import { useTranslation } from 'react-i18next';
import { StandardItem } from './StandardItem';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';
import styled from 'styled-components';

export const StandardSetItem = (props: {
  item: StandardSetRes;
  onClick: (item: StandardSetRes, action: StandardSetAction) => void;
  onClickStandard: (
    std: StandardRes | null,
    action: StandardSetAction,
    stdSet: StandardSetRes
  ) => void;
}) => {
  const { t } = useTranslation();
  const [showStandards, setShowStandards] = useState(false);

  const handleClickStandard = (
    standard: StandardRes,
    action: StandardSetAction
  ) => {
    props.onClickStandard(standard, action, props.item);
  };

  const handleShowStandards = () => {
    if (props.item.standards.length > 0) {
      setShowStandards((prev) => {
        return !prev;
      });
    }
  };

  return (
    <StandardSetItemStyle className={'standard-set-item'}>
      <div className={'standard-set-info'}>
        <InputGroup label={<StandardSetTag item={props.item} />}>
          <H4>{props.item.name}</H4>
        </InputGroup>

        <InputGroup label={t('label.description')}>
          <LanguageContentTag multiple content={props.item.description} />
        </InputGroup>

        {props.item.standards.length === 0 ? (
          <H5
            onClick={() =>
              props.onClickStandard(
                null,
                StandardSetAction.add_standard,
                props.item
              )
            }
          >
            <span style={{ whiteSpace: 'normal' }}>
              {t('standard_set.warning.empty')}
            </span>
          </H5>
        ) : (
          <H5 onClick={handleShowStandards}>
            <span style={{ whiteSpace: 'normal' }}>
              {t('standard_set.warning.total_standard', {
                numb: props.item.standards.length,
              })}
            </span>
            &nbsp;
            {showStandards
              ? IconUtils.actions.move_up
              : IconUtils.actions.move_down}
          </H5>
        )}
      </div>

      {showStandards && (
        <List
          bordered
          size={'small'}
          dataSource={props.item.standards}
          renderItem={(std) => (
            <List.Item>
              <StandardItem item={std} onClick={handleClickStandard} />
            </List.Item>
          )}
        />
      )}

      <div className={'standard-set-actions'}>
        <Dropdown
          menu={{
            items: [
              {
                key: StandardSetAction.update,
                icon: IconUtils.actions.edit,
                label: t('standard_set.actions.edit'),
                onClick: () =>
                  props.onClick(props.item, StandardSetAction.update),
              },
              {
                key: StandardSetAction.delete,
                icon: IconUtils.actions.delete,
                label: t('standard_set.actions.remove'),
                onClick: () =>
                  props.onClick(props.item, StandardSetAction.delete),
              },
              {
                key: 'divider-1',
                type: 'divider',
              },
              {
                key: StandardSetAction.add_standard,
                icon: IconUtils.lesson.standards,
                label: t('standard_set.actions.add_standard'),
                onClick: () =>
                  props.onClick(props.item, StandardSetAction.add_standard),
              },
            ],
          }}
          arrow
          trigger={['click']}
        >
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      </div>
    </StandardSetItemStyle>
  );
};

const StandardSetItemStyle = styled.div`
  position: relative;
  width: 100%;

  .standard-set-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
