import React, { useEffect } from 'react';

import { Button, Col, Divider, Row } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { InputGroup, InputValue } from '@components/input/InputGroup';

import SingleUpload from '@components/input/SingleUpload';
import { ImageScale } from '@utils/ImageScale';
import IconImage from '@components/images/IconImage';
import { userNameRegex } from '@modules/authorization/container/RegisteUserForm';
import { CreateUserReq } from '@modules/users/services/StudentAPI';
import { UserRes } from '@modules/users/services/model';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';

export const CreateUserForm = (props: {
  schoolId?: number;
  userId?: number;
  loading: boolean;
  userData: UserRes | null;
  onSubmit: (value: CreateUserReq) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.userData) {
      restoreUserData(props.userData);
    }
  }, [props.userData]);

  const getValidation = () => {
    if (props.userId != null && props.userId > 0) {
      yup.object().shape({
        firstName: yup.string().required(t('validation.required.field')),
        lastName: yup.string().required(t('validation.required.field')),
        phone: yup.string(),
      });
    } else {
      return yup.object().shape({
        username: yup
          .string()
          .required(t('registration.required.email.valid'))
          .matches(userNameRegex, 'Please enter valid username'),
        password: yup.string().required(t('registration.required.password')),
        repeatPassword: yup
          .string()
          .required(t('registration.required.repeat_password'))
          .oneOf([yup.ref('password')], t('errors.password_must_match')),

        firstName: yup.string().required(t('validation.required.field')),
        lastName: yup.string().required(t('validation.required.field')),

        phone: yup.string(),
      });
    }
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      schoolId:
        props.schoolId != null && props.schoolId > 0 ? props.schoolId : -1,
      userId: -1,
      username: '',
      password: '',
      repeatPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      avatar: '',
    },

    validationSchema: getValidation(),

    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  const restoreUserData = (data: UserRes) => {
    formik.setFieldValue('userId', data.userId);
    formik.setFieldValue('username', data.username);
    formik.setFieldValue('email', data.email);
    formik.setFieldValue('firstName', data.firstName);
    formik.setFieldValue('lastName', data.lastName);
    formik.setFieldValue('phone', data.phone);
    formik.setFieldValue('avatar', data.avatar);
    //
    // if (data.owner) {
    //   formik.setFieldValue('password', data.password);
    // }
  };

  const handleOnFileUploaded = (uploadedFile: string) => {
    formik.setFieldValue('avatar', uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err when upload file....');
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col flex={ImageScale.avatar.width + 24 + 'px'}>
            <InputGroup label={t('label.avatar')}>
              <SingleUpload
                scale={ImageScale.avatar.scale}
                accept={'image/jpg, image/jpeg, image/png'}
                multiple={false}
                onSuccessFunc={handleOnFileUploaded}
                onErrorFunc={handleOnUploadHasErr}
              >
                <IconImage
                  src={formik.values.avatar}
                  width={ImageScale.avatar.width}
                  height={ImageScale.avatar.height}
                />
              </SingleUpload>
            </InputGroup>
          </Col>

          <Col flex="auto">
            {props.userId != null && props.userId > 0 ? (
              <InputGroup label={t('label.username')}>
                <InputValue type={'textarea'}>
                  {formik.values.username}
                </InputValue>
              </InputGroup>
            ) : (
              <Input
                required
                name="username"
                type="text"
                label={t('label.username')}
                placeholder={t('form.enter_user_name')}
                onChange={formik.handleChange}
                value={formik.values.username}
              />
            )}

            <div className={'form-group'}>
              {!props.userId && (
                <>
                  <Input
                    required
                    name="password"
                    type="password"
                    label={t('label.password')}
                    placeholder={t('form.enter_password')}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />

                  <Input
                    required
                    name="repeatPassword"
                    type="password"
                    label={t('label.password_again')}
                    placeholder={t('form.enter_password_again')}
                    onChange={formik.handleChange}
                    value={formik.values.repeatPassword}
                  />
                </>
              )}

              <Divider />

              <Input
                required
                name="firstName"
                type="text"
                label={t('label.firstName')}
                placeholder={t('label.firstName')}
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />

              <Input
                required
                name="lastName"
                type="text"
                label={t('label.lastName')}
                placeholder={t('label.lastName')}
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />

              <Input
                name="email"
                type="text"
                label={t('label.email')}
                placeholder={t('label.email')}
                onChange={formik.handleChange}
                value={formik.values.email}
              />

              <Input
                name="phone"
                type="text"
                label={t('label.phone')}
                placeholder={t('label.phone')}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />

              <ButtonGroup className="submit-container" type={'right'}>
                <Button
                  type={'default'}
                  size={'large'}
                  shape={'round'}
                  onClick={props.onCancel}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  type={'primary'}
                  size={'large'}
                  shape={'round'}
                  loading={props.loading}
                  onClick={formik.submitForm}
                  icon={IconUtils.actions.save}
                >
                  {props.userId != null && props.userId > 0
                    ? t('button.update')
                    : t('button.create')}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </FormikProvider>
    </>
  );
};
