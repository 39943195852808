import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { CompMode } from '@cms/ComponentInteface';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { PageHeader } from '@app/header/PageHeader';

const ViewCollection = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: exerciseId } = router.query as unknown as { id: string };

  return (
    <EmptyLayout className={'view-collection-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_LESSON}
        subMenu={NavigationMenuEnum.MY_LESSON_PRACTICE}
        title={t('lesson.actions.review')}
        description={t('lesson.actions.review')}
      />
      <ViewExercisePage
        type={CompMode.DO_EXERCISE}
        exerciseId={Number(exerciseId)}
        productTocId={-1}
        productId={-1}
      />
    </EmptyLayout>
  );
};

export default ViewCollection;
