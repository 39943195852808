import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, notification, Row, Skeleton } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { InputGroup } from '@components/input/InputGroup';
import ColorCircle from '@components/input/ColorCircle';
import ColorPickerInput from '@components/input/ColorPicker';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { useTheme } from '@app/styled/StyledProvider';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { SelectTeacher } from '@components/select/SelectTeacher';
import { useSelectUser } from '@hooks/useSelectUser';
import { ClassRes, ViewClassRes } from '@modules/users/services/clazz';
import { SelectCertificate } from '@modules/admin/component/SelectCertificate';
import { SelectProgram } from '@modules/admin/component/SelectProgram';

export function ClassEditForm(props: {
  classId?: number;
  successHandle: (data: ClassRes) => void;
  onCloseFunc: () => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const selectTeacher = useSelectUser();

  const [getClassInfo, { data, isSuccess }] =
    ClassAPI.endpoints.getClassInfo.useLazyQuery();

  const [createClass, { isLoading: createLoading }] =
    ClassAPI.endpoints.createClass.useMutation({});
  const [updateClass, { isLoading: updateLoading }] =
    ClassAPI.endpoints.updateClass.useMutation({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.classId != null && props.classId > 0) {
      getClassInfo(props.classId);
    } else {
      setIsReady(true);
    }
  }, [props.classId]);

  useEffect(() => {
    if (data && isSuccess) {
      setClassData(data);
      setIsReady(true);
    }
  }, [data]);

  const setClassData = (data: ViewClassRes) => {
    formik.setValues({
      code: data.code,
      name: data.name,
      description: data.description,
      color: data.color,

      mainTeacherId:
        data.mainTeacher != null && data.mainTeacher.userId > 0
          ? data.mainTeacher.userId
          : -1,
      secondaryTeacherId:
        data.secondTeacher != null && data.secondTeacher.userId > 0
          ? data.secondTeacher.userId
          : -1,

      programId: data.program?.programId ?? -1,
      certificateId: data.certificate?.certificateId ?? -1,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      code: '',
      name: '',
      description: '',
      color: theme.app.primary,
      mainTeacherId: selectTeacher,
      secondaryTeacherId: -1,
      programId: -1,
      certificateId: -1,
    },

    validationSchema: yup.object().shape({
      code: yup
        .string()
        .min(2, 'Must be exactly 2 digits')
        .max(3, 'Must be exactly 3 digits')
        .required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.classId != null && props.classId > 0) {
        const payload = {
          ...values,
          classId: props.classId,
        };

        updateClass(payload)
          .unwrap()
          .then((data) => {
            notification.success({
              message: t('class.warning.update_success'),
              description: t('class.warning.create_success_message'),
              placement: 'bottomRight',
            });
            props.successHandle(data);
          })
          .catch(() => {
            notification.error({
              message: t('class.warning.update_error'),
              description: t('class.warning.create_error_message'),
              placement: 'bottomRight',
            });
          });
      } else {
        const payload = { ...values };

        createClass(payload)
          .unwrap()
          .then((data) => {
            props.successHandle(data);

            notification.success({
              message: t('class.warning.create_success'),
              description: t('class.warning.create_success_message'),
              placement: 'bottomRight',
            });
          })
          .catch(() => {
            notification.error({
              message: t('class.warning.create_error'),
              description: t('class.warning.create_error_message'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col flex={72 + 24 + 'px'}>
              <ColorCircle
                className={'review-item'}
                color={formik.values.color}
              >
                {formik.values.code}
              </ColorCircle>
            </Col>

            <Col flex={'auto'}>
              <Row gutter={[Default_Gutter, Default_Gutter]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Input
                    required
                    name="code"
                    type="text"
                    label={t('label.code')}
                    placeholder={t('label.code')}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                  />
                </Col>

                <Col span={12}>
                  <InputGroup label={t('label.color')}>
                    <ColorPickerInput
                      value={formik.values.color}
                      onChange={(color) => formik.setFieldValue('color', color)}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Input
                required
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Input
                type={'textarea'}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                maxLength={512}
                style={{ minHeight: 120 }}
              />

              <Authorization
                type={'ifAnyGranted'}
                roles={[RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
              >
                <Divider plain>{t('class.actions.select_teacher')}</Divider>

                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <SelectTeacher
                      label={t('class.label.main_teacher')}
                      value={formik.values.mainTeacherId}
                      onChange={(val) =>
                        formik.setFieldValue('mainTeacherId', val)
                      }
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <SelectTeacher
                      label={t('class.label.secondary_teacher')}
                      value={formik.values.secondaryTeacherId}
                      onChange={(val) =>
                        formik.setFieldValue('secondaryTeacherId', val)
                      }
                    />
                  </Col>
                </Row>

                <Divider plain>Select Program</Divider>

                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <SelectProgram
                      label={'Select Program'}
                      value={formik.values.programId}
                      onChange={(val) => formik.setFieldValue('programId', val)}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <SelectCertificate
                      label={'Select Certificate'}
                      value={formik.values.certificateId}
                      onChange={(val) =>
                        formik.setFieldValue('certificateId', val)
                      }
                    />
                  </Col>
                </Row>
              </Authorization>
            </Col>
          </Row>

          <ButtonGroup className="submit-container" type={'right'}>
            <Button
              size={'large'}
              type="default"
              shape={'round'}
              onClick={props.onCloseFunc}
            >
              {t('button.cancel')}
            </Button>

            <Button
              size={'large'}
              type={'primary'}
              shape={'round'}
              loading={createLoading || updateLoading}
              onClick={formik.submitForm}
              icon={IconUtils.actions.save}
            >
              {props.classId != null && props.classId > 0
                ? t('button.update')
                : t('button.create')}
            </Button>
          </ButtonGroup>
        </FormikProvider>
      )}
    </>
  );
}
