export interface JsLibraryDataProps {
  id: string;
  name: string;
  url: string;
}

export const JsLib = {
  jquery: {
    id: 'jquery-lib',
    name: 'jquery',
    url: '/public/cms/components/library/jquery.min.js',
  },

  // jqueryTouch: {
  //   id: 'jquery-touch-lib',
  //   name: 'jqueryTouch',
  //   url: '/cms/components/library/jquery.ui.touch-punch.min.js',
  // },

  raphael: {
    id: 'raphael-lib',
    name: 'raphael',
    url: '/public/cms/components/library/raphael.min.js',
  },

  raphaelInlineEdit: {
    id: 'raphael-inline-edit-lib',
    name: 'raphaelInlineEdit',
    url: '/public/cms/components/library/_raphael.inline.edit.js',
  },

  annotation: {
    id: 'annotation-lib',
    name: 'annotation',
    url: '/public/cms/components/annotation/global.min.js',
  },

  guppy: {
    id: 'guppy_lib',
    name: 'guppy',
    url: '/public/math-equation/guppy.min.js',
  },

  guppy_osk: {
    id: 'guppy_osk_lib',
    name: 'guppyOsk',
    url: '/public/math-equation/guppy_osk.js',
  },

  math_equation: {
    id: 'math_equation_lib',
    name: 'mathEquation',
    url: '/public/math-equation/math-expressions.js',
  },

  highlight_text: {
    id: 'highlight_text_lib',
    name: 'highlightText',
    url: '/public/cms/components/highlight-text/highlight-text.js',
  },
};
