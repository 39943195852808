import React, { useEffect, useMemo, useState } from 'react';
import { ClassRes } from '@modules/users/services/clazz';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { CertificateDisplay } from '@modules/admin/component/CertificateDisplay';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { CertificateAPI } from '@modules/admin/service/CertificateAPI';
import TablePagination from '@components/table/TablePagination';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { UserDisplay } from '@components/avatar/UserDisplayName';
import { UserAvatar } from '@components/avatar/UserAvatar';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { Divider, Progress, Slider, Space } from 'antd';
import styled from 'styled-components';
import {
  CertificateRes,
  UserCertificateRes,
} from '@modules/admin/service/program';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { IssuesCertificateForm } from '@modules/admin/container/IssuesCertificateForm';
import { EntityStatusEnum } from '@services/model/common';
import { UserCertificateDisplay } from '@modules/admin/container/UserCertificateDisplay';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { UserRes } from '@modules/users/services/model';

const user_width = 90;
const user_label_width = 280;
const score_width = 250;
const total_score = 250;
const action_width = 200;

enum ManageCertificateAction {
  defaut = '',
  publish = 'publish-certificate',
  share = 'share-certificate',
}

export const ManageClassCertificate = (props: {
  item: ClassRes;
  certificate: CertificateRes;
}) => {
  const { t } = useTranslation();

  const [getStudentInClass, { data, isFetching }] =
    ClassAPI.endpoints.getStudentInClass.useLazyQuery({});

  const [findById, { data: certificateData, isFetching: certificateFetching }] =
    CertificateAPI.endpoints.findById.useLazyQuery();

  const [getUserCertificate, { data: userCertificate }] =
    CertificateAPI.endpoints.getUserCertificate.useLazyQuery();

  const [createUserCertificate, { isLoading }] =
    CertificateAPI.endpoints.createUserCertificate.useMutation();

  const [action, setAction] = useState<{ action: string; params: any }>({
    action: ManageCertificateAction.defaut,
    params: -1,
  });

  const [certificateRecords, setCertificateRecords] = useState<
    Record<number, UserCertificateRes>
  >({});
  const [userScore, setUserScore] = useState<Record<string, number>>({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
  });

  const [sourceData, setSourceData] = useState<any[]>([]);

  useEffect(() => {
    if (props.item.classId && props.item.classId > 0) {
      getStudentInClass({ classId: props.item.classId, selectAll: true });
    }
  }, [props.item.classId]);

  useEffect(() => {
    if (props.certificate && props.certificate.certificateId) {
      findById(props.certificate.certificateId);
    }
  }, [props.certificate.certificateId]);

  useEffect(() => {
    if (props.item.classId > 0 && props.certificate.certificateId > 0) {
      getUserCertificate({
        classId: props.item.classId,
        certificateId: props.certificate.certificateId,
      });
    }
  }, [props.item.classId, props.certificate.certificateId]);

  useEffect(() => {
    if (userCertificate && userCertificate.length > 0) {
      const mapRecords: Record<number, UserCertificateRes> = {};
      userCertificate.forEach((res) => {
        mapRecords[res.user.userId] = res;
      });
      setCertificateRecords(mapRecords);
    }
  }, [userCertificate]);

  const onSelectUserScore = (userId: number) => {
    // if exited in db -> restore data...
    if (certificateRecords[userId] != null) {
      const userScore = getStudentCertificateScore(certificateRecords[userId]);
      setUserScore(userScore);
      // else -> set default data...
    } else {
      const userScore: Record<string, any> = { userId: userId };
      if (certificateData) {
        certificateData.scores?.forEach((scr) => {
          userScore[scr.certificateScoreId] = 0;
        });
      }
      setUserScore(userScore);
    }
  };

  const updateUserScore = (certificateScoreId: number, value: number) => {
    setUserScore((prev) => {
      const newVal = { ...prev };
      newVal[certificateScoreId] = value;
      return newVal;
    });
  };

  const onSaveScore = () => {
    const userId = userScore['userId'];
    const scores: any[] = [];

    let keys = Object.keys(userScore);
    keys.forEach((key) => {
      if (key !== 'userId') {
        scores.push({ certificateScoreId: Number(key), score: userScore[key] });
      }
    });

    createUserCertificate({
      userId: userId,
      classId: props.item.classId,
      certificateId: props.certificate.certificateId,
      scores: scores,
    })
      .unwrap()
      .then((res) => {
        setCertificateRecords((prev) => {
          const newVal = { ...prev };
          newVal[res.user.userId] = res;
          return newVal;
        });
        onCancelScore();
      })
      .catch(() => {
        alert('Cannot update');
      });
  };

  const onUpdateCertificateSuccess = (res: UserCertificateRes) => {
    setCertificateRecords((prev) => {
      const newVal = { ...prev };
      newVal[res.user.userId] = res;
      return newVal;
    });

    onCancel();
  };

  const onPublishCertificate = (user: UserRes) => {
    setAction({
      action: ManageCertificateAction.publish,
      params: user,
    });
  };

  const onShareCertification = (code: string) => {
    setAction({
      action: ManageCertificateAction.share,
      params: code,
    });
  };

  const onCancel = () => {
    setAction({
      action: '',
      params: -1,
    });
  };

  const onCancelScore = () => {
    setUserScore({});
  };

  useEffect(() => {
    const items: any[] = [];

    if (data != null && certificateData != null) {
      data.forEach((st) => {
        const userCertification = certificateRecords[st.userId];
        console.log(st.userId, userCertification);

        const studentScore = getStudentCertificateScore(
          certificateRecords[st.userId]
        );
        const isSelected = st.userId === userScore.userId;

        const rowData: Record<string, any> = {
          thumbnails: <UserAvatar src={st.avatar} size={60} />,
          name: <UserDisplay item={st} />,
        };

        if (isSelected) {
          let totalScore = 0;
          let maxScore = 0;
          certificateData.scores?.forEach((scr) => {
            const value = userScore[scr.certificateScoreId] ?? 0;

            totalScore += value;
            maxScore += scr.score;

            rowData['score' + scr.certificateScoreId] = (
              <ScoreInputStyle>
                <Slider
                  // tooltipVisible
                  min={0}
                  max={scr.score}
                  value={value}
                  onChange={(val) =>
                    updateUserScore(scr.certificateScoreId, val)
                  }
                />
                <span className={'score-label'}>
                  {Math.round((value * 100) / scr.score)}%
                </span>
              </ScoreInputStyle>
            );
          });

          if (maxScore === 0) {
            maxScore = 10;
          }

          rowData['totalScore'] = (
            <span className={'score-label'}>
              {Math.round((totalScore * 100) / maxScore)}%
            </span>
          );

          rowData['actions'] = (
            <Space align={'center'}>
              <Button
                type={'primary'}
                icon={IconUtils.actions.save}
                shape={'round'}
                onClick={onSaveScore}
                loading={isLoading}
              >
                Save
              </Button>

              <Button
                danger
                type={'default'}
                icon={IconUtils.actions.revert}
                shape={'circle'}
                onClick={onCancelScore}
              />
            </Space>
          );
        } else {
          let totalScore = 0;
          let maxScore = 0;

          certificateData.scores?.forEach((scr) => {
            const value = studentScore[scr.certificateScoreId];

            totalScore += value;
            maxScore += scr.score;

            rowData['score' + scr.certificateScoreId] = (
              <ScoreInputStyle>
                {value != null && value >= 0 ? (
                  <Progress
                    steps={10}
                    percent={Math.round((value * 100) / scr.score)}
                    format={(percent) => percent + '%'}
                  />
                ) : (
                  <span className={'score-label'}>
                    <b>N/A</b>
                  </span>
                )}
              </ScoreInputStyle>
            );
          });

          if (maxScore === 0) {
            maxScore = 10;
          }

          rowData['totalScore'] = (
            <span className={'score-label'}>
              <Progress
                steps={10}
                percent={Math.round((totalScore * 100) / maxScore)}
                format={(percent) => percent + '%'}
              />
            </span>
          );

          rowData['actions'] = (
            <Space align={'center'}>
              <Button
                type={'default'}
                icon={IconUtils.actions.edit}
                shape={'circle'}
                onClick={() => onSelectUserScore(st.userId)}
                disabled={userScore.userId != null && userScore.userId > 0}
              />

              <Divider type={'vertical'} />

              <Button
                disabled={userCertification == null}
                type={
                  userCertification != null &&
                  userCertification.status === EntityStatusEnum.PUBLISHED
                    ? 'primary'
                    : 'default'
                }
                icon={IconUtils.menu.certificate}
                shape={'circle'}
                onClick={() => onPublishCertificate(st)}
              />

              <Divider type={'vertical'} />

              <Button
                disabled={
                  userCertification == null ||
                  userCertification.status !== EntityStatusEnum.PUBLISHED
                }
                type={'default'}
                icon={IconUtils.actions.share}
                shape={'circle'}
                onClick={() => onShareCertification(userCertification.code)}
              />
            </Space>
          );
        }

        items.push(rowData);
      });
    }

    setSourceData(items);
  }, [data, certificateData, userScore, certificateRecords]);

  const tableColumns: any[] = useMemo(() => {
    const columns: any[] = [];
    columns.push({
      title: '#',
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      fixed: 'left',
      align: 'center',
      sort: true,
      width: user_width,
    });

    columns.push({
      title: 'Name',
      width: user_label_width,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sort: true,
    });

    if (certificateData && certificateData.scores) {
      certificateData.scores.forEach((st) => {
        columns.push({
          title: st.name,
          width: score_width,
          dataIndex: 'score' + st.certificateScoreId,
          key: 'score',
          align: 'center',
          sort: true,
        });
      });
    }

    columns.push({
      title: 'Overall',
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      width: total_score,
    });

    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: action_width,
    });

    return columns;
  }, [certificateData]);

  const onRefresh = (params: any) => {
    setParams(params);
  };

  return (
    <div className={'class-certificate'}>
      <HeaderGroup className={'mt-0'}>
        <H1>Class Certificate</H1>

        <CertificateDisplay className={'bordered'} item={props.certificate} />
      </HeaderGroup>

      <p>
        Sau khi hoàn thành khóa học, dựa vào các điều kiện dưới đây, học sinh có
        thể được cấp chứng chỉ.
      </p>

      <TablePagination
        className={'class-session-attend-table'}
        params={params}
        isLoading={isFetching || certificateFetching}
        refresh={onRefresh}
        columns={tableColumns}
        scroll={{
          x:
            user_width +
            user_label_width +
            (certificateData?.scores?.length ?? 1) * score_width +
            action_width,
        }}
        dataSource={sourceData}
        pagination={false}
      />

      {action.action === 'publish-certificate' && (
        <CustomModal
          header={'Issue Certificate'}
          className={CustomModalClassEnum.large_modal}
          content={
            <IssuesCertificateForm
              user={action.params}
              clazz={props.item}
              certificate={props.certificate}
              onSuccess={onUpdateCertificateSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}

      {action.action === 'share-certificate' && (
        <CustomModal
          header={'Certificate'}
          className={CustomModalClassEnum.large_modal}
          content={
            <>
              <UserCertificateDisplay shareable={true} code={action.params} />

              <ButtonGroup className="submit-container" type={'center'}>
                <Button
                  type="default"
                  size={'large'}
                  shape={'round'}
                  onClick={onCancel}
                >
                  {t('button.close')}
                </Button>
              </ButtonGroup>
            </>
          }
          onCloseFunc={onCancel}
        />
      )}
    </div>
  );
};

const getStudentCertificateScore = (
  studentScore: UserCertificateRes
): Record<string, number> => {
  const studentScoreMapping: Record<string, number> = {};

  if (studentScore != null) {
    studentScore.scores.forEach((scr) => {
      studentScoreMapping[scr.certificateScore.certificateScoreId] = scr.score;
    });

    studentScoreMapping['userId'] = studentScore.user.userId;
  }

  return studentScoreMapping;
};

const ScoreInputStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-slider {
    flex-grow: 1;
  }

  .score-label {
    margin-left: 10px;
  }
`;
