import React from 'react';
import { MenuProps } from 'antd';

export type MenuItem = Required<MenuProps>['items'][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  type?: 'group' | '',
  visible?: boolean
): MenuItem {
  if (visible == null || visible) {
    return {
      key,
      icon,
      children,
      label,
      type,
      visible,
    } as MenuItem;
  } else {
    return null;
  }
}

export interface ItemMenuProps {
  key: React.Key;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: ItemMenuProps[] | null;
  visible?: boolean;
}

export const getMenuItem = (props: ItemMenuProps): MenuItem => {
  if (props.visible == null || props.visible) {
    // check if has sub menu ---
    if (props.children != null && props.children.length > 0) {
      // filter visible children.
      const children = props.children.filter((child) => {
        return child.visible == null || child.visible;
      });

      // if multiple menu ->
      if (children.length > 1) {
        const childMenu = children.map((child) => {
          return getMenuItem(child);
        });

        return {
          key: props.key,
          icon: props.icon,
          label: props.label,
          children: childMenu,
        } as MenuItem;

        // if single menu -> replace menu by label = child label, icon = child icon.
      } else if (children.length === 1) {
        const childMenu = children[0];
        return {
          key: props.key,
          icon: childMenu.icon,
          label: childMenu.label,
        } as MenuItem;

        // if  empty => didn't show
      } else {
        return null;
      }
    } else {
      return {
        key: props.key,
        icon: props.icon,
        label: props.label,
      } as MenuItem;
    }
  } else {
    return null;
  }
};
