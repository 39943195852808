import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useSecurity } from '@utils/authorization';
import { useSelector } from '@app/redux/hook';
import { getMenuItem } from '@components/template/common/MenuUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import i18next from 'i18next';
import { useProUser } from '@components/button/RequireProUserButton';
import {
  BarsOutlined,
  DatabaseOutlined,
  ExceptionOutlined,
} from '@ant-design/icons';
import { HeaderMenu } from '@components/template/app-layout/header/header-menu/HeaderMenuDisplay';

const SchoolManagementMenu = () => {
  const { t } = useTranslation();

  const proUser = useProUser();
  const { ifAnyGranted } = useSecurity();
  const { authorities } = useSelector((state) => state.profile);

  const menuItems = useMemo(() => {
    return [
      getMenuItem({
        key: NavigationMenuEnum.PROGRAM_PROGRAM,
        label: (
          <NavLink href={SiteMap.program.program}>{t('menu.program')}</NavLink>
        ),
        icon: IconUtils.menu.program,
        visible: ifAnyGranted(SiteMap.program.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PROGRAM_CERTIFICATE,
        label: (
          <NavLink href={SiteMap.program.certificate}>
            {t('menu.certificate')}
          </NavLink>
        ),
        icon: IconUtils.menu.certificate,
        visible: ifAnyGranted(SiteMap.program.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PROGRAM_SUBJECT,
        label: (
          <NavLink href={SiteMap.program.subjects}>{t('menu.subject')}</NavLink>
        ),
        icon: <BarsOutlined />,
        visible: ifAnyGranted(SiteMap.program.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PROGRAM_CATEGORY,
        label: (
          <NavLink href={SiteMap.program.categories}>
            {t('menu.category')}
          </NavLink>
        ),
        icon: <DatabaseOutlined />,
        visible: ifAnyGranted(SiteMap.program.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PROGRAM_GRADE,
        label: (
          <NavLink href={SiteMap.program.grades}>{t('menu.grade')}</NavLink>
        ),
        icon: <ExceptionOutlined />,
        visible: ifAnyGranted(SiteMap.program.roles),
      }),
    ];
  }, [authorities, i18next.language, proUser]);

  return (
    <HeaderMenu
      group={NavigationMenuEnum.PROGRAM}
      groupIcon={IconUtils.menu.program}
      className={'product-group-menu'}
      menuItems={menuItems}
    />
  );
};

export default SchoolManagementMenu;
