import { PaginationReq } from '@services/model/PaginationRes';
import { CompAnswerProps } from '@cms/ComponentInteface';
import { ClassRes } from '@modules/users/services/clazz';
import { ComponentType } from 'react';
import { ResourceGradingProps } from '@app/redux/slices/viewResource';
import { ExerciseStandardRes } from '@services/reports/LessonReport';
import {
  AssignmentExpiredType,
  AssignmentInstanceRes,
} from '@modules/assignments/service/assignment_model';
import { UserInfoRes } from '@modules/users/services/model';
import { LessonRes } from '@modules/product/services/lesson_model';

export enum ExerciseStatus {
  NOT_START = 0,
  VIEWED = 1,
  IN_PROGRESS = 2,
  SUBMITTED = 3,
  COMPLETED = 4,
  NEED_GRADING = 5,
}

export enum ExerciseResponseStatusEnum {
  NOT_START = 0,
  IN_PROGRESS = 2,
  SUBMITTED = 3,
  COMPLETED = 4,
  NEED_GRADING = 5,
}

export interface ExerciseResourceAnswerReq {
  resourceId: number;
  answer: string | null;
}

export interface ExerciseResponseRes {
  exerciseResponseId: number;

  resourceId: number;
  sectionId: number;

  displayOrder: number;

  skip: boolean | null;

  duration: number;
  autoScore: boolean;
  score: number | null;
  maxScore: number;
  tryTimes: number;

  comment: string;

  status: ExerciseResponseStatusEnum;
  answer: CompAnswerProps[];
  result: ComponentResponseProps[];
}

export interface ExerciseRes {
  exerciseId: number;
  expiredType: AssignmentExpiredType;
  duration: number;
  unlockAt: string;
  lockAt: string;

  unlockInMs?: number;
  expiredInMs?: number;

  productId?: number;
  productTocId?: number;

  lesson: LessonRes;

  createdDate: string;
  modifiedDate: string;
  completedDate: string;

  resourceId?: number;
  sectionId?: number;

  status: ExerciseStatus;
  progress: number | null;
  score: number | null;
  maxScore: number | null;

  answers: ExerciseResponseRes[];

  assignmentInstance?: AssignmentInstanceRes;

  student?: UserInfoRes;
  clazz?: ClassRes;
}

export interface CreateExerciseReq {
  lessonId: number;
  productId?: number;
  productTocId?: number;
  getLatest?: boolean;
}

export interface UpdateExerciseStatusReq {
  exerciseId: number;
  sectionId: number;
  resourceId: number;
}

export interface UpdateExerciseAndAnswerReq extends UpdateExerciseStatusReq {
  answers: ExerciseResourceAnswerReq[];
}

export interface ComponentResponseProps {
  id: string;
  type: ComponentType;
  isSkip: boolean;
  autoScore: boolean;
  comment: string | null;
  score: number;
  maxScore: number;
  totalScore: number;
  correct: string[];
  incorrect: string[];
  correctAnswer: any | any[];
}

export interface LessonExerciseFilterReq {
  lessonId: number;
  productId?: number;
  productTocId?: number;
}

export interface GradeExerciseReq {
  exerciseId: number;
  grading: ResourceGradingProps[];
}

export interface SelfCheckExerciseReq {
  lessonId: number;
  answers: ExerciseResourceAnswerReq[];
}

export interface ShowCorrectAnswerReq {
  lessonId: number;
}

export interface ExerciseScoreOverallRes {
  exerciseId: number;

  lessonId: number;
  productId?: number;
  productTocId?: number;

  score: number;
  maxScore: number;
  scores: ExerciseStandardRes[];

  assignmentInstance?: AssignmentInstanceRes;
}

export interface UpdateExerciseCompletedDateReq {
  exerciseId: number;
  completedDate: string;
}

export interface FilterExerciseReportReq extends PaginationReq {
  lessonId: number;
}

export interface SavePracticeAnswerReq {
  exerciseId: number;
  resourceId: number;
  duration: number;
  answer: string;
  tryTimes: number;
  score: number;
  maxScore: number;
  submit: boolean;
}

export interface ViewExerciseResourceReq {
  exerciseId: number;
  sectionId: number;
  resourceId: number;
}

export interface CheckAnswerReq {
  exerciseId: number;
  sectionId: number;
  resourceId: number;

  duration: number;
  answer: string | null;
  submitted: boolean;
  tryTimes?: number;
}

export interface SelfCheckQuestionReq {
  lessonId: number;
  answers: CheckAnswerReq[];
}
