import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';

import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import ManageCourse from '@router/pages/management/account/manage_course';
import ManageClass from '@router/pages/management/account/manage_class';
import PublishCourse from '@router/pages/management/account/publish_course';
import CourseStudent from '@router/pages/management/account/view_course_students';
import ManageRequest from '@router/pages/management/account/invitation_request';
import { RoleEnum } from '@app/redux/slices/roles';
import TeacherManagement from '@router/pages/management/school/users/school_teacher';
import SchoolAdminManagement from '@router/pages/management/school/users/school_school_admin';
import StudentManagement from '@router/pages/management/school/users/school_student';
import ParentManagement from '@router/pages/management/school/users/school_parents';
import TeacherAdminManagement from '@router/pages/management/school/users/admin/school_teacher_admin';
import SchoolAdminAdminManagement from '@router/pages/management/school/users/admin/school_school_admin_admin';
import SchoolSupportAdminManagement from '@router/pages/management/school/users/admin/school_school_support_admin';
import StudentAdminManagement from '@router/pages/management/school/users/admin/school_student_admin';
import ParentAdminManagement from '@router/pages/management/school/users/admin/school_parent_admin';
import SchoolSupportManagement from '@router/pages/management/school/users/school_school_support';
import ManageSurvey from '@router/pages/survey/manage_course';
import ViewSurveyReport from '@router/pages/survey/view_survey_report';

export const ManageUserRouters: PageRouterProps[] = [
  {
    path: SiteMap.management.courses.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.courses.roles}
        element={<ManageCourse />}
      />
    ),
  },
  {
    path: SiteMap.management.courses.publish,
    element: (
      <PrivateRouter
        roles={SiteMap.management.courses.roles}
        element={<PublishCourse />}
      />
    ),
  },
  {
    path: SiteMap.management.courses.request,
    element: (
      <PrivateRouter
        roles={SiteMap.management.courses.roles}
        element={<ManageRequest />}
      />
    ),
  },
  {
    path: SiteMap.management.courses.students,
    element: (
      <PrivateRouter
        roles={SiteMap.management.courses.roles}
        element={<CourseStudent />}
      />
    ),
  },

  {
    path: SiteMap.survey.list,
    element: (
      <PrivateRouter roles={SiteMap.survey.roles} element={<ManageSurvey />} />
    ),
  },

  {
    path: SiteMap.survey.report,
    element: (
      <PrivateRouter
        roles={SiteMap.survey.roles}
        element={<ViewSurveyReport />}
      />
    ),
  },

  {
    path: SiteMap.management.classes.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.classes.roles}
        element={<ManageClass />}
      />
    ),
  },

  // .......................... STUDENTS ..........................
  {
    path: SiteMap.management.students.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.students.roles}
        element={<StudentManagement />}
      />
    ),
  },
  {
    path: SiteMap.management.students.admin,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN]}
        element={<StudentAdminManagement />}
      />
    ),
  },

  // .......................... PARENTS ..........................
  {
    path: SiteMap.management.parents.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.parents.roles}
        element={<ParentManagement />}
      />
    ),
  },
  {
    path: SiteMap.management.parents.admin,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN]}
        element={<ParentAdminManagement />}
      />
    ),
  },

  // .......................... TEACHERS ..........................
  {
    path: SiteMap.management.teachers.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.teachers.roles}
        element={<TeacherManagement />}
      />
    ),
  },
  {
    path: SiteMap.management.teachers.admin,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN]}
        element={<TeacherAdminManagement />}
      />
    ),
  },

  // .......................... SCHOOL SUPPORTS ..........................
  {
    path: SiteMap.management.supports.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.supports.roles}
        element={<SchoolSupportManagement />}
      />
    ),
  },

  {
    path: SiteMap.management.supports.admin,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN]}
        element={<SchoolSupportAdminManagement />}
      />
    ),
  },

  // .......................... SCHOOL ADMIN ..........................
  {
    path: SiteMap.management.school_admins.path,
    element: (
      <PrivateRouter
        roles={SiteMap.management.school_admins.roles}
        element={<SchoolAdminManagement />}
      />
    ),
  },

  {
    path: SiteMap.management.school_admins.admin,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN]}
        element={<SchoolAdminAdminManagement />}
      />
    ),
  },

  // manage users for admin

  // {
  //   path: SiteMap.management.students.invitations,
  //   element: (
  //     <PrivateRouter roles={SiteMap.management.students.roles} element={<ManageCourse />}
  //       />
  //       <ManageInvitation />
  //     </PrivateRouter>
  //   ),
  // },
];
