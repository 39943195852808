import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import {
  CompConfiguration,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { DropDownProps } from '@cms/comps/interact/dropdown/DropdownComp';

export const DropDownExampleData: DropDownProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.DROPDOWN,

  configuration: {
    sourceItems: [
      {
        label: '',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Choose Answer',
        },
      },
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
      },
      {
        label: 'd',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Grape',
        },
      },
    ],
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'This is ' + CompConfiguration.INLINE_COMPONENT + '.',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    onlyOne: true,
  },
};
