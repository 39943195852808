import React from 'react';
import styled from 'styled-components';

const SimpleSwitchStyle = styled.span`
  border: 1px solid;
  cursor: pointer;
  padding: 0.25em 1.25em;
  line-height: 1.6em;
  border-radius: 2em;

  display: inline-block;

  &.block {
    width: 100%;
  }

  &.active {
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.app.primary};
  }
`;

export function SimpleSwitch(props: {
  children: any;
  value: number;
  checked: boolean;
  onChange: (value: number, isChecked: boolean) => void;
}) {
  const handleOnChange = () => {
    props.onChange(props.value, !props.checked);
  };

  return (
    <SimpleSwitchStyle
      className={`simple-switch ${props.checked ? 'active' : ''}`}
      onClick={handleOnChange}
    >
      {props.children}
    </SimpleSwitchStyle>
  );
}
