import React, { useCallback, useContext, useEffect, useState } from 'react';

import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { GCalendarContext } from '@modules/calendar/context/CalendarContext';
import { useTranslation } from 'react-i18next';
import { CalendarEventProps } from '@modules/calendar/container/ManageCalendar';
import PublicSessionInfo from '@components/public/session/PublicSessionInfo';
import {
  CalendarEventRes,
  EventAPI,
  EventRes,
  EventTypeEnum,
} from '@services/event/EventAPI';
import styled from 'styled-components';
import { AssignmentInfo } from '@modules/calendar/container/IncomingEvents';
import dayjs from 'dayjs';
import { ServerDateAndTimeFormat } from '@utils/DateAndTimeUtils';
import MonthCalendar from '@components/calendar/MyCalendar';
import { IconUtilsSVG } from '@utils/IconUtils';

export default function UserCalendar(props: { classId?: number }) {
  const { t } = useTranslation();

  const { defaultDate, setDefaultDate } = useContext(GCalendarContext);
  const [events, setEvents] = useState<CalendarEventProps[]>([]);

  const [findAll, { data, isSuccess }] =
    EventAPI.endpoints.findAll.useLazyQuery({});

  const [selectedEvent, setSelectedEvent] = useState<CalendarEventProps | null>(
    null
  );

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setEvents(restructureEvents(data));
    }
  }, [data, isSuccess]);

  const refresh = useCallback(() => {
    if (props.classId != null && props.classId > 0) {
      findAll({ classId: props.classId });
    } else {
      findAll({});
    }
  }, [props.classId]);

  const handleEventClick = (info: any) => {
    const event = info.event._def.extendedProps as CalendarEventProps;
    setSelectedEvent(event);
  };

  const getEventClassNames = (event: any) => {
    if (event.type === EventTypeEnum.ASSIGNMENT) {
      return 'assignment-event' + event.clone ? ' template-event' : '';
    } else {
      return 'session-event';
    }
  };

  const eventRendering = (info: any) => {
    const event = info.event._def.extendedProps as CalendarEventProps;
    if (event.type === EventTypeEnum.SESSION) {
      return {
        html: `<div class="custom-event">${IconUtilsSVG.session}${info.event.title}</div>`,
      };
    } else {
      return {
        html: `<div class="custom-event">${IconUtilsSVG.assignment}${info.event.title}</div>`,
      };
    }
  };

  return (
    <StudentCalendarStyle className={'student-calendar'}>
      <MonthCalendar
        allDaySlot={true}
        defaultDate={defaultDate}
        events={events}
        getEventClass={getEventClassNames}
        eventClick={handleEventClick}
        goToDate={setDefaultDate}
        eventRendering={eventRendering}
      />

      {selectedEvent && (
        <>
          {selectedEvent.type === EventTypeEnum.SESSION &&
            selectedEvent.item &&
            selectedEvent.item.session && (
              <CustomModal
                header={t('session.header')}
                className={CustomModalClassEnum.large_modal}
                content={
                  <PublicSessionInfo item={selectedEvent.item?.session} />
                }
                onCloseFunc={() => setSelectedEvent(null)}
              />
            )}

          {selectedEvent.type === EventTypeEnum.ASSIGNMENT &&
            selectedEvent.items &&
            selectedEvent.items?.length > 0 && (
              <CustomModal
                header={t('assignment.title')}
                className={CustomModalClassEnum.full_size_modal}
                content={
                  <div className={'assignment-group'}>
                    {selectedEvent.items.map((res) => {
                      return (
                        <AssignmentInfo
                          key={res.eventId}
                          item={{
                            ...res,
                            startTime: res.startTimeOrg ?? res.endTime,
                            endTime: res.endTimeOrg ?? res.endTime,
                          }}
                        />
                      );
                    })}
                  </div>
                }
                onCloseFunc={() => setSelectedEvent(null)}
              />
            )}
        </>
      )}
    </StudentCalendarStyle>
  );
}

const getDayFromRange = (fromDate: string, toDate: string): string[] => {
  const from = dayjs(fromDate);
  const to = dayjs(toDate);

  if (fromDate.split('T')[0] === toDate.split('T')[0]) {
    return [fromDate];
  } else {
    let start = from;
    const dayRanger: string[] = [];

    while (start.isBefore(to)) {
      const dateFormat = start.format(ServerDateAndTimeFormat.DATE_N_TIME);

      if (dateFormat.split('T')[0] === toDate.split('T')[0]) {
        dayRanger.push(toDate);
      } else if (dateFormat.split('T')[0] === fromDate.split('T')[0]) {
        dayRanger.push(fromDate);
      } else {
        dayRanger.push(dateFormat);
      }
      start = start.add(1, 'day');
    }
    return dayRanger;
  }
};

const StudentCalendarStyle = styled.div`
  padding-right: 20px;
`;

export const structureEvent = (data: EventRes[]): CalendarEventProps[] => {
  return data.map((evt) => {
    if (evt.type === EventTypeEnum.SESSION) {
      return {
        title: evt.name,
        type: evt.type,
        start: evt.startTime,
        end: evt.endTime,
        clone: false,
        item: evt,
        items: [evt],
      };
    } else {
      return {
        title: evt.name,
        type: evt.type,
        start: evt.startTime,
        end: evt.endTime,
        clone: false,
        item: evt,
        items: [evt],
        display: 'background',
      };
    }
  });
};

export const restructureEvents = (data: EventRes[]): CalendarEventProps[] => {
  const listEvent: CalendarEventRes[] = [];

  data.forEach((item: EventRes) => {
    if (item.type === EventTypeEnum.SESSION) {
      listEvent.push(item);
    } else if (item.type === EventTypeEnum.ASSIGNMENT) {
      const daysFromRange: string[] = getDayFromRange(
        item.startTime,
        item.endTime
      );

      if (daysFromRange.length === 1) {
        listEvent.push({
          ...item,
          clone: false,
          startTime: item.startTime.split('T')[0],
          endTime: item.startTime.split('T')[0],

          startTimeOrg: item.startTime,
          endTimeOrg: item.endTime,

          allDay: true,
          stick: true,
        });
      } else {
        daysFromRange.forEach((date, index) => {
          listEvent.push({
            ...item,
            clone: index !== 0 && index !== daysFromRange.length,
            startTime: date,
            endTime: date,
            startTimeOrg: item.startTime,
            endTimeOrg: item.endTime,
          });
        });
      }
    }
  });

  const eventSorts = listEvent.sort((a, b) => {
    return dayjs(b.startTime).valueOf() - dayjs(a.startTime).valueOf();
  });

  const result: CalendarEventProps[] = [];
  let currentDate: string = '';
  let events: CalendarEventRes[] = [];

  eventSorts.forEach((item) => {
    if (item.type === EventTypeEnum.SESSION) {
      result.push({
        title: item.name,
        start: item.startTime,
        end: item.endTime,
        type: item.type,
        clone: false,
        item: item,
      });
    } else {
      const startTime = item.startTime.split('T')[0];

      if (currentDate.split('T')[0] !== startTime) {
        if (events.length > 0) {
          const clone = events.some((i) => {
            return i.clone;
          });
          result.push({
            title: events.length + ' assignments',
            type: item.type,
            clone: clone,
            start: currentDate,
            end: currentDate,
            items: events,
          });
        }
        currentDate = item.startTime;
        events = [item];
      } else {
        events.push(item);
      }
    }
  });

  if (events.length > 0) {
    const clone = events.some((i) => {
      return i.clone;
    });

    result.push({
      title: events.length + ' assignments',
      type: events[0].type,
      clone: clone,
      start: currentDate,
      end: currentDate,
      items: events,
    });
  }
  return result;
};
