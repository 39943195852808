export enum LongOperationLayoutEnum {
  horizontal = 'horizontal',
  vertical = 'vertical',
  place_value = 'place-value',
  place_value_block = 'place-value-block',
}

export enum MathOperationEnum {
  addition = 'addition',
  subtraction = 'subtraction',
  multiplication = 'multiplication',
  division = 'division',

  area_multiplication = 'area-multiplication',
  area_division = 'area-division',

  place_value = 'place-value',
}

export interface MathOperationProps {
  operation: MathOperationEnum;
  results: OperationExpProps[];
}
//
// export interface AreaOperationExpProps {
//   type: 'empty' | 'header' | 'factor';
//   exp: string;
// }

export class OperationSupportUtils {
  SPLIT_CHARACTER = '_';
  HIDDEN_CHARACTER = '_';
  VARIABLE_CHARACTER = 'x';
  TEMPLATE_CHARACTER = 'y';
  DECIMAL_CHARACTER = '.';
  PLACE_VALUE_CHARACTER = 'U';

  ADD_OPERATION = '+';
  SUB_OPERATION = '-';
  MUL_OPERATION = '*';
  DIV_OPERATION = '/';
  EQUAL_OPERATION = '=';

  DIV_REMAIN_OPERATION = 'R';

  createStringChars = (char: string, numb: number) => {
    return getStringByChar(char, numb);
  };

  getOperationTypeClassName = (action: string) => {
    switch (action) {
      case this.ADD_OPERATION:
        return 'addition-operation';

      case this.SUB_OPERATION:
        return 'subtraction-operation';

      case this.MUL_OPERATION:
        return 'multiplication-operation';

      case this.DIV_OPERATION:
        return 'division-operation';

      case this.DIV_REMAIN_OPERATION:
        return 'division-remain-operation';

      default:
        return '';
    }
  };

  parseDivisionInline = (
    dividend: string,
    divisor: string
  ): MathOperationProps => {
    const remainder = Number(dividend) % Number(divisor);
    const quotient = (Number(dividend) - remainder) / Number(divisor);

    const results: OperationExpProps[] = [];

    results.push({
      type: 'dividend',
      exp: dividend.split(''),
    });

    results.push({
      type: 'divisor',
      exp: [this.DIV_OPERATION, ...divisor.split('')],
    });

    results.push({
      type: 'quotient',
      exp: [this.EQUAL_OPERATION, ...quotient.toString().split('')],
    });

    if (remainder != null && remainder > 0) {
      results.push({
        type: 'division-remain',
        exp: [this.DIV_REMAIN_OPERATION, ...remainder.toString().split('')],
      });
    }

    return {
      operation: MathOperationEnum.multiplication,
      results: results,
    };
  };

  parseAreaDivision = (
    dividend: string,
    divisor: string
  ): MathOperationProps => {
    const remainder = Number(dividend) % Number(divisor);
    const quotient = (Number(dividend) - remainder) / Number(divisor);

    /* (525 / 5)
     *  __| 100 + 20 + 1   / 0
     *  5 | 500 | 25 | 5   / 1
     *    | 500 | 20 | 5   / 2
     *    |  25 |  5 | 0   / 3
     * */

    const quotientNumbers = quotient
      .toString()
      .split('')
      .reverse()
      .map((val, i) => {
        return parseInt(val) * Math.pow(10, i);
      })
      .reverse();

    const expressionArray: string[][] = [];
    expressionArray.push([
      this.HIDDEN_CHARACTER,
      this.HIDDEN_CHARACTER,
      divisor,
      this.HIDDEN_CHARACTER,
    ]);

    let remain = Number(dividend);
    quotientNumbers.forEach((quote) => {
      const stepValue = quote * Number(divisor);
      const stepRemain = remain - stepValue;
      expressionArray.push([
        quote.toString(),
        remain.toString(),
        stepValue.toString(),
        stepRemain.toString(),
      ]);
      remain = stepRemain;
    });

    const results: OperationExpProps[] = [];

    for (let i = 0; i < 4; i++) {
      if (i === 0) {
        const headerCol = expressionArray.map((exp) => {
          return exp[i];
        });

        const headerRow: OperationExpProps = {
          type: 'header',
          exp: headerCol,
        };
        results.push(headerRow);
      } else {
        const rowCol = expressionArray.map((exp) => {
          return exp.length > i ? exp[i] : this.HIDDEN_CHARACTER;
        });

        const tempRow: OperationExpProps = {
          type: 'template',
          exp: rowCol,
        };
        results.push(tempRow);
      }
    }

    return {
      operation: MathOperationEnum.area_division,
      results: results,
    };
  };

  // how to get expression : 36 / 12538
  parseDivision = (dividend: string, divisor: string): MathOperationProps => {
    const remainder = Number(dividend) % Number(divisor);
    const quotient = (Number(dividend) - remainder) / Number(divisor);

    const prefixArr = [...divisor.split(''), '/'];

    const results = [];
    const quotientValue: OperationExpProps = {
      type: 'quotient',
      exp: [
        ...getStringByChar(this.HIDDEN_CHARACTER, divisor.length),
        this.SPLIT_CHARACTER,
        ...quotient.toString().split(''),
        'R',
        ...remainder.toString().split(''),
      ],
    };
    results.push(quotientValue);

    const dividerValue: OperationExpProps = {
      type: 'divisor',
      exp: [...prefixArr, ...dividend.split('')],
    };
    results.push(dividerValue);

    let currentCharIndex = 0;
    let remainVal = dividend[currentCharIndex];

    while (currentCharIndex < dividend.length) {
      if (Number(remainVal) < Number(divisor)) {
        currentCharIndex += 1;
        remainVal = remainVal + dividend[currentCharIndex];
      } else {
        const remain_template = Number(remainVal) % Number(divisor);
        const result_template = Number(remainVal) - remain_template;

        const tempValue: OperationExpProps = {
          type: 'division-subtract',
          exp: [
            ...getStringByChar(this.HIDDEN_CHARACTER, divisor.length),
            ...getStringByChar(
              this.HIDDEN_CHARACTER,
              currentCharIndex - result_template.toString().length + 1
            ),
            '-',
            ...result_template.toString().split(''),
          ],
        };
        results.push(tempValue);
        currentCharIndex += 1;

        if (currentCharIndex < dividend.length) {
          remainVal = remain_template + dividend[currentCharIndex];
          const remainValue: OperationExpProps = {
            type: 'division-remain',
            exp: [
              ...getStringByChar(this.HIDDEN_CHARACTER, divisor.length),
              this.HIDDEN_CHARACTER,
              ...getStringByChar(
                this.HIDDEN_CHARACTER,
                currentCharIndex - remainVal.toString().length + 1
              ),
              ...remainVal.toString().split(''),
            ],
          };
          results.push(remainValue);
        } else {
          const remainValue: OperationExpProps = {
            type: 'division-remain',
            exp: [
              ...getStringByChar(this.HIDDEN_CHARACTER, divisor.length),
              this.HIDDEN_CHARACTER,
              ...getStringByChar(
                this.HIDDEN_CHARACTER,
                currentCharIndex - remain_template.toString().length
              ),
              ...remain_template.toString().split(''),
            ],
          };
          results.push(remainValue);
        }
      }
    }

    let maxLength = 0;
    results.forEach((row) => {
      if (row.exp.length > maxLength) {
        maxLength = row.exp.length;
      }
    });

    results.forEach((row) => {
      if (row.exp.length < maxLength) {
        row.exp = [
          ...row.exp,
          ...getStringByChar(this.HIDDEN_CHARACTER, maxLength - row.exp.length),
        ];
      }
    });

    return {
      operation: MathOperationEnum.division,
      results: results,
    };
  };

  parseMultiplicationInline = (
    multiplier: string,
    multiplicand: string
  ): MathOperationProps => {
    const product = Number(multiplier) * Number(multiplicand);

    const results: OperationExpProps[] = [];

    results.push({
      type: 'multiplier',
      exp: multiplier.split(''),
    });

    results.push({
      type: 'multiplicand',
      exp: [this.MUL_OPERATION, ...multiplicand.split('')],
    });

    results.push({
      type: 'product',
      exp: [this.EQUAL_OPERATION, ...product.toString().split('')],
    });

    return {
      operation: MathOperationEnum.multiplication,
      results: results,
    };
  };

  parseAreaMultiplication = (
    multiplier: string,
    multiplicand: string
  ): MathOperationProps => {
    const results: OperationExpProps[] = [];

    const columnNumbers = multiplier
      .split('')
      .reverse()
      .map((val, i) => {
        return parseInt(val) * Math.pow(10, i);
      })
      .reverse();

    const rowNumbers = multiplicand
      .split('')
      .reverse()
      .map((val, i) => {
        return parseInt(val) * Math.pow(10, i);
      })
      .reverse();

    // let total = 0;

    const headerCol: string[] = [
      this.HIDDEN_CHARACTER,
      ...columnNumbers.map((num) => {
        return num.toString();
      }),
    ];

    const headerRow: OperationExpProps = {
      type: 'header',
      exp: headerCol,
    };

    results.push(headerRow);

    rowNumbers.forEach((row) => {
      const rowData: string[] = [];

      columnNumbers.forEach((col, index) => {
        if (index === 0) {
          rowData.push(row.toString());
        }

        const value = row * col;
        // total += value;
        rowData.push(value.toString());
      });

      results.push({
        type: 'expression',
        exp: rowData,
      });
    });

    return {
      operation: MathOperationEnum.area_multiplication,
      results: results,
    };
  };

  parseMultiplication = (
    multiplier: string,
    multiplicand: string,
    removeZero: boolean
  ) => {
    const product = Number(multiplier) * Number(multiplicand);

    const hasDecimal = [multiplier, multiplicand, product.toString()].some(
      (numb) => {
        return numb.includes(this.DECIMAL_CHARACTER);
      }
    );

    const multiplierValue: OperationNumbProps = {
      type: 'multiplier',
      data: getDecimalInputNumb(multiplier),
      exp: [],
    };

    const multiplicandValue: OperationNumbProps = {
      type: 'multiplicand',
      data: getDecimalInputNumb(multiplicand),
      exp: [],
    };

    const productValue: OperationNumbProps = {
      type: 'product',
      data: getDecimalInputNumb(product.toString()),
      exp: [],
    };

    const _results = [multiplierValue, multiplicandValue];
    multiplicand
      .split('')
      .reverse()
      .forEach((numb, index) => {
        if (numb !== this.DECIMAL_CHARACTER) {
          const temp = Number(multiplier) * Number(numb) * Math.pow(10, index);
          const tempValue: OperationNumbProps = {
            type: 'template',
            data: getDecimalInputNumb(temp.toString()),
            exp:
              temp === 0
                ? getStringByChar('0', index + multiplier.length)
                : temp.toString().split(''),
          };
          if (temp === 0) {
            console.log('multiplierValue', temp, tempValue);
            console.log(getStringByChar('0', index + multiplier.length));
          }

          _results.push(tempValue);
        }
      });

    _results.push(productValue);

    const results = validateMutiplicationNumber(
      _results,
      hasDecimal,
      removeZero
    );

    // then append operation;

    // 0 : multiplier,  1: multiplicand
    const multiplicandIndex = 1;
    const lastTemplateIndex = results.length - 2;

    if (results[lastTemplateIndex].exp[0] === this.HIDDEN_CHARACTER) {
      results[multiplicandIndex].exp[0] = this.MUL_OPERATION;
      results[lastTemplateIndex].exp[0] = this.ADD_OPERATION;
    } else {
      results.forEach((rs, index) => {
        if (index === multiplicandIndex) {
          rs.exp = [this.MUL_OPERATION, ...rs.exp];
        } else if (index === lastTemplateIndex) {
          rs.exp = [this.ADD_OPERATION, ...rs.exp];
        } else {
          rs.exp = [this.HIDDEN_CHARACTER, ...rs.exp];
        }
      });
    }

    return {
      operation: MathOperationEnum.multiplication,
      results: results,
    };
  };

  parseSubtractionInline = (
    minuend: string,
    subtrachend: string[]
  ): MathOperationProps => {
    const difference = subtrachend.reduce((_minuend, val) => {
      return _minuend - Number(val);
    }, Number(minuend));

    const results: OperationExpProps[] = [];

    results.push({
      type: 'minuend',
      exp: minuend.toString().split(''),
    });

    subtrachend.forEach((subtract) => {
      results.push({
        type: 'subtrachend',
        exp: [this.SUB_OPERATION, ...subtract.split('')],
      });
    });

    results.push({
      type: 'difference',
      exp: [this.EQUAL_OPERATION, ...difference.toString().split('')],
    });

    return {
      operation: MathOperationEnum.subtraction,
      results: results,
    };
  };

  parseSubtraction = (
    minuend: string,
    subtrachend: string[],
    placeValue: boolean
  ): MathOperationProps => {
    const difference = subtrachend.reduce((_minuend, val) => {
      return _minuend - Number(val);
    }, Number(minuend));

    const hasDecimal = [minuend, ...subtrachend, difference.toString()].some(
      (numb) => {
        return numb.includes(this.DECIMAL_CHARACTER);
      }
    );

    const minuendValue: OperationNumbProps = {
      type: 'minuend',
      data: getDecimalInputNumb(minuend),
      exp: [],
    };

    const subtrachendValues: OperationNumbProps[] = subtrachend.map((add) => {
      return { type: 'subtrachend', data: getDecimalInputNumb(add), exp: [] };
    });

    const differenceValue: OperationNumbProps = {
      type: 'difference',
      data: getDecimalInputNumb(difference.toString()),
      exp: [],
    };

    let headerValue: OperationExpProps | null = null;

    if (placeValue) {
      const placeValueHeader = getPlaceValueHeader(
        [minuendValue, ...subtrachendValues, differenceValue],
        hasDecimal
      );

      headerValue = {
        type: 'place-value',
        exp: placeValueHeader.hasDecimal
          ? [
              ...getStringByChar(
                this.PLACE_VALUE_CHARACTER,
                placeValueHeader.integer
              ),
              '.',
              ...getStringByChar(
                this.PLACE_VALUE_CHARACTER,
                placeValueHeader.decimal
              ),
            ]
          : [
              ...getStringByChar(
                this.PLACE_VALUE_CHARACTER,
                placeValueHeader.integer
              ),
            ],
      };
    }

    const results = validateOperationNumber(
      MathOperationEnum.subtraction,
      [minuendValue, ...subtrachendValues, differenceValue],
      hasDecimal
    );

    // then append operation;
    if (placeValue && headerValue != null) {
      results.unshift(headerValue);
      const lastOperationIndex = results.length - 2;

      // with place value, need insert empty space for each row and append the subtraction operation
      results.forEach((rs, index) => {
        if (index === lastOperationIndex) {
          rs.exp = [this.SUB_OPERATION, ...rs.exp];
        } else {
          rs.exp = [this.HIDDEN_CHARACTER, ...rs.exp];
        }
      });
    } else {
      const lastOperationIndex = results.length - 2;
      // check if the subtraction operation has enough space to insert
      if (results[lastOperationIndex].exp[0] === this.HIDDEN_CHARACTER) {
        results[lastOperationIndex].exp[0] = this.SUB_OPERATION;

        // if not -> insert empty space for each row and append the subtraction operation
      } else {
        results.forEach((rs, index) => {
          if (index === lastOperationIndex) {
            rs.exp = [this.SUB_OPERATION, ...rs.exp];
          } else {
            rs.exp = [this.HIDDEN_CHARACTER, ...rs.exp];
          }
        });
      }
    }

    return {
      operation: MathOperationEnum.subtraction,
      results: results,
    };
  };

  parseAdditionInline = (addend: string[]): MathOperationProps => {
    const sum = addend.reduce((_sum, val) => {
      return _sum + Number(val);
    }, 0);

    const results: OperationExpProps[] = [];

    addend.forEach((add, idx) => {
      if (idx > 0) {
        results.push({
          type: 'addend',
          exp: [this.ADD_OPERATION, ...add.split('')],
        });
      } else {
        results.push({ type: 'addend', exp: add.split('') });
      }
    });

    results.push({
      type: 'sum',
      exp: [this.EQUAL_OPERATION, ...sum.toString().split('')],
    });

    console.log(results);

    return {
      operation: MathOperationEnum.addition,
      results: results,
    };
  };

  parseAddition = (
    addend: string[],
    placeValue: boolean
  ): MathOperationProps => {
    const sum = addend.reduce((_sum, val) => {
      return _sum + Number(val);
    }, 0);

    const hasDecimal = [...addend, sum.toString()].some((numb) => {
      return numb.includes(this.DECIMAL_CHARACTER);
    });

    const addedValue: OperationNumbProps[] = addend.map((add) => {
      return { type: 'addend', data: getDecimalInputNumb(add), exp: [] };
    });

    const sumValue: OperationNumbProps = {
      type: 'sum',
      data: getDecimalInputNumb(sum.toString()),
      exp: [],
    };

    let headerValue: OperationExpProps | null = null;

    if (placeValue) {
      const placeValueHeader = getPlaceValueHeader(
        [...addedValue, sumValue],
        hasDecimal
      );

      headerValue = {
        type: 'place-value',
        exp: placeValueHeader.hasDecimal
          ? [
              ...getStringByChar(
                this.PLACE_VALUE_CHARACTER,
                placeValueHeader.integer
              ),
              '.',
              ...getStringByChar(
                this.PLACE_VALUE_CHARACTER,
                placeValueHeader.decimal
              ),
            ]
          : [
              ...getStringByChar(
                this.PLACE_VALUE_CHARACTER,
                placeValueHeader.integer
              ),
            ],
      };
    }

    const results = validateOperationNumber(
      MathOperationEnum.addition,
      [...addedValue, sumValue],
      hasDecimal
    );

    // then append operation.
    if (placeValue && headerValue != null) {
      results.unshift(headerValue);

      // with place value, need insert empty space for each row and append the addition operation
      results.forEach((rs, index) => {
        if (index === results.length - 2) {
          rs.exp = [this.ADD_OPERATION, ...rs.exp];
        } else {
          rs.exp = [this.HIDDEN_CHARACTER, ...rs.exp];
        }
      });
    } else {
      // check if the addition operation has enough space to insert
      if (results[results.length - 2].exp[0] === this.HIDDEN_CHARACTER) {
        results[results.length - 2].exp[0] = this.ADD_OPERATION;

        // if not, insert empty space for each row and append the addition operation
      } else {
        results.forEach((rs, index) => {
          if (index === results.length - 2) {
            rs.exp = [this.ADD_OPERATION, ...rs.exp];
          } else {
            rs.exp = [this.HIDDEN_CHARACTER, ...rs.exp];
          }
        });
      }
    }

    return {
      operation: MathOperationEnum.addition,
      results: results,
    };
  };

  parsePlaceValue = (numbers: string): MathOperationProps => {
    const numbersValue: OperationNumbProps = {
      type: 'addend',
      data: getDecimalInputNumb(numbers),
      exp: [],
    };

    const placeValueHeader = getPlaceValueHeader([numbersValue], false);

    const headerValue: OperationExpProps = {
      type: 'place-value',
      exp: placeValueHeader.hasDecimal
        ? [
            ...getStringByChar(
              this.PLACE_VALUE_CHARACTER,
              placeValueHeader.integer
            ),
            '.',
            ...getStringByChar(
              this.PLACE_VALUE_CHARACTER,
              placeValueHeader.decimal
            ),
          ]
        : [
            ...getStringByChar(
              this.PLACE_VALUE_CHARACTER,
              placeValueHeader.integer
            ),
          ],
    };

    const numberValue: OperationExpProps = {
      type: 'addend',
      exp: placeValueHeader.hasDecimal
        ? [
            ...numbersValue.data.integerExp.split(''),
            '.',
            ...numbersValue.data.decimalExp.split(''),
          ]
        : [...numbersValue.data.integerExp.split('')],
    };

    return {
      operation: MathOperationEnum.place_value,
      results: [headerValue, numberValue],
    };
  };
}

export interface OperationExpProps {
  type: // for addition
  | 'empty'
    | 'addend'
    | 'sum'
    // for subtraction
    | 'minuend'
    | 'subtrachend'
    | 'difference'
    // for multiplication
    | 'multiplier'
    | 'multiplicand'
    | 'template'
    | 'product'
    // for division
    | 'dividend'
    | 'divisor'
    | 'remainder'
    | 'quotient'
    | 'division-subtract'
    | 'division-remain'
    | 'place-value'
    // for area multiplication
    | 'header'
    | 'expression';
  exp: string[];
}

interface OperationNumbProps extends OperationExpProps {
  data: OperationProps;
}

interface OperationProps {
  integerNumb: number;
  integerChar: number;
  integerExp: string;
  decimalNumb: number;
  decimalChar: number;
  decimalExp: string;
}

const validateMutiplicationNumber = (
  results: OperationNumbProps[],
  hasDecimal: boolean,
  removeZero: boolean
): OperationExpProps[] => {
  let integerBox = 0;
  let decimalBox = 0;

  results.forEach((numb) => {
    if (numb.type !== 'template') {
      if (integerBox < numb.data.integerChar) {
        integerBox = numb.data.integerChar;
      }

      if (decimalBox < numb.data.decimalChar) {
        decimalBox = numb.data.decimalChar;
      }
    }
  });

  let indexTempChar = 0;

  results.forEach((numb) => {
    let integerChars = numb.data.integerExp.split('');
    const decimalChars = numb.data.decimalExp.split('');

    if (
      numb.type === 'multiplier' ||
      numb.type === 'multiplicand' ||
      numb.type === 'product'
    ) {
      if (hasDecimal) {
        if (numb.data.integerChar < integerBox) {
          const prefixNumb = getStringByChar(
            OperationUtils.HIDDEN_CHARACTER,
            integerBox - numb.data.integerChar
          );

          integerChars = prefixNumb.concat(integerChars);
        }

        if (numb.data.decimalChar < decimalBox) {
          const suffix = getStringByChar(
            OperationUtils.HIDDEN_CHARACTER,
            decimalBox - numb.data.decimalChar
          );
          integerChars = suffix.concat(integerChars);
        }

        numb.exp =
          numb.data.decimalNumb !== 0
            ? integerChars
                .concat(OperationUtils.DECIMAL_CHARACTER)
                .concat(decimalChars)
            : integerChars;
      } else {
        if (numb.data.integerChar < integerBox) {
          const prefixNumb = getStringByChar(
            OperationUtils.HIDDEN_CHARACTER,
            integerBox - numb.data.integerChar
          );

          integerChars = prefixNumb.concat(integerChars);
        }

        numb.exp = integerChars;
      }
    } else {
      const prefix = integerBox + decimalBox - numb.exp.length;
      if (prefix > 0) {
        const prefixNumb = getStringByChar(
          OperationUtils.HIDDEN_CHARACTER,
          prefix - 1
        );
        numb.exp = prefixNumb.concat(numb.exp);
      }

      if (indexTempChar > 0 && removeZero) {
        for (let i = 1; i <= indexTempChar; i++) {
          numb.exp[numb.exp.length - i] = OperationUtils.HIDDEN_CHARACTER;
        }
      }
      indexTempChar += 1;
    }
  });

  return results.map((num) => {
    return {
      type: num.type,
      exp: num.exp,
    };
  });
};

const validateOperationNumber = (
  operation: MathOperationEnum,
  results: OperationNumbProps[],
  hasDecimal: boolean
): OperationExpProps[] => {
  let integerBox = 0;
  let decimalBox = 0;

  results.forEach((numb) => {
    if (integerBox < numb.data.integerChar) {
      integerBox = numb.data.integerChar;
    }

    if (decimalBox < numb.data.decimalChar) {
      decimalBox = numb.data.decimalChar;
    }
  });

  results.forEach((add) => {
    let integerChars = add.data.integerExp.split('');
    let decimalChars = add.data.decimalExp.split('');

    if (add.data.integerChar < integerBox) {
      const prefixNumb = getStringByChar(
        OperationUtils.HIDDEN_CHARACTER,
        integerBox - add.data.integerChar
      );

      integerChars = prefixNumb.concat(integerChars);
    }

    if (add.data.decimalChar < decimalBox) {
      const suffix = getStringByChar(
        OperationUtils.HIDDEN_CHARACTER,
        decimalBox - add.data.decimalChar
      );
      decimalChars = decimalChars.concat(suffix);
    }

    add.exp =
      hasDecimal && decimalChars.length > 0
        ? integerChars
            .concat(OperationUtils.DECIMAL_CHARACTER)
            .concat(decimalChars)
        : integerChars;
  });

  return results.map((num) => {
    return {
      type: num.type,
      exp: num.exp,
    };
  });
};

export const getStringByChar = (char: string, numb: number): string[] => {
  const arr: string[] = [];
  for (let i = 0; i < numb; i++) {
    arr.push(char);
  }
  return arr;
};

const getPlaceValueHeader = (
  results: OperationNumbProps[],
  hasDecimal: boolean
) => {
  let integerBox = 0;
  let decimalBox = 0;

  results.forEach((numb) => {
    if (integerBox < numb.data.integerChar) {
      integerBox = numb.data.integerChar;
    }

    if (decimalBox < numb.data.decimalChar) {
      decimalBox = numb.data.decimalChar;
    }
  });

  return { integer: integerBox, decimal: decimalBox, hasDecimal };
};

const getDecimalInputNumb = (value: string): OperationProps => {
  const integerNumb = getNumberFromStr(value.split('.')[0]);
  const decimalNumb = getNumberFromStr(value.split('.')[1]);

  return {
    integerNumb: integerNumb,
    integerExp: integerNumb.toString(),
    integerChar: integerNumb.toString().length,

    decimalNumb: decimalNumb,
    decimalExp: decimalNumb.toString(),
    decimalChar: decimalNumb.toString().length,
  };
};

const getNumberFromStr = (value: string) => {
  if (value != null && value.trim() !== '') {
    return Number(value);
  } else {
    return 0;
  }
};
export const OperationUtils = new OperationSupportUtils();
