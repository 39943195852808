import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { CheckboxGroup } from '@components/input/Checkbox';
import { useTranslation } from 'react-i18next';
import { CircleUnderlineSettingProps } from '@cms/comps/interact/circle-underline/CircleUnderlineComp';
import { BorderOutlined, UnderlineOutlined } from '@ant-design/icons';
import Input from '@components/input/input';

export const CircleUnderlineSetting = (props: {
  setting: CircleUnderlineSettingProps;
  onSettingChange: (newSetting: CircleUnderlineSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  const options = [
    {
      label: (
        <>
          Circle&nbsp;
          <BorderOutlined />
        </>
      ),
      value: 'circle',
    },
    {
      label: (
        <>
          Underline&nbsp;
          <UnderlineOutlined />
        </>
      ),
      value: 'underline',
    },
  ];

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <CheckboxGroup
          options={options}
          value={formik.values.actions}
          onChange={(values) => formik.setFieldValue('actions', values)}
        >
          {t('component.label.auto_capital')}
        </CheckboxGroup>
      </FormikProvider>
    </SettingStyle>
  );
};
