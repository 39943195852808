import { Button, notification } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import * as yup from 'yup';
import { AssignmentAPI } from '@modules/assignments/service/AssignmentAPI';
import { CreateAssignmentForm } from '@modules/assignments/container/assignment/CreateAssignmentForm';
import AssignmentLessonForm from './AssignmentLessonForm';
import AssignmentStudentForm from '@modules/assignments/container/assignment/AssignmentStudentForm';
import ButtonGroup from '@components/button/ButtonGroup';
import Card, { CardClassEnum } from '@components/card';
import { H2 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { IconUtils } from '@utils/IconUtils';
import { useSelectUser } from '@hooks/useSelectUser';
import { ClassRosterReq } from '@modules/users/services/clazz';
import {
  AssignmentExpiredType,
  CreateAssignmentReq,
} from '@modules/assignments/service/assignment_model';

const CreateAssignment = (props: {
  classId?: number;
  lessonId: number;
  productId: number;
  productTocId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const selectUserId = useSelectUser();

  const [createAssignment, { isLoading }] =
    AssignmentAPI.endpoints.createAssignment.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      assignmentId: -1,
      assignmentGroupId: -1,

      name: '',
      direction: '',
      expiredType: AssignmentExpiredType.date_n_time,
      duration: 45,
      unlockAtDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      unlockAtTime: '08:00:00',
      lockAtDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      lockAtTime: '23:30:00',

      lessonIds: [props.lessonId],
      rosters: [],
      forUserId: selectUserId,
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      description: yup.string(),
    }),

    onSubmit: (values) => {
      const request: CreateAssignmentReq = {
        ...values,
        unlockAt: values.unlockAtDate + 'T' + values.unlockAtTime,
        lockAt: values.lockAtDate + 'T' + values.lockAtTime,
      };

      _createAssignment(request);
    },
  });

  useEffect(() => {
    formik.setFieldValue('forUserId', selectUserId);
  }, [selectUserId]);

  const _createAssignment = (values: CreateAssignmentReq) => {
    createAssignment(values)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('assignment.warning.create_success'),
          description: t('assignment.warning.create_success_msg'),
          placement: 'bottomRight',
        });
        props.onSuccess();
      })
      .catch(() => {
        notification.error({
          message: t('assignment.warning.create_error'),
          description: t('assignment.warning.create_error_msg'),
          placement: 'bottomRight',
        });
      });
  };

  const handleOnSelectLesson = (lessonIds: number[]) => {
    formik.setFieldValue('lessonIds', lessonIds);
  };

  const handleOnSelectStudent = (rosters: ClassRosterReq[]) => {
    formik.setFieldValue('rosters', rosters);
  };

  return (
    <CreateAssignmentStyle>
      <FormikProvider value={formik}>
        <div className={'create-assignment-section'}>
          <HeaderGroup className={'header-group'}>
            <H2>{t('assignment.info')}</H2>
          </HeaderGroup>

          <Card className={CardClassEnum.rectangle_box}>
            <CreateAssignmentForm formik={formik} />
          </Card>
        </div>

        <div className={'create-assignment-section'}>
          <AssignmentLessonForm
            teacherId={formik.values.forUserId}
            productId={props.productId}
            productTocId={props.productTocId}
            lessonIds={formik.values.lessonIds}
            onChange={handleOnSelectLesson}
          />
        </div>

        <div className={'create-assignment-section'}>
          <AssignmentStudentForm
            teacherId={formik.values.forUserId}
            classId={props.classId}
            rosterIds={formik.values.rosters}
            onChange={handleOnSelectStudent}
          />
        </div>

        <div className={'create-assignment-actions'}>
          <ButtonGroup type={'right'}>
            <Button
              type="default"
              size="large"
              onClick={props.onCancel}
              shape={'round'}
            >
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              size="large"
              icon={IconUtils.actions.save}
              onClick={formik.submitForm}
              loading={isLoading}
              disabled={
                formik.values.rosters.length === 0 ||
                formik.values.lessonIds.length === 0
              }
            >
              {t('assignment.actions.create')}
            </Button>
          </ButtonGroup>
        </div>
      </FormikProvider>
    </CreateAssignmentStyle>
  );
};

export default CreateAssignment;

export const CreateAssignmentStyle = styled.div`
  .create-assignment-section {
    margin-bottom: 3em;
  }
`;
