import { useEffect, useRef, useState } from 'react';

const getContainerDimensions = (containerRef: any) => {
  if (containerRef.current) {
    return {
      height: containerRef.current.offsetHeight,
      width: containerRef.current.offsetWidth,
    };
  } else {
    return {
      width: -1,
      height: -1,
    };
  }
};
export const useOnContainerResize = (
  containerRef: any,
  onChange: (width: number, height: number) => void,
  delayMs: number
) => {
  const timer = useRef<number | null>(null);

  const [dimensions, setDimensions] = useState(() => {
    return getContainerDimensions(containerRef);
  });

  useEffect(() => {
    if (containerRef.current) {
      setDimensions(getContainerDimensions(containerRef));
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (timer.current != null) {
      window.clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      onChange(dimensions.width, dimensions.height);
    }, delayMs);

    return () => {
      if (timer.current != null) {
        window.clearTimeout(timer.current);
      }
    };
  }, [dimensions]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getContainerDimensions(containerRef));
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef.current]);
};
