import React, { useEffect, useState } from 'react';
import FilterResource from '@cms/resource-bank/FilterResource';
import { ListEditResourceBanks } from '@cms/resource-bank/ListResourceBank';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import Card, { CardClassEnum } from '@components/card';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import Button from '@components/button';
import { FilterResourceReq } from '@modules/product/services/resource_model';

export default function ResourceTableManagement() {
  const { t } = useTranslation();

  const [syncData, { isLoading }] = ResourceAPI.endpoints.syncData.useMutation(
    {}
  );

  const [filterResource, { data, isFetching }] =
    ResourceAPI.endpoints.filterResource.useLazyQuery();

  const [params, setParams] = useState<FilterResourceReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    keyword: '',
    lessonId: -1,
  });

  useEffect(() => {
    const filterParams = {
      ...params,
      selectAll: params.lessonId != null && params.lessonId > 0,
    };
    filterResource(filterParams);
  }, [params]);

  const handleOnFilter = (filterData: {
    keyword: string;
    lessonId: number;
  }) => {
    setParams((prev) => {
      return {
        ...prev,
        keyword: filterData.keyword,
        lessonId: filterData.lessonId,
      };
    });
  };

  const handleOnPageChange = (params: PaginationReq) => {
    setParams((prev) => {
      return {
        ...params,
        keyword: prev.keyword,
        lessonId: prev.lessonId,
      };
    });
  };

  const handleSyncData = () => {
    syncData({})
      .unwrap()
      .then(() => {
        alert('success');
      })
      .catch((err) => {
        console.log('Have error when save exercise', err);
      });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('resource.header')}</H1>

        <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
          <Button loading={isLoading} onClick={handleSyncData}>
            Sync Data
          </Button>
        </Authorization>
      </HeaderGroup>

      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <FilterResource onSubmit={handleOnFilter} />
      </Card>

      <ListEditResourceBanks
        isFetching={isFetching}
        data={data}
        params={params}
        refresh={handleOnPageChange}
      />
    </>
  );
}
