import React, { useMemo, useState } from 'react';
import i18next from 'i18next';
import { LanguageEnum, LanguageIcon } from '@components/language/LanguageIcon';
import styled from 'styled-components';

export const LanguageContentTag = (props: {
  multiple?: boolean;
  content: string;
}) => {
  if (props.multiple) {
    return <MultipleLanguageContentTag content={props.content} />;
  } else {
    return <SingleLanguageContentTag content={props.content} />;
  }
};

export function LanguageTag(props: { en: string; vi: string; name: string }) {
  if ('vi' === i18next.language && props.vi) {
    return <>{props.vi}</>;
  } else if ('en' === i18next.language && props.en) {
    return <>{props.en}</>;
  } else {
    return <>{props.name}</>;
  }
}

export const MultipleLanguageContentTag = (props: { content: string }) => {
  const [content] = useState<{ type: string; content: string }[]>(() => {
    try {
      const result: { type: string; content: string }[] = [];
      const multipleContent = JSON.parse(props.content);
      Object.keys(multipleContent).forEach((key) => {
        result.push({ type: key, content: multipleContent[key] });
      });
      return result;
    } catch {
      return [{ type: 'default', content: props.content }];
    }
  });

  return (
    <>
      {content.map((item) => {
        return (
          <MultipleLanguageContentStyle
            className={'content-language'}
            key={item.type}
          >
            <LanguageIcon language={item.type as LanguageEnum} height={16} />

            <div
              className={'content-language-content'}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </MultipleLanguageContentStyle>
        );
      })}
    </>
  );
};

const MultipleLanguageContentStyle = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 0.5em;
  padding: 0.25em 0.75em;
  background: rgba(0, 0, 0, 0.05);
  min-height: 2em;
  position: relative;
  padding-right: 30px;
  border-radius: 0.5em 0.5em 0px 0.5em;

  .language-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    transform: translate(0%, 0%);
  }
`;

export const SingleLanguageContentTag = (props: { content: string }) => {
  const content = useMemo(() => {
    try {
      const multipleContent = JSON.parse(props.content);
      if (multipleContent[i18next.language]) {
        return multipleContent[i18next.language];
      } else if (multipleContent['default']) {
        return multipleContent['default'];
      } else {
        return props.content;
      }
    } catch (e) {
      return props.content;
    }
  }, [props.content, i18next.language]);

  return <>{content}</>;
};
