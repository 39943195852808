import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { EntityRequestEnum } from '@utils/Constant';
import { Button, Col, Row } from 'antd';
import TablePagination from '@components/table/TablePagination';
import { CourseAPI, RegistrationCourseRes } from '@services/private/CourseAPI';
import CustomModal from '@components/modal/CustomModal';
import { useTranslation } from 'react-i18next';
import ViewCourseRegistrationForm from './ViewCourseRegistrationForm';
import { StudentAvatarInfo } from '@components/info/StudentInfo';
import { PendingMessageDisplay } from '@components/text/PendingMessageDisplay';
import { DateLabel } from '@components/text/DateLabel';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { InputGroup } from '@components/input/InputGroup';
import styled from 'styled-components';
import { H3 } from '@components/typography';
import { Default_Gutter } from '@components/grid';

export default function MyTeacherRegistrationCourse(props: {
  pageSize?: number;
}) {
  const { t } = useTranslation();

  const [getRegistrationCourses, { data, isFetching }] =
    CourseAPI.endpoints.getRegistrationCourses.useLazyQuery({});

  const [action, setAction] = useState('');
  const [selected, setSelected] = useState<RegistrationCourseRes | null>(null);
  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: props.pageSize ? props.pageSize : MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
  });

  useEffect(() => {
    getRegistrationCourses(params);
  }, []);

  const handleOnSuccess = () => {
    hideRegistrationInfo();
    getRegistrationCourses(params);
  };

  const showRegistrationInfo = (registration: RegistrationCourseRes) => {
    setSelected(registration);
    setAction('show-modal');
  };

  const hideRegistrationInfo = () => {
    setSelected(null);
    setAction('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'avatar',
      key: 'avatar',
      sorter: false,
      align: 'center',
      width: 90,
    },
    {
      title: t('label.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 200,
    },
    {
      title: t('label.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      width: 200,
      align: 'center',
    },
    {
      title: t('course.title'),
      dataIndex: 'course.name',
      key: 'course.name',
      sorter: false,
    },
    {
      title: t('label.registered_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      align: 'center',
      width: 120,
    },
  ];

  const processDataRow = (item: RegistrationCourseRes) => {
    return {
      key: item.courseRegistrationId,
      avatar: (
        <StudentAvatarInfo
          avatar={item.student.avatar}
          firstName={item.student.firstName}
          lastName={item.student.lastName}
          email={item.student.email}
        />
      ),
      email: item.email,
      phone: item.phone,
      createdDate: <DateLabel label={item.createdDate} />,
      'course.name': item.course.name,
      status: (
        <Button
          type={'text'}
          disabled={item.status === EntityRequestEnum.REMOVED}
          onClick={() => showRegistrationInfo(item)}
        >
          <PendingMessageDisplay status={item.status} />
        </Button>
      ),
    };
  };

  const renderMobile = (item: RegistrationCourseRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <RegistrationCourseStyle className={'registration-course-item'}>
          <H3>{item.course.name}</H3>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col flex={'120px'}>
              <StudentAvatarInfo
                avatar={item.student.avatar}
                firstName={item.student.firstName}
                lastName={item.student.lastName}
                email={item.student.email}
              />
            </Col>

            <Col flex={'auto'}>
              <div className={'registration-info'}>
                <InputGroup label={t('label.email')}>{item.email}</InputGroup>

                <InputGroup label={t('label.phone')}>{item.phone}</InputGroup>

                <InputGroup label={t('label.registered_date')}>
                  <DateLabel label={item.createdDate} />
                </InputGroup>
              </div>
            </Col>
          </Row>

          <div className={'registration-action'}>
            <Button
              type={'default'}
              disabled={item.status === EntityRequestEnum.REMOVED}
              onClick={() => showRegistrationInfo(item)}
            >
              <PendingMessageDisplay status={item.status} />
            </Button>
          </div>
        </RegistrationCourseStyle>
      </Card>
    );
  };

  const refreshData = (params: any) => {
    setParams(params);
    getRegistrationCourses(params);
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action === 'show-modal' && (
        <CustomModal
          maskClosable={false}
          header={t('header.course_registration')}
          content={
            <ViewCourseRegistrationForm
              item={selected}
              onSuccess={handleOnSuccess}
            />
          }
          onCloseFunc={hideRegistrationInfo}
        />
      )}
    </>
  );
}

const RegistrationCourseStyle = styled.div`
  position: relative;

  .registration-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
