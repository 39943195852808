import styled from 'styled-components';
import React from 'react';
import { ProductTocInfo } from '@modules/product/components/toc/ProductTocInfo';
import { useViewProductContext } from '@modules/product/components/view-toc/ViewTableOfContentContext';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';

const ViewTableOfContent = (props: { onSelectLesson?: () => void }) => {
  const { productTocs, selectedProductTocId, selectLessonId, setSelectLesson } =
    useViewProductContext();

  const handleOnSelectLesson = (
    lesson: LessonRes,
    productToc: ProductTocRes
  ) => {
    setSelectLesson(lesson, productToc);

    if (props.onSelectLesson) {
      props.onSelectLesson();
    }
  };

  return (
    <TableOfContentStyle className={'table-of-content'}>
      <div className={'table-of-content-tree'}>
        {productTocs &&
          productTocs.map((item) => {
            return (
              <ProductTocInfo
                key={item.productTocId}
                selectTocId={selectedProductTocId}
                selectLessonId={selectLessonId}
                onSelectLesson={handleOnSelectLesson}
                item={item}
              />
            );
          })}
      </div>
    </TableOfContentStyle>
  );
};

export default ViewTableOfContent;

const TableOfContentStyle = styled.div`
  width: 500px;
  max-width: 100%;
  overflow-y: auto;

  .product-toc-info {
    margin-bottom: 1.25em;
    background: #fff;
  }
`;
