import React from 'react';

import PublishProductPage from '@modules/product/pages/PublishProductPage';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useRouter } from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const PublishProduct = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id } = router.query;

  return (
    <ManagementLayout className={'publish-product-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_PRODUCT}
        title={t('product.publish')}
        description={t('product.publish')}
      />
      <PublishProductPage productId={Number(id)} free={false} />
    </ManagementLayout>
  );
};

export default PublishProduct;
