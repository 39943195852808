import { clearCurrentUserData } from '@app/redux/slices/profileSlice';
import { useEffect } from 'react';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';
import { JwtRes } from '@modules/authorization/services/model';

interface LoginRes {
  refreshToken: string;
  id: string;
  username: string;
  email: string;
  expiredTime: string;

  error?: string;
  redirect?: string;
  schoolId: number | null;
}

export interface RedirectLoginReq extends LoginRes {
  access_token: string;
  iat: string;
  exp: string;
}

export interface LoginOauthReq extends LoginRes {
  token: string;
  iat: string;
  exp: string;
}

export const LogoutComp = () => {
  const router = useRouter();

  router.push(SiteMap.auth.login);

  return null;
};

export const LoginComp = (props: { data: LoginOauthReq }) => {
  const router = useRouter();
  const { onLoginSuccess } = useLoginSuccess();

  useEffect(() => {
    clearCurrentUserData();

    const data: JwtRes = {
      access_token: props.data.token,
      refresh_token: props.data.refreshToken,
      iat: props.data.iat,
      exp: props.data.exp,
    };

    onLoginSuccess(data);

    setTimeout(() => {
      if (props.data.redirect) {
        router.push(props.data.redirect);
      } else {
        router.push(SiteMap.private.dashboard);
      }
    }, 0);
  }, []);

  return null;
};
