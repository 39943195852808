import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { AreaDivisionSettingProps } from '@cms/comps/math/operation/area-division/AreaDivisionComp';
import Input from '@components/input/input';
import { useTranslation } from 'react-i18next';

export const AreaDivisionSetting = (props: {
  setting: AreaDivisionSettingProps;
  onSettingChange: (newSetting: AreaDivisionSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
