import React from 'react';
import Input from '@components/input/input';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Default_Gutter } from '@components/grid';
//
// "primary_color": "Primary color",
//   "secondary_color": "Secondary color",
//   "border_color": "Border color"

export const ColorSetting = (props: { formik: any }) => {
  const { t } = useTranslation();

  return (
    <div className={'template-setting-group color-group'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="colorSetting.default"
            type="text"
            label={t('lesson_template.config.text_color')}
            placeholder={t('lesson_template.config.text_color')}
            value={props.formik.values.colorSetting.default}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="colorSetting.primary"
            type="text"
            label={t('lesson_template.config.primary_color')}
            placeholder={t('lesson_template.config.primary_color')}
            value={props.formik.values.colorSetting.primary}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="colorSetting.secondary"
            type="text"
            label={t('lesson_template.config.secondary_color')}
            placeholder={t('lesson_template.config.secondary_color')}
            value={props.formik.values.colorSetting.secondary}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="colorSetting.showCorrectAnswer"
            type="text"
            label={t('lesson_template.config.correct_answer')}
            placeholder={t('lesson_template.config.correct_answer')}
            value={props.formik.values.colorSetting.showCorrectAnswer}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="colorSetting.borderColor"
            type="text"
            label={t('lesson_template.config.border_color')}
            placeholder={t('lesson_template.config.border_color')}
            value={props.formik.values.colorSetting.borderColor}
            onChange={props.formik.handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};
