export const ClassProficiencySkillReport = (props: { classId: number }) => {
  return (
    <div className={'class-proficiency-skill-report'}>
      <p>
        Class Proficiency Skill Report hiển thị skill của học sinh theo từng
        skill set (standard set).
      </p>

      <p>
        Giáo viên dựa vào report này để đánh giá số điểm hiện tại của học sinh
        và xem tình hình học tập dựa vào các bài assignment được giao.
      </p>
    </div>
  );
};
