import React from 'react';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { SelfCheckBtn } from '@cms/lesson-template/components/button-wrapper/SelfCheckBtn';
import { NextQuestionButton } from '@cms/lesson-template/components/buttons/NextQuestionButton';
import { NextSectionBtn } from '@cms/lesson-template/components/button-wrapper/NextSectionBtn';
import { NextSectionButton } from '../buttons/NextSectionButton';
import {
  AnonymousViewMode,
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '../mode/ExerciseMode';
import { SubmitExerciseBtn } from '@cms/lesson-template/components/button-wrapper/SubmitExerciseBtn';
import SwitchCondition from '@components/common/condition/SwitchCondition';

export const NextQuestionBtn = (props: { onClick?: () => void }) => {
  const { interact, lastQuestion, lastSection } = useLessonNavigationContext();

  return (
    <>
      <AnonymousViewMode>
        <SwitchCondition
          conditions={[
            {
              condition: lastQuestion && lastSection && interact,
              children: <SubmitExerciseBtn />,
            },
            {
              condition: !lastQuestion,
              children: <NextQuestionButton onClick={props.onClick} />,
            },
            {
              condition: !lastSection,
              children: <NextSectionBtn onClick={props.onClick} />,
            },
          ]}
        />
      </AnonymousViewMode>

      <SelfCheckExerciseMode>
        {lastQuestion && lastSection ? (
          <SelfCheckBtn />
        ) : (
          <>
            {!lastQuestion ? (
              <NextQuestionButton onClick={props.onClick} />
            ) : (
              <NextSectionBtn onClick={props.onClick} />
            )}
          </>
        )}
      </SelfCheckExerciseMode>

      <DoExerciseMode>
        {lastQuestion && lastSection ? (
          <SubmitExerciseBtn />
        ) : (
          <>
            {!lastQuestion ? (
              <NextQuestionButton onClick={props.onClick} />
            ) : (
              <NextSectionButton onClick={props.onClick} />
            )}
          </>
        )}
      </DoExerciseMode>

      <GradingExerciseMode>
        {!lastQuestion ? (
          <NextQuestionButton onClick={props.onClick} />
        ) : (
          <NextSectionButton onClick={props.onClick} />
        )}
      </GradingExerciseMode>

      <ReviewExerciseMode>
        {!lastQuestion ? (
          <NextQuestionButton onClick={props.onClick} />
        ) : (
          <NextSectionButton onClick={props.onClick} />
        )}
      </ReviewExerciseMode>
    </>
  );
};

export const NextQuestionNav = (props: { onClick?: () => void }) => {
  const { lastQuestion, lastSection } = useLessonNavigationContext();

  if (!lastQuestion) {
    return <NextQuestionButton onClick={props.onClick} />;
  } else if (!lastSection) {
    return <NextSectionBtn onClick={props.onClick} />;
  } else {
    return null;
  }
};
