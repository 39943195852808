import styled from 'styled-components';
import { DEFAULT_SIZE } from '@modules/product/components/lesson/Config';

export const LessonStyle = styled.div`
  display: flex;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  padding-left: ${DEFAULT_SIZE.objectBank}px;

  .cms-page-layout {
    padding-left: 135px;
    padding-right: 135px;
  }

  .cms-page-layout {
    width: 100%;
  }

  .gtudy-page-header {
    display: none;
  }

  &.multiple-question-edit-lesson-layout {
    padding-left: 0px;
    padding-right: 0px;

    .cms-page-layout {
      margin-top: 40px;
      padding-left: 135px;
      padding-right: 135px;
    }
  }

  &.single-question-edit-lesson-layout {
    .lesson-template {
      margin-bottom: 150px;
    }

    .cms-page-layout {
      margin-bottom: 150px;
    }
  }

  &.build-question-edit-lesson-layout {
    padding-left: 0px;
    position: relative;

    .cms-page-layout {
      margin-top: 40px;
    }

    .resource-bank-container {
      width: 40%;
      padding: 25px 25px 25px 28px;
      border-left: 2px solid #ccc;
      position: sticky;
      top: 0px;
      left: 0px;
    }

    .lesson-content-editable {
      width: 60%;
      padding: 0px;
      background: ${(props) => props.theme.app.background}; //#f5f5f5;
      border: none;

      .lesson-content-editable {
        width: 100%;
        margin: 0 auto;
        padding: 0px;

        .lesson-template {
          min-height: 100vh;
        }
      }
    }
  }

  &.build-section-edit-lesson-layout {
    padding-left: 0%;
    padding-right: 0%;

    .build-section-template {
      width: 100%;
    }
  }

  > * {
    box-sizing: border-box;
  }

  .lesson-content-editable {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    .lesson-title,
    .lesson-resources {
    }

    .lesson-title {
      width: 100%;
      margin-bottom: 20px;
    }

    .lesson-resources {
      width: 100%;

      flex-grow: 1;
    }
  }

  .component-bank {
    width: ${DEFAULT_SIZE.objectBank}px;
    overflow-y: auto;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 1;
  }

  .edit-lesson-actions {
  }
`;

export const LessonGroupStyle = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;

  &.exit-group {
    right: 10px;
    top: 10px;
    bottom: auto;
  }

  font-size: 20px;
  background: #fff;
  border-radius: 1.25em;
  box-shadow: 0px 0px 10px 5px #ccc;
  padding: 0.33em 0.33em;
  display: flex;
  align-items: center;
  z-index: 3;

  .action-button {
    &.ant-tooltip-disabled-compatible-wrapper {
      button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5em;
        border: none;
        font-size: 18px;

        font-weight: bold;
        background: ${(props) => props.theme.app.primary};
        color: ${(props) => props.theme.app.primary_text};
      }
    }
  }
`;
