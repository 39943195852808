import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateProductReq,
  FilterProductLessonReq,
  ProductLessonAccessibleReq,
  ProductPriceRes,
  ProductRes,
  TableOfContentRes,
  TransferProductReq,
  UpdateProductMetadataReq,
  UpdateProductStatusReq,
} from '@modules/product/services/product_model';
import { LessonRes } from './lesson_model';

export const ProductAPI = createApi({
  reducerPath: 'ProductAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyProducts: builder.query<PaginationRes<ProductRes>, PaginationReq>({
      query: (params) => ({
        url: `/products`,
        params,
      }),
    }),

    getAllProduct: builder.query<ProductRes[], unknown>({
      query: () => ({
        url: `/products/find-all`,
        method: 'GET',
      }),
    }),

    getPublishProducts: builder.query<PaginationRes<ProductRes>, unknown>({
      query: () => ({
        url: `/products/publish`,
      }),
    }),

    getProduct: builder.query<ProductRes, number>({
      query: (productId) => ({
        url: `/products/${productId}`,
        method: 'GET',
      }),
    }),

    viewProductByCode: builder.query<ProductRes, string>({
      query: (productCode) => ({
        url: `/products/${productCode}/view`,
        method: 'GET',
      }),
    }),

    deleteProduct: builder.mutation<BasicMessageRes, number>({
      query: (productId) => ({
        url: `/products/${productId}`,
        method: 'DELETE',
      }),
    }),

    getProductLessons: builder.query<LessonRes[], unknown>({
      query: (productId: number) => ({
        url: `/products/${productId}/lessons`,
        method: 'GET',
      }),
    }),

    getTableOfContent: builder.query<TableOfContentRes, number>({
      query: (productId) => ({
        url: `/products/${productId}/table-of-content`,
        method: 'GET',
      }),
    }),

    filterLessons: builder.query<TableOfContentRes, FilterProductLessonReq>({
      query: (params) => ({
        url: `/products/${params.productId}/table-of-content/filter`,
        method: 'GET',
        params,
      }),
    }),

    getProductPrices: builder.query<ProductPriceRes, number>({
      query: (productId) => ({
        url: `/products/${productId}/prices`,
        method: 'GET',
      }),
    }),

    updateProductPrice: builder.mutation<BasicMessageRes, ProductPriceRes>({
      query: (body: ProductPriceRes) => ({
        url: `/products/${body.productId}/prices`,
        body,
        method: 'PUT',
      }),
    }),

    createProduct: builder.mutation<ProductRes, CreateProductReq>({
      query: (body: CreateProductReq) => ({
        url: '/products',
        body,
        method: 'POST',
      }),
    }),

    updateProduct: builder.mutation<ProductRes, CreateProductReq>({
      query: (body) => ({
        url: `/products/${body.productId}`,
        body,
        method: 'PUT',
      }),
    }),

    updateProductStatus: builder.mutation<ProductRes, UpdateProductStatusReq>({
      query: (body) => ({
        url: `/products/${body.productId}/status`,
        method: 'PUT',
        body,
      }),
    }),

    updateMetadata: builder.mutation<ProductRes, UpdateProductMetadataReq>({
      query: (body) => ({
        url: `/products/${body.productId}/metadata`,
        body,
        method: 'PUT',
      }),
    }),

    validateProduct: builder.mutation<BasicMessageRes, number>({
      query: (productId) => ({
        url: `/products/${productId}/validate`,
        method: 'PUT',
      }),
    }),

    transfer: builder.mutation<ProductRes, TransferProductReq>({
      query: (body) => ({
        url: `/products/${body.productId}/transfer`,
        body,
        method: 'PUT',
      }),
    }),

    getCompletedProducts: builder.query<
      PaginationRes<ProductRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: `/products/completed`,
        params,
      }),
    }),

    viewProduct: builder.query<TableOfContentRes, number>({
      query: (productId) => ({
        url: `/products/${productId}/view-progress`,
        method: 'GET',
      }),
    }),

    updateAccessible: builder.mutation<
      BasicMessageRes,
      ProductLessonAccessibleReq
    >({
      query: (body) => ({
        url: `/products/${body.productId}/accessible`,
        method: 'PUT',
        body: body.lessons,
      }),
    }),
  }),
});
