import React, { useRef } from 'react';
import { Button, Upload } from 'antd';
import { UploadAPI } from '@modules/product/services/UploadAPI';
import styled from 'styled-components';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useWindowSizeChange } from '@hooks/useWindowSize';
import { FileResponse } from '@modules/product/services/assets_model';

interface SingleUploadProps {
  accept: string;
  multiple: boolean;
  onSuccessFunc: (fileName: string) => void;
  onErrorFunc?: () => void;
  children: any;
  scale: number;
}

const SingleUpload = ({
  accept,
  multiple,
  onSuccessFunc,
  onErrorFunc,
  ...props
}: SingleUploadProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [uploadFile, { isLoading }] =
    UploadAPI.endpoints.uploadFile.useMutation();

  useWindowSizeChange(() => {
    if (elementRef.current && props.scale) {
      const width = elementRef.current.offsetWidth;
      const height = width * props.scale;
      elementRef.current.style.minHeight = height + 'px';
    }
  }, 500);

  async function execute_uploadFile(file: any) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      uploadFile(formData)
        .unwrap()
        .then((data: FileResponse) => {
          onSuccessFunc(data.fileUrl);
        })
        .catch(() => {
          if (onErrorFunc) {
            onErrorFunc();
          }
        });
    } catch (error) {
      if (onErrorFunc) {
        onErrorFunc();
      }
    }
  }

  const handleFileChange = (event: any) => {
    const file = event.fileList[0].originFileObj;
    execute_uploadFile(file);
  };

  return (
    <UploadFileWrapperStyle
      className={'upload-element-wrapper'}
      ref={elementRef}
    >
      <UploadStyle
        showUploadList={false}
        maxCount={multiple ? 10 : 1}
        onChange={handleFileChange}
        accept={accept}
        className={props.scale ? 'scale-element' : ''}
        {...props}
      >
        <Button
          type={'default'}
          block
          className="upload-file-button"
          loading={isLoading}
          onClick={(e: any) => e.preventDefault()}
        >
          <span className={'upload-icon'}>
            <CloudUploadOutlined />
          </span>

          <span className={'upload-content'}>{props.children}</span>
        </Button>
      </UploadStyle>
    </UploadFileWrapperStyle>
  );
};

export default SingleUpload;

const UploadFileWrapperStyle = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;

  .scale-element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;

    .ant-image {
      width: 100% !important;
      height: auto !important;

      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }
  }
`;

const UploadStyle = styled(Upload)`
  position: relative;

  .ant-upload {
    width: 100%;
    display: flex;
  }

  .upload-content {
    display: flex;
    width: 100%;
  }

  .ant-btn {
    display: flex;
    height: auto;
    padding: 0px;
    margin: 0px;
  }

  span.ant-btn-loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }

  .upload-file-button {
    &:hover {
      .upload-icon {
        bottom: 50%;
        right: 50%;
        transform: translate(50%, 50%);
        font-size: 64px;
      }

      .upload-content {
        opacity: 0.5;
      }
    }
    .upload-icon {
      position: absolute !important;
      z-index: 1;
      font-size: 20px;
      bottom: 5px;
      right: 5px;
    }
  }
`;
