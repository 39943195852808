import React from 'react';
import styled from 'styled-components';
import { ImageContentProps } from '@cms/content/ContentType';

export const CmsImageViewer = (props: {
  contentData: ImageContentProps;
  width?: number;
  align?: 'left' | 'right' | 'center';
  onChange?: (value: string) => void;
}) => {
  const getContainerStyle = () => {
    const style: Record<string, any> = {};
    if (props.width && props.width >= 0) {
      style['width'] = props.width + '%';
    }
    return style;
  };

  return (
    <CmsImageContentViewerStyle
      className={'cms-image-viewer'}
      style={getContainerStyle()}
    >
      <img src={props.contentData.data} alt={props.contentData.description} />
    </CmsImageContentViewerStyle>
  );
};

const CmsImageContentViewerStyle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }
`;
