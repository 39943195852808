import React, { createContext, useContext, useEffect, useState } from 'react';
import { CompAnswerProps, CompProps } from '@cms/ComponentInteface';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompGroupEnum } from '@cms/utils/CompProps';
import {
  DragNDropItemCompProps,
  getDragNDropAns,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import {
  DragNDropInlineAnsProps,
  DragNDropInlineCompProps,
  DragNDropInlineSettingProps,
} from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineComp';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useDispatch } from 'react-redux';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import produce from 'immer';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';

export interface SelectDragNDropItem {
  item: DragNDropItemCompProps | null;
  type: 'source' | 'target';
}
const DragNDropInlineCompContext = createContext({
  modifiedTime: -1 as number,
  disabled: false as boolean,

  originalData: {} as DragNDropInlineCompProps,
  setting: {} as DragNDropInlineSettingProps,
  sourceItems: [] as DragNDropItemCompProps[],
  targetItems: [] as DragNDropItemCompProps[],

  disableSource: [] as string[],
  disableTarget: [] as string[],

  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  updateOptions: (options: DragNDropItemCompProps[], type: CompGroupEnum) => {
    console.log(options, type);
  },
  updateAnswers: (answers: string[]) => {
    console.log(answers);
  },
  updateComponent: () => {},

  onDropData: (data: string) => {
    console.log(data);
  },

  onMoveData: (oldData: string, newData: string) => {
    console.log(oldData, newData);
  },

  onRemoveData: (data: string) => {
    console.log(data);
  },
});

export const DragNDropInlineContextProvider = (props: {
  disabled: boolean;
  item: DragNDropInlineCompProps;
  answer: DragNDropInlineAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();

  const [answer, setCompAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [modifiedTime, setModifiedTime] = useState(-1);
  const [feedback, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);
  const [disableSource, setDisableSource] = useState<string[]>([]);
  const [disableTarget, setDisableTarget] = useState<string[]>([]);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );

  const [targetItems, setTargetItems] = useState(
    props.item.configuration.targetItems
  );

  useEffect(() => {
    if (props.answer != null && props.answer.answer) {
      setCompAnswer({
        value: props.answer && props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: [],
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    updateDisabledGroup(answer.value);
  }, [answer, props.item.setting]);

  const updateDisabledGroup = (answers: string[]) => {
    const disabledSrc: string[] = [];
    const disabledTgt: string[] = [];

    // each object is used one time
    if (!props.item.setting.multipleDrag) {
      answers.forEach((item) => {
        const ans = getDragNDropAns(item);
        disabledSrc.push(ans.drag);
      });
    }

    if (!props.item.setting.multipleDrop) {
      answers.forEach((item) => {
        const ans = getDragNDropAns(item);
        disabledTgt.push(ans.drop);
      });
    }

    setDisableSource(disabledSrc);
    setDisableTarget(disabledTgt);
  };

  // ------------------- custom data ------------------------------------

  const updateOptions = (
    options: DragNDropItemCompProps[],
    type: CompGroupEnum
  ) => {
    if (type === CompGroupEnum.source) {
      setSourceItems(options);
    } else {
      setTargetItems(options);
    }

    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
        targetItems: targetItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer.value,
        },
      })
    );
  };

  const updateAnswers = (newAnswers: string[]) => {
    setCompAnswer({
      value: newAnswers,
      triggerChange: true,
    });
  };

  const onMoveData = (oldData: string, newData: string) => {
    setCompAnswer((prevAns) => {
      const reduceAns = [...prevAns.value].filter((ans) => {
        return ans !== oldData;
      });

      if (!reduceAns.includes(newData)) {
        const newAnswers = [...reduceAns, newData];
        return {
          value: newAnswers,
          triggerChange: true,
        };
      } else {
        return {
          value: reduceAns,
          triggerChange: false,
        };
      }
    });
  };

  const onDropData = (answerPart: string) => {
    if (!props.disabled) {
      setCompAnswer((prevAns) => {
        const reduceAns = [...prevAns.value];

        if (!reduceAns.includes(answerPart)) {
          const newAnswers = [...reduceAns, answerPart];
          return {
            value: newAnswers,
            triggerChange: true,
          };
        } else {
          return {
            value: reduceAns,
            triggerChange: false,
          };
        }
      });
    }
  };

  const onRemoveData = (removeAns: string) => {
    if (!props.disabled) {
      setCompAnswer((prevAns) => {
        const reduceAnswer = [...prevAns.value].filter((str) => {
          return str !== removeAns;
        });

        return {
          value: reduceAnswer,
          triggerChange: true,
        };
      });
    }
  };

  return (
    <DragNDropInlineCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        originalData: props.item,

        sourceItems,
        targetItems,
        updateOptions,

        disabled: props.disabled,
        setting: props.item.setting,

        disableSource,
        disableTarget,
        answers: answer.value,
        feedback: feedback,

        updateComponent,
        updateAnswers,

        onDropData,
        onRemoveData,
        onMoveData,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedback}>
        {props.children}
      </CompFeedbackContextProvider>
    </DragNDropInlineCompContext.Provider>
  );
};

export const useDragNDropInlineCompContext = () => {
  const context = useContext(DragNDropInlineCompContext);
  if (!context) {
    throw new Error('You must wrap container by DragNDropCompContextProvider');
  }
  return context;
};
