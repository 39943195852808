import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { CreateUserReq } from '@modules/users/services/StudentAPI';
import { notification } from 'antd';
import { CreateUserForm } from '@modules/users/component/users/CreateUserForm';
import { UserStudentAPI } from '@modules/users/services/UserStudentAPI';
import { UserInfoRes, UserRes } from '@modules/users/services/model';

export const CreateStudentUserForm = (props: {
  schoolId?: number;
  studentId?: number;
  onSuccess: (data: UserInfoRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getStudentData, { data, isSuccess }] =
    UserStudentAPI.endpoints.getUserData.useLazyQuery();

  const [createStudent, { isLoading: isCreateLoading }] =
    UserStudentAPI.endpoints.createUser.useMutation({});
  const [updateStudent, { isLoading: isUpdateLoading }] =
    UserStudentAPI.endpoints.updateUser.useMutation({});

  const [userData, setUserData] = useState<UserRes | null>(null);

  useEffect(() => {
    if (props.studentId != null && props.studentId > 0) {
      getStudentData(props.studentId);
    }
  }, [props.studentId]);

  useEffect(() => {
    if (data && isSuccess) {
      setUserData(data);
    }
  }, [data]);

  const handleOnSubmit = (values: CreateUserReq) => {
    if (props.studentId != null && props.studentId > 0) {
      updateStudent(values)
        .unwrap()
        .then((data) => {
          notification.success({
            message: t('student.warning.success'),
            description: t('student.warning.update_success'),
            placement: 'bottomRight',
          });
          props.onSuccess(data);
        })
        .catch(() => {
          notification.error({
            message: t('student.warning.error'),
            description: t('student.warning.update_failure'),
            placement: 'bottomRight',
          });
        });
    } else {
      createStudent(values)
        .unwrap()
        .then((data) => {
          notification.success({
            message: t('student.warning.success'),
            description: t('student.warning.add_success'),
            placement: 'bottomRight',
          });
          props.onSuccess(data);
        })
        .catch(() => {
          notification.error({
            message: t('student.warning.error'),
            description: t('student.warning.add_failure'),
            placement: 'bottomRight',
          });
        });
    }
  };
  return (
    <CreateUserForm
      loading={isCreateLoading || isUpdateLoading}
      schoolId={props.schoolId}
      userId={props.studentId}
      userData={userData}
      onCancel={props.onCancel}
      onSubmit={handleOnSubmit}
    />
  );
};
