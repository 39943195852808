import React, { useEffect } from 'react';
import { CertificateAPI } from '@modules/admin/service/CertificateAPI';
import { Loading } from '@components/loading/Loading';
import DOMPurify from 'dompurify';
import { Col, Row } from 'antd';
import ClipboardCopy from '@components/text/ClickToCopy';
import { SiteMap } from '@router/SiteMap';
// import QRCodeComp from '@components/text/QRCode';
import styled from 'styled-components';
import { Default_Gutter } from '@components/grid';

export const UserCertificateDisplay = (props: {
  code: string;
  shareable: boolean;
}) => {
  const [viewUserCertificate, { data, isFetching }] =
    CertificateAPI.endpoints.viewUserCertificate.useLazyQuery();

  useEffect(() => {
    if (props.code) {
      viewUserCertificate(props.code);
    }
  }, [props.code]);

  return (
    <UserCertificateStyle className={'user-certificate-display'}>
      <Loading loading={isFetching}>
        <div className={'certificate-template-content'}>
          <div
            className={'certificate-content'}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data?.template ?? ''),
            }}
          />
        </div>
      </Loading>

      {props.shareable && (
        <div className={'certificate-share-content'}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col flex={'auto'}>
              <blockquote>
                <ClipboardCopy
                  layout={'inline'}
                  copyText={
                    window.location.origin +
                    SiteMap.public.view_certificate_gen(props.code)
                  }
                />
              </blockquote>
            </Col>

            {/*<Col flex={'150px'}>*/}
            {/*  <blockquote>*/}
            {/*    <QRCodeComp*/}
            {/*      size={120}*/}
            {/*      url={*/}
            {/*        window.location.origin +*/}
            {/*        SiteMap.public.view_certificate_gen(props.code)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </blockquote>*/}
            {/*</Col>*/}
          </Row>
        </div>
      )}
    </UserCertificateStyle>
  );
};

const UserCertificateStyle = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .certificate-share-content {
    margin-top: 1em;
    margin-bottom: 1em;

    blockquote .ant-btn {
      font-size: 20px;
      width: 100%;
    }
  }
`;
