import React, { createContext, useContext, useState } from 'react';

import { CompAnswerProps, CompProps } from '@cms/ComponentInteface';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';

import {
  GlossaryCompProps,
  GlossarySettingProps,
} from '@cms/comps/game/glossary/GlossaryComp';
import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { GlossaryType } from '@cms/comps/game/GlossaryWordBank';
import { updateComponent } from '@app/redux/slices/composeResource';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
  VideoContentProps,
} from '@cms/content/ContentType';

const GlossaryCompContext = createContext({
  modifiedTime: -1,
  setting: {} as GlossarySettingProps,
  sourceItems: {} as GlossaryGameContent,
  // for edit able
  updateOption: (
    type: GlossaryType,
    data:
      | TextContentProps
      | ImageContentProps
      | AudioContentProps
      | VideoContentProps
  ) => {
    console.log(data, type);
  },

  updateComponent: () => {},
});

export const GlossaryCompContextProvider = (props: {
  item: GlossaryCompProps;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );

  const updateOption = (
    type: GlossaryType,
    data:
      | TextContentProps
      | ImageContentProps
      | AudioContentProps
      | VideoContentProps
  ) => {
    if (type === GlossaryType.word) {
      setSourceItems((prev) => {
        return { ...prev, word: data };
      });
    } else {
      setSourceItems((prev) => {
        return { ...prev, description: data };
      });
    }
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const triggerUpdateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
      };
    });

    dispatch(updateComponent(newComps));
  };

  return (
    <GlossaryCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        setting: props.item.setting,
        sourceItems: sourceItems,

        updateOption,
        updateComponent: triggerUpdateComponent,
      }}
    >
      {props.children}
    </GlossaryCompContext.Provider>
  );
};

export const useGlossaryCompContext = () => {
  const context = useContext(GlossaryCompContext);
  if (!context) {
    throw new Error('You must wrap container by GlossaryCompContextProvider');
  }
  return context;
};
