import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const useRouter = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  // @ts-ignore
  const urlParams = Object.fromEntries([...searchParams]);

  return {
    push: navigate,
    query: { ...params, ...urlParams } as any,
  };
};
