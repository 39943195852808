import { SessionAndRosterRes } from '@services/model/session';
import React, { useEffect, useMemo, useState } from 'react';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { ClassFilterReq } from '@modules/users/services/clazz';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { DisplayCheckBox } from '@components/input/SimpleCheckBox';
import styled from 'styled-components';
import TablePagination from '@components/table/TablePagination';

export interface AttendSessionStatus {
  userId: number;
  status: 'attended' | 'absent';
  joinDate?: string;
  quitDate?: string;
}

const session_name_width = 280;
const date_label_width = 150;
const user_width = 100;

export const ClassSessionAttend = (props: { classId: number }) => {
  const [getStudentInClass, { data: studentData, isFetching }] =
    ClassAPI.endpoints.getStudentInClass.useLazyQuery({});

  const [getSessions, { data: sessionData, isFetching: isSessionFetching }] =
    ClassAPI.endpoints.getSessions.useLazyQuery({});

  const [params] = useState<ClassFilterReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    classId: props.classId,
    selectAll: true,
  });

  const [sourceData, setSourceData] = useState<any[]>([]);

  const tableColumns: any[] = useMemo(() => {
    const columns: any[] = [];
    columns.push({
      title: 'Session',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sort: true,
      width: session_name_width,
    });

    columns.push({
      title: 'Start Time',
      width: date_label_width,
      dataIndex: 'startDate',
      key: 'startDate',
      fixed: 'left',
      align: 'center',
      sort: true,
    });

    if (studentData) {
      studentData.forEach((st) => {
        columns.push({
          title: <UserThumbnailDisplay layout={'column'} size={32} item={st} />,
          width: user_width,
          dataIndex: 'student' + st.userId,
          key: 'student' + st.userId,
          align: 'center',
        });
      });
    }

    return columns;
  }, [studentData]);

  useEffect(() => {
    getStudentInClass({ classId: props.classId, selectAll: true });
  }, [props.classId]);

  useEffect(() => {
    getSessions(params);
  }, [params]);

  useEffect(() => {
    if (studentData && sessionData) {
      const _sourceData = sessionData.content.map(
        (sec: SessionAndRosterRes) => {
          const attendedStatus: Record<number, AttendSessionStatus> = {};

          if (sec.rosters != null && sec.rosters.length > 0) {
            sec.rosters.forEach((st) => {
              attendedStatus[st.user.userId] = {
                userId: st.user.userId,
                joinDate: st.joinDate,
                quitDate: st.lastJoinDate,
                status: st.joinDate ? 'attended' : 'absent',
              };
            });
          }
          const record: Record<string, any> = {
            name: sec.session.name,
            startDate: (
              <DateAndTimeLabel type={'inline'} label={sec.session.startDate} />
            ),
          };

          studentData.forEach((st) => {
            const status = attendedStatus[st.userId];
            const value = status != null ? status.status === 'attended' : null;

            record['student' + st.userId] = (
              <DisplayCheckBox
                tooltip={{
                  checked: 'Attend',
                  unchecked: 'Absent',
                  empty: 'Not belongs',
                }}
                value={value}
              />
            );
          });

          return record;
        }
      );
      setSourceData(_sourceData);
    }
  }, [studentData, sessionData]);

  const onRefresh = (params: any) => {
    console.log(params);
  };

  return (
    <ClassAttendReportStyle className={'class-session-attend-report'}>
      <p>
        Class Participants report thể hiện lịch sử tham dự các buổi học online
        của từng học sinh.
      </p>

      <p>
        Giáo viên có thể căn cứ report này để thông kê và lên lịch giảng dạy các
        học sinh chưa thể tham gia đầy đủ.
      </p>

      <TablePagination
        className={'class-session-attend-table'}
        params={params}
        isLoading={isFetching || isSessionFetching}
        refresh={onRefresh}
        columns={tableColumns}
        scroll={{
          x:
            session_name_width +
            date_label_width +
            (sessionData?.content.length ?? 1) * user_width,
        }}
        dataSource={sourceData}
        pagination={false}
      />
    </ClassAttendReportStyle>
  );
};

const ClassAttendReportStyle = styled.div`
  .class-session-attend-table {
    .user-n-thumbnails {
      align-items: center;

      .user-display-name {
        font-weight: normal;
        text-transform: none;
        margin-top: 0.5em;
      }
    }
  }
`;
