import { Button, Dropdown, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import CustomModal from '@components/modal/CustomModal';
import { EditAssignmentGroupForm } from './EditAssignmentGroup';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { AssignmentGroupAPI } from '@modules/assignments/service/AssignmentGroupAPI';
import TablePagination from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import { DateLabel } from '@components/text/DateLabel';
import useConfirmModal from '@components/modal/ConfirmModal';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { H4 } from '@components/typography';
import styled from 'styled-components';
import { AssignmentGroupRes } from '@modules/assignments/service/assignment_model';

export enum AssignmentGroupActionEnum {
  default = '',
  refresh = 'refresh',
  create = 'create',
  update = 'update',
  remove = 'remove',
}

interface AssignmentGroupActionProps {
  action: AssignmentGroupActionEnum;
  timestamp: number;
  assignmentGroupId?: number;
}

export const AssignmentGroupTable = (props: {
  action?: AssignmentGroupActionEnum;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [findAll, { data, isFetching }] =
    AssignmentGroupAPI.endpoints.findAll.useLazyQuery();

  const [deleteAssignmentGroup] =
    AssignmentGroupAPI.endpoints.deleteAssignmentGroup.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
  });

  const [action, setAction] = useState<AssignmentGroupActionProps>({
    action: AssignmentGroupActionEnum.default,
    timestamp: -1,
  });

  useEffect(() => {
    if (props.action === AssignmentGroupActionEnum.refresh) {
      refreshData();
    }
  }, [props.action]);

  useEffect(() => {
    refreshData();
  }, [params]);

  const refreshData = () => {
    findAll(params);
  };

  const changeAction = (action: AssignmentGroupActionEnum, params?: number) => {
    setAction({
      action: action,
      assignmentGroupId: params,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCancel = () => {
    setAction({
      action: AssignmentGroupActionEnum.default,
      timestamp: -1,
    });
    refreshData();
  };

  const handleOnSuccess = () => {
    setAction({
      action: AssignmentGroupActionEnum.default,
      timestamp: -1,
    });
    refreshData();
  };

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 180,
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const processDataRow = (item: AssignmentGroupRes) => {
    return {
      key: item.assignmentGroupId,
      name: item.name,
      description: item.description,
      createdDate: <DateLabel label={item.createdDate} />,
      actions: (
        <Dropdown
          menu={{
            items: [
              {
                key: AssignmentGroupActionEnum.update,
                onClick: () =>
                  changeAction(
                    AssignmentGroupActionEnum.update,
                    item.assignmentGroupId
                  ),
                label: t('label.update'),
              },
              {
                key: AssignmentGroupActionEnum.remove,
                onClick: () => confirmRemoveAssignmentGroup(item),
                label: t('label.remove'),
              },
            ],
          }}
          placement="topRight"
          arrow
        >
          <Button shape="circle" type={'default'} icon={IconUtils.more_icon} />
        </Dropdown>
      ),
    };
  };

  const renderMobile = (item: AssignmentGroupRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <AssignmentGroupStyle className={'assignment-group-container'}>
          <div className={'assignment-group-info'}>
            <H4>{item.name}</H4>

            <p>{item.description}</p>
          </div>

          <div className={'assignment-group-actions'}>
            <Dropdown
              menu={{
                items: [
                  {
                    key: AssignmentGroupActionEnum.update,
                    onClick: () =>
                      changeAction(
                        AssignmentGroupActionEnum.update,
                        item.assignmentGroupId
                      ),
                    label: t('label.update'),
                  },
                  {
                    key: AssignmentGroupActionEnum.remove,
                    onClick: () => confirmRemoveAssignmentGroup(item),
                    label: t('label.remove'),
                  },
                ],
              }}
              placement="bottomRight"
              arrow
            >
              <Button
                shape="circle"
                type={'default'}
                icon={IconUtils.more_icon}
              />
            </Dropdown>
          </div>
        </AssignmentGroupStyle>
      </Card>
    );
  };

  const confirmRemoveAssignmentGroup = (item: AssignmentGroupRes) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('confirm.confirmRemoveItem'),
      t('label.remove'),
      t('label.cancel'),
      (result) => {
        if (result) {
          deleteAssignmentGroup(item.assignmentGroupId)
            .unwrap()
            .then(() => {
              changeAction(AssignmentGroupActionEnum.default);
              refreshData();
            })
            .catch((e) => {
              notification.error({
                message: t('header.error'),
                description: t(e.data.error_description),
                placement: 'bottomRight',
              });
              changeAction(AssignmentGroupActionEnum.default);
            });
        }
      }
    );
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={setParams}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === AssignmentGroupActionEnum.update &&
        action.assignmentGroupId &&
        action.assignmentGroupId > 0 && (
          <CustomModal
            header={t('assignment_group.actions.edit')}
            content={
              <EditAssignmentGroupForm
                assignmentGroupId={action.assignmentGroupId}
                successHandle={handleOnSuccess}
                onCloseFunc={handleOnCancel}
              />
            }
            onCloseFunc={handleOnCancel}
          />
        )}
    </>
  );
};

const AssignmentGroupStyle = styled.div`
  position: relative;

  .assignment-group-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
