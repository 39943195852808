import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Col, Progress, Row, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { H2, H5 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { AlignLeftOutlined, TableOutlined } from '@ant-design/icons';
import { AssignmentAPI } from '@modules/assignments/service/AssignmentAPI';
import TablePagination from '@components/table/TablePagination';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { TeacherExerciseStatus } from '@components/exercise/ExerciseStatus';

import { ExerciseActionTeacher } from '@components/common/assignment/teacher/AssignmentTeacherActions';
import { ResponsiveScreen } from '@hooks/useMedia';
import { InputGroup } from '@components/input/InputGroup';
import Card, { CardClassEnum } from '@components/card';
import { useTheme } from '@app/styled/StyledProvider';
import { AssignmentInstanceStatusRes } from '@modules/assignments/service/assignment_model';

const AssigmentInstanceStatusStyle = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1.6em;

  .assignment-chart-color {
    display: inline-block;
    text-align: center;
  }
`;

const AssignmentStatusDetailStyle = styled.div`
  min-width: 250px;

  .assignment-progress-group {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
`;

export const AssigmentInstanceStatus = (props: {
  item: AssignmentInstanceStatusRes;
}) => {
  const completed =
    props.item.submitted + props.item.needGrading + props.item.completed;

  const percent = Math.round((completed * 100) / props.item.total);

  return (
    <AssigmentInstanceStatusStyle className={'assignment-status'}>
      <Progress
        percent={percent}
        format={() => completed + '/' + props.item.total}
      />
    </AssigmentInstanceStatusStyle>
  );
};

export const AssignmentProgressStatus = (props: {
  item: AssignmentInstanceStatusRes;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<'summary' | 'detail'>('summary');

  const colorArray = [
    {
      type: 'notStarted',
      color: theme.assignment.not_started,
      value: props.item.notStarted,
      name: t('exercise.status.not_start'),
    },
    {
      type: 'inProgress',
      color: theme.assignment.in_progress,
      value: props.item.viewed + props.item.inProgress,
      name: t('exercise.status.in_progress'),
    },
    {
      type: 'needGrading',
      color: theme.assignment.need_grading,
      value: props.item.needGrading,
      name: t('exercise.status.need_grading'),
    },
    {
      type: 'completed',
      color: theme.assignment.completed,
      value: props.item.completed,
      name: t('exercise.status.completed'),
    },
  ];

  return (
    <AssignmentStatusDetailStyle className={'detail-assignment-data'}>
      <HeaderGroup style={{ marginTop: 0 }}>
        <H2>
          {t('assignment.title')}: {props.item.assignment.name}
        </H2>

        <Segmented
          block
          value={viewMode}
          onChange={(val) => setViewMode(val as 'summary' | 'detail')}
          options={[
            {
              value: 'summary',
              label: <AlignLeftOutlined />,
              title: t('label.summary'),
            },
            {
              value: 'detail',
              label: <TableOutlined />,
              title: t('label.detail'),
            },
          ]}
        />
      </HeaderGroup>

      <div style={{ marginTop: 20 }}>
        {viewMode === 'summary' ? (
          <Row>
            <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
              {colorArray.map((col, index) => {
                return (
                  <div className={'assignment-progress-group'} key={col.type}>
                    <H5 style={{ color: col.color, marginBottom: 0 }}>
                      {col.name}:{' '}
                    </H5>

                    <Progress
                      strokeColor={col.color}
                      percent={Math.round((col.value * 100) / props.item.total)}
                      format={() => col.value + '/' + props.item.total}
                    />
                  </div>
                );
              })}
            </Col>
          </Row>
        ) : (
          <AssignmentStatusDetailTable
            assignmentInstanceId={
              props.item.assignmentInstance.assignmentInstanceId
            }
          />
        )}
      </div>
    </AssignmentStatusDetailStyle>
  );
};

const AssignmentStatusDetailTable = (props: {
  assignmentInstanceId: number;
}) => {
  const { t } = useTranslation();

  const [getAssignmentInstanceStatus, { data, isFetching }] =
    AssignmentAPI.endpoints.getAssignmentInstanceStatus.useLazyQuery();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'status',
    direction: 'ascend',
    selectAll: true,
  });

  useEffect(() => {
    getAssignmentInstanceStatus({
      assignmentInstanceId: props.assignmentInstanceId,
      params: { ...params },
    });
  }, [props.assignmentInstanceId, params]);

  const columns = [
    {
      title: t('label.avatar'),
      dataIndex: 'avatar',
      key: 'avatar',
      width: 40,
      align: 'center',
    },
    {
      title: t('label.firstName'),
      dataIndex: 'user.firstName',
      key: 'firstName',
      width: '15%',
      sorter: true,
    },
    {
      title: t('label.lastName'),
      dataIndex: 'user.lastName',
      key: 'lastName',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.email'),
      dataIndex: 'user.email',
      key: 'email',
      sorter: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      width: '',
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '',
      align: 'center',
    },
  ];

  const processDataRow = (item: ExerciseRes) => {
    return {
      avatar: <UserAvatar src={item.student?.avatar} />,
      'user.firstName': item.student?.firstName,
      'user.lastName': item.student?.lastName,
      'user.email': item.student?.email,
      status: <TeacherExerciseStatus status={item.status} />,
      actions: (
        <ExerciseActionTeacher
          assignmentInstanceId={props.assignmentInstanceId}
          item={item}
        />
      ),
    };
  };

  const refreshData = (params: any) => {
    setParams({ ...params });
  };

  const renderMobile = (item: ExerciseRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <AssignmentStatusStyle className={'assignment-status-detail'}>
          <div className={'assignment-thumbnails'}>
            <UserAvatar src={item.student?.avatar} />
            <TeacherExerciseStatus status={item.status} />
          </div>

          <div className={'assignment-info'}>
            <InputGroup
              label={
                <>
                  {t('label.firstName')} {t('label.lastName')}
                </>
              }
            >
              {item.student?.firstName} {item.student?.lastName}
            </InputGroup>

            <InputGroup label={t('label.email')}>
              {item.student?.email}
            </InputGroup>
          </div>

          <div className={'assignment-action'}>
            <ExerciseActionTeacher
              assignmentInstanceId={props.assignmentInstanceId}
              item={item}
            />
          </div>
        </AssignmentStatusStyle>
      </Card>
    );
  };

  return (
    <TablePagination
      params={params}
      isLoading={isFetching}
      columns={columns}
      data={data}
      refresh={refreshData}
      processDataRow={processDataRow}
      responsive={{
        screen: [ResponsiveScreen.xs, ResponsiveScreen.sm, ResponsiveScreen.md],
        render: renderMobile,
      }}
    />
  );
};

const AssignmentStatusStyle = styled.div`
  display: flex;
  position: relative;
  gap: 1em;

  .assignment-thumbnails {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-avatar {
      margin-bottom: 0.25em;
    }
  }

  .assignment-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
