import React, { ReactNode, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import '@styles/globals.scss';
import { ConfigProvider } from 'antd';
import { useSelector } from '@app/redux/hook';
import { getThemeColor } from '@app/styled/themeColors';
import { useTheme as useThemStyled } from 'styled-components';

export const StyledProvider = (props: { children: ReactNode }) => {
  const { theme } = useSelector((state) => state.appLayout);

  const themeColors = useMemo(() => {
    return getThemeColor(theme);
  }, [theme]);

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          fontFamily: 'Montserrat',
          fontSize: 14,
          colorPrimary: themeColors.app.primary,
          // colorBgBase: themeColors.app.background,

          colorSuccess: themeColors.warning.success,
          colorWarning: themeColors.warning.warning,
          colorError: themeColors.warning.error,
          colorInfo: themeColors.warning.info,

          // colorTextBase: themeColors.warning.success,
          // colorBgBase: themeColors.warning.success,
          ...themeColors,
        },
      }}
    >
      <ThemeProvider theme={themeColors}>{props.children}</ThemeProvider>
    </ConfigProvider>
  );
};

export const useTheme = (): Record<string, any> => {
  return useThemStyled();
};

//  background: ${(props) => props.theme.app.primary};
