import React from 'react';
import styled from 'styled-components';
import { ComponentScoreIcon } from '@cms/icons/ComponentScoreIcon';

const ExerciseScoreStyle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const ExerciseScore = ({
  skip,
  score,
  maxScore,
}: {
  size?: number;
  skip: boolean | null;
  score: number | null;
  maxScore: number;
}) => {
  return (
    <ExerciseScoreStyle>
      <ComponentScoreIcon
        isSkip={skip != null && skip}
        score={score}
        maxScore={maxScore}
      />
    </ExerciseScoreStyle>
  );
};

export default ExerciseScore;
