import React from 'react';
import styled from 'styled-components';
import { ReviewExerciseButton } from '../buttons/exercise/ReviewExerciseButton';
import { SelfCheckBtn } from '../button-wrapper/SelfCheckBtn';
import {
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';
import SubmitGradingScoringButton from '@cms/lesson-template/components/buttons/SubmitGradingScoringButton';
import { DoAssignmentGroupBtn } from '../button-wrapper/DoAssignmentGroupBtn';
import { DoAssignmentCompleteBtn } from '../button-wrapper/DoAssignmentCompleteBtn';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';

export const MultipleSectionToolbar = () => {
  const { interact } = useLessonNavigationContext();

  return (
    <>
      <SelfCheckExerciseMode>
        <MultipleSectionToolbarStyle
          className={'multiple-section-toolbar self-check-toolbar'}
        >
          {interact && <SelfCheckBtn />}
        </MultipleSectionToolbarStyle>
      </SelfCheckExerciseMode>

      <DoExerciseMode>
        <MultipleSectionToolbarStyle
          className={'multiple-section-toolbar do-assignment-toolbar'}
        >
          {interact ? <DoAssignmentGroupBtn /> : <DoAssignmentCompleteBtn />}
        </MultipleSectionToolbarStyle>
      </DoExerciseMode>

      <GradingExerciseMode>
        <MultipleSectionToolbarStyle
          className={'multiple-section-toolbar grading-toolbar'}
        >
          <SubmitGradingScoringButton />
        </MultipleSectionToolbarStyle>
      </GradingExerciseMode>

      <ReviewExerciseMode>
        <ReviewExerciseButton />
      </ReviewExerciseMode>
    </>
  );
};

const MultipleSectionToolbarStyle = styled.div`
  margin-top: 1.5em;
  margin-bottom: 1em;

  &:empty {
    display: none;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
