import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResourceAnswerProps } from '@app/redux/slices/viewResource';

import { collectExerciseAnswer } from '../toolbar/handler/collectExerciseAnswer';
import LessonButton from './style';
import { useSelector } from '@app/redux/hook';
import { IconUtils } from '@utils/IconUtils';
import { useTheme } from '@app/styled/StyledProvider';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import { DoExerciseAPI } from '@modules/assignments/service/DoExerciseAPI';

export const CheckAnswerButton = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { config } = useLessonTemplateContext();

  const [selfCheck] = DoExerciseAPI.endpoints.selfCheck.useMutation();

  const {
    lessonId,
    isShowCorrectAnswer,
    isCheckAnswerMode,
    dispatchExerciseAction,
  } = useViewLessonContext();

  const { currentAnswer } = useSelector((state) => state.viewResource);

  const selfCheckExercise = () => {
    const request = {
      lessonId: lessonId,
      answers: collectExerciseAnswer(currentAnswer),
    };

    selfCheck(request)
      .unwrap()
      .then((result) => {
        const answers: ResourceAnswerProps[] = result.map((res) => {
          return { resourceId: res.resourceId, answers: res.answer };
        });

        dispatchExerciseAction(ExerciseActionEnum.show_self_check_result, {
          answers: answers,
          feedBacks: result,
        });
      })
      .catch((err) => {
        console.log('Have error when save exercise', err);
      });
  };

  return (
    <>
      {!isShowCorrectAnswer && !isCheckAnswerMode && (
        <>
          {config.layout === LessonTemplateLayoutEnum.presentation ? (
            <LessonButton
              type={'primary'}
              shape={'circle'}
              style={{ background: theme.exercise.check_answer }}
              onClick={selfCheckExercise}
              icon={IconUtils.exercise.check_answer}
            />
          ) : (
            <LessonButton
              type={'primary'}
              shape={'round'}
              style={{ background: theme.exercise.check_answer }}
              onClick={selfCheckExercise}
              icon={IconUtils.exercise.check_answer}
            >
              {t('exercise.button.check_answer')}
            </LessonButton>
          )}
        </>
      )}
    </>
  );
};
