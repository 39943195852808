import React, { useEffect, useRef, useState } from 'react';
import { CoordinateSettingProps, CoordinateUtils } from './CoordinateUtils';
import styled from 'styled-components';
import useAnnotationLib from '@cms/script/useAnnotationLib';
import useResizeObserver from '@react-hook/resize-observer';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import {
  CoordinateActionGroup,
  coordinateActionGroups,
} from '@cms/comps/interact/coordinate/CoordinateActionGroup';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import {
  CompAnswerProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { CoordinateActionEnum } from '@cms/comps/interact/coordinate/CoordinateEnum';

export interface CoordinateObjectProps {
  objectId: string;
  objectType: string;
  props: any;
  color: string;
  borderColor: string;
}

export interface CoordinateProps extends CompProps {
  type: CompTypeEnum.COORDINATE;
  configuration: {
    sourceItems: CoordinateObjectProps[];
  };
  setting: CoordinateSettingProps;
}

export interface CoordinateAnsProps extends CompAnswerProps {
  answer: any;
}

export const CoordinateComp = (props: {
  disabled: boolean;
  item: CoordinateProps;
  answer: CoordinateAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const backgroundRef = useRef(null);
  const coordinateUtils = useRef<CoordinateUtils>(new CoordinateUtils());
  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  const [answer, setAnswer] = useState<{
    value: any;
    triggerChange: boolean;
  }>({
    value: null,
    triggerChange: false,
  });

  const [ready, setReady] = useState(false);
  const [action, setAction] = useState({
    action: CoordinateActionEnum.DEFAULT,
    params: null,
    timestamp: 0,
  });

  useAnnotationLib(() => {
    setReady(true);
  });

  useResizeObserver(backgroundRef, (entry) => {
    coordinateUtils.current.setContainerSize(entry.contentRect);
  });

  useEffect(() => {
    if (props.answer) {
      setAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
      coordinateUtils.current.setAnswer(props.answer.answer);
    } else {
      setAnswer({
        value: null,
        triggerChange: false,
      });
      coordinateUtils.current.setAnswer(null);
    }
  }, [props.answer]);

  useEffect(() => {
    if (backgroundRef.current) {
      coordinateUtils.current.init(backgroundRef.current);
      coordinateUtils.current.onChange(handleAnswerChange);
    }
  }, [backgroundRef]);

  useEffect(() => {
    if (ready) {
      coordinateUtils.current.setReady(true);
    } else {
      coordinateUtils.current.setReady(false);
    }
  }, [ready]);

  useEffect(() => {
    coordinateUtils.current.setSetting(props.item.setting);
  }, [props.item.setting]);

  useEffect(() => {
    coordinateUtils.current.setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    if (coordinateUtils.current != null) {
      coordinateUtils.current.setAction(action.action, action.params);
    }
  }, [action]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  const handleAnswerChange = (answer: any) => {
    setAnswer({ value: answer, triggerChange: true });
  };

  const changeAction = (action: CoordinateActionEnum, params: any) => {
    setAction({
      action: action,
      params: params,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      <CoordinateCompStyle
        className={`coordinate-graph-comp ${props.item.setting?.className}`}
      >
        <div className={'coordinate-graph-wrap'}>
          <div ref={backgroundRef} className={'coordinate-graph-workspace'} />
        </div>

        <div className={'coordinate-graph-toolbar'}>
          {coordinateActionGroups.map((act) => {
            return (
              <CoordinateActionGroup
                key={act.group}
                action={action.action}
                item={act}
                onClick={changeAction}
              />
            );
          })}
        </div>

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </CoordinateCompStyle>
    </CompFeedbackContextProvider>
  );
};

const CoordinateCompStyle = styled.div`
  display: flex;

  .coordinate-graph-wrap {
    order: 1;
    display: flex;
    flex-grow: 1;
    padding: 10px;

    .coordinate-graph-workspace {
      position: relative;
      display: inline-flex;

      svg {
        border: none;
        background: #fff;
        outline: 1px solid #ccc;
      }
    }
  }

  .coordinate-graph-toolbar {
    order: 2;
  }
`;
