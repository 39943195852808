import { ProgramRes } from '@modules/admin/service/program';
import { ImageScale } from '@utils/ImageScale';
import IconImage from '@components/images/IconImage';
import React from 'react';
import styled from 'styled-components';

export const ProgramDisplay = (props: {
  className?: string;
  item: ProgramRes;
}) => {
  return (
    <ProgramDisplayStyle className={`program-display ${props.className ?? ''}`}>
      <IconImage
        src={props.item.icon}
        width={ImageScale.program.icon}
        height={ImageScale.program.icon}
      />

      <span className={'program-title'}>
        {props.item.code} - {props.item.name}
      </span>
    </ProgramDisplayStyle>
  );
};

const ProgramDisplayStyle = styled.div`
  display: flex;
  align-items: center;

  .program-title {
    flex-grow: 1;
    white-space: nowrap;
    margin-left: 1ch;
    text-align: left;
  }

  &.bordered {
    border: 1px solid #ccc;
    padding: 0.25em 0.75em;
    border-radius: 1.5em;
  }
`;
