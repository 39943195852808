import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { CircleUnderlineProps } from '@cms/comps/interact/circle-underline/CircleUnderlineComp';

export const CircleUnderlineExampleData: CircleUnderlineProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.CIRCLE_UNDERLINE,

  configuration: {
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Underline the word abcdef and circle the word ghk.',
        },
      },
    ],
  },

  setting: {
    autoScore: true,
    actions: ['circle', 'underline'],
  },
};
