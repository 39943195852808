import React, { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { EntityStatusEnum } from '@services/model/common';
import { ComposingStatus } from '@components/common/lesson/ComposingStatus';

interface SelectEntityStatusProps extends SelectProps {
  formik?: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  name: string;
  selectOptions: EntityStatusEnum[];
  value?: EntityStatusEnum | null;
  onChange?: (value: number) => void;
}

const SelectEntityStatus = (props: SelectEntityStatusProps) => {
  const { t } = useTranslation();

  const handleOnInputChange = (value: number) => {
    if (props.onChange) {
      props.onChange(value);
    } else if (props.formik) {
      props.formik.setFieldValue(props.name, value);
    }
  };

  return (
    <InputGroup label={props.label ?? t('label.status')}>
      <Select
        showSearch
        style={{ width: '100%' }}
        size={'large'}
        placeholder={props.placeholder}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value ?? props.formik.values[props.name]}
        filterOption={false}
      >
        <Select.Option
          key={EntityStatusEnum.EMPTY}
          value={EntityStatusEnum.EMPTY}
        >
          <span>{t('label.compose_status.select')}</span>
        </Select.Option>

        {props.selectOptions != null &&
          props.selectOptions.map((op) => {
            return (
              <Select.Option key={op} value={op}>
                <ComposingStatus status={op} />
              </Select.Option>
            );
          })}
      </Select>
    </InputGroup>
  );
};

export default SelectEntityStatus;
