import { Card as AntdCard, CardProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const CardStyle = styled(AntdCard)`
  margin-bottom: 3em;
  border-radius: 2em;
  box-shadow: 0 0 1px rgb(0 0 0 / 10%), 0 1px 10px rgb(0 0 0 / 10%);

  &.small-radius {
    border-radius: 1em !important;
  }

  &.no-vertical-spacing {
    .ant-card-body {
      padding: 0 !important;
    }
  }

  &.no-margin {
    margin-bottom: 0 !important;
  }

  &.card-empty {
    border-radius: 0;
    box-shadow: none;
    background: transparent;

    .ant-card-body {
      padding: 1em 0em;

      .screen-xs & {
        padding: 8px 0px;
      }

      .screen-sm & {
        padding: 10px 0px;
      }

      .screen-md & {
        padding: 12px 0px;
      }

      .screen-lg & {
        padding: 16px 0px;
      }

      .screen-xl & {
        padding: 16px 0px;
      }

      .screen-xxl & {
        padding: 16px 0px;
      }
    }
  }

  &.rectangle-card {
    border-radius: 0;
    width: 100%;

    .ant-card-body {
      padding: 1em 2em;

      .screen-xs & {
        padding: 8px;
      }

      .screen-sm & {
        padding: 10px;
      }

      .screen-md & {
        padding: 12px;
      }

      .screen-lg & {
        padding: 16px;
      }

      .screen-xl & {
        padding: 16px;
      }

      .screen-xxl & {
        padding: 16px;
      }
    }
  }

  &.round-box-card {
    border-radius: 1em;

    .ant-card-body {
      padding: 1em;

      .screen-xs & {
        padding: 10px;
      }

      .screen-sm & {
        padding: 12px;
      }

      .screen-md & {
        padding: 16px;
      }

      .screen-lg & {
        padding: 24px;
      }

      .screen-xl & {
        padding: 24px;
      }

      .screen-xxl & {
        padding: 24px;
      }
    }
  }

  &.rectangle-box-card {
    border-radius: 0;
    margin-bottom: 1em;

    &.ant-card-bordered {
      border-radius: 8px;
    }

    //&:first-child {
    //  &:last-child {
    //    margin-bottom: 0;
    //  }
    //}

    .ant-card-body {
      padding: 2em;

      .screen-xs & {
        padding: 10px;
      }

      .screen-sm & {
        padding: 12px;
      }

      .screen-md & {
        padding: 16px;
      }

      .screen-lg & {
        padding: 20px;
      }

      .screen-xl & {
        padding: 20px;
      }

      .screen-xxl & {
        padding: 20px;
      }
    }
  }

  .ant-card-body {
    padding: 2em;

    .screen-xs & {
      padding: 8px;
    }

    .screen-sm & {
      padding: 10px;
    }

    .screen-md & {
      padding: 12px;
    }

    .screen-lg & {
      padding: 16px;
    }

    .screen-xl & {
      padding: 16px;
    }

    .screen-xxl & {
      padding: 16px;
    }
  }

  .ant-card-meta .ant-card-meta-title {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${(props) => props.theme.app.primary};
    }
  }
`;

export enum CardClassEnum {
  default = '',
  empty = 'card-empty',
  rectangle = 'rectangle-card',
  rectangle_box = 'rectangle-box-card',
  round_box = 'round-box-card',
  no_margin = 'no-margin',
}

const Card = ({ className, ...props }: CardProps) => {
  return (
    <CardStyle
      className={`gstudy-card ${className ? className : ''}`}
      {...props}
    />
  );
};

export default Card;
