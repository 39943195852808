import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import React, { useEffect, useState } from 'react';
import { CompMode } from '@cms/ComponentInteface';
import { PracticeExerciseAPI } from '@modules/assignments/service/PracticeExerciseAPI';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import PracticeToolbar from '@cms/lesson-template/practice/PracticeToolbar';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import {
  ExerciseEventEnum,
  PracticeActionEnum,
  PracticeResourceState,
  usePracticeContext,
} from '@cms/lesson-template/context/PracticeContext';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';
import {
  CheckAnswerReq,
  SelfCheckQuestionReq,
} from '@modules/assignments/service/exercise_model';

export const CheckAnswerPracticeButton = () => {
  const [checkAnswer] = PracticeExerciseAPI.endpoints.checkAnswer.useMutation();
  const [selfCheckAnswer] =
    PracticeExerciseAPI.endpoints.selfCheckAnswer.useMutation();

  const { resource, section, lastSection, lastQuestion } =
    useLessonNavigationContext();

  const { exerciseId } = useExerciseServiceContext();

  const {
    state,
    initState,
    complete,
    onAnswerChange,
    clearAnswer,
    showAnswerResult,

    triggerEvent,
  } = usePracticeContext();

  const { type, lessonId, getResourceAnswers } = useViewLessonContext();

  const answers = getResourceAnswers([resource.resourceId]);

  const [currentTime, setCurrentTime] = useState(
    DateAndTimeUtils.getCurrentTime()
  );

  useEffect(() => {
    if (resource.resourceId > 0) {
      initState(
        [resource.resourceId],
        resource.interact,
        PracticeResourceState.IN_PROGRESS,
        false,
        0
      );
      setCurrentTime(DateAndTimeUtils.getCurrentTime());
    } else {
      complete(lastSection, lastQuestion);
    }
  }, [resource.resourceId]);

  useEffect(() => {
    onAnswerChange(answers);
  }, [JSON.stringify(answers)]);

  const handleClick = (action: PracticeActionEnum) => {
    if (action === PracticeActionEnum.checkAnswer) {
      checkAnswerQuestion();
    } else if (action === PracticeActionEnum.clearAnswer) {
      clearAnswer([resource.resourceId]);
    }
  };

  const checkAnswerQuestion = () => {
    const duration = DateAndTimeUtils.getCurrentTime() - currentTime;

    if (
      type === CompMode.SELF_CHECK ||
      type === CompMode.VIEW ||
      type === CompMode.PRESENTATION
    ) {
      const studentAns: CheckAnswerReq[] = [];
      const resourcesIds: number[] = [];

      answers.forEach((ans) => {
        studentAns.push({
          exerciseId: exerciseId,
          resourceId: ans.resourceId,
          sectionId: section.sectionId,
          duration: duration,
          answer: JSON.stringify(ans.answers, null, 2),
          submitted: lastQuestion && lastSection,
          tryTimes: state.tryTimes,
        });
        resourcesIds.push(ans.resourceId);
      });

      const request: SelfCheckQuestionReq = {
        lessonId: lessonId,
        answers: studentAns,
      };

      selfCheckAnswer(request)
        .unwrap()
        .then((result) => {
          showAnswerResult(result.answers[0], lastSection, lastQuestion);
        })
        .catch(() => {
          alert('Have error when save exercise');
        });

      triggerEvent(ExerciseEventEnum.check_answer, resourcesIds);
    } else {
      const resourcesIds: number[] = [resource.resourceId];

      const request: CheckAnswerReq = {
        exerciseId: exerciseId,
        sectionId: section.sectionId,
        resourceId: resource.resourceId,
        duration: duration,
        answer: JSON.stringify(answers[0].answers, null, 2),
        submitted: lastQuestion && lastSection,
      };

      checkAnswer(request)
        .unwrap()
        .then((result) => {
          showAnswerResult(result.answers[0], lastSection, lastQuestion);
        })
        .catch(() => {
          alert('Have error when save exercise');
        });

      triggerEvent(ExerciseEventEnum.check_answer, resourcesIds);
    }
  };

  return (
    <PracticeToolbar
      resourceIds={[resource.resourceId]}
      interactive={resource.interact}
      state={state.state}
      answered={state.answered}
      onClick={handleClick}
    />
  );
};
