import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import {
  DragItemProps,
  DragNDropSplitLabel,
  useDragNDropContext,
} from '@components/react-dnd-bt/DragNDropContext';
import { useDropContext } from '@components/react-dnd-bt/DropItem';
import styled from 'styled-components';

export interface DragDataProps {
  draggableId: string;
  droppableId: string;
  acceptType: string;
  id: string;
}

export const generateDragId = (
  draggableId: string,
  acceptType: string,
  droppableId: string,
  containerId: string
) => {
  return [draggableId, droppableId, acceptType, containerId].join(
    DragNDropSplitLabel
  );
};

export const parseDragData = (id: string): DragDataProps => {
  const [draggableId, droppableId, acceptType, containerId] =
    id.split(DragNDropSplitLabel);

  return {
    draggableId: draggableId,
    droppableId: droppableId,
    acceptType: acceptType,
    id: containerId,
  };
};

export function DragItem(props: DragItemProps) {
  const { id, staticDrag } = useDragNDropContext();
  const { droppableId, disabledIds } = useDropContext();
  const draggableId = generateDragId(
    props.draggableId,
    props.acceptType,
    droppableId,
    id
  );

  const isDisabledDrag =
    props.disabled || disabledIds.includes(props.draggableId);

  return (
    <Draggable
      draggableId={draggableId}
      index={props.index}
      isDragDisabled={isDisabledDrag}
    >
      {(provided, snapshot) => {
        const className = `draggable-item 
          ${snapshot.isDragging ? ' dragging' : ''} 
          ${isDisabledDrag ? 'disabled-draggable' : ''} 
          ${props.className ? props.className : ''}
          ${staticDrag ? 'drag-static' : 'drag-dynamic'}
        `;

        return (
          <React.Fragment>
            <DragItemStyle
              ref={provided.innerRef}
              className={className}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
            >
              {props.children}
            </DragItemStyle>

            {staticDrag && (
              <>
                {snapshot.isDragging && (
                  <CloneDragItemStyle
                    className={`${className} clone-draggable-item`}
                  >
                    {props.children}
                  </CloneDragItemStyle>
                )}
              </>
            )}
          </React.Fragment>
        );
      }}
    </Draggable>
  );
}

const DragItemStyle = styled.div`
  &.dragging {
    background: rgba(0, 0, 0, 0.03);
  }

  &.disabled-draggable {
    cursor: not-allowed;
    filter: grayscale(0.9);
  }

  &:not(.dragging) {
    transform: none !important;
  }
`;

const CloneDragItemStyle = styled(DragItemStyle)``;
