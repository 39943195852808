import useConfirmModal from '@components/modal/ConfirmModal';

import { useCallback, useEffect, useRef, useState } from 'react';
import {
  EditResourceAction,
  ResourceActionProps,
} from '@modules/product/components/resource/toolbar/MovingResourceItem';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { AssetsRes } from '@modules/product/services/assets_model';
import { CompProps, CompScoringProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import {
  ComposeLessonActionEnum,
  dispatchComposeLessonAction,
  warningMessage,
} from '@app/redux/slices/composeLesson';
import { useTranslation } from 'react-i18next';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { EditModeEnum } from '../../lesson/ComposeLessonProps';
import { useProUser } from '@components/button/RequireProUserButton';
import { useDispatch, useSelector } from '@app/redux/hook';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { useLessonEditableContext } from '@cms/context/LessonEditableProvider';
import {
  AddResourceToLessonReq,
  CreateResourceReq,
} from '@modules/product/services/resource_model';

export const useMovingResourceContext = (editMode: EditModeEnum) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const dispatch = useDispatch();
  const router = useRouter();
  const proUser = useProUser();

  const { lesson } = useLessonEditableContext();

  const { productId, productTocId, resources } = useSelector(
    (state) => state.composeLesson
  );

  const resourceActions = useRef<ResourceActionProps[]>([]);

  const [addResourceToLesson] =
    LessonAPI.endpoints.addResourceToLesson.useMutation({});
  const [createResource] = ResourceAPI.endpoints.createResource.useMutation({});

  const [moveResourceTop] = LessonAPI.endpoints.moveResourceTop.useMutation({});
  const [moveResourceUp] = LessonAPI.endpoints.moveResourceUp.useMutation({});
  const [moveResourceDown] = LessonAPI.endpoints.moveResourceDown.useMutation(
    {}
  );
  const [moveResourceBottom] =
    LessonAPI.endpoints.moveResourceBottom.useMutation({});

  const [cloneResource] = ResourceAPI.endpoints.cloneResource.useMutation({});
  const [removeFromLesson] = ResourceAPI.endpoints.removeFromLesson.useMutation(
    {}
  );

  const [nextQN, setNextQN] = useState(resources.length + 1);

  useEffect(() => {
    setNextQN(resources.length + 1);
  }, [resources]);

  const onListenActions = (params: ResourceActionProps) => {
    resourceActions.current.push(params);
  };

  const handleOnUploadFiles = (file: AssetsRes) => {
    const fileComp: CompProps = COMPONENT_UTILS.generateComps(file);
    createNewResource([fileComp]);
  };

  const handleDropComponent = (
    compData: CompProps[],
    dropItem: { resourceId: number }
  ) => {
    const newComps = compData.map((comp) => {
      return COMPONENT_UTILS.generateComponent(comp);
    });

    if (dropItem.resourceId != null && dropItem.resourceId > 0) {
      onAddComponentToResource(dropItem.resourceId, newComps);
    } else {
      createNewResource(newComps);
    }
  };

  const createNewResource = useCallback(
    (components: CompProps[]) => {
      const scoring: CompScoringProps[] = [];

      components.forEach((comp) => {
        if (ResourceUtils.isInteractComp(comp.type)) {
          scoring.push(ResourceUtils.generateInteractScoring(comp));
        }
      });

      const request: CreateResourceReq = {
        lessonId: lesson!.lessonId,
        displayOrder: resources.length + 1,
        content: JSON.stringify(components, null, 2),
        scoring: scoring.length > 0 ? JSON.stringify(scoring) : '',
        correctAnswer: '',
      };

      createResource(request)
        .unwrap()
        .then((saveData) => {
          if (editMode === EditModeEnum.MULTIPLE_QUESTION) {
            // change to edit resource mode
            router.push(
              SiteMap.content.lesson.compose_resource_gen(
                lesson!.lessonId,
                proUser,
                saveData.resourceId,
                productId,
                productTocId
              )
            );
          } else {
            dispatch(
              dispatchComposeLessonAction({
                action: ComposeLessonActionEnum.refresh,
                params: saveData.resourceId,
              })
            );
          }
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    },
    [resources]
  );

  const onAddComponentToResource = (resourceId: number, newComp: any) => {
    resourceActions.current.forEach((item) => {
      if (item.key === EditResourceAction.ADD_COMPONENT) {
        item.callBackFunc(resourceId, newComp);
      }
    });
  };

  const handleOnActionClick = (
    action: EditResourceAction,
    resource: ResourceProps
  ) => {
    const request = {
      lessonId: lesson!.lessonId,
      resourceId: resource.resourceId,
    };

    if (action === EditResourceAction.MOVE_UP) {
      moveResourceUp(request)
        .unwrap()
        .then(() => {
          dispatch(
            dispatchComposeLessonAction({
              action: ComposeLessonActionEnum.refresh,
              params: null,
            })
          );
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    } else if (action === EditResourceAction.MOVE_DOWN) {
      moveResourceDown(request)
        .unwrap()
        .then(() => {
          dispatch(
            dispatchComposeLessonAction({
              action: ComposeLessonActionEnum.refresh,
              params: null,
            })
          );
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    } else if (action === EditResourceAction.MOVE_TOP) {
      moveResourceTop(request)
        .unwrap()
        .then(() => {
          dispatch(
            dispatchComposeLessonAction({
              action: ComposeLessonActionEnum.refresh,
              params: null,
            })
          );
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    } else if (action === EditResourceAction.MOVE_BOTTOM) {
      moveResourceBottom(request)
        .unwrap()
        .then(() => {
          dispatch(
            dispatchComposeLessonAction({
              action: ComposeLessonActionEnum.refresh,
              params: null,
            })
          );
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    } else if (action === EditResourceAction.CLONE) {
      cloneResource(request)
        .unwrap()
        .then((saveData) => {
          dispatch(
            dispatchComposeLessonAction({
              action: ComposeLessonActionEnum.refresh,
              params: saveData.resourceId,
            })
          );
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    } else if (action === EditResourceAction.DELETE) {
      confirm(
        'danger',
        t('header.confirm'),
        t('component.actions.delete_component'),
        t('button.confirm'),
        t('button.cancel'),
        (result) => {
          if (result) {
            removeFromLesson(request)
              .unwrap()
              .then(() => {
                dispatch(
                  dispatchComposeLessonAction({
                    action: ComposeLessonActionEnum.refresh,
                    params: null,
                  })
                );
              })
              .catch(() => {
                dispatch(warningMessage(false));
              });
          }
        }
      );
    } else if (action === EditResourceAction.EDIT) {
      router.push(
        SiteMap.content.lesson.compose_resource_gen(
          lesson!.lessonId,
          proUser,
          resource.resourceId,
          productId,
          productTocId
        )
      );
    } else if (action === EditResourceAction.ADD_TO_LESSON) {
      const request: AddResourceToLessonReq = {
        lessonId: lesson!.lessonId,
        resourceId: resource.resourceId,
        displayOrder: resources.length + 1,
      };

      addResourceToLesson(request)
        .unwrap()
        .then(() => {
          dispatch(
            dispatchComposeLessonAction({
              action: ComposeLessonActionEnum.refresh,
              params: null,
            })
          );
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    }
  };

  return {
    nextQN,
    onListenActions,
    handleOnUploadFiles,
    handleDropComponent,
    handleOnActionClick,
  };
};
