import React, { useEffect } from 'react';
import { Button, Divider, notification, Switch } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';

import {
  CreateOauthAppReq,
  OauthAppAPI,
  OauthAppRes,
} from '@services/private/OauthAppAPI';
import { InputGroup } from '@components/input/InputGroup';
import { RoleEnum } from '@app/redux/slices/roles';
import { UserPermission } from '@modules/users/component/permission/UserPermission';

const CreateOauthAppForm = (props: {
  oauthAppId: number;
  onSuccess: (newData: OauthAppRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [findById, { data, isSuccess }] =
    OauthAppAPI.endpoints.findById.useLazyQuery();

  const [createOauthApp, { isLoading: createLoading }] =
    OauthAppAPI.endpoints.create.useMutation({});

  const [updateOauthApp, { isLoading: updateLoading }] =
    OauthAppAPI.endpoints.update.useMutation({});

  useEffect(() => {
    if (props.oauthAppId != null && props.oauthAppId > 0) {
      findById(props.oauthAppId);
    }
  }, [props.oauthAppId]);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setValues({
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        scopes:
          data.scopes != null && data.scopes.trim() !== ''
            ? (data.scopes.split(',') as RoleEnum[])
            : [],
        redirectUrl: data.redirectUrl,

        name: data.name,
        description: data.description,
        enable: data.enable,
      });
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      clientId: '',
      clientSecret: '',
      scopes: [] as RoleEnum[],
      redirectUrl: '',

      name: '',
      description: '',
      enable: false,
    },

    validationSchema: yup.object().shape({
      // scopes: yup.string().required(t('validation.required.field')),
      // redirectUrl: yup.string().required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request = { ...values, scopes: values.scopes.join(',') };
      handleOnSubmit(request);
    },
  });

  const handleOnSubmit = (values: CreateOauthAppReq) => {
    if (props.oauthAppId != null && props.oauthAppId > 0) {
      updateOauthApp({
        oauthAppId: props.oauthAppId,
        ...values,
      })
        .unwrap()
        .then((userData) => {
          props.onSuccess(userData);

          notification.success({
            message: t('oauth_app.warning.update_success'),
            description: t('oauth_app.warning.update_success_message'),
            placement: 'bottomRight',
          });
        })
        .catch(() => {
          notification.error({
            message: t('oauth_app.warning.add_error'),
            description: t('oauth_app.warning.add_error_message'),
            placement: 'bottomRight',
          });
        });
    } else {
      createOauthApp(values)
        .unwrap()
        .then((userData) => {
          props.onSuccess(userData);

          notification.success({
            message: t('oauth_app.warning.add_success'),
            description: t('oauth_app.warning.add_success_message'),
            placement: 'bottomRight',
          });
        })
        .catch(() => {
          notification.error({
            message: t('oauth_app.warning.add_error'),
            description: t('oauth_app.warning.add_error_message'),
            placement: 'bottomRight',
          });
        });
    }
  };

  const handleOnChange = (roles: RoleEnum[]) => {
    formik.setFieldValue('scopes', roles);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Input
          required={true}
          name="name"
          type="text"
          label={t('label.name')}
          placeholder={t('label.description')}
          onChange={formik.handleChange}
          value={formik.values.name}
        />

        <Input
          name="description"
          type="text"
          label={t('label.description')}
          placeholder={t('label.description')}
          onChange={formik.handleChange}
          value={formik.values.description}
        />

        <InputGroup label={t('label.activated')}>
          <Switch
            checked={formik.values.enable}
            onChange={(val) => formik.setFieldValue('enable', val)}
          />
        </InputGroup>

        <Divider />

        {props.oauthAppId != null && props.oauthAppId > 0 && (
          <>
            <Input
              type="textarea"
              name="clientId"
              label={t('oauth_app.label.consumer')}
              placeholder={t('oauth_app.label.consumer')}
              onChange={formik.handleChange}
              value={formik.values.clientId}
              disabled={true}
            />

            <Input
              type="textarea"
              name="clientSecret"
              label={t('oauth_app.label.secret')}
              placeholder={t('oauth_app.label.secret')}
              onChange={formik.handleChange}
              value={formik.values.clientSecret}
              disabled={true}
            />
          </>
        )}

        <UserPermission
          value={formik.values.scopes}
          onChange={handleOnChange}
        />

        <Input
          type="text"
          name="redirectUrl"
          label={t('oauth_app.label.redirect_url')}
          placeholder={t('oauth_app.label.redirect_url')}
          onChange={formik.handleChange}
          value={formik.values.redirectUrl}
        />

        <div className="submit-container">
          {props.oauthAppId != null && props.oauthAppId > 0 ? (
            <ButtonGroup>
              <Button
                type={'primary'}
                loading={createLoading || updateLoading}
                onClick={formik.submitForm}
              >
                {t('button.update')}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button type={'primary'} onClick={formik.submitForm}>
                {' '}
                {t('button.create')}
              </Button>
            </ButtonGroup>
          )}
        </div>
      </FormikProvider>
    </>
  );
};

export default CreateOauthAppForm;
