import React, { ReactNode, useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import useFilterOptions from '@hooks/useFilterOptions';
import { compareKeywords } from '@utils/common';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { ComposingWithStatus } from '@components/common/lesson/ComposingStatus';
import { OptionWrapper } from '@modules/common/components/style';
import { LessonRes } from '@modules/product/services/lesson_model';

interface SelectLessonProps extends SelectProps {
  formik?: any;
  productId: number;
  label?: ReactNode;
  placeholder?: ReactNode;

  name: string;
  value?: number;
  onChange?: (value: number) => void;
}

const SelectLesson = (props: SelectLessonProps) => {
  const { t } = useTranslation();
  const [findAll, { data, isSuccess, isFetching }] =
    LessonAPI.endpoints.findAll.useLazyQuery();

  const [
    getProductLessons,
    {
      data: lessonByProducts,
      isSuccess: lessonByProductSuccess,
      isFetching: lsPdFetching,
    },
  ] = ProductAPI.endpoints.getProductLessons.useLazyQuery();

  const [lessons, setLessons] = useState<LessonRes[]>([]);
  const filter = useFilterOptions<LessonRes>(lessons, (opt, keyword) => {
    const keywords =
      keyword != null && keyword.trim() !== ''
        ? keyword.toLowerCase().trim().split(' ')
        : null;

    return (
      keywords == null ||
      compareKeywords(opt.name, keywords) ||
      compareKeywords(opt.keyword, keywords) ||
      compareKeywords(opt.description, keywords)
    );
  });

  // if filter by product
  useEffect(() => {
    if (isSuccess && data) {
      setLessons(data);
    }
  }, [data]);

  useEffect(() => {
    if (lessonByProducts && lessonByProductSuccess) {
      setLessons(lessonByProducts);
    }
  }, [lessonByProducts]);

  useEffect(() => {
    if (props.productId != null && props.productId > 0) {
      getProductLessons(props.productId);
    } else {
      findAll({});
    }
  }, [props.productId]);

  const handleOnInputChange = (value: number) => {
    if (props.onChange) {
      props.onChange(value);
    } else if (props.formik) {
      props.formik.setFieldValue(props.name, value);
    }
  };

  return (
    <InputGroup label={props.label ?? t('select.select_a_lesson')}>
      <Select
        showSearch
        style={{ width: '100%' }}
        size={'large'}
        placeholder={props.placeholder ?? t('select.select_a_lesson')}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value ?? props.formik.values[props.name]}
        loading={isFetching || lsPdFetching}
        searchValue={filter.keyword}
        onSearch={filter.onSearch}
        filterOption={false}
      >
        <Select.Option key={-1} value={-1}>
          <OptionWrapper>
            <ThumbnailsImg
              type={'icon'}
              src={''}
              ratio={ImageScale.lesson.ratio}
              width={32}
            />
            <span>{props.placeholder ?? t('select.select_a_lesson')}</span>
          </OptionWrapper>
        </Select.Option>

        {filter.options.map((ls) => {
          return (
            <Select.Option key={ls.lessonId} value={ls.lessonId}>
              <OptionWrapper>
                <ThumbnailsImg
                  type={'icon'}
                  src={ls.thumbnails}
                  ratio={ImageScale.lesson.ratio}
                  width={32}
                />
                <ComposingWithStatus status={ls.status}>
                  {ls.name}
                </ComposingWithStatus>
              </OptionWrapper>
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};

export default SelectLesson;
