import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { WordBoxSettingProps } from '@cms/comps/game/word-box/WordBoxComp';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import WordBoxTryTimes from '@cms/comps/game/common/WordTryTime';
import { StringUtils } from '@utils/StringUtils';

const WordBoxItem = (props: {
  item: GlossaryGameContent;
  setting: WordBoxSettingProps;

  onCorrectPart?: (numberTry: number, answer: any) => void; // correct answer but not complete
  onCorrect: (numberTry: number) => void;

  onIncorrectPart?: (numberTry: number, answer: any) => void; // incorrect answer but have change
  onIncorrect: (numberTry: number) => void;
}) => {
  const [answers, setAnswers] = useState<string[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
  const [incorrectAnswers, setIncorrectAnswers] = useState<string[]>([]);
  const [numberTry, setNumberTry] = useState(0);

  useEffect(() => {
    setAnswers([]);
    setCorrectAnswers(props.item.word.data.toLowerCase().trim().split(''));
    setIncorrectAnswers([]);
    setNumberTry(0);
  }, [JSON.stringify(props.item)]);

  useEffect(() => {
    if (answers.length > 0) {
      let trimmingAnswer = correctAnswers.join('').toLowerCase();

      trimmingAnswer = StringUtils.replaceAll(trimmingAnswer, ' ', '');
      trimmingAnswer = StringUtils.replaceAll(trimmingAnswer, '_', '');

      const crAnswer = trimmingAnswer.split('').filter((item, pos) => {
        return trimmingAnswer.indexOf(item) === pos;
      });

      const isCorrect =
        answers.sort().join('').toLowerCase() ===
        crAnswer.sort().join('').toLowerCase();

      if (isCorrect) {
        props.onCorrect(numberTry);
      } else if (props.onCorrectPart) {
        props.onCorrectPart(numberTry, answers[answers.length - 1]);
      }
    }
  }, [answers]);

  useEffect(() => {
    if (incorrectAnswers.length > 0) {
      if (numberTry >= props.setting.tryTimes) {
        props.onIncorrect(numberTry);
      } else if (props.onIncorrectPart) {
        props.onIncorrectPart(
          numberTry,
          incorrectAnswers[incorrectAnswers.length - 1]
        );
      }
    }
  }, [numberTry, incorrectAnswers]);

  const handleOnClick = (char: string) => {
    if (correctAnswers.includes(char)) {
      setAnswers((prevState) => [...prevState, char]);
    } else {
      setIncorrectAnswers((prevState) => [...prevState, char]);
      setNumberTry((prevState) => prevState + 1);
    }
  };

  return (
    <WordBoxItemStyle className={'word-box-item'}>
      <div className={'word-chart-container'}>
        <WordChartContainer
          correctCharacter={answers}
          disableCharacter={incorrectAnswers}
          onClick={handleOnClick}
        />
      </div>

      <div className={'word-chard-direction-container'}>
        <div className={'word-box-content-display'}>
          <ContentViewer contentData={props.item.description} />
        </div>
      </div>

      <div className={'word-box-answer-container'}>
        <WordBoxAnswer answers={answers} correctAnswer={correctAnswers} />

        <WordBoxTryTimes
          type={'incorrect'}
          current={numberTry}
          total={props.setting.tryTimes}
        />
      </div>
    </WordBoxItemStyle>
  );
};

const WordChartContainerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  max-width: 30em; // n =  alphabet / 2, max-with = 2n * .125 * n
  margin: 0 auto;

  .word-chart-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    border: 1px solid #ccc;
    margin: 0.125em;
    font-weight: bold;

    cursor: pointer;

    &.incorrect-answer {
      background: ${(props) => props.theme.component.incorrect};
      color: ${(props) => props.theme.color.white};
    }

    &.correct-answer {
      background: ${(props) => props.theme.component.correct};
      color: ${(props) => props.theme.color.white};
    }
  }
`;

const WordChartContainer = (props: {
  disableCharacter: string[];
  correctCharacter: string[];
  onClick: (char: string) => void;
}) => {
  const alphabet = 'abcdefghijklmnopqrstuvxyz';

  return (
    <WordChartContainerStyle className={'word-chart-items'}>
      {alphabet.split('').map((char, index) => {
        if (props.disableCharacter.includes(char)) {
          return (
            <span
              key={'word-chart-item-' + index}
              className={'word-chart-item incorrect-answer'}
            >
              {char}
            </span>
          );
        } else if (props.correctCharacter.includes(char)) {
          return (
            <span
              key={'word-chart-item-' + index}
              className={'word-chart-item correct-answer'}
            >
              {char}
            </span>
          );
        } else {
          return (
            <span
              key={'word-chart-item-' + index}
              className={'word-chart-item'}
              onClick={() => props.onClick(char)}
            >
              {char}
            </span>
          );
        }
      })}
    </WordChartContainerStyle>
  );
};

const WordBoxAnswerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .word-box-answer-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    font-weight: bold;
    border: 1px solid #ccc;
    margin: 0.125em;
  }
`;

const WordBoxAnswer = (props: {
  answers: string[];
  correctAnswer: string[];
}) => {
  return (
    <WordBoxAnswerStyle className={'word-box-answer'}>
      {props.correctAnswer.map((item, index) => {
        if (item === '_' || item === ' ') {
          return (
            <span
              key={'empty-word' + index}
              className={'word-box-answer-item empty-word'}
            />
          );
        } else {
          if (props.answers.includes(item)) {
            return (
              <span
                key={'word-item-answered-' + index}
                className={'word-box-answer-item'}
              >
                {item}
              </span>
            );
          } else {
            return (
              <span
                key={'word-item-' + index}
                className={'word-box-answer-item'}
              />
            );
          }
        }
      })}
    </WordBoxAnswerStyle>
  );
};

export default WordBoxItem;

const WordBoxItemStyle = styled.div`
  display: flex;
  flex-direction: column;

  .word-chard-direction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .try-times-container {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
  }
`;
