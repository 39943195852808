import React from 'react';

import { Col, Row } from 'antd';
import InComingEvent from '../components/IncomingEvents';
import CourseRegistration from '@modules/course/container/CourseRegistration';
import { IncompleteAssignmentTable } from '@components/common/assignment/teacher/IncompleteAssignmentTable';
import { Default_Gutter } from '@components/grid';

const MyTeacherDashboardPage = () => {
  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <IncompleteAssignmentTable />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <CourseRegistration />
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
          <InComingEvent />
        </Col>
      </Row>
    </>
  );
};

export default MyTeacherDashboardPage;
