import { FullscreenButton } from '@cms/lesson-template/components/buttons/compose/FullScreenButton';
import { PrintLessonButton } from '@cms/lesson-template/components/buttons/compose/PrintLessonButton';
import React from 'react';
import { ExerciseToolbarStyle } from './style';
import { ReviewExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ReviewExerciseButton';
import { ExitExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ExitExerciseButton';
import { H3 } from '@components/typography';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';

export const ReviewExerciseToolbar = () => {
  const { lesson } = useViewLessonContext();

  return (
    <ExerciseToolbarStyle className={'lesson-toolbar review-exercise-toolbar'}>
      <div className={'header-group'}>
        <ExitExerciseButton />

        <H3>{lesson.name}</H3>
      </div>

      <div className={'left-group'}></div>

      <div className={'right-group'}>
        <FullscreenButton />
        <PrintLessonButton />
        <ReviewExerciseButton />
      </div>
    </ExerciseToolbarStyle>
  );
};
