import { ExerciseReportRes } from '@services/reports/LessonReport';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import type { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import {
  NeedGradingIco,
  PercentScoreIcon,
} from '@cms/icons/ComponentScoreIcon';
import { H3 } from '@components/typography';
import Table from '@components/table/Table';
import { StudentInfoSimple } from '@components/info/StudentInfo';
import Button from '@components/button';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { CompMode } from '@cms/ComponentInteface';
import ButtonGroup from '@components/button/ButtonGroup';
import { StudentAnswerResponse } from '@cms/response/StudentAnswerResponse';
import styled from 'styled-components';
import {
  ExerciseResponseStatusEnum,
  ExerciseStatus,
} from '@modules/assignments/service/exercise_model';

enum ViewAssignmentReportAction {
  default = '',
  view_exercise = 'view-exercise',
}

const AssignmentScoreReport = (props: { reports: ExerciseReportRes[] }) => {
  const { t } = useTranslation();

  const [action, setAction] = useState({
    action: ViewAssignmentReportAction.default,
    params: -1,
  });

  const [sourceData, setSourceData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableSize, setTableSize] = useState({ width: 600, height: 100 });

  useEffect(() => {
    const columnData = getColumnDisplay();
    setColumns(columnData.columns);
    setTableSize({ width: columnData.maxLength, height: columnData.maxHeight });

    const sourceData = getDataSource();
    setSourceData(sourceData);
  }, []);

  const getColumnDisplay = () => {
    let maxLength = 0;
    const maxHeight = 50 * (props.reports.length + 1);

    const columns: ColumnsType<any> = [];
    columns.push({
      title: t('report.student'),
      width: 235,
      dataIndex: 'student',
      key: 'student',
      fixed: 'left',
    });
    maxLength += 235;

    columns.push({
      title: t('report.score'),
      width: 100,
      dataIndex: 'score',
      key: 'score',
      fixed: 'left',
      align: 'center',
    });

    maxLength += 100;

    props.reports[0].responses.forEach((item, index) => {
      columns.push({
        title: 'Q' + (index + 1),
        width: 70,
        dataIndex: 'q' + index,
        key: 'q' + index,
        align: 'center',
      });

      maxLength += 50;
    });

    return {
      columns,
      maxLength,
      maxHeight,
    };
  };

  const viewExerciseResult = (exerciseId: number) => {
    setAction({
      action: ViewAssignmentReportAction.view_exercise,
      params: exerciseId,
    });
  };

  const onCancel = () => {
    setAction({
      action: ViewAssignmentReportAction.default,
      params: -1,
    });
  };

  const getDataSource = () => {
    const data: any[] = [];

    props.reports.forEach((exercise) => {
      const exerciseData: Record<string, any> = {
        student: <StudentInfoSimple item={exercise.exercise.student!} />,
        score: (
          <>
            {exercise.exercise.status === ExerciseStatus.NEED_GRADING && (
              <Button
                type={'text'}
                onClick={() => viewExerciseResult(exercise.exercise.exerciseId)}
              >
                <NeedGradingIco maxScore={-1} />
              </Button>
            )}

            {exercise.exercise.status !== ExerciseStatus.NEED_GRADING && (
              <>
                {exercise.exercise.score != null &&
                exercise.exercise.maxScore != null ? (
                  <PercentScoreIcon
                    score={exercise.exercise.score!}
                    maxScore={exercise.exercise.maxScore!}
                    onClick={() =>
                      viewExerciseResult(exercise.exercise.exerciseId)
                    }
                  />
                ) : (
                  <Button
                    type={'text'}
                    onClick={() =>
                      viewExerciseResult(exercise.exercise.exerciseId)
                    }
                  >
                    <>N/A</>
                  </Button>
                )}
              </>
            )}
          </>
        ),
      };
      exercise.responses.forEach((res, index) => {
        exerciseData['q' + index] = <StudentAnswerResponse item={res} />;
      });

      data.push(exerciseData);
    });

    return data;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Assignment Score Report
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Exercise Score Report</h3>}
          content={
            <>
              <p>Report cho biết kết quả làm bài của học sinh.</p>
            </>
          }
        />
      </H3>

      <Table
        columns={columns}
        dataSource={sourceData}
        scroll={{ x: tableSize.width }}
        pagination={false}
      />

      {action.action === ViewAssignmentReportAction.view_exercise && (
        <CustomModal
          header={t('exercise.actions.review')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ViewExerciseStyle className={'view-exercise-result'}>
              <ViewExercisePage
                type={CompMode.REVIEW_EXERCISE}
                exerciseId={action.params}
                productTocId={-1}
                productId={-1}
              />

              <div className={'submit-container'}>
                <ButtonGroup type={'center'}>
                  <Button
                    size={'large'}
                    shape={'round'}
                    type={'primary'}
                    danger
                    onClick={onCancel}
                  >
                    {t('button.close')}
                  </Button>
                </ButtonGroup>
              </div>
            </ViewExerciseStyle>
          }
          onCloseFunc={onCancel}
        />
      )}
    </LessonReportChartStyle>
  );
};

export default AssignmentScoreReport;

export const showExerciseStatus = (status: ExerciseResponseStatusEnum) => {
  switch (status) {
    case ExerciseResponseStatusEnum.NOT_START:
      return 'NOT_START';
    case ExerciseResponseStatusEnum.IN_PROGRESS:
      return 'IN_PROGRESS';
    case ExerciseResponseStatusEnum.SUBMITTED:
      return 'SUBMITTED';
    case ExerciseResponseStatusEnum.COMPLETED:
      return 'COMPLETED';
    case ExerciseResponseStatusEnum.NEED_GRADING:
      return 'NEED_GRADING';
    default:
      return '';
  }
};

const ViewExerciseStyle = styled.div`
  .exercise-template-container {
    border: 1px solid #000;
  }
`;
