import styled from 'styled-components';
import React from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import { RoleDisplay } from '@components/role/RoleDisplay';

const FeatureGroupStyle = styled.div`
  color: #141414;
  font-size: 15px;
  line-height: 1.8;
  display: flex;
  flex-direction: column;

  .feature-header {
    background: #ccc;
    color: #000;
    margin-bottom: 0;
    padding: 0.25em 1em;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .role-group {
      .role-display {
        font-size: 13px;
        padding: 0.25em 1em;
        margin-left: 0.5em;
        border-radius: 1em;
        background: ${(props) => props.theme.app.primary};
        color: #fff;
      }
    }
  }

  .feature-content {
    padding: 0.5em 1em;
    border: 1px solid #ccc;

    dd {
      margin-bottom: 0.5em;
      margin-left: 1em;

      dt {
        margin-bottom: 0.25em;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &.completed {
    color: ${(props) => props.theme.color.grey};
    filter: grayscale(0.5);
  }

  .incompleted {
    color: ${(props) => props.theme.warning.error};
    font-weight: bold;
  }
`;

export const FeatureGroup = (props: {
  header: string;
  roles?: RoleEnum[];
  children: any;
  completed?: boolean;
}) => {
  return (
    <FeatureGroupStyle
      className={`feature-group ${props.completed ? 'completed' : 'not-start'}`}
    >
      <h4 className={'feature-header'}>
        {props.header}

        {props.roles && props.roles.length > 0 && (
          <div className={'role-group'}>
            {props.roles.map((rl) => {
              return <RoleDisplay role={rl} key={rl} />;
            })}
          </div>
        )}
      </h4>
      <div className={'feature-content'}>{props.children}</div>
    </FeatureGroupStyle>
  );
};
