import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';
import styled from 'styled-components';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import { FibWordCompContextProvider } from './FibWordCompContext';
import FibWordGroupItems from '@cms/comps/interact/inline/FibWordGroupItems';
import { FibWordEditableProvider } from '@cms/comps/interact/inline/FibWordEditableContext';

export interface FibWordAnsProps extends CompAnswerProps {
  answer: string[];
}

export interface FibWordSettingProps extends CompInteractSettingProps {
  maxLength: number;
  autoCapital: boolean;
}

export interface FibWordOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface FibWordProps extends CompProps {
  type: CompTypeEnum.FIB_WORD;
  configuration: {
    targetItems: FibWordOptionProps[];
  };
  setting: FibWordSettingProps;
}

export const FibWordComp = (props: {
  disabled: boolean;
  item: FibWordProps;
  answer: FibWordAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  return (
    <FibWordCompContextProvider
      disabled={props.disabled}
      item={props.item}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <FibWordEditableProvider>
        <FibWordStyle
          className={`comp comp-fib-word ${props.item.setting?.className}`}
        >
          <FibWordGroupItems />

          {props.feedback && (
            <ComponentGrading
              className={ComponentGradingClassName.absolute_position}
              feedback={props.feedback}
            />
          )}
        </FibWordStyle>
      </FibWordEditableProvider>
    </FibWordCompContextProvider>
  );
};

const FibWordStyle = styled.div`
  position: relative;

  .comps-fib-word-content .inline-component {
    display: inline-flex;
  }

  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }
`;
