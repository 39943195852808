import OptionEditable from '@cms/editable/OptionEditable';
import { CompGroupEnum } from '@cms/utils/CompProps';
import { MatchingDragOption } from '@cms/comps/interact/matching/MactchingOption';
import { ComponentColorEnum } from '@cms/script/ComponentUtils';
import React from 'react';
import {
  generatorMatchingAns,
  matchingColors,
} from '@cms/comps/interact/matching/MatchingComp';
import { useMatchingCompContext } from '@cms/comps/interact/matching/MatchingCompContext';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import DragNDropContext from '@components/react-dnd-bt/DragNDropContext';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { HolderOutlined } from '@ant-design/icons';

const MatchingSourceItems = () => {
  const {
    modifiedTime,
    originalData,
    disableSource,
    sourceItems,

    updateOptionData,
    updateGroupOptions,
    updateOptionType,
  } = useMatchingCompContext();

  return (
    <DropItem
      acceptType={'matching-drag-item'}
      droppableId={''}
      className={'matching-panel source-items'}
      disabledIds={disableSource}
    >
      {sourceItems &&
        sourceItems.map((source, index) => {
          return (
            <DragItem
              key={JSON.stringify(source) + modifiedTime}
              className={'matching-option'}
              acceptType={'matching-drag-item'}
              draggableId={source.label}
              index={index}
              hidePlaceholder={false}
            >
              <ComposeModeContent>
                <HolderOutlined />
              </ComposeModeContent>

              <OptionEditable
                type={source.content.type}
                disabled={{
                  addOption: false,
                  removeOption: sourceItems.length === 1,
                }}
                onChangeType={(newType) =>
                  updateOptionType(newType, index, source, CompGroupEnum.source)
                }
                onOperation={(operation) =>
                  updateGroupOptions(
                    operation,
                    index,
                    source,
                    CompGroupEnum.source
                  )
                }
              >
                <MatchingDragOption
                  compId={originalData.id}
                  target={source}
                  type={CompGroupEnum.source}
                  color={matchingColors[index % matchingColors.length]}
                  handleOnContentChange={(data) =>
                    updateOptionData(data, index, source, CompGroupEnum.source)
                  }
                />
              </OptionEditable>
            </DragItem>
          );
        })}
    </DropItem>
  );
};

const MatchingTargetItems = () => {
  const {
    originalData,
    modifiedTime,
    targetItems,

    updateOptionData,
    updateGroupOptions,
    updateOptionType,
    onDropData,
  } = useMatchingCompContext();

  const handleOnDrop = (drag: string, drop: string) => {
    const ansPart = generatorMatchingAns(drag, drop);
    onDropData(ansPart);
  };

  return (
    <div className={'matching-panel target-items'}>
      {targetItems.map((target, index) => {
        return (
          <DropItem
            key={JSON.stringify(target) + modifiedTime}
            acceptType={'matching-drag-item'}
            droppableId={target.label}
            className={'matching-option drop-item'}
            handleOnDrop={(draggableId) =>
              handleOnDrop(draggableId, target.label)
            }
          >
            <OptionEditable
              type={target.content.type}
              disabled={{
                addOption: false,
                removeOption: targetItems.length === 1,
              }}
              onChangeType={(newType) =>
                updateOptionType(newType, index, target, CompGroupEnum.target)
              }
              onOperation={(operation) =>
                updateGroupOptions(
                  operation,
                  index,
                  target,
                  CompGroupEnum.target
                )
              }
            >
              <MatchingDragOption
                compId={originalData.id}
                target={target}
                type={CompGroupEnum.target}
                color={ComponentColorEnum.grey}
                handleOnContentChange={(data) =>
                  updateOptionData(data, index, target, CompGroupEnum.target)
                }
              />
            </OptionEditable>
          </DropItem>
        );
      })}
    </div>
  );
};

const MatchingDragNDrops = () => {
  const { modifiedTime, originalData, disableTarget, updateComponent } =
    useMatchingCompContext();

  return (
    <>
      <DragNDropContext
        id={originalData.id}
        disableDropIds={disableTarget}
        staticDrag={true}
      >
        <MatchingSourceItems />
        <MatchingTargetItems />
      </DragNDropContext>

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </>
  );
};

export default MatchingDragNDrops;
