import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import NavLink from '@components/button/NavLink';
import React from 'react';
import Button from '@components/button';
import { SelfCheckExerciseMode } from '@cms/lesson-template/components/mode/ExerciseMode';
import { useProUser } from '@components/button/RequireProUserButton';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import styled from 'styled-components';

export const ComposeResourceButton = (props: { resourceId: number }) => {
  const { lessonId, isEditableMode } = useViewLessonContext();
  const proUser = useProUser();

  return (
    <SelfCheckExerciseMode>
      {isEditableMode && (
        <Authorization
          type={'ifAnyGranted'}
          roles={[RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]}
        >
          <ComposeResourceWrapStyle className={'compose-button'}>
            <div className={'compose-resource-background'} />

            <div className={'compose-resource-button'}>
              <NavLink
                href={SiteMap.content.lesson.compose_resource_gen(
                  lessonId,
                  proUser,
                  props.resourceId,
                  -1,
                  -1
                )}
              >
                <Button
                  type={'primary'}
                  shape={'circle'}
                  size={'small'}
                  className={'compose-resource-btn'}
                  icon={IconUtils.actions.edit}
                />
              </NavLink>
            </div>
          </ComposeResourceWrapStyle>
        </Authorization>
      )}
    </SelfCheckExerciseMode>
  );
};

const ComposeResourceWrapStyle = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  bottom: -5px;
  left: -15px;
  background: rgba(0, 0, 0, 0.01);
  border-radius: 10px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  .compose-resource-button {
    position: absolute;
    right: 2px;
    bottom: 2px;
    z-index: 2;
  }
`;
