import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { InputGroup } from '@components/input/InputGroup';
import { Select } from 'antd';
import { FractionSettingProps } from '@cms/comps/math/frac/FractionComp';
import Input from '@components/input/input';
import { FractionTypeEnum } from '@cms/comps/math/frac/FractionInlineComp';
import { useTranslation } from 'react-i18next';

export const FractionSetting = (props: {
  setting: FractionSettingProps;
  onSettingChange: (newSetting: FractionSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.number.number_type')}>
          <Select
            size={'large'}
            placeholder={t('component.number.number_type')}
            onChange={(value) => formik.setFieldValue('type', value)}
            value={formik.values.type}
          >
            <Select.Option
              key={FractionTypeEnum.fraction}
              value={FractionTypeEnum.fraction}
            >
              {t('component.number.fraction')}
            </Select.Option>

            <Select.Option
              key={FractionTypeEnum.mixed_number}
              value={FractionTypeEnum.mixed_number}
            >
              {t('component.number.mixed')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Input
          name="digit"
          type="text"
          label={t('component.number.digit')}
          placeholder={t('component.number.digit')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.digit}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
