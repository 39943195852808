import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useRouter } from '@hooks/useRouter';
import { PageHeader } from '@app/header/PageHeader';
import SurveyReportPage from '@modules/public/pages/SurveyReportPage';

const ViewSurveyReport = () => {
  const { t } = useTranslation();

  const router = useRouter();

  const { surveyId } = router.query as unknown as {
    surveyId: number;
  };

  return (
    <ManagementLayout className={'view-survey-report-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PAGES}
        subMenu={NavigationMenuEnum.PAGES_STATIC_PAGE}
        title={t('menu.school_content')}
        description={t('menu.school_content')}
      />

      <SurveyReportPage surveyId={surveyId} />
    </ManagementLayout>
  );
};

export default ViewSurveyReport;
