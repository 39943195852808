import DnDInlineDropItems from '@cms/comps/interact/drag-n-drop/inline/items/DnDInlineDropItems';
import React from 'react';
import DnDInlineDragItems from './items/DnDInlineDragItems';
import DragNDropContext, {
  DragNDropAction,
  DragNDropActionType,
} from '@components/react-dnd-bt/DragNDropContext';
import { useDragNDropInlineCompContext } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';
import { generatorDragNDropAns } from '@cms/comps/interact/drag-n-drop/DragNDropComp';

const DragNDropInlineDragEvent = () => {
  const { originalData, onDropData, onMoveData, onRemoveData } =
    useDragNDropInlineCompContext();

  const handleOnChange = (action: DragNDropAction) => {
    if (
      action.action === DragNDropActionType.add &&
      action.params != null &&
      action.params.new != null
    ) {
      const answer = generatorDragNDropAns(
        action.params.new.droppableId,
        action.params.new.draggableId
      );

      onDropData(answer);
    } else if (
      action.action === DragNDropActionType.move &&
      action.params != null &&
      action.params.old != null &&
      action.params.new != null
    ) {
      const oldAnswer = generatorDragNDropAns(
        action.params.old.droppableId,
        action.params.old.draggableId
      );

      const newAnswer = generatorDragNDropAns(
        action.params.new.droppableId,
        action.params.new.draggableId
      );

      onMoveData(oldAnswer, newAnswer);
    } else if (
      action.action === DragNDropActionType.remove &&
      action.params != null &&
      action.params.old != null
    ) {
      const oldAnswer = generatorDragNDropAns(
        action.params.old.droppableId,
        action.params.old.draggableId
      );

      onRemoveData(oldAnswer);
    }
  };

  return (
    <DragNDropContext id={originalData.id} onChange={handleOnChange}>
      <div className={'dnd-workspace drag-event'}>
        <DnDInlineDragItems />
        <DnDInlineDropItems />
      </div>
    </DragNDropContext>
  );
};

export default DragNDropInlineDragEvent;
