import React, { useCallback } from 'react';
import { WordPuzzleCompProps } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import WordPuzzleDescription from '@cms/comps/game/word-puzzle/WordPuzzleDescription';
import WordPuzzleWordBanks from '@cms/comps/game/word-puzzle/WordPuzzleWordBanks';
import styled from 'styled-components';
import { GridWordProps } from '@cms/comps/game/word-search/WordSearchUtils';

import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { updateComponent } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { useWordPuzzleContext } from '@cms/comps/game/word-puzzle/WordPuzzleCompProvider';

export const WordPuzzleItems = (props: { item: WordPuzzleCompProps }) => {
  const dispatch = useDispatch();

  const { selectedWord } = useWordPuzzleContext();

  const placeWordAtIndex = useCallback(
    (box: GridWordProps) => {
      if (selectedWord.length > 0) {
        const targetIndex = props.item.configuration.sourceItems.findIndex(
          (word) => {
            return selectedWord.includes(word.id);
          }
        );

        if (targetIndex > -1) {
          const newTarget = {
            ...props.item.configuration.sourceItems[targetIndex],
            offset: { row: box.row, column: box.column },
          };
          updateComponentData('sourceItems', newTarget);
        } else {
          const targetIndex = props.item.configuration.targetItems!.findIndex(
            (word) => {
              return selectedWord.includes(word.id);
            }
          );

          if (targetIndex > -1) {
            const newTarget = {
              ...props.item.configuration.targetItems![targetIndex],
              offset: { row: box.row, column: box.column },
            };

            updateComponentData('targetItems', newTarget);
          }
        }
      }
    },
    [selectedWord]
  );

  const updateComponentData = (
    type: 'sourceItems' | 'targetItems',
    newData: GlossaryGameContent
  ) => {
    if (type === 'sourceItems') {
      const newSourceItems = props.item.configuration.sourceItems.map(
        (data) => {
          if (data.id === newData.id) {
            return newData;
          } else {
            return data;
          }
        }
      );

      const newCompData = {
        ...props.item,
        configuration: {
          ...props.item.configuration,
          defaultWord: newData.id,
          sourceItems: newSourceItems,
        },
      };

      dispatch(updateComponent(newCompData));
    } else {
      const newTargetItems = props.item.configuration.targetItems!.map(
        (data) => {
          if (data.id === newData.id) {
            return newData;
          } else {
            return data;
          }
        }
      );

      const newCompData = {
        ...props.item,
        configuration: {
          ...props.item.configuration,
          defaultWord: newData.id,
          targetItems: newTargetItems,
        },
      };

      dispatch(updateComponent(newCompData));
    }
  };

  return (
    <WordPuzzleItemsStyle className={'word-puzzle-item'}>
      <div className={'word-puzzle-bank-container'}>
        <WordPuzzleWordBanks
          setting={props.item.setting}
          placeWordAtIndex={placeWordAtIndex}
        />
      </div>

      <div className={'word-puzzle-separator'} />

      <div className={'word-puzzle-description-container'}>
        <WordPuzzleDescription
          across={props.item.configuration.sourceItems}
          down={props.item.configuration.targetItems!}
        />
      </div>
    </WordPuzzleItemsStyle>
  );
};

const WordPuzzleItemsStyle = styled.div`
  display: flex;

  .word-puzzle-separator {
    width: 2em;
  }

  .word-puzzle-description-container {
    flex-grow: 1;
  }
`;
