import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import React, { useEffect, useState } from 'react';
import { AttendanceSession } from '@classroom/components/AttendanceSession';
import { H3 } from '@components/typography';
import { useRouter } from '@hooks/useRouter';
import useConfirmModal from '@components/modal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import PublicSessionInfo from '@components/public/session/PublicSessionInfo';
import {
  ClassRoomAction,
  ClassRoomInteractAction,
} from '@classroom/context/actions';
import { ClassRoomDirection } from '@classroom/components/ClassRoomHelp';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { SessionAPI } from '@services/private/SessionAPI';
import { SiteMap } from '@router/SiteMap';
import styled from 'styled-components';
import Button from '@components/button';
import { ClassRoomAssignForm } from '@classroom/components/ClassRoomAssignForm';
import { SessionTaskRes } from '@services/model/session';

export const ClassRoomInteractHandle = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [attendanceSession] =
    SessionAPI.endpoints.attendanceSession.useMutation({});

  const { session, dispatchAction } = useClassRoomContext();
  const { action, onActionChange, onAllowPresent } =
    useClassRoomInteractContext();

  useEffect(() => {
    if (action.action === ClassRoomInteractAction.JOIN) {
      joinSession();
    } else if (action.action === ClassRoomInteractAction.QUIT) {
      quitSession();
    } else if (action.action === ClassRoomInteractAction.ALLOW_PRESENT) {
      onAllowPresent(true, action.params);
    } else if (action.action === ClassRoomInteractAction.STOP_PRESENT) {
      onAllowPresent(false, action.params);
    }
  }, [action, session.sessionId]);

  const onCancel = () => {
    onActionChange(ClassRoomInteractAction.DEFAULT, '');
  };

  const joinSession = () => {
    attendanceSession(session.sessionId)
      .unwrap()
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const quitSession = () => {
    confirm(
      'danger',
      t('header.confirm'),
      t('class_room.warning.exit_session'),
      t('class_room.actions.quit'),
      t('class_room.actions.continuous'),
      (r) => {
        if (r) {
          attendanceSession(session.sessionId)
            .unwrap()
            .then(() => {
              router.push(SiteMap.private.my_calendar);
            })
            .catch((err) => {
              console.log(err);
              router.push(SiteMap.private.my_calendar);
            });
        }
      }
    );
  };

  const onAssignTaskSuccess = (task: SessionTaskRes) => {
    dispatchAction(ClassRoomAction.ASSIGN_EXERCISE, task.sessionTaskId);
    onCancel();
  };

  return (
    <>
      {action.action === ClassRoomInteractAction.VIEW_PARTICIPANTS && (
        <CustomModal
          header={
            <H3 className={'mb-0'}>
              {session.name}{' '}
              <span className={'default-text'}>Participants</span>
            </H3>
          }
          className={CustomModalClassEnum.medium_modal}
          content={<AttendanceSession session={session} onClose={onCancel} />}
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ClassRoomInteractAction.SHOW_INFO && (
        <CustomModal
          header={
            <H3 className={'mb-0'}>
              {session.name} <span className={'default-text'}>Information</span>
            </H3>
          }
          className={CustomModalClassEnum.medium_modal}
          content={<PublicSessionInfo item={session} type={'view'} />}
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ClassRoomInteractAction.HELP && (
        <CustomModal
          header={'Class Room Direction'}
          className={CustomModalClassEnum.medium_modal}
          content={<ClassRoomDirection onClose={onCancel} />}
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ClassRoomInteractAction.ASSIGN && (
        <CustomModal
          header={'Assign'}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ClassRoomAssignForm
              session={session}
              sectionId={Number(action.params)}
              onSuccess={onAssignTaskSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}

      {/*<InteractStateDisplay />*/}
    </>
  );
};

export const InteractStateDisplay = () => {
  const [show, setShow] = useState(true);

  const { recording, presentingIds, raiseHandIds, allowInteract } =
    useClassRoomInteractContext();

  const { historyUuid, sectionId, exerciseId, sessionTaskId } =
    useClassRoomContext();

  return (
    <InteractStyle className={'interact-state'}>
      {show ? (
        <>
          <Button size={'small'} type={'text'} onClick={() => setShow(false)}>
            &times;
          </Button>
          <b>uuid: {historyUuid}</b>
          <br />
          <b>
            Data: {sectionId} | {exerciseId + ''} | {sessionTaskId + ''}
          </b>
          <br />
          recording: {recording + ''}
          <br />
          allowInteract: {allowInteract + ''},
          <br />
          raiseHands: {raiseHandIds.join(',')}
          <br />
          presentingIds: {presentingIds.join(',')}
        </>
      ) : (
        <Button size={'small'} type={'text'} onClick={() => setShow(true)}>
          ?
        </Button>
      )}
    </InteractStyle>
  );
};

const InteractStyle = styled.div`
  position: fixed;
  top: 5px;
  left: 5px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.85);

  .ant-btn {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, 0%);
  }
`;
