import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { CourseRes } from '@services/private/CourseAPI';
import { BasicMessageRes } from '@services/model/Response';
import {
  CategoryRes,
  GradeRes,
  SubjectRes,
} from '@modules/admin/service/model';

export interface PublishCourseInfoRes {
  course: CourseRes;
  subjects: SubjectRes[];
  categories: CategoryRes[];
  grades: GradeRes[];
}

export interface UpdateCourseInfoReq {
  courseId: number;
  subjectIds: number[];
  categoryIds: number[];
  gradeIds: number[];
}

export interface LessonPriceProps {
  lessonId: number;
  price: number;
}

export interface UpdateCoursePriceReq {
  courseId: number;
  price: number;
}

export interface CourseInfoRes {
  course: CourseRes;
  grades: GradeRes[];
  categories: CategoryRes[];
  subjects: SubjectRes[];
}

export const PublishCourseAPI = createApi({
  reducerPath: 'PublishCourseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getCourseInfo: builder.query<CourseInfoRes, unknown>({
      // => PublishCourseInfoRes
      query: (courseId) => ({
        url: `/publish/courses/${courseId}`,
      }),
    }),

    updateCourseInfo: builder.mutation<CourseInfoRes, UpdateCourseInfoReq>({
      query: (body: UpdateCourseInfoReq) => ({
        url: '/publish/courses',
        method: 'PUT',
        body,
      }),
    }),

    updateCoursePrices: builder.mutation<CourseInfoRes, UpdateCoursePriceReq>({
      query: (body: UpdateCoursePriceReq) => ({
        url: '/publish/courses/prices',
        method: 'PUT',
        body,
      }),
    }),

    getReviewCourseData: builder.query<CourseInfoRes, unknown>({
      // => PublishCourseRes
      query: (courseId) => ({
        url: `/publish/courses/${courseId}/review`,
      }),
    }),

    publishCourse: builder.mutation<BasicMessageRes, number>({
      query: (courseId) => ({
        url: `/publish/courses/${courseId}/publish`,
        method: 'POST',
      }),
    }),
  }),
});
