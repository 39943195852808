import { useTranslation } from 'react-i18next';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';
import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React, { useEffect, useState } from 'react';
import { H3 } from '@components/typography';
import { Alert, Progress } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import styled from 'styled-components';
import { QuestionStatusItem } from './QuestionStatusItem';
import {
  ExerciseRes,
  ExerciseResponseRes,
  ExerciseStatus,
} from '@modules/assignments/service/exercise_model';

export const ExerciseResponseStatus = () => {
  const { t } = useTranslation();
  const { exerciseId, viewStatus } = useExerciseServiceContext();
  const { dispatchExerciseAction } = useViewLessonContext();

  const [inProgress, setInProgress] = useState(false);
  const [data, setData] = useState<ExerciseRes | null>(null);
  const [answers, setAnswers] = useState<ExerciseResponseRes[]>([]);

  useEffect(() => {
    if (exerciseId != null && exerciseId > 0) {
      viewStatus((data) => {
        setData(data);
        const isProgress =
          data.status === ExerciseStatus.NOT_START ||
          data.status === ExerciseStatus.VIEWED ||
          data.status === ExerciseStatus.IN_PROGRESS;

        setAnswers(data.answers);
        setInProgress(isProgress);
      });
    }
  }, [exerciseId]);

  return (
    <LessonStatusReviewStyle className={'exercise-status'}>
      {data && data.lesson && (
        <div className={'lesson-status-header'}>
          <H3>{data.lesson.name}</H3>

          {inProgress && (
            <div className={'exercise-progress-status'}>
              {data.progress != null && data.progress <= 100 && (
                <Progress
                  percent={data.progress}
                  status="success"
                  format={() => ''}
                />
              )}

              {data.progress != null && data.progress < 100 && (
                <div style={{ margin: '1em auto' }}>
                  <Alert
                    message={t('exercise.warning.warning_forgot_answer')}
                    type="error"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className={'lesson-status-group'}>
        <div className={'lesson-status-column left-panel'}>
          {answers.map((ans, index) => {
            return index <= answers.length / 2 ? (
              <QuestionStatusItem
                key={ans.resourceId + '_' + index}
                item={ans}
              />
            ) : null;
          })}
        </div>

        <div className={'lesson-status-column right-panel'}>
          {answers.map((ans, index) => {
            return index > answers.length / 2 ? (
              <QuestionStatusItem
                key={ans.resourceId + '_' + index}
                item={ans}
              />
            ) : null;
          })}
        </div>
      </div>

      {data && (
        <div className={'lesson-status-actions'}>
          {inProgress ? (
            <ButtonGroup type={'right'}>
              {data.progress == null || data.progress < 100 ? (
                <>
                  <Button
                    className={'submit-assignment'}
                    danger={true}
                    type={'primary'}
                    shape={'round'}
                    onClick={() =>
                      dispatchExerciseAction(ExerciseActionEnum.submit, '')
                    }
                  >
                    {t('exercise.button.submit')}
                  </Button>

                  <Button
                    className={'review-assignment'}
                    type={'primary'}
                    shape={'round'}
                    onClick={() =>
                      dispatchExerciseAction(ExerciseActionEnum.default, null)
                    }
                  >
                    {t('button.close')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={'review-assignment'}
                    type={'default'}
                    shape={'round'}
                    onClick={() =>
                      dispatchExerciseAction(ExerciseActionEnum.default, null)
                    }
                  >
                    {t('button.close')}
                  </Button>

                  <Button
                    className={'submit-assignment'}
                    type={'primary'}
                    shape={'round'}
                    onClick={() =>
                      dispatchExerciseAction(ExerciseActionEnum.submit, '')
                    }
                  >
                    {t('exercise.button.submit')}
                  </Button>
                </>
              )}
            </ButtonGroup>
          ) : (
            <ButtonGroup type={'center'}>
              <Button
                type={'default'}
                shape={'round'}
                onClick={() =>
                  dispatchExerciseAction(ExerciseActionEnum.default, null)
                }
              >
                {t('button.close')}
              </Button>
            </ButtonGroup>
          )}
        </div>
      )}
    </LessonStatusReviewStyle>
  );
};

const LessonStatusReviewStyle = styled.div`
  max-width: 650px;
  margin: 0 auto;

  .lesson-status-header {
    margin-bottom: 2em;
  }

  .lesson-status-group {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    .lesson-status-column {
      width: calc(50% - 20px);

      .lesson-status-item {
        margin: 1em 0em;
      }
    }
  }

  .lesson-status-actions {
    margin-top: 2em;
  }
`;
