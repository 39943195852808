import React, { useState } from 'react';

import { LessonActionEnum } from '@modules/product/container/actions';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from 'antd/lib/button';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import LessonEditForm from '@modules/product/container/LessonEditForm';
import { IconUtils } from '@utils/IconUtils';
import { H1 } from '@components/typography';
import { LessonManagementTable } from '@components/common/lesson/LessonManagementTable';
import { useProUser } from '@components/button/RequireProUserButton';
import { CollectionCreateForm } from '@modules/product/container/CollectionEditForm';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '../services/lesson_model';

export default function ManageLessonPage(props: { types: LessonTypeEnum }) {
  const { t } = useTranslation();
  const router = useRouter();
  const proUser = useProUser();

  const [action, setAction] = useState({
    action: LessonActionEnum.DEFAULT,
    timestamp: DateAndTimeUtils.getCurrentTime(),
  });

  const handleAddLesson = () => {
    setAction({
      action: LessonActionEnum.ADD_LESSON,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCancel = () => {
    setAction({
      action: LessonActionEnum.DEFAULT,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCreateLessonSuccess = (isAdded: boolean, lesson: LessonRes) => {
    router.push(SiteMap.content.lesson.compose_gen(lesson.lessonId, proUser));
  };

  const handleOnSelect = (lessonExampleId: number) => {
    router.push(SiteMap.private.my_lesson.create_gen(lessonExampleId));
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('lesson.my_lesson')}
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={handleAddLesson}
          />
        </H1>
      </HeaderGroup>

      <LessonManagementTable types={[props.types]} />

      {action.action === LessonActionEnum.ADD_LESSON && (
        <>
          {proUser ? (
            <CustomModal
              header={t('lesson.actions.create')}
              className={CustomModalClassEnum.medium_modal}
              content={
                <LessonEditForm
                  type={props.types}
                  successHandle={handleOnCreateLessonSuccess}
                  onCloseFunc={handleOnCancel}
                />
              }
              onCloseFunc={handleOnCancel}
            />
          ) : (
            <CustomModal
              header={t('my_lesson.actions.create')}
              className={CustomModalClassEnum.medium_modal}
              content={
                <CollectionCreateForm
                  onSelect={handleOnSelect}
                  onCancel={handleOnCancel}
                />
              }
              onCloseFunc={handleOnCancel}
            />
          )}
        </>
      )}
    </>
  );
}
