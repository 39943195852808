import {
  JwtRes,
  UserAndPermissionRes,
} from '@modules/authorization/services/model';
import { login, updateUser } from '@app/redux/slices/profileSlice';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';
import { useDispatch } from 'react-redux';

export const useLoginSuccess = () => {
  const dispatch = useDispatch();

  const handleOnSuccess = (data: JwtRes) => {
    const payload = {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
    };

    dispatch(login(payload));
    dispatch(refreshExpiredData({ iat: data.iat, exp: data.exp }));
  };

  const onGetUserSuccess = (data: UserAndPermissionRes) => {
    dispatch(updateUser(data));
  };

  return {
    onLoginSuccess: handleOnSuccess,
    onGetUserSuccess: onGetUserSuccess,
  };
};
