import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { TeacherProfile } from '@modules/public/components/teachers/TeacherProfile';
import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';
import { Default_Gutter } from '@components/grid';
import { PageHeader } from '@app/header/PageHeader';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const Teachers = () => {
  const [viewTeachers, { data, isSuccess }] =
    PublicPageAPI.endpoints.viewTeachers.useLazyQuery();

  useEffect(() => {
    viewTeachers({});
  }, []);

  return (
    <PublicLayout className={'teachers-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_TEACHERS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'Teachers'}
        description={'Teachers'}
      />

      <PublicPageContainer>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          {data &&
            isSuccess &&
            data.content.map((tch) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={4}
                  key={tch.userProfileId}
                >
                  <TeacherProfile item={tch} />
                </Col>
              );
            })}
        </Row>
      </PublicPageContainer>
    </PublicLayout>
  );
};

export default Teachers;
