import { createApi } from '@reduxjs/toolkit/query/react';
import {
  PaginationAndFilterReq,
  PaginationReq,
  PaginationRes,
} from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  StudentInvitationRes,
  UserInfoRes,
  UserRes,
} from '@modules/users/services/model';

export interface CreateSimpleUserReq {
  username: string;
}

export interface CreateUserReq {
  clientId?: string;
  userId?: number;
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  avatar: string;
}

export interface DisabledUserReq {
  userId: number;
  disabled: boolean;
}

export interface FilterStudentReq {
  selectIds?: number[];
  keyword?: string | null;
  classId?: number | null;
}

export interface FilterUserReq extends PaginationReq {
  keyword?: string | null;
  schoolId?: number | null;
}

export const StudentAPI = createApi({
  reducerPath: 'StudentAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findExitUser: builder.query<UserInfoRes, string>({
      query: (params) => ({
        url: '/students/check',
        method: 'GET',
        params: { email: params },
      }),
    }),

    findByEmailOrUserName: builder.query<
      PaginationRes<UserInfoRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: '/students/find-by-email',
        method: 'GET',
        params: params,
      }),
    }),

    filterStudent: builder.query<UserRes[], number>({
      query: (classId) => ({
        url: `/students/filter/${classId}`,
        method: 'GET',
      }),
    }),

    findByIds: builder.query<UserRes[], number[]>({
      query: (params) => ({
        url: `/students/find-by-ids`,
        method: 'GET',
        params: { ids: params },
      }),
    }),

    getStudents: builder.query<PaginationRes<UserRes>, FilterUserReq>({
      query: (params: PaginationReq) => ({
        url: '/students',
        method: 'GET',
        params,
      }),
    }),

    getMyStudentInvitation: builder.query<
      PaginationRes<StudentInvitationRes>,
      PaginationReq
    >({
      query: (params: PaginationReq) => ({
        url: '/students/invitation',
        method: 'GET',
        params,
      }),
    }),

    filterStudents: builder.mutation<
      PaginationRes<UserRes>,
      PaginationAndFilterReq<FilterStudentReq>
    >({
      query: (body: PaginationAndFilterReq<FilterStudentReq>) => ({
        url: '/students/filter-by',
        method: 'POST',
        body: body,
      }),
    }),

    getStudentData: builder.query<UserRes, number>({
      query: (userId) => ({
        url: `/students/${userId}`,
        method: 'GET',
      }),
    }),

    createStudent: builder.mutation<UserInfoRes, CreateUserReq>({
      query: (body) => ({
        url: '/students',
        method: 'POST',
        body,
      }),
    }),

    updateStudent: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: `/students/${body.userId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteSubscribeStudent: builder.mutation<BasicMessageRes, number>({
      query: (studentId: number) => ({
        url: `/students/${studentId}`,
        method: 'DELETE',
      }),
    }),

    disabledStudent: builder.mutation<BasicMessageRes, DisabledUserReq>({
      query: () => ({
        url: '/students/disabled',
        method: 'DELETE',
      }),
    }),
  }),
});
