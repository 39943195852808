import { ExerciseReportRes } from '@services/reports/LessonReport';
import ColorUtils from '@utils/ColorUtils';
import BarGraphChart, {
  BarGraphReportProp,
} from '@modules/reports/charts/bargraph/BarGraphChart';
import { ReportUtils } from '@modules/reports/ReportUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import React from 'react';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { useTranslation } from 'react-i18next';
import { H3 } from '@components/typography';

const AssignmentScoreHistory = (props: { reports: ExerciseReportRes[] }) => {
  const { t } = useTranslation();

  const renderFunc = (
    data: ExerciseReportRes,
    index: number
  ): BarGraphReportProp => {
    const percent = ReportUtils.percent(
      data.exercise.score!,
      data.exercise.maxScore!
    );

    const recordData: BarGraphReportProp = {
      group: `${data.exercise.student?.displayName}`,
      score: percent,
      scoreColor: ColorUtils.getColorByScore(percent),
      data: data,
    };

    return recordData;
  };

  const labelFormat = (e: any) => {
    return e.id;
  };

  const customGetColor = (params: { id: string; data: BarGraphReportProp }) => {
    return params.data.scoreColor;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Assignment Score History
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Assignment Score History</h3>}
          content={
            <>
              <p>Report so sánh điểm của từng học sinh với nhau.</p>

              <p>
                Giáo viên dựa vào màu sắc để đánh giá trình độ chung của cả lớp.
              </p>
            </>
          }
        />
      </H3>

      <BarGraphChart
        keys={['score']}
        className={'lesson-standard'}
        content={props.reports}
        size={500}
        maxValue={100}
        render={renderFunc}
        labelFormat={labelFormat}
        xAxisTitle={t('report.students')}
        yAxisTitle={t('report.scores')}
        yAxisValues={[0, 25, 50, 75, 100]}
        getColor={customGetColor}
      />
    </LessonReportChartStyle>
  );
};

export default AssignmentScoreHistory;
