import React, { useEffect } from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { CurrencyAPI } from '@modules/payment/services/CurrencyAPI';
import { Default_Gutter } from '@components/grid';
import {
  CreateCurrencyReq,
  CurrencyRes,
} from '@modules/payment/services/model';

const initialValue: CreateCurrencyReq = {
  currencyId: -1,
  code: '',
  name: '',
};

export function CreateCurrencyForm(props: {
  currencyId: number | null;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess }] =
    CurrencyAPI.endpoints.findById.useLazyQuery();

  const [createCurrency, { isLoading: isSaveLoading }] =
    CurrencyAPI.endpoints.create.useMutation({});

  const [updateCurrency, { isLoading: isUpdateLoading }] =
    CurrencyAPI.endpoints.update.useMutation({});

  useEffect(() => {
    if (props.currencyId != null && props.currencyId > 0) {
      findById(props.currencyId);
    }
  }, [props.currencyId]);

  useEffect(() => {
    if (data && isSuccess) {
      setFormData(data);
    }
  }, [data]);

  const setFormData = (data: CurrencyRes) => {
    formik.setValues({
      currencyId: data.currencyId,
      code: data.code,
      name: data.name,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      code: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (data != null && data.currencyId > 0) {
        updateCurrency(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('currency.warning.update_success'),
              description: t('currency.warning.update_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('currency.warning.update_error'),
              description: t('currency.warning.update_error_message'),
              placement: 'bottomRight',
            });
          });
      } else {
        createCurrency(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('currency.warning.add_success'),
              description: t('currency.warning.add_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle();
          })

          .catch(() => {
            notification.error({
              message: t('currency.warning.add_error'),
              description: t('currency.warning.add_error_message'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            required={true}
            name="code"
            type="text"
            label={t('label.code')}
            placeholder={t('label.code')}
            onChange={formik.handleChange}
            value={formik.values.code}
          />

          <Input
            required={true}
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <div className="submit-container">
            <ButtonGroup>
              <Button
                type={'primary'}
                loading={isSaveLoading || isUpdateLoading}
                onClick={formik.submitForm}
              >
                {props.currencyId != null && props.currencyId > 0 ? (
                  <>{t('button.update')}</>
                ) : (
                  <>{t('button.create')}</>
                )}
              </Button>

              <Button type="default" onClick={props.onCloseFunc}>
                {t('button.cancel')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </FormikProvider>
  );
}
