import CreateAssignment from '@modules/assignments/container/assignment/CreateAssignment';
import React from 'react';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export const CreateAssignmentPage = ({
  lessonId,
  productTocId,
  productId,
}: {
  lessonId: number;
  productTocId: number;
  productId: number;
}) => {
  const router = useRouter();

  const goBack = () => {
    if (productTocId > 0 || productId > 0) {
      router.push(SiteMap.content.product.toc_gen(productId));
    } else if (lessonId > 0) {
      router.push(SiteMap.content.lesson.list);
    } else {
      router.push(SiteMap.assignments.list);
    }
  };

  return (
    <Row gutter={[Default_Gutter, Default_Gutter]}>
      <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
        <CreateAssignment
          lessonId={lessonId}
          productTocId={productTocId}
          productId={productId}
          onSuccess={goBack}
          onCancel={goBack}
        />
      </Col>
    </Row>
  );
};
