import React, { useEffect } from 'react';

import { Button, Col, notification, Row } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { StringUtils } from '@utils/StringUtils';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import { LanguageContentInput } from '@components/editor/LanguageContentInput';
import { Default_Gutter } from '@components/grid';
import {
  CreateLessonExampleReq,
  LessonExampleRes,
  LessonTypeEnum,
} from '@modules/admin/service/model';

export default function LessonExampleEditForm(props: {
  category?: LessonTypeEnum;
  lessonId?: number;
  lessonExampleId?: number;
  onSuccess: (isAdded: boolean, data: LessonExampleRes) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [viewLessonExample, { data, isSuccess, isFetching }] =
    LessonExampleAPI.endpoints.viewLessonExample.useLazyQuery({});

  const [createLessonExample, { isLoading: isCreateLoading }] =
    LessonExampleAPI.endpoints.createLessonExample.useMutation({});

  const [updateLessonExample, { isLoading: isUpdateLoading }] =
    LessonExampleAPI.endpoints.updateLessonExample.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      category: props.category ?? LessonTypeEnum.empty,
      code: '',
      name: '',
      description: '',
      displayOrder: 1,
    },

    validationSchema: yup.object().shape({
      category: yup.string().trim().required(t('validation.required.field')),
      code: yup.string().trim().required(t('validation.required.field')),
      name: yup.string().trim().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.lessonExampleId != null && props.lessonExampleId > 0) {
        const payload: CreateLessonExampleReq = {
          lessonExampleId: props.lessonExampleId,
          category: values.category,
          code: values.code,
          name: values.name,
          description: values.description,
          displayOrder: values.displayOrder,
        };

        handleOnUpdate(payload);
      } else {
        const payload: CreateLessonExampleReq = {
          lessonId: props.lessonId ? props.lessonId : -1,
          category: values.category,
          code: values.code,
          name: values.name,
          description: values.description,
          displayOrder: values.displayOrder,
        };
        handleOnCreate(payload);
      }
    },
  });

  useEffect(() => {
    if (props.lessonExampleId != null && props.lessonExampleId > 0) {
      viewLessonExample(props.lessonExampleId);
    }
  }, [props.lessonExampleId]);

  useEffect(() => {
    if (data && isSuccess) {
      restoreFormData(data);
    }
  }, [data]);

  const restoreFormData = (data: LessonExampleRes) => {
    formik.setValues({
      category: data.category,
      code: data.code,
      name: data.name,
      description: data.description,
      displayOrder: data.displayOrder,
    });
  };

  const handleOnUpdate = (payload: CreateLessonExampleReq) => {
    updateLessonExample(payload)
      .unwrap()
      .then((data) => {
        props.onSuccess(false, data);

        notification.success({
          message: t('page_site.warning.update_success'),
          description: t('page_site.warning.update_success_message'),
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        notification.error({
          message: t('lesson.warning.update_error'),
          description: t('lesson.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleOnCreate = (payload: CreateLessonExampleReq) => {
    createLessonExample(payload)
      .unwrap()
      .then((data) => {
        props.onSuccess(true, data);

        notification.success({
          message: t('page_site.warning.add_success'),
          description: t('page_site.warning.add_success_message'),
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        notification.error({
          message: t('page_site.warning.add_error'),
          description: t('page_site.warning.add_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleCodeChange = (value: string) => {
    const newPath = StringUtils.replaceAll(value.toLowerCase(), ' ', '-');
    formik.setFieldValue('code', StringUtils.replaceAll(newPath, '--', '-'));
  };

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            required={true}
            name="code"
            type="text"
            label={t('label.code')}
            placeholder={t('label.code')}
            onChange={(event) => handleCodeChange(event.target.value)}
            value={formik.values.code}
          />

          <Input
            required={true}
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <LanguageContentInput
            row={2}
            maxLength={512}
            initValue={data ? data.description : ''}
            onChange={(val) => formik.setFieldValue('description', val)}
          />

          <Input
            name="displayOrder"
            type="number"
            label={t('label.displayOrder')}
            placeholder={t('label.displayOrder')}
            onChange={formik.handleChange}
            min={1}
            value={formik.values.displayOrder}
          />

          <div className="submit-container">
            <ButtonGroup>
              {props.lessonExampleId != null && props.lessonExampleId > 0 ? (
                <Button
                  type={'primary'}
                  loading={isFetching || isUpdateLoading}
                  onClick={formik.submitForm}
                >
                  {t('button.update')}
                </Button>
              ) : (
                <Button
                  type={'primary'}
                  loading={isCreateLoading}
                  onClick={formik.submitForm}
                >
                  {t('button.create')}
                </Button>
              )}

              <Button type="default" onClick={props.onClose}>
                {t('button.cancel')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </FormikProvider>
  );
}
