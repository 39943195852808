import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { CompInteractSettingProps } from '@cms/ComponentInteface';
import Input from '@components/input/input';
import { useTranslation } from 'react-i18next';

export const FibParagraphSetting = (props: {
  setting: CompInteractSettingProps;
  onSettingChange: (newSetting: CompInteractSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <Input
        label={t('component.label.class_name')}
        placeholder={t('component.label.class_name')}
        name="className"
        value={formik.values.className ?? ''}
        onChange={formik.handleChange}
      />
    </SettingStyle>
  );
};
