import { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { xAxisSetting, yAxisSetting } from '../RecordChartSetting';

const defaultIndexGroup = 'group';

export interface BarGraphReportProp extends Record<string, any> {
  group: string;
}

export interface BarGraphProps<T> {
  className: string;
  size: number;
  maxValue: number;
  content: T[];
  keys: string[];
  render: (data: T, index: number) => BarGraphReportProp;
  labelFormat: (data: T) => string;
  xAxisTitle: string;
  yAxisTitle: string;
  yAxisValues?: number[];
  getColor: (params: any) => string;
}

const BarGraphChart = (props: BarGraphProps<any>) => {
  const [data] = useState<BarGraphReportProp[]>(() => {
    return props.content.map((it: any, index: number) => {
      return props.render(it, index);
    });
  });

  const [tickValues] = useState<number[]>(() => {
    if (props.yAxisValues) {
      return props.yAxisValues;
    } else {
      const arr: number[] = [];
      for (let i = 0; i < props.keys.length; i++) {
        arr.push(i * 100);
      }
      return arr;
    }
  });

  return (
    <div
      className={props.className}
      style={{ width: '100%', height: props.size }}
    >
      <ResponsiveBar
        data={data}
        keys={props.keys}
        indexBy={defaultIndexGroup}
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        colors={props.getColor}
        borderColor={{
          from: 'color',
          // modifiers: [['darker', 1.6]],
        }}
        maxValue={props.maxValue}
        axisTop={null}
        axisRight={null}
        axisBottom={xAxisSetting(props.xAxisTitle)}
        axisLeft={yAxisSetting(props.yAxisTitle, tickValues)}
        // gridYValues={tickValues}
        labelSkipWidth={12}
        labelSkipHeight={4}
        labelTextColor={{
          from: 'color',
          // modifiers: [['darker', 1.6]],
        }}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return props.labelFormat(e);
        }}
      />
    </div>
  );
};

export default BarGraphChart;
