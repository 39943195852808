import { UserProfileRes } from '@services/private/UserProfileAPI';
import React, { useMemo } from 'react';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ContainerScale from '@components/Layout/ContainerScale';
import styled from 'styled-components';

export const ViewTeacherProfilePage = (props: { item: UserProfileRes }) => {
  const { t } = useTranslation();

  const coverImg = useMemo(() => {
    if (props.item.youtubeUrl != null && props.item.youtubeUrl) {
      return (
        <ContainerScale scale={9 / 16} minHeight={300}>
          <iframe
            title={props.item.displayName}
            className="video-instruction content-scale"
            width="100%"
            height="100%"
            src={props.item.youtubeUrl}
          />
        </ContainerScale>
      );
    } else if (props.item.thumbnailsUrl != null && props.item.thumbnailsUrl) {
      return (
        <ContainerScale scale={9 / 16} minHeight={300}>
          <img
            className={'content-scale'}
            alt={props.item.name}
            src={props.item.thumbnailsUrl}
            style={{ width: '100%', height: 'auto' }}
          />
        </ContainerScale>
      );
    } else {
      return null;
    }
  }, [props.item]);

  const subscribeUser = () => {};

  return (
    <ViewTeacherProfileStyle className={'teacher-profile-detail'}>
      <div className={'site-introduction'}>{coverImg}</div>

      <div className={'site-header'}>
        <div className={'site-avatar'}>
          <img alt={props.item.shortIntroduce} src={props.item.avatarUrl} />
        </div>

        <div className={'site-title-display'}>
          <h2 dangerouslySetInnerHTML={{ __html: props.item.name }} />
        </div>

        <div className={'site-title-actions'}>
          <Button
            type="text"
            onClick={subscribeUser}
            style={{ float: 'right' }}
          >
            {t('label.subscribe')}
          </Button>
        </div>
      </div>

      <Divider />

      <div
        className={'site-introduce'}
        dangerouslySetInnerHTML={{ __html: props.item.introduce }}
      />
    </ViewTeacherProfileStyle>
  );
};

const ViewTeacherProfileStyle = styled.div`
  .site-header {
    margin-top: 2em;
  }
`;
