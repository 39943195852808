import React from 'react';

import { CompAnswerProps, CompScoringProps } from '@cms/ComponentInteface';
import { ColumnsType } from 'antd/lib/table';
import { H3 } from '@components/typography';
import { TableDefault } from '@components/table/TablePagination';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import Button from '@components/button';
import { regenerationCorrectAnswer } from '@app/redux/slices/composeResource';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { Tooltip } from '@components/tooltip/Tooltip';
import { useDispatch, useSelector } from '@app/redux/hook';

const ResourceAnswerEditable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newAnswers } = useSelector((state) => state.composeResource);

  const columns: ColumnsType<CompAnswerProps> = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 400,
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      width: 180,
    },
    {
      title: (
        <>
          {t('component.label.correct_answer')}
          <Hint
            icon={<InfoCircleOutlined />}
            header={<h3>{t('component.label.correct_answer')}</h3>}
            content={
              <>
                <p>{t('resource.validation.correct_answer')}</p>
                <p>{t('resource.validation.correct_answer_2')}</p>
                <p>{t('resource.validation.correct_answer_3')}</p>
                <p>{t('resource.validation.correct_answer_4')}</p>
              </>
            }
          />
        </>
      ),
      dataIndex: 'provideCorrectAnswer',
      key: 'provideCorrectAnswer',
      render: (value, record) => {
        return record.answer ? (
          <span style={{ wordBreak: 'break-word' }}>
            {JSON.stringify(record.answer, null, 2)}
          </span>
        ) : (
          <span className={'text-red'}>
            {t('component.label.missing_correct_answer')}
          </span>
        );
      },
    },
  ];

  const getRowKey = (record: CompScoringProps) => {
    return record.id;
  };

  const onRegenerationCorrectAnswer = () => {
    dispatch(regenerationCorrectAnswer());
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H3>
          {t('component.label.correct_answer')}

          <Tooltip
            placement="top"
            title={'Click the button to clear all correct answers.'}
          >
            <Button
              danger
              type={'primary'}
              size={'small'}
              shape={'circle'}
              onClick={onRegenerationCorrectAnswer}
            >
              <SyncOutlined />
            </Button>
          </Tooltip>
        </H3>
      </HeaderGroup>

      <TableDefault
        rowKey={getRowKey}
        columns={columns}
        dataSource={newAnswers}
        pagination={false}
      />
    </>
  );
};

export default ResourceAnswerEditable;
