import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import { GlossaryGameCompProps } from '@cms/comps/game/GameUtils';
import { WordSearchBank } from '@cms/comps/game/word-search/WordSearchBank';
import WordSearchBackground from '@cms/comps/game/word-search/WordSearchBackground';
import styled from 'styled-components';
import WordBoxTryTimes from '@cms/comps/game/common/WordTryTime';
import {
  GlossaryGameActionEnum,
  useGlossaryGameItemContext,
} from '@cms/lesson-template/glossary-game/GlossaryGameContext';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useDispatch } from 'react-redux';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { GlossaryWordBank } from '@cms/comps/game/GlossaryWordBank';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';

export interface WordSearchSettingProps extends CompInteractSettingProps {
  layout: string;

  // setting of game...
  horizontal: boolean;
  vertical: boolean;
  diagonal: boolean;

  maxRows: number;
  maxColumns: number;
}

export interface WordSearchCompProps extends GlossaryGameCompProps {
  type: CompTypeEnum.WORD_SEARCH;
  setting: WordSearchSettingProps;
}

export function WordSearchComp(props: {
  item: WordSearchCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(true);

  const context = useGlossaryGameItemContext();

  const [points, setPoints] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  const onCorrect = (point: number) => {
    setPoints(point);
    context.dispatchAction(
      GlossaryGameActionEnum.correct_part,
      point,
      CompTypeEnum.WORD_SEARCH
    );
  };

  const onComplete = (point: number) => {
    setPoints(point);

    context.dispatchAction(
      GlossaryGameActionEnum.correct,
      point,
      CompTypeEnum.WORD_SEARCH
    );
  };

  useEffect(() => {
    if (points === props.item.configuration.sourceItems.length) {
      onComplete(points);
    } else if (points > 0) {
      onCorrect(points);
    }
  }, [points]);

  const handleComponentChange = (newComps: WordSearchCompProps) => {
    const answer = newComps.configuration.sourceItems.map((ans) => {
      return ans.word.data;
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );
    setShowComponent(true);
  };

  return (
    <WordSearchCompStyle className={'com-word-search'}>
      {showComponent && (
        <div className={'word-box-item'}>
          <div className={'word-search-bank-container'}>
            <WordSearchBank
              correctAnswers={correctAnswers}
              items={props.item.configuration.sourceItems}
            />
          </div>

          <div className={'word-search-separator'} />

          <div className={'word-search-background-container'}>
            <WordSearchBackground
              items={props.item.configuration.sourceItems}
              setting={props.item.setting}
              setPoints={setPoints}
              setCorrectAnswers={setCorrectAnswers}
            />

            <WordBoxTryTimes
              type={'correct'}
              current={points}
              total={props.item.configuration.sourceItems.length}
            />
          </div>

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </div>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <GlossaryWordBank
            item={props.item as GlossaryGameCompProps}
            onChange={handleComponentChange}
            onCancel={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>
    </WordSearchCompStyle>
  );
}

const WordSearchCompStyle = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  display: flex;
  border: 1px solid #ccc;

  .word-box-item {
    width: 100%;
    height: 100%;
    padding: var(--cms-padding-option, 0.5em);
    display: flex;

    .word-search-separator {
      width: 2em;
      min-width: 2em;
    }

    .word-search-bank-container {
      flex-grow: 1;
    }

    .word-search-background-container {
      .try-times-container {
        margin-top: 1em;
      }
    }
  }
`;
