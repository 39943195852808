// @ts-nocheck
const pasteAsPlainText = (event: any) => {
  event.preventDefault();
  const text = event.clipboardData.getData('text/plain');
  document.execCommand('insertHTML', false, text);
};

export class TemplateEditableUtils {
  container: any;
  onChange: any;

  init(_workspace: HTMLElement, onChange: (answer: string) => void) {
    this.container = $(_workspace);

    this.onChange = () => {
      const $template = $('<div>').html($(_workspace).html());

      $template.find('[contentEditable]').removeAttr('contentEditable');
      $template.find('.editable-button-group').remove();
      const newValue = $template.html();
      onChange(newValue);
    };

    this.bindEvents(this.container, this.onChange);
  }

  update(answer: string) {
    this.container.empty();
    this.container.html(answer);
    this.bindEvents(this.container, this.onChange);
  }

  bindEvents = ($element: any, onChange: () => void) => {
    $element.find('[contentEditable]').removeAttr('contentEditable');
    $element.find('.editable-button-group').remove();

    if ($element[0].tagName === 'A') {
      $element.click((e) => {
        e.preventDefault();
      });
    }

    if ($element[0].tagName === 'IMG') {
      $element.click((e) => {
        e.preventDefault();
      });
    }

    if ($element.attr('editable') === 'true') {
      $element.attr('contentEditable', 'true');
      $element[0].onPaste = pasteAsPlainText;
      $element.blur(() => {
        onChange();
      });
    } else if ($element.attr('editable') === 'group') {
      $element.children().each((idx: number, child: any) => {
        this.bindEvents($(child), onChange);

        const buttonGroup = $('<div class="editable-button-group"></div>');
        const addMoreBtn = $('<span class="add-group-button">+</span>');
        const removeBtn = $('<span class="remove-group-button">&minus;</span>');

        buttonGroup.append(addMoreBtn);
        $(buttonGroup).append(removeBtn);
        $element.append(buttonGroup);

        addMoreBtn.click((evt: any) => {
          const parent = $(evt.target).closest('[editable="group"]');
          const clone = parent.clone();
          clone.insertAfter(parent);
          onChange();
        });

        removeBtn.click((evt: any) => {
          const item = $(evt.target).closest('[editable="group"]');
          item.remove();
          onChange();
        });
      });
    } else {
      $element.children().each((idx: number, child: any) => {
        this.bindEvents($(child), onChange);
      });
    }
  };
}
