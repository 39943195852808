import React from 'react';
import Head from '@app/header';
import { useTranslation } from 'react-i18next';

const AudioTestingExample = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('organization')} - Template</title>
        <meta name="description" content="Parent registration" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </>
  );
};

export default AudioTestingExample;
