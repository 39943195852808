import { ExerciseReportRes } from '@services/reports/LessonReport';

import {
  CoordinatePointProps,
  LineGraphChardRecordProps,
} from '@modules/reports/charts/line/LineGraphChart';

import React, { useState } from 'react';
import ColorUtils from '@utils/ColorUtils';
import { ReportUtils } from '@modules/reports/ReportUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { H3 } from '@components/typography';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import PumpChart from '@modules/reports/charts/line/BumpChart';

const LessonStandardGrowth = (props: { reports: ExerciseReportRes[] }) => {
  const [standardReports] = useState<LineGraphChardRecordProps[]>(() => {
    const standardReports: LineGraphChardRecordProps[] = [];

    props.reports[0].standards.forEach((item, standardIndex) => {
      const standardScore: CoordinatePointProps[] = [];

      props.reports.forEach((exercise) => {
        const targetStd = exercise.standards[standardIndex];

        standardScore.push({
          x: DateAndTimeUtils.format(
            exercise.exercise.createdDate,
            DateAndTimeFormat.SHORT
          ),
          y: ReportUtils.percent(targetStd.score, targetStd.maxScore!),
        });
      });

      const report = {
        id: item.standard.code,
        color: ColorUtils.getDefaultColor(item.standard.standardId).color,
        data: standardScore,
      };

      standardReports.push(report);
    });
    return standardReports;
  });

  const renderFunc = (
    data: LineGraphChardRecordProps
  ): LineGraphChardRecordProps => {
    return data;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Standard Growth Report{' '}
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Standard Growth Report</h3>}
          content={
            <>
              <p>
                Biểu đồ này thể hiện sự thay đổi của các kí năng của bạn theo
                thời gian{' '}
              </p>

              <p>Ngoài ra nó còn dùng để so sánh các skill khác nhau.</p>
            </>
          }
        />
      </H3>

      <PumpChart
        className={'lesson-standard-chart'}
        size={500}
        content={standardReports}
        render={renderFunc}
      />
    </LessonReportChartStyle>
  );
};

export default LessonStandardGrowth;
