import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import MyTeacherCourse from '@modules/course/container/MyTeacherCourse';
import MyTeacherRegistrationCourse from '@modules/course/container/MyTeacherRegistrationCourse';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';

export enum CourseActionEnum {
  default = '',

  add_course = 'add_course',
  edit_course = 'edit_course',
  view_student = 'view-student',
  remove_course = 'remove-course',
}
export default function MyCoursePage() {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24} key={'course-product'}>
          <MyTeacherCourse />
        </Col>

        <Col span={24} key={'empty-product'}>
          <HeaderGroup className="header-group">
            <H1>{t('header.registration_courses')}</H1>
          </HeaderGroup>

          <MyTeacherRegistrationCourse />
        </Col>
      </Row>
    </>
  );
}
