import React, { useState } from 'react';
import { Button, Popover, Space } from 'antd';
import {
  GroupContentStyle,
  GroupHeaderStyle,
} from '@components/template/app-layout/header/HeaderAccountMenu';
import { useTranslation } from 'react-i18next';
import { CommentOutlined } from '@ant-design/icons';

const HeaderMessageMenu = () => {
  const { t } = useTranslation();
  const [hasMessage] = useState(false);

  const title = (
    <GroupHeaderStyle className={'user-header-info'}>
      <h3>{t('menu.my_messages')}</h3>
    </GroupHeaderStyle>
  );

  const content = (
    <GroupContentStyle className={'user-group-info'}>
      <Space size={8} direction={'vertical'} style={{ width: '100%' }}>
        You have no message.
      </Space>
    </GroupContentStyle>
  );

  return (
    <>
      {hasMessage ? (
        <Popover
          placement="bottom"
          title={title}
          content={content}
          trigger={['click']}
          overlayClassName={'user-info-popup'}
        >
          <Button
            type={'default'}
            shape="circle"
            disabled={true}
            size={'large'}
            style={{
              background: '#FFF',
            }}
          >
            <CommentOutlined />
          </Button>
        </Popover>
      ) : (
        <Button
          type={'default'}
          shape="circle"
          disabled={true}
          size={'large'}
          style={{
            background: '#FFF',
          }}
        >
          <CommentOutlined />
        </Button>
      )}
    </>
  );
};

export default HeaderMessageMenu;
