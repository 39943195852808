import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import { FormikProvider } from 'formik';
import { Button, Select } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import React, { useEffect } from 'react';
import Checkbox from '@components/input/Checkbox';
import { InputGroup } from '@components/input/InputGroup';
import Card, { CardClassEnum } from '@components/card';
import { HintText } from '@components/text/HintText';
import { useTranslation } from 'react-i18next';

export default function LessonTemplateConfig(props: {
  formik: any;
  onNext: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.formik.values.singleMode) {
      props.formik.setFieldValue('singleQuestionMode', false);
    }

    if (!props.formik.values.singleQuestionMode) {
      props.formik.setFieldValue('stepByStep', false);
    }
  }, [props.formik.values]);

  useEffect(() => {
    // handle layout change.
    const layout = props.formik.values.layout;

    if (layout === LessonTemplateLayoutEnum.two_page) {
      props.formik.setFieldValue('singleMode', true);
      props.formik.setFieldValue('singleQuestionMode', false);
      props.formik.setFieldValue('stepByStep', false);
    } else if (layout === LessonTemplateLayoutEnum.presentation) {
      props.formik.setFieldValue('singleMode', true);
      props.formik.setFieldValue('singleQuestionMode', true);
    }
  }, [props.formik.values.layout]);

  return (
    <FormikProvider value={props.formik}>
      <Card className={CardClassEnum.rectangle_box}>
        <InputGroup label={t('lesson_template.config.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('lesson_template.config.select_layout')}
            value={props.formik.values.layout}
            onChange={(value) => props.formik.setFieldValue('layout', value)}
          >
            <Select.Option
              key={LessonTemplateLayoutEnum.responsive}
              value={LessonTemplateLayoutEnum.responsive}
            >
              {t('lesson_template.config.responsive')}
            </Select.Option>

            <Select.Option
              key={LessonTemplateLayoutEnum.letter}
              value={LessonTemplateLayoutEnum.letter}
            >
              {t('lesson_template.config.letter')}
            </Select.Option>

            <Select.Option
              key={LessonTemplateLayoutEnum.letter_horizontal}
              value={LessonTemplateLayoutEnum.letter_horizontal}
            >
              {t('lesson_template.config.letter_horizontal')}
            </Select.Option>

            <Select.Option
              key={LessonTemplateLayoutEnum.two_page}
              value={LessonTemplateLayoutEnum.two_page}
            >
              {t('lesson_template.config.two_page')}
            </Select.Option>

            <Select.Option
              key={LessonTemplateLayoutEnum.presentation}
              value={LessonTemplateLayoutEnum.presentation}
            >
              {t('lesson_template.config.presentation')}
            </Select.Option>
          </Select>
        </InputGroup>

        <InputGroup label={t('lesson_template.config.layout_setting')}>
          <Checkbox
            formik={props.formik}
            name={'singleMode'}
            checked={props.formik.values.singleMode}
            value={props.formik.values.singleMode}
            onChange={props.formik.handleChange}
            disabled={
              props.formik.values.layout === LessonTemplateLayoutEnum.two_page
            }
          >
            {t('lesson_template.config.single_section')}
            <HintText>
              {t('lesson_template.config.single_section_hint')}
            </HintText>
          </Checkbox>

          <Checkbox
            formik={props.formik}
            name={'singleQuestionMode'}
            checked={props.formik.values.singleQuestionMode}
            value={props.formik.values.singleQuestionMode}
            onChange={props.formik.handleChange}
            disabled={
              !props.formik.values.singleMode ||
              props.formik.values.layout === LessonTemplateLayoutEnum.two_page
            }
          >
            {t('lesson_template.config.single_question')}
            <HintText>
              {t('lesson_template.config.single_question_hint')}
            </HintText>
          </Checkbox>

          <Checkbox
            formik={props.formik}
            name={'stepByStep'}
            checked={props.formik.values.stepByStep}
            value={props.formik.values.stepByStep}
            onChange={props.formik.handleChange}
            disabled={
              !props.formik.values.singleQuestionMode ||
              props.formik.values.layout === LessonTemplateLayoutEnum.two_page
            }
          >
            {t('lesson_template.config.step_by_step')}
            <HintText>{t('lesson_template.config.step_by_step_hint')}</HintText>
          </Checkbox>
        </InputGroup>
      </Card>

      <ButtonGroup className="mt-5" type={'space-between'}>
        <Button
          type="default"
          size={'large'}
          shape={'round'}
          onClick={props.onCancel}
        >
          {t('button.cancel')}
        </Button>

        <Button
          type={'primary'}
          size={'large'}
          shape={'round'}
          onClick={props.onNext}
        >
          {t('lesson_template.label.go_to_style_n_layout')}
        </Button>
      </ButtonGroup>
    </FormikProvider>
  );
}
