import React, { useState } from 'react';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';
import {
  ContentActionEnum,
  ContentViewerInlineEditable,
} from '@cms/comps/content/ContentViewerComp';

import styled from 'styled-components';
import OptionEditable from '@cms/editable/OptionEditable';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { CircleUnderlineOptionProps } from '@cms/comps/interact/circle-underline/CircleUnderlineComp';
import { useCircleUnderlineContext } from '@cms/comps/interact/circle-underline/CircleUnderlineCompContext';
import { useCircleUnderlineEditableContext } from '@cms/comps/interact/circle-underline/CircleUnderlineEditableContext';
import {
  SelectTextProps,
  TextSelectable,
} from '@cms/comps/common/TextSelectable';

import { SelectTextAction } from '@cms/comps/interact/circle-underline/SelectTextAction';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';

export const CircleUnderGroupItem = ({
  drop,
  index,
}: {
  index: number;
  drop: CircleUnderlineOptionProps;
}) => {
  const { disabled, setting, targetItems, answers, handleOnChange } =
    useCircleUnderlineContext();

  const {
    editItem,
    changeToEdit,
    updateOptionData,
    updateOptionType,
    updateGroupOptions,
  } = useCircleUnderlineEditableContext();

  const [action, setAction] = useState<{ id: string; type: string }>({
    id: '',
    type: '',
  });

  const [selectText, setSelectText] = useState<any | null>(null);

  const getClassName = () => {
    return [
      'circle-underline-group-item',
      editItem === drop.label ? 'edit-mode' : 'default-mode',
      `group-${index}`,
    ].join(' ');
  };

  const onSelectText = (
    data: SelectTextProps,
    bBox: { x: number; y: number; width: number; height: number }
  ) => {
    setSelectText({
      id: data.id,
      type: data.type,
      x: bBox.x,
      y: bBox.y,
      width: bBox.width,
      height: bBox.height,
    });
  };

  const onContentChange = (text: string) => {
    handleOnChange(index + ':' + text);
  };

  const onActionChange = (id: string, action: string) => {
    if (!disabled) {
      if (action === 'remove') {
        setAction({ id, type: 'remove' });
        setSelectText(null);
      } else if (action === 'cancel') {
        setAction((prev) => {
          return { ...prev, type: 'cancel' };
        });
        setSelectText(null);
      } else {
        setAction({ id, type: action });
        setSelectText((prev: any) => {
          return { ...prev, type: action };
        });
      }
    }
  };

  const getInlineAnswer = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans.split(':')[1];
      }
    });
    return inlineAns;
  };

  const compareFbFunc = (ans: any[], res: any) => {
    return ans.some((crAns) => {
      return crAns.split(':')[0] === res.split(':')[0];
    });
  };

  return (
    <CircleUnderlineGroupItemStyle className={getClassName()}>
      {editItem === drop.label ? (
        <div className={`circle-underline-item`}>
          <ContentViewerInlineEditable
            hideInput={true}
            contentData={drop.content}
            onChange={(data) => updateOptionData(data, index, drop)}
            onCancel={() => changeToEdit('')}
          />
        </div>
      ) : (
        <OptionEditable
          disabled={{
            addOption: targetItems.length === 15,
            removeOption: targetItems.length === 1,
          }}
          contentTypes={[ContentActionEnum.CHANGE_TO_TEXT]}
          type={drop.content.type}
          onChangeType={(newType) => updateOptionType(newType, index, drop)}
          onOperation={(operation) =>
            updateGroupOptions(operation, index, drop)
          }
        >
          <TextSelectable
            disabled={disabled}
            className={'circle-underline-item'}
            content={drop.content.data}
            answer={getInlineAnswer(index + '')}
            setting={{
              className: 'circle-underline',
              excludeClassName: [],
            }}
            action={action}
            actions={{
              onSelect: onSelectText,
              onChange: onContentChange,
            }}
          >
            <CompFeedbackResultIcon
              answer={index + ':' + getInlineAnswer(index + '')}
              compareFunc={compareFbFunc}
            />

            {selectText && selectText.id && !disabled && (
              <SelectTextAction
                setting={setting}
                data={selectText}
                onChange={(type) => onActionChange(selectText.id, type)}
              />
            )}
          </TextSelectable>

          <ComposeModeContent>
            {editItem === '' && (
              <Button
                className={'edit-content-group'}
                size={'small'}
                type={'primary'}
                shape={'circle'}
                onClick={() => changeToEdit(drop.label)}
                icon={IconUtils.actions.edit}
              />
            )}
          </ComposeModeContent>
        </OptionEditable>
      )}
    </CircleUnderlineGroupItemStyle>
  );
};

const CircleUnderlineGroupItemStyle = styled.div`
  width: 100%;

  .feedback-icon {
    position: absolute;
    top: 50%;
    right: -0.5em;
    transform: translate(100%, -50%);
  }

  .cms-content-actions {
    width: 100%;

    .cms-content-display {
      padding-right: 30px;
      position: relative;

      .edit-content-group {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0%, -50%);
      }
    }
  }

  .circle-underline-item {
    .content-inline-editor {
      width: 100%;
    }
  }

  .circle-underline-item {
    display: block;
    background: rgba(0, 0, 0, 0.03);
    padding: 0.25em;
    border-radius: 0.25em;

    .circle-underline {
      background: ${(props) => props.theme.component.primary_bgr};
      cursor: pointer;

      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;

      &[type='circle'] {
        border: 2px solid ${(props) => props.theme.component.primary};

        .show-correct-answer & {
          border-color: ${(props) => props.theme.component.correct_answer};
          background: ${(props) => props.theme.component.correct_answer_bgr};
        }
      }

      &[type='underline'] {
        border-bottom: 2px solid ${(props) => props.theme.component.primary};

        .show-correct-answer & {
          border-color: ${(props) => props.theme.component.correct_answer};
          background: ${(props) => props.theme.component.correct_answer_bgr};
        }
      }
    }
  }

  .component-and-feedback {
    position: relative;
    display: flex;
    align-items: baseline;

    .feedback-icon {
      position: relative;
    }
  }
`;
