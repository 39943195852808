import React from 'react';
import Head from '@app/header';
import { useTranslation } from 'react-i18next';

const CoordinateTesting = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('organization')} - Coordinate</title>
        <meta name="description" content="Parent registration" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div style={{ padding: 50 }}>
        <div
          style={{
            maxWidth: 1100,
            margin: '0 auto',
            border: '1px solid #000',
            background: '#FFF',
          }}
        />
      </div>
    </>
  );
};

export default CoordinateTesting;
