import React from 'react';

import LoginOauthForm from '@modules/authorization/components/oauth2/LoginOauthForm';

import { Col, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { SiteMap } from '@router/SiteMap';
import { useRouter } from '@hooks/useRouter';

const LoginOauth = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleLoginSuccess = () => {
    setTimeout(() => {
      router.push(SiteMap.private.dashboard);
    }, 500);
  };

  return (
    <PublicLayout>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'Login with Oauth2 testing'}
        description={'Login with Oauth2 testing'}
      />

      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            <H1>{t('login.header')}</H1>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <p>{t('login.description')}</p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <LoginOauthForm onSuccess={handleLoginSuccess} />
              </Col>
            </Row>
          </div>
        </div>
      </AuthorizeStyle>
    </PublicLayout>
  );
};

export default LoginOauth;
