import { useEffect, useState } from 'react';

export const ResponsiveSize = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export enum ResponsiveScreen {
  empty = '',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

const queries = [
  `(min-width: ${ResponsiveSize.xxl + 1}px)`,
  `(min-width: ${ResponsiveSize.xl + 1}px)`,
  `(min-width: ${ResponsiveSize.lg + 1}px)`,
  `(min-width: ${ResponsiveSize.md + 1}px)`,
  `(min-width: ${ResponsiveSize.sm + 1}px)`,
  `(min-width: ${ResponsiveSize.xs + 1}px)`,
];

const screens = [
  ResponsiveScreen.xxl,
  ResponsiveScreen.xl,
  ResponsiveScreen.lg,
  ResponsiveScreen.md,
  ResponsiveScreen.sm,
  ResponsiveScreen.xs,
];

const getValue = () => {
  const defaultValue = ResponsiveScreen.xs;

  try {
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    return typeof screens[index] !== 'undefined'
      ? screens[index]
      : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

const useMediaQuery = () => {
  const [value, setValue] = useState<ResponsiveScreen>(ResponsiveScreen.empty);

  useEffect(() => {
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    setValue(getValue);

    const handler = () => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addListener(handler));

    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, []);

  return value;
};

export default useMediaQuery;
