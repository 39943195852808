import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import { OneColumnSectionProps } from '@cms/section-bank/columns/OneColumnSection';
import { TwoColumnSectionProps } from '@cms/section-bank/columns/TwoColumnSection';
import { ThreeColumnSectionProps } from '@cms/section-bank/columns/ThreeColumnSection';
import { FixedGroupSectionProps } from '@cms/section-bank/fixed-group/FixedGroupSection';
import React from 'react';
import { OneColumnSectionSetting } from '../columns/OneColumnSectionSetting';
import { ThreeColumnSectionSetting } from '../columns/ThreeColumnSectionSetting';
import { TwoColumnSectionSetting } from '../columns/TwoColumnSectionSetting';
import { FixedGroupSectionSetting } from '@cms/section-bank/fixed-group/FixedGroupSectionSetting';
import { InteractVideoSectionSetting } from '@cms/section-bank/interact-video/InteractVideoSectionSetting';
import { InteractVideoSectionProps } from '@cms/section-bank/interact-video/InteractVideoSection';

const SectionSettingEditable = (props: {
  item: LessonSectionProps;
  onChange: (setting: string) => void;
}) => {
  switch (props.item.type) {
    case SectionTypeEnum.one_column:
      return (
        <OneColumnSectionSetting
          item={props.item as OneColumnSectionProps}
          onChange={props.onChange}
        />
      );

    case SectionTypeEnum.two_column:
      return (
        <TwoColumnSectionSetting
          item={props.item as TwoColumnSectionProps}
          onChange={props.onChange}
        />
      );

    case SectionTypeEnum.three_column:
      return (
        <ThreeColumnSectionSetting
          item={props.item as ThreeColumnSectionProps}
          onChange={props.onChange}
        />
      );

    case SectionTypeEnum.fixed_group:
      return (
        <FixedGroupSectionSetting
          item={props.item as FixedGroupSectionProps}
          onChange={props.onChange}
        />
      );

    case SectionTypeEnum.interact_video:
      return (
        <InteractVideoSectionSetting
          item={props.item as InteractVideoSectionProps}
          onChange={props.onChange}
        />
      );

    default:
      return null;
  }
};

export default SectionSettingEditable;
