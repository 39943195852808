import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  DragNDropAnsProps,
  DragNDropCompProps,
  DragNDropItemCompProps,
  DragNDropSettingProps,
  getDragNDropAns,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { CompAnswerProps, CompProps } from '@cms/ComponentInteface';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { CompGroupEnum } from '@cms/utils/CompProps';
import produce from 'immer';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import { ContentOperationEnum } from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';

const DragNDropCompContext = createContext({
  modifiedTime: -1,
  disabled: false as boolean,
  id: '' as string,
  setting: {} as DragNDropSettingProps | DragNDropSettingProps,
  sourceItems: [] as DragNDropItemCompProps[],
  targetItems: [] as DragNDropItemCompProps[],

  disableSource: [] as string[],
  disableTarget: [] as string[],
  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  // for interact
  onDropData: (data: string) => {
    console.log(data);
  },

  onMoveData: (oldData: string, newData: string) => {
    console.log(oldData, newData);
  },

  onRemoveData: (data: string) => {
    console.log(data);
  },

  // for editable
  updateOptions: (options: DragNDropItemCompProps[], type: CompGroupEnum) => {
    console.log(options, type);
  },
  updateAnswers: (answers: string[]) => {
    console.log(answers);
  },
  updateComponent: () => {},

  updateOptionType: (
    type: AssetsTypeEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(type, index, option, from);
  },

  updateGroupOptions: (
    operation: ContentOperationEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(operation, index, option, from);
  },

  updateOptionData: (
    data: AssetsContentProps,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(data, index, option, from);
  },
});

export const DragNDropCompContextProvider = (props: {
  disabled: boolean;
  item: DragNDropCompProps;
  answer: DragNDropAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();
  const [answer, setCompAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);
  const [disableSource, setDisableSource] = useState<string[]>([]);
  const [disableTarget, setDisableTarget] = useState<string[]>([]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (props.answer && props.answer.answer) {
      setCompAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: [],
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    updateDisabledGroup(answer.value);
  }, [answer.value, props.item.setting]);

  const updateDisabledGroup = (answers: string[]) => {
    const disabledSrc: string[] = [];
    const disabledTgt: string[] = [];

    // each object is used one time
    if (!props.item.setting.multipleDrag) {
      answers.forEach((item) => {
        const ans = getDragNDropAns(item);
        disabledSrc.push(ans.drag);
      });
    }

    if (!props.item.setting.multipleDrop) {
      answers.forEach((item) => {
        const ans = getDragNDropAns(item);
        disabledTgt.push(ans.drop);
      });
    }

    setDisableSource(disabledSrc);
    setDisableTarget(disabledTgt);
  };

  const onMoveData = (oldData: string, newData: string) => {
    setCompAnswer((prevAns) => {
      const reduceAns = [...prevAns.value].filter((ans) => {
        return ans !== oldData;
      });

      if (!reduceAns.includes(newData)) {
        const newAnswers = [...reduceAns, newData];
        return {
          value: newAnswers,
          triggerChange: true,
        };
      } else {
        return {
          value: reduceAns,
          triggerChange: false,
        };
      }
    });
  };

  const onDropData = (answerPart: string) => {
    if (!props.disabled) {
      setCompAnswer((prevAns) => {
        const reduceAns = [...prevAns.value];

        if (!reduceAns.includes(answerPart)) {
          const newAnswers = [...reduceAns, answerPart];
          return {
            value: newAnswers,
            triggerChange: true,
          };
        } else {
          return {
            value: reduceAns,
            triggerChange: false,
          };
        }
      });
    }
  };

  const onRemoveData = (removeAns: string) => {
    if (!props.disabled) {
      setCompAnswer((prevAns) => {
        const reduceAnswer = [...prevAns.value].filter((str) => {
          return str !== removeAns;
        });

        return {
          value: reduceAnswer,
          triggerChange: true,
        };
      });
    }
  };

  const updateAnswers = (newAnswers: string[]) => {
    setCompAnswer({
      value: newAnswers,
      triggerChange: true,
    });
  };

  // then custom data.............
  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );
  const [targetItems, setTargetItems] = useState(
    props.item.configuration.targetItems
  );

  const updateOptions = (
    options: DragNDropItemCompProps[],
    type: CompGroupEnum
  ) => {
    if (type === CompGroupEnum.source) {
      setSourceItems(options);
    } else {
      setTargetItems(options);
    }
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  // for edit able
  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    if (from === CompGroupEnum.source) {
      const newSourceItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        sourceItems
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        targetItems
      );
      updateOptions(newTargetItems, from);
    }
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    let newOptions: DragNDropItemCompProps[] = [];
    let newAnswers: string[] = [];

    if (from === CompGroupEnum.source) {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          sourceItems,
          OptionTypeEnum.ABC
        );

        newAnswers = handleInsertOption(
          newOptions[index + 1].label,
          answer.value
        );

        // then, update correct answer.
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          sourceItems,
          OptionTypeEnum.ABC
        );

        newAnswers = handleRemoveOption(option.label, answer.value);
      }
    } else {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleInsertGroup(index, answer.value);
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleRemoveGroup(index + 1, answer.value);
      }
    }

    // then update data...
    updateOptions(newOptions, from);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    const newOption = { label: option.label, content: { ...data } };

    if (from === CompGroupEnum.source) {
      const newSourceItems = COMPONENT_UTILS.updateAtIndex(
        sourceItems,
        index,
        newOption
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = COMPONENT_UTILS.updateAtIndex(
        targetItems,
        index,
        newOption
      );
      updateOptions(newTargetItems, from);
    }
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
        targetItems: targetItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer.value,
        },
      })
    );
  };

  return (
    <DragNDropCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        disabled: props.disabled,
        id: props.item.id,
        setting: props.item.setting,
        sourceItems: sourceItems,
        targetItems: targetItems,

        disableSource: disableSource,
        disableTarget: disableTarget,
        answers: answer.value,
        feedback: feedBack,

        updateOptions,
        updateAnswers,
        updateComponent,

        updateGroupOptions,
        updateOptionData,
        updateOptionType,

        onDropData,
        onRemoveData,
        onMoveData,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedBack}>
        {props.children}
      </CompFeedbackContextProvider>
    </DragNDropCompContext.Provider>
  );
};

export const useDragNDropCompContext = () => {
  const context = useContext(DragNDropCompContext);
  if (!context) {
    throw new Error('You must wrap container by DragNDropCompContextProvider');
  }
  return context;
};

const handleInsertOption = (optionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(optionLabel);

  return answers.map((ans) => {
    const ansData = parseDragNDropAnswer(ans);
    // if answer is a -> a => b
    const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
    if (answerIndex >= newOptionIndex) {
      return generateDragNDropAns(
        ansData.index,
        ansData.groupLabel,
        COMPONENT_UTILS.getABC(answerIndex + 1)
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveOption = (removeOptionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(removeOptionLabel);

  return answers
    .filter((ans) => {
      const ansData = parseDragNDropAnswer(ans);
      return ansData.optionLabel !== removeOptionLabel;
    })
    .map((ans) => {
      const ansData = parseDragNDropAnswer(ans);

      const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
      if (answerIndex > newOptionIndex) {
        return generateDragNDropAns(
          ansData.index,
          ansData.groupLabel,
          COMPONENT_UTILS.getABC(answerIndex - 1)
        );
      } else {
        return ans;
      }
    });
};

const handleInsertGroup = (index: number, answers: string[]) => {
  // then, update correct answer.
  return answers.map((ans) => {
    const ansData = parseDragNDropAnswer(ans);

    if (ansData.index > index + 1) {
      return generateDragNDropAns(
        ansData.index + 1,
        ansData.groupLabel,
        ansData.optionLabel
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveGroup = (index: number, answers: string[]) => {
  return answers
    .filter((ans) => {
      const ansData = parseDragNDropAnswer(ans);
      return ansData.index !== index;
    })
    .map((ans) => {
      const ansData = parseDragNDropAnswer(ans);

      if (ansData.index > index) {
        return generateDragNDropAns(
          ansData.index - 1,
          ansData.groupLabel,
          ansData.optionLabel
        );
      } else {
        return ans;
      }
    });
};

const parseDragNDropAnswer = (ans: string) => {
  const groupPart = ans.split(':')[0];
  const answerPart = ans.split(':')[1];

  const index = groupPart.split('|')[0];
  const groupLabel = groupPart.split('|')[1];

  return {
    index: Number(index),
    groupLabel: groupLabel,
    optionLabel: answerPart,
  };
};

export const generateDragNDropAns = (
  group: number,
  groupIndex: string,
  optionLabel: string
) => {
  return `${group}|${groupIndex}:${optionLabel}`;
};
