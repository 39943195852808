import React from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import Authorization from '@utils/authorization';
import ComposeProductGroup from '@modules/product/container/product/ComposeProductGroup';

export default function ManageProductPage() {
  return (
    <>
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.CREATE_PRODUCT, RoleEnum.SCHOOL]}
      >
        <ComposeProductGroup />
      </Authorization>
    </>
  );
}
