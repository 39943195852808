import React from 'react';

import { useRouter } from '@hooks/useRouter';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { CompMode } from '@cms/ComponentInteface';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';

const GradeExercise = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { exerciseId, productId, productTocId } = router.query as unknown as {
    exerciseId: number;
    productTocId: number;
    productId: number;
  };

  return (
    <EmptyLayout className={'grade-exercise-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={
          productId
            ? NavigationMenuEnum.LIBRARY_PRODUCTS
            : NavigationMenuEnum.LIBRARY_LESSONS
        }
        title={t('exercise.actions.resume')}
        description={t('exercise.actions.resume')}
      />

      <ViewExercisePage
        key={exerciseId + '_' + CompMode.GRADING_EXERCISE}
        type={CompMode.GRADING_EXERCISE}
        exerciseId={Number(exerciseId)}
        productTocId={productTocId}
        productId={productId}
      />
    </EmptyLayout>
  );
};

export default GradeExercise;
