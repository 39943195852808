import React, { ReactNode } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import Button from '@components/button';
import { Tooltip } from '@components/tooltip/Tooltip';

export const SimpleCheckBox = (props: {
  value: boolean;
  onChange?: (value: boolean) => void;
}) => {
  const onChange = (val: boolean) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  if (props.value) {
    return (
      <div className={'simple-checkbox'}>
        <Button
          type={'primary'}
          shape={'circle'}
          icon={<CheckOutlined />}
          onClick={() => onChange(false)}
        />
      </div>
    );
  } else {
    return (
      <div className={'simple-checkbox'}>
        <Button
          type={'default'}
          shape={'circle'}
          icon={<CheckOutlined />}
          onClick={() => onChange(true)}
        />
      </div>
    );
  }
};

export const DisplayCheckBox = (props: {
  tooltip: {
    checked: string;
    unchecked: string;
    empty: string;
  };
  icon?: {
    checked: ReactNode;
    unchecked: ReactNode;
    empty: ReactNode;
  };
  value: boolean | null;
  onClick?: () => void;
}) => {
  if (props.value === true) {
    return (
      <Tooltip title={props.tooltip.checked}>
        <div className={'simple-checkbox'}>
          <Button
            type={'primary'}
            shape={'circle'}
            icon={props.icon?.checked ?? <CheckOutlined />}
            onClick={props.onClick}
          />
        </div>
      </Tooltip>
    );
  } else if (props.value === false) {
    return (
      <Tooltip title={props.tooltip.unchecked}>
        <div className={'simple-checkbox'}>
          <Button
            type={'primary'}
            shape={'circle'}
            danger
            icon={props.icon?.unchecked ?? <CloseOutlined />}
            onClick={props.onClick}
          />
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={props.tooltip.empty}>
        <div className={'simple-checkbox'}>
          <Button
            type={'text'}
            shape={'circle'}
            icon={props.icon?.empty ?? <QuestionOutlined />}
            onClick={props.onClick}
          />
        </div>
      </Tooltip>
    );
  }
};
