import { SessionAPI } from '@services/private/SessionAPI';
import React, { useEffect } from 'react';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import { List } from 'antd';

import { SessionStudentRes } from '@services/model/session';
import styled from 'styled-components';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { IconUtils } from '@utils/IconUtils';

export const ShowRaiseHandModal = (props: {
  sessionId: number;
  raiseHandIds: string[];
  presentingIds: string[];
  onSelect: (allowPresent: boolean, uuid: string) => void;
  onClose: () => void;
}) => {
  const [viewSessionAttendances, { data }] =
    SessionAPI.endpoints.viewSessionAttendances.useLazyQuery();

  useEffect(() => {
    viewSessionAttendances(props.sessionId);
  }, [props.sessionId]);

  return (
    <div className={'show-raise-hand-participant'}>
      <List
        bordered
        itemLayout="horizontal"
        size={'small'}
        dataSource={data ?? []}
        renderItem={(userStatus: SessionStudentRes) => {
          const isRaiseHand = props.raiseHandIds.includes(userStatus.user.uuid);
          const isPresent = props.presentingIds.includes(userStatus.user.uuid);
          return (
            <List.Item>
              <UserRaiseHandStyle className={'user-raise-hand-group'}>
                <UserThumbnailDisplay
                  layout={'row'}
                  item={userStatus.user}
                  size={40}
                />

                <SwitchCondition
                  conditions={[
                    {
                      condition: isPresent,
                      children: (
                        <Button
                          shape={'round'}
                          type={'primary'}
                          danger={true}
                          icon={IconUtils.class_room.join_classroom}
                          onClick={() =>
                            props.onSelect(false, userStatus.user.uuid)
                          }
                        >
                          Stop Present
                        </Button>
                      ),
                    },

                    {
                      condition: isRaiseHand,
                      children: (
                        <Button
                          shape={'round'}
                          type={'primary'}
                          icon={IconUtils.class_room.join_classroom}
                          onClick={() =>
                            props.onSelect(true, userStatus.user.uuid)
                          }
                        >
                          Allow Present
                        </Button>
                      ),
                    },

                    {
                      condition: !isRaiseHand && !isPresent,
                      children: (
                        <Button
                          shape={'round'}
                          type={'default'}
                          onClick={() =>
                            props.onSelect(true, userStatus.user.uuid)
                          }
                          icon={IconUtils.class_room.raise_hand}
                        >
                          Ask for Answer
                        </Button>
                      ),
                    },
                  ]}
                />
              </UserRaiseHandStyle>
            </List.Item>
          );
        }}
      />

      <ButtonGroup type={'center'} className={'submit-container'}>
        <Button shape={'round'} size={'large'} onClick={props.onClose}>
          Close
        </Button>
      </ButtonGroup>
    </div>
  );
};

const UserRaiseHandStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
