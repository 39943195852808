import React, { ReactNode, useState } from 'react';
import { connect } from 'formik';
import { get as getPath } from 'lodash';

import { Input as AntInput } from 'antd';
import { ErrMessageStyle } from '@components/input/style';
import { InputGroup } from './InputGroup';
import { InputProps as AntInputProps } from 'antd/lib/input';
import styled from 'styled-components';
import Button from '../button';
import { SwapOutlined } from '@ant-design/icons';
import SocialVideo from '@cms/content/SocialVideoPlayList';

export interface YoutubeInputProps extends AntInputProps {
  name: string;
  addedClass?: string;
  label?: ReactNode;
  hint?: ReactNode;
  placeHolder?: string;
  formik?: any;
}

const YoutubeInput = ({
  name,
  addedClass,
  label,
  hint,
  placeHolder,
  required,
  formik,
  ...props
}: YoutubeInputProps) => {
  const [showVideo, setShowVideo] = useState(props.value ? true : false);

  const handleOnBlur = (evt: any) => {
    if (evt.target.value) {
      setShowVideo(true);
    }
  };

  return (
    <InputGroup
      addedClass={addedClass}
      label={label}
      hint={hint}
      required={required}
    >
      {showVideo ? (
        <YoutubeInputStyle
          className={'youtube-video-container'}
          style={{ paddingBottom: (9 / 16) * 100 + '%' }}
        >
          <SocialVideo type={'youtube'} src={props.value + ''} />

          <Button
            type={'primary'}
            shape={'circle'}
            icon={<SwapOutlined />}
            onClick={() => setShowVideo(false)}
          />
        </YoutubeInputStyle>
      ) : (
        <InputStyle>
          <AntInput
            autoComplete={'false'}
            autoCorrect={'false'}
            placeholder={placeHolder}
            name={name}
            required={required}
            size="large"
            style={{}}
            onBlur={handleOnBlur}
            {...props}
          />

          <Button
            type={'primary'}
            shape={'circle'}
            icon={<SwapOutlined />}
            disabled={!props.value}
            onClick={() => setShowVideo(true)}
          />
        </InputStyle>
      )}

      {getPath(formik?.errors, name) && getPath(formik?.touched, name) && (
        <ErrMessageStyle className="error-message">
          {getPath(formik?.errors, name)}
        </ErrMessageStyle>
      )}
    </InputGroup>
  );
};

export default connect(YoutubeInput);

const InputStyle = styled.div`
  display: flex;
  align-items: center;

  .ant-btn {
    margin-left: 1em;
  }
`;

const YoutubeInputStyle = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  .ant-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
