import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import React, { useEffect } from 'react';
import { PreviousQuestionBtn } from '@cms/lesson-template/components/button-wrapper/PreviousQuestionBtn';
import { NextQuestionBtn } from '@cms/lesson-template/components/button-wrapper/NextQuestionBtn';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import styled from 'styled-components';
import {
  AnonymousViewMode,
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';
import { SelfCheckBtn } from '@cms/lesson-template/components/button-wrapper/SelfCheckBtn';
import { NextSectionButton } from '@cms/lesson-template/components/buttons/NextSectionButton';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { NextQuestionButton } from '@cms/lesson-template/components/buttons/NextQuestionButton';
import { SubmitExerciseButton } from '@cms/lesson-template/components/buttons/exercise/SubmitExerciseButton';
import { SubmitExerciseBtn } from '@cms/lesson-template/components/button-wrapper/SubmitExerciseBtn';

export const SingleQuestionNavigationHandler = (props: {
  sectionId: number;
  resources: ResourceProps[];
}) => {
  const { resource, section, updateNavigation } = useLessonNavigationContext();

  useEffect(() => {
    if (
      props.sectionId === section.sectionId &&
      (resource.type !== LessonNavigationType.default ||
        resource.resourceId < 0)
    ) {
      let isMatch = false;

      const _resourceId = getSelectedResource(
        props.resources,
        resource.resourceId,
        resource.type
      );

      let _preResourceId = -1;
      let _nextResourceId = -1;

      props.resources.forEach((rs) => {
        if (rs.resourceId === _resourceId) {
          isMatch = true;
        } else {
          if (isMatch && _nextResourceId < 0) {
            _nextResourceId = rs.resourceId;
          } else if (!isMatch) {
            _preResourceId = rs.resourceId;
          }
        }
      });

      if (isMatch) {
        updateNavigation(LessonNavigationAction.UPDATE_RESOURCE_NAV, {
          resourceId: _resourceId,
          previousResourceId: _preResourceId,
          nextResourceId: _nextResourceId,
          type: LessonNavigationType.default,
        });
      }
    }
  }, [props.resources, section.sectionId, resource.resourceId, resource.type]);

  return null;
};

export const SingleQuestionToolbar = () => {
  const { interact, resource, lastQuestion, lastSection } =
    useLessonNavigationContext();
  const { config } = useLessonTemplateContext();

  return (
    <>
      {config.singleQuestionMode && (
        <>
          <SelfCheckExerciseMode>
            <SingleQuestionToolbarStyle
              className={'single-question-toolbar self-check-toolbar'}
            >
              <PreviousQuestionBtn />

              <SwitchCondition
                conditions={[
                  {
                    condition:
                      lastQuestion && lastSection && !resource.interact,
                    children: <></>,
                  },
                  {
                    condition: lastQuestion && lastSection && resource.interact,
                    children: <SelfCheckBtn />,
                  },
                  {
                    condition: !lastQuestion,
                    children: <NextQuestionButton />,
                  },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleQuestionToolbarStyle>
          </SelfCheckExerciseMode>

          <AnonymousViewMode>
            <SingleQuestionToolbarStyle
              className={'single-question-toolbar anonymous-toolbar'}
            >
              <PreviousQuestionBtn />

              <SwitchCondition
                conditions={[
                  {
                    condition: lastQuestion && lastSection && !interact,
                    children: <></>,
                  },
                  {
                    condition: lastQuestion && lastSection && interact,
                    children: <SubmitExerciseButton />,
                  },
                  {
                    condition: lastQuestion && lastSection && resource.interact,
                    children: <SelfCheckBtn />,
                  },
                  {
                    condition: !lastQuestion,
                    children: <NextQuestionButton />,
                  },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleQuestionToolbarStyle>
          </AnonymousViewMode>

          <DoExerciseMode>
            <SingleQuestionToolbarStyle
              className={'single-question-toolbar do-exercise-toolbar'}
            >
              <PreviousQuestionBtn />

              <SwitchCondition
                conditions={[
                  {
                    condition: lastQuestion && lastSection,
                    children: (
                      <SubmitExerciseBtn interact={resource.interact} />
                    ),
                  },
                  {
                    condition: !lastQuestion,
                    children: <NextQuestionButton />,
                  },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleQuestionToolbarStyle>
          </DoExerciseMode>

          <GradingExerciseMode>
            <SingleQuestionToolbarStyle
              className={'single-question-toolbar grading-toolbar'}
            >
              <PreviousQuestionBtn />

              <SwitchCondition
                conditions={[
                  {
                    condition: lastQuestion && lastSection,
                    children: (
                      // <ExitGradingButton />
                      <></>
                    ),
                  },
                  {
                    condition: !lastQuestion,
                    children: <NextQuestionButton />,
                  },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleQuestionToolbarStyle>
          </GradingExerciseMode>

          <ReviewExerciseMode>
            <SingleQuestionToolbarStyle
              className={'single-question-toolbar review-toolbar'}
            >
              <PreviousQuestionBtn />
              <NextQuestionBtn />
            </SingleQuestionToolbarStyle>
          </ReviewExerciseMode>
        </>
      )}
    </>
  );
};

const SingleQuestionToolbarStyle = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;

  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const getSelectedResource = (
  resources: ResourceProps[],
  defaultResourceId: number,
  type: LessonNavigationType
) => {
  if (resources.length === 0) {
    return -1;
  } else {
    if (type === LessonNavigationType.last) {
      return resources[resources.length - 1].resourceId;
    } else if (type === LessonNavigationType.first) {
      return resources[0].resourceId;
    } else {
      return defaultResourceId > 0
        ? defaultResourceId
        : resources[0].resourceId;
    }
  }
};
