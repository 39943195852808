import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { LessonRes } from '@modules/product/services/lesson_model';

export const StudentLessonAPI = createApi({
  reducerPath: 'StudentLessonAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyLesson: builder.query<LessonRes[], unknown>({
      query: () => ({
        url: `/student/lessons`,
      }),
    }),
  }),
});
