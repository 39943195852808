import React, { useEffect, useState } from 'react';

import { Button, Col, List, notification, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  CreateLanguageCommand,
  LanguageAPI,
  LanguageRes,
} from '@services/setting/LanguageAPI';
import Hint from '@components/text/Hint';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  LanguageEnum,
  LanguageIcon,
} from '@components/language/LanguageIcon';
import { Default_Gutter } from '@components/grid';

const initialValue: CreateLanguageCommand = {
  code: '',
  name: '',
  displayOrder: 1,
};

const availableLanguages = [
  LanguageEnum.English,
  LanguageEnum.Vietnamese,
  // LanguageEnum.French,
  // LanguageEnum.Japanese,
  // LanguageEnum.Chinese,
];

export function LanguageEditForm(props: {
  languageId?: number;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [showHintText, setShowHintText] = useState(false);

  const [findById, { data, isSuccess, isFetching }] =
    LanguageAPI.endpoints.findById.useLazyQuery();

  const [create, { isLoading: isCreateLoading }] =
    LanguageAPI.endpoints.create.useMutation({});
  const [update, { isLoading: isUpdateLoading }] =
    LanguageAPI.endpoints.update.useMutation({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.languageId != null && props.languageId > 0) {
      findById(props.languageId);
    } else {
      setIsReady(true);
    }
  }, [props.languageId]);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setIsReady(true);
    }
  }, [isSuccess]);

  const setFormData = (data: LanguageRes) => {
    formik.setValues({
      code: data.code,
      name: data.name,
      displayOrder: data.displayOrder,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      code: yup.string().required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.languageId != null && props.languageId > 0) {
        update({ ...values, languageId: props.languageId })
          .unwrap()
          .then(() => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.update_success_message'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotUpdate'),
              placement: 'bottomRight',
            });
          });
      } else {
        create(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.createSuccessful'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })

          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotCreate'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  const handleUpdateCode = (e: any, code: LanguageEnum) => {
    e.stopPropagation();
    formik.setFieldValue('code', code);
  };

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <Input
                hint={
                  <Hint
                    visible={showHintText}
                    onVisibleChange={(visible) => setShowHintText(visible)}
                    icon={<InfoCircleOutlined />}
                    header={'Select one of following'}
                    content={
                      <List size="small" style={{ cursor: 'pointer' }}>
                        {availableLanguages.map((lang) => {
                          return (
                            <List.Item
                              key={lang}
                              onClick={(e) => handleUpdateCode(e, lang)}
                            >
                              <LanguageIcon
                                language={lang}
                                height={20}
                                showLabel
                              />
                            </List.Item>
                          );
                        })}
                      </List>
                    }
                  />
                }
                required={true}
                name="code"
                type="text"
                label={t('language.label.code')}
                placeholder={t('language.label.code')}
                onChange={formik.handleChange}
                value={formik.values.code}
              />

              <Input
                required={true}
                name="name"
                type="text"
                label={t('language.label.name')}
                placeholder={t('language.label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Input
                name="displayOrder"
                type="number"
                label={t('language.label.display_order')}
                placeholder={t('language.label.display_order')}
                onChange={formik.handleChange}
                value={formik.values.displayOrder}
                min={1}
              />

              <div className="submit-container">
                <ButtonGroup>
                  <Button
                    type={'primary'}
                    loading={isCreateLoading || isUpdateLoading || isFetching}
                    onClick={formik.submitForm}
                  >
                    {props.languageId != null && props.languageId > 0 ? (
                      <>{t('button.update')}</>
                    ) : (
                      <>{t('button.create')}</>
                    )}
                  </Button>

                  <Button type="default" onClick={props.onCloseFunc}>
                    {t('button.cancel')}
                  </Button>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}
