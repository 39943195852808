import React, { useEffect } from 'react';

import { Button, Col, notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { PublishLessonAPI } from '@services/publish/PublishLessonAPI';
import { EntityStatusEnum } from '@services/model/common';
import ClipboardCopy from '@components/text/ClickToCopy';
import { SiteMap } from '@router/SiteMap';
import { LessonRes } from '@modules/product/services/lesson_model';

export default function CollectionPublishForm(props: {
  lessonId: number;
  successHandle: (isAdded: boolean, data: LessonRes) => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [getLesson, { data, isFetching }] =
    LessonAPI.endpoints.getLesson.useLazyQuery({});

  const [publishItem, { isLoading }] =
    PublishLessonAPI.endpoints.publishItem.useMutation({});

  useEffect(() => {
    if (props.lessonId && props.lessonId > 0) {
      getLesson(props.lessonId);
    }
  }, [props.lessonId]);

  const unPublishLesson = () => {
    publishItem({
      lessonId: props.lessonId,
      publish: false,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: t('my_lesson.warning.publish_success'),
          description: t('my_lesson.warning.publish_success_message'),
          placement: 'bottomRight',
        });
        props.successHandle(false, data!);
      })
      .catch(() => {
        notification.error({
          message: t('my_lesson.warning.publish_error'),
          description: t('my_lesson.warning.publish_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <div>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          {data && data.status === EntityStatusEnum.PUBLISHED && (
            <ClipboardCopy
              layout={'inline'}
              copyText={
                window.location.origin +
                SiteMap.public.lesson_view_gen(data.lessonId, data.name)
              }
            />
          )}
        </Col>

        <Col span={24}>
          <ButtonGroup type={'center'}>
            <Button
              type={'primary'}
              shape={'round'}
              danger={true}
              loading={isFetching || isLoading}
              onClick={unPublishLesson}
              icon={IconUtils.actions.publish}
            >
              {t('my_lesson.actions.change_to_private')}
            </Button>

            <Button type="default" shape={'round'} onClick={props.onCloseFunc}>
              {t('button.cancel')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}
