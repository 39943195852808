import React, { useEffect, useState } from 'react';
import { SessionAPI } from '@services/private/SessionAPI';
import { Loading } from '@components/loading/Loading';
import { SessionTaskGroupStyle } from '@classroom/container/task/style';
import { ClassRoomSessionTask } from './ClassRoomSessionTask';

export const ClassRoomInprogressTask = (props: { sessionId: number }) => {
  const [defaultTask, setDefaultTask] = useState(-1);

  const [getTasks, { data, isFetching, isSuccess }] =
    SessionAPI.endpoints.getTasks.useLazyQuery();

  useEffect(() => {
    getTasks(props.sessionId);
  }, [props.sessionId]);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.length > 0 && defaultTask < 0) {
        setDefaultTask(data[0].sessionTaskId);
      }
    }
  }, [data, defaultTask]);

  return (
    <Loading loading={isFetching}>
      {data &&
        data.map((ts) => {
          return (
            <SessionTaskGroupStyle
              className={`session-task-group ${
                ts.sessionTaskId === defaultTask ? 'active' : 'inactive'
              }`}
              key={ts.sessionTaskId}
            >
              <div
                className={'task-title-display'}
                onClick={() => setDefaultTask(ts.sessionTaskId)}
              >
                {ts.direction}
              </div>

              <div className={'task-content'}>
                {defaultTask === ts.sessionTaskId && (
                  <ClassRoomSessionTask
                    sessionTaskId={ts.sessionTaskId}
                    sessionId={props.sessionId}
                  />
                )}
              </div>
            </SessionTaskGroupStyle>
          );
        })}
    </Loading>
  );
};
