import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { SessionAPI } from '@services/private/SessionAPI';
import { ViewSession } from '@modules/calendar/components/CreateSessionForm';
import { ViewSessionLesson } from '@modules/calendar/components/SessionLessonForm';
import { ViewSessionStudent } from '@components/form/ShareSessionToStudentForm';
import PublicSessionForm from '@modules/calendar/components/PublicSessionForm';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { IconUtils } from '@utils/IconUtils';
import { UpdateSessionAction } from '@modules/calendar/components/UpdateSessionDataModal';
import { SessionRes } from '@services/model/session';

export default function ViewSessionDataModal(props: {
  action: UpdateSessionAction;
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean, needReload?: boolean) => void;
  onClose: (session: SessionRes | null) => void;
  onChange: (title: string) => void;
}) {
  const { t } = useTranslation();

  const [action, setAction] = useState(props.action);

  const [findById, { data: sessionData }] =
    SessionAPI.endpoints.findById.useLazyQuery({});

  useEffect(() => {
    refresh();
  }, [props.sessionData.sessionId]);

  const handleClick = (data: UpdateSessionAction) => {
    refresh();
    setAction(data);
  };

  const refresh = () => {
    if (props.sessionData.sessionId > 0) {
      findById(props.sessionData.sessionId);
    }
  };

  return (
    <>
      {sessionData && (
        <ResponsiveTabs
          onChange={(type) => handleClick(type as UpdateSessionAction)}
          items={[
            {
              key: UpdateSessionAction.METADATA,
              label: (
                <>
                  {IconUtils.view_info}
                  {t('session.update')}
                </>
              ),
              children: (
                <ViewSession
                  sessionData={sessionData}
                  selectedDate={''}
                  onClose={props.onClose}
                  warningResult={props.warningResult}
                  onDidMount={() => props.onChange(t('session.update'))}
                />
              ),
            },
            {
              key: UpdateSessionAction.STUDENT,
              label: (
                <>
                  {IconUtils.users}
                  {t('session.actions.manage_students')}
                </>
              ),
              children: (
                <ViewSessionStudent
                  sessionData={sessionData}
                  warningResult={props.warningResult}
                  onActive={() =>
                    props.onChange(t('session.actions.manage_students'))
                  }
                />
              ),
            },
            {
              key: UpdateSessionAction.LESSON,
              label: (
                <>
                  {IconUtils.lesson.assign}
                  {t('session.actions.share_lessons')}
                </>
              ),
              children: (
                <ViewSessionLesson
                  sessionData={sessionData}
                  warningResult={props.warningResult}
                  onActive={() =>
                    props.onChange(t('session.actions.share_lessons'))
                  }
                />
              ),
            },
            {
              key: UpdateSessionAction.SHARE,
              label: (
                <>
                  {IconUtils.lesson.publish}
                  {t('session.actions.share')}
                </>
              ),
              children: (
                <PublicSessionForm
                  readonly={true}
                  sessionData={sessionData}
                  warningResult={props.warningResult}
                  onActive={() => props.onChange(t('session.actions.share'))}
                />
              ),
            },
          ]}
          activeKey={action}
        />
      )}
    </>
  );
}
