import React from 'react';
import useLoadMyProfile from '@hooks/useLoadMyProfile';
import SessionExpiredModal from '@components/template/app-layout/utils/SessionExpiredModal';
import styled from 'styled-components';
import useMediaQuery from '@hooks/useMedia';
import { Layout } from 'antd';
import useLogin from '@hooks/useLogin';
import { useSelector } from '@app/redux/hook';

const ComposeLessonLayout = ({
  className,
  children,
}: {
  className: string;
  children: any;
}) => {
  const { isAuth } = useSelector((state) => state.profile);

  useLogin(false);
  useLoadMyProfile(true);

  const screen = useMediaQuery();

  return (
    <ComposeLayoutStyle hasSider className={`screen-${screen} ${className}`}>
      {children}

      {isAuth && <SessionExpiredModal />}
    </ComposeLayoutStyle>
  );
};

export default ComposeLessonLayout;

const ComposeLayoutStyle = styled(Layout)`
  font-size: 16px;
  line-height: 1.6;
  min-height: 100vh;
  overflow-y: auto;

  &.class-room-layout {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .session-expired-modal {
      display: none;
    }
  }

  * {
    box-sizing: border-box;
  }
`;
