import React, { useState } from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { Col, Row, Steps } from 'antd';
import PublicTeacherProfileForm from '../container/my-profile/PublicTeacherProfileForm';
import UserProfileReview from '../container/my-profile/UserProfileReviewForm';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
const { Step } = Steps;

enum UserPublicProfileStepEnum {
  INTRODUCTION = 2,
  REVIEW = 3,
}

const PublicProfileTeacherPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const [currentState, setCurrentState] = useState<UserPublicProfileStepEnum>(
    UserPublicProfileStepEnum.INTRODUCTION
  );

  const getStatusType = (status: UserPublicProfileStepEnum) => {
    return status === currentState
      ? 'process'
      : status < currentState
      ? 'finish'
      : 'wait';
  };

  const handleOnCancel = () => {
    console.log('do nothing....');
  };

  const viewPublishSite = (name: string) => {
    router.push(SiteMap.public.teacher_view_gen(name));
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('profile.public_profile')}</H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={20}>
          <Steps>
            <Step
              status={getStatusType(UserPublicProfileStepEnum.INTRODUCTION)}
              title={'Information'}
            />
            <Step
              status={getStatusType(UserPublicProfileStepEnum.REVIEW)}
              title={'Review'}
            />
          </Steps>

          <div style={{ marginTop: '3em' }}>
            {currentState === UserPublicProfileStepEnum.INTRODUCTION && (
              <PublicTeacherProfileForm
                onSuccess={() =>
                  setCurrentState(UserPublicProfileStepEnum.REVIEW)
                }
              />
            )}

            {currentState === UserPublicProfileStepEnum.REVIEW && (
              <UserProfileReview
                onCancel={handleOnCancel}
                onSuccess={viewPublishSite}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PublicProfileTeacherPage;
