import { useEffect, useRef, useState } from 'react';
import {
  ChatBoxMessage,
  UserPublicInfoProps,
} from '@components/chatbox/ChatBoxMessage';

import styled from 'styled-components';
import { notification } from 'antd';
import { ChatBoxCompose } from '@components/chatbox/ChatBoxCompose';
import { FireBaseService } from '@services/FireStoreService';
import { useSelector } from '@app/redux/hook';

interface MessageProps {
  uid?: string;
  createdDate?: string;
  userId: number;
  message: string;
}

const ChatBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .chat-box-messages {
    flex-grow: 1;
    overflow-y: auto !important;

    .chat-box-bottom {
      min-height: 15px;
      width: 100%;
    }
  }
`;

export const ChatBox = (props: {
  collection: string;
  channel: string;
  className?: string;
}) => {
  const { uuid } = useSelector((state) => state.profile);

  const messageService = useRef(
    new FireBaseService(uuid, props.collection, props.channel + 'messages')
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { userId, avatar, email, displayName, isReady } = useSelector(
    (state) => state.userInformation
  );

  const [firstTime, setFirstTime] = useState(true);

  const [messageArray, setMessageArray] = useState<MessageProps[]>([]);
  // const [newMessageArray, setNewMessageArray] = useState<MessageProps[]>([]);

  const [loginUsers, setLoginUsers] = useState<UserPublicInfoProps[]>([]);
  const [newLoginUsers, setNewLoginUsers] = useState<UserPublicInfoProps[]>([]);

  const userService = useRef(
    new FireBaseService(uuid, props.collection, props.channel + 'users')
  );

  useEffect(() => {
    if (messagesEndRef.current != null) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageArray]);

  const onMessagesChange = (data: any[]) => {
    setMessageArray((prev) => {
      return prev.concat(data);
    });
  };

  const onUserChange = (data: any[]) => {
    try {
      if (data && Array.isArray(data)) {
        setLoginUsers((prev) => {
          return [...prev, ...data];
        });

        if (!firstTime) {
          setNewLoginUsers(data);
        } else {
          setFirstTime(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    messageService.current.onChange(onMessagesChange);
    userService.current.onChange(onUserChange);
  }, [isReady]);

  useEffect(() => {
    if (isReady) {
      handleLogin();
    }
  }, [isReady]);

  useEffect(() => {
    window.onbeforeunload = () => {
      handleLogout();
    };
  }, []);

  useEffect(() => {
    if (newLoginUsers) {
      newLoginUsers.forEach((user) => {
        notification.info({
          message: 'Thông báo',
          description: user.live
            ? `${user.displayName} đã tham gia vào lớp học.`
            : `${user.displayName} đã rời khỏi lớp học.`,
          placement: 'bottomRight',
        });
      });
    }
  }, [newLoginUsers]);

  const handleLogin = () => {
    const data: UserPublicInfoProps = {
      userId: userId,
      displayName: displayName,
      email: email,
      avatar: avatar,
      live: true,
    };

    userService.current
      .create(data, userId)
      .then(() => {
        console.log(`add success`);
      })
      .catch((err: any) => {
        console.log(`have an err`, err);
      });
  };

  const handleLogout = () => {
    const data: UserPublicInfoProps = {
      userId: userId,
      displayName: displayName,
      email: email,
      avatar: avatar,
      live: false,
    };

    userService.current
      .create(data, userId)
      .then(() => {
        console.log(`add success`);
      })
      .catch((err: any) => {
        console.log(`have an err`, err);
      });
  };

  const handleOnSend = (message: string) => {
    const data: MessageProps = {
      userId: userId,
      message: message,
    };

    messageService.current
      .create(data)
      .then(() => {
        console.log(`add success`);
      })
      .catch((err: any) => {
        console.log(`have an err`, err);
      });
  };

  return (
    <ChatBoxStyle className={`chat-box-container ${props.className ?? ''}`}>
      <div className={'chat-box-messages'}>
        {messageArray.map((msg) => {
          const filterUser = loginUsers.filter((user) => {
            return user.userId === msg.userId;
          });

          const sender = filterUser.length > 0 ? filterUser[0] : null;

          return (
            <ChatBoxMessage
              key={msg.uid}
              myMessage={msg.userId === userId}
              message={msg.message}
              author={sender}
              createdDate={msg.createdDate}
            />
          );
        })}

        <div className={'chat-box-bottom'} ref={messagesEndRef} />
      </div>

      <ChatBoxCompose
        placeHolder={'Type your message....'}
        onSend={handleOnSend}
      />
    </ChatBoxStyle>
  );
};
