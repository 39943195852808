import React, { useState } from 'react';
import {
  ContactsOutlined,
  LogoutOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Button, Divider, Popover } from 'antd';

import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';

import { UserAvatar } from '@components/avatar/UserAvatar';
import Authorization from '@utils/authorization';
import {
  CurrentUserDisplay,
  CurrentUserRolesDisplay,
} from '@components/avatar/UserDisplayName';
import styled from 'styled-components';
import { H4 } from '@components/typography';
import NavLink from '@components/button/NavLink';
import { NavigationMenuEnum } from '../navigation/Navigation';
import { useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';
import { CurrentRoleDisplay } from '@components/role/RoleDisplay';
import { IconUtils } from '@utils/IconUtils';

const HeaderAccountMenu = () => {
  const { t } = useTranslation();
  const { menu } = useSelector((state) => state.appState);
  const { avatar } = useSelector((state) => state.userInformation);

  const [showPermission, setShowPermission] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const togglePermission = () => {
    setShowPermission((prevState) => !prevState);
  };

  const title = (
    <GroupHeaderStyle className={'user-header-info'} onClick={togglePermission}>
      <div className={'user-role-display'}>
        <CurrentRoleDisplay />
      </div>

      <H4>
        <CurrentUserDisplay />
      </H4>

      <div style={{ marginTop: '1em', marginBottom: '1em' }}>
        <UserAvatar src={avatar} size={180} />
      </div>

      {showPermission && <CurrentUserRolesDisplay />}
    </GroupHeaderStyle>
  );

  const content = (
    <>
      <GroupContentStyle
        className={'user-group-info'}
        onClick={() => setOpenMenu(false)}
      >
        <NavLink href={SiteMap.private.my_account.index} key={'my-account'}>
          <UserOutlined /> {t('menu.my_account')}
        </NavLink>

        <Authorization
          type={'ifAnyGranted'}
          roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
        >
          <NavLink
            href={SiteMap.private.my_account.public_profile}
            key={'public_profile'}
          >
            <SolutionOutlined /> {t('profile.public_profile')}
          </NavLink>
        </Authorization>

        <NavLink href={SiteMap.lti.oauth_app} key={'oauth_app'}>
          <ContactsOutlined /> {t('profile.actions.oauth_app')}
        </NavLink>

        <Authorization type={'ifAnyGranted'} roles={[RoleEnum.STUDENT]}>
          <NavLink
            href={SiteMap.private.my_account.certificate}
            key={'certificate'}
          >
            {IconUtils.menu.certificate} Certificate
          </NavLink>
        </Authorization>

        <Divider />
      </GroupContentStyle>

      <NavLink href={SiteMap.auth.logout} key={'logout'}>
        <Button shape="round" danger type={'primary'} size={'large'} block>
          <LogoutOutlined /> {t('menu.logout')}
        </Button>
      </NavLink>
    </>
  );

  return (
    <Popover
      placement="bottomRight"
      title={title}
      content={content}
      trigger={['click']}
      overlayClassName={'user-info-popup'}
      open={openMenu}
      onOpenChange={(open) => setOpenMenu(open)}
    >
      <Button
        type={
          openMenu || menu.includes(NavigationMenuEnum.MY_ACCOUNT)
            ? 'primary'
            : 'default'
        }
        shape="circle"
        size={'large'}
        style={{
          padding: 0,
          overflow: 'hidden',
          width: 40,
          maxWidth: 40,
        }}
        onClick={() => setOpenMenu(true)}
        icon={<UserAvatar src={avatar} size={40} />}
      />
    </Popover>
  );
};

export default HeaderAccountMenu;

export const GroupHeaderStyle = styled.div`
  h3 {
    margin-bottom: 0;
  }
`;

export const GroupContentStyle = styled.div`
  font-size: 16px;

  .ant-divider.ant-divider-horizontal {
    margin: 4px auto;
  }

  a {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-weight: 600;

    &.active {
      background: ${(props) => props.theme.menu.active.background};
      color: ${(props) => props.theme.menu.active.text};
    }

    .anticon {
      margin-right: 12px;
    }

    &:hover,
    &:focus {
      background: ${(props) => props.theme.menu.background};
      color: ${(props) => props.theme.menu.text};
    }
  }
`;
