export enum CompGroupEnum {
  source = 'source',
  target = 'target',
}

export enum CompActionEnum {
  default = '',
  video_play = 'video_playing',
  video_pause = 'video_pause',
  video_resume = 'video_resume',
  video_jump_to = 'video_jump_to',
}

export type TriggerCompEventType = (
  id: string,
  event: CompActionEnum,
  params: any
) => void;
