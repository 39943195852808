import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { MatchingGameCompProps } from '@cms/comps/game/matching/FlashCardMatchingComp';

export const MatchingGameExampleData: MatchingGameCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.GAME_MATCHING,

  configuration: {
    sourceItems: [
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana Thumbnail',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    columns: 2,
    rows: 2,
  },
};
