import React, { useState } from 'react';
import ResourceStandardForm from '@modules/product/components/resource/metadata/ResourceStandardForm';
import ResourceMetadataForm from '@modules/product/components/resource/metadata/ResourceMetadataForm';
import styled from 'styled-components';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { ResourceRes } from '@modules/product/services/resource_model';

export enum ResourceMetadataEditableEnum {
  metadata = 'metadata',
  standards = 'standards',
}

export const ResourceInfoEditable = (props: {
  resourceId: number;
  onSuccess: (data: ResourceRes) => void;
  onCancel: () => void;
}) => {
  return (
    <ResourceMetadataEditableStyle className={'resource-metadata-editable'}>
      <ResourceMetadataForm
        resourceId={props.resourceId}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
      />
    </ResourceMetadataEditableStyle>
  );
};

const ResourceMetadataEditable = (props: {
  resourceId: number;
  lessonId?: number;

  onSuccess: (data: ResourceRes) => void;
  onCancel: () => void;

  activeTab?: ResourceMetadataEditableEnum;
  onChange?: (tab: ResourceMetadataEditableEnum) => void;
}) => {
  const { t } = useTranslation();

  const [defaultTab, setDefaultTab] = useState(
    props.activeTab ? props.activeTab : ResourceMetadataEditableEnum.metadata
  );

  const handleOnChange = (tab: ResourceMetadataEditableEnum) => {
    setDefaultTab(tab);
    if (props.onChange) {
      props.onChange(tab);
    }
  };

  return (
    <ResourceMetadataEditableStyle className={'resource-metadata-editable'}>
      <ResponsiveTabs
        items={[
          {
            key: ResourceMetadataEditableEnum.metadata,
            label: (
              <>
                {IconUtils.actions.view_info}
                {t('resource.actions.update_question_metadata')}
              </>
            ),
            children: (
              <ResourceMetadataForm
                lessonId={props.lessonId}
                resourceId={props.resourceId}
                onSuccess={props.onSuccess}
                onCancel={props.onCancel}
              />
            ),
          },
          {
            key: ResourceMetadataEditableEnum.standards,
            label: (
              <>
                {IconUtils.lesson.standards}
                {t('resource.actions.update_standards')}
              </>
            ),
            children: (
              <ResourceStandardForm
                resourceId={props.resourceId}
                onCancel={props.onCancel}
              />
            ),
          },
        ]}
        activeKey={defaultTab}
        onChange={(tab) => handleOnChange(tab as ResourceMetadataEditableEnum)}
      />
    </ResourceMetadataEditableStyle>
  );
};

export default ResourceMetadataEditable;

const ResourceMetadataEditableStyle = styled.div`
  min-width: 600px;
  max-width: calc(80vw);

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;
