import React, { useState } from 'react';
import {
  CompAnswerProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { ImageContentProps, TextContentProps } from '@cms/content/ContentType';
import styled from 'styled-components';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';

import { AudioRecordingCompEditable } from './AudioRecordingCompEditable';
import AudioRecordingGroupItems from './AudioRecordingGroupsItem';
import { AudioRecordingCompContextProvider } from './AudioRecordingCompContext';

export interface AudioOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps;
}

export interface AudioRecordingProps extends CompProps {
  type: CompTypeEnum.AUDIO_RECORDING;
  configuration: {
    sourceItems: AudioOptionProps[];
  };
}

export interface AudioRecordingAnswerProps extends CompAnswerProps {
  answer: string[];
}

const AudioRecordingComp = (props: {
  disabled: boolean;
  item: AudioRecordingProps;
  answer: AudioRecordingAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [showComponent, setShowComponent] = useState(true);

  return (
    <AudioRecordingStyle
      className={`audio-recording-comp ${props.item.setting?.className}`}
    >
      <AudioRecordingCompContextProvider
        disabled={props.disabled}
        item={props.item}
        answer={props.answer}
        feedback={props.feedback}
        onChange={props.onChange}
      >
        {showComponent && (
          <>
            <AudioRecordingGroupItems />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </>
        )}

        <ComposeModeContent>
          {!showComponent && (
            <AudioRecordingCompEditable
              item={props.item}
              onClose={() => setShowComponent(true)}
            />
          )}
        </ComposeModeContent>

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </AudioRecordingCompContextProvider>
    </AudioRecordingStyle>
  );
};

export default AudioRecordingComp;

const AudioRecordingStyle = styled.div`
  position: relative;

  .audio-recording-inline-group-item {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
`;
