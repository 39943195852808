import React from 'react';

import { CompScoringProps } from '@cms/ComponentInteface';

import { InputNumber, Switch } from 'antd';
import {
  regenerationScoring,
  updateScoring,
} from '@app/redux/slices/composeResource';
import { TableDefault } from '@components/table/TablePagination';
import type { ColumnsType } from 'antd/lib/table';
import { H3 } from '@components/typography';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { Tooltip } from '@components/tooltip/Tooltip';
import { useDispatch, useSelector } from '@app/redux/hook';

const ResourceScoringEditable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { scoring } = useSelector((state) => state.composeResource);

  const handleOnManualScoreChange = (
    checked: boolean,
    item: CompScoringProps
  ) => {
    dispatch(updateScoring({ ...item, manualScore: checked }));
  };

  const handleOnScoreChange = (
    maxScore: number | null,
    item: CompScoringProps
  ) => {
    if (maxScore == null) {
      maxScore = 1;
    }
    dispatch(updateScoring({ ...item, maxScore: maxScore }));
  };

  const columns: ColumnsType<CompScoringProps> = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 400,
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      width: 180,
    },
    {
      title: (
        <>
          {t('component.label.autoscore')}
          <Hint
            icon={<InfoCircleOutlined />}
            header={<h3>{t('component.label.autoscore')}</h3>}
            content={
              <>
                <p>{t('resource.validation.autoscore')}</p>
                <p>{t('resource.validation.autoscore_2')}</p>
                <p>{t('resource.validation.autoscore_3')}</p>
              </>
            }
          />
        </>
      ),
      dataIndex: 'manualScore',
      key: 'manualScore',
      align: 'center',
      render: (value, record) => {
        return (
          <Switch
            checked={!value}
            disabled={ResourceUtils.onlySupportManualScore(record.type)}
            onChange={(val) => handleOnManualScoreChange(!val, record)}
          />
        );
      },
    },
    {
      title: (
        <>
          {t('component.label.max_score')}
          <Hint
            icon={<InfoCircleOutlined />}
            header={<h3>{t('component.label.max_score')}</h3>}
            content={
              <>
                <p>{t('resource.validation.score')}</p>
                <p>{t('resource.validation.score_2')}</p>
                <p>{t('resource.validation.score_3')}</p>
              </>
            }
          />
        </>
      ),
      dataIndex: 'maxScore',
      key: 'maxScore',
      align: 'center',
      render: (value, record) => {
        return (
          <InputNumber
            min={1}
            max={100}
            value={value}
            onChange={(val) => handleOnScoreChange(val, record)}
          />
        );
      },
    },
  ];

  const getRowKey = (record: CompScoringProps) => {
    return record.id;
  };

  const onRegenerationScoring = () => {
    dispatch(regenerationScoring());
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H3>
          {t('component.label.scoring')}

          <Tooltip
            placement="top"
            title={'Click to reset auto-score and max-point to default.'}
          >
            <Button
              type={'default'}
              size={'small'}
              shape={'circle'}
              onClick={onRegenerationScoring}
            >
              <SyncOutlined />
            </Button>
          </Tooltip>
        </H3>
      </HeaderGroup>

      <TableDefault
        rowKey={getRowKey}
        columns={columns}
        dataSource={scoring}
        pagination={false}
      />
    </>
  );
};

export default ResourceScoringEditable;
