import React from 'react';
import { FeatureGroup } from './FeatureGroup';
import Paragraph from 'antd/lib/typography/Paragraph';

export const OrganizationFeature = () => {
  return (
    <div className={'feature-group'}>
      <Paragraph>
        Bạn là một tổ chức mới thành lập và bạn không có quá nhiều chi phí để
        xây dựng một hệ thống cho riêng mình?
      </Paragraph>

      <Paragraph>
        Bạn có 1 danh sách đồng nghiệp cùng hoạt động trên gstudy và muốn cùng
        hợp tác để phát triển.
      </Paragraph>

      <Paragraph>Gstudy sẽ là lựa chọn tối ưu dành cho bạn.</Paragraph>

      <FeatureGroup header={'Chức năng Tổ chức'}>
        <ol>
          <li>
            Với chi phí ít ỏi hàng tháng, bạn sẽ có 1 hệ thống tương tự gstudy
            để phục vụ cho mục đích dạy và học.
          </li>

          <li>
            Gstudy cho phép bạn tạo một tổ chức trên hệ thống chúng tôi. Tuy
            nhiên hệ thống của bạn là một hệ thống độc lập với giao diện riêng,
            thông tin người dùng riêng, danh sách tài liệu riêng, domain riêng
            và mọi thứ riêng biệt. Chỉ khác biệt, mọi thông tin của hệ thống đều
            được lưu trữ trên gstudy để tiết kiệm chi phí.
          </li>

          <li>
            Bạn cũng có thể xuất bản các tài liệu và mở các khóa học trên hệ
            thống của mình. Đồng thời, những thông tin này sẽ xuất hiện trên
            Gstudy. Điều này giúp cho các thông tin này có thể tiếp cận nhiều
            giáo viên và nhiều học sinh hơn.
          </li>

          <li>
            Nếu một ngày nào đó, tổ chức của bạn đã phát triển đủ mạnh, lúc này,
            Gstudy sẵn sàng hỗ trợ giúp bạn xây dựng 1 hệ thống riêng độc lập
            với gstudy.
          </li>
        </ol>
      </FeatureGroup>

      <FeatureGroup header={'Chức năng Tạo Giao diện'}>
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>

      <FeatureGroup header={'Chức năng Tạo Giáo viên'}>
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>

      <FeatureGroup header={'Chức năng quản lý quy trình Soạn bài và xuất bản'}>
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>

      <FeatureGroup
        header={'Các chức năng khác tương tự student và teacher của gstudy.'}
      >
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>

      <FeatureGroup header={'Chức năng thanh toán'}>
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>

      <FeatureGroup header={'Chức năng Đặt quảng cáo'}>
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>

      <FeatureGroup header={'Chức năng thiết lập domain riêng'}>
        <ol>
          <li>Chức năng đang phát triển</li>
        </ol>
      </FeatureGroup>
    </div>
  );
};
