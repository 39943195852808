import styled from 'styled-components';

export const LessonInfoReviewStyle = styled.div`
  &.selected {
    font-weight: bold;
  }

  &.highlight-lesson {
    background: ${(props) => props.theme.app.primary_bgr};
  }
`;
