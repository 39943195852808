import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  CreateCollectionReq,
  LessonRes,
  UpdateCollectionReq,
} from '@modules/product/services/lesson_model';

export const LessonCollectionAPI = createApi({
  reducerPath: 'LessonCollectionAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createLesson: builder.mutation<LessonRes, CreateCollectionReq>({
      query: (body) => ({
        url: '/lessons/collections',
        method: 'POST',
        body,
      }),
    }),

    updateLesson: builder.mutation<LessonRes, UpdateCollectionReq>({
      query: (body) => ({
        url: `/lessons/collections/${body.lessonId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
