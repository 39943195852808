import styled from 'styled-components';
import { AssetsRes } from '@modules/product/services/assets_model';
import React from 'react';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import {
  MultipleChoiceItemProps,
  MultipleChoiceSettingProps,
} from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';

const MultipleChoiceOption = (props: {
  option: MultipleChoiceItemProps;
  answer: string[];
  setting: MultipleChoiceSettingProps;
  onClick: (label: string) => void;
  handleOnContentChange: (data: AssetsRes) => void;
}) => {
  const getClass = () => {
    const className: string[] = ['mc-item'];
    if (props.answer && props.answer.includes(props.option.label)) {
      className.push('active');
    }

    if (!props.setting.showOption) {
      className.push('single-option');
    }

    return className.join(' ');
  };
  return (
    <MCOptionStyle className={getClass()}>
      <CompFeedbackResultIcon answer={props.option.label} />

      {props.setting.showOption ? (
        <>
          <span
            className={props.setting.multiple ? 'mc-checkbox' : 'mc-radio'}
            onClick={() => props.onClick(props.option.label)}
          />

          {props.setting && props.setting.showLabel && (
            <span className={'mc-label'}>{props.option.label}.&nbsp;</span>
          )}

          <div className={'mc-content single-option'}>
            <ContentViewerEditable
              contentData={props.option.content}
              onChange={props.handleOnContentChange}
            />
          </div>
        </>
      ) : (
        <div
          className={'mc-content hide-option'}
          onClick={() => props.onClick(props.option.label)}
        >
          <ContentViewerEditable
            contentData={props.option.content}
            onChange={props.handleOnContentChange}
          />
        </div>
      )}
    </MCOptionStyle>
  );
};

export default MultipleChoiceOption;

const MCOptionStyle = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;

  .feedback-icon {
    transform: translate(-30px, 0px);
    position: absolute;
  }

  &:not(:first-child) {
    margin-top: 0.125em;
  }

  .mc-content {
    flex-grow: 1;
  }

  .mc-label {
    min-width: 1.5em;
  }

  &.single-option {
    .mc-content {
      border: 1pt solid #ccc;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      background: #fff;
      padding: 0.25em 0.5em;
      cursor: pointer;
    }

    &.active .mc-content {
      border: 1pt solid ${(props) => props.theme.component.primary};

      .show-correct-answer & {
        border: 1pt solid ${(props) => props.theme.component.correct_answer};
      }
    }
  }

  &.active {
    color: ${(props) => props.theme.component.primary};

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
    }

    .mc-radio {
      border-color: ${(props) => props.theme.component.primary};

      .show-correct-answer & {
        border-color: ${(props) => props.theme.component.correct_answer};
      }

      &:before {
        background: ${(props) => props.theme.component.primary};

        .show-correct-answer & {
          background: ${(props) => props.theme.component.correct_answer};
        }
      }
    }

    .mc-checkbox {
      border-color: ${(props) => props.theme.component.primary};

      .show-correct-answer & {
        border-color: ${(props) => props.theme.component.correct_answer};
      }

      &:before {
        content: '✔';
        color: ${(props) => props.theme.component.primary};

        .show-correct-answer & {
          color: ${(props) => props.theme.component.correct_answer};
        }
      }
    }
  }

  .mc-radio {
    display: inline-flex;
    width: 1em;
    min-width: 1em;
    height: 1em;
    min-height: 1em;
    border: 1.5pt solid #ccc;
    margin-right: 0.5em;
    border-radius: 100%;
    position: relative;
    cursor: pointer;
    transform: translate(0%, 20%);

    &:before {
      content: '';
      width: 0.5em;
      height: 0.5em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ccc;
      border-radius: 100%;
    }
  }

  .mc-checkbox {
    cursor: pointer;
    display: inline-flex;
    width: 1em;
    min-width: 1em;
    height: 1em;
    border: 1.5pt solid #ccc;
    margin-right: 0.5em;
    position: relative;
    transform: translate(0%, 20%);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
