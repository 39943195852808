export interface Common {
  success: boolean;
  message?: string;
  errorMessage?: string;
}

export enum EntityStatusEnum {
  PENDING = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  REMOVED = 3,
  PUBLISHED = 4,
  COMPLETED = 5,
  EMPTY = -1,
}

export enum RequestStatusEnum {
  UNREAD = 0,
  READ = 1,
  REPLY = 0,
  REMOVED = 3,
}

export enum SchoolStatusEnum {
  ACTIVE = 0,
  INACTIVE = 1,
  PENDING = 0,
  PUBLISHED = 3,
  REJECT = 4,
}

export enum ViewMode {
  'view' = 'view',
  'edit' = 'edit',
}

export const getEntityStatusClass = (status: EntityStatusEnum) => {
  switch (status) {
    case EntityStatusEnum.PUBLISHED:
      return 'published';

    case EntityStatusEnum.COMPLETED:
      return 'completed';

    case EntityStatusEnum.ACTIVE:
      return 'activated';

    case EntityStatusEnum.INACTIVE:
      return 'inactivated';

    case EntityStatusEnum.REMOVED:
      return 'removed';

    case EntityStatusEnum.PENDING:
      return 'pending';

    default:
      return '';
  }
};
