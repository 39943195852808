import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { InputGroup } from '@components/input/InputGroup';
import { Select } from 'antd';
import { FibNumberSettingProps } from '@cms/comps/math/fib/FibNumberComp';
import Input from '@components/input/input';
import { useTranslation } from 'react-i18next';

export const FibNumberSetting = (props: {
  setting: FibNumberSettingProps;
  onSettingChange: (newSetting: FibNumberSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.number.number_type')}>
          <Select
            size={'large'}
            placeholder={t('component.number.number_type')}
            onChange={(value) => formik.setFieldValue('numberType', value)}
            value={formik.values.numberType}
          >
            <Select.Option key={'integer'} value={'integer'}>
              {t('component.number.integer')}
            </Select.Option>

            <Select.Option key={'decimal'} value={'decimal'}>
              {t('component.number.decimal')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Input
          name="digit"
          type="text"
          label={t('component.number.digit')}
          placeholder={t('component.number.digit')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.digit}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
