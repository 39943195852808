import React from 'react';

import { ReviewExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ReviewExerciseButton';
import { SaveExerciseButton } from '@cms/lesson-template/components/buttons/exercise/SaveExerciseButton';
import { SubmitExerciseBtn } from './SubmitExerciseBtn';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';

export const DoAssignmentGroupBtn = () => {
  const { lesson } = useViewLessonContext();

  return (
    <>
      {lesson.validation && lesson.validation.interact && (
        <>
          <ReviewExerciseButton />
          <SaveExerciseButton />
          <SubmitExerciseBtn />
        </>
      )}
    </>
  );
};
