import React from 'react';
import { Col, Row } from 'antd';
import LessonTree from '@modules/product/components/toc/LessonTree';
import ComposeToc from '@modules/product/components/toc/ComposeToc';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { H1, H3 } from '@components/typography';
import {
  TableOfContentAction,
  useComposeTocContext,
} from '@modules/product/components/toc/ComposeTocProvider';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import LessonEditForm from '../container/LessonEditForm';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { LessonStandardComp } from '@modules/product/components/lesson/lesson-data/modal/LessonStandardComp';
import Card, { CardClassEnum } from '@components/card';
import NavLink from '@components/button/NavLink';
import DragNDropContext from '@components/react-dnd-bt/DragNDropContext';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';

export default function TableOfContentPage() {
  const { t } = useTranslation();

  const { action, productId, product, selectedLessonIds, dispatchAction } =
    useComposeTocContext();

  const addLessonToToc = (lesson: LessonRes, productToc: ProductTocRes) => {
    dispatchAction(TableOfContentAction.add_lesson_to_unit, {
      lessonId: lesson.lessonId,
      productTocId: productToc.productTocId,
    });
  };

  const onCreateLessonSuccess = (isAdded: boolean, data: LessonRes) => {
    if (isAdded) {
      addLessonToToc(data, action.params as ProductTocRes);
    }
  };

  const handleOnCancel = () => {
    dispatchAction(TableOfContentAction.default, null);
  };

  return (
    <>
      {product && (
        <NavLink href={SiteMap.content.product.review_gen(product.productId)}>
          <HeaderGroup className="header-group" style={{ marginBottom: '3em' }}>
            <H1>
              {IconUtils.table_of_content}
              &nbsp;{t('table_of_content.header')}
              <span className={'default-text'}>
                &nbsp;-&nbsp;{product.name}
              </span>
            </H1>
          </HeaderGroup>
        </NavLink>
      )}

      <DragNDropContext id={'table-of-content'}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <ComposeToc />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div style={{ position: 'sticky', top: 10, left: 0, right: 0 }}>
              <H3>{t('table_of_content.lesson_bank')}</H3>

              <Card className={CardClassEnum.rectangle_box}>
                <LessonTree
                  selectedProductId={productId}
                  selectedLessonIds={selectedLessonIds}
                  lessonIds={selectedLessonIds}
                />
              </Card>
            </div>
          </Col>
        </Row>
      </DragNDropContext>

      {action.action === TableOfContentAction.insert_lesson_bellow && (
        <CustomModal
          header={t('lesson.actions.create')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <LessonEditForm
              type={LessonTypeEnum.lesson}
              successHandle={onCreateLessonSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === TableOfContentAction.view_lesson && (
        <CustomModal
          header={t('lesson.actions.review')}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={action.params} />}
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === TableOfContentAction.view_lesson_standard && (
        <CustomModal
          header={t('lesson.actions.view_standards')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <LessonStandardComp
              lessonId={action.params}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
