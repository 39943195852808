import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';

import FullCalendar from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { GCalendarContext } from '@modules/calendar/context/CalendarContext';
import styled from 'styled-components';
import { useLanguage } from '@hooks/useLanguage';
// import { DatesSetArg } from '@fullcalendar/common';

const CalendarThumbnails = (props: any) => {
  const language = useLanguage();

  const calendarRef = useRef(null);

  const { defaultDate, events, setDefaultDate } = useContext(GCalendarContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (calendarRef.current) {
      // @ts-ignore
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(defaultDate);
      setReady(true);
    }
  }, [defaultDate]);

  const handleDateClick = useCallback(
    (arg: DateClickArg) => {
      if (ready) {
        setDefaultDate(arg.dateStr);
      }
    },
    [ready, defaultDate]
  );

  // const handleEventChange = useCallback(
  //   (event: DatesSetArg) => {
  //     if (ready) {
  //       setDefaultDate(event.startStr);
  //     }
  //   },
  //   [ready, defaultDate]
  // );

  const renderEventContent = useCallback(() => {
    return <CalendarEventStyle />;
  }, []);

  return (
    <CalendarThumbnailsStyle {...props}>
      <FullCalendar
        ref={calendarRef}
        initialDate={defaultDate}
        // datesSet={handleEventChange}
        headerToolbar={{
          start: 'title',
          center: '',
          end: 'prev,next',
        }}
        allDaySlot={false}
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
        editable={false}
        selectable={false}
        dateClick={handleDateClick}
        initialView={'dayGridMonth'}
        events={events}
        timeZone={'UTC'}
        height={'auto'}
        expandRows={true}
        eventContent={renderEventContent}
        locale={language}
        visibleRange={(currentDate) => {
          // Generate a new date for manipulating in the next step
          const startDate = new Date(currentDate.valueOf());
          const endDate = new Date(currentDate.valueOf());

          // Adjust the start & end dates, respectively
          startDate.setDate(startDate.getDate() - 1); // One day in the past
          endDate.setDate(endDate.getDate() + 2); // Two days into the future

          return { start: startDate, end: endDate };
        }}
      />
    </CalendarThumbnailsStyle>
  );
};

export default CalendarThumbnails;

const CalendarEventStyle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${(props) => props.theme.event.primary};
`;

const CalendarThumbnailsStyle = styled.div`
  .fc-scrollgrid-section > td {
    border-width: 0px !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: 0px solid #f0f2f5 !important;
  }

  .fc-scrollgrid-section-header th[role='presentation'] {
    border-right: 0px solid #f0f2f5 !important;
  }

  .fc-theme-standard td.fc-daygrid-day,
  .fc-theme-standard th.fc-col-header-cell {
    border-color: #f0f2f5;

    .ant-popover & {
      border-color: ${(props) => props.theme.color.white};
    }
  }

  .fc-theme-standard td.fc-daygrid-day,
  .fc-theme-standard th.fc-col-header-cell {
    border-width: 3px;
  }

  .fc-col-header-cell-cushion {
    font-weight: bold;
    color: ${(props) => props.theme.event.primary};
    text-transform: uppercase;
    font-size: 0.85em;
  }

  .fc-header-toolbar.fc-toolbar {
    margin-bottom: 12px;

    .fc-toolbar-title {
      color: ${(props) => props.theme.event.primary};
      font-size: 20px;
      margin-bottom: 0px;
    }

    .fc-button-group {
      .fc-button {
        border: none;
        padding: 2px;
        background-color: transparent !important;
        color: ${(props) => props.theme.event.primary} !important;

        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }

  .fc-col-header {
    color: ${(props) => props.theme.event.primary};
  }

  .fc-view-harness.fc-view-harness-active {
    background-color: rgba(215, 215, 215, 0.3);

    .fc-day-today {
      background-color: ${(props) => props.theme.event.primary_bgr};
      color: ${(props) => props.theme.event.primary};
    }
  }

  .fc-col-header-cell {
    font-weight: normal;
    font-size: 14px;
  }

  .fc-daygrid-day.fc-day-past {
    .ant-popover & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .fc-daygrid-day.fc-day-future {
    background-color: ${(props) => props.theme.color.white};

    .ant-popover & {
      background-color: rgba(215, 215, 215, 0.3);
    }
  }

  .fc-daygrid-day-frame {
    width: 100%;
    min-width: 38px;
    height: 38px;
  }

  .fc-daygrid-day-top {
    font-size: 12px !important;
  }

  .fc-daygrid-day-events {
    position: absolute !important;
    min-height: auto !important;

    bottom: 3px;
    left: 0px;
    right: 0px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px !important;

    .fc-daygrid-event-harness {
      display: none !important;

      &:first-child {
        display: block !important;
      }
    }
  }
`;
