import React, { createContext, useEffect, useRef, useState } from 'react';

import { createRoot } from 'react-dom/client';
const TemplateFileForTesting = () => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [contentValue] = useState(
    `This is <span class="comp" name="firstName" type="input" ></span> 
                and <span class="comp" name="birthday" type="date-picker" ></span> 
                and <span class="comp" name="sex" type="select" ></span>`
  );

  const handleOnChange = (name: string, value: string) => {
    setData((prevState) => {
      const newData: Record<string, any> = {};
      newData[name] = value;
      console.log('handleOnChange', { ...prevState, ...newData });

      return { ...prevState, ...newData };
    });
  };

  const [data, setData] = useState<Record<string, any>>({
    firstName: 'Khanh Tran',
    birthday: '02/09/1990',
  });

  useEffect(() => {
    if (contentRef.current) {
      const components = contentRef.current.getElementsByClassName('comp');

      // for each tag here and try to convert them to another react component
      Array.from(components).forEach(function (element) {
        const type = element.getAttribute('type');
        const name = element.getAttribute('name') + '';

        if (type === 'date-picker') {
          createRoot(element).render(
            <DatePickerComp
              name={name}
              value={data[name]}
              onChange={handleOnChange}
            />
          );
        } else if (type === 'input') {
          createRoot(element).render(
            <InputComp
              name={name}
              value={data[name]}
              onChange={handleOnChange}
            />
          );
        } else if (type === 'select') {
          createRoot(element).render(
            <SelectComp
              name={name}
              value={data[name]}
              onChange={handleOnChange}
            />
          );
        }
      });
    }
  }, [contentRef.current]);

  return (
    <HtmlContext.Provider value={{ data }}>
      <div style={{ padding: 30 }}>
        <div
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: contentValue }}
        />
        <div>{contentValue}</div>
      </div>
    </HtmlContext.Provider>
  );
};

const HtmlContext = createContext({
  data: {} as Record<string, any>,
});

export default TemplateFileForTesting;

const DatePickerComp = (props: {
  name: string;
  value: string;
  onChange: (name: string, value: any) => void;
}) => {
  return (
    <input
      onChange={(e) => props.onChange(props.name, e.target.value)}
      style={{ maxWidth: 100 }}
    />
  );
};

const SelectComp = (props: {
  name: string;
  value: string;
  onChange: (name: string, value: any) => void;
}) => {
  return (
    <input
      onChange={(e) => props.onChange(props.name, e.target.value)}
      style={{ maxWidth: 100 }}
    />
  );
};

const InputComp = (props: {
  name: string;
  value: string;
  onChange: (name: string, value: any) => void;
}) => {
  const [count, setCount] = useState(0);

  const increase = () => {
    setCount((prev) => prev + 1);
  };

  return (
    <>
      <input
        onChange={(e) => props.onChange(props.name, e.target.value)}
        style={{ maxWidth: 100 }}
      />
      <h4>count {count}</h4>

      <button onClick={increase}>increase</button>
    </>
  );
};
