import { useTranslation } from 'react-i18next';
import { useTheme } from '@app/styled/StyledProvider';
import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import {
  ComponentScoreIcon,
  getQuestionScoreType,
} from '@cms/icons/ComponentScoreIcon';
import { Space, Tooltip } from 'antd';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import {
  CheckCircleOutlined,
  DoubleRightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import Button from '@components/button';
import styled from 'styled-components';
import React from 'react';
import {
  ExerciseResponseRes,
  ExerciseResponseStatusEnum,
} from '@modules/assignments/service/exercise_model';

export const QuestionStatusItem = (props: { item: ExerciseResponseRes }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { updateNavigation } = useLessonNavigationContext();
  const { dispatchExerciseAction } = useViewLessonContext();

  const goToQuestion = () => {
    updateNavigation(LessonNavigationAction.GO_TO_QUESTION, {
      sectionId: props.item.sectionId ?? -1,
      resourceId: props.item.resourceId,
      type: LessonNavigationType.refresh,
    });

    dispatchExerciseAction(
      ExerciseActionEnum.go_to_question,
      props.item.resourceId
    );
  };

  const getLessonStatusClass = (): string => {
    let className = ' lesson-status-item ';

    if (props.item.status === ExerciseResponseStatusEnum.NEED_GRADING) {
      className += ' need-grading ';
    } else if (props.item.status === ExerciseResponseStatusEnum.COMPLETED) {
      const scoreClassName = getQuestionScoreType(
        props.item.skip != null && props.item.skip,
        props.item.score,
        props.item.maxScore
      );
      className += scoreClassName;
    } else if (props.item.status === ExerciseResponseStatusEnum.IN_PROGRESS) {
      className += props.item.skip ? ' skip-answer ' : ' answered ';
    } else if (props.item.status === ExerciseResponseStatusEnum.NOT_START) {
      className += ' not-start ';
    }

    return className;
  };

  return (
    <LessonStatusItemStyle className={getLessonStatusClass()}>
      <Space align={'center'}>
        <span className={'lesson-question-number'}>
          {t('exercise.button.question_n', { n: props.item.displayOrder })}
        </span>

        <span className={'lesson-status-icon'}>
          <SwitchCondition
            conditions={[
              {
                condition:
                  props.item.status === ExerciseResponseStatusEnum.NEED_GRADING,
                children: (
                  <ComponentScoreIcon
                    isSkip={props.item.skip != null && props.item.skip}
                    score={props.item.score}
                    maxScore={props.item.maxScore}
                  />
                ),
              },
              {
                condition:
                  props.item.status === ExerciseResponseStatusEnum.COMPLETED,
                children: (
                  <ComponentScoreIcon
                    isSkip={props.item.skip != null && props.item.skip}
                    score={props.item.score}
                    maxScore={props.item.maxScore}
                  />
                ),
              },
              {
                condition:
                  props.item.status === ExerciseResponseStatusEnum.IN_PROGRESS,
                children: (
                  <>
                    {props.item.skip ? (
                      <QuestionCircleOutlined color={theme.component.skip} />
                    ) : (
                      <CheckCircleOutlined color={theme.component.correct} />
                    )}
                  </>
                ),
              },
              {
                condition:
                  props.item.status === ExerciseResponseStatusEnum.NOT_START,
                children: <QuestionCircleOutlined color={'grey'} />,
              },
            ]}
          />
        </span>
      </Space>

      <div
        className={`view-question-action resource-${props.item.resourceId} section-${props.item.sectionId}`}
      >
        <Tooltip title={t('exercise.actions.go_to_question')}>
          {props.item.status === ExerciseResponseStatusEnum.IN_PROGRESS && (
            <Button
              block
              shape={'round'}
              type={props.item.skip ? 'default' : 'primary'}
              onClick={goToQuestion}
            >
              {t('label.answered')} <DoubleRightOutlined />
            </Button>
          )}

          {props.item.status === ExerciseResponseStatusEnum.NOT_START && (
            <Button
              block
              shape={'round'}
              type={'default'}
              onClick={goToQuestion}
            >
              {t('label.unanswered')} <DoubleRightOutlined />
            </Button>
          )}

          {props.item.status === ExerciseResponseStatusEnum.NEED_GRADING && (
            <Button
              block
              shape={'round'}
              type={'primary'}
              onClick={goToQuestion}
            >
              {t('exercise.actions.grade_question')} <DoubleRightOutlined />
            </Button>
          )}

          {props.item.status !== ExerciseResponseStatusEnum.NOT_START &&
            props.item.status !== ExerciseResponseStatusEnum.IN_PROGRESS &&
            props.item.status !== ExerciseResponseStatusEnum.NEED_GRADING && (
              <Button
                block
                shape={'round'}
                type={'default'}
                onClick={goToQuestion}
              >
                {t('label.view')} <DoubleRightOutlined />
              </Button>
            )}
        </Tooltip>
      </div>
    </LessonStatusItemStyle>
  );
};

const LessonStatusItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  .view-question-action {
    flex-grow: 1;
    text-align: center;
    padding-left: 20px;
  }

  &.not-start {
    color: ${(props) => props.theme.component.not_started};
  }

  &.skip-answer {
    color: ${(props) => props.theme.component.skip};

    .lesson-status-icon {
      color: ${(props) => props.theme.component.skip};
    }
  }

  &.answered {
    color: ${(props) => props.theme.component.correct};

    .lesson-status-icon {
      color: ${(props) => props.theme.component.correct};
    }
  }

  &.incorrect,
  &.score-0-percent,
  &.score-25-percent {
    color: ${(props) => props.theme.component.incorrect};

    .lesson-status-icon {
      color: ${(props) => props.theme.component.incorrect};
    }
  }

  &.score-50-percent,
  &.score-67-percent {
    color: ${(props) => props.theme.component.warning};

    .lesson-status-icon {
      color: ${(props) => props.theme.component.warning};
    }
  }

  &.correct,
  &.score-75-percent,
  &.score-100-percent {
    color: ${(props) => props.theme.component.correct};

    .lesson-status-icon {
      color: ${(props) => props.theme.component.correct};
    }
  }

  &.need-grading {
    color: ${(props) => props.theme.component.need_grading};
  }

  .lesson-question-number {
    text-transform: uppercase;
    font-weight: bold;
  }

  .lesson-status-icon {
    font-size: 150%;
  }
`;
