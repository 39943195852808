import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import MyReports from '@router/pages/private/my_report/my_reports';
import ExerciseReports from '@router/pages/private/my_report/exercise_reports';
import ViewAssignmentReport from '@router/pages/private/my_report/view_assignment_report';
import ViewLessonReport from '@router/pages/private/my_report/view_lesson_report';
import ViewProductReport from '@router/pages/private/my_report/view_product_report';
import React from 'react';
import ViewExerciseReport from '@router/pages/private/my_report/view_exercise_report';

const ReportRouters: PageRouterProps[] = [
  {
    path: SiteMap.reports.list,
    element: <PrivateRouter element={<MyReports />} />,
  },
  {
    path: SiteMap.reports.exercises,
    element: <PrivateRouter element={<ExerciseReports />} />,
  },
  {
    path: SiteMap.reports.exercise,
    element: <PrivateRouter element={<ViewAssignmentReport />} />,
  },
  {
    path: SiteMap.reports.lesson,
    element: <PrivateRouter element={<ViewLessonReport />} />,
  },
  {
    path: SiteMap.reports.product,
    element: <PrivateRouter element={<ViewProductReport />} />,
  },

  {
    path: SiteMap.reports.lesson_report,
    element: <PrivateRouter element={<ViewExerciseReport />} />,
  },
];

export default ReportRouters;
