import { UserRes } from '@modules/users/services/model';
import { PublicStudentCircleInfo } from '@components/info/StudentInfo';
import React from 'react';
import styled from 'styled-components';
import { H4 } from '@components/typography';

export const PublicSessionStudentInfo = (props: {
  title: string;
  items: UserRes[];
}) => {
  return (
    <PublicSessionStudentInfoStyle className={'public-session-info'}>
      <H4>{props.title}</H4>

      {props.items.map((ls) => {
        return <PublicStudentCircleInfo item={ls} key={ls.userId} />;
      })}
    </PublicSessionStudentInfoStyle>
  );
};

const PublicSessionStudentInfoStyle = styled.div`
  .small-color-circle {
    font-size: 24px;
    font-weight: 600;
  }

  .small-color-circle,
  .icon-image {
    width: 40px;
    height: 40px;
    margin-right: 0.25em;
  }
`;
