import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useSelector } from 'react-redux';
import { RootState } from '@app/redux/store';
import LtiViewProductPage from '@modules/lti/pages/LtiViewProductPage';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { H1 } from '@components/typography';
import useLogin from '@hooks/useLogin';
import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { SiteMap } from '@router/SiteMap';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import LtiProductTocPage from '@modules/lti/pages/LtiProductTocPage';

const ViewProductLTI = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { product_code, access_token } = router.query as unknown as {
    product_code: string;
    uid: string;
    access_token: string;
  };

  const [viewProductByCode, { data, error, isError }] =
    ProductAPI.endpoints.viewProductByCode.useLazyQuery();

  const { isAuth, isReady } = useSelector((state: RootState) => state.profile);

  useLogin(false, access_token);

  useEffect(() => {
    if (isAuth && isReady && product_code) {
      viewProductByCode(product_code);
    }
  }, [isAuth, isReady, product_code]);

  useEffect(() => {
    if (error && isError) {
      router.push(SiteMap.public.page_404);
    }
  }, [error, isError]);

  return (
    <EmptyLayout className={'view-product-lti-page'}>
      {data && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PRODUCTS}
            subMenu={NavigationMenuEnum.EMPTY}
            title={data.name}
            keywords={data.keyword}
            description={data.description}
          />
          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.CREATE_PRODUCT]}
          >
            <AuthorizeStyle>
              <div className={'home-block-container'}>
                <LtiProductTocPage productId={data.productId} />
              </div>
            </AuthorizeStyle>
          </Authorization>

          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
          >
            <AuthorizeStyle>
              <div className={'home-block-container'}>
                <LtiViewProductPage product={data} />
              </div>
            </AuthorizeStyle>
          </Authorization>
        </>
      )}

      {error && <H1>{t('oauth_app.warning.product_not_found')}</H1>}
    </EmptyLayout>
  );
};

export default ViewProductLTI;
