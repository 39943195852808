import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  CheckAnswerReq,
  ExerciseRes,
  ExerciseResponseRes,
  SelfCheckQuestionReq,
  ViewExerciseResourceReq,
} from '@modules/assignments/service/exercise_model';

export const PracticeExerciseAPI = createApi({
  reducerPath: 'PracticeExerciseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    viewExerciseResource: builder.query<
      ExerciseResponseRes,
      ViewExerciseResourceReq
    >({
      query: (params: ViewExerciseResourceReq) => ({
        url: `/exercises/${params.exerciseId}/sessions/${params.sectionId}/resources/${params.resourceId}/view-answer`,
        params,
      }),
    }),

    checkAnswer: builder.mutation<ExerciseRes, CheckAnswerReq>({
      query: (body) => ({
        url: `/exercises/${body.exerciseId}/sessions/${body.sectionId}/resources/${body.resourceId}/check-answer`,
        method: 'POST',
        body,
      }),
    }),

    selfCheckAnswer: builder.mutation<ExerciseRes, SelfCheckQuestionReq>({
      query: (body) => ({
        url: `/exercises/lessons/${body.lessonId}/self-check`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
