import styled from 'styled-components';
import React, { useState } from 'react';
import Input from '@components/input/input';
import { Button } from 'antd';
import { IconUtils } from '@utils/IconUtils';

export const ChatBoxCompose = (props: {
  placeHolder: string;
  onSend: (value: string) => void;
}) => {
  const [value, setValue] = useState('');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleSubmit = () => {
    setValue('');
    props.onSend(value);
  };

  const handleOnKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <ChatBoxComposeStyle className={'chat-box-compose'}>
      <Input
        type={'textarea'}
        name="message"
        placeholder={props.placeHolder}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        style={{ height: '2.6em', resize: 'none' }}
        onKeyUp={handleOnKeyUp}
      />

      <Button
        type={'primary'}
        size={'large'}
        shape={'circle'}
        onClick={handleSubmit}
        disabled={!value}
        icon={IconUtils.actions.send}
      />
    </ChatBoxComposeStyle>
  );
};

const ChatBoxComposeStyle = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 15px;

  .form-group {
    flex-grow: 1;
    margin: 0px !important;
  }

  .ant-btn {
    margin-left: 15px;
    border: none;
  }
`;
