import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';

import Products from '@router/pages/public/product/products';
import ViewProduct from '@router/pages/public/product/view_product';
import ViewProductLesson from '@router/pages/public/product/product_lesson';
import Lessons from '@router/pages/public/lesson/lessons';
import ViewLesson from '@router/pages/public/lesson/lesson_view';
import Courses from '@router/pages/public/course/courses';
import ViewCourse from '@router/pages/public/course/view_course';
import Teachers from '@router/pages/public/teacher/teachers';
import ViewTeacher from '@router/pages/public/teacher/view_teacher';

import React from 'react';
import ViewSurvey from '@router/pages/public/survey/view_survey';
import ViewCertificate from '@router/pages/public/certificate/view_certificate';
import DoExercisePage from '@router/pages/public/exercise/do_exercise';
import ReviewExercisePage from '@router/pages/public/exercise/review_exercise';
import GradeExercisePage from '@router/pages/public/exercise/grade_exercise';

export const PublicRouters: PageRouterProps[] = [
  { path: SiteMap.public.products, element: <Products /> },
  { path: SiteMap.public.product, element: <ViewProduct /> },
  { path: SiteMap.public.product_lesson, element: <ViewProductLesson /> },

  { path: SiteMap.public.lessons, element: <Lessons /> },
  { path: SiteMap.public.lessons_view, element: <ViewLesson /> },

  { path: SiteMap.public.exercise.view_exercise, element: <DoExercisePage /> },
  {
    path: SiteMap.public.exercise.grade_exercise,
    element: <GradeExercisePage />,
  },
  {
    path: SiteMap.public.exercise.review_exercise,
    element: <ReviewExercisePage />,
  },

  { path: SiteMap.public.courses, element: <Courses /> },
  { path: SiteMap.public.course_detail, element: <ViewCourse /> },
  { path: SiteMap.public.teachers, element: <Teachers /> },
  { path: SiteMap.public.teacher_view, element: <ViewTeacher /> },

  { path: SiteMap.public.view_survey, element: <ViewSurvey /> },

  {
    path: SiteMap.public.view_certificate,
    element: <ViewCertificate />,
  },
];
