import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { CoordinateActionEnum } from '@cms/comps/interact/coordinate/CoordinateEnum';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';

export interface AnnotationDataProps {
  data: string;
  timestamp: number;
}

export interface AnnotationSettingProps {
  enable: boolean;
  action: CoordinateActionEnum;

  fill: string;
  fillOpacity: number;

  stroke: number;
  strokeColor: string;
  strokeOpacity: number;
}

const defaultAnnotationSetting: AnnotationSettingProps = {
  enable: false,
  action: CoordinateActionEnum.SELECT,

  fill: '#ccc',
  fillOpacity: 1,

  stroke: 1,
  strokeColor: '#FFF',
  strokeOpacity: 0,
};

export const AnnotationContext = createContext({
  // init data....
  annotation: {} as any,
  updateAnnotation: (value: string, triggerChange: boolean) => {
    console.log(value, triggerChange);
  },

  // annotation setting of current
  setting: defaultAnnotationSetting,
  updateSetting: (properties: string, value: any) => {
    console.log(properties, value);
  },
});

export const AnnotationContextProvider = (props: { children: ReactNode }) => {
  const { onAnnotationChange } = useClassRoomContext();

  const [annotationData, setAnnotationData] = useState({
    data: '',
    timestamp: -1,
  });

  const [annoSetting, setAnnoSetting] = useState<AnnotationSettingProps>(
    defaultAnnotationSetting
  );

  useEffect(() => {
    if (annotationData.timestamp > -1) {
      onAnnotationChange(annotationData.data);
    }
  }, [annotationData]);

  const updateAnnotation = (annotationData: string, triggerChange: boolean) => {
    setAnnotationData({
      data: annotationData,
      timestamp: triggerChange ? DateAndTimeUtils.getCurrentTime() : -1,
    });
  };

  const updateAnnotationSetting = (properties: string, value: any) => {
    setAnnoSetting((prev) => {
      return { ...prev, properties: value };
    });
  };

  return (
    <AnnotationContext.Provider
      value={{
        annotation: annotationData,
        updateAnnotation: updateAnnotation,
        setting: annoSetting,
        updateSetting: updateAnnotationSetting,
      }}
    >
      {props.children}
    </AnnotationContext.Provider>
  );
};

export const useAnnotationContext = () => {
  const context = useContext(AnnotationContext);
  if (!context) {
    throw new Error('You must wrap container by AnnotationContextProvider');
  }
  return context;
};
