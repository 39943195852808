import { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ClipboardCopyStyle = styled.span`
  position: relative;

  &.hidden-layout {
    white-space: nowrap;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-btn {
    min-height: auto;
    height: auto;
  }

  .display-text {
    word-break: break-word;
    white-space: normal;
    text-align: left;
  }

  .copy-message {
    position: absolute !important;
    right: 5px;
    top: 5px;

    font-size: 8px;

    background: ${(props) => props.theme.app.primary};
    color: ${(props) => props.theme.app.primary_text};

    display: inline-flex;
    padding: 0.25em 1em;
    border-radius: 1em;
  }
`;

export default function ClipboardCopy(props: {
  copyText: string;
  title?: string | any;
  layout: 'hidden' | 'inline';
}) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard

    copyTextToClipboard(props.copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ClipboardCopyStyle className={`clip-board-copy ${props.layout}-layout`}>
      {props.layout === 'inline' && (
        <Button type={'default'} onClick={handleCopyClick}>
          <span className={'display-text'}>
            {props.title ? props.title : props.copyText}
          </span>

          {
            <span className={'copy-message'}>
              {isCopied ? t('label.copied') : t('label.copy')}
            </span>
          }
        </Button>
      )}

      {props.layout === 'hidden' && (
        <Button type={'default'} onClick={handleCopyClick}>
          <span className={'display-text'}>
            {props.title ? props.title : props.copyText}
          </span>
          {
            <span className={'copy-message'}>
              {isCopied ? t('label.copied') : t('label.copy')}
            </span>
          }
        </Button>
      )}
    </ClipboardCopyStyle>
  );
}
