import React, { useEffect, useState } from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { Col, Row } from 'antd';
import { LessonStandardWeight } from '@modules/reports/lesson/components/LessonStandardWeight';
import { LessonReportTypeEnum } from '@services/reports/AssignmentReport';
import AssignmentScoreHistory from '@modules/reports/assignments/components/AssignmentScoreHistory';
import AssignmentStandardScoreHistory from '@modules/reports/assignments/components/AssignmentStandardScoreHistory';
import AssignmentStandardGroupScoreHistory from '@modules/reports/assignments/components/AssignmentStandardStackScoreHistory';
import Card, { CardClassEnum } from '@components/card';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { SelectReportType } from '@modules/reports/components/SelectReportType';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import AssignmentScoreReport from '@modules/reports/assignments/components/AssignmentScoreReport';
import { LessonStandardGroupWeight } from '@modules/reports/lesson/components/LessonStandardGroupWeight';
import AssignmentStandardDistributed from '@modules/reports/assignments/components/AssignmentStandardDistributed';
import { SurveyAPI } from '@modules/assignments/service/SurveyAPI';
import { LessonStandardSetWeight } from '@modules/reports/lesson/components/LessonStandardSetWeight';
import { useTranslation } from 'react-i18next';

const SurveyReportPage = (props: { surveyId: number }) => {
  const { t } = useTranslation();

  const [viewReports, { data, isSuccess }] =
    SurveyAPI.endpoints.viewReports.useLazyQuery();

  const [viewType, setViewType] = useState(LessonReportTypeEnum.score);
  const [enableStandardReport, setEnableStandardReport] = useState(false);

  useEffect(() => {
    viewReports(props.surveyId);
  }, [props.surveyId]);

  useEffect(() => {
    if (data) {
      setEnableStandardReport(
        data && data.standardSets.length > 0 && data.standards.length > 0
      );
    }
  }, [data]);

  const handleOnChange = (value: LessonReportTypeEnum) => {
    setViewType(value);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('survey.report')}

          {data && isSuccess && (
            <>
              &nbsp;-&nbsp;<b>{data.survey.name}</b>
            </>
          )}
        </H1>

        <div className={'header-buttons'}>
          <SelectReportType
            enableStandardReport={enableStandardReport}
            value={viewType}
            onChange={handleOnChange}
          />
        </div>
      </HeaderGroup>

      {data && isSuccess && data.exercises.length > 0 && (
        <>
          <SwitchCondition
            conditions={[
              {
                condition: viewType === LessonReportTypeEnum.score,
                children: (
                  <Row gutter={[Default_Gutter, Default_Gutter]}>
                    <Col span={24}>
                      <AssignmentScoreReport reports={data.exercises} />
                    </Col>
                  </Row>
                ),
              },
              {
                condition: viewType === LessonReportTypeEnum.history,
                children: (
                  <>
                    <Row gutter={[Default_Gutter, Default_Gutter]}>
                      <Col span={24}>
                        <Card className={CardClassEnum.rectangle_box}>
                          <AssignmentScoreHistory reports={data.exercises} />
                        </Card>
                      </Col>

                      {enableStandardReport && (
                        <>
                          <Col span={24}>
                            <Card className={CardClassEnum.rectangle_box}>
                              <AssignmentStandardScoreHistory
                                reports={data.exercises}
                              />
                            </Card>
                          </Col>

                          <Col span={24}>
                            <Card className={CardClassEnum.rectangle_box}>
                              <AssignmentStandardGroupScoreHistory
                                reports={data.exercises}
                              />
                            </Card>
                          </Col>

                          <Col span={24}>
                            <Card className={CardClassEnum.rectangle_box}>
                              <AssignmentStandardDistributed
                                reports={data.exercises}
                              />
                            </Card>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                ),
              },
              {
                condition: viewType === LessonReportTypeEnum.standard,
                children: (
                  <Row gutter={[Default_Gutter, Default_Gutter]}>
                    <Col span={12}>
                      <Card className={CardClassEnum.rectangle_box}>
                        <LessonStandardSetWeight
                          lesson={data.lesson}
                          standardSet={data.standardSets}
                        />

                        <LessonStandardGroupWeight
                          lesson={data.lesson}
                          standards={data.standards}
                        />
                      </Card>
                    </Col>

                    <Col span={12}>
                      <Card className={CardClassEnum.rectangle_box}>
                        <LessonStandardWeight
                          lesson={data.lesson}
                          standards={data.standards}
                        />
                      </Card>
                    </Col>
                  </Row>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default SurveyReportPage;
