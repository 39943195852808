import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreatePaymentMethodReq,
  PaymentMethodRes,
} from '@modules/payment/services/model';

export const PaymentMethodAPI = createApi({
  reducerPath: 'PaymentMethodAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<PaymentMethodRes>, unknown>({
      query: () => ({
        url: `/payment-method`,
      }),
    }),

    findById: builder.query<PaymentMethodRes, number>({
      query: (paymentMethodId) => ({
        url: `/payment-method/${paymentMethodId}`,
        method: 'GET',
      }),
    }),

    create: builder.mutation<PaymentMethodRes, CreatePaymentMethodReq>({
      query: (body) => ({
        url: '/payment-method',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<PaymentMethodRes, CreatePaymentMethodReq>({
      query: (body) => ({
        url: '/payment-method',
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (paymentMethodId) => ({
        url: `/payment-method/${paymentMethodId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
