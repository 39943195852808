import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { AudioCompProps } from '@cms/comps/content/audio/AudioComp';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';

export const StaticAudioData: AudioCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.AUDIO,

  configuration: {
    content: {
      id: 'sample_audio_comp',
      type: AssetsTypeEnum.AUDIO,
      data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/wjz7iF3thZrsna5Q.file_example_MP3_2MG.mp3',
    },
    sourceItems: [],
  },
  setting: {
    layout: '',
  },
};

export const StaticAudioInlineData: AudioCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.AUDIO,

  configuration: {
    content: {
      id: 'sample_audio_comp',
      type: AssetsTypeEnum.AUDIO,
      data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/wjz7iF3thZrsna5Q.file_example_MP3_2MG.mp3',
    },
    sourceItems: [
      {
        label: '1',
        from: 0,
        to: -1,
        content: {
          id: 'static-content-prop',
          type: AssetsTypeEnum.TEXT,
          data: 'Click to play audio.',
        },
      },
    ],
  },

  setting: {
    layout: 'subtitle',
  },
};
