import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CoordinateProps } from '@cms/comps/interact/coordinate/CoordinateComp';
import { CoordinateGridType } from '@cms/comps/interact/coordinate/CoordinateUtils';

export const CoordinateExampleData: CoordinateProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.COORDINATE,

  configuration: {
    sourceItems: [],
  },
  setting: {
    grid: {
      type: CoordinateGridType.grid,
      snapToGrid: false,
      unit: 50,
      xAxis: {
        from: 1,
        to: 10,
        step: 0.5,
        stepLabel: 2,
      },

      yAxis: {
        from: 1,
        to: 10,
        step: 0.5,
        stepLabel: 2,
      },
    },
    actions: [],
  },
};
