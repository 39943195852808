import { CompScoringProps } from '@cms/ComponentInteface';
import { WordScrambleCompProps } from '@cms/comps/game/word-scramble/WordScrambleComp';

class WordScrambleValidation {
  getScoring = (comp: WordScrambleCompProps): CompScoringProps => {
    const totalPart = comp.configuration.sourceItems.length;

    return {
      id: comp.id,
      type: comp.type,
      manualScore: false,
      maxScore: totalPart,
    };
  };
}

export const WordScrambleValidationUtils = new WordScrambleValidation();
