import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import { CompActionEnum } from '@cms/utils/CompProps';

export const VideoPlayer = (props: {
  title?: string;
  videoSrc: string;
  action?: { action: CompActionEnum; params: any };
  onChange?: (currentTime: number, duration: number) => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isPlay, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showVolume, setShowVolume] = useState(false);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(currentTime, duration);
    }
  }, [currentTime]);

  useEffect(() => {
    if (props.action) {
      if (props.action.action === CompActionEnum.video_pause) {
        pauseVideo();
      } else if (props.action.action === CompActionEnum.video_resume) {
        resumeVideo();
      } else if (props.action.action === CompActionEnum.video_play) {
        playVideo();
      } else if (props.action.action === CompActionEnum.video_jump_to) {
        setCurrentTimeAndPlay(props.action.params);
      }
    }
  }, [props.action]);

  const pauseVideo = () => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setPlay(false);
      }
    }
  };

  const resumeVideo = () => {
    if (videoRef.current) {
      if (!isPlay) {
        setPlay(true);
        videoRef.current.play();
      }
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      if (!isPlay) {
        setPlay(true);
        videoRef.current.play();
      }
    }
  };

  const setCurrentTimeAndPlay = (newTime: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);

      if (!isPlay) {
        setPlay(true);
        videoRef.current.play();
      }
    }
  };

  const getTimeDisplay = (time: number) => {
    const second = parseInt(time + '') % 60;
    const minute = (parseInt(time + '') - second) / 60;

    return `${minute < 10 ? '0' + minute : minute}:${
      second < 10 ? '0' + second : second
    }`;
  };

  const handleLoadedData = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
      if (isPlay) videoRef.current.play();
    }
  };

  const handlePausePlayClick = () => {
    if (videoRef.current) {
      if (isPlay) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setPlay(!isPlay);
    }
  };

  const getClickEventValue = (event: any, type: 'horizontal' | 'vertical') => {
    const bBox = event.target.getBoundingClientRect();
    const offset = {
      x: event.clientX - bBox.left,
      y: event.clientY - bBox.height,
    };

    let percent;

    if (type === 'horizontal') {
      percent = offset.x / event.target.clientWidth;
    } else {
      percent = 1 - offset.y / event.target.clientHeight;
    }

    if (percent < 0) {
      return 0;
    } else if (percent > 1) {
      return 1;
    } else {
      return percent;
    }
  };

  const handleTimeSliderChange = (
    event: any,
    type: 'horizontal' | 'vertical'
  ) => {
    const percent = getClickEventValue(event, type);
    const newTime = percent * duration;

    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);

      if (!isPlay) {
        setPlay(true);
        videoRef.current.play();
      }
    }
  };

  const handleChangeVolume = (event: any, type: 'horizontal' | 'vertical') => {
    const percent = getClickEventValue(event, type);
    if (videoRef.current) {
      videoRef.current.volume = percent;
      setVolume(percent);
    }
  };

  const handleOnTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleStopVideo = () => {
    setPlay(false);
  };

  return (
    <CmsVideoStyle className={'adapt-video'}>
      <div className={'cms-video-player'}>
        <video
          ref={videoRef}
          src={props.videoSrc}
          onLoadedData={handleLoadedData}
          onTimeUpdate={handleOnTimeUpdate}
          onEnded={handleStopVideo}
        />
      </div>

      <div className={'cms-video-progress'}>
        <span
          className={'video-progress-background'}
          onClick={(e) => handleTimeSliderChange(e, 'horizontal')}
        />
        <span
          className={'video-progress'}
          style={{ right: 100 - (currentTime / duration) * 100 + '%' }}
        />
        <span
          className={'current-progress'}
          style={{ left: (currentTime / duration) * 100 + '%' }}
        />
      </div>

      <div className={'cms-video-controller'}>
        <div className={'cms-video-group left-group'}>
          <span className={'video-button'} onClick={handlePausePlayClick}>
            {!isPlay && <PlayCircleOutlined />}
            {isPlay && <PauseCircleOutlined />}
          </span>

          <span className={'video-button'} onClick={() => setShowVolume(true)}>
            {volume === 0 && <SoundOutlined />}

            {0 < volume && volume < 0.33 && <SoundOutlined />}

            {0.33 <= volume && volume <= 0.66 && <SoundOutlined />}

            {0.66 <= volume && <SoundOutlined />}

            {showVolume && (
              <span className={'video-volume-progress'}>
                <span
                  className={'video-volume-background'}
                  onClick={(e) => handleChangeVolume(e, 'horizontal')}
                />
                <span
                  className={'video-progress'}
                  style={{ left: volume * 100 + '%' }}
                />
              </span>
            )}
          </span>

          <span className={'video-button'}>
            <span className={'current-time'}>
              {getTimeDisplay(currentTime)}
            </span>
            <span className={'time-separator'}>/</span>
            <span className={'total-time'}>{getTimeDisplay(duration)}</span>
          </span>
        </div>
      </div>
    </CmsVideoStyle>
  );
};

const CmsVideoStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #3a383a;
  z-index: 0;

  .cms-video-player {
    display: flex;
    video {
      width: 100%;
    }
  }

  .cms-video-progress {
    position: relative;
    height: 0.25em;
    background: #ccc;
    cursor: pointer;

    .video-progress-background {
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: 0px;
      right: 0px;
      z-index: 2;
      cursor: pointer;
    }

    .video-progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: ${(props) => props.theme.component.primary};
      z-index: 1;
    }

    .current-progress {
      width: 0.75em;
      height: 0.75em;
      border-radius: 100%;
      display: inline-block;
      background: ${(props) => props.theme.component.primary};
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 1;
    }
  }

  .cms-video-controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 0.5em;
    color: ${(props) => props.theme.color.white};
    font-size: 120%;

    .video-button {
      padding: 0.125em;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
    }
  }

  .cms-video-group {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .video-volume-progress {
    margin-left: 0.5em;
    width: 3em;
    height: 0.125em;
    background: #fff;
    border-radius: 1em;
    display: inline-flex;
    position: relative;

    .video-volume-background {
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: 0px;
      right: 0px;
      z-index: 2;
      cursor: pointer;
    }

    .video-progress {
      position: absolute;
      width: 0.5em;
      height: 0.5em;
      border-radius: 100%;
      background: #fff;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  .current-time,
  .total-time,
  .time-separator {
    font-size: 0.75em;
  }
`;
