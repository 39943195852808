import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { ComposingWithStatus } from '@components/common/lesson/ComposingStatus';
import styled from 'styled-components';
import { UpdateLessonDataEnum } from '@modules/product/components/lesson/lesson-data/LessonDataModal';
import { IconUtils } from '@utils/IconUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { LessonRes } from '@modules/product/services/lesson_model';

export enum LessonSelectionEnum {
  add = 'add',
  remove = 'remove',
}

const LessonListItem = (props: {
  item: LessonRes;
  type?: LessonSelectionEnum;
  selected?: boolean;
  generateTitle?: (name: string) => ReactNode;
  onSelect?: (lesson: LessonRes) => void;
  onRemove?: (lessonId: number) => void;
}) => {
  const { t } = useTranslation();

  const [mode, changeToMode] = useState<UpdateLessonDataEnum>(
    UpdateLessonDataEnum.DEFAULT
  );

  const handleSelect = () => {
    if (props.onSelect) {
      props.onSelect(props.item);
    }
  };

  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.item.lessonId);
    }
  };

  return (
    <>
      <LessonListItemStyle className={'lesson-item'}>
        <div className={'lesson-title'}>
          <Space>
            <ThumbnailsImg
              type={'icon'}
              src={props.item.thumbnails}
              ratio={ImageScale.product.ratio}
              width={ImageScale.product.icon}
            />

            <ComposingWithStatus status={props.item.status}>
              {props.item.name}
            </ComposingWithStatus>
          </Space>
        </div>

        <div className={'lesson-action'}>
          <Space size={8}>
            <Button
              size={'small'}
              type={'default'}
              shape={'round'}
              onClick={() => changeToMode(UpdateLessonDataEnum.REVIEW)}
              title={t('lesson.actions.view')}
              icon={IconUtils.lesson.review}
            >
              {t('label.view')}
            </Button>

            {props.type && props.type === LessonSelectionEnum.add ? (
              <>
                {props.selected ? (
                  <Button
                    size={'small'}
                    type={'primary'}
                    shape={'round'}
                    danger
                    onClick={handleRemove}
                    title={t('lesson.actions.remove_lesson')}
                  >
                    {t('label.remove')}
                  </Button>
                ) : (
                  <Button
                    size={'small'}
                    type={'primary'}
                    shape={'round'}
                    onClick={handleSelect}
                    title={t('lesson.actions.select_lesson')}
                  >
                    {t('label.select')}
                  </Button>
                )}
              </>
            ) : (
              <Button
                size={'small'}
                type={'primary'}
                shape={'round'}
                danger
                onClick={handleRemove}
                title={t('lesson.actions.remove_lesson')}
              >
                {t('label.remove')}
              </Button>
            )}
          </Space>
        </div>
      </LessonListItemStyle>

      {mode === UpdateLessonDataEnum.REVIEW && (
        <CustomModal
          header={t('lesson.actions.review')}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={props.item.lessonId} />}
          onCloseFunc={() => {
            changeToMode(UpdateLessonDataEnum.DEFAULT);
          }}
        />
      )}
    </>
  );
};

export default LessonListItem;

const LessonListItemStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewLessonListItem = (props: {
  items: LessonRes[];
  generateTitle?: (name: string) => ReactNode;
}) => {
  return (
    <List
      bordered
      dataSource={props.items}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar size={'large'} shape={'square'} src={item.thumbnails} />
            }
            title={
              <>
                {props.generateTitle
                  ? props.generateTitle(item.name)
                  : item.name}
              </>
            }
            description={item.description}
          />
        </List.Item>
      )}
    />
  );
};
