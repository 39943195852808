import { CLEAR_KEYCODE_ARRAY, isEnterANumber } from '@cms/utils/ComponentUtils';

export class HTMLSupportUtils {
  findAncestor = (ctn: HTMLElement, cls: string): any[] => {
    let results: any[] = [];
    const children = ctn.childNodes;

    if (children != null && children.length > 0) {
      children.forEach((child: any) => {
        if (child.classList != null && child.classList.contains(cls)) {
          results.push(child);
        } else {
          const childTag = this.findAncestor(child, cls);
          if (childTag.length > 0) {
            results = results.concat(childTag);
          }
        }
      });
    }
    return results;
  };

  validateMaxLength = (e: any, maxLength: number) => {
    const key = e.key;
    const currentAns = e.target.value + key;

    // @ts-ignore
    if (
      inArray(e.keyCode, [
        46, // backspace
        8, // delete
        9, // tab
        27, // escape
        110,
        190, // decimal point
        109,
        189,
      ]) || // and minus
      // allow number from 0 to 9
      // Allow: Ctrl+A, Command+A
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      key === '-' ||
      // allow dollar character ($)
      (e.keyCode === 52 && (e.shiftKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // do nothing....
    } else if (currentAns.length > maxLength) {
      e.preventDefault();
    }
  };

  validateNumber = (e: any) => {
    const key = e.key;
    const keyCode = e.keyCode;
    const ctrlKey = e.ctrlKey === true || e.metaKey === true;
    const shiftKey = e.shiftKey === true || e.metaKey === true;
    const currentAns = e.target.value ? e.target.value + key : key;

    if (
      inArray(keyCode, CLEAR_KEYCODE_ARRAY) || // and minus
      // allow number from 0 to 9
      isEnterANumber(e) ||
      // Allow: Ctrl+A, Command+A
      (keyCode === 65 && ctrlKey) ||
      key === '-' ||
      // allow dollar character ($)
      (keyCode === 52 && shiftKey) ||
      // Allow: home, end, left, right, down, up
      (keyCode >= 35 && keyCode <= 40)
    ) {
      const positionOfCursor = doGetCaretPosition(e.target);

      // if the input is number but the cursor is beginning and beginning character of the input is minus (-) or dollar ($) not allow typing
      if (
        !shiftKey &&
        ((48 <= keyCode && keyCode <= 57) || (96 <= keyCode && keyCode <= 105))
      ) {
        if (
          positionOfCursor === 0 &&
          (currentAns.indexOf('-') === 0 || currentAns.indexOf('$') === 0)
        ) {
          e.preventDefault();
        }
      }
      // if the input has a decimal point, not allow to enter any more.
      if (
        currentAns.indexOf('.') > -1 &&
        (keyCode === 190 || keyCode === 110)
      ) {
        e.preventDefault();
      }

      // if input has a minus character, not allow to enter any more, or if the cursor is not the beginning of character, not allow typing
      if (
        (key === '-' || keyCode === 189 || keyCode === 109) &&
        (currentAns.indexOf('$') > -1 ||
          currentAns.indexOf('-') > -1 ||
          positionOfCursor !== 0)
      ) {
        e.preventDefault();
      }

      // if input has a dollar, not allow to enter any more, or if the cursor is not the beginning of character, not allow typing
      if (
        shiftKey &&
        keyCode === 52 &&
        (currentAns.indexOf('$') > -1 ||
          currentAns.indexOf('-') > -1 ||
          positionOfCursor !== 0)
      ) {
        console.log('...........prevent default 4');
        e.preventDefault();
      }
    } else {
      console.log('...........prevent default 5', {
        key,
        keyCode,
        ctrlKey,
        shiftKey,
        currentAns,
      });
      e.preventDefault();
    }
  };

  formatTheInputNumber = (value: string) => {
    if (value == null || value.trim() === '') {
      return '';
    }

    let valStr = value.replace(/,/g, '');
    valStr = valStr.replace('-', '−');

    const prefix =
      valStr.indexOf('$') > -1 ? '$' : valStr.indexOf('−') > -1 ? '−' : '';
    const suffix = valStr.indexOf('%') > -1 ? '%' : '';

    if (prefix) {
      valStr = valStr.replace(prefix, '');
    }

    if (suffix) {
      valStr = valStr.replace(suffix, '');
    }

    if (valStr.indexOf('.') > -1) {
      const integer = valStr.split('.')[0];
      const decimal = valStr.split('.')[1];

      const numberFormatted = convertStringToNumberFormat(integer);
      return prefix + numberFormatted + '.' + decimal + suffix;
    } else {
      const numberFormatted = convertStringToNumberFormat(valStr);
      return prefix + numberFormatted + suffix;
    }
  };
}

export const HTMLUtils = new HTMLSupportUtils();

const inArray = (value: number, array: number[]) => {
  return array.includes(value);
};

function doGetCaretPosition(oField: any) {
  // Initialize
  let iCaretPos = 0;

  // @ts-ignore
  if (document.selection) {
    // Set focus on the element
    oField.focus();

    // @ts-ignore
    const oSel = document.selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart('character', -oField.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart === '0')
    iCaretPos = oField.selectionStart;

  // Return results
  return iCaretPos;
}

const convertStringToNumberFormat = (value: string) => {
  if (value.length > 3) {
    return addCommaForFormatNumber(value);
  } else {
    return value;
  }
};

const addCommaForFormatNumber = (value: string) => {
  // @ts-ignore
  return reverse(chunkString(reverse(value), 3).join(','));
};

const chunkString = (str: string, length: number) => {
  return str.match(new RegExp('.{1,' + length + '}', 'g'));
};

const reverse = (str: string) => {
  return str.split('').reverse().join('');
};
