import dayjs from 'dayjs';

export const SERVER_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss';

export enum ServerDateAndTimeFormat {
  DATE_N_TIME = 'YYYY-MM-DDThh:mm:ss',
  DATE = 'YYYY-MM-DD',
  TIME = 'hh:mm:ss',
}

export enum ClientDateAndTimeFormat {
  DATE_N_TIME = 'YYYY-MM-DD HH:mm:ss',
  DATE = 'YYYY-MM-DD',
  TIME = 'HH:mm:ss',
}

export enum CalendarDateAndTimeFormat {
  DATE_N_TIME = 'YYYY-MM-DDThh:mm:ssZ',
  DATE = 'yyyy-MM-dd',
  TIME = "HH:mm:ssZ',",
}

export enum DateAndTimeFormat {
  DATE_N_TIME = 'MM-DD-YYYY HH:mm:ss',
  DATE = 'MM-DD-YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  SHORT = 'YYYY-MM-DD HH:mm',
}

export const DayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export class DateAndTime {
  current = () => {
    return dayjs();
  };

  getCurrentTime = (): number => {
    return dayjs().valueOf();
  };

  getDate = (format?: string) => {
    return dayjs().format(format ? format : DateAndTimeFormat.DATE);
  };

  getCurrentDate = (format?: string) => {
    return dayjs().format(format || DateAndTimeFormat.DATE_N_TIME);
  };

  getDateWithFormat = () => {
    return dayjs().format(DateAndTimeFormat.DATE_N_TIME);
  };

  getDateStr = (date: any, format?: DateAndTimeFormat | string) => {
    return dayjs(date).format(format ? format : DateAndTimeFormat.DATE_N_TIME);
  };

  format = (date: string, format?: DateAndTimeFormat | string) => {
    return dayjs(date, ServerDateAndTimeFormat.DATE_N_TIME).format(
      format ? format : DateAndTimeFormat.DATE_N_TIME
    );
  };

  getRange = (
    type: string,
    date?: string,
    format?: DateAndTimeFormat | string
  ) => {
    if (type === 'date') {
      return this.getDateRange(date, format);
    } else if (type === 'month') {
      return this.getWeekRange(date, format);
    } else {
      return this.getMonthRange(date, format);
    }
  };

  getDateRange = (date?: string, format?: ServerDateAndTimeFormat | string) => {
    const currentDate = date
      ? dayjs(date, format ? format : ServerDateAndTimeFormat.DATE_N_TIME)
      : dayjs();

    const startDate = dayjs(currentDate).startOf('day');
    const endDate = dayjs(currentDate).endOf('day');

    return {
      startDate: startDate.format(
        format ? format : ServerDateAndTimeFormat.DATE
      ),
      endDate: endDate.format(format ? format : ServerDateAndTimeFormat.DATE),

      startDateAndTime: startDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
      endDateAndTime: endDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
    };
  };

  getWeekRange = (date?: string, format?: string) => {
    const currentDate = date
      ? dayjs(date, format ? format : ServerDateAndTimeFormat.DATE_N_TIME)
      : dayjs();

    const startDate = dayjs(currentDate).startOf('week');
    const endDate = dayjs(currentDate).endOf('week');

    return {
      startDate: startDate.format(
        format ? format : ServerDateAndTimeFormat.DATE
      ),
      endDate: endDate.format(format ? format : ServerDateAndTimeFormat.DATE),

      startDateAndTime: startDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
      endDateAndTime: endDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
    };
  };

  getMonthRange = (date?: string, format?: string) => {
    const currentDate = date
      ? dayjs(date, format ? format : ServerDateAndTimeFormat.DATE_N_TIME)
      : dayjs();

    const startDate = dayjs(currentDate).startOf('month');
    const endDate = dayjs(currentDate).endOf('month');

    return {
      startDate: startDate.format(
        format ? format : ServerDateAndTimeFormat.DATE
      ),
      endDate: endDate.format(format ? format : ServerDateAndTimeFormat.DATE),

      startDateAndTime: startDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
      endDateAndTime: endDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
    };
  };

  getDisplayTime = (second: number) => {
    if (second > 60 * 60) {
      return Math.round(second / (60 * 60)) + 'hrs';
    } else if (second > 60) {
      return Math.round(second / 60) + 'mins';
    } else {
      return second + 'sec';
    }
  };

  getWeekRangeFromDate = (currentDate?: any, format?: string) => {
    const startDate = dayjs(currentDate).startOf('week');
    const endDate = dayjs(currentDate).endOf('week');

    return {
      startDate: startDate.format(
        format ? format : ServerDateAndTimeFormat.DATE
      ),
      endDate: endDate.format(format ? format : ServerDateAndTimeFormat.DATE),
      startDateAndTime: startDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
      endDateAndTime: endDate.format(ServerDateAndTimeFormat.DATE_N_TIME),
    };
  };

  getDaysInRanger = (_startDate: string, _endDate: string): string[] => {
    let start = dayjs(_startDate, ServerDateAndTimeFormat.DATE);
    let end = dayjs(_endDate, ServerDateAndTimeFormat.DATE);
    const dayRanger: string[] = [];

    while (start.isBefore(end) || start.isSame(end)) {
      const dateFormat = start.format(ServerDateAndTimeFormat.DATE);
      dayRanger.push(dateFormat);
      start = start.add(1, 'day');
    }
    return dayRanger;
  };

  add(
    dateStr: string,
    format: string,
    duration: number,
    unit: 'year' | 'month' | 'week' | 'day' | 'hour'
  ) {
    return dayjs(dateStr, format).add(duration, unit).format(format);
  }
}

export const DateAndTimeUtils = new DateAndTime();
