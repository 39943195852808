import {
  CertificateRes,
  UserCertificateRes,
} from '@modules/admin/service/program';
import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import ContentEditable from 'react-contenteditable';
import { CertificateAPI } from '@modules/admin/service/CertificateAPI';
import { ClassRes } from '@modules/users/services/clazz';
import { UserRes } from '@modules/users/services/model';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { StringUtils } from '@utils/StringUtils';

export const IssuesCertificateForm = (props: {
  clazz: ClassRes;
  user: UserRes;
  certificate: CertificateRes;
  onSuccess: (res: UserCertificateRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [issueCertificate, { isLoading }] =
    CertificateAPI.endpoints.issueCertificate.useMutation({});

  const [template, setTemplate] = useState(() => {
    return generateTemplate(
      props.certificate.template,
      props.clazz,
      props.user,
      props.certificate
    );
  });

  const handleOnChange = (template: string) => {
    setTemplate(template);
  };

  const handleSubmit = () => {
    issueCertificate({
      certificateId: props.certificate.certificateId,
      userId: props.user.userId,
      classId: props.clazz.classId,
      template: template,
    })
      .unwrap()
      .then((res) => {
        notification.success({
          message: t('alert.success'),
          description: t('alert.update_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess(res);
      })
      .catch(() => {
        notification.error({
          message: t('alert.warning'),
          description: t('alert.cannotUpdate'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <div className={'issue-certificate-form'}>
      <ContentEditable
        onChange={(evt) => {}}
        onBlur={(evt) => handleOnChange(evt.currentTarget.innerHTML)}
        html={DOMPurify.sanitize(template)}
      />

      <ButtonGroup className="submit-container" type={'right'}>
        <Button
          type="default"
          size={'large'}
          shape={'round'}
          onClick={props.onCancel}
        >
          {t('button.cancel')}
        </Button>

        <Button
          type={'primary'}
          size={'large'}
          shape={'round'}
          icon={IconUtils.menu.certificate}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Cấp chứng chỉ
        </Button>
      </ButtonGroup>
    </div>
  );
};

const generateTemplate = (
  template: string,
  clazz: ClassRes,
  student: UserRes,
  certificate: CertificateRes
): string => {
  const currentDate = DateAndTimeUtils.getDate();
  console.log(template, clazz, student, certificate);

  let templateFmt = StringUtils.replaceAll(
    template,
    '{firstName}',
    student.firstName ?? ''
  );

  templateFmt = StringUtils.replaceAll(
    templateFmt,
    '{firstName}',
    student.firstName ?? ''
  );
  templateFmt = StringUtils.replaceAll(
    templateFmt,
    '{lasName}',
    student.lastName ?? ''
  );
  templateFmt = StringUtils.replaceAll(
    templateFmt,
    '{certificateDirection}',
    certificate.description ?? ''
  );
  templateFmt = StringUtils.replaceAll(
    templateFmt,
    '{certificateName}',
    certificate.name ?? ''
  );
  templateFmt = StringUtils.replaceAll(
    templateFmt,
    '{date}',
    currentDate ?? ''
  );

  console.log('templateFormat', templateFmt);
  return templateFmt;
};
