import React from 'react';
import dayjs from 'dayjs';

export const DateDefaultFormat = 'YYYY-MM-DD';
export const TimeDefaultFormat = 'HH:mm:ss';
export const DateAndTimeDefaultFormat = 'YYYY-MM-DD HH:mm:ss';

export const getDateAndTime = (dateByString: string, format: string) => {
  return dayjs(dateByString).format(format);
};

const DateAndTime = (props: { date: string; format: string }) => {
  if (!props.date) {
    return <></>;
  }

  const date = new Date(props.date);

  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  return <>{`${year}/${month}/${day}`}</>;
};

export default DateAndTime;
