import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { AssignmentGroupAPI } from '@modules/assignments/service/AssignmentGroupAPI';
import { Default_Gutter } from '@components/grid';
import { useSelector } from '@app/redux/hook';
import {
  AssignmentGroupRes,
  CreateAssignmentGroupReq,
} from '@modules/assignments/service/assignment_model';
import { IconUtils } from '@utils/IconUtils';

const initialValue: CreateAssignmentGroupReq = {
  assignmentGroupId: -1,
  name: '',
  description: '',
};

export function EditAssignmentGroupForm(props: {
  assignmentGroupId?: number;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const { isReady: isLoadSchoolReady, school } = useSelector(
    (state) => state.userInformation
  );

  const [findById, { data, isSuccess }] =
    AssignmentGroupAPI.endpoints.findById.useLazyQuery();

  const [createAssignmentGroup, { isLoading }] =
    AssignmentGroupAPI.endpoints.createAssignmentGroup.useMutation({});

  const [updateAssignmentGroup, { isLoading: updateLoading }] =
    AssignmentGroupAPI.endpoints.updateAssignmentGroup.useMutation({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.assignmentGroupId != null && props.assignmentGroupId > 0) {
      findById(props.assignmentGroupId);
    } else {
      setIsReady(true);
    }
  }, [props.assignmentGroupId]);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setIsReady(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (school && isLoadSchoolReady) {
      if (school.schoolId > 0) {
        formik.setFieldValue('schoolId', school.schoolId);
      }
    }
  }, [school]);

  const setFormData = (data: AssignmentGroupRes) => {
    formik.setValues({
      assignmentGroupId: data.assignmentGroupId,
      name: data.name,
      description: data.description,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      description: yup.string(),
    }),

    onSubmit: (values) => {
      if (props.assignmentGroupId != null && props.assignmentGroupId > 0) {
        _updateAssignmentGroup(values);
      } else {
        _createAssignmentGroup(values);
      }
    },
  });

  const _updateAssignmentGroup = (values: CreateAssignmentGroupReq) => {
    updateAssignmentGroup(values)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('alert.success'),
          description: t('alert.update_success_message'),
          placement: 'bottomRight',
        });

        props.successHandle();
      })
      .catch(() => {
        notification.error({
          message: t('alert.warning'),
          description: t('alert.cannotUpdate'),
          placement: 'bottomRight',
        });
      });
  };

  const _createAssignmentGroup = (values: CreateAssignmentGroupReq) => {
    createAssignmentGroup(values)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('alert.success'),
          description: t('alert.createSuccessful'),
          placement: 'bottomRight',
        });

        props.successHandle();
      })

      .catch(() => {
        notification.error({
          message: t('alert.warning'),
          description: t('alert.cannotCreate'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <Input
                required={true}
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Input
                name="description"
                type="textarea"
                label={t('label.description')}
                placeholder={t('label.description')}
                onChange={formik.handleChange}
                value={formik.values.description}
                maxLength={512}
                row={4}
              />

              <ButtonGroup className="submit-container">
                <Button
                  type={'primary'}
                  shape={'round'}
                  loading={isLoading || updateLoading}
                  onClick={formik.submitForm}
                  icon={IconUtils.actions.save}
                >
                  {props.assignmentGroupId != null &&
                  props.assignmentGroupId > 0
                    ? t('button.update')
                    : t('button.create')}
                </Button>

                <Button
                  type="default"
                  shape={'round'}
                  onClick={props.onCloseFunc}
                >
                  {t('button.cancel')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}
