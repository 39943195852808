import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const Incomplete = () => {
  return (
    <PublicLayout>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'Incomplete page'}
        description={'Incomplete page'}
      />

      <h1>Incomplete</h1>

      <p>Page is incomplete</p>
    </PublicLayout>
  );
};

export default Incomplete;
