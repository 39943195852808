import { UserRes } from '@modules/users/services/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import {
  StudentIconDisplay,
  StudentNameDisplay,
} from '@components/info/StudentInfo';
import { StudentAssignmentStatus } from '@components/exercise/ExerciseStatus';
import styled from 'styled-components';
import { ExerciseStatus } from '@modules/assignments/service/exercise_model';

const SelectStudentAssignment = (props: {
  item: UserRes;
  status?: ExerciseStatus | null;
  selected?: boolean;
  onSelect?: (user: UserRes) => void;
  onRemove?: (userId: number) => void;
}) => {
  const { t } = useTranslation();

  const handleSelect = () => {
    if (props.onSelect) {
      props.onSelect(props.item);
    }
  };

  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.item.userId);
    }
  };

  return (
    <StudentAssignmentStatusStyle>
      <Space>
        <StudentIconDisplay size={'large'} item={props.item} />
        <StudentNameDisplay item={props.item} />
      </Space>

      <Space>
        <StudentExerciseStyle>
          {props.status ? (
            <StudentAssignmentStatus status={props.status} />
          ) : (
            <span />
          )}
        </StudentExerciseStyle>

        {props.selected ? (
          <Button
            size={'small'}
            type={'primary'}
            shape={'round'}
            danger
            onClick={handleRemove}
            disabled={
              props.status != null && props.status !== ExerciseStatus.NOT_START
            }
            title={t('label.remove')}
          >
            {t('label.remove')}
          </Button>
        ) : (
          <Button
            disabled={props.selected}
            size={'small'}
            type={'primary'}
            shape={'round'}
            onClick={handleSelect}
            title={t('label.select')}
          >
            {t('label.select')}
          </Button>
        )}
      </Space>
    </StudentAssignmentStatusStyle>
  );
};

export default SelectStudentAssignment;

const StudentAssignmentStatusStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StudentExerciseStyle = styled.div`
  text-align: left;

  .student-assignment-status {
    justify-content: flex-start;
    min-width: 8em;
  }
`;
