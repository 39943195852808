import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@services/baseQuery';
import { SessionRes } from '@services/model/session';
import { AccessClassRoomReq } from '@modules/calendar/service/model';

export const ClassRoomAPI = createApi({
  reducerPath: 'ClassRoomAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    canAccessClassRoom: builder.query<SessionRes, AccessClassRoomReq>({
      query: (params: AccessClassRoomReq) => ({
        url: `/public/class-room/request`,
        params,
      }),
    }),

    accessClassRoom: builder.mutation<SessionRes, AccessClassRoomReq>({
      query: (body: AccessClassRoomReq) => ({
        url: '/public/class-room/join',
        method: 'POST',
        body,
      }),
    }),
  }),
});
