import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SwapOutlined } from '@ant-design/icons';
import { GlossaryType } from '@cms/comps/game/GlossaryWordBank';
import { useGlossaryCompContext } from '@cms/comps/game/glossary/GlossaryCompContext';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';

const GlossaryItem = () => {
  const {
    modifiedTime,
    setting,
    sourceItems: item,
    updateOption,
    updateComponent,
  } = useGlossaryCompContext();

  const [flip, setFlip] = useState(false);

  const toggleFlip = () => {
    setFlip((prev) => {
      return !prev;
    });
  };

  return (
    <GlossaryItemStyle key={modifiedTime}>
      <div className={`flip-card ${flip ? 'selected' : ''}`}>
        <div className={'flip-card-inner'}>
          <div className={'flip-card-front'}>
            {setting.showTerm != null &&
            setting.showTerm === GlossaryType.description ? (
              <ContentViewerEditable
                contentData={item.description}
                onChange={(data) =>
                  updateOption(GlossaryType.description, data as any)
                }
              />
            ) : (
              <ContentViewerEditable
                contentData={item.word}
                onChange={(data) =>
                  updateOption(GlossaryType.word, data as any)
                }
              />
            )}
          </div>

          <div className={'flip-card-back'}>
            {setting.showTerm != null &&
            setting.showTerm === GlossaryType.description ? (
              <ContentViewerEditable
                contentData={item.word}
                onChange={(data) =>
                  updateOption(GlossaryType.word, data as any)
                }
              />
            ) : (
              <ContentViewerEditable
                contentData={item.description}
                onChange={(data) =>
                  updateOption(GlossaryType.description, data as any)
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className={'flash-card-toolbar'}>
        <button onClick={toggleFlip} className={`${flip ? 'active' : ''}`}>
          <SwapOutlined />
        </button>
      </div>

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </GlossaryItemStyle>
  );
};
export default GlossaryItem;

const GlossaryItemStyle = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 6px 0px #ccc;
  border-radius: 0.125em;

  text-align: center;
  cursor: pointer;

  .flash-card-toolbar {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25em;

    button {
      width: 2em;
      height: 2em;
      border: none;
      font-size: 125%;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.02);

      &.active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .flip-card {
    background-color: transparent;
    position: absolute;
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card.selected .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
  }

  .flip-card-front {
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.black};
    font-size: 320%;
  }

  .flip-card-back {
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.black};
    transform: rotateY(180deg);
    font-size: 200%;
  }
`;
