import React, { createContext, useContext, useEffect, useState } from 'react';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
// import { GlossaryResource } from '@cms/lesson-template/glossary-game/GlossaryResource';
// import { ResourceContextProvider } from '@cms/context/ResourceContextProvider';
// import { useViewLessonContext } from '@cms/lesson-template/ViewLessonContext';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';

export enum GlossaryGameActionEnum {
  init = 'init',
  correct_part = 'on-correct-part',
  correct = 'on-correct',

  incorrect_part = 'on-incorrect-part',
  incorrect = 'on-incorrect',

  view_next_part = 'view-next-part',
  view_next_word = 'view-next-word',
  completed_words = 'completed-words',
}

const GlossaryGameItemContext = createContext({
  points: 0,
  action: {
    type: GlossaryGameActionEnum.init,
    timestamp: 0,
    compType: CompTypeEnum.EMPTY,
  },
  dispatchAction: (
    type: GlossaryGameActionEnum,
    points: number,
    compType: CompTypeEnum
  ) => {
    console.log(type, points, compType);
  },
} as GlossaryGameItemContextProps);

export interface GlossaryGameActionProps {
  type: GlossaryGameActionEnum;
  timestamp: number;
  compType: CompTypeEnum;
}

export interface GlossaryGameItemContextProps {
  points: number;
  action: GlossaryGameActionProps;
  dispatchAction: (
    type: GlossaryGameActionEnum,
    points: any,
    compType: CompTypeEnum
  ) => void;
}

const GlossaryGameItemProvider = () => {
  const { resource } = useLessonNavigationContext();
  // const { sections, exerciseId } = useViewLessonContext();

  const [action, setAction] = useState<GlossaryGameActionProps>({
    type: GlossaryGameActionEnum.init,
    timestamp: DateAndTimeUtils.getCurrentTime(),
    compType: CompTypeEnum.EMPTY,
  });

  const [points, setPoints] = useState(0);

  useEffect(() => {
    if (resource.resourceId > 0) {
      setPoints(0);
      setAction({
        type: GlossaryGameActionEnum.init,
        timestamp: DateAndTimeUtils.getCurrentTime(),
        compType: CompTypeEnum.EMPTY,
      });
    }
  }, [resource.resourceId]);

  const dispatchAction = (
    type: GlossaryGameActionEnum,
    points: number,
    compType: CompTypeEnum
  ) => {
    // 1. update score.
    if (
      compType === CompTypeEnum.WORD_PUZZLE ||
      compType === CompTypeEnum.GAME_MATCHING
    ) {
      setPoints(points);
    } else {
      setPoints((prevState) => {
        return prevState + points;
      });
    }

    setAction({
      type: type,
      timestamp: DateAndTimeUtils.getCurrentTime(),
      compType: compType,
    });
  };

  return (
    <GlossaryGameItemContext.Provider
      value={{
        points,
        action,
        dispatchAction,
      }}
    >
      {/*<ResourceContextProvider*/}
      {/*  resourceId={navigation.current.resourceId}*/}
      {/*  comps={navigation.current.components}*/}
      {/*  answer={[]}*/}
      {/*  feedback={[]}*/}
      {/*  key={navigation.current.resourceId}*/}
      {/*>*/}
      {/*  <GlossaryResource item={navigation.current} exerciseId={exerciseId} />*/}
      {/*</ResourceContextProvider>*/}

      <div className={'glossary-game-test'}>
        Points: {points} - Action: {action.type}
      </div>
    </GlossaryGameItemContext.Provider>
  );
};

export default GlossaryGameItemProvider;

export const useGlossaryGameItemContext = () => {
  const context = useContext(GlossaryGameItemContext);

  if (!context) {
    throw new Error('You must wrap container by ResourceViewContext.Provider');
  }
  return context as GlossaryGameItemContextProps;
};
