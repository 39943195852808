import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationRes } from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateSimpleUserReq,
  CreateUserReq,
  DisabledUserReq,
  FilterUserReq,
} from '@modules/users/services/StudentAPI';
import { UserInfoRes, UserRes } from '@modules/users/services/model';

export const UserStudentAPI = createApi({
  reducerPath: 'UserStudentAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<UserRes>, FilterUserReq>({
      query: (params: FilterUserReq) => ({
        url: `/users/students`,
        method: 'GET',
        params,
      }),
    }),

    getUserData: builder.query<UserRes, number>({
      query: (userId: number) => ({
        url: `/users/students/${userId}`,
        method: 'GET',
      }),
    }),

    createUser: builder.mutation<UserInfoRes, CreateUserReq>({
      query: (body) => ({
        url: '/users/students',
        method: 'POST',
        body,
      }),
    }),

    createSimpleUser: builder.mutation<UserInfoRes, CreateSimpleUserReq>({
      query: (body) => ({
        url: '/users/students/simple',
        method: 'POST',
        body,
      }),
    }),

    updateUser: builder.mutation<UserInfoRes, CreateUserReq>({
      query: (body) => ({
        url: '/users/students',
        method: 'PUT',
        body,
      }),
    }),

    activateUser: builder.mutation<BasicMessageRes, DisabledUserReq>({
      query: (body) => ({
        url: `/users/students/${body.userId}/activated`,
        method: 'PUT',
        body,
      }),
    }),
    //
    // activeUser: builder.mutation<BasicMessageRes, DisabledStudentReq>({
    //   query: () => ({
    //     url: '/users/students/disabled',
    //     method: 'DELETE',
    //   }),
    // }),
  }),
});
