import { ExerciseReportRes } from '@services/reports/LessonReport';
import ColorUtils from '@utils/ColorUtils';
import BarGraphChart, {
  BarGraphReportProp,
} from '@modules/reports/charts/bargraph/BarGraphChart';
import React, { useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { H3 } from '@components/typography';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { ReportUtils } from '@modules/reports/ReportUtils';

const LessonStandardScoreHistory = (props: {
  reports: ExerciseReportRes[];
}) => {
  const [keys] = useState(() => {
    return props.reports[0].standards.map((std) => {
      return std.standard.code;
    });
  });

  const renderFunc = (data: ExerciseReportRes): BarGraphReportProp => {
    const recordData: BarGraphReportProp = {
      group: DateAndTimeUtils.format(
        data.exercise.createdDate,
        DateAndTimeFormat.SHORT
      ),
    };

    data.standards.forEach((std) => {
      const score = ReportUtils.percent(std.score, std.maxScore);
      recordData[std.standard.code] = score;
      recordData[std.standard.code + 'Color'] = ColorUtils.getDefaultColor(
        std.standard.standardId
      ).color;
    });

    return recordData;
  };

  const keysDisplay = useMemo(() => {
    return [...keys, 'incorrect'];
  }, [keys]);

  const labelFormat = (e: any) => {
    return e.id;
  };

  const customGetColor = (params: { id: string; data: BarGraphReportProp }) => {
    return params.data[params.id + 'Color'];
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Standard Score History
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Standard Score History Chart</h3>}
          content={
            <>
              <p>Biểu đồ này thể hiện kết quả của các kĩ năng của học sinh</p>

              <p>
                Các cột trong biểu đồ thể hiện điểm số của từng skill mà bạn đã
                đạt được theo đơn vị %.
              </p>

              <p>
                Bạn có thể xem xét sự thay đổi của các skill của mình theo thời
                gian.
              </p>
            </>
          }
        />
      </H3>

      <BarGraphChart
        className={'lesson-standard'}
        keys={keysDisplay}
        content={props.reports}
        size={500}
        maxValue={keys.length * 100}
        render={renderFunc}
        labelFormat={labelFormat}
        xAxisTitle={'Date and time'}
        yAxisTitle={'Score (%)'}
        getColor={customGetColor}
      />
    </LessonReportChartStyle>
  );
};

export default LessonStandardScoreHistory;
