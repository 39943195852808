import { Typography, Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Title, Paragraph: ParagraphAntd, Text: TextAntd } = Typography;

const TitleStyle = styled(Title)`
  &.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-popover-title & {
    margin-bottom: 0;
    //
    //margin-top: 5px;
    //margin-bottom: 5px;
  }

  &.h1 {
    font-size: 250%;
  }

  &.h2 {
    font-size: 200%;
  }

  &.h3 {
    font-size: 150%;
  }

  &.h4 {
    font-size: 125%;
  }

  &.h5 {
    font-size: 115%;
  }

  .default-text {
    font-weight: normal;
  }
`;

export const H1 = ({ className, skeleton, ...props }: any) => {
  if (skeleton) {
    return (
      <Skeleton>
        <TitleStyle className={`h1 ${className ? className : ''}`} ellipsis>
          &nbsp;
        </TitleStyle>
      </Skeleton>
    );
  } else {
    return (
      <TitleStyle
        className={`h1 ${className ? className : ''}`}
        {...props}
        ellipsis
      />
    );
  }
};

export const H2 = ({ className, skeleton, ...props }: any) => {
  if (skeleton) {
    return (
      <Skeleton>
        <TitleStyle
          className={`h2 ${className ? className : ''}`}
          level={2}
          ellipsis
        >
          &nbsp;
        </TitleStyle>
      </Skeleton>
    );
  } else {
    return (
      <Title
        className={`h2 ${className ? className : ''}`}
        ellipsis
        level={2}
        {...props}
      />
    );
  }
};

export const H3 = ({ className, skeleton, ...props }: any) => {
  if (skeleton) {
    return (
      <Skeleton>
        <TitleStyle
          className={`h3 ${className ? className : ''}`}
          level={2}
          ellipsis
        >
          &nbsp;
        </TitleStyle>
      </Skeleton>
    );
  } else {
    return (
      <Title
        className={`h3 ${className ? className : ''}`}
        ellipsis
        level={3}
        {...props}
      />
    );
  }
};

export const H4 = ({ className, skeleton, ...props }: any) => {
  if (skeleton) {
    return (
      <Skeleton>
        <TitleStyle
          className={`h4 ${className ? className : ''}`}
          level={4}
          ellipsis
        >
          &nbsp;
        </TitleStyle>
      </Skeleton>
    );
  } else {
    return (
      <TitleStyle
        className={`h4 ${className ? className : ''}`}
        level={4}
        ellipsis
        {...props}
      />
    );
  }
};

export const H5 = ({ className, skeleton, ...props }: any) => {
  if (skeleton) {
    return (
      <Skeleton>
        <TitleStyle
          className={`h5 ${className ? className : ''}`}
          level={5}
          ellipsis
        >
          &nbsp;
        </TitleStyle>
      </Skeleton>
    );
  } else {
    return (
      <TitleStyle
        className={`h5 ${className ? className : ''}`}
        ellipsis
        level={5}
        {...props}
      />
    );
  }
};

export const Paragraph = (props: any) => {
  return <ParagraphAntd className={'paragraph'} {...props} />;
};

export const Text = (props: any) => {
  return <TextAntd className={'text-content'} {...props} />;
};
