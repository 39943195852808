import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { SessionAPI } from '@services/private/SessionAPI';
import { UserRes } from '@modules/users/services/model';
import { StudentInfoStatic } from '@components/info/StudentInfo';
import { useTranslation } from 'react-i18next';
import { H3, H4 } from '@components/typography';
import { InvitedStudentForm } from '@components/form/InviteStudentForm';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import ButtonGroup from '@components/button/ButtonGroup';
import { SessionRes, SessionStudentRes } from '@services/model/session';
import { ClassRosterRes } from '@modules/users/services/clazz';
import { SelectClassRoster } from '@components/common/student-selector/SelectClassRoster';

export const ViewSessionStudent = (props: {
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean) => void;
  onActive?: () => void;
}) => {
  const { t } = useTranslation();

  const [students, setStudents] = useState<UserRes[]>([]);

  useEffect(() => {
    if (props.sessionData.students) {
      setStudents(props.sessionData.students);
    }
  }, [props.sessionData]);

  useEffect(() => {
    if (props.onActive) {
      props.onActive();
    }
  }, [props.onActive]);

  return (
    <InvitedStudentForm>
      <div className={'invite-student-group'}>
        <H4>{t('session.accepted_students')}:</H4>

        <List
          bordered
          dataSource={students}
          renderItem={(item) => (
            <List.Item>
              <StudentInfoStatic
                key={item.userId}
                selected={true}
                item={item}
              />
            </List.Item>
          )}
        />
      </div>
    </InvitedStudentForm>
  );
};

export default function ShareSessionToStudentForm(props: {
  sessionId: number;
  warningResult: (isSuccess: boolean) => void;
  onActive?: (title: string) => void;
}) {
  const { t } = useTranslation();

  const [getStudents, { data, isSuccess }] =
    SessionAPI.endpoints.getStudents.useLazyQuery({});

  const [addStudent] = SessionAPI.endpoints.addStudent.useMutation({});
  const [removeStudent] = SessionAPI.endpoints.removeStudent.useMutation({});

  const [viewType, setViewType] = useState('view');
  const [students, setStudents] = useState<SessionStudentRes[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);

  useEffect(() => {
    if (props.sessionId != null && props.sessionId > 0) {
      reloadData();
    }
  }, [props.sessionId]);

  useEffect(() => {
    if (data && isSuccess) {
      setStudents(data.content);
    }
  }, [data]);

  useEffect(() => {
    if (props.onActive) {
      props.onActive(t('session.students') as string);
    }
  }, []);

  useEffect(() => {
    const selectedStudents = students.map((item) => {
      return item.user.userId;
    });
    setSelectedStudents(selectedStudents);
  }, [students]);

  const reloadData = () => {
    getStudents(props.sessionId);
  };

  const handleSelectData = (data: ClassRosterRes) => {
    const request = {
      sessionId: props.sessionId,
      classRosterId: data.classRosterId,
    };

    addStudent(request)
      .unwrap()
      .then(() => {
        reloadData();
        props.warningResult(true);
      })
      .catch(() => {
        props.warningResult(false);
      });
  };

  const handleClickRemove = (userId: number) => {
    const request = {
      sessionId: props.sessionId,
      userId: userId,
    };

    removeStudent(request)
      .unwrap()
      .then(() => {
        reloadData();
        props.warningResult(true);
      })
      .catch(() => {
        props.warningResult(false);
      });
  };

  return (
    <InvitedStudentForm>
      {viewType === 'view' ? (
        <div className={'invite-student-group'}>
          <HeaderGroup>
            <H3>Participants:</H3>

            <Button
              shape={'round'}
              size={'small'}
              type={'primary'}
              icon={IconUtils.add_new}
              onClick={() => setViewType('edit')}
            >
              Add
            </Button>
          </HeaderGroup>

          <List
            bordered
            dataSource={students}
            renderItem={(item) => (
              <List.Item>
                <StudentInfoStatic
                  key={item.user.userId}
                  selected={true}
                  item={item.user}
                  onRemove={handleClickRemove}
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <div className={'invite-student-group'}>
          <H4>Select Student:</H4>

          <SelectClassRoster
            selectIds={selectedStudents}
            onSelect={handleSelectData}
            onRemove={handleClickRemove}
          />

          <div className={'submit-container'}>
            <ButtonGroup type={'right'}>
              <Button onClick={() => setViewType('view')}>
                {t('button.close')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </InvitedStudentForm>
  );
}
