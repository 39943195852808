import { ReactNode } from 'react';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';

export const ClassRoomHost = (props: { children: ReactNode }) => {
  const { session } = useClassRoomContext();

  if (session.isHost) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const ClassRoomParticipant = (props: { children: ReactNode }) => {
  const { session } = useClassRoomContext();

  if (!session.isHost) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};
