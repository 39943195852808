import { createSlice } from '@reduxjs/toolkit';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { ResourceNavigationProps } from '@cms/lesson-template/context/ViewLessonContext';
import { CompMode } from '@cms/ComponentInteface';
import { getQuestionNavigation } from '@cms/utils/ComposeResourceUtils';
import {
  LessonRes,
  LessonSectionRes,
} from '@modules/product/services/lesson_model';

export enum ComposeLessonActionEnum {
  default = '',
  refresh = 'refresh',
}

export interface ComposeLessonActionProps {
  action: ComposeLessonActionEnum;
  params: any;
  timestamp: number;
}

export interface EditLessonProps {
  ready: boolean;
  uuid: string;

  productId: number;
  productTocId: number;
  lessonData: LessonRes;
  navigation: ResourceNavigationProps;
  sections: LessonSectionRes[];
  resources: ResourceProps[];

  action: ComposeLessonActionProps;
  warningMsg: { success: boolean | null; timestamp: number | null };
}
const initialState: EditLessonProps = {
  ready: false,
  uuid: 'waiting',
  productId: -1,
  productTocId: -1,
  lessonData: {} as LessonRes,
  sections: [],
  resources: [],

  navigation: {
    current: {} as ResourceProps | null,
    currentId: -1,
    currentIndex: -1,
    currentData: { resourceId: -1, sectionId: -1 },
    previous: { resourceId: -1, sectionId: -1 },
    next: { resourceId: -1, sectionId: -1 },
    first: false,
    last: false,
  },

  action: {
    action: ComposeLessonActionEnum.default,
    params: '',
    timestamp: DateAndTimeUtils.getCurrentTime(),
  } as ComposeLessonActionProps,
  warningMsg: { success: null, timestamp: null },
};

const composeLesson = createSlice({
  name: 'composeLesson',
  initialState,

  reducers: {
    initData(
      state,
      action: {
        payload: {
          lesson: LessonRes;
          resourceId: number;
          productId?: number;
          productTocId?: number;
        };
      }
    ) {
      state.ready = true;

      if (action.payload.productId && Number(action.payload.productId) > 0) {
        state.productId = Number(action.payload.productId);
      } else {
        state.productId = -1;
      }

      if (
        action.payload.productTocId &&
        Number(action.payload.productTocId) > 0
      ) {
        state.productTocId = Number(action.payload.productTocId);
      } else {
        state.productTocId = -1;
      }

      state.lessonData = action.payload.lesson;

      if (
        action.payload.lesson.sections &&
        action.payload.lesson.sections.length > 0
      ) {
        state.sections = action.payload.lesson.sections;
        state.resources = ResourceUtils.getResources(
          action.payload.lesson.sections
        );
        state.navigation = getQuestionNavigation(
          action.payload.lesson.sections,
          action.payload.resourceId,
          CompMode.COMPOSE
        );
      } else {
        state.sections = [];
        state.resources = [];
        state.navigation = getQuestionNavigation(
          [],
          action.payload.resourceId,
          CompMode.COMPOSE
        );
      }
    },

    viewQuestionById(state, action: { payload: number }) {
      state.navigation = getQuestionNavigation(
        state.sections,
        action.payload,
        CompMode.COMPOSE
      );
    },

    updateMetadata(state, action: { payload: LessonRes }) {
      state.lessonData = action.payload;
    },

    dispatchComposeLessonAction(
      state,
      action: {
        payload: {
          action: ComposeLessonActionEnum;
          params: any;
        };
      }
    ) {
      state.action = {
        action: action.payload.action,
        params: action.payload.params,
        timestamp: DateAndTimeUtils.getCurrentTime(),
      };
    },

    warningMessage(state, action: { payload: boolean }) {
      if (action.payload) {
        state.warningMsg = {
          success: action.payload,
          timestamp: DateAndTimeUtils.getCurrentTime(),
        };
      }
    },

    resetComposeLesson(state) {
      Object.assign(state, initialState);
    },
  },
});

const { actions, reducer } = composeLesson;
export const {
  initData,
  viewQuestionById,
  dispatchComposeLessonAction,
  updateMetadata,
  warningMessage,
  resetComposeLesson,
} = actions;
export default reducer;
