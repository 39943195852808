import { SessionTaskInstanceRes } from '@services/model/session';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { SessionAPI } from '@services/private/SessionAPI';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import React, { useState } from 'react';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';

export const ViewStudentExercise = (props: {
  taskInstance: SessionTaskInstanceRes;
}) => {
  const { session, sessionTaskId, onSubmitTask } = useClassRoomContext();
  const [newState, setNewState] = useState(props.taskInstance);

  const [completeTask] = SessionAPI.endpoints.completeTask.useMutation({});

  const onSubmit = (exercise: ExerciseRes) => {
    completeTask({
      sessionId: session.sessionId,
      taskId: sessionTaskId,
      taskInstanceId: props.taskInstance.sessionTaskInstanceId,
    })
      .unwrap()
      .then((result) => {
        setNewState(result);

        onSubmitTask(
          sessionTaskId,
          props.taskInstance.sessionTaskInstanceId,
          exercise.exerciseId
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ViewExercisePage
      key={newState.sessionTaskInstanceId + '-' + newState.status}
      type={CompMode.DO_EXERCISE}
      exerciseId={props.taskInstance.exercise.exerciseId}
      setting={{
        lessonType: LessonTypeEnum.lesson,
        config: {
          singleMode: false,
          singleQuestionMode: false,
          stepByStep: false,
          layout: LessonTemplateLayoutEnum.responsive,
        },
      }}
      trigger={{
        onSubmit: onSubmit,
      }}
    />
  );
};
