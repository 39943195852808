import { IconUtils } from '@utils/IconUtils';
import { Button, Dropdown } from 'antd';
import React from 'react';
import { SurveyActionEnum } from '@modules/assignments/container/survey/ManageSurveyTable';
import { useTranslation } from 'react-i18next';
import { SurveyRes } from '@modules/assignments/service/survey_model';

export const SurveyAction = ({
  item,
  onEdit,
  onPublish,
  onViewReport,
  onClose,
}: {
  onEdit: (surveyId: number) => void;
  onPublish: (surveyId: number) => void;
  onViewReport: (surveyId: number) => void;
  onClose: (surveyId: number) => void;
  item: SurveyRes;
}) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: SurveyActionEnum.update,
            label: t('label.update'),
            icon: IconUtils.actions.edit,
            onClick: () => onEdit(item.surveyId),
          },
          {
            key: SurveyActionEnum.share,
            label: t('survey.actions.publish'),
            icon: IconUtils.actions.share,
            onClick: () => onPublish(item.surveyId),
          },
          {
            key: SurveyActionEnum.view_report,
            label: t('survey.actions.view_response'),
            icon: IconUtils.actions.report,
            onClick: () => onViewReport(item.surveyId),
          },
          {
            key: 'divider-1',
            type: 'divider',
          },
          {
            key: SurveyActionEnum.close,
            label: t('survey.actions.close'),
            icon: IconUtils.actions.disabled,
            onClick: () => onClose(item.surveyId),
          },
        ],
      }}
      placement="topRight"
      arrow
    >
      <Button shape="circle" type={'default'} icon={IconUtils.more_icon} />
    </Dropdown>
  );
};
