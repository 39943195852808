import { SurveyAPI } from '@modules/assignments/service/SurveyAPI';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import { DateLabel } from '@components/text/DateLabel';
import { notification } from 'antd';
import Card, { CardClassEnum } from '@components/card';
import { Text } from '@components/typography';
import { ResponsiveScreen } from '@hooks/useMedia';
import TablePagination from '@components/table/TablePagination';
import { LessonDisplay } from '@components/product/LessonDisplay';
import { SurveyAction } from '@modules/assignments/components/survey/SurveyAction';
import { InputGroup } from '@components/input/InputGroup';
import { EntityWithStatus } from '@components/status/EntityStatus';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { CreateSurveyForm } from '@modules/assignments/container/survey/CreateSurveyForm';
import { SiteMap } from '@router/SiteMap';
import { useRouter } from '@hooks/useRouter';
import { EntityStatusEnum } from '@services/model/common';
import { SurveyRes } from '@modules/assignments/service/survey_model';

export enum SurveyActionEnum {
  default = '',
  create = 'create-survey',
  update = 'update-survey',
  close = 'close-survey',
  share = 'share-survey',
  view_report = 'view-report',
}

export const ManageSurveyTable = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const router = useRouter();

  const [findAll, { data, isFetching }] =
    SurveyAPI.endpoints.findAll.useLazyQuery();

  const [publishSurvey] = SurveyAPI.endpoints.publishSurvey.useMutation({});
  const [closeSurvey] = SurveyAPI.endpoints.closeSurvey.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
  });

  const [action, setAction] = useState({
    action: SurveyActionEnum.default,
    params: -1,
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  const refreshData = () => {
    findAll(params);
  };

  const changeAction = (action: SurveyActionEnum, surveyId?: number) => {
    setAction({
      action: action,
      params: surveyId ?? -1,
    });
  };

  const columns = [
    {
      title: t('survey.label.lesson'),
      dataIndex: 'lesson',
      key: 'lesson',
      width: 320,
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 180,
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.publishedDate'),
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      width: 180,
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const onEdit = (surveyId: number) => {
    changeAction(SurveyActionEnum.update, surveyId);
  };

  const onViewReport = (surveyId: number) => {
    router.push(SiteMap.survey.report_gen(surveyId));
  };

  const onPublish = (surveyId: number) => {
    confirm(
      'confirm',
      t('header.confirm'),
      t('survey.warning.confirm_publish_survey'),
      t('survey.actions.publish'),
      t('label.cancel'),
      (result) => {
        if (result) {
          publishSurvey(surveyId)
            .unwrap()
            .then(() => {
              refreshData();
            })
            .catch((e) => {
              notification.error({
                message: t('header.error'),
                description: t(e.data.error_description),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const onClose = (surveyId: number) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('survey.warning.confirm_close_survey'),
      t('survey.actions.close'),
      t('label.cancel'),
      (result) => {
        if (result) {
          closeSurvey(surveyId)
            .unwrap()
            .then(() => {
              refreshData();
            })
            .catch((e) => {
              notification.error({
                message: t('header.error'),
                description: t(e.data.error_description),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const onCancel = () => {
    changeAction(SurveyActionEnum.default, -1);
  };

  const processDataRow = (item: SurveyRes) => {
    return {
      key: item.surveyId,
      lesson: <LessonDisplay item={item.lesson} />,
      name: <SurveyName item={item} />,
      createdDate: <DateLabel label={item.createdDate} />,
      publishedDate: <DateLabel label={item.publishedDate} />,
      actions: (
        <SurveyAction
          item={item}
          onEdit={onEdit}
          onPublish={onPublish}
          onClose={onClose}
          onViewReport={onViewReport}
        />
      ),
    };
  };

  const renderMobile = (item: SurveyRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <div className={'survey-mobile-item'}>
          <div className={'assignment-group-info'}>
            <SurveyName item={item} />
          </div>

          <InputGroup label={t('survey.label.lesson')}>
            <LessonDisplay item={item.lesson} />
          </InputGroup>

          <InputGroup label={t('label.createdDate')}>
            <DateLabel label={item.createdDate} />
          </InputGroup>

          <InputGroup label={t('label.publishedDate')}>
            <DateLabel label={item.publishedDate} />
          </InputGroup>

          <div className={'assignment-group-actions'}>
            <SurveyAction
              item={item}
              onEdit={onEdit}
              onPublish={onPublish}
              onClose={onClose}
              onViewReport={onViewReport}
            />
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className={'manage-survey-table'}>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={setParams}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === SurveyActionEnum.update && (
        <CustomModal
          header={'Create survey'}
          className={CustomModalClassEnum.full_size_modal}
          closable={false}
          maskClosable={false}
          content={
            <>
              <CreateSurveyForm
                lessonId={-1}
                surveyId={action.params}
                onSuccess={onCancel}
                onCancel={onCancel}
              />
            </>
          }
          onCloseFunc={onCancel}
        />
      )}
    </div>
  );
};

const SurveyName = ({ item }: { item: SurveyRes }) => {
  return (
    <div className={'survey-name'}>
      <EntityWithStatus status={item.status}>
        <b>{item.name}</b>
      </EntityWithStatus>

      {item.status === EntityStatusEnum.PUBLISHED && (
        <Text
          copyable={{
            text:
              window.location.origin +
              SiteMap.public.view_survey_gen(
                item.surveyId,
                item.name,
                item.accessCode
              ),
          }}
        >
          &nbsp;
        </Text>
      )}
    </div>
  );
};
