import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import { WelComeStyle } from '@modules/public/HomePage';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { IconUtils } from '@utils/IconUtils';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';

const RegisterSuccess = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout className={'register-success-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('registration.success')}
        description={t('registration.success')}
      />

      <WelComeStyle>
        <div className={'home-block-container'}>
          <Result
            status="success"
            title={t('registration.success')}
            subTitle={t('registration.warning.success')}
            extra={[
              <NavLink href={SiteMap.auth.login} key={'login'}>
                <Button type="primary" shape={'round'}>
                  {IconUtils.login} {t('menu.login')}
                </Button>
              </NavLink>,
            ]}
          />
        </div>
      </WelComeStyle>
    </PublicLayout>
  );
};

export default RegisterSuccess;
