import { useEffect } from 'react';

import {
  logout,
  changeToLogout,
  logoutAllTabs,
} from '@app/redux/slices/profileSlice';
import { useDispatch, useSelector } from '@app/redux/hook';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';
import { MyProfileAPI } from '@services/private/MyProfileAPI';

// require login : only visit page if user is logon. If not -> redirect to Login page.
// access_token: if access_token is provided, we should load login data. The login return

const useLogin = (requireLogin?: boolean, accessToken?: string) => {
  const dispatch = useDispatch();
  const { onGetUserSuccess } = useLoginSuccess();

  const { isAuth, isReady } = useSelector((state) => state.profile);

  const [loadUserProfile, { data, isSuccess, error, isError }] =
    MyProfileAPI.endpoints.loadUserProfile.useLazyQuery();

  useEffect(() => {
    logoutAllTabs();
  }, []);

  useEffect(() => {
    if (!isAuth && !isReady) {
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        loadUserProfile({});
      } else if (localStorage.getItem('accessToken')) {
        loadUserProfile({});
      }
    }
  }, [isAuth, isReady, accessToken]);

  useEffect(() => {
    if (data && isSuccess) {
      onGetUserSuccess(data);
    }
  }, [data]);

  useEffect(() => {
    if (error && isError) {
      if (requireLogin) {
        dispatch(logout());
      } else {
        dispatch(changeToLogout());
      }
    }
  }, [error]);
};

export default useLogin;
