import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import React, { useEffect, useState } from 'react';

import { H4 } from '@components/typography';
import { updateComponents } from '@app/redux/slices/composeResource';
import { HintText } from '@components/text/HintText';
import Alert from 'antd/lib/alert';
import { Tabs } from 'antd';
import ResourceViewItem from '@cms/ResourceViewItem';
import { CompProps } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { LessonA4TemplateEditable } from '@modules/product/components/lesson/lesson-template/A4Template';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '@app/redux/hook';

export const EditResourceJSON = (props: { onClose: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newComponents, originalResource } = useSelector(
    (state) => state.composeResource
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [components, setComponents] = useState<CompProps[]>([]);

  useEffect(() => {
    setInputValue(JSON.stringify(newComponents, null, 2));
    setComponents([...newComponents]);
  }, [newComponents]);

  const handleUpdateComponent = () => {
    try {
      const newComponents = JSON.parse(inputValue);
      dispatch(updateComponents(newComponents));
      props.onClose();
    } catch (e) {
      setErrorMessage('Cannot parse input value' + e);
    }
  };

  const handleOnInputChange = (event: any) => {
    setInputValue(event.target.value);
    try {
      const newComponents = JSON.parse(inputValue);
      setComponents(newComponents);
    } catch (e) {
      setComponents([]);
      setErrorMessage('Cannot parse input value' + e);
    }
  };

  const revertDataToDefault = () => {
    setInputValue(JSON.stringify(newComponents, null, 2));
    setErrorMessage('');
  };

  const items = [
    {
      label: t('resource.component_data'),
      key: 'component-data',
      children: (
        <div className={'component-data'}>
          <H4>{t('resource.component_data')}</H4>
          <textarea
            value={inputValue}
            style={{
              resize: 'vertical',
              minHeight: '25em',
              width: '100%',
              border: '1px solid #666',
              padding: '1em',
              marginBottom: '2em',
            }}
            onChange={handleOnInputChange}
          />

          <ButtonGroup type={'center'}>
            <Button
              type={'primary'}
              shape={'round'}
              onClick={handleUpdateComponent}
            >
              {t('button.update')}
            </Button>
            <Button type={'default'} shape={'round'} onClick={props.onClose}>
              {t('button.cancel')}
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
    {
      label: t('resource.review_component'),
      key: 'component-review',
      children: (
        <div className={'component-review'}>
          <H4>{t('resource.review_component')}</H4>

          <div className={'component-review-container'}>
            <LessonA4TemplateEditable>
              <ResourceViewItem
                resourceId={originalResource.resourceId}
                components={components}
              />
            </LessonA4TemplateEditable>
          </div>

          <ButtonGroup type={'center'}>
            <Button
              type={'primary'}
              shape={'round'}
              onClick={handleUpdateComponent}
            >
              {t('button.update')}
            </Button>
            <Button type={'default'} shape={'round'} onClick={props.onClose}>
              {t('button.cancel')}
            </Button>
          </ButtonGroup>
        </div>
      ),
    }, // remember to pass the key prop
  ];

  return (
    <EditResourceJSONStyle className={'resource-data-container'}>
      {errorMessage != null && errorMessage !== '' && (
        <Alert
          type={'error'}
          message={'Error!'}
          closable={true}
          description={
            <>
              <HintText>{errorMessage}</HintText>

              <p>{t('resource.close_to_reset_data')}</p>
            </>
          }
          onClose={revertDataToDefault}
        />
      )}
      <Tabs items={items} />
    </EditResourceJSONStyle>
  );
};

const EditResourceJSONStyle = styled.div`
  .lesson-template {
    border: 1px solid #666;
  }

  .button-group {
    margin-top: 2em;
  }
`;
