// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDfttLxnkwEzzEGgG6EeRvQV1Zd6aE_2OM',
  authDomain: 'gstudypro-ea33b.firebaseapp.com',
  databaseURL:
    'https://gstudypro-ea33b-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'gstudypro-ea33b',
  storageBucket: 'gstudypro-ea33b.appspot.com',
  messagingSenderId: '42826197972',
  appId: '1:42826197972:web:ff564a893d60bb47748441',
  measurementId: 'G-8ZSPN37LTZ',
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const authorize = getAuth(firebase);
export const database = getDatabase(firebase);
export const firestore = getFirestore(firebase);

/*
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyASYLJjKYY0BcHdd2s85Zp6NqX_wlbrxFY',
  authDomain: 'poetic-archway-346018.firebaseapp.com',
  projectId: 'poetic-archway-346018',
  storageBucket: 'poetic-archway-346018.appspot.com',
  messagingSenderId: '361987602466',
  appId: '1:361987602466:web:26ff9cc1aaa146b412327b',
  measurementId: 'G-GM7DW001KY',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/
