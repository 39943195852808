import React, { useEffect } from 'react';
import { message } from 'antd';

import { resetComposeLesson } from '@app/redux/slices/composeLesson';
import { useTranslation } from 'react-i18next';

import ComposeSimpleLessonPage from '@modules/product/components/lesson/pages/ComposeSimpleLessonPage';
import { useDispatch, useSelector } from '@app/redux/hook';

const ComposeCollectionPage = (props: {
  from: string;
  lessonId?: number;
  lessonExampleId?: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { warningMsg } = useSelector((state) => state.composeLesson);

  useEffect(() => {
    return () => {
      dispatch(resetComposeLesson());
    };
  }, [props.lessonId]);

  useEffect(() => {
    if (warningMsg && warningMsg.timestamp && warningMsg.success != null) {
      warningMessage(warningMsg.success);
    }
  }, [warningMsg]);

  const warningMessage = (isSuccess: boolean) => {
    if (isSuccess) {
      message.info({
        content: t('resource.warning.update_success'),
        className: 'bottom-center-message',
        duration: 2,
        style: {
          position: 'fixed',
          bottom: 10,
          right: 10,
        },
      });
    } else {
      message.error({
        content: t('resource.warning.update_error'),
        className: 'bottom-center-message',
        duration: 2,
        style: {
          position: 'fixed',
          bottom: 10,
          right: 10,
        },
      });
    }
  };

  return (
    <ComposeSimpleLessonPage
      from={props.from}
      lessonId={props.lessonId}
      lessonExampleId={props.lessonExampleId}
    />
  );
};

export default ComposeCollectionPage;
