import styled from 'styled-components';
import { Layout } from 'antd';
import { ReactNode } from 'react';
const { Sider, Content, Footer } = Layout;

export const max_bank_size = 400;
export const max_question_bank = 500;

export const LessonBankLayout = (props: {
  className: string;
  width?: number;
  children: ReactNode;
}) => {
  return (
    <LessonBankStyle
      className={props.className}
      width={props.width ?? max_bank_size}
      style={{ minWidth: props.width ?? max_bank_size }}
    >
      {props.children}
    </LessonBankStyle>
  );
};

export const ClassRoomLayout = styled(Layout)`
  position: relative;
  width: 100vw;
  height: 100vh;

  border: 3px solid #ccc;

  &.recording {
    border: 3px solid ${(props) => props.theme.app.primary};
  }
`;

export const ComposeLayout = styled(Layout)`
  position: relative;
  margin-left: ${max_bank_size}px;
  height: 100vh;

  &.compose-multiple-page,
  &.compose-single-question {
    .cms-comp {
      border: 2px dotted #cccccc;

      &:hover {
        border: 2px solid ${(props) => props.theme.app.primary};
      }
    }
  }

  &.compose-build-question-page {
    margin-left: ${max_question_bank}px;
  }

  .lesson-template-header {
    display: none;

    p {
      margin-bottom: 0;
    }

    &.lesson-header {
      display: flex;
      align-items: center;
      margin-bottom: 1em;

      .lesson-title-display {
        margin-right: 1ch;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .toggle-lesson-button {
    position: absolute;
    top: 12px;
    left: 24px;
    right: 24px;
  }

  &.compose-wysi-page {
    margin-left: 0px;

    .compose-content-layout {
      padding-bottom: 60px;

      .exercise-content-display {
        aspect-ratio: unset !important;
      }
    }

    .lesson-layout {
      aspect-ratio: 16 / 9;
      display: flex;
      align-items: center;

      .lesson-content {
        width: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;

        .lesson-content-editable {
          width: 100%;
        }
      }
    }

    .lesson-template-header {
      display: block;

      p {
        margin-bottom: 0;
      }
    }

    .compose-footer-layout {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.prevent-interact {
    .view-lesson-content-container,
    .annotation-workspace {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }
`;

export const ComposeContentLayout = styled(Content)`
  overflow-y: auto;

  .screen-sm & {
    .exercise-content-display,
    .resource-validation-table,
    .compose-navigation-toolbar {
      max-width: 100%;
    }
  }

  .exercise-content-display {
    max-width: 80%;
    margin: 80px auto 30px;
    background: #fff;
    border: 1px solid #ccc;
    position: relative;
  }

  .resource-validation-table {
    max-width: 80%;
    margin: 1em auto 3em;
  }

  .compose-navigation-toolbar {
    max-width: 80%;
    margin: 1em auto 3em;
  }
`;

export const ComposeFooterLayout = styled(Footer)`
  position: sticky;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0;
`;

export const ComposeToolbarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;

  border-top: 3px solid ${(props) => props.theme.app.primary};
  background: ${(props) => props.theme.app.background};

  .left-group {
    > * {
      margin-right: 1em;
    }
  }

  .toolbar-group.center-group {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-btn {
    &:not([disabled]) {
      &.ant-btn-primary {
        &:not(.ant-btn-dangerous) {
          color: ${(props) => props.theme.color.white};
          background: ${(props) => props.theme.app.primary};
          border-color: ${(props) => props.theme.app.primary};
        }
      }
    }
  }
`;

const LessonBankStyle = styled(Sider)`
  &.ant-layout-sider {
    background: transparent;
    box-shadow: 0 12px 30px rgb(80 143 244 / 10%);
    transition: 0.3s;
    padding: 6px 12px;
    border-right: 1px solid #ccc;

    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
`;
