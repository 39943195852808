import { RoleEnum } from '@app/redux/slices/roles';
import Authorization from '@utils/authorization';
import React from 'react';
import { ClassTeacherSession } from '@modules/users/component/classes/session/ClassTeacherSession';
import { ClassStudentSession } from '@modules/users/component/classes/session/ClassStudentSession';

export const ClassSession = (props: { classId: number }) => {
  return (
    <div className={'class-session'}>
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.CREATE_CALENDAR, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <ClassTeacherSession classId={props.classId} />
      </Authorization>

      <Authorization
        type={'ifNotGranted'}
        roles={[RoleEnum.CREATE_CALENDAR, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <ClassStudentSession classId={props.classId} />
      </Authorization>
    </div>
  );
};
