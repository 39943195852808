import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { PdfCompProps } from '@cms/comps/content/PdfComp';

export const StaticPdfData: PdfCompProps = {
  id: 'simple_pdf_comp',
  type: CompTypeEnum.PDF,

  configuration: {
    content: {
      id: 'simple_pdf_comp',
      type: AssetsTypeEnum.PDF,
      data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/4iGq4uEC5A8IWtfb.sample_pdf.pdf',
    },
  },
};
