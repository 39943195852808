import React, { ReactNode } from 'react';
import { ExerciseServiceContext } from '@cms/service/ExerciseServiceContext';

export const PresentServiceProvider = (props: { children: ReactNode }) => {
  return (
    <ExerciseServiceContext.Provider
      value={{
        isLoading: false,
        params: -1,

        lessonId: -1,
        exerciseId: -1,

        viewStatus: () => {},

        onSave: () => {},
        onSubmit: () => {},
        onGrading: () => {},

        viewScores: () => {},
      }}
    >
      {props.children}
    </ExerciseServiceContext.Provider>
  );
};
