import { ResourceContextProvider } from '@cms/context/ResourceContextProvider';
import { CompMode } from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import styled from 'styled-components';
import { LessonSectionAPI } from '@modules/product/services/LessonSectionAPI';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { ExerciseResource } from '@cms/lesson-template/exercise/ExerciseResource';
import { useLessonEditableContext } from '@cms/context/LessonEditableProvider';
import { H4 } from '@components/typography';

export const LessonQuestions = (props: { requireRefresh: number }) => {
  const { lesson } = useLessonEditableContext();

  const [findEmptySection, { data, isSuccess }] =
    LessonSectionAPI.endpoints.findEmptySection.useLazyQuery({});

  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    if (lesson) {
      findEmptySection(lesson.lessonId);
    }
  }, [props.requireRefresh, lesson]);

  useEffect(() => {
    if (data && isSuccess) {
      setResources(
        data.map((res) => {
          return ResourceUtils.convert(res, '', -1);
        })
      );
    }
  }, [data]);

  return (
    <LessonQuestionStyle className={'lesson-question-container'}>
      <DropItem
        acceptType={'question'}
        droppableId={''}
        className={'draggable-resource-container show-correct-answer'}
      >
        {resources != null &&
          resources.map((item, index) => {
            return (
              <DragItem
                acceptType={'question'}
                key={item.resourceId + '_' + index}
                draggableId={item.resourceId + ''}
                hidePlaceholder={true}
                index={index}
              >
                <ResourceContextProvider
                  mode={CompMode.REVIEW}
                  resourceId={item.resourceId}
                  comps={item.components}
                  answer={item.correctAnswer}
                >
                  <div className={'question-item'}>
                    <ExerciseResource
                      item={item}
                      questionNumber={item.questionNumber}
                    />
                    <div className={'question-background-event'} />
                  </div>
                </ResourceContextProvider>
              </DragItem>
            );
          })}
      </DropItem>

      {resources != null && resources.length === 0 && (
        <div className={'empty-space'}>
          <H4 className={'no-margin'}>All questions has been placed.</H4>
        </div>
      )}
    </LessonQuestionStyle>
  );
};

const LessonQuestionStyle = styled.div`
  .draggable-resource-container {
    .question-item {
      position: relative;

      .question {
        z-index: 1;
      }

      .question-background-event {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }
  }

  .empty-space {
    margin-top: 30px;
    margin-bottom: 30px;
    background: #ccc;
    border-radius: 8px;
    padding: 1em 2em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
