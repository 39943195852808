import React from 'react';
import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';

import { useTranslation } from 'react-i18next';
import { CompMode } from '@cms/ComponentInteface';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';

const ReviewAssignment = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { id: exerciseId } = router.query as unknown as { id: number };

  return (
    <EmptyLayout className={'review-assignment-page'}>
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('do_assignment.title')}
        description={t('do_assignment.title')}
      />

      <ViewExercisePage
        type={CompMode.REVIEW_ASSIGNMENT}
        exerciseId={Number(exerciseId)}
      />
    </EmptyLayout>
  );
};

export default ReviewAssignment;
