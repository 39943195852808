import React, { useEffect, useState } from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { ExerciseAPI } from '@modules/assignments/service/ExerciseAPI';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { InputGroup } from '@components/input/InputGroup';
import { LessonDisplay } from '@components/product/LessonDisplay';
import styled from 'styled-components';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { CompMode } from '@cms/ComponentInteface';
import ButtonGroup from '@components/button/ButtonGroup';

enum ExerciseReportActionEnum {
  default = '',
  view_report = 'view-report',
}

const ExerciseLessonReportPage = (props: { lessonId: number }) => {
  const { t } = useTranslation();

  const [viewExerciseReports, { data, isFetching }] =
    ExerciseAPI.endpoints.viewExerciseReports.useLazyQuery();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'ascend',
  });

  const [action, setAction] = useState({
    action: ExerciseReportActionEnum.default,
    params: -1,
  });

  useEffect(() => {
    viewExerciseReports({
      ...params,
      lessonId: props.lessonId,
    });
  }, [params, props.lessonId]);

  useEffect(() => {
    if (data) {
    }
  }, [data]);

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('assignment.unlockAt'),
      dataIndex: 'unlockAt',
      key: 'unlockAt',
      sorter: true,
      width: 200,
      align: 'center',
    },

    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      align: 'center',
    },
  ];

  const refreshData = (params: PaginationReq) => {
    setParams(params);
  };

  const onViewExercise = (exerciseId: number) => {
    setAction({
      action: ExerciseReportActionEnum.view_report,
      params: exerciseId,
    });
  };

  const onCancel = () => {
    setAction({
      action: ExerciseReportActionEnum.default,
      params: -1,
    });
  };

  const processDataRow = (item: ExerciseRes) => {
    return {
      key: item.exerciseId,
      name: <LessonDisplay item={item.lesson} />,
      unlockAt: <DateAndTimeLabel type={'block'} label={item.createdDate} />,
      actions: (
        <Button
          type={'primary'}
          shape={'round'}
          icon={IconUtils.actions.report}
          onClick={() => onViewExercise(item.exerciseId)}
        >
          {t('button.report')}
        </Button>
      ),
    };
  };

  const renderMobile = (item: ExerciseRes) => {
    return (
      <Card
        hoverable
        className={`${CardClassEnum.rectangle_box} show-correct-answer`}
      >
        <ExerciseItemStyle className={'exercise-report-item'}>
          <InputGroup>
            <LessonDisplay item={item.lesson} />
          </InputGroup>

          <InputGroup label={t('assignment.unlockAt')}>
            <DateAndTimeLabel type={'inline'} label={item.createdDate} />
          </InputGroup>

          <div className={'exercise-action'}>
            <Button
              type={'primary'}
              shape={'round'}
              icon={IconUtils.actions.report}
              onClick={() => onViewExercise(item.exerciseId)}
            >
              {t('button.report')}
            </Button>
          </div>
        </ExerciseItemStyle>
      </Card>
    );
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>Exercise Reports</H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === ExerciseReportActionEnum.view_report && (
        <CustomModal
          header={t('exercise.actions.review')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ViewExerciseStyle className={'view-exercise-result'}>
              <ViewExercisePage
                type={CompMode.REVIEW_EXERCISE}
                exerciseId={action.params}
              />

              <div className={'submit-container'}>
                <ButtonGroup type={'center'}>
                  <Button
                    size={'large'}
                    shape={'round'}
                    type={'primary'}
                    danger
                    onClick={onCancel}
                  >
                    {t('button.close')}
                  </Button>
                </ButtonGroup>
              </div>
            </ViewExerciseStyle>
          }
          onCloseFunc={onCancel}
        />
      )}
    </>
  );
};

export default ExerciseLessonReportPage;

const ExerciseItemStyle = styled.div`
  position: relative;

  .exercise-action {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const ViewExerciseStyle = styled.div``;
