import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';

import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
} from '@cms/ComponentInteface';
import { CompGroupEnum } from '@cms/utils/CompProps';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import {
  AudioOptionProps,
  AudioRecordingAnswerProps,
  AudioRecordingProps,
} from '@cms/comps/recording/AudioRecordingComp';

const AudioRecordingCompContext = createContext({
  modifiedTime: -1 as number,
  disabled: false as boolean,

  originalData: {} as AudioRecordingProps,
  setting: {} as CompInteractSettingProps,
  sourceItems: [] as AudioOptionProps[],

  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  updateOptions: (options: AudioOptionProps[], type: CompGroupEnum) => {
    console.log(options, type);
  },
  updateAnswers: (answers: string[]) => {
    console.log(answers);
  },
  handleOnAnswerChange: (value: string) => {
    console.log(value);
  },
  updateComponent: () => {},
});

export const AudioRecordingCompContextProvider = (props: {
  disabled: boolean;
  item: AudioRecordingProps;
  answer: AudioRecordingAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();

  const [answer, setCompAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedback, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (props.answer != null && props.answer.answer) {
      setCompAnswer({
        value: props.answer && props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: [],
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  const handleOnAnswerChange = (answer: string) => {
    setCompAnswer((prev) => {
      const indexGroup = answer.split(':')[0];

      const isExits = [...prev.value].some((ans) => {
        return ans.split(':')[0] === indexGroup;
      });

      if (isExits) {
        const newAns = [...prev.value].map((ans) => {
          if (ans.split(':')[0] === indexGroup) {
            return answer;
          } else {
            return ans;
          }
        });

        return {
          value: newAns,
          triggerChange: true,
        };
      } else {
        return {
          value: [...prev.value, answer],
          triggerChange: true,
        };
      }
    });
  };

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );

  useEffect(() => {
    setSourceItems(props.item.configuration.sourceItems);
  }, [props.item]);

  const updateOptions = (options: AudioOptionProps[]) => {
    setSourceItems(options);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer.value,
        },
      })
    );
  };

  const updateAnswers = (newAnswers: string[]) => {
    setCompAnswer({
      value: newAnswers,
      triggerChange: true,
    });
  };

  return (
    <AudioRecordingCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        originalData: props.item,

        sourceItems,
        updateOptions,

        disabled: props.disabled,
        setting: props.item.setting,

        answers: answer.value,
        feedback: feedback,

        updateComponent,
        updateAnswers,
        handleOnAnswerChange,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedback}>
        {props.children}
      </CompFeedbackContextProvider>
    </AudioRecordingCompContext.Provider>
  );
};

export const useAudioRecordingCompContext = () => {
  const context = useContext(AudioRecordingCompContext);
  if (!context) {
    throw new Error('You must wrap container by useAudioRecordingCompContext');
  }
  return context;
};
