import { CompScoringProps } from '@cms/ComponentInteface';
import { WordBoxCompProps } from '@cms/comps/game/word-box/WordBoxComp';

class WordBoxValidation {
  getScoring = (comp: WordBoxCompProps): CompScoringProps => {
    const totalPart = comp.configuration.sourceItems.length;

    return {
      id: comp.id,
      type: comp.type,
      manualScore: false,
      maxScore: totalPart,
    };
  };
}

export const WordBoxValidationUtils = new WordBoxValidation();
