import { Alert, Button, notification, Skeleton } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollToTop from '@components/feature/ScrollToTop';
import PublishSubjectContainer from '@modules/product/container/publish/PubishSubjectContainer';
import PublishCategoryContainer from '@modules/product/container/publish/PubishCategoryContainer';
import PublishGradeContainer from './PubishGradeContainer';
import { useTranslation } from 'react-i18next';
import { ReviewProductContext } from '@modules/product/context/BuildProductContext';
import {
  PublishProductAPI,
  UpdateProductInfoReq,
  ProductInfoRes,
} from '@services/publish/PublishProductAPI';

import { H2 } from '@components/typography';
import Card, { CardClassEnum } from '@components/card';
import { IconUtils } from '@utils/IconUtils';

export default function ProductInformationStep(props: { goNext: () => void }) {
  const { t } = useTranslation();

  const [getProductInfo, { data, isSuccess }] =
    PublishProductAPI.endpoints.getProductInfo.useLazyQuery();

  const [updateProductInfo] =
    PublishProductAPI.endpoints.updateProductInfo.useMutation({});

  const { productId } = useContext(ReviewProductContext);

  const [ready, setReady] = useState(false);
  const [errorMsg, setErrMessage] = useState('');

  const [subjectIds, setSubjectIds] = useState<number[]>([]);
  const [gradeIds, setGradeIds] = useState<number[]>([]);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);

  useEffect(() => {
    setReady(false);
    getProductInfo(productId);
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      updateCurrentData(data);
    }
  }, [data]);

  const updateCurrentData = (data: ProductInfoRes) => {
    if (data.grades != null && data.grades.length > 0) {
      const ids = data.grades.map((item) => {
        return item.gradeId;
      });
      setGradeIds(ids);
    }

    if (data.subjects != null && data.subjects.length > 0) {
      const ids = data.subjects.map((item) => {
        return item.subjectId;
      });
      setSubjectIds(ids);
    }

    if (data.categories != null && data.categories.length > 0) {
      const ids = data.categories.map((item) => {
        return item.categoryId;
      });
      setCategoryIds(ids);
    }

    setReady(true);
  };

  const executeUpdateProductInfo = () => {
    const request: UpdateProductInfoReq = {
      productId: productId,
      subjectIds: subjectIds,
      categoryIds: categoryIds,
      gradeIds: gradeIds,
    };

    updateProductInfo(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('product.warning.update_product_info_success'),
          description: t('product.warning.update_product_info_success_message'),
          placement: 'bottomRight',
        });
        props.goNext();
      })
      .catch(() => {
        notification.error({
          message: t('product.warning.update_product_info_error'),
          description: t('product.warning.update_product_info_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const updateProductInformation = () => {
    if (
      subjectIds.length === 0 ||
      gradeIds.length === 0 ||
      categoryIds.length === 0
    ) {
      setErrMessage(t('publish.basic_info.saving_error'));
    } else {
      executeUpdateProductInfo();
    }
  };

  return (
    <PublishProductStyle className={'publish-product-section'}>
      <ScrollToTop visible={false} />

      <H2>{t('publish.basic_info.header')}</H2>

      {errorMsg && (
        <Alert
          message={t('label.warning')}
          description={errorMsg}
          type="error"
          closable
          onClose={() => setErrMessage('')}
        />
      )}

      {!ready && (
        <>
          <Skeleton avatar paragraph={{ rows: 4 }} />
          <Skeleton avatar paragraph={{ rows: 4 }} />
          <Skeleton avatar paragraph={{ rows: 4 }} />
        </>
      )}

      {ready && (
        <div className={'product-publish-content'}>
          <Card className={CardClassEnum.rectangle_box}>
            <PublishGradeContainer
              ids={gradeIds}
              onChange={(ids: number[]) => setGradeIds(ids)}
            />

            <PublishCategoryContainer
              ids={categoryIds}
              onChange={(ids: number[]) => setCategoryIds(ids)}
            />

            <PublishSubjectContainer
              ids={subjectIds}
              onChange={(ids: number[]) => setSubjectIds(ids)}
            />
          </Card>
        </div>
      )}

      <div className={'product-publish-step'}>
        <Button type={'default'} disabled={true} shape={'round'} size={'large'}>
          {IconUtils.actions.previous} {t('button.step.previous')}
        </Button>

        <Button
          type={'primary'}
          shape={'round'}
          onClick={updateProductInformation}
          size={'large'}
        >
          {t('button.step.next')} {IconUtils.actions.next}
        </Button>
      </div>
    </PublishProductStyle>
  );
}

export const PublishProductStyle = styled.div`
  .product-publish-step {
    display: flex;
    justify-content: space-between;

    margin-top: 2em;
    margin-bottom: 4em;
  }

  .product-publish-content {
    margin-top: 2em;
  }

  .publish-group {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
`;
