import { CompProps, CompTypeEnum } from '@cms/ComponentInteface';

import { AudioCompProps } from '@cms/comps/content/audio/AudioComp';
import { ImageCompProps } from '@cms/comps/content/ImageComp';
import { VideoCompProps } from '@cms/comps/content/VideoComp';
import { DocCompProps } from '@cms/comps/content/DocComp';
import { PdfCompProps } from '@cms/comps/content/PdfComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import {
  AudioContentProps,
  DocContentProps,
  EpubContentProps,
  ImageContentProps,
  PdfContentProps,
  VideoContentProps,
} from '@cms/content/ContentType';
import { EpubCompProps } from '@cms/comps/content/EpubComp';
import { AssetsRes } from '@modules/product/services/assets_model';

export interface ComponentBankUtilsProps {
  generateUID: () => string;

  generateComps(content: AssetsRes): CompProps;
}

class ComponentUtils implements ComponentBankUtilsProps {
  generateUID(): string {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  getABC(index: number): string {
    return 'abcdefghijklmnopqrstuvxyz'.charAt(index);
  }

  getIndexOfABC(char: string): number {
    return 'abcdefghijklmnopqrstuvxyz'.indexOf(char);
  }

  getNumberOfStr(str: string): number {
    return Number(str.replace(/\D/g, ''));
  }

  getStringOfStr(str: string): string {
    const number = str.replace(/\D/g, '');
    return str.replace(number, '');
  }

  insertAtIndex(array: any[], index: number, newItem: any): any[] {
    return [...array.slice(0, index + 1), newItem, ...array.slice(index + 1)];
  }

  removeAtIndex(array: any[], index: number): any[] {
    const arr = [...array];
    arr.splice(index, 1);
    return arr;
  }

  updateAtIndex(array: any[], index: number, newItem: any): any {
    const arr = [...array];
    arr[index] = newItem;
    return arr;
  }

  moveElement<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    const arr = [...array];
    const elAtIndex = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, elAtIndex);
    return arr;
  }

  generateComponent(compData: CompProps): CompProps {
    const result = { ...compData };
    result.id = this.generateUID();
    return result;
  }

  generateComps(content: AssetsRes): CompProps {
    if (content.type === AssetsTypeEnum.IMAGE) {
      return this.generateImageComp(content as ImageContentProps);
    } else if (content.type === AssetsTypeEnum.AUDIO) {
      return this.generateAudioComp(content as AudioContentProps);
    } else if (content.type === AssetsTypeEnum.VIDEO) {
      return this.generateVideoComp(content as VideoContentProps);
    } else if (content.type === AssetsTypeEnum.PDF) {
      return this.generatePDFComp(content as PdfContentProps);
    } else if (content.type === AssetsTypeEnum.DOC) {
      return this.generateDocComp(content as DocContentProps);
    } else if (content.type === AssetsTypeEnum.EPUB) {
      return this.generateEpubComp(content as EpubContentProps);
    } else {
      return this.generateImageComp(content as ImageContentProps);
    }
  }

  private generateImageComp(content: ImageContentProps): ImageCompProps {
    return {
      id: this.generateUID(),
      type: CompTypeEnum.IMAGE,

      configuration: {
        content: content,
      },
    };
  }

  private generateAudioComp(content: AudioContentProps): AudioCompProps {
    return {
      id: this.generateUID(),
      type: CompTypeEnum.AUDIO,

      configuration: {
        content: content,
        sourceItems: [],
      },
      setting: {
        layout: '',
      },
    };
  }

  private generateVideoComp(content: VideoContentProps): VideoCompProps {
    return {
      id: this.generateUID(),
      type: CompTypeEnum.VIDEO,

      configuration: {
        content: content,
      },
    };
  }

  private generatePDFComp(content: PdfContentProps): PdfCompProps {
    return {
      id: this.generateUID(),
      type: CompTypeEnum.PDF,

      configuration: {
        content: content,
      },
    };
  }

  private generateDocComp(content: DocContentProps): DocCompProps {
    return {
      id: this.generateUID(),
      type: CompTypeEnum.WORD,

      configuration: {
        content: content,
      },
    };
  }

  private generateEpubComp(content: EpubContentProps): EpubCompProps {
    return {
      id: this.generateUID(),
      type: CompTypeEnum.EPUB,

      configuration: {
        content: content,
      },
    };
  }
}

export const COMPONENT_UTILS = new ComponentUtils();

export const CLEAR_KEYCODE_ARRAY = [
  46, // backspace
  8, // delete
  9, // tab
  27, // escape
  110,
  190, // decimal point
  109,
  189,
];

export const isEnterANumber = (e: any) => {
  return !isNaN(e.key);
};

export const isEnterACharacter = (e: any) => {
  return isNaN(e.key);
};
