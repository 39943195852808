import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LessonSectionAPI } from '@modules/product/services/LessonSectionAPI';
import SectionUtils from '@cms/section-bank/SectionUtils';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { Tooltip } from '@components/tooltip/Tooltip';
import { Button, Popover, Space } from 'antd';
import { PicLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { H4 } from '@components/typography';
import SectionSettingEditable from '@cms/section-bank/components/SectionSettingEditable';
import { SectionItemEditable } from '@cms/section-bank/components/SectionItemEditable';
import { TextContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { LessonTemplateWrapper } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { ViewExerciseWrapper } from '@cms/lesson-template/exercise/ViewExerciseWrapper';

const SectionComposeContext = createContext({
  item: {} as LessonSectionProps,
  onDropResource: (resourceId: number, group: string, index: number) => {
    console.log(resourceId, group, index);
  },
  onRemoveResource: (resourceId: number, group: string, index: number) => {
    console.log(resourceId, group, index);
  },
});

const SectionCompose = (props: {
  lessonId: number;
  item: LessonSectionProps;
  onChange: () => void;
}) => {
  const { t } = useTranslation();

  const [addResourceToSection] =
    LessonSectionAPI.endpoints.addResourceToSection.useMutation({});

  const [updateSection] = LessonSectionAPI.endpoints.updateSection.useMutation(
    {}
  );

  const [removeResourceFromSection] =
    LessonSectionAPI.endpoints.removeResourceFromSection.useMutation({});

  const [section, setSection] = useState(props.item);
  const [modifiedTime, setModifiedTime] = useState(-1);

  const [setting, setSetting] = useState(JSON.stringify(props.item.setting));
  const [showSetting, setShowSetting] = useState(false);

  const [header, setHeader] = useState<TextContentProps>({
    id: COMPONENT_UTILS.generateUID(),
    type: AssetsTypeEnum.TEXT,
    data: '',
  });

  const [footer, setFooter] = useState<TextContentProps>({
    id: COMPONENT_UTILS.generateUID(),
    type: AssetsTypeEnum.TEXT,
    data: '',
  });

  useEffect(() => {
    setSection(props.item);
  }, [props.item]);

  useEffect(() => {
    restoreSectionData(section);
  }, [section]);

  const handleOnRemoveResource = (resourceId: number) => {
    const request = {
      lessonSectionId: section.lessonSectionId,
      resourceId: resourceId,
      lessonId: props.lessonId,
    };

    removeResourceFromSection(request)
      .unwrap()
      .then(() => {
        props.onChange();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnDropResource = (
    resourceId: number,
    group: string,
    index: number
  ) => {
    const request = {
      lessonId: props.lessonId,
      resourceId: resourceId,
      lessonSectionId: section.lessonSectionId,
      group: group,
      index: index,
    };

    addResourceToSection(request)
      .unwrap()
      .then((data) => {
        setSection(SectionUtils.convert(data));
        props.onChange();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const restoreSectionData = (data: LessonSectionProps) => {
    if (data.content != null) {
      setHeader(data.content.header);
      setFooter(data.content.footer);
    } else {
      setHeader({
        id: COMPONENT_UTILS.generateUID(),
        type: AssetsTypeEnum.TEXT,
        data: '',
      });

      setFooter({
        id: COMPONENT_UTILS.generateUID(),
        type: AssetsTypeEnum.TEXT,
        data: '',
      });
    }
  };

  const updateOptionData = (
    data: TextContentProps,
    group: 'header' | 'footer'
  ) => {
    if (group === 'header') {
      setHeader(data);
    } else {
      setFooter(data);
    }
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const handleOnSettingChange = (_setting: string) => {
    triggerUpdateSection(_setting, header, footer);
  };

  const handleOnContentChange = () => {
    triggerUpdateSection(setting, header, footer);
  };

  const triggerUpdateSection = (
    setting: string,
    header: TextContentProps,
    footer: TextContentProps
  ) => {
    const request = {
      lessonSectionId: props.item.lessonSectionId,
      lessonId: props.lessonId,
      name: props.item.name,
      setting: setting,
      content: JSON.stringify({
        header: header,
        footer: footer,
      }),
    };

    updateSection(request)
      .unwrap()
      .then((data) => {
        const newSec = SectionUtils.convert(data);

        setSection(newSec);
        setSetting(JSON.stringify(newSec.setting));
        setShowSetting(false);
        props.onChange();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <ViewExerciseWrapper
      className={'exercise-content-display'}
      ratio={{ width: 16, height: 9 }}
      minFontSize={12}
    >
      <Space
        className={'section-actions top-right-menu'}
        style={{ position: 'absolute', top: 10, right: 10 }}
      >
        <Popover
          placement="bottomRight"
          showArrow
          content={
            <SectionSettingStyle className={`lesson-section-setting`}>
              <SectionSettingEditable
                item={section}
                onChange={handleOnSettingChange}
              />
            </SectionSettingStyle>
          }
          title={<H4>Setting</H4>}
          trigger="click"
          open={showSetting}
        >
          <Tooltip title={t('section.actions.update_setting')}>
            <Button
              shape={'circle'}
              onClick={() => setShowSetting((prev) => !prev)}
            >
              <PicLeftOutlined />
            </Button>
          </Tooltip>
        </Popover>
      </Space>

      <LessonTemplateWrapper>
        <SectionComposeItemStyle className={'section-compose'}>
          <SectionComposeContext.Provider
            value={{
              item: section,
              onDropResource: handleOnDropResource,
              onRemoveResource: handleOnRemoveResource,
            }}
          >
            <SectionItemEditable
              item={section}
              header={header}
              footer={footer}
              onDataChange={updateOptionData}
            />

            <AutoUpdateToolbar
              lastChange={modifiedTime}
              onTimeout={handleOnContentChange}
            />
          </SectionComposeContext.Provider>
        </SectionComposeItemStyle>
      </LessonTemplateWrapper>
    </ViewExerciseWrapper>
  );
};

export default SectionCompose;

export const useSectionComposeContext = () => {
  const context = useContext(SectionComposeContext);

  if (!context) {
    throw new Error(
      'You must wrap container by SectionComposeContext.Provider'
    );
  }
  return context;
};

const SectionComposeItemStyle = styled.div`
  position: relative;
  background: #fff;
  font-size: 16px;
  padding: 0.5em 0em;

  margin-top: 1em;
  margin-bottom: 1em;

  .section-compose-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .section-compose-header-display {
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .section-compose-header,
  .section-compose-content {
    padding: 0.5em 1em;
  }
`;

const SectionSettingStyle = styled.div`
  border-top: 1px solid #ccc;
  padding: 0.5em;

  .button-group {
    margin-top: 1em;
  }
`;
