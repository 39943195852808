import React, { useRef } from 'react';
import { useWindowSizeChange } from '@hooks/useWindowSize';
import styled from 'styled-components';

export const ContainerRatio = (props: {
  className?: string;
  ratio: string; // height / width, ex: 2 / 1
  onChange?: (width: number) => void;
  style?: any;
  children: any;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useWindowSizeChange(() => {
    handleWindowChange();
  }, 500);

  const handleWindowChange = () => {
    if (elementRef.current && props.onChange) {
      const width = elementRef.current.offsetWidth;
      if (props.onChange) {
        props.onChange(width);
      }
    }
  };

  return (
    <div
      className={`container-ratio ${props.className ?? ''} `}
      style={
        props.style
          ? { ...props.style, aspectRatio: props.ratio }
          : { aspectRatio: props.ratio }
      }
      ref={elementRef}
    >
      {props.children}
    </div>
  );
};

const ContainerScale = (props: {
  className?: string;
  scale: number; // height / width
  minHeight?: number;
  onChange?: (width: number, height: number) => void;
  style?: any;
  children: any;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useWindowSizeChange(() => {
    handleWindowChange();
  }, 500);

  const handleWindowChange = () => {
    if (elementRef.current && props.scale) {
      const width = elementRef.current.offsetWidth;
      const height = width * props.scale;
      elementRef.current.style.minHeight = height + 'px';

      if (props.onChange) {
        props.onChange(width, height);
      }
    }
  };

  return (
    <ContainerScaleStyle
      className={`container-scale-wrapper ${props.className ?? ''}`}
      ref={elementRef}
      style={
        props.style
          ? { ...props.style, minHeight: props.minHeight ?? 'auto' }
          : { minHeight: props.minHeight ?? 'auto' }
      }
    >
      {props.children}
    </ContainerScaleStyle>
  );
};

export default ContainerScale;

const ContainerScaleStyle = styled.div`
  display: block;
  position: relative;

  iframe.content-scale,
  img.content-scale {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
  }
`;
