import React, { ReactNode } from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import styled from 'styled-components';

export const PageFullSize = (props: {
  header?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <PageFullSizeStyle className={'page-full-size'}>
      {props.header && (
        <HeaderGroup className="header-group">{props.header}</HeaderGroup>
      )}

      {props.children}
    </PageFullSizeStyle>
  );
};

const PageFullSizeStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-group {
    margin-top: 0;
  }

  > .ant-tabs {
    height: 100%;

    .ant-tabs-ink-bar {
      background: transparent;
      //display: none;

      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #ccc;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font-size: 16px;
        padding: 8px 12px;

        border-radius: 8px;
        border: 1px solid #ccc;

        &.ant-tabs-tab-active {
          background: #fff;
          border: 1px solid #ccc;
          position: relative;
        }

        .ant-tabs-tab-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
        }

        .anticon {
          font-size: 200%;
          margin-right: 0;
          margin-bottom: 4px;
        }
      }
    }

    .ant-tabs-content-holder {
      border-left: none;
    }

    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }

  .ant-card {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 0;

    > .ant-card-body {
      height: 100%;
    }
  }
`;
