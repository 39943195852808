import { useTranslation } from 'react-i18next';
import { Button, Card, Rate } from 'antd';
import ThumbnailsImg, {
  ThumbnailImgIcon,
} from '@components/product/ProductThumbnails';
import React from 'react';
import styled from 'styled-components';
import { ProductInfoRes } from '@services/publish/PublishProductAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import NavLink from '@components/button/NavLink';
import { ImageScale } from '@utils/ImageScale';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import { H4, Paragraph } from '@components/typography';
import { SiteMap } from '@router/SiteMap';
import { ProductRes } from '@modules/product/services/product_model';

const { Meta } = Card;

export const PublicProductInfo = (props: { item: ProductRes }) => {
  const { t } = useTranslation();

  return (
    <PublishProductStyle className={'product-info'}>
      <Card
        hoverable
        cover={
          <ThumbnailsImg
            type={'icon'}
            src={props.item.icon}
            ratio={ImageScale.product.ratio}
          />
        }
      >
        <div className={'product-content'}>
          <Meta
            title={<H4>{props.item.name}</H4>}
            description={
              <div className={'product-description'}>
                <Paragraph ellipsis>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.item.shortInstruction,
                    }}
                  />
                </Paragraph>
              </div>
            }
          />

          <div className={'product-actions'}>
            <ButtonGroup>
              <NavLink
                href={SiteMap.public.product_gen(
                  props.item.productId,
                  props.item.name
                )}
              >
                <Button type={'primary'}>{t('label.view_detail')}</Button>
              </NavLink>
            </ButtonGroup>
          </div>
        </div>
      </Card>
    </PublishProductStyle>
  );
};

const PublishProductStyle = styled.div``;

export const KeywordItemStyle = styled.span`
  background: ${(props) => props.theme.app.primary};
  color: ${(props) => props.theme.app.primary_text};
  display: inline-block;
  text-align: center;
  padding: 0.125em 1em;
  border-radius: 1em;
  font-size: 14px;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
`;

const PublishProductPreviewStyle = styled.div`
  .product-header {
    display: flex;

    .product-image-thumbnails {
      width: 33%;
    }

    .product-info {
      width: 66%;
      padding-left: 2em;

      .product-tittle {
        font-size: 32px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .product-title-display {
          font-weight: bold;
        }

        .product-price {
          font-size: 22px;
        }
      }

      .product-rate {
        text-align: right;
      }

      .product-author {
        text-align: right;
      }
    }
  }

  .product-introduction,
  .product-records,
  .product-keyword {
    margin-top: 1em;
  }

  .product-records {
    display: flex;

    .product-record-item {
      width: 33%;
      text-align: center;

      h4 {
        font-size: 24px;
      }
    }
  }

  .product-keyword {
    border-top: 1px solid #ccc;
    text-align: right;
    padding-top: 1em;

    .keyword {
      margin-right: 0px;
      margin-left: 1em;
    }
  }
`;

export const PublicProductPreview = (props: {
  item: ProductInfoRes;
  author: UserProfileRes;
}) => {
  return (
    <PublishProductPreviewStyle className={'product-review-item'}>
      <div className={'product-header'}>
        <div className={'product-image-thumbnails'}>
          <ThumbnailImgIcon
            type={'icon'}
            src={props.item.product.icon}
            alt={props.item.product.description}
          />
        </div>

        <div className={'product-info'}>
          <h2 className={'product-tittle'}>
            <span className={'product-title-display'}>
              {props.item.product.name}
            </span>
            {props.item.product.price != null &&
              props.item.product.price > 0 && (
                <span className={'product-price'}>
                  <b>{props.item.product.price}</b> G-points
                </span>
              )}
            {props.item.product.price === 0 && (
              <span className={'product-price'}>
                <b>Free</b>
              </span>
            )}
            {props.item.product.price} -- X
          </h2>

          <div className={'product-rate'}>
            <Rate disabled={true} value={3} />
          </div>

          <div className={'product-author'}>
            <h3>
              Author: <b>{props.author.displayName}</b>
            </h3>
          </div>
        </div>
      </div>

      <div className={'product-introduction'}>
        <h3>Descriptions:</h3>

        <div
          className={'product-introduction-content'}
          dangerouslySetInnerHTML={{ __html: props.item.product.instruction }}
        />
      </div>

      <div className={'product-records'}>
        <div className={'product-record-item'}>
          <h4>1000</h4>
          View
        </div>

        <div className={'product-record-item'}>
          <h4>100</h4>
          Feedback
        </div>

        <div className={'product-record-item'}>
          <h4>100</h4>
          Number Lessons
        </div>
      </div>

      <div className={'product-keyword'}>
        <h3>Keywords:</h3>

        {props.item.product.keyword.split(',').map((kw: string, index) => {
          return (
            <KeywordItemStyle className={'keyword'} key={kw + '_' + index}>
              {kw.trim()}
            </KeywordItemStyle>
          );
        })}
      </div>
    </PublishProductPreviewStyle>
  );
};
