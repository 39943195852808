import { H5 } from '@components/typography';
import {
  ResourceContextProvider,
  ResourceItem,
} from '@cms/context/ResourceContextProvider';
import { CompMode } from '@cms/ComponentInteface';
import NavLink from '@components/button/NavLink';
import { Button } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SiteMap } from '@router/SiteMap';
import { ResourceRes } from '@modules/product/services/resource_model';

export const ResourceContentItem = (props: { item: ResourceRes }) => {
  const { t } = useTranslation();
  const resource = ResourceUtils.convert(props.item, '', -1);

  return (
    <ResourceContentItemStyle className={'resource-info'}>
      <H5>
        {t('label.name')}: {props.item.name}
      </H5>

      <div className={'resource-content'}>
        <ResourceContextProvider
          mode={CompMode.REVIEW}
          resourceId={props.item.resourceId}
          comps={resource.components}
          answer={resource.correctAnswer}
          feedback={[]}
        >
          <ResourceItem />
        </ResourceContextProvider>
      </div>

      <div className={'resource-action'}>
        <NavLink
          href={SiteMap.content.resource.compose_gen(props.item.resourceId)}
        >
          <Button
            type={'primary'}
            shape={'circle'}
            icon={IconUtils.actions.edit}
          />
        </NavLink>
      </div>
    </ResourceContentItemStyle>
  );
};

const ResourceContentItemStyle = styled.div`
  position: relative;

  .resource-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
