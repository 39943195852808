import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import React from 'react';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import styled from 'styled-components';
import { CompMode } from '@cms/ComponentInteface';
import { useResourceContext } from '@cms/context/ResourceContextProvider';

export const WordDescriptionBank = (props: {
  questionNumber: number;
  selectedWord: string;
  correctAnswers: string[];
  items: GlossaryGameContent[];
  onClick: (word: string) => void;
}) => {
  const { mode } = useResourceContext();

  const getClassName = (word: GlossaryGameContent) => {
    const className: string[] = ['word-puzzle-item'];

    if (
      props.selectedWord != null &&
      props.selectedWord !== '' &&
      word.id === props.selectedWord
    ) {
      className.push('selected-item');
    }

    if (mode === CompMode.COMPOSE && word.offset == null) {
      className.push('error-item');
    }

    return className.join(' ');
  };

  const getWarningError = (word: GlossaryGameContent) => {
    if (mode === CompMode.COMPOSE && word.offset == null) {
      return (
        <WordPuzzleWarning>
          <span>
            Word didnt place to graph. Click any box to append the missing word.
          </span>
        </WordPuzzleWarning>
      );
    } else {
      return null;
    }
  };

  return (
    <WordPuzzleBoxStyle className={'word-puzzle-box'}>
      {props.items.map((word, index) => {
        if (props.correctAnswers.includes(word.id)) {
          return (
            <div
              className={`word-puzzle-item correct-item`}
              key={word.id + '_' + index + '_correct'}
            >
              <div className={'word-search-question-number'}>
                {props.questionNumber + index}. &nbsp;
              </div>

              <div className={'word-search-question-content'}>
                <ContentViewer contentData={word.description} />
              </div>
            </div>
          );
        } else {
          return (
            <React.Fragment key={word.id + '_' + index}>
              <div
                className={getClassName(word)}
                onClick={() => props.onClick(word.id)}
              >
                <div className={'word-search-question-number'}>
                  {props.questionNumber + index}. &nbsp;
                </div>

                <div className={'word-search-question-content'}>
                  <ContentViewer contentData={word.description} />
                </div>
              </div>

              {getWarningError(word)}
            </React.Fragment>
          );
        }
      })}
    </WordPuzzleBoxStyle>
  );
};

const WordPuzzleBoxStyle = styled.div`
  position: relative;

  .word-puzzle-item {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    padding: 0.25em 0.5em;
    margin: -0.25em -0.5em;

    &.correct-item {
      color: ${(props) => props.theme.component.correct};
      cursor: default;
    }

    &.selected-item {
      background: ${(props) => props.theme.color.grey};
    }

    &.error-item {
      color: ${(props) => props.theme.color.white};
      background: ${(props) => props.theme.component.incorrect_bgr};

      &.selected-item {
        background: ${(props) => props.theme.component.incorrect};
      }
    }
  }
`;

const WordPuzzleWarning = styled.div`
  color: ${(props) => props.theme.component.incorrect};
  padding: 0.25em 0.5em;
  margin: -0.25em -0.5em;

  span {
    font-size: 75%;
  }
`;
