import {
  ClassRes,
  ClassRosterEnumType,
  ClassRosterRes,
} from '@modules/users/services/clazz';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Dropdown, List } from 'antd';
import ColorCircle from '@components/input/ColorCircle';
import { UserAvatar } from '@components/avatar/UserAvatar';
import React, { useMemo } from 'react';
import { H3, H4 } from '@components/typography';
import { DateLabel } from '@components/text/DateLabel';
import { IconUtils } from '@utils/IconUtils';
import { InputGroup } from '@components/input/InputGroup';
import { ClassItemStyle } from './ClassInfoPlaceHolder';
import { UserAddOutlined } from '@ant-design/icons';
import { useTheme } from '@app/styled/StyledProvider';
import { EntityWithStatus } from '@components/status/EntityStatus';
import { EntityStatusEnum } from '@services/model/common';
import { StudentInfoSimple } from '@components/info/StudentInfo';
import ButtonGroup from '@components/button/ButtonGroup';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { useRouter } from '@hooks/useRouter';

export const ViewClassInfo = (props: {
  item: ClassRes;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'view-class-info'}>
      <div className={'view-class-info-group'}>
        <H3>{props.item.name}</H3>

        <InputGroup label={t('label.createdDate')}>
          <DateLabel label={props.item.createdDate} />
        </InputGroup>
      </div>

      <div className={'view-class-info-group'}>
        <H4>Participants:</H4>

        <div className={'user-groups'}>
          <List
            bordered
            dataSource={props.item.rosters}
            renderItem={(st) => (
              <List.Item>
                <StudentInfoSimple className={'no-padding'} item={st.user} />
                <span>
                  {st.type === ClassRosterEnumType.MAIN_TEACHER ||
                  st.type === ClassRosterEnumType.SECONDARY_TEACHER
                    ? t('roles.teacher')
                    : t('roles.student')}
                </span>
              </List.Item>
            )}
          />
        </div>
      </div>

      <div className={'view-class-action'} style={{ marginTop: '2em' }}>
        <ButtonGroup type={'center'}>
          <Button shape={'round'} onClick={props.onCancel}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export const ClassItem = (props: { item: ClassRes; onClick?: () => void }) => {
  const { t } = useTranslation();
  return (
    <ClassItemStyle className={'class-info'}>
      <div className={'class-info-group'}>
        <div className={'class-thumbnail'}>
          <div className={'class-avatar'}>
            <ColorCircle color={props.item.color}>
              {props.item.code.substring(0, 3)}
            </ColorCircle>
          </div>
        </div>

        <div className={'class-detail'} onClick={props.onClick}>
          <H4 title={t('classes.actions.update_info')}>
            <EntityWithStatus status={props.item.status} hideToolTip={true}>
              {props.item.name}
            </EntityWithStatus>
          </H4>

          <InputGroup label={t('label.createdDate')}>
            <DateLabel label={props.item.createdDate} />
          </InputGroup>
        </div>
      </div>

      <div className={'class-student-group'}>
        <InputGroup label={t('label.students')}>
          <ClassRosterGroup
            type={[ClassRosterEnumType.STUDENT]}
            rosters={props.item.rosters}
          />
        </InputGroup>
      </div>

      <div className={'class-actions'}>
        <NavLink
          href={SiteMap.private.my_class.view_gen(
            props.item.classId,
            props.item.code
          )}
        >
          <Button type={'primary'} shape={'round'}>
            {t('classes.actions.access')} {IconUtils.actions.access}
          </Button>
        </NavLink>
      </div>
    </ClassItemStyle>
  );
};

export const ClassInfoEditable = (props: {
  item: ClassRes;
  onEdit: (classId: number) => void;
  onActivateClass: (classId: number, isActive: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <ClassItemStyle className={'class-info'}>
      <div className={'class-info-group'}>
        <div className={'class-thumbnail'}>
          <div className={'class-avatar'}>
            <ColorCircle color={props.item.color}>
              {props.item.code.substring(0, 3)}
            </ColorCircle>
          </div>
        </div>

        <div className={'class-detail'}>
          <H4 title={t('classes.actions.update_info')}>
            <EntityWithStatus status={props.item.status}>
              {props.item.name}
            </EntityWithStatus>
          </H4>

          <InputGroup label={t('label.createdDate')}>
            <DateLabel label={props.item.createdDate} />
          </InputGroup>
        </div>
      </div>

      <div className={'class-student-group'}>
        <InputGroup label={t('class.label.teachers')}>
          <ClassRosterGroup
            type={[
              ClassRosterEnumType.MAIN_TEACHER,
              ClassRosterEnumType.SECONDARY_TEACHER,
            ]}
            rosters={props.item.rosters}
          />
        </InputGroup>

        <InputGroup label={t('class.label.rosters')}>
          <ClassRosterGroup
            type={[ClassRosterEnumType.STUDENT]}
            rosters={props.item.rosters}
          />
        </InputGroup>
      </div>

      <div className={'class-actions'}>
        <ClassActionDropdown
          item={props.item}
          onEdit={props.onEdit}
          onActivateClass={props.onActivateClass}
        />
      </div>
    </ClassItemStyle>
  );
};

export const ClassActionDropdown = (props: {
  item: ClassRes;
  onEdit: (classId: number) => void;
  onActivateClass: (classId: number, isActive: boolean) => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const isActive =
    props.item.status != null && EntityStatusEnum.ACTIVE === props.item.status;

  const handleOnAccess = () => {
    router.push(
      SiteMap.private.my_class.view_gen(props.item.classId, props.item.code)
    );
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'access',
            icon: IconUtils.actions.access,
            label: t('classes.actions.access'),
            onClick: handleOnAccess,
          },
          {
            key: 'edit',
            icon: IconUtils.actions.edit,
            label: t('class.actions.update'),
            onClick: () => props.onEdit(props.item.classId),
          },

          {
            key: 'remove',
            icon: isActive
              ? IconUtils.actions.disabled
              : IconUtils.actions.enabled,
            label: isActive
              ? t('class.actions.disabled')
              : t('class.actions.enabled'),
            onClick: () => props.onActivateClass(props.item.classId, isActive),
          },
        ],
      }}
      trigger={['click']}
    >
      <Button type="default" shape="circle" icon={IconUtils.more_icon} />
    </Dropdown>
  );
};

export const ClassRosterGroup = (props: {
  rosters: ClassRosterRes[];
  type: ClassRosterEnumType[];
  max?: number;
}) => {
  const theme = useTheme();

  const rosters = useMemo(() => {
    return props.rosters
      ? props.rosters.filter((st) => {
          return props.type.includes(st.type);
        })
      : [];
  }, [props.rosters, props.type]);

  return (
    <div className={'class-roster-group'}>
      {rosters.length > 0 ? (
        <Avatar.Group
          maxCount={props.max ?? 6}
          maxStyle={{
            color: theme.app.primary,
            backgroundColor: theme.app.primary_bgr,
          }}
        >
          {rosters.map((st) => {
            if (props.type.includes(st.type)) {
              return (
                <UserAvatar
                  key={st.user.userId}
                  src={st.user.avatar}
                  size={40}
                />
              );
            } else {
              return null;
            }
          })}
        </Avatar.Group>
      ) : (
        <UserAvatar src={''} size={40} icon={<UserAddOutlined />} />
      )}
    </div>
  );
};
