import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useDragNDropInlineCompContext } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { DragNDropItemCompProps } from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import { CompGroupEnum } from '@cms/utils/CompProps';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import { ContentOperationEnum } from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';

const DndInlineEditableContext = createContext({
  editItem: '',
  changeToEdit: (id: string) => {
    console.log(id);
  },

  updateOptionType: (
    type: AssetsTypeEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(type, index, option, from);
  },

  updateGroupOptions: (
    operation: ContentOperationEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(operation, index, option, from);
  },

  updateOptionData: (
    data: AssetsContentProps,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(data, index, option, from);
  },
});

export const DndInlineEditable = (props: { children: ReactNode }) => {
  const [editItem, setEditItem] = useState('');

  const {
    modifiedTime,
    updateComponent,
    answers,
    sourceItems,
    targetItems,
    updateOptions,
    updateAnswers,
  } = useDragNDropInlineCompContext();

  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    if (from === CompGroupEnum.source) {
      const newSourceItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        sourceItems
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        targetItems
      );
      updateOptions(newTargetItems, from);
    }
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    let newOptions: DragNDropItemCompProps[] = [];
    let newAnswers: string[] = [];

    if (from === CompGroupEnum.source) {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          sourceItems,
          OptionTypeEnum.ABC
        );

        newAnswers = handleInsertOption(newOptions[index + 1].label, answers);

        // then, update correct answer.
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          sourceItems,
          OptionTypeEnum.ABC
        );

        newAnswers = handleRemoveOption(option.label, answers);
      }
    } else {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleInsertGroup(index, answers);
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleRemoveGroup(index + 1, answers);
      }
    }

    // then update data...
    updateOptions(newOptions, from);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: DragNDropItemCompProps,
    from: CompGroupEnum
  ) => {
    const newOption = { label: option.label, content: { ...data } };

    if (from === CompGroupEnum.source) {
      const newSourceItems = COMPONENT_UTILS.updateAtIndex(
        sourceItems,
        index,
        newOption
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = COMPONENT_UTILS.updateAtIndex(
        targetItems,
        index,
        newOption
      );
      updateOptions(newTargetItems, from);
    }
    setEditItem('');
  };

  const changeToEdit = (editId: string) => {
    setEditItem(editId);
  };

  return (
    <DndInlineEditableContext.Provider
      value={{
        editItem,
        changeToEdit,
        updateOptionType,
        updateGroupOptions,
        updateOptionData,
      }}
    >
      {props.children}

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </DndInlineEditableContext.Provider>
  );
};

export const useDndInlineEditable = () => {
  const context = useContext(DndInlineEditableContext);
  if (!context) {
    throw new Error('You must wrap container by DndInlineEditableContext');
  }
  return context;
};

export const handleInsertOption = (optionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(optionLabel);

  return answers.map((ans) => {
    const ansData = parseDragNDropInlineAnswer(ans);
    // if answer is a -> a => b
    const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
    if (answerIndex >= newOptionIndex) {
      return generateDragNDropInlineAns(
        ansData.index,
        ansData.groupLabel,
        COMPONENT_UTILS.getABC(answerIndex + 1)
      );
    } else {
      return ans;
    }
  });
};

export const handleRemoveOption = (
  removeOptionLabel: string,
  answers: string[]
) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(removeOptionLabel);

  return answers
    .filter((ans) => {
      const ansData = parseDragNDropInlineAnswer(ans);
      return ansData.optionLabel !== removeOptionLabel;
    })
    .map((ans) => {
      const ansData = parseDragNDropInlineAnswer(ans);

      const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
      if (answerIndex > newOptionIndex) {
        return generateDragNDropInlineAns(
          ansData.index,
          ansData.groupLabel,
          COMPONENT_UTILS.getABC(answerIndex - 1)
        );
      } else {
        return ans;
      }
    });
};

export const handleInsertGroup = (index: number, answers: string[]) => {
  // then, update correct answer.
  return answers.map((ans) => {
    const ansData = parseDragNDropInlineAnswer(ans);

    if (ansData.index > index + 1) {
      return generateDragNDropInlineAns(
        ansData.index + 1,
        ansData.groupLabel,
        ansData.optionLabel
      );
    } else {
      return ans;
    }
  });
};

export const handleRemoveGroup = (index: number, answers: string[]) => {
  return answers
    .filter((ans) => {
      const ansData = parseDragNDropInlineAnswer(ans);
      return ansData.index !== index;
    })
    .map((ans) => {
      const ansData = parseDragNDropInlineAnswer(ans);

      if (ansData.index > index) {
        return generateDragNDropInlineAns(
          ansData.index - 1,
          ansData.groupLabel,
          ansData.optionLabel
        );
      } else {
        return ans;
      }
    });
};

export const parseDragNDropInlineAnswer = (ans: string) => {
  const groupPart = ans.split(':')[0];
  const answerPart = ans.split(':')[1];

  const index = groupPart.split('|')[0];
  const groupLabel = groupPart.split('|')[1];

  return {
    index: Number(index),
    groupLabel: groupLabel,
    optionLabel: answerPart,
  };
};

export const generateDragNDropInlineAns = (
  group: number,
  groupIndex: string,
  optionLabel: string
) => {
  return `${group}|${groupIndex}:${optionLabel}`;
};
