import { DOMRectReadOnly } from '@juggle/resize-observer/lib/DOMRectReadOnly';
import { CoordinateActionEnum } from '@cms/comps/interact/coordinate/CoordinateEnum';
import { CompInteractSettingProps } from '@cms/ComponentInteface';

export enum CoordinateGridType {
  // quadrants = 'quadrants',
  blank_cover = 'blank-cover',
  coordinate = 'coordinate',
  grid = 'grid',
  dot = 'dot',
  blank = 'blank',
  // numberLine = 'number-line',
}

export interface AxisSettingProps {
  from: number;
  to: number;
  step: number;
  stepLabel: number;
}
export interface CoordinateSettingProps extends CompInteractSettingProps {
  grid: {
    type: CoordinateGridType;
    snapToGrid: boolean;
    unit: number | null; // 1 axis equal ? null = auto.

    xAxis: AxisSettingProps;
    yAxis: AxisSettingProps;
  };
  actions: CoordinateActionEnum[];

  // point: {
  //   snap: boolean;
  // };
}

export interface CoordinateUtilsProps {
  init: (workspace: HTMLElement) => void; // CONTAINER
  onChange: (onChangeFunc: (answer: any) => void) => void; // TRIGGER WHEN PAPER CHANGE

  setContainerSize: (content: DOMRectReadOnly) => void; // ON RESIZE
  setAction: (action: CoordinateActionEnum, params: any) => void; // TRIGGER WHEN CLICK THE ACTION BUTTON
  setDisabled: (disabled: boolean) => void; // TRIGGER WHEN CHANGE FROM EDITABLE TO READONY MODE.
  setSetting: (setting: CoordinateSettingProps) => void; // TRIGGER IF SETTING CHANGE
  setAnswer: (answer: any) => void; // TRIGGER IF CURRENT ANSWER CHANGE
}

export class CoordinateUtils implements CoordinateUtilsProps {
  // outside
  ready = false as boolean;

  workspace: any;
  disabled = false as boolean;
  setting = null as CoordinateSettingProps | null;
  currentAns = null as null | any;

  // inside (state)
  paper: any;

  // trigger event ....
  resizeFunc: any;
  handleOnChange: any;

  // init -> used to set data.....
  init(workspace: HTMLElement) {
    if (this.workspace == null) {
      this.workspace = workspace;
    }
  }

  onChange(onChangeFunc: (answer: any) => void) {
    this.handleOnChange = onChangeFunc;
  }

  setReady(ready: boolean) {
    this.ready = ready;
    this.sync(false);
  }

  setSetting(setting: CoordinateSettingProps) {
    this.setting = setting;
    this.sync(false);
  }

  setDisabled(disabled: boolean) {
    this.disabled = disabled;
    this.sync(false);
  }

  setAnswer(answer: any) {
    this.currentAns = answer;
    this.sync(false);
  }

  setContainerSize(content: DOMRectReadOnly) {
    if (this.paper != null) {
      if (this.resizeFunc != null) {
        clearTimeout(this.resizeFunc);
      }

      this.resizeFunc = setTimeout(() => {
        this.paper.resizePaper(content.width, content.height);
      }, 500);
    }
  }

  setAction(action: CoordinateActionEnum, params: any) {
    if (this.paper != null) {
      this.paper.changeAction(action, params);
    }
  }

  // each component hold its state. if one of any update --> trigger to update again...
  sync(clearAll: boolean) {
    if (clearAll) {
      if (this.workspace != null) {
        this.workspace.innerHTML = '';
        this.paper = null;
      }
    }

    if (this.ready) {
      this.initBackground();
      this.restoreData();
    }
  }

  initBackground = () => {
    if (this.workspace != null) {
      const bgrWidth = this.workspace.parentNode.clientWidth;
      const bgrHeight = this.workspace.parentNode.clientHeight;

      if (this.setting != null && this.setting.grid != null) {
        let grid = this.setting.grid;

        if (grid.type === CoordinateGridType.blank_cover) {
          const unit = bgrWidth / 100;
          grid = {
            type: CoordinateGridType.blank,
            snapToGrid: false,
            unit: unit, // 1 axis equal ? null = auto.

            xAxis: {
              from: 0,
              to: bgrWidth / unit,
              step: 1,
              stepLabel: 5,
            },
            yAxis: {
              from: -(bgrHeight / unit),
              to: 0,
              step: 1,
              stepLabel: 5,
            },
          };
        }

        const x_range = grid.xAxis.to - grid.xAxis.from;
        const y_range = grid.yAxis.to - grid.yAxis.from;

        let wk_unit_x = grid.unit != null ? grid.unit : bgrWidth / x_range;
        let wk_unit_y = grid.unit != null ? grid.unit : bgrHeight / y_range;

        if (grid.unit != null) {
          if (
            x_range * grid.unit > bgrWidth ||
            y_range * grid.unit > bgrHeight
          ) {
            const unit_w = bgrWidth / x_range; // get 1 axis size
            const unit_h = bgrHeight / y_range; // get 1 axis size

            wk_unit_x = unit_w < unit_h ? unit_w : unit_h;
            wk_unit_y = wk_unit_x;
          }
        }

        grid = { ...grid, unit: wk_unit_x };

        if (this.paper == null) {
          // @ts-ignore
          this.paper = new Raphael(
            this.workspace,
            wk_unit_x * x_range,
            wk_unit_y * y_range
          );
          this.paper.initCoordinateGraph(1, grid, this.handleOnChange);
        } else {
          this.paper.resizePaper(wk_unit_x * x_range, wk_unit_y * y_range);
        }
      }
    }
  };

  restoreData = () => {
    if (this.paper != null) {
      if (this.currentAns != null && this.currentAns !== '') {
        try {
          this.paper.restoreObjects(JSON.parse(this.currentAns));
        } catch (e) {
          console.log('have error when parse data', e, this.currentAns);
          this.currentAns = '';
        }
      } else {
        this.paper.removeAllObjects();
      }
    }
  };
}
