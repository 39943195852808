import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { CompAnswerProps, CompProps } from '@cms/ComponentInteface';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { CompGroupEnum } from '@cms/utils/CompProps';
import produce from 'immer';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import { ContentOperationEnum } from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import {
  MultipleChoiceAnsProps,
  MultipleChoiceCompProps,
  MultipleChoiceItemProps,
  MultipleChoiceSettingProps,
} from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';
import {StringUtils} from "@utils/StringUtils";

const MultipleChoiceCompContext = createContext({
  modifiedTime: -1,
  disabled: false as boolean,
  setting: {} as MultipleChoiceSettingProps,
  sourceItems: [] as MultipleChoiceItemProps[],

  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  // for interact
  onSelectOption: (option: string) => {
    console.log(option);
  },

  // for edit able
  updateOptions: (options: MultipleChoiceItemProps[], type: CompGroupEnum) => {
    console.log(options, type);
  },
  updateAnswers: (answers: string[]) => {
    console.log(answers);
  },
  updateComponent: () => {},

  updateOptionType: (
    type: AssetsTypeEnum,
    index: number,
    option: MultipleChoiceItemProps
  ) => {
    console.log(type, index, option);
  },

  updateGroupOptions: (
    operation: ContentOperationEnum,
    index: number,
    option: MultipleChoiceItemProps
  ) => {
    console.log(operation, index, option);
  },

  updateOptionData: (
    data: AssetsContentProps,
    index: number,
    option: MultipleChoiceItemProps
  ) => {
    console.log(data, index, option);
  },
});

export const MultipleChoiceCompContextProvider = (props: {
  disabled: boolean;
  item: MultipleChoiceCompProps;
  answer: MultipleChoiceAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedback] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    setAnswer({
      value: props.answer && props.answer.answer ? props.answer.answer : [],
      triggerChange: false,
    });
  }, [StringUtils.toStr(props.answer)]);

  useEffect(() => {
    setFeedback(
      props.feedback
        ? {
            manualScore: !props.feedback.autoScore,
            correct: props.feedback.correct,
            incorrect: props.feedback.incorrect,
          }
        : initCompFeedback
    );
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  const onSelectOption = (option: string) => {
    if (!props.disabled) {
      if (props.item.setting && props.item.setting.multiple) {
        let newArr;
        if (answer.value && answer.value.includes(option)) {
          newArr = [...answer.value].filter((item: string) => {
            return item !== option;
          });
        } else {
          newArr = [...answer.value, option];
        }

        setAnswer({
          value: newArr,
          triggerChange: true,
        });
      } else {
        setAnswer({
          value: [option],
          triggerChange: true,
        });
      }
    }
  };

  const updateAnswers = (newAnswers: string[]) => {
    setAnswer({
      value: newAnswers,
      triggerChange: true,
    });
  };

  // then custom data.............
  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );

  const updateOptions = (options: MultipleChoiceItemProps[]) => {
    setSourceItems(options);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  // for edit able
  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: MultipleChoiceItemProps
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    const newSourceItems = OptionGroupUtils.updateOption(
      index,
      newOption,
      sourceItems
    );
    updateOptions(newSourceItems);
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: MultipleChoiceItemProps
  ) => {
    let newOptions: MultipleChoiceItemProps[] = [];
    let newAnswers: string[] = [];

    if (operation === ContentOperationEnum.ADD) {
      newOptions = OptionGroupUtils.insertOption(
        index,
        option,
        sourceItems,
        OptionTypeEnum.ABC
      );

      newAnswers = handleInsertOption(
        newOptions[index + 1].label,
        answer.value
      );

      // then, update correct answer.
    } else {
      newOptions = OptionGroupUtils.removeOption(
        index,
        sourceItems,
        OptionTypeEnum.ABC
      );

      newAnswers = handleRemoveOption(option.label, answer.value);
    }

    // then update data...
    updateOptions(newOptions);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: MultipleChoiceItemProps
  ) => {
    const newOption = { label: option.label, content: { ...data } };
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(
      sourceItems,
      index,
      newOption
    );
    updateOptions(newSourceItems);
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer.value,
        },
      })
    );
  };

  return (
    <MultipleChoiceCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        disabled: props.disabled,
        setting: props.item.setting,
        sourceItems: sourceItems,

        answers: answer.value,
        feedback: feedBack,

        onSelectOption,

        updateOptions,
        updateAnswers,
        updateComponent,

        updateGroupOptions,
        updateOptionData,
        updateOptionType,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedBack}>
        {props.children}
      </CompFeedbackContextProvider>
    </MultipleChoiceCompContext.Provider>
  );
};

export const useMultipleChoiceCompContext = () => {
  const context = useContext(MultipleChoiceCompContext);
  if (!context) {
    throw new Error(
      'You must wrap container by MultipleChoiceCompContextProvider'
    );
  }
  return context;
};

const handleInsertOption = (optionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(optionLabel);

  return answers.map((ans) => {
    // if answer is a -> a => b
    const answerIndex = COMPONENT_UTILS.getIndexOfABC(ans);
    if (answerIndex >= newOptionIndex) {
      return COMPONENT_UTILS.getABC(answerIndex + 1);
    } else {
      return ans;
    }
  });
};

const handleRemoveOption = (removeOptionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(removeOptionLabel);

  return answers
    .filter((ans) => {
      return ans !== removeOptionLabel;
    })
    .map((ans) => {
      const answerIndex = COMPONENT_UTILS.getIndexOfABC(ans);
      if (answerIndex > newOptionIndex) {
        return COMPONENT_UTILS.getABC(answerIndex - 1);
      } else {
        return ans;
      }
    });
};
