import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import React, { useMemo } from 'react';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompMode } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { H5 } from '@components/typography';
import {
  SectionDropColumn,
  SectionResourceDragItem,
} from '@cms/section-bank/components/SectionDropColumn';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import {
  getColumnStyle,
  SectionColumnSettingProps,
} from '@cms/section-bank/columns/OneColumnSection';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import { useTranslation } from 'react-i18next';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { SingleQuestionToolbarHandle } from '@cms/lesson-template/components/toolbar/SingeQuestionToolbar';

export interface FixedGroupSectionSettingProps
  extends SectionColumnSettingProps {
  layout: 'left-right' | 'right-left' | 'top-bottom' | 'bottom-top';
  showJump: boolean;
}

export interface FixedGroupSectionProps extends LessonSectionProps {
  type: SectionTypeEnum.fixed_group;
  setting: FixedGroupSectionSettingProps;

  group: {
    header: ResourceProps[];
    content: ResourceProps[];
    // footer: ResourceProps[]
  };
}

export const FixedGroupSection = (props: {
  item: FixedGroupSectionProps;
  mode: CompMode;
}) => {
  const { t } = useTranslation();

  const { config } = useLessonTemplateContext();
  const { resource } = useLessonNavigationContext();

  const getFixedClass = useMemo(() => {
    return props.item.setting.layout;
  }, [props.item.setting]);

  return (
    <FixedGroupSectionStyle
      className={`lesson-section fixed-section fixed-section-layout section_${props.mode.toLowerCase()} ${getFixedClass}`}
    >
      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.header && (
          <div className={'lesson-section-header-container'}>
            <ContentViewer contentData={props.item.content.header} />
          </div>
        )}

      <div
        className={'lesson-section-container'}
        style={{ gap: props.item.setting.gap + 'em' }}
      >
        {(props.mode === CompMode.COMPOSE ||
          props.item.group.header.length > 0) && (
          <div
            className={'fixed-column header-group fixed-group'}
            style={getColumnStyle(
              props.item.setting.width[0],
              2,
              props.item.setting.gap
            )}
          >
            <SectionDropColumn
              title={'Fixed Header Column'}
              group={'header'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.header.map((item, index) => {
                return (
                  <SectionResourceDragItem
                    key={item.resourceId + '_' + index}
                    index={index}
                    item={item}
                    mode={props.mode}
                  />
                );
              })}

              {props.mode === CompMode.COMPOSE && (
                <>
                  <H5 className={'empty-space'}>
                    {t('section.direction.drag_question')}
                  </H5>
                  <div className={'hint'}>
                    {t('section.direction.fixed_content')}
                  </div>
                </>
              )}
            </SectionDropColumn>
          </div>
        )}

        {(props.mode === CompMode.COMPOSE ||
          props.item.group.content.length > 0) && (
          <div
            className={'fixed-column content-group dynamic-group'}
            style={getColumnStyle(
              props.item.setting.width[1],
              2,
              props.item.setting.gap
            )}
          >
            <SectionDropColumn
              title={'Dynamic Column'}
              group={'content'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.content.map((item, index) => {
                const visible =
                  props.mode === CompMode.COMPOSE ||
                  !config.singleQuestionMode ||
                  resource.resourceId === item.resourceId;

                if (visible) {
                  return (
                    <SectionResourceDragItem
                      key={item.resourceId + '_' + index + '_' + visible}
                      index={index}
                      item={item}
                      mode={props.mode}
                    />
                  );
                } else {
                  return null;
                }
              })}

              {props.mode === CompMode.COMPOSE && (
                <H5 className={'empty-space'}>
                  {t('section.direction.drag_question')}
                </H5>
              )}

              <SingleQuestionToolbarHandle
                sectionId={props.item.lessonSectionId}
                resources={props.item.group.content}
              />
            </SectionDropColumn>
          </div>
        )}
      </div>

      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.footer && (
          <div className={'lesson-section-footer-container'}>
            <ContentViewer contentData={props.item.content.footer} />
          </div>
        )}
    </FixedGroupSectionStyle>
  );
};

export const FixedGroupSectionStyle = styled.div`
  .lesson-section-header-container {
    margin-bottom: 0.5em;
  }

  .lesson-section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &.section-compose {
    .fixed-column {
      border: 2px dashed #ccc;
      padding: 2em;
    }
  }

  &.left-right {
    .lesson-section-container {
      .header-group {
        order: 1;
      }

      .content-group {
        order: 2;
      }
    }
  }

  &.right-left {
    .lesson-section-container {
      .header-group {
        order: 2;
      }

      .content-group {
        order: 1;
      }
    }
  }

  &.top-bottom {
    .lesson-section-container {
      flex-direction: column;
      gap: 1em;

      .header-group {
        order: 1;
      }

      .content-group {
        order: 2;
      }
    }
  }

  &.bottom-top {
    .lesson-section-container {
      flex-direction: column;
      gap: 1em;

      .header-group {
        order: 2;
      }

      .content-group {
        order: 1;
      }
    }
  }
`;

export const FixedGroupSectionData: FixedGroupSectionProps = {
  lessonSectionId: -1,
  code: COMPONENT_UTILS.generateUID(),
  type: SectionTypeEnum.fixed_group,
  name: 'Fixed group layout',

  setting: {
    width: [66, 33],
    layout: 'left-right',
    showJump: true,
    gap: '1em',
  },

  group: {
    header: [],
    content: [],
  },
};
