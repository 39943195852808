import React, { ReactNode, useMemo } from 'react';
import { List, TableProps } from 'antd';
import { FilterValue, TablePaginationConfig } from 'antd/lib/table/interface';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { ResponsiveSwitchContent } from '@components/responsive/Responsive';
import { ResponsiveScreen } from '@hooks/useMedia';
import { TableWrap } from './style';
import { Pagination } from 'antd';

export interface TablePaginationRes<T> {
  content: T[];
  pageable: {
    pageNumber: number;
    pageSize: number;
  };
  totalElements: number;
}

export interface TablePaginationProps<T> extends TableProps<T> {
  params: PaginationReq;
  data?: TablePaginationRes<T>;
  isLoading: boolean;
  refresh: (params: PaginationReq) => void;
  columns: any[];
  processDataRow?: (record: T) => any;

  responsive?: {
    screen: ResponsiveScreen[];
    render: (record: T) => ReactNode;
  };
}

export const TableDefault = (props: TableProps<any>) => {
  return <TableWrap className={'gstudy-table-default'} bordered {...props} />;
};

const TablePagination = ({
  params,
  data,
  isLoading,
  refresh,
  columns,
  processDataRow,
  ...props
}: TablePaginationProps<any>) => {
  const _columns = useMemo(() => {
    return columns.map((col) => {
      if (params.sort && params.sort === col.dataIndex) {
        const sortOrder = params.direction === 'descend' ? 'descend' : 'ascend';
        return {
          ...col,
          sortOrder: sortOrder,
          defaultSortOrder: sortOrder,
        };
      } else {
        return { ...col };
      }
    });
  }, [columns, params]);

  const tableSource = useMemo(() => {
    return data?.content.map((item: any) => {
      if (processDataRow) {
        return processDataRow(item);
      } else {
        return item;
      }
    });
  }, [data, processDataRow]);

  const tablePagination: TablePaginationConfig | false = useMemo(() => {
    return data && data.totalElements > data.pageable.pageSize
      ? {
          size: 'default',
          current: data ? data.pageable.pageNumber + 1 : 1,
          pageSize: data ? data.pageable.pageSize : 0,
          total: data ? data.totalElements : 0,
          showTotal: (total: number, range: number[]) => {
            return `${range[0]}-${range[1]} of ${total} items.`;
          },
          hideOnSinglePage: true,
          responsive: true,
          showSizeChanger: false,
        }
      : false;
  }, [data]);

  const getUniqueRowKey = (record: any) => {
    return record.key;
  };

  const onPageChange = (page: number, pageSize: number) => {
    const newParams: PaginationReq = {
      page: page ?? 1,
      size: pageSize ?? MAX_PAGE_SIZE,
    };

    refresh(newParams);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    const newParams: PaginationReq = {
      page: pagination.current ? pagination.current : 1,
      size: pagination.pageSize
        ? parseInt(pagination.pageSize + '')
        : MAX_PAGE_SIZE,
      sort: sorter.field ? sorter.field + '' : '',
      direction: sorter.order ? sorter.order : null,
    };

    if (
      getSortFilterValue(newParams.sort) !== getSortFilterValue(params.sort) ||
      getSortFilterValue(newParams.direction) !==
        getSortFilterValue(params.direction)
    ) {
      newParams.page = 1;
    }

    refresh(newParams);
  };

  return (
    <>
      {props.responsive ? (
        <ResponsiveSwitchContent
          screens={
            props.responsive.screen ?? [
              ResponsiveScreen.sm,
              ResponsiveScreen.xs,
            ]
          }
          excludeScreenContent={
            <TableWrap
              bordered
              loading={isLoading}
              columns={_columns}
              dataSource={tableSource}
              pagination={tablePagination}
              onChange={handleTableChange}
              rowKey={getUniqueRowKey}
              {...props}
            />
          }
        >
          <List
            itemLayout="vertical"
            dataSource={data?.content}
            renderItem={(item) => {
              return props.responsive!.render(item);
            }}
          />

          {data && data.totalElements > data.pageable.pageSize && (
            <div
              className={'pagination-wrapper'}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Pagination
                defaultCurrent={data ? data.pageable.pageNumber + 1 : 1}
                total={data ? data.totalElements : 0}
                onChange={onPageChange}
              />
            </div>
          )}
        </ResponsiveSwitchContent>
      ) : (
        <TableWrap
          bordered
          loading={isLoading}
          columns={_columns}
          dataSource={tableSource}
          pagination={tablePagination}
          onChange={handleTableChange}
          rowKey={getUniqueRowKey}
          {...props}
        />
      )}
    </>
  );
};

export default TablePagination;

const getSortFilterValue = (value?: string) => {
  return value ? value : '';
};
