import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { Select } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { PlaceValueSettingProps } from '@cms/comps/math/operation/place-value/PlaceValueComp';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';

export const PlaceValueSetting = (props: {
  setting: PlaceValueSettingProps;
  onSettingChange: (newSetting: PlaceValueSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.label.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('component.label.select_layout')}
            onChange={(value) => formik.setFieldValue('layout', value)}
            value={formik.values.layout}
          >
            <Select.Option key={'horizontal'} value={'horizontal'}>
              {t('component.label.horizontal')}
            </Select.Option>

            <Select.Option key={'vertical'} value={'vertical'}>
              {t('component.label.vertical')}
            </Select.Option>
          </Select>
        </InputGroup>
      </FormikProvider>
    </SettingStyle>
  );
};
