import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { EntityStatusEnum } from '@services/model/common';
import Card, { CardClassEnum } from '@components/card';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import {
  ComposingStatus,
  ComposingWithStatus,
} from '@components/common/lesson/ComposingStatus';
import { DateLabel } from '@components/text/DateLabel';

import TablePagination from '@components/table/TablePagination';
import { ResponsiveScreen } from '@hooks/useMedia';
import { ProductTeacherItem } from '@components/product/ProductTeacherItem';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { ProductFilterForm } from './ProductFilterForm';
import { ProductActionEnum } from '@modules/product/container/product/TeacherProductGroup';
import { ComposeProductAction } from '@modules/product/components/product/ComposeProductAction';
import { ProductRes } from '@modules/product/services/product_model';

export const ManageProductTable = (props: { action: ProductActionEnum }) => {
  const { t } = useTranslation();

  const [getMyProducts, { data, isFetching, isLoading }] =
    ProductAPI.endpoints.getMyProducts.useLazyQuery({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',

    searchTerm: '',
    status: EntityStatusEnum.EMPTY,
  });

  const columns = [
    {
      title: t('label.thumbnails'),
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      width: 90,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('label.created_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 180,
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      sorter: false,
      align: 'center',
      width: 120,
    },
  ];

  useEffect(() => {
    refreshPage();
  }, [params]);

  useEffect(() => {
    if (props.action === ProductActionEnum.REFRESH) {
      refreshPage();
    }
  }, [props.action]);

  const refreshPage = useCallback(() => {
    getMyProducts(params);
  }, [params]);

  const renderMobile = (item: ProductRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <ProductTeacherItem item={item} onChange={refreshPage} />
      </Card>
    );
  };

  const processDataRow = (item: ProductRes) => {
    return {
      key: item.productId,
      thumbnails: (
        <ThumbnailsImg
          type={'icon'}
          src={item.icon}
          ratio={ImageScale.product.ratio}
        />
      ),
      name: (
        <ComposingWithStatus status={item.status}>
          {item.name}
        </ComposingWithStatus>
      ),
      createdDate: <DateLabel label={item.createdDate} />,
      publishedDate: <DateLabel label={item.createdDate} />,
      status: <ComposingStatus status={item.status} />,
      actions: <ComposeProductAction item={item} onChange={refreshPage} />,
    };
  };

  const handleOnSubmit = (keyword: string, status: EntityStatusEnum) => {
    setParams((prev) => {
      return {
        ...prev,
        searchTerm: keyword,
        status: status,
        page: 1,
      };
    });
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return {
        ...prev,
        ...params,
      };
    });
  };

  return (
    <>
      <Card className={CardClassEnum.rectangle_box}>
        <ProductFilterForm
          keyword={params.searchTerm}
          status={params.status!}
          onSubmit={handleOnSubmit}
        />
      </Card>

      <TablePagination
        params={params}
        isLoading={isFetching || isLoading}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </>
  );
};
