import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { LessonAPI } from '@modules/product/services/LessonAPI';
import { ProductTocRes } from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const SelectProductLessonContext = createContext({
  ready: false as boolean,
  productId: -1 as number,
  productTocId: -1 as number,
  productTocs: [] as ProductTocRes[],
  selectLessons: [] as LessonRes[],
  selectedLessonIds: [] as number[],
  onRemoveLesson: (lessonId: number) => {
    console.log(lessonId);
  },
  onSelectLesson: (lessons: LessonRes[]) => {
    console.log(lessons);
  },
});

export const SelectProductLessonContextProvider = (props: {
  productId: number;
  productTocId: number;
  lessonIds: number[];
  onChange: (lessons: LessonRes[]) => void;
  children: ReactNode;
}) => {
  const [ready, setReady] = useState(false);
  const [productTocs] = useState<ProductTocRes[]>([]);
  const [selectedLessonIds] = useState<number[]>(props.lessonIds);

  const [selectLessons, setSelectLessons] = useState<LessonRes[]>([]);

  const [findByIds, { data, isSuccess }] =
    LessonAPI.endpoints.findByIds.useLazyQuery();

  useEffect(() => {
    // load lessons ids
    if (props.lessonIds && props.lessonIds.length > 0) {
      findByIds(props.lessonIds);
    } else {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    // update current lesson...
    if (data && isSuccess) {
      setSelectLessons(data);
      setReady(true);
    }
  }, [data]);

  useEffect(() => {
    props.onChange(selectLessons);
  }, [selectLessons]);

  const onSelectLesson = (lessons: LessonRes[]) => {
    setSelectLessons(lessons);
  };

  const onRemoveLesson = (lessonId: number) => {
    setSelectLessons((prev) => {
      return [...prev].filter((ls) => {
        return ls.lessonId !== lessonId;
      });
    });
  };

  return (
    <SelectProductLessonContext.Provider
      value={{
        ready: ready,
        productId: props.productId,
        productTocId: props.productTocId,
        productTocs,
        selectLessons,
        selectedLessonIds,
        onRemoveLesson,
        onSelectLesson,
      }}
    >
      {props.children}
    </SelectProductLessonContext.Provider>
  );
};

export const useSelectProductLessonContext = () => {
  const context = useContext(SelectProductLessonContext);
  if (!context) {
    throw new Error(
      'You must wrap container by SelectProductLessonContextProvider'
    );
  }
  return context;
};
