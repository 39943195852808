import React, { ReactNode } from 'react';
import { InputProps as AntInputProps } from 'antd/lib/input';
import { connect } from 'formik';
import { get as getPath } from 'lodash';

import { Input as AntInput } from 'antd';
import { ErrMessageStyle } from '@components/input/style';
import { InputGroup } from './InputGroup';

export interface InputProps extends AntInputProps {
  row?: number;
  name: string;
  addedClass?: string;
  label?: ReactNode;
  hint?: ReactNode;
  placeHolder?: string | undefined;
  formik?: any;
}

const Input = ({
  name,
  row,
  addedClass,
  label,
  hint,
  placeHolder,
  required,
  formik,
  ...props
}: InputProps) => {
  const getInputField = () => {
    if (props.type === 'password') {
      return (
        <AntInput.Password
          autoComplete={'false'}
          autoCorrect={'false'}
          placeholder={placeHolder ?? ''}
          name={name}
          size="large"
          required={required}
          {...props}
        />
      );
    } else if (props.type === 'textarea') {
      return (
        // @ts-ignore
        <AntInput.TextArea
          autoComplete={'false'}
          autoCorrect={'false'}
          placeholder={placeHolder ?? ''}
          name={name}
          size="large"
          rows={row ? row : 4}
          style={props.style}
          required={required}
          {...props}
        />
      );
    } else {
      return (
        <AntInput
          autoComplete={'false'}
          autoCorrect={'false'}
          placeholder={placeHolder ?? ''}
          name={name}
          required={required}
          size="large"
          {...props}
        />
      );
    }
  };

  const inputField = getInputField();

  return (
    <InputGroup
      addedClass={addedClass}
      label={label}
      hint={hint}
      required={required}
    >
      {inputField}

      {getPath(formik?.errors, name) && getPath(formik?.touched, name) && (
        <ErrMessageStyle className="error-message">
          {getPath(formik?.errors, name)}
        </ErrMessageStyle>
      )}
    </InputGroup>
  );
};

export default connect(Input);
