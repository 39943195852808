import { FormikProvider, useFormik } from 'formik';
import { Default_Gutter } from '@components/grid';
import { Col, Row } from 'antd';
import Checkbox from '@components/input/Checkbox';
import React, { useEffect } from 'react';
import {
  SchoolSettingAPI,
  SchoolSettingRes,
  UpdateSchoolRegisterSettingRes,
} from '@services/private/SchoolSettingAPI';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { useLoadSchoolSetting } from '@modules/setting/container/school-setting/useLoadSchoolSetting';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H2 } from '@components/typography';

export const SchoolRegisterSettingForm = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onError: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();

  const { data, isFetching, isSuccess } = useLoadSchoolSetting(props.schoolId);

  const [updateRegisterSetting, { isLoading }] =
    SchoolSettingAPI.endpoints.updateRegisterSetting.useMutation({});

  const [updateRegisterSettingAdmin, { isLoading: isAdminLoading }] =
    SchoolSettingAPI.endpoints.updateRegisterSettingAdmin.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      allowStudentRegister: false,
      allowTeacherRegister: false,
      allowParentRegister: false,
    },

    onSubmit: (values) => {
      if (props.schoolId != null && props.schoolId > 0) {
        updateSchoolSettingAdmin({ ...values, schoolId: props.schoolId });
      } else {
        updateSchoolSetting(values);
      }
    },
  });

  useEffect(() => {
    if (data && isSuccess) {
      restoreSettingData(data);
    }
  }, [data]);

  const restoreSettingData = (data: SchoolSettingRes) => {
    formik.setValues({
      allowStudentRegister: data.allowParentRegister,
      allowTeacherRegister: data.allowParentRegister,
      allowParentRegister: data.allowParentRegister,
    });
  };

  const updateSchoolSetting = (request: UpdateSchoolRegisterSettingRes) => {
    updateRegisterSetting(request)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  const updateSchoolSettingAdmin = (
    setting: Required<UpdateSchoolRegisterSettingRes>
  ) => {
    updateRegisterSettingAdmin(setting)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  return (
    <>
      <HeaderGroup style={{ marginTop: 0, marginBottom: 20 }}>
        <H2>School Registration</H2>
      </HeaderGroup>

      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <Checkbox
              name={'allowStudentRegister'}
              checked={formik.values.allowStudentRegister}
              value={formik.values.allowStudentRegister}
              formik={formik}
              onChange={formik.handleChange}
            >
              Allow student register
            </Checkbox>

            <Checkbox
              name={'allowTeacherRegister'}
              checked={formik.values.allowTeacherRegister}
              value={formik.values.allowTeacherRegister}
              formik={formik}
              onChange={formik.handleChange}
            >
              Allow Teacher register
            </Checkbox>

            <Checkbox
              name={'allowParentRegister'}
              checked={formik.values.allowParentRegister}
              value={formik.values.allowParentRegister}
              formik={formik}
              onChange={formik.handleChange}
            >
              Allow parent register
            </Checkbox>
          </Col>
        </Row>
        <div
          className="d-flex submit-container"
          style={{ justifyContent: 'space-between' }}
        >
          <ButtonGroup>
            <Button
              type="primary"
              shape={'round'}
              onClick={formik.submitForm}
              icon={IconUtils.save}
              loading={isLoading || isFetching || isAdminLoading}
            >
              <>{t('button.update')}</>
            </Button>

            {props.onCancel && (
              <Button type="default" shape={'round'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </FormikProvider>
    </>
  );
};
