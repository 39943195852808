import React from 'react';

import { EditStandardSetForm } from '@modules/product/components/standards/EditStandardSetForm';

import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { StandardSetRes } from '@modules/product/services/standard_model';
const { TabPane } = Tabs;

export function ManageStandardSet(props: {
  data: StandardSetRes | null;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div>
      {!props.data && (
        <EditStandardSetForm
          mode={'manage'}
          data={props.data}
          successHandle={props.successHandle}
          onCloseFunc={props.onCloseFunc}
        />
      )}

      {props.data && props.data.standardSetId > 0 && (
        <Tabs defaultActiveKey={'metadata'} tabPosition={'left'}>
          <TabPane tab={t('standard_set.info')} key={'metadata'}>
            <EditStandardSetForm
              mode={'manage'}
              data={props.data}
              successHandle={props.successHandle}
              onCloseFunc={props.onCloseFunc}
            />
          </TabPane>

          <TabPane tab={t('standard_set.standards')} key={'standards'}>
            {/*<ListStandardTable*/}
            {/*  data={props.data}*/}
            {/*  successHandle={props.successHandle}*/}
            {/*  onCloseFunc={props.onCloseFunc}*/}
            {/*/>*/}
          </TabPane>
        </Tabs>
      )}
    </div>
  );
}
