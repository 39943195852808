import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Dropdown, notification, Row } from 'antd';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

import { H1, H4 } from '@components/typography';
import { DateLabel } from '@components/text/DateLabel';
import {
  LessonTemplateAPI,
  LessonTemplateRes,
} from '@modules/product/services/LessonTemplateAPI';
import { EditLessonTemplateForm } from '../container/lesson-template/EditLessonTemplateForm';
import useConfirmModal from '@components/modal/ConfirmModal';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { EntityStatus } from '@components/status/EntityStatus';
import { IconUtils } from '@utils/IconUtils';
import Card, { CardClassEnum } from '@components/card';
import { InputGroup } from '@components/input/InputGroup';
import styled from 'styled-components';
import { Default_Gutter } from '@components/grid';
import { ResponsiveScreen } from '@hooks/useMedia';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

enum LessonTemplatePageMode {
  DEFAULT = '',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
  COMPOSE = 'compose',
  CLONE = 'clone',
  PUBLISH = 'publish',
}

const LessonTemplatePage = () => {
  const router = useRouter();
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();

  const { ifAnyGranted } = useSecurity();

  const [findAll, { data, isFetching }] =
    LessonTemplateAPI.endpoints.findAll.useLazyQuery();

  const [deleteItem] = LessonTemplateAPI.endpoints.deleteItem.useMutation({});
  const [publishItem] = LessonTemplateAPI.endpoints.publishItem.useMutation({});
  const [cloneItem] = LessonTemplateAPI.endpoints.cloneItem.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'ascend',
  });

  const [action, setAction] = useState<{
    action: LessonTemplatePageMode;
    params: number;
  }>({ action: LessonTemplatePageMode.DEFAULT, params: -1 });

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      align: 'center',
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      width: 150,
      align: 'center',
    },
    {
      title: t('label.modifiedDate'),
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      width: 150,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'action',
      key: 'action',
      width: 120,
      align: 'center',
    },
  ];

  useEffect(() => {
    findAll(params);
  }, [params]);

  useEffect(() => {
    return () => {
      setParams({
        page: 1,
        size: MAX_PAGE_SIZE,
        sort: 'createdDate',
        direction: 'descend',
      });
    };
  }, []);

  const handleClickMenu = (
    item: LessonTemplateRes,
    action: LessonTemplatePageMode
  ) => {
    if (action === LessonTemplatePageMode.COMPOSE) {
      composeLessonContent(item.lessonTemplateId);
    } else {
      setAction({
        action: action,
        params: item.lessonTemplateId,
      });
    }
  };

  const getDropdownMenu = (item: LessonTemplateRes) => {
    const menuItems = [];

    if (item.owner) {
      menuItems.push({
        key: LessonTemplatePageMode.COMPOSE,
        icon: IconUtils.compose_lesson,
        onClick: () => handleClickMenu(item, LessonTemplatePageMode.COMPOSE),
        label: t('lesson_example.actions.compose'),
      });

      menuItems.push({
        key: 'divider-1',
        type: 'divider',
      });

      menuItems.push({
        key: LessonTemplatePageMode.UPDATE,
        icon: IconUtils.lesson.edit,
        onClick: () => handleClickMenu(item, LessonTemplatePageMode.UPDATE),
        label: t('label.update'),
      });
    }

    menuItems.push({
      key: LessonTemplatePageMode.CLONE,
      icon: IconUtils.actions.clone,
      onClick: () => handleClone(item),
      label: t('label.create_a_copy'),
    });

    if (item.owner) {
      menuItems.push({
        key: LessonTemplatePageMode.DELETE,
        icon: IconUtils.lesson.remove,
        onClick: () => handleClickRemove(item),
        label: t('label.remove'),
      });

      if (ifAnyGranted([RoleEnum.ADMIN, RoleEnum.SCHOOL])) {
        menuItems.push({
          key: 'divider-2',
          type: 'divider',
        });

        menuItems.push({
          key: LessonTemplatePageMode.PUBLISH,
          icon: IconUtils.lesson.publish,
          onClick: () => handlePublishAction(item),
          label: 'Publish Template',
        });
      }
    }
    return menuItems;
  };

  const processDataRow = (item: LessonTemplateRes) => {
    return {
      key: item.lessonTemplateId,
      name: item.name,
      description: item.description,
      status: <EntityStatus status={item.status} />,
      createdDate: <DateLabel label={item.createdDate} />,
      modifiedDate: <DateLabel label={item.modifiedDate} />,
      action: (
        <Dropdown
          menu={{ items: getDropdownMenu(item) }}
          placement="topRight"
          arrow
        >
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      ),
    };
  };

  const renderMobile = (item: LessonTemplateRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <LessonTemplateItemStyle className={'lesson-template-item'}>
          <div className={'lesson-template-info'}>
            <H4>{item.name}</H4>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col span={24}>
                <InputGroup>{item.description}</InputGroup>
              </Col>

              <Col span={24}>
                <InputGroup label={t('label.status')}>
                  <EntityStatus status={item.status} />
                </InputGroup>
              </Col>

              <Col span={12}>
                <InputGroup label={t('label.createdDate')}>
                  <DateLabel label={item.createdDate} />
                </InputGroup>
              </Col>

              <Col span={12}>
                <InputGroup label={t('label.modifiedDate')}>
                  <DateLabel label={item.modifiedDate} />
                </InputGroup>
              </Col>
            </Row>
          </div>

          <div className={'lesson-template-actions'}>
            <Dropdown
              menu={{ items: getDropdownMenu(item) }}
              placement="topRight"
              arrow
            >
              <Button
                type="default"
                shape="circle"
                icon={IconUtils.more_icon}
              />
            </Dropdown>
          </div>
        </LessonTemplateItemStyle>
      </Card>
    );
  };

  const refreshData = (params: any) => {
    setParams(params);
  };

  const composeLessonContent = (lessonTemplateId: number) => {
    router.push(
      SiteMap.content.metadata.lesson_template_compose_gen(lessonTemplateId)
    );
  };

  const handleOnSuccess = (
    res: LessonTemplateRes,
    composeTemplate: boolean
  ) => {
    if (composeTemplate) {
      composeLessonContent(res.lessonTemplateId);
    } else {
      findAll(params);
      handleOnCancel();
    }
  };

  const handleOnCancel = () => {
    setAction({
      action: LessonTemplatePageMode.DEFAULT,
      params: -1,
    });
  };

  const handleClickRemove = (item: LessonTemplateRes) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('confirm.confirmRemoveItem'),
      t('label.update'),
      t('label.cancel'),
      (r) => {
        if (r) {
          removeLessonItem(item);
        }
      }
    );
  };

  const handlePublishAction = (item: LessonTemplateRes) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('lesson_template.warning.confirm_publish'),
      t('label.publish'),
      t('label.cancel'),
      (r) => {
        if (r) {
          publishLessonTemplate(item);
        }
      }
    );
  };

  const handleClone = (item: LessonTemplateRes) => {
    cloneItem(item.lessonTemplateId)
      .unwrap()
      .then(() => {
        handleOnSuccess(item, false);
      })
      .catch(() => {
        notification.error({
          message: t('registration.teacher.warning.error'),
          description: t('registration.teacher.warning.error_message'),
          placement: 'bottomRight',
        });
        handleOnCancel();
      });
  };

  const handleOnAddTemplate = () => {
    setAction({
      action: LessonTemplatePageMode.ADD,
      params: -1,
    });
  };

  const removeLessonItem = (item: LessonTemplateRes) => {
    deleteItem(item.lessonTemplateId)
      .unwrap()
      .then(() => {
        handleOnSuccess(item, false);
      })
      .catch(() => {
        notification.error({
          message: t('registration.teacher.warning.error'),
          description: t('registration.teacher.warning.error_message'),
          placement: 'bottomRight',
        });
        handleOnCancel();
      });
  };

  const publishLessonTemplate = (item: LessonTemplateRes) => {
    publishItem(item.lessonTemplateId)
      .unwrap()
      .then(() => {
        handleOnSuccess(item, false);
      })
      .catch(() => {
        notification.error({
          message: t('registration.teacher.warning.error'),
          description: t('registration.teacher.warning.error_message'),
          placement: 'bottomRight',
        });
        handleOnCancel();
      });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('lesson_template.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={handleOnAddTemplate}
          />
        </H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === LessonTemplatePageMode.ADD && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('lesson_template.add')}
          content={
            <EditLessonTemplateForm
              lessonTemplateId={-1}
              successHandle={(res) => handleOnSuccess(res, true)}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === LessonTemplatePageMode.UPDATE && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('lesson_template.update')}
          content={
            <EditLessonTemplateForm
              lessonTemplateId={action.params}
              successHandle={(res) => handleOnSuccess(res, false)}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

export default LessonTemplatePage;

const LessonTemplateItemStyle = styled.div`
  position: relative;

  .form-group {
    margin-top: 1em;
  }

  .lesson-template-actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;
