import { getProductParams, URL_PARSE } from '@router/Router';
import { RoleEnum } from '@app/redux/slices/roles';

export const SiteMap = {
  index: '/', // DONE
  dynamic_page: '/:path', // DONE

  auth: {
    login: '/login', // DONE
    login_admin: '/login/administrator', // DONE
    login_to_another_school: '/login/school/:clientId',

    logout: '/logout', // DONE
    launch: '/launch',
    school_launch: '/school/launch',
    forgot_password: '/forgot-password', // DONE
    reset_password: '/reset-password', // DONE
  },

  // register
  register: {
    index: '/register', // DONE
    student: '/register/student', // DONE
    teacher: '/register/teacher', // DONE
    parent: '/register/parent', // DONE

    success: '/register/success', // DONE
    active_user: '/register/active-user', // DONE
    active_account: '/register/active-account', //
  },

  public: {
    //page and feature
    products: '/products', // DONE
    product: '/products/:productId/:productName',
    product_gen: (productId: number, productName: string) => {
      return `/products/${productId}/${URL_PARSE.removeAccents(productName)}`;
    },
    product_lesson:
      '/products/:productId/:productName/lessons/:lessonId/:lessonName',
    product_lesson_gen: (
      productId: number,
      productName: string,
      productTocId: number,
      lessonId: number,
      lessonName: string
    ) => {
      const pName = URL_PARSE.removeAccents(productName);
      const lName = URL_PARSE.removeAccents(lessonName);
      return `/products/${productId}/${pName}/lessons/${lessonId}/${lName}?productTocId=${productTocId}`;
    },

    lessons: '/lessons', // DONE
    lessons_view: '/lessons/:lessonId/view/:lessonName', // DONE
    lesson_view_gen: (lessonId: number, lessonName: string) => {
      return `/lessons/${lessonId}/view/${URL_PARSE.removeAccents(lessonName)}`;
    },

    courses: '/courses',
    course_detail: '/courses/:id/:name',
    course_detail_gen: (courseId: number, courseName: string) => {
      return `/courses/${courseId}/${URL_PARSE.removeAccents(courseName)}`;
    },

    teachers: '/teachers',
    teacher_view: '/teachers/:shortName',
    teacher_view_gen: (shortName: string) => {
      return `/teachers/${shortName}`;
    },

    page_404: '/404', // DONE
    page_403: '/403', // DONE
    page_incomplete: '/incomplete', // DONE
    version: '/version', // DONE

    view_qa_code: (link: string) => {
      return `/s/code/?code=${encodeURIComponent(link)}`;
    },

    view_survey: '/surveys/:surveyId/:surveyName',
    view_survey_gen: (surveyId: number, name: string, accessCode: string) => {
      return `/surveys/${surveyId}/${URL_PARSE.removeAccents(
        name
      )}?access_code=${encodeURIComponent(accessCode)}`;
    },

    view_certificate: '/certificate/:code',
    view_certificate_gen: (code: string) => {
      return `/certificate/${code}`;
    },

    exercise: {
      view_exercise: '/anonymous/exercise/:exerciseId/view',
      view_exercise_gen: (exerciseId: number) => {
        return `/anonymous/exercise/${exerciseId}/view`;
      },

      grade_exercise: '/anonymous/exercise/:exerciseId/grade',
      grade_exercise_gen: (exerciseId: number) => {
        return `/anonymous/exercise/${exerciseId}/grade`;
      },

      review_exercise: '/anonymous/exercise/:exerciseId/review',
      review_exercise_gen: (exerciseId: number) => {
        return `/anonymous/exercise/${exerciseId}/review`;
      },
    },
  },

  integration: {
    lti: {},
  },

  lti: {
    redirect: '/login/oauth2/redirect',

    oauth_app: '/lti/oauth-app', // DONE

    login: '/login/lti',
    login_testing: '/login/oauth2/testing', // DONE

    product_test: '/lti/products/testing', // DONE
    product_view: '/lti/products/:product_code/view', // DONE
    product_view_gen: (
      accessToken: string,
      uid: string,
      product_code: string
    ) => {
      const params = URL_PARSE.convertParams({
        access_token: accessToken,
        uid: uid,
      });
      return `/lti/products/${product_code}/view?${params}`;
    },
  },

  template: {
    features: '/features', // DONE
    template: '/template', // DONE
    template_audio_recording: '/template/audio-recording', // DONE
    template_coordinate: '/template/coordinate', // DONE
    template_dnd: '/template/drag-n-drop', // DONE
    template_pdf: '/template/pdf', // DONE
    template_video: '/template/video', // DONE
  },

  private: {
    dashboard: '/dashboard', //**
    my_calendar: '/my-calendar', // DONE
    my_messages: '/my-messages',
    my_notification: '/my-notification',
    my_class: {
      roles: [
        RoleEnum.STUDENT,
        RoleEnum.PARENT,
        RoleEnum.TEACHER,
        RoleEnum.SUPPORT,
        RoleEnum.SCHOOL,
      ],

      list: '/my-class',
      view: '/my-class/:classId/:code',
      view_gen: (classId: number, code: string) => {
        return `/my-class/${classId}/${URL_PARSE.removeAccents(code)}`;
      },
    },

    my_lesson: {
      list: '/my-lesson', // DONE
      create: '/my-lesson/create', // DONE
      create_gen: (lessonExampleId: number) => {
        const params = URL_PARSE.convertParams({
          lessonExampleId: lessonExampleId,
        });
        return `/my-lesson/create?${params}`;
      },

      compose: '/my-lesson/:id/compose', // DONE
      compose_gen: (lessonId: number, from: string) => {
        return `/my-lesson/${lessonId}/compose?from=${from}`;
      },

      practice: '/my-lesson/:id/practice', // DONE
      practice_gen: (lessonId: number) => {
        return `/my-lesson/${lessonId}/practice`;
      },
    },

    my_library: {
      roles: [RoleEnum.STUDENT, RoleEnum.TEACHER, RoleEnum.PARENT],
      // list: '/my-library', // DONE
      lessons: '/my-library/lessons', // DONE
      view_lesson: '/my-library/lessons/:id', // DONE
      view_lesson_gen: (
        lessonId: number,
        productId?: number | null,
        productTocId?: number | null
      ) => {
        const params = URL_PARSE.convertParams({
          productId: productId,
          productTocId: productTocId,
        });

        return `/my-library/lessons/${lessonId}?${params}`;
      },

      products: '/my-library/products',

      // for student
      view_product: '/my-library/products/:id',
      view_product_gen: (productId: number) => {
        return `/my-library/products/${productId}`;
      },

      // for teachers
      review_product: '/my-library/products/:id/review',
      review_product_gen: (productId: number) => {
        return `/my-library/products/${productId}/review`;
      },
    },

    my_account: {
      index: '/my-account', // DONE
      certificate: '/my-account/certificate',
      public_profile: '/my-account/public-profile', // DONE
      review: '/my-account/public-profile', // DONE
    },

    my_exercise: {
      start: '/exercises/:exerciseId/start',
      start_gen: (
        exerciseId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        return `/exercises/${exerciseId}/start${params}`;
      },

      grade: '/exercises/:exerciseId/grade',
      grade_gen: (
        exerciseId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        return `/exercises/${exerciseId}/grade${params}`;
      },

      review: '/exercises/:exerciseId/review',
      review_gen: (
        exerciseId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        return `/exercises/${exerciseId}/review${params}`;
      },
    },

    my_assignment: {
      roles: [RoleEnum.STUDENT, RoleEnum.PARENT, RoleEnum.TEACHER],
      list: '/my-assignments',

      start: '/my-assignments/:assignmentInstanceId/start/',
      start_gen: (assignmentInstanceId: number) => {
        return `/my-assignments/${assignmentInstanceId}/start`;
      },

      review: '/my-assignments/:id/review',
      review_gen: (exerciseId: number) => {
        return `/my-assignments/${exerciseId}/review`;
      },
    },
  },

  assignments: {
    roles: [RoleEnum.TEACHER, RoleEnum.SUPPORT, RoleEnum.SCHOOL],
    list: '/assignments',

    create: '/assignments/create',
    create_gen: (lessonId: number, productTocId: number, productId: number) => {
      const params = URL_PARSE.convertParams({
        lessonId: lessonId,
        productTocId: productTocId,
        productId: productId,
      });
      return `/assignments/create/?${params}`;
    },

    edit: '/assignments/edit/:id',
    edit_gen: (assignmentInstanceId: number) => {
      return `/assignments/edit/${assignmentInstanceId}`;
    },

    grading: '/assignments/grading/:id',
    grade_gen: (assignmentInstanceId: number, exerciseId?: number) => {
      if (exerciseId != null && exerciseId > 0) {
        const params = URL_PARSE.convertParams({
          exerciseId: exerciseId,
        });
        return `/assignments/grading/${assignmentInstanceId}${params}`;
      } else {
        return `/assignments/grading/${assignmentInstanceId}`;
      }
    },

    review: '/assignments/review/:id',
    review_gen: (exerciseId: number) => {
      return `/assignments/review/${exerciseId}`;
    },
  },

  program: {
    roles: [RoleEnum.SCHOOL],
    program: '/programs',
    certificate: '/programs/certificates',
    categories: '/programs/categories',
    grades: '/programs/grades',
    subjects: '/programs/subjects',
  },

  content: {
    metadata: {
      lesson_type: '/content/lesson-type',
      roles: [RoleEnum.CREATE_LESSON_TEMPLATE, RoleEnum.SCHOOL],

      lesson_template: '/content/lesson-template',
      lesson_template_compose: '/content/lesson-template/:id/compose',
      lesson_template_compose_gen: (lessonTemplateId: number) => {
        return `/content/lesson-template/${lessonTemplateId}/compose`;
      },

      lesson_template_roles: [
        RoleEnum.CREATE_LESSON_TEMPLATE,
        RoleEnum.PUBLISH_PAGE,
        RoleEnum.SCHOOL,
      ],
    },

    example: {
      list: '/example',
      collection: '/example/collection',
      lesson: '/example/lesson',
      page: '/example/page',
    },

    product: {
      list: '/content/products',
      roles: [RoleEnum.CREATE_PRODUCT, RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL],

      review: '/content/products/:id/review',
      review_gen: (productId: number) => {
        return `/content/products/${productId}/review`;
      },

      publish: '/content/products/:id/publish',
      publish_roles: [RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL],
      publish_gen: (productId: number) => {
        return `/content/products/${productId}/publish`;
      },

      toc: '/content/products/:id/table-of-content',
      toc_gen: (productId: number) => {
        return `/content/products/${productId}/table-of-content`;
      },

      accessible: '/content/products/:productId/accessible',
      accessible_roles: [RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL],
      accessible_gen: (productId: number) => {
        return `/content/products/${productId}/accessible`;
      },
    },

    lesson: {
      list: '/content/lessons', // DONE
      create: '/content/lessons/create', // DONE
      compose: '/content/lessons/:lessonId/compose', // DONE
      compose_simple: '/content/lessons/:lessonId/compose/simple', // DONE
      compose_gen: (
        lessonId: number,
        proUser: boolean,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        if (proUser) {
          return `/content/lessons/${lessonId}/compose${params}`;
        } else {
          return `/content/lessons/${lessonId}/compose/simple${params}`;
        }
      },

      build_resource: '/content/lessons/:lessonId/compose/build',
      build_resource_gen: (
        lessonId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        return `/content/lessons/${lessonId}/compose/build${params}`;
      },

      build_section: '/content/lessons/:lessonId/compose/sections',
      build_section_gen: (
        lessonId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        return `/content/lessons/${lessonId}/compose/sections${params}`;
      },

      review: '/content/lessons/:id/review',
      review_gen: (
        lessonId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        return `/content/lessons/${lessonId}/review${params}`;
      },

      compose_resource:
        '/content/lessons/:lessonId/compose/resources/:resourceId',
      compose_resource_simple:
        '/content/lessons/compose/:lessonId/resources/:resourceId/simple',
      compose_resource_gen: (
        lessonId: number,
        proUser: boolean,
        resourceId: number,
        productId?: number,
        productTocId?: number
      ) => {
        const params = getProductParams(productId, productTocId);
        if (proUser) {
          return `/content/lessons/${lessonId}/compose/resources/${resourceId}${params}`;
        } else {
          return `/content/lessons/${lessonId}/compose/resources/${resourceId}/simple${params}`;
        }
      },
    },

    resource: {
      list: '/content/resources',
      create: '/content/resources/compose',
      compose: '/content/resources/:resourceId/compose',
      compose_gen: (resourceId: number) => {
        return `/content/resources/${resourceId}/compose`;
      },

      assets: '/content/assets-files',
      standards_set: '/content/standard-sets',
    },
  },

  page_content: {
    static_page: '/content/pages',
    static_page_roles: [RoleEnum.DESIGN_PAGE, RoleEnum.SCHOOL],

    content: '/content/pages/content',
    content_roles: [RoleEnum.DESIGN_PAGE, RoleEnum.SCHOOL],

    publish_static_page: '/content/pages/:id/publish',
    publish_static_page_gen: (pageSiteId: number) => {
      return `/content/pages/${pageSiteId}/publish`;
    },
    publish_static_page_roles: [RoleEnum.PUBLISH_PAGE, RoleEnum.SCHOOL],

    page_template: '/content/pages/template',
  },

  survey: {
    list: '/surveys',
    report: '/surveys/:surveyId/report',
    report_gen: (surveyId: number) => {
      return `/surveys/${surveyId}/report`;
    },
    roles: [RoleEnum.MANAGE_COURSE, RoleEnum.SCHOOL],
  },

  reports: {
    list: '/reports',
    exercises: '/reports/exercise',

    exercise: '/reports/assignment/:assignmentInstanceId',
    view_exercise_gen: (assignmentInstanceId: number) => {
      return `/reports/assignment/${assignmentInstanceId}`;
    },

    lesson: '/reports/lesson/:lessonId',
    view_lesson_gen: (
      lessonId: number,
      productId?: number,
      productTocId?: number
    ) => {
      const params = getProductParams(productId, productTocId);
      return `/reports/lesson/${lessonId}${params}`;
    },

    lesson_report: '/reports/lesson/:lessonId/reports',
    lesson_report_gen: (lessonId: number) => {
      return `/reports/lesson/${lessonId}/reports`;
    },

    product: '/reports/product/:productId',
  },

  management: {
    courses: {
      roles: [RoleEnum.MANAGE_COURSE, RoleEnum.SUPPORT, RoleEnum.SCHOOL],
      path: '/management/courses',
      request: '/management/courses/requests',

      publish: '/management/courses/:id/publish',
      publish_gen: (courseId: number) => {
        return `/management/courses/${courseId}/publish`;
      },

      students: '/management/courses/:id/students',
      students_gen: (courseId: number) => {
        return `/management/courses/${courseId}/students`;
      },
    },

    classes: {
      roles: [
        RoleEnum.MANAGE_COURSE,
        RoleEnum.MANAGE_CLASS,
        RoleEnum.SUPPORT,
        RoleEnum.SCHOOL,
      ],
      path: '/management/classes',
    },

    students: {
      roles: [
        RoleEnum.MANAGE_COURSE,
        RoleEnum.MANAGE_CLASS,
        RoleEnum.SUPPORT,
        RoleEnum.SCHOOL,
      ],
      path: '/management/students', // DONE
      admin: '/school/:schoolId/users/students',
      admin_gen: (schoolId: number) => {
        return `/school/${schoolId}/users/students`;
      },
    },

    parents: {
      roles: [
        RoleEnum.MANAGE_COURSE,
        RoleEnum.MANAGE_CLASS,
        RoleEnum.SUPPORT,
        RoleEnum.SCHOOL,
      ],
      path: '/management/parents', // DONE
      admin: '/school/:schoolId/users/parents',
      admin_gen: (schoolId: number) => {
        return `/school/${schoolId}/users/parents`;
      },
    },

    teachers: {
      roles: [RoleEnum.SUPPORT, RoleEnum.SCHOOL],
      path: '/management/teachers', // DONE
      admin: '/school/:schoolId/users/teachers',
      admin_gen: (schoolId: number) => {
        return `/school/${schoolId}/users/teachers`;
      },
    },

    supports: {
      roles: [RoleEnum.SCHOOL],
      path: '/management/supports', // DONE
      admin: '/school/:schoolId/users/school-supports',
      admin_gen: (schoolId: number) => {
        return `/school/${schoolId}/users/school-supports`;
      },
    },

    school_admins: {
      roles: [RoleEnum.SCHOOL],
      path: '/management/school-admins', // DONE
      admin: '/school/:schoolId/users/school-admins',
      admin_gen: (schoolId: number) => {
        return `/school/${schoolId}/users/school-admins`;
      },
    },

    school: {
      list: '/school/list',
      list_roles: [RoleEnum.ADMIN],

      review: '/school/review',
      update: '/school/update',

      info_roles: [RoleEnum.SCHOOL],
      info: '/school/info',
      info_review: '/school/info/review',

      setting_roles: [RoleEnum.SCHOOL],
      setting: '/school/setting',
      setting_email_template: '/school/setting/email-template',
    },

    setting: {
      setting: '/school/setting',
      setting_email_template: '/school/setting/email-template',

      language: '/setting/language',
      currency: '/setting/payment/currency',
      payment_method: '/setting/payment/method',
    },
  },

  license: {
    book: '/license/products',
    book_roles: [RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL],

    book_license: '/license/products/:productId',
    book_license_roles: [RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL],
    book_license_gen: (productId: number) => {
      return `/license/products/${productId}`;
    },
  },

  sync: {
    events: '/sync/events',
  },

  class_room: {
    join: '/session/join',
    join_gen: (token: string) => {
      return `/session/join?token=${encodeURIComponent(token)}`;
    },
    class_room: '/session/class-room',
    class_room_gen: (code: string, token: string, from: string) => {
      if (token) {
        const params = URL_PARSE.convertParams({
          code: code,
          token: token,
          from: from,
        });

        return `/session/class-room?${params}`;
      } else {
        const params = URL_PARSE.convertParams({
          code: code,
          from: from,
        });
        return `/session/class-room?${params}`;
      }
    },
  },
};
