import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { PublishCourseStyle } from '@modules/course/container/publish/CourseInformationStep';
import ScrollToTop from '@components/feature/ScrollToTop';
import { useTranslation } from 'react-i18next';
import {
  PublishCourseAPI,
  UpdateCoursePriceReq,
} from '@services/publish/PublishCourseAPI';
import { CourseRes } from '@services/private/CourseAPI';
import { PriceTag } from '@components/product/LessonInfo';
import Card from '@components/card';

export default function CourseLicenseStep(props: {
  courseData: CourseRes;
  goPrevious: () => void;
  goNext: () => void;
}) {
  const { t } = useTranslation();

  const [updateCoursePrices, { isLoading }] =
    PublishCourseAPI.endpoints.updateCoursePrices.useMutation({});

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    setTotalPrice(props.courseData.price);
  }, []);

  const handleUpdateCoursePrices = () => {
    const request: UpdateCoursePriceReq = {
      courseId: props.courseData.courseId,
      price: totalPrice,
    };

    updateCoursePrices(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('course.warning.update_success'),
          description: t('course.warning.update_success_message'),
          placement: 'bottomRight',
        });
        props.goNext();
      })
      .catch(() => {
        notification.error({
          message: t('course.warning.update_error'),
          description: t('course.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleInputChange = (price: number) => {
    setTotalPrice(price);
  };

  return (
    <>
      <ScrollToTop visible={false} />

      <PublishCourseStyle className={'publish-course-section'}>
        <h1>{t('publish.basic_info.price')}</h1>

        <Card>
          <div className={'course-publish-content'}>
            <div className={'product-total-price'}>
              <h2>{t('label.total') + ': '} </h2>

              <PriceTag
                key={totalPrice}
                className={'product-price'}
                price={totalPrice}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </Card>

        <div className={'course-publish-step'}>
          <Button type={'default'} shape="round" onClick={props.goPrevious}>
            {t('button.step.previous')}
          </Button>

          <Button
            type={'primary'}
            loading={isLoading}
            shape="round"
            onClick={handleUpdateCoursePrices}
          >
            {t('button.step.next')}
          </Button>
        </div>
      </PublishCourseStyle>
    </>
  );
}
