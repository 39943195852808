import React from 'react';
import { Col, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from '@modules/authorization/container/ForgotPasswordForm';
import { H1 } from '@components/typography';

export default function ForgotPasswordPage(props: any) {
  const { t } = useTranslation();

  const handleSuccess = () => {
    // do nothing....
  };

  return (
    <>
      <AuthorizeStyle>
        <div className={'home-block-container'} {...props}>
          <div className={'gstudy-container'}>
            <H1>{t('header.forgot_password')}</H1>

            <Row gutter={[48, 24]}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <p>{t('forgot_password.direction_1')}</p>

                <p>{t('forgot_password.direction_2')}</p>

                <p>{t('forgot_password.direction_3')}</p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <ForgotPasswordForm successHandle={handleSuccess} />
              </Col>
            </Row>
          </div>
        </div>
      </AuthorizeStyle>
    </>
  );
}
