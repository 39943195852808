import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const original_width = 22;
  const original_height = 22;

  const width = props.height
    ? (parseInt(props.height + '') * original_width) / original_height
    : original_width;
  const height = props.height ? props.height : original_height;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#198754"></circle>
      <path
        d="M7 11L10 14L15 8"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
