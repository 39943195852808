import styled from 'styled-components';
import React from 'react';
import { DropItem } from '@components/react-dnd-bt/DropItem';

const WordScrambleDropItems = (props: {
  answers: string[];
  correctAnswer: string[];
  onDrop: (char: string, dropIndex: number) => void;
}) => {
  return (
    <WordScrambleAnswerStyle className={'word-scramble-drop-items'}>
      {props.correctAnswer.map((item, dropIndex) => {
        if (item === '_' || item === ' ') {
          return (
            <span
              className={'word-scramble-answer-item empty-word'}
              key={`word-scramble-answer-item-${dropIndex}`}
            >
              &nbsp;
            </span>
          );
        } else {
          if (props.answers[dropIndex] === item) {
            return (
              <span
                className={'word-scramble-answer-item correct-item'}
                key={`word-scramble-answer-item-${dropIndex}`}
              >
                {item}
              </span>
            );
          } else {
            return (
              <DropItem
                key={'word-chard-drop-item-' + dropIndex}
                acceptType={'word-scramble-char'}
                droppableId={dropIndex + ''}
                className={'word-scramble-answer-item'}
                handleOnDrop={(draggableId: string) =>
                  props.onDrop(draggableId, dropIndex)
                }
              >
                &nbsp;
              </DropItem>
            );
          }
        }
      })}
    </WordScrambleAnswerStyle>
  );
};

export default WordScrambleDropItems;

const WordScrambleAnswerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .word-scramble-answer-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    font-weight: bold;
    border: 1px solid #ccc;
    margin: 0.125em;

    &.empty-word {
      border: 1px solid transparent;
    }

    .correct-item {
      background: ${(props) => props.theme.component.correct};
      color: ${(props) => props.theme.color.white};
    }
  }
`;
