import { ManageClassActionEnum } from '@modules/users/pages/ManageClassPage';
import { Avatar, Card } from 'antd';
import ColorCircle from '@components/input/ColorCircle';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { H3, H4 } from '@components/typography';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@app/styled/StyledProvider';
const { Meta } = Card;

export const ClassItemStyle = styled.div`
  position: relative;

  .class-info-group {
    display: flex;
    gap: 15px;

    .class-thumbnail {
      width: 64px;
    }
  }

  .class-student-group .form-group {
    margin-bottom: 0;
  }

  .class-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const ClassStyle = styled.div`
  label {
    font-size: 12px;
  }

  .class-content {
    display: flex;

    .class-avatar {
      color: ${(props) => props.theme.app.primary_text};
      background: ${(props) => props.theme.app.primary};

      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;

      margin-right: 15px;

      font-size: 150%;
      width: 3em;
      height: 3em;
      position: relative;
      overflow: hidden;

      .icon-image {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
    }

    .class-detail {
      flex-grow: 1;
      margin-left: 10px;

      h1 {
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        color: ${(props) => props.theme.color.grey};
        border: 2px dashed;

        max-width: 70%;
        height: 100px;
        margin: 0 auto;

        &:hover {
          color: #141414;
        }
      }
    }
  }

  .created-date {
    margin-top: 10px;
  }

  .actions {
    position: absolute;
    top: 5px;
    right: 5px;
    background: ${(props) => props.theme.color.white};
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ClassInfoPlaceHolder = (props: {
  onEdit: (classDat: undefined, mode: ManageClassActionEnum) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ClassStyle className={'class-info'}>
      <Card hoverable>
        <div
          className={'class-content'}
          onClick={() => props.onEdit(undefined, ManageClassActionEnum.add)}
        >
          <div className={'class-avatar'}>
            <ColorCircle color={theme.app.primary}>GS</ColorCircle>
          </div>

          <div className={'class-detail'}>
            <Meta
              title={
                <H3>
                  {t('class.actions.click_to_add_class')} <PlusOutlined />
                </H3>
              }
            />

            <div className={'created-date'}>
              <H4>
                {t('classes.warning.number_student', {
                  numb: 'xx',
                })}
              </H4>

              <Avatar.Group
                maxCount={4}
                maxStyle={{
                  color: theme.app.primary,
                  backgroundColor: theme.app.primary_bgr,
                }}
              >
                <UserAvatar src={''} size={40} />
              </Avatar.Group>
            </div>

            <div className={'created-date'}>
              <label>{t('label.createdDate')}</label>: <H4>xx/xx/xxxx</H4>
            </div>
          </div>
        </div>
      </Card>
    </ClassStyle>
  );
};
