import React from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import env from '@utils/config';
import axios from 'axios';
import base64_encode from '@utils/EncodeUtils';
import { JwtRes } from '@modules/authorization/services/model';
import { IconUtils } from '@utils/IconUtils';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';

export default function LoginOauthForm(props: { onSuccess: () => void }) {
  const { t } = useTranslation();
  const { onLoginSuccess } = useLoginSuccess();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      grantType: 'oauth-app',
      clientId: '',
      clientSecret: '',
    },

    validationSchema: yup.object().shape({
      clientId: yup.string().required(t('validation.required.field')),
      clientSecret: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      doLogin(values.clientId, values.clientSecret);
    },
  });

  const doLogin = async (clientId: string, clientSecret: string) => {
    axios({
      method: 'POST', //you can set what request you want to be
      url: env.SERVER_URL + '/v0/oauth2/token',
      headers: {
        Authorization: `Basic ${base64_encode(`${clientId}:${clientSecret}`)}`,
      },
    })
      .then(({ data }: { data: JwtRes }) => {
        onLoginSuccess(data);
        props.onSuccess();

        notification.success({
          message: t('login.warning.login_success'),
          description: t('login.warning.login_success_message'),
          placement: 'bottomRight',
        });
      })
      .catch(() => {});
  };

  const onAddExample = () => {
    formik.setValues({
      grantType: 'oauth-app',
      clientId: '40fd2dd2-28ed-4e8a-a16a-56a1f90ba6c5.gstudy.net',
      clientSecret:
        'b1e5355d-b0b7-444f-b23b-64663c78ef50-4f87558a-9689-47e5-83b8-f29d633c3c9d',
    });
  };

  return (
    <FormikProvider value={formik}>
      <Input
        required={true}
        name="clientId"
        type="text"
        label={t('oauth_app.label.consumer')}
        placeholder={t('oauth_app.label.consumer')}
        onChange={formik.handleChange}
        value={formik.values.clientId}
      />

      <Input
        required={true}
        name="clientSecret"
        type="text"
        label={t('oauth_app.label.secret')}
        placeholder={t('oauth_app.label.secret')}
        onChange={formik.handleChange}
        value={formik.values.clientSecret}
      />

      <div className="submit-container">
        <ButtonGroup>
          <Button
            type={'primary'}
            shape={'round'}
            onClick={formik.submitForm}
            icon={IconUtils.login}
          >
            {t('button.login')}
          </Button>

          <Button onClick={onAddExample}>Add Example</Button>
        </ButtonGroup>
      </div>
    </FormikProvider>
  );
}
