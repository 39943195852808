import React from 'react';

import { AuthorizeStyle } from '../styled/PageStyle';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import RegisterUserForm from '@modules/authorization/container/RegisteUserForm';
import { RoleEnum } from '@app/redux/slices/roles';
import Card from '@components/card';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export default function TeacherRegistration(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();

  const handleRegisterSuccess = () => {
    router.push(SiteMap.register.success);
  };

  return (
    <AuthorizeStyle>
      <div className={'home-block-container'}>
        <div className={'gstudy-container'}>
          <H1>{t('header.teacher_registration')}</H1>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              <p>
                Gstudy là một nền tảng giáo dục online giúp bạn có thể xây dựng
                các khóa học và tương tác với học sinh.
              </p>

              <p>
                Gstudy cung cấp các công cụ soạn thảo thông minh. Bạn có thể dễ
                dàng sử dụng để xây dựng nên các tài liệu của mình
              </p>

              <p>
                Gstudy hỗ trợ các tính năng phục vụ cho việc dạy học, như
                Assignment, ClassRoom hay tương tác thông qua các Chat channel.
              </p>

              <p>
                Bạn có thể xuất bản sách lên hệ thống, hoặc tuyển sinh các khóa
                học mới.
              </p>

              <p>Còn chần chờ gì nữa, đăng kí ngay thôi!!</p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <Card>
                <H1>{t('header.teacher_registration')}</H1>

                <RegisterUserForm
                  disabled={props.disabled}
                  role={RoleEnum.TEACHER}
                  successHandle={handleRegisterSuccess}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </AuthorizeStyle>
  );
}
