import React from 'react';

import { SectionColumnSettingProps } from './OneColumnSection';
import { FormikProvider, useFormik } from 'formik';
import { Button, Slider } from 'antd';
import Input from '@components/input/input';
import { ThreeColumnSectionProps } from '@cms/section-bank/columns/ThreeColumnSection';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';

export const ThreeColumnSectionSetting = (props: {
  item: ThreeColumnSectionProps;
  onChange: (setting: string) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.item.setting as SectionColumnSettingProps,
    onSubmit: (values) => {
      props.onChange(JSON.stringify(values));
    },
  });

  const handleSlideChange = (value: [number, number]) => {
    const column1 = value[0];
    const column2 = value[1] - column1;
    const column3 = 100 - column1 - column2;
    formik.setFieldValue('width', [column1, column2, column3]);
  };

  return (
    <FormikProvider value={formik}>
      <Input
        name="gap"
        type="text"
        label={t('section.label.space_between')}
        onBlur={formik.handleChange}
        defaultValue={formik.values.gap}
      />

      <InputGroup label={t('section.label.column_size')}>
        <Slider
          range
          step={1}
          defaultValue={[formik.values.width[0], formik.values.width[1]]}
          onChange={handleSlideChange}
          // onAfterChange={onAfterChange}
        />
      </InputGroup>

      <Button
        type={'primary'}
        onClick={formik.submitForm}
        icon={IconUtils.actions.save}
      >
        {t('button.update')}
      </Button>
    </FormikProvider>
  );
};
