import { CourseRes } from '@services/private/CourseAPI';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import NavLink from '@components/button/NavLink';
import ButtonGroup from '@components/button/ButtonGroup';
import React from 'react';
import styled from 'styled-components';
import { SiteMap } from '@router/SiteMap';
const { Meta } = Card;

export const PublicCourseInfo = (props: { item: CourseRes }) => {
  const { t } = useTranslation();

  return (
    <PublishCourseStyle className={'product-info'}>
      <Card
        hoverable
        cover={
          <ThumbnailsImg
            type={'icon'}
            src={props.item.thumbnails}
            ratio={ImageScale.course.ratio}
          />
        }
      >
        <div className={'course-content'}>
          <NavLink href={''}>
            <Meta
              title={props.item.name}
              description={
                <div
                  className={'course-description'}
                  dangerouslySetInnerHTML={{ __html: props.item.description }}
                />
              }
            />

            {props.item.startDate != null && (
              <div className={'created-date'}>
                <label>{t('label.openDate')}</label>
                <h4>{props.item.startDate.split(' ')[0]}</h4>
              </div>
            )}

            <div className={'course-actions'}>
              <ButtonGroup>
                <NavLink
                  href={SiteMap.public.course_detail_gen(
                    props.item.courseId,
                    props.item.name
                  )}
                >
                  <Button type={'primary'}>{t('label.view_detail')}</Button>
                </NavLink>
              </ButtonGroup>
            </div>
          </NavLink>
        </div>
      </Card>
    </PublishCourseStyle>
  );
};

const PublishCourseStyle = styled.div``;
