import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import {
  TableOfContentAction,
  useComposeTocContext,
} from '@modules/product/components/toc/ComposeTocProvider';
import { ComposeTocUnit } from './ComposeTocUnit';
import { IconUtils } from '@utils/IconUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';

const TableOfContentStyle = styled.div`
  .table-of-content-action {
    margin-top: 2em;
  }

  .table-of-content-action {
    margin-bottom: 2em;
  }
`;

export default function ComposeToc() {
  const { t } = useTranslation();

  const { dispatchAction, selectTocId, productTocs } = useComposeTocContext();

  const createToc = () => {
    dispatchAction(TableOfContentAction.add_unit, -1);
  };

  const addLessonToToc = (lessonId: number, productTocId: number) => {
    dispatchAction(TableOfContentAction.add_lesson_to_unit, {
      lessonId,
      productTocId,
    });
  };

  return (
    <TableOfContentStyle className={'table-of-content'}>
      <div className={'table-of-content-tree'}>
        {productTocs.map((item, index) => {
          return (
            <ComposeTocUnit
              key={item.productTocId}
              type={selectTocId === item.productTocId ? 'edit' : 'view'}
              first={index === 0}
              last={index === productTocs.length - 1}
              item={item}
              onAddLesson={addLessonToToc}
            />
          );
        })}
      </div>

      <div className={'table-of-content-action'}>
        <ButtonGroup>
          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={createToc}
            icon={IconUtils.add_new}
          >
            {t('table_of_content.actions.add')}
          </Button>

          <NavLink href={SiteMap.content.product.list}>
            <Button size={'large'} type={'default'} shape={'round'}>
              {t('label.back')}
            </Button>
          </NavLink>
        </ButtonGroup>
      </div>
    </TableOfContentStyle>
  );
}
