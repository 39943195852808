import React, { useEffect, useState } from 'react';
import { Divider, List } from 'antd';
import { SessionAPI } from '@services/private/SessionAPI';
import LessonTree from '@modules/product/components/toc/LessonTree';
import { useTranslation } from 'react-i18next';
import { H2 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import Button from 'antd/lib/button';
import ButtonGroup from '@components/button/ButtonGroup';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { LessonClickable } from '@components/product/LessonInfoDraggable';
import { SessionRes } from '@services/model/session';
import { LessonRes } from '@modules/product/services/lesson_model';

export const ViewSessionLesson = (props: {
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean) => void;
  onActive?: (title: string) => void;
}) => {
  const { t } = useTranslation();

  const [lessons, setLessons] = useState<LessonRes[]>([]);

  useEffect(() => {
    if (props.onActive) {
      props.onActive(t('session.lessons') as string);
    }
  }, []);

  useEffect(() => {
    if (props.sessionData.lessons) {
      setLessons(props.sessionData.lessons);
    }
  }, [props.sessionData]);

  return (
    <div className={'section-lesson-form'}>
      <HeaderGroup className="header-group">
        <H2>{t('session.lessons')}</H2>
      </HeaderGroup>

      <List
        bordered
        dataSource={lessons}
        renderItem={(item) => (
          <List.Item>
            <LessonClickable key={item.lessonId} item={item} selected={true} />
          </List.Item>
        )}
      />
    </div>
  );
};

export default function SessionLessonForm(props: {
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean) => void;
  onActive?: (title: string) => void;
}) {
  const { t } = useTranslation();

  const [addLesson] = SessionAPI.endpoints.addLesson.useMutation({});
  const [removeLesson] = SessionAPI.endpoints.removeLesson.useMutation({});

  const [selectMode, setSelectMode] = useState(false);
  const [lessons, setLessons] = useState<LessonRes[]>([]);
  const [selectedLessonIds, setSelectedLessonIds] = useState<number[]>([]);

  useEffect(() => {
    if (props.onActive) {
      props.onActive(t('session.lessons') as string);
    }
  }, []);

  useEffect(() => {
    if (props.sessionData.lessons) {
      setLessons(props.sessionData.lessons);
    }
  }, [props.sessionData]);

  useEffect(() => {
    const lessonIds = lessons.map((item) => {
      return item.lessonId;
    });
    setSelectedLessonIds(lessonIds);
  }, [lessons]);

  const handleSelectData = (lessonData: LessonRes) => {
    const request = {
      sessionId: props.sessionData.sessionId,
      lessonId: lessonData.lessonId,
    };

    addLesson(request)
      .unwrap()
      .then(() => {
        setLessons((prev: LessonRes[]) => {
          const arr = [...prev];
          arr.push(lessonData);
          return arr;
        });

        props.warningResult(true);
      })
      .catch(() => {
        props.warningResult(false);
      });
  };

  const handleClickRemoveLesson = (lessonId: number) => {
    const request = {
      sessionId: props.sessionData.sessionId,
      lessonId: lessonId,
    };
    removeLesson(request)
      .unwrap()
      .then(() => {
        setLessons((prev: LessonRes[]) => {
          return prev.filter((item) => {
            return item.lessonId !== lessonId;
          });
        });
        props.warningResult(true);
      })
      .catch(() => {
        props.warningResult(false);
      });
  };

  return (
    <div className={'section-lesson-form'}>
      {selectMode ? (
        <>
          <HeaderGroup className="header-group">
            <H2>{t('session.library')}</H2>
          </HeaderGroup>

          <LessonTree
            teacherId={props.sessionData.owner.userId}
            selectedProductId={-1}
            selectedLessonIds={selectedLessonIds}
            handleSelectData={handleSelectData}
            handleRemoveLesson={handleClickRemoveLesson}
          />

          <Divider />

          <ButtonGroup type={'center'}>
            <Button
              type={'default'}
              shape={'round'}
              onClick={() => setSelectMode(false)}
            >
              {t('button.close')}
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <HeaderGroup className="header-group">
            <H2>
              {t('session.lessons')}

              <Button
                type={'primary'}
                shape="circle"
                size={'small'}
                icon={IconUtils.add_new}
                onClick={() => setSelectMode(true)}
              />
            </H2>
          </HeaderGroup>

          <List
            bordered
            dataSource={lessons}
            renderItem={(item) => (
              <List.Item>
                <LessonClickable
                  key={item.lessonId}
                  item={item}
                  selected={true}
                  onRemove={handleClickRemoveLesson}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
}
