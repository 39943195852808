import React from 'react';

import CreateSchoolPage from '@modules/setting/pages/CreateSchoolPage';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';

const CreateOrUpdateSchool = () => {
  return (
    <ManagementLayout className={'create-school-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_INFO}
        title={'My School'}
        description={'My School'}
      />

      <CreateSchoolPage />
    </ManagementLayout>
  );
};

export default CreateOrUpdateSchool;
