import { ClassResourceRes } from '@modules/users/services/clazz';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { MenuProps } from 'antd/es/menu';
import { IconUtils } from '@utils/IconUtils';
import { Dropdown } from 'antd';
import Button from '@components/button';
import React from 'react';

export const ClassResourceActions = (props: {
  classId: number;
  item: ClassResourceRes;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [removeResource] = ClassAPI.endpoints.removeResource.useMutation({});

  const handleClickRemove = () => {
    confirm(
      'danger',
      t('header.confirm'),
      'Bạn có muốn xóa tài liệu này khỏi chương trình học của lớp không',
      t('button.confirm'),
      t('button.cancel'),
      (result) => {
        if (result) {
          removeResource({
            productId: props.item.product.productId,
            classId: props.classId,
          })
            .unwrap()
            .then((res) => {
              props.onSuccess();
            })
            .catch(() => {
              // add warning message....
            });
        }
      }
    );
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'remove',
      icon: IconUtils.actions.delete,
      label: 'Remove',
      onClick: handleClickRemove,
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      <Button type="default" shape="circle" icon={IconUtils.more_icon} />
    </Dropdown>
  );
};
