import { ClassResourceRes } from '@modules/users/services/clazz';
import { Progress } from 'antd';
import React from 'react';

export const ClassResourceLicense = (props: { item: ClassResourceRes }) => {
  const getLicenseFormat = () => {
    return `${props.item.licenses}/ ${props.item.totalStudents}`;
  };

  return (
    <div className={'group-license'}>
      <Progress
        percent={(props.item.licenses * 100) / props.item.totalStudents}
        format={getLicenseFormat}
      />

      <div>
        Total {props.item.licenses} of {props.item.totalStudents} students has
        been assigned license.
      </div>
    </div>
  );
};
