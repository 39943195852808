import { UserAvatar } from '@components/avatar/UserAvatar';
import { InputGroup } from '@components/input/InputGroup';
import { Button, Dropdown } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserInforMobileStyle } from './style';
import { EntityWithStatus } from '@components/status/EntityStatus';
import { EntityStatusEnum } from '@services/model/common';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { UserRes } from '@modules/users/services/model';

export const TeacherUserInfo = (props: {
  item: UserRes;
  onView: (teacherId: number) => void;
  onEdit: (teacherId: number) => void;
  onActive: (teacherId: number, isActivate: boolean) => void;
  onResetPassword: (teacherId: number) => void;
  onUpdatePermission: (teacherId: number) => void;
  onViewProductLicense: (teacher: UserRes) => void;
}) => {
  const { t } = useTranslation();
  return (
    <UserInforMobileStyle className={'parent-info'}>
      <div className={'user-info-thumbnails'}>
        <UserAvatar src={props.item.avatar} size={72} />

        <InputGroup label={t('label.username')}>
          <EntityWithStatus status={props.item.status}>
            {props.item.username}
          </EntityWithStatus>
        </InputGroup>
      </div>

      <div className={'user-info-info'}>
        <InputGroup
          label={
            <>
              {t('label.firstName')} {t('label.lastName')}
            </>
          }
        >
          {props.item.firstName} {props.item.lastName}
        </InputGroup>

        <InputGroup label={t('label.email')}>{props.item.email}</InputGroup>

        <InputGroup label={t('label.phone')}>{props.item.phone}</InputGroup>
      </div>

      <div className={'user-info-action'}>
        <ManageTeacherDropDown
          item={props.item}
          onView={props.onView}
          onEdit={props.onEdit}
          onActive={props.onActive}
          onResetPassword={props.onResetPassword}
          onUpdatePermission={props.onUpdatePermission}
          onViewProductLicense={props.onViewProductLicense}
        />
      </div>
    </UserInforMobileStyle>
  );
};

export const ManageTeacherDropDown = (props: {
  item: UserRes;
  onView: (teacherId: number) => void;
  onEdit: (teacherId: number) => void;
  onActive: (teacherId: number, isActivate: boolean) => void;
  onResetPassword: (teacherId: number) => void;
  onUpdatePermission: (teacherId: number) => void;
  onViewProductLicense: (teacher: UserRes) => void;
}) => {
  const { t } = useTranslation();
  const { ifAnyGranted } = useSecurity();

  const activeUser =
    props.item.status != null && EntityStatusEnum.ACTIVE === props.item.status;

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'view',
            icon: IconUtils.actions.view_info,
            label: t('teacher.actions.view_info'),
            onClick: () => props.onView(props.item.userId),
          },

          {
            key: 'edit',
            icon: IconUtils.actions.edit,
            label: t('teacher.actions.update'),
            onClick: () => props.onEdit(props.item.userId),
          },

          {
            key: 'remove',
            icon: activeUser
              ? IconUtils.actions.disabled
              : IconUtils.actions.enabled,
            label: activeUser
              ? t('student.actions.disable_user')
              : t('student.actions.enable_user'),
            onClick: () => props.onActive(props.item.userId, activeUser),
          },

          ifAnyGranted([RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL])
            ? {
                type: 'divider',
              }
            : null,

          ifAnyGranted([RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL])
            ? {
                key: 'view-product-license',
                icon: IconUtils.menu.license,
                label: t('users.actions.manage_license'),
                onClick: () => props.onViewProductLicense(props.item),
              }
            : null,
          {
            type: 'divider',
          },
          {
            key: 'update-permission',
            icon: IconUtils.password,
            label: t('users.actions.update_permission'),
            onClick: () => props.onUpdatePermission(props.item.userId),
          },
          {
            key: 'reset-password',
            icon: IconUtils.password,
            label: t('users.actions.reset_password'),
            onClick: () => props.onResetPassword(props.item.userId),
          },
        ],
      }}
      trigger={['click']}
    >
      <Button type="default" shape="circle" icon={IconUtils.more_icon} />
    </Dropdown>
  );
};
