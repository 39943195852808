import React, { useEffect, useState } from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { ProductProgressItem } from '@components/product/ProductProgressItem';
import { Default_Gutter } from '@components/grid';
import { Col, Row } from 'antd';
import Card, { CardClassEnum } from '@components/card';
import { useGetProductProgress } from '@modules/product/context/useGetProductProgress';
import { ProductLicenseAPI } from '@modules/license/services/ProductLicenseAPI';
import { ProductLicenseRes } from '@modules/license/services/model';

export default function LicenseProducts() {
  const { t } = useTranslation();

  const [getLicenseProduct, { data, isSuccess }] =
    ProductLicenseAPI.endpoints.getLicense.useLazyQuery({});

  const { progress } = useGetProductProgress();

  const [products, setProducts] = useState<ProductLicenseRes[]>([]);

  useEffect(() => {
    getLicenseProduct({
      selectAll: true,
    });
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      setProducts(data.content);
    }
  }, [data]);

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('library.header')}</H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        {products &&
          products.length > 0 &&
          products.map((item) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
                key={item.product.productId + '_' + item.modifiedDate}
              >
                <Card
                  hoverable
                  className={`${CardClassEnum.rectangle}`}
                  style={{ marginBottom: 0 }}
                >
                  <ProductProgressItem
                    item={item}
                    progress={progress[item.product.productId]}
                  />
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}
