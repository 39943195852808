import { LessonAPI } from '@modules/product/services/LessonAPI';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import React, { useMemo, useState } from 'react';
import { LessonActionEnum } from '@modules/product/container/actions';
import { Button, Dropdown, notification } from 'antd';
import { LessonActionHandleTeacher } from '@components/common/lesson/actions/LessonActionHandleTeacher';
import { IconUtils } from '@utils/IconUtils';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { EntityStatusEnum } from '@services/model/common';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonActionTeacher = ({
  type,
  item,
  onChange,
}: {
  type: 'table' | 'box';
  item: LessonRes;
  onChange: () => void;
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();
  const proUser = useProUser();

  const [deleteLesson] = LessonAPI.endpoints.deleteLesson.useMutation();
  const [updateStatus] = LessonAPI.endpoints.updateStatus.useMutation();

  const [action, setAction] = useState<{
    action: LessonActionEnum;
    params: number;
  }>({
    action: LessonActionEnum.DEFAULT,
    params: -1,
  });

  const handleOnCancel = () => {
    setAction({
      action: LessonActionEnum.DEFAULT,
      params: -1,
    });

    onChange();
  };

  const removeLesson = (lessonId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('lesson.warning.confirm_remove'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteLesson(lessonId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('lesson.warning.success'),
                description: t('lesson.warning.remove_success'),
                placement: 'bottomRight',
              });
              onChange();
            })
            .catch(() => {
              notification.error({
                message: t('lesson.warning.error'),
                description: t('lesson.warning.remove_error'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const onEdit = (lessonId: number) => {
    router.push(SiteMap.content.lesson.compose_gen(lessonId, proUser));
  };

  const editMetadata = (lessonId: number) => {
    setAction({
      action: LessonActionEnum.EDIT_METADATA,
      params: lessonId,
    });
  };

  const reviewLesson = (lessonId: number) => {
    router.push(SiteMap.content.lesson.review_gen(lessonId));
  };

  // const onShare = (lessonId: number) => {
  //   setAction({
  //     action: LessonActionEnum.SHARE_LESSON,
  //     params: lessonId,
  //   });
  // };

  const onAssign = (lessonId: number) => {
    router.push(SiteMap.assignments.create_gen(lessonId, -1, -1));
  };

  const onUpdateStatus = (lessonId: number, status: EntityStatusEnum) => {
    confirm(
      'warning',
      t('header.confirm'),
      status === EntityStatusEnum.ACTIVE
        ? t('lesson.warning.update_complete_status')
        : t('lesson.warning.update_composing_status'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          updateStatus({
            lessonId: lessonId,
            status:
              status === EntityStatusEnum.ACTIVE ? 'published' : 'composing',
          })
            .unwrap()
            .then(() => {
              notification.success({
                message: t('lesson.warning.success'),
                description: t('lesson.warning.remove_success'),
                placement: 'bottomRight',
              });
              onChange();
            })
            .catch(() => {
              notification.error({
                message: t('lesson.warning.error'),
                description: t('lesson.warning.remove_error'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const menuItems = useMemo(() => {
    if (item.type === LessonTypeEnum.lesson) {
      const items: ItemType[] = [
        {
          key: 'edit',
          icon: IconUtils.compose_lesson,
          label: t('lesson.actions.compose'),
          onClick: () => onEdit(item.lessonId),
        },
        {
          key: 'review-lesson',
          icon: IconUtils.lesson.review,
          label: t('lesson.actions.review'),
          onClick: () => reviewLesson(item.lessonId),
        },
        {
          key: 'divider-1',
          type: 'divider',
        },
        {
          key: 'edit-metadata',
          icon: IconUtils.lesson.edit_metadata,
          label: t('lesson.actions.update_metadata'),
          onClick: () => editMetadata(item.lessonId),
        },

        {
          key: 'remove',
          icon: IconUtils.lesson.remove,
          label: t('lesson.actions.remove_lesson'),
          onClick: () => removeLesson(item.lessonId),
        },
        {
          key: 'divider-2',
          type: 'divider',
        },
        // {
        //   key: 'share',
        //   icon: IconUtils.lesson.share_to_students,
        //   label: t('lesson.actions.share_to_student'),
        //   onClick: () => onShare(item.lessonId),
        // },
        {
          key: 'assign',
          icon: IconUtils.lesson.assign,
          label: t('lesson.actions.create_assignment'),
          onClick: () => onAssign(item.lessonId),
        },
        {
          key: 'divider-3',
          type: 'divider',
        },
        {
          key: 'status',
          icon: IconUtils.lesson.status,
          label: t('lesson.actions.update_status'),
          onClick: () => onUpdateStatus(item.lessonId, item.status),
        },
      ];

      return items;
    } else {
      const items: ItemType[] = [
        {
          key: 'edit',
          icon: IconUtils.compose_lesson,
          label: t('lesson.actions.compose'),
          onClick: () => onEdit(item.lessonId),
        },
        {
          key: 'review-lesson',
          icon: IconUtils.lesson.review,
          label: t('lesson.actions.review'),
          onClick: () => reviewLesson(item.lessonId),
        },
        {
          key: 'divider-1',
          type: 'divider',
        },
        {
          key: 'edit-metadata',
          icon: IconUtils.lesson.edit_metadata,
          label: t('lesson.actions.update_metadata'),
          onClick: () => editMetadata(item.lessonId),
        },

        {
          key: 'remove',
          icon: IconUtils.lesson.remove,
          label: t('lesson.actions.remove_lesson'),
          onClick: () => removeLesson(item.lessonId),
        },
      ];

      return items;
    }
  }, [item]);

  return (
    <>
      <Dropdown
        menu={{ items: menuItems }}
        trigger={['click']}
        placement={type === 'table' ? 'bottom' : 'bottomRight'}
        arrow
      >
        <Button
          size={type === 'table' ? 'middle' : 'small'}
          type="default"
          shape="circle"
          icon={IconUtils.more_icon}
        />
      </Dropdown>

      <LessonActionHandleTeacher
        action={action}
        handleOnCancel={handleOnCancel}
      />
    </>
  );
};
