import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';

export interface OauthAppRes {
  oauthAppId: number;
  clientId: string;
  clientSecret: string;
  scopes: string;
  redirectUrl: string;

  name: string;
  description: string;
  homePageUrl: string;

  enable: boolean;
  createdDate: string;
  modifiedDate: string;
}

export interface CreateOauthAppReq {
  oauthAppId?: number;

  clientId: string;
  clientSecret: string;

  scopes: string;
  redirectUrl: string;

  name: string;
  description: string;
  enable: boolean;
}

export const OauthAppAPI = createApi({
  reducerPath: 'OauthAppAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<OauthAppRes>, unknown>({
      query: () => ({
        url: `/oauth-app`,
      }),
    }),

    findById: builder.query<OauthAppRes, number>({
      query: (oauthAppId) => ({
        url: `/oauth-app/${oauthAppId}`,
        method: 'GET',
      }),
    }),

    create: builder.mutation<OauthAppRes, CreateOauthAppReq>({
      query: (body) => ({
        url: '/oauth-app',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<OauthAppRes, CreateOauthAppReq>({
      query: (body) => ({
        url: `/oauth-app/${body.oauthAppId}`,
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (oauthAppId) => ({
        url: `/oauth-app/${oauthAppId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
