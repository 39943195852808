import { ClassRoomPresentation } from '@classroom/container/workspace/ClassRoomPresentation';
import React from 'react';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { ClassRoomWorkspaceEnum } from '@classroom/context/actions';
import { ClassRoomOverview } from '@classroom/container/workspace/ClassRoomOverview';
import { ClassRoomExercise } from '@classroom/container/workspace/ClassRoomExercise';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import styled from 'styled-components';
import {
  ClassRoomHost,
  ClassRoomParticipant,
} from '@classroom/context/ClassRoomMode';
import { ClassRoomWaiting } from '@classroom/components/ClassRoomWaiting';
import { AttendanceSession } from '@classroom/components/AttendanceSession';
import Card, { CardClassEnum } from '@components/card';
import { H2 } from '@components/typography';

export const ClassRoomContent = () => {
  return (
    <ClassRoomContextStyle className={'class-room-content'}>
      <ClassRoomHost>
        <ClassRoomHostContent />
      </ClassRoomHost>

      <ClassRoomParticipant>
        <ClassRoomParticipantContent />
      </ClassRoomParticipant>
    </ClassRoomContextStyle>
  );
};

const ClassRoomHostContent = () => {
  const { workspace } = useClassRoomContext();

  return (
    <SwitchCondition
      conditions={[
        {
          condition: workspace === ClassRoomWorkspaceEnum.class_room,
          children: <ClassRoomOverview />,
        },
        {
          condition: workspace === ClassRoomWorkspaceEnum.presentation,
          children: <ClassRoomPresentation />,
        },
        {
          condition: workspace === ClassRoomWorkspaceEnum.exercise,
          children: <ClassRoomExercise />,
        },
      ]}
    />
  );
};

const ClassRoomParticipantContent = () => {
  const { session, sessionTaskId, exerciseId, sectionId } =
    useClassRoomContext();

  return (
    <SwitchCondition
      conditions={[
        {
          condition: sessionTaskId < 0 && exerciseId < 0 && sectionId < 0,
          children: (
            <ClassRoomWaiting>
              <Card
                className={CardClassEnum.rectangle_box}
                style={{
                  maxWidth: 900,
                  maxHeight: '50vh',
                  overflowY: 'auto',
                  margin: '0 auto',
                  padding: '2em',
                }}
              >
                <H2>{session.name} Participants</H2>

                <p>Please wait to start...</p>

                <AttendanceSession session={session} />
              </Card>
            </ClassRoomWaiting>
          ),
        },

        {
          condition: sectionId > 0,
          children: <ClassRoomPresentation />,
        },

        {
          condition: exerciseId > 0,
          children: <ClassRoomPresentation />,
        },

        {
          condition: sessionTaskId > 0,
          children: <ClassRoomExercise />,
        },
      ]}
    />
  );
};

const ClassRoomContextStyle = styled.div`
  .class-room-content-board {
    width: 100%;
    height: 100%;

    .dynamic-content-container {
      background: #fff;
    }
  }

  .white-board {
    .dynamic-content-container {
      border: 2px solid #333;
    }
  }
`;
