import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { Select } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { GlossaryType } from '@cms/comps/game/GlossaryWordBank';
import { useTranslation } from 'react-i18next';
import { GlossarySettingProps } from '@cms/comps/game/glossary/GlossaryComp';
import Input from '@components/input/input';

export const GlossarySetting = (props: {
  setting: GlossarySettingProps;
  onSettingChange: (newSetting: GlossarySettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.flash_card.default_display')}>
          <Select
            size={'large'}
            placeholder={t('component.flash_card.select_default_type')}
            onChange={(value) => formik.setFieldValue('showTerm', value)}
            value={formik.values.showTerm}
          >
            <Select.Option key={GlossaryType.word} value={GlossaryType.word}>
              {t('component.flash_card.term')}
            </Select.Option>

            <Select.Option
              key={GlossaryType.description}
              value={GlossaryType.description}
            >
              {t('component.flash_card.definition')}
            </Select.Option>
          </Select>
        </InputGroup>
      </FormikProvider>
    </SettingStyle>
  );
};
