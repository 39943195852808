import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';

import {
  AddStudentToClassReq,
  ClassFilterReq,
  ClassRes,
  ClassResourceRes,
  ClassRosterRes,
  CreateClassResourceReq,
  CreateOrUpdateClass,
  DisabledClassReq,
  FilterClassReq,
  FilterStudentClassReq,
  ViewClassRes,
} from '@modules/users/services/clazz';
import {
  SessionAndRosterRes,
  SessionUserHistoryRes,
} from '@services/model/session';
import { UserRes } from '@modules/users/services/model';

export const ClassAPI = createApi({
  reducerPath: 'ClassAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMyClasses: builder.query<PaginationRes<ClassRes>, FilterClassReq>({
      query: (params) => ({
        url: `/classes`,
        params: params,
      }),
    }),

    findAllClass: builder.query<ClassRes[], unknown>({
      query: () => ({
        url: `/classes/all`,
      }),
    }),

    getClassInfo: builder.query<ViewClassRes, number | string>({
      query: (classId) => ({
        url: `/classes/${classId}`,
        method: 'GET',
      }),
    }),

    createClass: builder.mutation<ClassRes, CreateOrUpdateClass>({
      query: (body: CreateOrUpdateClass) => ({
        url: '/classes',
        body,
        method: 'POST',
      }),
    }),

    updateClass: builder.mutation<ClassRes, CreateOrUpdateClass>({
      query: (body: CreateOrUpdateClass) => ({
        url: `/classes/${body.classId}`,
        body,
        method: 'PUT',
      }),
    }),

    activeClass: builder.mutation<BasicMessageRes, DisabledClassReq>({
      query: (body) => ({
        url: `/classes/${body.classId}/activated`,
        method: 'PUT',
        body,
      }),
    }),

    getClassRoster: builder.query<
      PaginationRes<ClassRosterRes>,
      FilterStudentClassReq
    >({
      query: (params) => ({
        url: `/classes/${params.classId}/rosters`,
        method: 'GET',
        params,
      }),
    }),

    getStudentInClass: builder.query<UserRes[], FilterStudentClassReq>({
      query: (params) => ({
        url: `/classes/${params.classId}/students`,
        method: 'GET',
        params,
      }),
    }),

    addStudent: builder.mutation<UserRes, AddStudentToClassReq>({
      query: (body) => ({
        url: `/classes/${body.classId}/students`,
        method: 'POST',
        body,
      }),
    }),

    removeStudent: builder.mutation<BasicMessageRes, AddStudentToClassReq>({
      query: (body) => ({
        url: `/classes/${body.classId}/students`,
        method: 'DELETE',
        body,
      }),
    }),

    getSessions: builder.query<
      PaginationRes<SessionAndRosterRes>,
      ClassFilterReq
    >({
      query: (params) => ({
        url: `/classes/${params.classId}/sessions`,
        method: 'GET',
        params,
      }),
    }),

    getStudentSessionHistory: builder.query<
      PaginationRes<SessionUserHistoryRes>,
      ClassFilterReq
    >({
      query: (params) => ({
        url: `/classes/${params.classId}/sessions/history`,
        method: 'GET',
        params,
      }),
    }),

    getClassResource: builder.query<
      PaginationRes<ClassResourceRes>,
      ClassFilterReq
    >({
      query: (params) => ({
        url: `/classes/${params.classId}/products`,
        method: 'GET',
        params,
      }),
    }),

    addResource: builder.mutation<ClassResourceRes, CreateClassResourceReq>({
      query: (body) => ({
        url: `/classes/${body.classId}/products`,
        method: 'POST',
        body,
      }),
    }),

    removeResource: builder.mutation<
      BasicMessageRes,
      { classId: number; productId: number }
    >({
      query: (body) => ({
        url: `/classes/${body.classId}/products/${body.productId}`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});
