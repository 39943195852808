import React, { useEffect, useState } from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { StudentLessonAPI } from '@services/student/StudentLessonAPI';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import styled from 'styled-components';
import { LessonItemStudent } from '@components/product/LessonItemStudent';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface StudentLessonActionProps {
  lesson: LessonRes;
  timestamp: number;
}
export default function StudentLessonGroup() {
  const { t } = useTranslation();

  const [getMyLesson, { data, isSuccess }] =
    StudentLessonAPI.endpoints.getMyLesson.useLazyQuery({});

  const [lessons, setLessons] = useState<LessonRes[]>([]);

  useEffect(() => {
    getMyLesson({});
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      setLessons(data);
    }
  }, [data]);

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('lesson.my_lesson')}</H1>
      </HeaderGroup>

      <LessonTableStyle className={'student-lesson-table'}>
        {lessons &&
          lessons.length > 0 &&
          lessons.map((ls) => {
            return (
              <LessonItemStudent
                item={ls}
                key={ls.lessonId + '_' + ls.modifiedDate}
              />
            );
          })}
      </LessonTableStyle>
    </>
  );
}

const LessonTableStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`;
