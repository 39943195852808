import React from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import NavLink from '@components/button/NavLink';
import { UserRequestAPI } from '@services/UserRequestAPI';
import { ReloadOutlined } from '@ant-design/icons';
import { SiteMap } from '@router/SiteMap';

export default function ResetPasswordForm(props: {
  uid: string;
  code: string;
  successHandle: () => void;
}) {
  const { t } = useTranslation();

  const [resetPassword, { isLoading }] =
    UserRequestAPI.endpoints.resetPassword.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      password: '',
      repeatPassword: '',
    },

    validationSchema: yup.object().shape({
      password: yup.string().required(t('registration.required.password')),
      repeatPassword: yup
        .string()
        .required(t('registration.required.repeat_password'))
        .oneOf([yup.ref('password')], t('errors.password_must_match')),
    }),

    onSubmit: (values) => {
      const payload = {
        uid: props.uid,
        code: props.code,
        password: values.password,
      };

      resetPassword(payload)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('reset_password.warning.success'),
            description: t('reset_password.warning.success_message'),
            placement: 'bottomRight',
          });

          props.successHandle();
        })
        .catch(() => {
          notification.error({
            message: t('reset_password.warning.error'),
            description: t('reset_password.warning.error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Input
        id="password"
        name="password"
        type="password"
        label={t('label.password')}
        placeholder={t('form.enter_password')}
        onChange={formik.handleChange}
        value={formik.values.password}
      />

      <Input
        id="repeatPassword"
        name="repeatPassword"
        type="password"
        label={t('label.password_again')}
        placeholder={t('form.enter_password_again')}
        onChange={formik.handleChange}
        value={formik.values.repeatPassword}
      />

      <div className="submit-container">
        <ButtonGroup>
          <Button
            type={'primary'}
            loading={isLoading}
            onClick={formik.submitForm}
            size={'large'}
            shape={'round'}
            icon={<ReloadOutlined />}
          >
            {t('button.reset_password')}
          </Button>

          <NavLink href={SiteMap.index}>
            <Button type="default" size={'large'} shape={'round'}>
              {t('button.back')}
            </Button>
          </NavLink>
        </ButtonGroup>
      </div>
    </FormikProvider>
  );
}
