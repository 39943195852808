import { OperationExpProps } from '@cms/comps/math/operation/OperationUtils';
import React, { createContext, useContext, useState } from 'react';

const LongOperationTableContext = createContext({
  rowIndex: -1,
  columnIndex: -1,

  focusNext: (rowIndex: number, columnIndex: number) => {
    console.log('focus next input....', rowIndex, columnIndex);
  },
});

export const useLongOperationTableContext = () => {
  const context = useContext(LongOperationTableContext);
  if (!context) {
    throw new Error('You must wrap container by LongOperationRowProvider');
  }
  return context;
};

export const LongOperationTableProvider = (props: {
  expression: OperationExpProps[];
  type: 'left-to-right' | 'right-to-left' | '';
  children: any;
}) => {
  const [rowIndex, setRowIndex] = useState(-1);
  const [columnIndex, setColumnIndex] = useState(-1);

  const focusNext = (rowIndex: number, columnIndex: number) => {
    // const currentRow = props.expression[rowIndex];

    if (props.type === 'left-to-right') {
      // const newIndex = [...currentRow.exp]
      //   .slice(columnIndex + 1)
      //   .indexOf(OperationUtils.VARIABLE_CHARACTER);
      //
      // if (newIndex > -1) {
      //   setRowIndex(rowIndex);
      //   setColumnIndex(columnIndex + 1 + newIndex);
      // }

      setRowIndex(rowIndex);
      setColumnIndex(columnIndex + 1);
    } else {
      // const newIndex = [...currentRow.exp]
      //   .splice(0, columnIndex)
      //   .lastIndexOf(OperationUtils.VARIABLE_CHARACTER);
      //
      // if (newIndex > -1) {
      //   setRowIndex(rowIndex);
      //   setColumnIndex(newIndex);
      // }

      setRowIndex(rowIndex);
      setColumnIndex(columnIndex - 1);
    }
  };

  return (
    <LongOperationTableContext.Provider
      value={{ rowIndex, columnIndex, focusNext }}
    >
      {props.children}
    </LongOperationTableContext.Provider>
  );
};
