import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import { DragItemTypeEnum } from '@components/react-dnd/DragNDropTypes';
import React, { useEffect, useState } from 'react';
import {
  DragNDropItemCompProps,
  generatorDragNDropAns,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import { SelectDragNDropItem } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';
import styled from 'styled-components';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { DragItem } from '@components/react-dnd-bt/DragItem';

const DnDDropInlineItem = ({
  dragItems,
  sourceItems,
  dropItem,
  disableTarget,
}: DnDDropInlineItemsProps) => {
  const [dragObjects, setDragObjects] = useState<DragNDropItemCompProps[]>([]);

  useEffect(() => {
    const _dragItems = sourceItems.filter((drag) => {
      return dragItems.includes(drag.label);
    });
    setDragObjects(_dragItems);
  }, [dragItems]);

  return (
    <DnDDropInlineItemStyle className={'droppable-items-wrapper'}>
      <DropItem
        acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM_INLINE}
        droppableId={dropItem.label}
        className={'droppable-items'}
        disabledIds={disableTarget}
      >
        <div className={'comps-dnd-drop-content'}>
          {dragObjects.length === 0 && (
            <div className={'comps-dnd-placeholder'}>&nbsp;</div>
          )}

          {dragObjects.length > 0 &&
            dragObjects.map((drag, index) => {
              const answerPart = generatorDragNDropAns(
                dropItem.label,
                drag.label
              );

              return (
                <DragItem
                  key={JSON.stringify(drag) + '_' + index}
                  acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM_INLINE}
                  index={index}
                  draggableId={drag.label}
                >
                  <div className={`comps-dnd-drag-option`}>
                    <CompFeedbackResultIcon answer={answerPart} />
                    <ContentViewer contentData={drag.content} />
                  </div>
                </DragItem>
              );
            })}
        </div>
      </DropItem>
    </DnDDropInlineItemStyle>
  );
};

export const DnDDropClickInlineItem = ({
  selectItem,
  onSelectItem,
  dragItems,
  sourceItems,
  dropItem,
}: DnDSelectInlineItemsProps) => {
  const [dragObjects, setDragObjects] = useState<DragNDropItemCompProps[]>([]);

  useEffect(() => {
    const _dragItems = sourceItems.filter((drag) => {
      return dragItems.includes(drag.label);
    });
    setDragObjects(_dragItems);
  }, [dragItems]);

  const handleOnClick = (drop: DragNDropItemCompProps) => {
    onSelectItem(drop, 'target', null);
  };

  const handleOnClickItem = (e: any, drag: DragNDropItemCompProps) => {
    e.stopPropagation();
    onSelectItem(drag, 'source', dropItem);
  };

  return (
    <DnDDropInlineItemStyle className={'droppable-items'}>
      <div
        className={`drop-item click-event ${getClassName(
          dropItem,
          selectItem
        )}`}
        key={JSON.stringify(dropItem)}
        onClick={() => handleOnClick(dropItem)}
      >
        <div className={'comps-dnd-drop-content'}>
          {dragObjects.length === 0 && (
            <div className={'comps-dnd-placeholder'}>&nbsp;</div>
          )}

          {dragObjects.length > 0 &&
            dragObjects.map((drag, index) => {
              const answerPart = generatorDragNDropAns(
                dropItem.label,
                drag.label
              );

              return (
                <div
                  key={JSON.stringify(drag) + '_' + index}
                  className={`comps-dnd-drag-option`}
                  onClick={(e) => handleOnClickItem(e, drag)}
                >
                  <CompFeedbackResultIcon answer={answerPart} />
                  <ContentViewer contentData={drag.content} />
                </div>
              );
            })}
        </div>
      </div>
    </DnDDropInlineItemStyle>
  );
};

export default DnDDropInlineItem;

interface DnDDropInlineItemsProps {
  dragItems: string[];
  sourceItems: DragNDropItemCompProps[];
  dropItem: DragNDropItemCompProps;
  disableTarget?: string[];
}

interface DnDSelectInlineItemsProps {
  dragItems: string[];
  sourceItems: DragNDropItemCompProps[];
  dropItem: DragNDropItemCompProps;
  selectItem: SelectDragNDropItem;
  onSelectItem: (
    item: DragNDropItemCompProps,
    type: 'source' | 'target',
    target: DragNDropItemCompProps | null
  ) => void;
}

const getClassName = (
  drop: DragNDropItemCompProps,
  select: SelectDragNDropItem
) => {
  if (select != null && select.item != null) {
    if (drop.label === select.item.label && select.type === 'target') {
      return 'highlight-option';
    } else {
      return '';
    }
  } else {
    return '';
  }
};

const DnDDropInlineItemStyle = styled.div`
  text-align: center;
  display: inline-flex;

  .comps-dnd-placeholder {
    line-height: 1.6;
    min-height: 1.6em;
  }

  .drop-item.click-event {
    cursor: pointer;

    &.highlight-option {
      background-color: ${(props) => props.theme.component.primary_bgr};
    }
  }

  &.droppable-items-wrapper {
    .droppable-container {
      padding-left: 0.125em;
      padding-right: 0.125em;
    }

    .draggable-item {
      color: ${(props) => props.theme.component.primary};

      .show-correct-answer & {
        color: ${(props) => props.theme.component.correct_answer};
      }
    }
  }
`;
