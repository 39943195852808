import React, { useEffect, useMemo, useState } from 'react';
import { H5 } from '@components/typography';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import PublicSessionInfo from '@components/public/session/PublicSessionInfo';
import { useTranslation } from 'react-i18next';
import { EventAPI, EventRes, EventTypeEnum } from '@services/event/EventAPI';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import styled from 'styled-components';
import { TimeRanger } from '@components/text/DateLabel';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar } from 'antd';
import { Timeline } from 'antd';
import {
  ExerciseAction,
  StudentExerciseWithStatus,
} from '@components/exercise/ExerciseStatus';
import { useTheme } from '@app/styled/StyledProvider';

interface EventGroupProps {
  endDate: string;
  events: EventRes[];
}
const IncomingEvents = () => {
  const { t } = useTranslation();

  const [getIncomingEvents, { data, isSuccess }] =
    EventAPI.endpoints.getIncomingEvents.useLazyQuery({});

  const [events, setEvents] = useState<EventGroupProps[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<EventRes | null>(null);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      const eventGroups: EventGroupProps[] = [];

      data.forEach((ev) => {
        const endDate = ev.endTime.split('T')[0];
        const lastRecord = eventGroups[eventGroups.length - 1];

        if (lastRecord != null && lastRecord.endDate === endDate) {
          lastRecord.events.push(ev);
        } else {
          eventGroups.push({
            endDate: endDate,
            events: [ev],
          });
        }
      });

      setEvents(eventGroups);
    }
  }, [data]);

  const refreshData = () => {
    getIncomingEvents({});
  };

  const handleOnClick = (event: EventRes) => {
    setSelectedEvent(event);
  };

  const eventTimeline = useMemo(() => {
    return events.map((evt) => {
      return {
        dot: <ClockCircleOutlined style={{ fontSize: '20px' }} />,
        children: <EventGroup item={evt} onClick={handleOnClick} />,
      };
    });
  }, [events]);

  return (
    <IncomingEventStyle className={'incoming-events'}>
      <div
        className={'incoming-event-title'}
        style={{ fontSize: '175%', fontWeight: 500 }}
      >
        {t('dashboard.label.incoming_event')}
      </div>

      <Timeline items={eventTimeline} style={{ marginTop: '1em' }} />

      {selectedEvent && (
        <>
          {selectedEvent.type === EventTypeEnum.SESSION && (
            <CustomModal
              header={t('session.header')}
              className={CustomModalClassEnum.large_modal}
              content={<PublicSessionInfo item={selectedEvent.session} />}
              onCloseFunc={() => setSelectedEvent(null)}
            />
          )}

          {selectedEvent.type === EventTypeEnum.ASSIGNMENT && (
            <CustomModal
              header={t('assignment.title')}
              className={CustomModalClassEnum.full_size_modal}
              content={<>{JSON.stringify(selectedEvent)}</>}
              onCloseFunc={() => setSelectedEvent(null)}
            />
          )}
        </>
      )}
    </IncomingEventStyle>
  );
};

export default IncomingEvents;

const IncomingEventStyle = styled.div`
  .ant-timeline .ant-timeline-item-head {
    background: transparent;
  }
`;

const EventGroup = (props: {
  item: EventGroupProps;
  onClick: (event: EventRes) => void;
}) => {
  return (
    <EventGroupStyle className={'event-groups'}>
      <div className={'event-timeline-header'}>{props.item.endDate}</div>

      <div className={'event-group-item'}>
        {props.item.events.map((event) => {
          if (event.type === EventTypeEnum.ASSIGNMENT) {
            return <AssignmentInfo item={event} />;
          } else {
            return <SessionInfo item={event} onClick={props.onClick} />;
          }
        })}
      </div>
    </EventGroupStyle>
  );
};

const EventGroupStyle = styled.div`
  .event-timeline-header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .assignment-info,
  .session-info {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
`;

export const AssignmentInfo = (props: { item: EventRes }) => {
  return (
    <AssignmentInfoStyle className={'assignment-info'}>
      <div className={'assignment-thumbnail'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.assignment.lesson.thumbnails}
          ratio={ImageScale.lesson.ratio}
          width={64}
        />
      </div>

      <div className={'assignment-title'}>
        <StudentExerciseWithStatus
          unlockInMs={props.item.assignment.unlockInMs}
          expiredInMs={props.item.assignment.expiredInMs}
          status={props.item.assignment.exercise.status}
        >
          {props.item.name}
        </StudentExerciseWithStatus>

        <div className={'assignment-time'}>
          <TimeRanger from={props.item.startTime} to={props.item.endTime} />
        </div>
      </div>

      <div className={'assignment-actions'}>
        <ExerciseAction size={'small'} item={props.item.assignment} />
      </div>
    </AssignmentInfoStyle>
  );
};

const AssignmentInfoStyle = styled.div`
  position: relative;

  display: flex;
  align-items: flex-start;

  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  background: #fff;

  &:not(:first-child) {
    margin-top: 1em;
  }

  .assignment-thumbnail {
    margin-right: 10px;
    display: inline-flex;
    height: 64px;
    width: 64px;
  }

  .assignment-title {
    flex-grow: 1;
    max-width: calc(100% - 84px);

    .ant-typography {
      margin-bottom: 0;
    }
  }

  .assignment-actions {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`;

const SessionInfo = (props: {
  item: EventRes;
  onClick: (event: EventRes) => void;
}) => {
  const theme = useTheme();

  return (
    <SessionInfoStyle
      className={'session-info'}
      onClick={() => props.onClick(props.item)}
    >
      <div className={'session-thumbnail'}>
        <CalendarOutlined style={{ fontSize: 55 }} />
      </div>

      <div className={'session-title'}>
        <H5>{props.item.name}</H5>

        <div className={'session-time'}>
          <TimeRanger from={props.item.startTime} to={props.item.endTime} />
        </div>
      </div>

      <div className={'session-participants'}>
        <Avatar.Group maxCount={3}>
          {props.item.session.students.map((st) => {
            if (st.avatar) {
              return <Avatar src={st.avatar} />;
            } else {
              return (
                <Avatar
                  style={{ backgroundColor: theme.warning.success }}
                  icon={<UserOutlined />}
                />
              );
            }
          })}
        </Avatar.Group>
      </div>
    </SessionInfoStyle>
  );
};

const SessionInfoStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  background: #fff;

  .session-thumbnail {
    margin-right: 10px;
    display: inline-flex;
    height: 64px;
    width: 64px;
  }

  .session-title {
    flex-grow: 1;

    .ant-typography {
      margin-bottom: 0;
    }
  }
`;
