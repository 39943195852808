import { useEffect } from 'react';

import { updateSetting } from '@app/redux/slices/appSettingSlice';

import env from '@utils/config';
import { PublishSchoolAPI } from '@services/publish/PublishSchool';
import { SchoolSettingAPI } from '@services/private/SchoolSettingAPI';
import { useDispatch, useSelector } from '@app/redux/hook';

const useLoadSchoolSetting = (getPrivate: boolean) => {
  const dispatch = useDispatch();

  const { isAuth, authorities } = useSelector((state) => state.profile);
  const { ready } = useSelector((state) => state.appSetting);

  const [getSchoolSetting, { data, isSuccess }] =
    PublishSchoolAPI.endpoints.getSchoolSetting.useLazyQuery();

  const [getPrivateSetting, { data: scData, isSuccess: scSuccess }] =
    SchoolSettingAPI.endpoints.getSchoolSetting.useLazyQuery();

  useEffect(() => {
    if (!ready) {
      if (getPrivate) {
        getPrivateSetting({});
      } else {
        getSchoolSetting(env.CLIENT_ID);
      }
    }
  }, [isAuth, authorities, ready]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(updateSetting(data));
    }
  }, [data]);

  useEffect(() => {
    if (scData && scSuccess) {
      dispatch(updateSetting(scData));
    }
  }, [scData]);
};

export default useLoadSchoolSetting;
