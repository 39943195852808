import { ClassAPI } from '@modules/users/services/ClassAPI';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import { ProductLicenseAPI } from '@modules/license/services/ProductLicenseAPI';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { UserDisplay } from '@components/avatar/UserDisplayName';
import styled from 'styled-components';
import { TableDefault } from '@components/table/TablePagination';
import { UserRes } from '@modules/users/services/model';
import { ColumnsType } from 'antd/lib/table';
import { UserAvatar } from '@components/avatar/UserAvatar';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { AssignProductLicenseForUser } from '@modules/license/components/AssignProductLicenseForUser';
import { IconUtils } from '@utils/IconUtils';
import { useTranslation } from 'react-i18next';
import { ProductLicenseDisplay } from '../program/ProductLicenseDisplay';
import { ProductLicenseRes } from '@modules/license/services/model';
import { ProductRes } from '@modules/product/services/product_model';

export const ClassProductLicenseView = (props: {
  classId: number;
  product: ProductRes;
}) => {
  const { t } = useTranslation();

  const [getStudentInClass, { data, isFetching, isSuccess }] =
    ClassAPI.endpoints.getStudentInClass.useLazyQuery({});

  const [
    checkLicenseForProducts,
    { data: licenseData, isFetching: isLicenseLoading },
  ] = ProductLicenseAPI.endpoints.checkLicenseForProducts.useLazyQuery({});

  const [action, setAction] = useState<{
    action: string;
    params: { user: UserRes | undefined; product: ProductRes | undefined };
  }>({
    action: '',
    params: { user: undefined, product: undefined },
  });

  const [productLicense, setProductLicense] = useState<
    Record<number, ProductLicenseRes>
  >({});

  useEffect(() => {
    getStudentInClass({ classId: props.classId, selectAll: true });
  }, [props.classId]);

  useEffect(() => {
    loadLicenseForClass();
  }, [data]);

  const loadLicenseForClass = () => {
    if (data && isSuccess) {
      const studentIds = data.map((st) => {
        return st.userId;
      });

      checkLicenseForProducts({
        productId: props.product.productId,
        userIds: studentIds,
      });
    }
  };

  useEffect(() => {
    if (licenseData && isSuccess) {
      const records: Record<number, ProductLicenseRes> = {};

      licenseData.forEach((ls) => {
        records[ls.user.userId] = ls;
      });

      setProductLicense(records);
    }
  }, [licenseData]);

  const columns: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      width: 110,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'License',
      dataIndex: 'license',
      key: 'license',
    },

    {
      title: 'Actionss',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 220 - 16,
    },
  ];

  const onAssignLicense = (user: UserRes) => {
    setAction({
      action: 'assign-license',
      params: { user: user, product: props.product },
    });
  };

  const sourceData = useMemo(() => {
    const dataMapping = data?.map((st) => {
      return {
        thumbnails: <UserAvatar src={st.avatar} size={40} />,
        name: <UserDisplay item={st} />,
        license: (
          <>
            {productLicense[st.userId] != null ? (
              <ProductLicenseDisplay item={productLicense[st.userId]} />
            ) : (
              <p>Student is not assigned license.</p>
            )}
          </>
        ),
        actions: (
          <>
            <Authorization
              type={'ifAnyGranted'}
              roles={[RoleEnum.SCHOOL, RoleEnum.BOOK_LICENSE]}
            >
              <Button
                icon={IconUtils.menu.license}
                type={productLicense[st.userId] != null ? 'default' : 'primary'}
                shape={'round'}
                onClick={() => onAssignLicense(st)}
              >
                {productLicense[st.userId] != null
                  ? t('license.actions.extend_license')
                  : t('license.actions.assign_license')}
              </Button>
            </Authorization>
          </>
        ),
        className:
          productLicense[st.userId] != null
            ? 'has-license'
            : 'not-assign-license',
      };
    });

    return dataMapping;
  }, [data, productLicense]);

  const getRowKey = (item: UserRes) => {
    return item.userId;
  };

  const getRowClassName = (record: any) => {
    return record.className ?? '';
  };

  const onCancel = () => {
    setAction({
      action: '',
      params: { user: undefined, product: undefined },
    });
  };

  const onUpdateLicenseSuccess = () => {
    loadLicenseForClass();
    onCancel();
  };

  return (
    <ClassProductLicenseStyle className={'class-product-license'}>
      <TableDefault
        loading={isFetching || isLicenseLoading}
        rowKey={getRowKey}
        rowClassName={getRowClassName}
        columns={columns}
        dataSource={sourceData}
        pagination={false}
      />

      {action.action === 'assign-license' &&
        action.params.user &&
        action.params.product && (
          <CustomModal
            header={t('license.actions.assign_license')}
            className={CustomModalClassEnum.large_modal}
            content={
              <AssignProductLicenseForUser
                user={action.params.user}
                product={action.params.product}
                onSuccess={onUpdateLicenseSuccess}
                onCancel={onCancel}
              />
            }
            onCloseFunc={onCancel}
          />
        )}
    </ClassProductLicenseStyle>
  );
};

const ClassProductLicenseStyle = styled.div`
  margin-left: ${50 - 16}px; // 50 -12

  .has-license td {
    //background: ${(props) => props.theme.app.primary_bgr} !important;
    //font-weight: bold;
  }

  .not-assign-license td {
    background: ${(props) => props.theme.warning.error_bgr} !important;
    font-weight: 600;
  }
`;
