import {
  MathOperationEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import Button from '@components/button';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { SyncOutlined } from '@ant-design/icons';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { LongEditableStyle } from '../addition/LongAdditionEditable';
import Input from 'antd/lib/input/Input';
import { AreaOperationTableEditable } from '../table/AreaOperationTableEditable';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { useTranslation } from 'react-i18next';

export const AreaDivisionEditable = (props: {
  dividend: string;
  divisor: string;
  expression: OperationExpProps[];
  onChange: (
    multiplier: string,
    multiplicand: string,
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => void;
}) => {
  const { t } = useTranslation();
  const [dividend, setDividend] = useState(props.dividend);
  const [divisor, setDivisor] = useState(props.divisor);
  const [expression, setExpression] = useState<OperationExpProps[]>([]);
  const [answerExpression, setAnswerExpression] = useState<OperationExpProps[]>(
    []
  );

  useEffect(() => {
    setExpression([...props.expression]);
  }, [props.expression]);

  useEffect(() => {
    const answer = OperationUtils.parseAreaDivision(
      props.dividend,
      props.divisor
    );
    setDividend(props.dividend);
    setDivisor(props.divisor);
    setAnswerExpression(answer.results);
  }, [props.dividend, props.divisor]);

  const handleOnClick = () => {
    const answer = OperationUtils.parseAreaDivision(dividend, divisor);
    setExpression(answer.results);
    setAnswerExpression(answer.results);
  };

  const handleOnExpressionChange = (
    index: number,
    expression: OperationExpProps
  ) => {
    setExpression((prev) => {
      return COMPONENT_UTILS.updateAtIndex(prev, index, expression);
    });
  };

  const handleOnChange = (type: 'dividend' | 'divisor', val: string) => {
    if (type === 'dividend') {
      setDividend(val);
    } else {
      setDivisor(val);
    }
  };

  const onUpdateEditableData = () => {
    props.onChange(dividend, divisor, expression, answerExpression);
  };

  return (
    <>
      <LongEditableStyle className={'long-multiplication-editable'}>
        <Row>
          <Col flex={'200px'}>
            <InputGroup
              label={t('component.operation.dividend')}
              key={'dividend '}
            >
              <Input
                name="dividend"
                type="number"
                onChange={(val) => handleOnChange('dividend', val.target.value)}
                value={dividend}
              />
            </InputGroup>

            <InputGroup
              label={t('component.operation.divisor')}
              key={'divisor '}
            >
              <Input
                name="divisor"
                type="number"
                onChange={(val) => handleOnChange('divisor', val.target.value)}
                value={divisor}
              />
            </InputGroup>
          </Col>

          <Col flex={'100px'}>
            <div className={'button-center-group'}>
              <Button type={'primary'} shape={'circle'} onClick={handleOnClick}>
                <SyncOutlined />
              </Button>
            </div>
          </Col>

          <Col flex={'auto'}>
            <AreaOperationTableEditable
              operation={MathOperationEnum.area_division}
              expression={expression}
              answerExpression={answerExpression}
              onChange={handleOnExpressionChange}
            />
          </Col>
        </Row>
      </LongEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={onUpdateEditableData}
      />
    </>
  );
};
