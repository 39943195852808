import TablePagination from '@components/table/TablePagination';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { StandardSetAPI } from '@modules/product/services/StandardSetAPI';
import { Button, Dropdown, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ManageStandardSet } from '@modules/product/container/standards/ManageStandards';

import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';
import {
  StandardSetAction,
  useManageStandardSet,
} from '@modules/product/container/standards/useManageStandardSet';
import { EditStandardForm } from '@modules/product/components/standards/EditStandardForm';
import { H1, H3, H4 } from '@components/typography';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { StandardTag } from '@modules/product/components/standards/StandardSetDisplay';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveScreen } from '@hooks/useMedia';
import { useSelector } from '@app/redux/hook';

export const StandardSetManage = () => {
  const { t } = useTranslation();

  const [findAll, { data, isFetching }] =
    StandardSetAPI.endpoints.findAll.useLazyQuery();

  const {
    action,
    setAction,
    selectedStandardSet,
    selectStandard,
    handleOnCancel,
    columns,
    processDataRow,
    renderMobile,
    handleClickStandardMenu,
  } = useManageStandardSet(() => {
    refreshData(params);
  });

  const { isReady: isLoadSchoolReady } = useSelector(
    (state) => state.userInformation
  );

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'displayOrder',
    direction: 'descend',
  });

  useEffect(() => {
    if (isLoadSchoolReady) {
      refreshData(params);
    }
  }, [params, isLoadSchoolReady]);

  const refreshData = (params: any) => {
    setParams(params);
    findAll({ ...params, schoolId: -1 });
  };

  const handleOnSuccess = () => {
    refreshData(params);
    handleOnCancel();
  };

  const expandedRowRender = (rowData: any) => {
    const dataSources = rowData.standards.map((st: StandardRes) => {
      return {
        key: st.standardId,
        code: <StandardTag item={st} />,
        name: st.name,
        description: <LanguageContentTag multiple content={st.description} />,
        action: (
          <Dropdown
            menu={{
              items: [
                {
                  key: StandardSetAction.update_standard,
                  icon: IconUtils.actions.edit,
                  label: t('standard.actions.edit'),
                  onClick: () =>
                    handleClickStandardMenu(
                      st,
                      StandardSetAction.update_standard,
                      rowData.original
                    ),
                },
                {
                  key: StandardSetAction.delete_standard,
                  icon: IconUtils.actions.delete,
                  label: t('standard.actions.remove'),
                  onClick: () =>
                    handleClickStandardMenu(
                      st,
                      StandardSetAction.delete_standard,
                      rowData.original
                    ),
                },
              ],
            }}
            arrow
            trigger={['click']}
            placement={'bottom'}
          >
            <Button type="default" shape="circle" icon={IconUtils.more_icon} />
          </Dropdown>
        ),
      };
    });

    return (
      <>
        {dataSources.length === 0 && (
          <H4
            onClick={() =>
              handleClickStandardMenu(
                null,
                StandardSetAction.add_standard,
                rowData.original
              )
            }
          >
            {t('standard_set.warning.empty')}
          </H4>
        )}

        {dataSources.length > 0 && (
          <Table
            columns={columns}
            dataSource={dataSources}
            pagination={false}
          />
        )}
      </>
    );
  };

  const handleRenderMobile = (item: StandardSetRes) => {
    return renderMobile(item, handleClickStandardMenu);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('standard_set.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction(StandardSetAction.create)}
          />
        </H1>
      </HeaderGroup>

      {(data == null || data.content.length === 0) && (
        <H3>{t('standard.warning.empty')}</H3>
      )}

      {data && data.content.length > 0 && (
        <TablePagination
          params={params}
          isLoading={isFetching}
          columns={columns}
          data={data}
          refresh={refreshData}
          processDataRow={processDataRow}
          expandable={{ expandedRowRender }}
          responsive={{
            screen: [
              ResponsiveScreen.xs,
              ResponsiveScreen.sm,
              ResponsiveScreen.md,
            ],
            render: handleRenderMobile,
          }}
        />
      )}

      {action === StandardSetAction.create && (
        <CustomModal
          header={t('standard_set.add')}
          content={
            <ManageStandardSet
              data={null}
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action === StandardSetAction.update && selectedStandardSet != null && (
        <CustomModal
          header={t('standard_set.update')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ManageStandardSet
              data={selectedStandardSet}
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action === StandardSetAction.add_standard &&
        selectedStandardSet != null && (
          <CustomModal
            header={t('standard.add')}
            className={CustomModalClassEnum.medium_modal}
            content={
              <EditStandardForm
                data={null}
                standardSet={selectedStandardSet}
                successHandle={handleOnSuccess}
                onCloseFunc={handleOnCancel}
              />
            }
            onCloseFunc={handleOnCancel}
          />
        )}

      {action === StandardSetAction.update_standard &&
        selectedStandardSet != null && (
          <CustomModal
            header={t('standard.update')}
            className={CustomModalClassEnum.large_modal}
            content={
              <EditStandardForm
                data={selectStandard}
                standardSet={selectedStandardSet}
                successHandle={handleOnSuccess}
                onCloseFunc={handleOnCancel}
              />
            }
            onCloseFunc={handleOnCancel}
          />
        )}
    </>
  );
};
