import React from 'react';

import { CompProps } from '@cms/ComponentInteface';

import styled from 'styled-components';
import ComposeComponentToolbar from '@cms/context/ComponentItemToolbar';

import { useTranslation } from 'react-i18next';
import { useSelector } from '@app/redux/hook';

export default function ComponentCompose(props: {
  item: CompProps;
  index: number;
  total: number;
  errors?: string[];
  children: any;
}) {
  const { t } = useTranslation();
  const { selectComponentId } = useSelector((state) => state.composeResource);

  return (
    <ComponentComposeStyle
      className={`cms-content-item ${
        selectComponentId === props.item.id ? ' active' : ''
      } ${props.errors != null && props.errors.length > 0 ? ' has-error' : ''}`}
    >
      <ComposeComponentToolbar
        key={props.item.id}
        item={props.item}
        disabledPrevious={props.index < 1}
        disabledNext={props.index >= props.total - 1}
      />

      <div className={'cms-components-content'}>{props.children}</div>

      {props.errors != null && props.errors.length > 0 && (
        <ComponentErrorStyle className={'cms-component-error'}>
          {props.errors.map((err_key) => {
            // @ts-ignore
            const message = t(err_key) as string;
            return (
              <p
                className={'cms-component-error-message'}
                style={{}}
                key={err_key}
              >
                {message}
              </p>
            );
          })}
        </ComponentErrorStyle>
      )}
    </ComponentComposeStyle>
  );
}

const ComponentErrorStyle = styled.div`
  margin-top: 0.5em;

  .cms-component-error-message {
    font-size: 70%;
    color: ${(props) => props.theme.warning.error};
    font-weight: bold;
  }
`;

const ComponentComposeStyle = styled.div`
  position: relative;

  .component-toolbar {
    display: none;
  }

  .component-toolbar {
    padding: 0.125em 0.5em;
    display: flex;
    color: ${(props) => props.theme.component.primary};
    font-weight: bold;
  }

  .cms-components-content {
    padding: 0.125em 0.5em 0.75em;
  }

  &:hover {
    //.cms-components-content {
    //  border: 2px solid #e2d8f9;
    //  background: #fcfcfc;
    //}
  }

  &.has-error {
    .cms-components-content {
      border: 2px solid #f9d0d0;
    }

    .cms-component-error {
      padding-right: 0.5em;
      text-align: right;
    }
  }
`;
