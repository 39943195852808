import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useSecurity } from '@utils/authorization';
import { useSelector } from '@app/redux/hook';
import { getMenuItem } from '@components/template/common/MenuUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import i18next from 'i18next';
import { useProUser } from '@components/button/RequireProUserButton';
import { HeaderMenu } from '@components/template/app-layout/header/header-menu/HeaderMenuDisplay';
import {
  CreditCardOutlined,
  DollarOutlined,
  HomeOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { RoleEnum } from '@app/redux/slices/roles';

const SchoolSettingMenu = () => {
  const { t } = useTranslation();

  const proUser = useProUser();
  const { ifAnyGranted } = useSecurity();
  const { authorities } = useSelector((state) => state.profile);

  const menuItems = useMemo(() => {
    return [
      getMenuItem({
        key: NavigationMenuEnum.PAGES_STATIC_PAGE,
        label: (
          <NavLink href={SiteMap.page_content.static_page}>
            {t('menu.school_content')}
          </NavLink>
        ),
        icon: IconUtils.menu.pages,
        visible: ifAnyGranted(SiteMap.page_content.static_page_roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PAGES_STATIC_PAGE_CONTENT,
        label: (
          <NavLink href={SiteMap.page_content.content}>
            {t('menu.school_content_page')}
          </NavLink>
        ),
        icon: IconUtils.menu.pages,
        visible: ifAnyGranted(SiteMap.page_content.content_roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PAGES_PAGE_TEMPLATE,
        label: (
          <NavLink href={SiteMap.page_content.page_template}>
            {t('menu.page_template')}
          </NavLink>
        ),
        icon: IconUtils.menu.pages_template,
        visible: ifAnyGranted(SiteMap.page_content.publish_static_page_roles),
      }),

      {
        type: 'divider',
      },

      getMenuItem({
        key: NavigationMenuEnum.SETTING_INFO,
        label: (
          <NavLink href={SiteMap.management.school.info}>
            {t('menu.school_info')}
          </NavLink>
        ),
        icon: <HomeOutlined />,
        visible: ifAnyGranted(SiteMap.management.school.info_roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.SETTING_SETTING,
        label: (
          <NavLink href={SiteMap.management.school.setting}>
            {t('menu.school_setting')}
          </NavLink>
        ),
        icon: IconUtils.menu.setting,
        visible: ifAnyGranted(SiteMap.management.school.info_roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.SETTING_EMAIL_TEMPLATE,
        label: (
          <NavLink href={SiteMap.management.setting.setting_email_template}>
            {t('menu.email_template')}
          </NavLink>
        ),
        icon: <MailOutlined />,
        visible: ifAnyGranted([RoleEnum.SCHOOL]),
      }),

      getMenuItem({
        key: NavigationMenuEnum.SETTING_PAYMENT_CURRENCY,
        label: (
          <NavLink href={SiteMap.management.setting.currency}>
            {t('menu.payment_currency')}
          </NavLink>
        ),
        icon: <DollarOutlined />,
        visible: ifAnyGranted([RoleEnum.SCHOOL]),
      }),

      getMenuItem({
        key: NavigationMenuEnum.SETTING_PAYMENT_METHOD,
        label: (
          <NavLink href={SiteMap.management.setting.payment_method}>
            {t('menu.payment_method')}
          </NavLink>
        ),
        icon: <CreditCardOutlined />,
        visible: ifAnyGranted([RoleEnum.SCHOOL]),
      }),
    ];
  }, [authorities, i18next.language, proUser]);

  return (
    <HeaderMenu
      group={NavigationMenuEnum.SETTING}
      groupIcon={IconUtils.menu.setting}
      className={'school-setting-menu'}
      menuItems={menuItems}
    />
  );
};

export default SchoolSettingMenu;
