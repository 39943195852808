import {
  ContentActionEnum,
  ContentViewerInlineEditable,
} from '@cms/comps/content/ContentViewerComp';
import React from 'react';
import { DragNDropItemCompProps } from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import { useDragNDropInlineCompContext } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';
import { CompConfiguration } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { TextContentProps } from '@cms/content/ContentType';
import DnDDropInlineItem from '@cms/comps/interact/drag-n-drop/inline/items/DnDInlineDropItem';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import OptionEditable from '@cms/editable/OptionEditable';
import { CompGroupEnum } from '@cms/utils/CompProps';

import { useDndInlineEditable } from '@cms/comps/interact/drag-n-drop/inline/DnDInlineEditable';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import DOMPurify from 'dompurify';

export const DnDDropInlineGroup = ({
  drop,
  index,
}: {
  drop: DragNDropItemCompProps;
  index: number;
}) => {
  const { sourceItems, targetItems, disableTarget, answers } =
    useDragNDropInlineCompContext();

  const {
    editItem,
    changeToEdit,
    updateOptionType,
    updateGroupOptions,
    updateOptionData,
  } = useDndInlineEditable();

  const getInlineDragItem = (dropItem: string) => {
    return answers
      .filter((ans) => {
        return ans.split(':')[0] === dropItem;
      })
      .map((ans) => {
        return ans.split(':')[1];
      });
  };

  const textArray = drop.content.data.split(CompConfiguration.INLINE_COMPONENT);

  return (
    <>
      {editItem === drop.label ? (
        <ContentViewerInlineEditable
          contentData={drop.content}
          onChange={(data) =>
            updateOptionData(data, index, drop, CompGroupEnum.target)
          }
          onCancel={() => changeToEdit('')}
        />
      ) : (
        <OptionEditable
          disabled={{
            addOption: targetItems.length === 15,
            removeOption: targetItems.length === 1,
          }}
          contentTypes={[ContentActionEnum.CHANGE_TO_TEXT]}
          type={drop.content.type}
          onChangeType={(newType) =>
            updateOptionType(newType, index, drop, CompGroupEnum.target)
          }
          onOperation={(operation) =>
            updateGroupOptions(operation, index, drop, CompGroupEnum.target)
          }
        >
          <div className={`dnd-inline-group group-${index}`}>
            {textArray.map((text, idx) => {
              const sourceData = `${drop.label}|${COMPONENT_UTILS.getABC(
                idx - 1
              )}`;
              const dragItems = getInlineDragItem(sourceData);

              const dropItem = {
                label: sourceData,
                content: {
                  id: sourceData,
                  data: '',
                  type: AssetsTypeEnum.TEXT,
                } as TextContentProps,
              };

              return (
                <React.Fragment
                  key={text + '_' + idx + '_' + dragItems.join('')}
                >
                  {idx > 0 && (
                    <>
                      {idx > 0 && (
                        <span className={'dnd-inline-text'}>&nbsp;</span>
                      )}

                      <DnDDropInlineItem
                        dragItems={getInlineDragItem(sourceData)}
                        sourceItems={sourceItems}
                        dropItem={dropItem}
                        disableTarget={disableTarget}
                      />
                    </>
                  )}

                  <div
                    className={'dnd-inline-text'}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(text),
                    }}
                  />
                </React.Fragment>
              );
            })}
          </div>

          <ComposeModeContent>
            {editItem === '' && (
              <Button
                className={'edit-content-group'}
                size={'small'}
                type={'primary'}
                shape={'circle'}
                onClick={() => changeToEdit(drop.label)}
                icon={IconUtils.actions.edit}
              />
            )}
          </ComposeModeContent>
        </OptionEditable>
      )}
    </>
  );
};
