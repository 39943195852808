import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import {
  OneColumnSection,
  OneColumnSectionProps,
} from '@cms/section-bank/columns/OneColumnSection';
import {
  TwoColumnSection,
  TwoColumnSectionProps,
} from '@cms/section-bank/columns/TwoColumnSection';
import {
  ThreeColumnSection,
  ThreeColumnSectionProps,
} from '@cms/section-bank/columns/ThreeColumnSection';
import {
  FixedGroupSection,
  FixedGroupSectionProps,
} from '@cms/section-bank/fixed-group/FixedGroupSection';
import React from 'react';
import { CompMode } from '@cms/ComponentInteface';
import {
  PresentationSection,
  PresentationSectionProps,
} from '@cms/section-bank/presentation/PresentationSection';
import styled from 'styled-components';
import {
  InteractVideoSection,
  InteractVideoSectionProps,
} from '@cms/section-bank/interact-video/InteractVideoSection';

const LessonSection = (props: {
  item: LessonSectionProps;
  visible: boolean;
  mode: CompMode;
}) => {
  if (props.visible) {
    return (
      <LessonSectionStyle
        className={`lesson-section section-${props.item.lessonSectionId}`}
      >
        <SectionItem {...props} />
      </LessonSectionStyle>
    );
  } else {
    return null;
  }
};

export default LessonSection;

export const SectionItem = (props: {
  item: LessonSectionProps;
  mode: CompMode;
}) => {
  switch (props.item.type) {
    case SectionTypeEnum.presentation:
      return (
        <PresentationSection
          item={props.item as PresentationSectionProps}
          mode={props.mode}
        />
      );

    case SectionTypeEnum.one_column:
      return (
        <OneColumnSection
          item={props.item as OneColumnSectionProps}
          mode={props.mode}
        />
      );

    case SectionTypeEnum.two_column:
      return (
        <TwoColumnSection
          item={props.item as TwoColumnSectionProps}
          mode={props.mode}
        />
      );

    case SectionTypeEnum.three_column:
      return (
        <ThreeColumnSection
          item={props.item as ThreeColumnSectionProps}
          mode={props.mode}
        />
      );

    case SectionTypeEnum.fixed_group:
      return (
        <FixedGroupSection
          item={props.item as FixedGroupSectionProps}
          mode={props.mode}
        />
      );

    case SectionTypeEnum.interact_video:
      return (
        <InteractVideoSection
          item={props.item as InteractVideoSectionProps}
          mode={props.mode}
        />
      );

    default:
      return null;
  }
};

const LessonSectionStyle = styled.div`
  &:not(:first-child) {
    margin-top: var(--cms-padding-section, 2em);
  }

  .droppable-container {
    .empty-space {
      display: flex;
      min-height: 5em;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1.5em 1.5em 0 1.5em;
      //border: 2px dashed #ccc;
      //background: rgba(0, 0, 0, 0.03) !important;
      //white-space: normal;
      font-size: 80%;
      color: #666;
    }

    .hint {
      font-size: 80%;
      color: #666;
      text-align: center;
      display: block;
    }
  }
`;
