import { CourseAPI, CourseRes } from '@services/private/CourseAPI';
import React, { useEffect, useState } from 'react';
import { EntityStatusEnum } from '@services/model/common';
import { Col, notification, Row } from 'antd';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import CreateCourseForm from '@modules/course/container/CreateCourseForm';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { CourseInfoEditable } from '@modules/course/components/CourseInfo';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import Button from 'antd/lib/button';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';

export enum CourseActionEnum {
  default = '',

  add_course = 'add_course',
  edit_course = 'edit_course',
  view_student = 'view-student',
  remove_course = 'remove-course',
}

export default function MyTeacherCourse() {
  const { t } = useTranslation();

  const [deleteCourse] = CourseAPI.endpoints.deleteCourse.useMutation();
  const [getMyCourses, { data, isSuccess }] =
    CourseAPI.endpoints.getMyCourses.useLazyQuery({});

  const [publishCourse, setPublishCourse] = useState<CourseRes[]>([]);
  const [privateCourse, setPrivateCourse] = useState<CourseRes[]>([]);

  const [action, setAction] = useState(CourseActionEnum.default);
  const [courseData, setCourseData] = useState<CourseRes | null>(null);

  useEffect(() => {
    getMyCourses({});
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      if (data.totalElements > 0 && data.content) {
        setCourses(data.content);
      }
    }
  }, [data]);

  const setCourses = (courses: CourseRes[]) => {
    const publishCourse: CourseRes[] = [];
    const privateCourse: CourseRes[] = [];

    courses.forEach((course) => {
      if (course.status === EntityStatusEnum.PUBLISHED) {
        publishCourse.push(course);
      } else {
        privateCourse.push(course);
      }

      setPublishCourse(publishCourse);
      setPrivateCourse(privateCourse);
    });
  };

  const handleOnCancel = () => {
    setAction(CourseActionEnum.default);
    setCourseData(null);
  };

  const handleOnCreateSuccess = () => {
    setAction(CourseActionEnum.default);
    getMyCourses({});
  };

  const handleOnEdit = (courseData: CourseRes) => {
    setCourseData(courseData);
    setAction(CourseActionEnum.edit_course);
  };

  const handleOnViewStudents = (courseData: CourseRes) => {
    setCourseData(courseData);
    setAction(CourseActionEnum.view_student);
  };

  const handleOnRemove = (id: number) => {
    deleteCourse(id)
      .unwrap()
      .then(() => {
        setAction(CourseActionEnum.default);
      })
      .catch((e) => {
        notification.error({
          message: t('header.error'),
          description: t(e.data.error_description),
          placement: 'bottomRight',
        });
        setAction(CourseActionEnum.default);
      });
  };

  const handleOnShare = (id: number) => {
    console.log(id);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('header.courses')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction(CourseActionEnum.add_course)}
          />
        </H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        {privateCourse &&
          privateCourse.length > 0 &&
          privateCourse.map((item) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
                key={item.courseId + '-' + item.modifiedDate}
              >
                <CourseInfoEditable
                  item={item}
                  onEdit={handleOnEdit}
                  onViewStudent={handleOnViewStudents}
                  onRemove={handleOnRemove}
                  onShare={handleOnShare}
                />
              </Col>
            );
          })}
      </Row>

      {publishCourse && publishCourse.length > 0 && (
        <>
          <HeaderGroup className="header-group">
            <H1>{t('course.opening')}</H1>
          </HeaderGroup>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            {publishCourse.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                  key={item.courseId + '-' + item.modifiedDate}
                >
                  <CourseInfoEditable
                    item={item}
                    onEdit={handleOnEdit}
                    onViewStudent={handleOnViewStudents}
                    onRemove={handleOnRemove}
                    onShare={handleOnShare}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {action === CourseActionEnum.add_course && (
        <CustomModal
          header={t('course.actions.add')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateCourseForm
              type={CourseActionEnum.add_course}
              successHandle={handleOnCreateSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action !== CourseActionEnum.add_course && courseData && (
        <CustomModal
          header={t('course.actions.update_info')}
          className={CustomModalClassEnum.large_modal}
          content={
            <CreateCourseForm
              type={action}
              courseData={courseData}
              successHandle={handleOnCreateSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
