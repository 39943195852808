import {
  LongOperationLayoutEnum,
  MathOperationEnum,
  MathOperationProps,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import Button from '@components/button';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { MinusOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { LongOperationTableEditable } from '../table/LongOperationTableEditable';
import { LongEditableStyle } from '../addition/LongAdditionEditable';
import Input from 'antd/lib/input/Input';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { useTranslation } from 'react-i18next';

export const LongSubtractionEditable = (props: {
  layout: LongOperationLayoutEnum;
  minuend: string;
  subtrachend: string[];
  expression: OperationExpProps[];
  onChange: (
    minuend: string,
    subtrachend: string[],
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => void;
}) => {
  const { t } = useTranslation();

  const [minuend, setMinuend] = useState<string>(props.minuend);
  const [subtrachend, setSubtrachend] = useState<string[]>(props.subtrachend);

  const [expression, setExpression] = useState<OperationExpProps[]>([]);
  const [answerExpression, setAnswerExpression] = useState<OperationExpProps[]>(
    []
  );

  useEffect(() => {
    setExpression([...props.expression]);
  }, [props.expression]);

  useEffect(() => {
    const minuend = props.minuend;
    const subtrachend = [...props.subtrachend];

    let answer: MathOperationProps;

    if (props.layout === LongOperationLayoutEnum.vertical) {
      answer = OperationUtils.parseSubtraction(minuend, subtrachend, false);
    } else if (props.layout === LongOperationLayoutEnum.horizontal) {
      answer = OperationUtils.parseSubtractionInline(minuend, subtrachend);
    } else {
      answer = OperationUtils.parseSubtraction(minuend, subtrachend, true);
    }

    setMinuend(minuend);
    setSubtrachend(subtrachend);
    setAnswerExpression(answer.results);
  }, [props.minuend, props.subtrachend, props.layout]);

  const handleOnClick = () => {
    let answer: MathOperationProps;

    if (props.layout === LongOperationLayoutEnum.vertical) {
      answer = OperationUtils.parseSubtraction(minuend, subtrachend, false);
    } else if (props.layout === LongOperationLayoutEnum.horizontal) {
      answer = OperationUtils.parseSubtractionInline(minuend, subtrachend);
    } else {
      answer = OperationUtils.parseSubtraction(minuend, subtrachend, true);
    }

    setExpression(answer.results);
    setAnswerExpression(answer.results);
  };

  const handleOnExpressionChange = (
    index: number,
    expression: OperationExpProps
  ) => {
    setExpression((prev) => {
      return COMPONENT_UTILS.updateAtIndex(prev, index, expression);
    });
  };

  const handleOnChange = (
    type: 'minuend' | 'subtrachend',
    val: string,
    index: number
  ) => {
    if (type === 'minuend') {
      setMinuend(val);
    } else {
      setSubtrachend((prev) => {
        return [...prev].map((v, idx) => {
          return idx === index ? val : v;
        });
      });
    }
  };

  const removeSubtrachends = (index: number) => {
    setSubtrachend((prev) => {
      return COMPONENT_UTILS.removeAtIndex(prev, index);
    });
  };

  const insertSubtrachends = (index: number) => {
    setSubtrachend((prev) => {
      return COMPONENT_UTILS.insertAtIndex(prev, index, prev[index]);
    });
  };

  const onUpdateEditableData = () => {
    props.onChange(minuend, subtrachend, expression, answerExpression);
  };

  return (
    <>
      <LongEditableStyle className={'long-subtraction-editable'}>
        <Row>
          <Col flex={'250px'}>
            <InputGroup
              label={t('component.operation.minuend')}
              key={'Minuend'}
            >
              <Space>
                <Input
                  name="minuend"
                  type="number"
                  onChange={(val) =>
                    handleOnChange('minuend', val.target.value, 0)
                  }
                  value={minuend}
                />
              </Space>
            </InputGroup>

            {subtrachend.map((val, index) => {
              return (
                <InputGroup
                  label={t('component.operation.subtrachend', { n: index + 1 })}
                  key={'Subtrachend ' + (index + 1)}
                >
                  <Space>
                    <Input
                      name="subtrachend"
                      type="number"
                      onChange={(val) =>
                        handleOnChange('subtrachend', val.target.value, index)
                      }
                      value={val}
                    />

                    <Button
                      type={'default'}
                      shape={'circle'}
                      size={'small'}
                      danger
                      disabled={subtrachend.length === 1}
                      onClick={() => removeSubtrachends(index)}
                    >
                      <MinusOutlined />
                    </Button>
                    <Button
                      type={'default'}
                      shape={'circle'}
                      size={'small'}
                      onClick={() => insertSubtrachends(index)}
                    >
                      <PlusOutlined />
                    </Button>
                  </Space>
                </InputGroup>
              );
            })}
          </Col>

          <Col flex={'100px'}>
            <div className={'button-center-group'}>
              <Button type={'primary'} shape={'circle'} onClick={handleOnClick}>
                <SyncOutlined />
              </Button>
            </div>
          </Col>

          <Col flex={'auto'}>
            <LongOperationTableEditable
              layout={props.layout}
              operation={MathOperationEnum.subtraction}
              expression={expression}
              answerExpression={answerExpression}
              onChange={handleOnExpressionChange}
            />
          </Col>
        </Row>
      </LongEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={onUpdateEditableData}
      />
    </>
  );
};
