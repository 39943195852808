import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import { PageRouterProps } from '@router/modules/props';
import ManagePages from '@router/pages/page_content/manage_pages';
import PageTemplate from '@router/pages/page_content/page_template';
import PublishPageContent from '@router/pages/page_content/publish_page_content';
import ManagePageContent from '@router/pages/page_content/manage_page_content';

export const PageContentRouter: PageRouterProps[] = [
  {
    path: SiteMap.page_content.static_page,
    element: (
      <PrivateRouter
        roles={SiteMap.page_content.static_page_roles}
        element={<ManagePages />}
      />
    ),
  },

  {
    path: SiteMap.page_content.content,
    element: (
      <PrivateRouter
        roles={SiteMap.page_content.content_roles}
        element={<ManagePageContent />}
      />
    ),
  },

  {
    path: SiteMap.page_content.publish_static_page,
    element: (
      <PrivateRouter
        roles={SiteMap.page_content.publish_static_page_roles}
        element={<PublishPageContent />}
      />
    ),
  },

  {
    path: SiteMap.page_content.page_template,
    element: (
      <PrivateRouter
        roles={SiteMap.page_content.publish_static_page_roles}
        element={<PageTemplate />}
      />
    ),
  },
];
