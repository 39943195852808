import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List } from 'antd';
import { UserRes } from '@modules/users/services/model';
import {
  StudentIconDisplay,
  StudentNameDisplay,
} from '@components/info/StudentInfo';

export enum SelectStudentActionEnum {
  add = 'add',
  remove = 'remove',
}

const StudentListItem = (props: {
  item: UserRes;
  type?: SelectStudentActionEnum;
  selected?: boolean;
  generateTitle?: (item: UserRes) => ReactNode;
  onSelect?: (user: UserRes) => void;
  onRemove?: (userId: number) => void;
}) => {
  const { t } = useTranslation();

  const handleSelect = () => {
    if (props.onSelect) {
      props.onSelect(props.item);
    }
  };

  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.item.userId);
    }
  };

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<StudentIconDisplay size={'large'} item={props.item} />}
        title={
          <>
            {props.generateTitle ? (
              props.generateTitle(props.item)
            ) : (
              <StudentNameDisplay item={props.item} />
            )}
          </>
        }
      />

      {props.type && props.type === SelectStudentActionEnum.add ? (
        <Button
          disabled={props.selected}
          size={'small'}
          type={'primary'}
          shape={'round'}
          onClick={handleSelect}
          title={t('label.select')}
        >
          {t('label.select')}
        </Button>
      ) : (
        <Button
          size={'small'}
          type={'primary'}
          shape={'round'}
          danger
          onClick={handleRemove}
          title={t('label.remove')}
        >
          {t('label.remove')}
        </Button>
      )}
    </List.Item>
  );
};

export default StudentListItem;
