import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  CreateExerciseReq,
  ExerciseRes,
  ExerciseResponseRes,
  ExerciseScoreOverallRes,
  GradeExerciseReq,
  SelfCheckExerciseReq,
  UpdateExerciseAndAnswerReq,
  UpdateExerciseStatusReq,
} from '@modules/assignments/service/exercise_model';

export const AnonymousDoExerciseAPI = createApi({
  reducerPath: 'AnonymousDoExerciseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    viewExerciseStatus: builder.query<ExerciseRes, number>({
      query: (exerciseId) => ({
        url: `/public/exercises/${exerciseId}/status`,
      }),
    }),

    viewExerciseScore: builder.query<ExerciseScoreOverallRes, number>({
      query: (exerciseId) => ({
        url: `/public/exercises/${exerciseId}/scores`,
      }),
    }),

    startExercise: builder.mutation<ExerciseRes, number>({
      query: (exerciseId) => ({
        url: `/public/exercises/${exerciseId}/start`,
        method: 'POST',
      }),
    }),

    createExercise: builder.mutation<ExerciseRes, CreateExerciseReq>({
      query: (body) => ({
        url: '/public/exercises/create',
        method: 'POST',
        body,
      }),
    }),

    viewExercise: builder.query<ExerciseRes, number>({
      query: (exerciseId) => ({
        url: `/public/exercises/${exerciseId}/view`,
      }),
    }),

    saveExercise: builder.mutation<ExerciseRes, UpdateExerciseAndAnswerReq>({
      query: (body: UpdateExerciseAndAnswerReq) => ({
        url: `/public/exercises/${body.exerciseId}/save`,
        method: 'PUT',
        body,
      }),
    }),

    submitExercise: builder.mutation<ExerciseRes, UpdateExerciseAndAnswerReq>({
      query: (body: UpdateExerciseAndAnswerReq) => ({
        url: `/public/exercises/${body.exerciseId}/submit`,
        body,
        method: 'PUT',
      }),
    }),

    gradeExercise: builder.mutation<ExerciseRes, GradeExerciseReq>({
      query: (body: GradeExerciseReq) => ({
        url: `/public/exercises/${body.exerciseId}/grade`,
        body,
        method: 'PUT',
      }),
    }),

    selfCheck: builder.mutation<ExerciseResponseRes[], SelfCheckExerciseReq>({
      query: (body) => ({
        url: `/public/exercises/self-check`,
        body,
        method: 'POST',
      }),
    }),

    // for testing
    updateExercise: builder.mutation<ExerciseRes, UpdateExerciseStatusReq>({
      query: (body: UpdateExerciseStatusReq) => ({
        url: `/public/exercises/${body.exerciseId}/update`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
