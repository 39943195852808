import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';

import { UserSubscriptionAPI } from '@services/private/UserSubscriptionAPI';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import ButtonGroup from '@components/button/ButtonGroup';
import { RequestStatusEnum } from '@services/model/common';
import { Default_Gutter } from '@components/grid';

export const ViewUserRequest = (props: {
  userSubscriptionId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [findSubscription, { data }] =
    UserSubscriptionAPI.endpoints.findSubscription.useLazyQuery();

  const [readSubscription, { isLoading }] =
    UserSubscriptionAPI.endpoints.readSubscription.useMutation({});

  useEffect(() => {
    findSubscription(props.userSubscriptionId);
  }, [props.userSubscriptionId]);

  const markAsViewed = () => {
    readSubscription(props.userSubscriptionId)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {});
  };

  return (
    <>
      {data && (
        <>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <InputGroup label={t('label.firstName')}>
                <InputValue>{data.firstName}</InputValue>
              </InputGroup>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <InputGroup label={t('label.lastName')}>
                <InputValue> {data.lastName}</InputValue>
              </InputGroup>
            </Col>
          </Row>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <InputGroup label={t('label.email')}>
                <InputValue>{data.email}</InputValue>
              </InputGroup>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <InputGroup label={t('label.phone')}>
                <InputValue>{data.phoneNumber}</InputValue>
              </InputGroup>
            </Col>
          </Row>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <InputGroup label={t('user_requests.label.message')}>
                <InputValue type={'textarea'}>{data.message}</InputValue>
              </InputGroup>
            </Col>
          </Row>

          {data.company && (
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <InputGroup label={t('user_requests.label.message')}>
                  <InputValue>{data.company}</InputValue>
                </InputGroup>
              </Col>
            </Row>
          )}

          {data.subject && (
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <InputGroup label={t('user_requests.label.subject')}>
                  <InputValue>{data.subject}</InputValue>
                </InputGroup>
              </Col>
            </Row>
          )}

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <ButtonGroup type={'right'}>
                <Button
                  type={'default'}
                  shape={'round'}
                  onClick={props.onCancel}
                >
                  {t('button.close')}
                </Button>

                {data == null ||
                  (data.status !== RequestStatusEnum.READ && (
                    <Button
                      type={'primary'}
                      shape={'round'}
                      onClick={markAsViewed}
                      loading={isLoading}
                    >
                      {t('user_requests.actions.mark_as_viewed')}
                    </Button>
                  ))}
              </ButtonGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
