import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { EditAssignmentPage } from '@modules/assignments/pages/EditAssignmentPage';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const EditAssignment = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { id: assignmentInstanceId } = router.query;

  return (
    <ManagementLayout className={'edit-assignment-page'}>
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('assignment.actions.edit')}
        description={t('assignment.actions.edit')}
      />

      <EditAssignmentPage assignmentInstanceId={Number(assignmentInstanceId)} />
    </ManagementLayout>
  );
};

export default EditAssignment;
