import { LessonReportTypeEnum } from '@services/reports/AssignmentReport';
import { Select } from 'antd';
import React from 'react';
import { InputGroup } from '@components/input/InputGroup';
import {
  LineChartOutlined,
  PercentageOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
const { Option } = Select;

export const SelectReportType = (props: {
  value: LessonReportTypeEnum;
  enableStandardReport: boolean;
  onChange: (value: LessonReportTypeEnum) => void;
}) => {
  return (
    <InputGroup label={'Select Report Type'}>
      <Select
        size={'large'}
        style={{ width: 200 }}
        placeholder="Select report type"
        optionFilterProp="children"
        onChange={props.onChange}
        value={props.value}
      >
        <Option value={LessonReportTypeEnum.score}>
          <PercentageOutlined />
          &nbsp; Score
        </Option>

        <Option value={LessonReportTypeEnum.history}>
          <LineChartOutlined />
          &nbsp; Comparation
        </Option>

        {props.enableStandardReport && (
          <Option value={LessonReportTypeEnum.standard}>
            <PieChartOutlined />
            &nbsp; Standard
          </Option>
        )}
      </Select>
    </InputGroup>
  );
};
