import React, { useState } from 'react';

import { Button, notification, Result } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { LoginOutlined } from '@ant-design/icons';
import env from '@utils/config';
import NavLink from '@components/button/NavLink';
import { ForgotPwReq, UserRequestAPI } from '@services/UserRequestAPI';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';

const ForgotPasswordForm = (props: { successHandle: () => void }) => {
  const { t } = useTranslation();

  const [forgotPassword, { isSuccess, isLoading }] =
    UserRequestAPI.endpoints.forgotPassword.useMutation({});

  const [showWarning, setShowWarning] = useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      email: '',
    },

    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t('registration.validation.email_invalid'))
        .required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const payload: ForgotPwReq = {
        clientId: env.CLIENT_ID,
        email: values.email,
      };

      forgotPassword(payload)
        .unwrap()
        .then(() => {
          props.successHandle();
          setShowWarning(true);
        })
        .catch(() => {
          setShowWarning(false);
          notification.error({
            message: t('forgot_password.warning.error'),
            description: t('errors.email_not_exits'),
            placement: 'bottomRight',
          });
        });
    },
  });

  if (showWarning) {
    return (
      <>
        {isSuccess && (
          <Result
            status="success"
            title={t('forgot_password.warning.success')}
            subTitle={t('forgot_password.warning.success_message')}
            extra={
              <NavLink href={SiteMap.auth.login}>
                <Button
                  type="primary"
                  shape={'round'}
                  size={'large'}
                  icon={IconUtils.login}
                >
                  <LoginOutlined /> {t('menu.login')}
                </Button>
              </NavLink>
            }
          />
        )}
      </>
    );
  } else {
    return (
      <FormikProvider value={formik}>
        <Input
          label={t('label.email')}
          formik={formik}
          name="email"
          type="email"
          placeholder={t('form.enter_email')}
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        <div className="submit-container">
          <ButtonGroup>
            <Button
              loading={isLoading}
              type={'primary'}
              onClick={formik.submitForm}
              size={'large'}
              shape={'round'}
              icon={IconUtils.actions.send_message}
            >
              {t('button.get_password')}
            </Button>

            <NavLink href={SiteMap.index}>
              <Button type="default" size={'large'} shape={'round'}>
                {t('button.back')}
              </Button>
            </NavLink>
          </ButtonGroup>
        </div>
      </FormikProvider>
    );
  }
};

export default ForgotPasswordForm;
