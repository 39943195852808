import React from 'react';
import styled from 'styled-components';
import { Divider, Space } from 'antd';
import HeaderNotificationMenu from '@components/template/app-layout/header/HeaderNotificationMenu';
import HeaderAccountMenu from './HeaderAccountMenu';
import HeaderMessageMenu from '@components/template/app-layout/header/HeaderMessageMenu';
import SwitchLanguage from '@components/header/SwitchLanguage';
import HeaderSwitchChildrenMenu from '@components/template/app-layout/header/HeaderSwitchChildrenMenu';
import { SelectTeacherGroup } from '@components/header/SelectTeacherGroup';
import UserManagementMenu from '@components/template/app-layout/header/header-menu/UserManagementMenu';
import ProductManagementMenu from '@components/template/app-layout/header/header-menu/ProductManagementMenu';
import SchoolManagementMenu from '@components/template/app-layout/header/header-menu/SchoolManagementMenu';
import SchoolSettingMenu from '@components/template/app-layout/header/header-menu/SchoolSettingMenu';
import LicenseMenu from '@components/template/app-layout/header/header-menu/LicenseMenu';

const HeaderNavigation = () => {
  return (
    <HeaderNavigationStyle size={[10, 8]} wrap align={'center'}>
      <SelectTeacherGroup />
      <SwitchLanguage />

      <UserManagementMenu />
      <ProductManagementMenu />
      <SchoolManagementMenu />
      <LicenseMenu />
      <SchoolSettingMenu />

      <Divider type={'vertical'} />
      <HeaderMessageMenu />
      <HeaderNotificationMenu />

      <HeaderSwitchChildrenMenu />
      <HeaderAccountMenu />
    </HeaderNavigationStyle>
  );
};

export default HeaderNavigation;

const HeaderNavigationStyle = styled(Space)`
  .ant-divider.ant-divider-vertical {
    height: 30px;
    margin: 0px 10px;
    border-left-width: 2px;
  }
`;
