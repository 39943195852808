import React from 'react';
import { CompConfiguration } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import { useFractionCompCompContext } from '@cms/comps/math/frac/FractionCompContext';
import { FractionInlineComp } from '@cms/comps/math/frac/FractionInlineComp';

const FractionGroupItems = () => {
  const {
    modifiedTime,
    disabled,
    targetItems,
    answers,
    setting,
    handleOnChange,
  } = useFractionCompCompContext();

  const getInlineAnswer = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans.split(':')[1];
      }
    });

    return inlineAns;
  };

  const getAnswerDisplay = (sourceId: string): string => {
    let inlineAns = '';

    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans;
      }
    });
    return inlineAns;
  };

  return (
    <FractionGroupStyle className={`fraction-group`}>
      {targetItems &&
        targetItems.map((drop, index) => {
          const textArray = drop.content.data.split(
            CompConfiguration.INLINE_COMPONENT
          );

          return (
            <div
              className={`fraction-group-item group-${index}`}
              key={drop.label + '_' + drop.content.id + '_' + modifiedTime}
            >
              {textArray.map((text, idx) => {
                const sourceData = drop.label + '|' + idx;

                return (
                  <React.Fragment key={text + '_' + idx}>
                    {idx > 0 && (
                      <div className={`component-and-feedback part-${idx}`}>
                        <CompFeedbackResultIcon
                          answer={getAnswerDisplay(sourceData)}
                        />

                        <FractionInlineComp
                          type={setting.type}
                          digit={setting.digit}
                          disabled={disabled}
                          value={getInlineAnswer(sourceData)}
                          onChange={(val) =>
                            handleOnChange(sourceData + ':' + val)
                          }
                        />
                      </div>
                    )}

                    {text.trim() !== '' && (
                      <>
                        {text
                          .split(CompConfiguration.SEPARATE_CONTENT)
                          .map((content, index) => {
                            return (
                              <span
                                className={'fraction-inline-text'}
                                key={content + '_' + index}
                                dangerouslySetInnerHTML={{
                                  __html: '&nbsp;' + content,
                                }}
                              />
                            );
                          })}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
    </FractionGroupStyle>
  );
};

export default FractionGroupItems;

const FractionGroupStyle = styled.div`
  .fraction-group-item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: var(--cms-padding-option, 0.5em);
    }

    .component-and-feedback {
      position: relative;
      padding: 0 0.25em;
      display: inline-flex;
      align-items: center;

      .feedback-icon {
        position: relative;
      }
    }
  }
`;
