import React, { useEffect, useState } from 'react';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { Button, notification } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { SchoolAPI, SchoolRes } from '@services/private/SchoolAPI';
import PublishProfileContainer from '@modules/setting/container/public-site/PublicProfile';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { SessionAPI } from '@services/private/SessionAPI';
import { CourseAPI, CourseRes } from '@services/private/CourseAPI';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { SessionRes } from '@services/model/session';
import { ProductRes } from '@modules/product/services/product_model';

export default function ReviewMyPublicSitePage(props: any) {
  const { t } = useTranslation();
  const router = useRouter();

  const [getSchool, { data: _school }] =
    SchoolAPI.endpoints.getSchool.useLazyQuery();

  const [getPublishCourses, { data: _courses }] =
    CourseAPI.endpoints.getPublishCourses.useLazyQuery();

  const [getPublishProducts, { data: _products }] =
    ProductAPI.endpoints.getPublishProducts.useLazyQuery();

  const [getPublishSessions, { data: _sessions }] =
    SessionAPI.endpoints.getPublishSessions.useLazyQuery();

  const [publishSchool] = SchoolAPI.endpoints.publishSchool.useMutation({});

  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState<SchoolRes | null>(null);
  const [products, setProducts] = useState<ProductRes[]>([]);
  const [courses, setCourses] = useState<CourseRes[]>([]);
  const [sessions, setSessions] = useState<SessionRes[]>([]);

  useEffect(() => {
    getSchool({});
    getPublishCourses({});
    getPublishProducts({});
    getPublishSessions({});
  }, []);

  useEffect(() => {
    if (_school) {
      setSchoolData(_school);
    }
  }, [_school]);

  useEffect(() => {
    if (_products) {
      setProducts(_products.content);
    }
  }, [_products]);

  useEffect(() => {
    if (_courses) {
      setCourses(_courses.content);
    }
  }, [_courses]);

  useEffect(() => {
    if (_sessions) {
      setSessions(_sessions);
    }
  }, [_sessions]);

  const backToEditPage = () => {
    router.push(SiteMap.management.school.info);
  };

  const publishProfile = () => {
    publishSchool({})
      .unwrap()
      .then(() => {
        setLoading(false);

        notification.success({
          message: `Cập nhật thành công`,
          description: 'Cập nhật thành công.',
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        setLoading(false);

        notification.error({
          message: `Cập nhật thất bại`,
          description: 'Cập nhật thất bại.',
          placement: 'bottomRight',
        });
      });
  };

  return (
    <AuthorizeStyle {...props}>
      {schoolData && (
        <PublishProfileContainer
          schoolData={schoolData}
          products={products}
          courses={courses}
          sessions={sessions}
        />
      )}

      <div>
        <div
          className="d-flex submit-container"
          style={{ justifyContent: 'space-between' }}
        >
          <ButtonGroup>
            <Button size={'large'} type="default" onClick={backToEditPage}>
              {t('button.back')}
            </Button>
          </ButtonGroup>

          <ButtonGroup type={'right'}>
            <Button
              size={'large'}
              type="primary"
              onClick={publishProfile}
              style={{ float: 'right' }}
              loading={loading}
            >
              {t('button.complete')}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </AuthorizeStyle>
  );
}
