import React from 'react';
import styled from 'styled-components';

import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
} from '@cms/ComponentInteface';

import { GlossaryGameContent } from '@cms/comps/game/GameUtils';

import GlossaryItem from './GlossaryItem';
import { ContainerRatio } from '@components/Layout/ContainerScale';

import { GlossaryType } from '@cms/comps/game/GlossaryWordBank';
import { GlossaryCompContextProvider } from '@cms/comps/game/glossary/GlossaryCompContext';

export interface GlossarySettingProps extends CompInteractSettingProps {
  showTerm: GlossaryType;
}

export interface GlossaryCompProps extends CompProps {
  configuration: {
    sourceItems: GlossaryGameContent;
  };
  setting: GlossarySettingProps;
}

export function GlossaryComp(props: {
  item: GlossaryCompProps;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  return (
    <GlossaryCompContextProvider item={props.item} onChange={props.onChange}>
      <GlossaryCompStyle className={'comp comp-glossary'}>
        <ContainerRatio
          className={'glossary-workspace-display'}
          ratio={'16 / 9'}
        >
          <GlossaryItem />
        </ContainerRatio>
      </GlossaryCompStyle>
    </GlossaryCompContextProvider>
  );
}

const GlossaryCompStyle = styled.div`
  position: relative;
  border: 2px solid #ccc;
  border-radius: 1em;

  .glossary-workspace-display {
    display: flex;
  }
`;
