import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CurrentRoleDisplay = () => {
  const { t } = useTranslation();

  return (
    <label style={{ fontSize: 13 }}>
      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
        {t('roles.teacher')}
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        {t('roles.school_admin')}
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        {t('roles.system_admin')}
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.STUDENT]}>
        {t('roles.student')}
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.PARENT]}>
        {t('roles.parent')}
      </Authorization>
    </label>
  );
};

export const RoleDisplay = (props: { role: RoleEnum }) => {
  const { t } = useTranslation();
  return (
    <label className={'role-display'}>
      {RoleEnum.TEACHER === props.role && <> {t('roles.teacher')}</>}
      {RoleEnum.SCHOOL === props.role && <> {t('roles.school_admin')}</>}
      {RoleEnum.ADMIN === props.role && <> {t('roles.system_admin')}</>}
      {RoleEnum.STUDENT === props.role && <> {t('roles.student')}</>}
      {RoleEnum.PARENT === props.role && <> {t('roles.parent')}</>}
    </label>
  );
};
