import React, { useEffect } from 'react';
import { Button, Col, notification, Row } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ImageScale } from '@utils/ImageScale';
import {
  UpdateStudentProfileReq,
  UserProfileAPI,
  UserProfileRes,
} from '@services/private/UserProfileAPI';
import Card, { CardClassEnum } from '@components/card';

import { Default_Gutter } from '@components/grid';
import { useSelector } from '@app/redux/hook';

export default function UserProfileForm(props: {
  onSuccess: () => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();

  const [getUserProfile, { data, isSuccess, error, isError }] =
    UserProfileAPI.endpoints.getUserProfile.useLazyQuery({});

  const [updateMyProfile, { isLoading }] =
    UserProfileAPI.endpoints.updateMyProfile.useMutation({});

  const { avatar, displayName } = useSelector((state) => state.userInformation);

  useEffect(() => {
    getUserProfile({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setUserProfileData(data);
    } else if (error && isError) {
      formik.setValues({
        name: '',
        displayName: displayName,
        avatarUrl: avatar,
      });
    }
  }, [data]);

  const setUserProfileData = (data: UserProfileRes) => {
    formik.setValues({
      name: data.name,
      displayName: data.displayName,
      avatarUrl: data.avatarUrl,
    });
  };

  const handleOnAvatarUploaded = (uploadedFile: string) => {
    formik.setFieldValue('avatarUrl', uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err');
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
      displayName: displayName,
      avatarUrl: avatar,
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      updateUserProfile(values);
    },
  });

  const updateUserProfile = (request: UpdateStudentProfileReq) => {
    updateMyProfile(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('profile.warning.update_success'),
          description: t('profile.warning.update_success_message'),
          placement: 'bottomRight',
        });
        props.onSuccess();
      })
      .catch(() => {
        notification.error({
          message: t('profile.warning.update_error'),
          description: t('profile.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <div className={'public-profile-container'}>
      <FormikProvider value={formik}>
        <div className={'form-group'}>
          <Card className={CardClassEnum.rectangle_box}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col flex={ImageScale.avatar.width + 24 + 'px'}>
                <InputGroup label={'Avatar'}>
                  <SingleUpload
                    scale={ImageScale.avatar.scale}
                    accept={'image/jpg, image/jpeg, image/png'}
                    multiple={false}
                    onSuccessFunc={handleOnAvatarUploaded}
                    onErrorFunc={handleOnUploadHasErr}
                  >
                    <IconImage
                      src={formik.values.avatarUrl}
                      width={ImageScale.avatar.width}
                      height={ImageScale.avatar.height}
                    />
                  </SingleUpload>
                </InputGroup>
              </Col>

              <Col flex={'auto'}>
                <Input
                  required
                  name="name"
                  type="text"
                  label={t('label.name')}
                  placeholder={t('label.name')}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                <Input
                  required
                  name="displayName"
                  type="text"
                  label={t('label.display_name')}
                  placeholder={t('label.display_name')}
                  onChange={formik.handleChange}
                  value={formik.values.displayName}
                />
              </Col>
            </Row>
          </Card>
        </div>

        <div className={'form-group'}>
          <ButtonGroup>
            {props.onCancel && (
              <Button type="default" shape={'round'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </ButtonGroup>

          <ButtonGroup type={'right'}>
            <Button
              type={'primary'}
              loading={isLoading}
              shape={'round'}
              onClick={formik.submitForm}
            >
              {t('button.public')}
            </Button>
          </ButtonGroup>
        </div>
      </FormikProvider>
    </div>
  );
}
