import React from 'react';
import styled from 'styled-components';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import {
  AnonymousViewMode,
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { SelfCheckBtn } from '@cms/lesson-template/components/button-wrapper/SelfCheckBtn';
import { NextSectionButton } from '@cms/lesson-template/components/buttons/NextSectionButton';
import { PreviousSectionButton } from '../buttons/PreviousSectionButton';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { ExitGradingButton } from '../buttons/grading/ExitGradingButton';
import { SubmitExerciseBtn } from '@cms/lesson-template/components/button-wrapper/SubmitExerciseBtn';

export const SingleSectionToolbar = () => {
  const { config } = useLessonTemplateContext();
  const { lastSection, section } = useLessonNavigationContext();

  return (
    <>
      {config.singleMode && !config.singleQuestionMode && (
        <>
          <AnonymousViewMode>
            <SingleSectionToolbarStyle
              className={'single-section-toolbar anonymous-toolbar'}
            >
              <PreviousSectionButton />
              ??
              <SwitchCondition
                conditions={[
                  { condition: !section.interact, children: <></> },
                  { condition: lastSection, children: <SelfCheckBtn /> },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleSectionToolbarStyle>
          </AnonymousViewMode>

          <SelfCheckExerciseMode>
            <SingleSectionToolbarStyle
              className={'single-section-toolbar self-check-toolbar'}
            >
              <PreviousSectionButton />

              <SwitchCondition
                conditions={[
                  { condition: !section.interact, children: <></> },
                  { condition: lastSection, children: <SelfCheckBtn /> },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleSectionToolbarStyle>
          </SelfCheckExerciseMode>

          <DoExerciseMode>
            <SingleSectionToolbarStyle
              className={'single-section-toolbar do-exercise-toolbar'}
            >
              <PreviousSectionButton />

              <SwitchCondition
                conditions={[
                  {
                    condition: lastSection,
                    children: <SubmitExerciseBtn />,
                  },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleSectionToolbarStyle>
          </DoExerciseMode>

          <GradingExerciseMode>
            <SingleSectionToolbarStyle
              className={'single-section-toolbar grading-toolbar'}
            >
              <PreviousSectionButton />

              <SwitchCondition
                conditions={[
                  {
                    condition: lastSection,
                    children: <ExitGradingButton />,
                  },
                  { condition: !lastSection, children: <NextSectionButton /> },
                ]}
              />
            </SingleSectionToolbarStyle>
          </GradingExerciseMode>

          <ReviewExerciseMode>
            <SingleSectionToolbarStyle
              className={'single-section-toolbar review-toolbar'}
            >
              <PreviousSectionButton />

              {!lastSection && <NextSectionButton />}
            </SingleSectionToolbarStyle>
          </ReviewExerciseMode>
        </>
      )}
    </>
  );
};

const SingleSectionToolbarStyle = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;

  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
