import { CompGroupEnum } from '@cms/utils/CompProps';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import { DragItemTypeEnum } from '@components/react-dnd/DragNDropTypes';
import OptionEditable from '@cms/editable/OptionEditable';
import React from 'react';
import { useDragNDropCompContext } from '@cms/comps/interact/drag-n-drop/DragNDropCompContext';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { HolderOutlined } from '@ant-design/icons';

const DnDDragItems = () => {
  const {
    modifiedTime,
    disableSource,
    sourceItems,
    updateOptionType,
    updateOptionData,
    updateGroupOptions,
  } = useDragNDropCompContext();

  return (
    <div className={'dnd-draggable-items'} key={modifiedTime}>
      <DropItem
        droppableId={''}
        acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM}
        disabledIds={disableSource}
        className={`${sourceItems.length}-drag-items`}
      >
        {sourceItems &&
          sourceItems.map((drag, index) => {
            return (
              <DragItem
                key={JSON.stringify(drag) + '_' + modifiedTime + '_' + index}
                acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM}
                index={index}
                draggableId={drag.label}
              >
                <ComposeModeContent>
                  <HolderOutlined />
                </ComposeModeContent>

                <div className={'dnd-option'}>
                  <OptionEditable
                    type={drag.content.type}
                    disabled={{
                      addOption: false,
                      removeOption: sourceItems.length === 1,
                    }}
                    onChangeType={(newType) =>
                      updateOptionType(
                        newType,
                        index,
                        drag,
                        CompGroupEnum.source
                      )
                    }
                    onOperation={(operation) =>
                      updateGroupOptions(
                        operation,
                        index,
                        drag,
                        CompGroupEnum.source
                      )
                    }
                  >
                    <ContentViewerEditable
                      contentData={drag.content}
                      onChange={(data) =>
                        updateOptionData(
                          data,
                          index,
                          drag,
                          CompGroupEnum.source
                        )
                      }
                    />
                  </OptionEditable>
                </div>
              </DragItem>
            );
          })}
      </DropItem>
    </div>
  );
};

export default DnDDragItems;
