import { ViewLessonContentProvider } from '@cms/lesson-template/context/ViewLessonContext';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import React, { useEffect, useState } from 'react';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { ViewExerciseProvider } from '@cms/lesson-template/context/ViewExerciseContext';
import { AnonymousServiceProvider } from '@cms/service/AnnonymousServiceProvider';
import { AnonymousDoExerciseAPI } from '@modules/assignments/service/AnonymousDoExerciseAPI';
import {
  ExerciseRes,
  ExerciseResponseRes,
} from '@modules/assignments/service/exercise_model';
import { LessonRes } from '@modules/product/services/lesson_model';
import {
  ResourceAnswerProps,
  ResourceGradingProps,
} from '@app/redux/slices/viewResource';
import ExerciseUtils from '@modules/assignments/utils/ExerciseUtils';

export const DoAnonymousExercisePage = (props: {
  type: CompMode;
  exerciseId: number;
  onExit: () => void;
}) => {
  const [viewExercise, { data, isSuccess }] =
    AnonymousDoExerciseAPI.endpoints.viewExercise.useLazyQuery({});

  const [exercise, setExercise] = useState<ExerciseRes | null>(null);
  const [lesson, setLesson] = useState<LessonRes | null>(null);

  const [answers, setAnswers] = useState<ResourceAnswerProps[]>([]);
  const [feedBacks, setFeedBacks] = useState<ExerciseResponseRes[]>([]);
  const [gradings, setGradings] = useState<ResourceGradingProps[]>([]);

  useEffect(() => {
    clearAllState();
    getExerciseData();
  }, [props.exerciseId, props.type]);

  useEffect(() => {
    if (data && isSuccess) {
      restoreExerciseData(data);
    }
  }, [data]);

  const clearAllState = () => {
    setExercise(null);
    setLesson(null);

    setAnswers([]);
    setFeedBacks([]);
    setGradings([]);
  };

  const getExerciseData = () => {
    viewExercise(props.exerciseId);
  };

  const restoreExerciseData = (data: ExerciseRes) => {
    const exerciseData = ExerciseUtils.restoreExercise(data, props.type);

    setExercise(exerciseData.exercise);
    setLesson(exerciseData.lesson);

    setAnswers(exerciseData.answers);
    setFeedBacks(exerciseData.feedBacks);
    setGradings(exerciseData.gradings);
  };

  const exitExercise = () => {
    props.onExit();
  };

  return (
    <>
      {lesson && exercise && (
        <ViewExerciseProvider exercise={exercise} exitExercise={exitExercise}>
          <ViewLessonContentProvider
            type={props.type}
            exercise={exercise}
            lesson={lesson}
            lessonType={lesson.type ?? LessonTypeEnum.lesson}
            answers={answers}
            feedBacks={feedBacks}
            gradings={gradings}
            exitExercise={exitExercise}
          >
            <AnonymousServiceProvider
              exerciseId={exercise.exerciseId}
              lessonId={lesson.lessonId}
            >
              <LessonTemplateLayoutProvider lesson={lesson}>
                <ViewLessonContent />
              </LessonTemplateLayoutProvider>
            </AnonymousServiceProvider>
          </ViewLessonContentProvider>
        </ViewExerciseProvider>
      )}
    </>
  );
};
