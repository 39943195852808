import TablePagination from '@components/table/TablePagination';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';

import { Button, Dropdown, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { SettingOutlined } from '@ant-design/icons';
import {
  EmailTemplateAPI,
  EmailTemplateRes,
} from '@services/setting/EmailTemplateAPI';
import { EditEmailTemplateForm } from './AddOrEditEmailTemplateForm';
import useConfirmModal from '@components/modal/ConfirmModal';
import ViewEmailTemplate from './ViewComposeMessage';
import { H1, H4 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { EmailSettingManage } from '@modules/setting/container/email-template/EmailSettingManage';
import { IconUtils } from '@utils/IconUtils';
import Card, { CardClassEnum } from '@components/card';
import { InputGroup } from '@components/input/InputGroup';
import { ResponsiveScreen } from '@hooks/useMedia';
import styled from 'styled-components';

export enum EmailTemplateActionEnum {
  default = 'default',
  view = 'view',
  create = 'create',
  update = 'update',
  delete = 'delete',
  setting = 'setting',
}

export const EmailTemplateManage = () => {
  const { t } = useTranslation();

  const { confirm } = useConfirmModal();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'code',
    direction: 'descend',
  });

  const [action, setAction] = useState<EmailTemplateActionEnum>(
    EmailTemplateActionEnum.default
  );

  const [selectEmailTemplateId, setSelectEmailTemplateId] = useState<
    number | null
  >(null);

  const [findAll, { data, isFetching }] =
    EmailTemplateAPI.endpoints.findAll.useLazyQuery();

  const [deleteEmailTemplate] = EmailTemplateAPI.endpoints.delete.useMutation(
    {}
  );

  useEffect(() => {
    refreshData(params);
  }, [params]);

  const refreshData = (params: any) => {
    setParams(params);
    findAll({ ...params });
  };

  const handleOnSuccess = () => {
    refreshData(params);
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setAction(EmailTemplateActionEnum.default);
    setSelectEmailTemplateId(null);
  };

  const columns = [
    {
      title: t('setting.email_template.code'),
      dataIndex: 'code',
      key: 'code',
      width: '20%',
      sorter: true,
    },
    {
      title: t('setting.email_template.subject'),
      dataIndex: 'subject',
      key: 'subject',
      width: '25%',
      sorter: true,
    },
    {
      title: t('setting.email_template.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const processDataRow = (record: EmailTemplateRes) => {
    return {
      code: record.code,
      subject: record.subject,
      description: record.description,
      actions: (
        <Dropdown
          menu={{
            items: [
              {
                key: 'view',
                icon: IconUtils.actions.view_info,
                onClick: () => handleViewEmailTemplate(record.emailTemplateId),
                label: t('label.view'),
              },
              {
                key: 'edit',
                icon: IconUtils.actions.edit,
                onClick: () =>
                  handleUpdateEmailTemplate(record.emailTemplateId),
                label: t('label.edit'),
              },
              {
                key: 'remove',
                icon: IconUtils.actions.delete,
                onClick: () =>
                  handleDeleteEmailTemplate(record.emailTemplateId),
                label: t('label.remove'),
              },
            ],
          }}
          placement="topRight"
          arrow
        >
          <Button shape="circle" type={'default'} icon={IconUtils.more_icon} />
        </Dropdown>
      ),
    };
  };

  const renderMobile = (record: EmailTemplateRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <EmailTemplateItemStyle className={'email-template-item'}>
          <H4>{record.code}</H4>

          <InputGroup label={t('setting.email_template.subject')}>
            {record.subject}
          </InputGroup>

          <InputGroup label={t('setting.email_template.description')}>
            {record.description}
          </InputGroup>

          <div className={'email-template-action'}>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'view',
                    icon: IconUtils.actions.view_info,
                    onClick: () =>
                      handleViewEmailTemplate(record.emailTemplateId),
                    label: t('label.view'),
                  },
                  {
                    key: 'edit',
                    icon: IconUtils.actions.edit,
                    onClick: () =>
                      handleUpdateEmailTemplate(record.emailTemplateId),
                    label: t('label.edit'),
                  },
                  {
                    key: 'remove',
                    icon: IconUtils.actions.delete,
                    onClick: () =>
                      handleDeleteEmailTemplate(record.emailTemplateId),
                    label: t('label.remove'),
                  },
                ],
              }}
              placement="topRight"
              arrow
            >
              <Button
                shape="circle"
                type={'default'}
                icon={IconUtils.more_icon}
              />
            </Dropdown>
          </div>
        </EmailTemplateItemStyle>
      </Card>
    );
  };

  const handleUpdateEmailTemplate = (emailTemplateId: number) => {
    setAction(EmailTemplateActionEnum.update);
    setSelectEmailTemplateId(emailTemplateId);
  };

  const handleViewEmailTemplate = (emailTemplateId: number) => {
    setAction(EmailTemplateActionEnum.view);
    setSelectEmailTemplateId(emailTemplateId);
  };

  const handleDeleteEmailTemplate = (emailTemplateId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('setting.email_template.warning.delete'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteEmailTemplate(emailTemplateId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('setting.email_template.warning.success'),
                description: t(
                  'setting.email_template.warning.success_message'
                ),
                placement: 'bottomRight',
              });
              handleOnCancel();
            })
            .catch(() => {
              notification.error({
                message: t('setting.email_template.warning.error'),
                description: t('setting.email_template.warning.error_message'),
                placement: 'bottomRight',
              });
              handleOnCancel();
            });
        }
      }
    );
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('setting.email_template.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction(EmailTemplateActionEnum.create)}
          />
        </H1>

        <Button
          type={'default'}
          shape="round"
          icon={<SettingOutlined />}
          onClick={() => setAction(EmailTemplateActionEnum.setting)}
        >
          {t('setting.email_setting.header')}
        </Button>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action === EmailTemplateActionEnum.create && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={<>{t('setting.email_template.actions.add')}</>}
          content={
            <EditEmailTemplateForm
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action === EmailTemplateActionEnum.view &&
        selectEmailTemplateId != null &&
        selectEmailTemplateId > 0 && (
          <CustomModal
            className={CustomModalClassEnum.medium_modal}
            header={<>{t('setting.email_template.actions.review')}</>}
            content={
              <ViewEmailTemplate
                emailTemplateId={selectEmailTemplateId}
                onCloseFunc={handleOnCancel}
              />
            }
            onCloseFunc={handleOnCancel}
          />
        )}

      {action === EmailTemplateActionEnum.update &&
        selectEmailTemplateId != null &&
        selectEmailTemplateId > 0 && (
          <CustomModal
            className={CustomModalClassEnum.large_modal}
            header={<>{t('setting.email_template.actions.update')}</>}
            content={
              <EditEmailTemplateForm
                emailTemplateId={selectEmailTemplateId}
                successHandle={handleOnSuccess}
                onCloseFunc={handleOnCancel}
              />
            }
            onCloseFunc={handleOnCancel}
          />
        )}

      {action === EmailTemplateActionEnum.setting && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('setting.email_setting.header')}
          content={
            <EmailSettingManage
              onSuccess={handleOnCancel}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

const EmailTemplateItemStyle = styled.div`
  position: relative;

  .email-template-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
