import { Select, SelectProps } from 'antd';
import React from 'react';

export const SelectWithSearch = (props: SelectProps) => {
  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      size={'large'}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      filterOption={false}
      {...props}
    >
      {props.children}
    </Select>
  );
};
