import {
  CompAnswerProps,
  CompConfiguration,
  CompInteractSettingProps,
  CompMode,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import {
  CompFeedbackProps,
  CompFeedbackResultIcon,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { TextContentProps } from '@cms/content/ContentType';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import produce from 'immer';
import { updateComponent } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import Divider from 'antd/lib/divider';
import {
  MathEquationInput,
  MathEquationLayoutEnum,
  MathEquationTabEnum,
} from '../../math-equation/MathEquationInput';
import { HTMLUtils } from '@cms/utils/HtmlUtils';
import ReactDOM from 'react-dom';

export interface MathEquationSettingProps extends CompInteractSettingProps {
  layout: MathEquationLayoutEnum;
}

export interface MathEquationProps extends CompProps {
  type: CompTypeEnum.FIB_EQUATION;
  configuration: {
    content: TextContentProps;
  };
  setting: MathEquationSettingProps;
}

export interface MathEquationAnsProps extends CompAnswerProps {
  answer: string;
}

export const MathEquationComp = (props: {
  disabled: boolean;
  item: MathEquationProps;
  answer: MathEquationAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();
  const mathEquationContent = useRef(null);

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  const [answer, setCompAnswer] = useState({
    value: '',
    triggerChange: false,
  });

  useEffect(() => {
    if (mathEquationContent.current != null) {
      initMathEquationInline(
        mathEquationContent.current,
        props.item.setting.layout,
        props.disabled,
        answer.value,
        handleOnChange
      );
    }
  }, [mathEquationContent, props.item, props.disabled, answer.value, mode]);

  useEffect(() => {
    if (props.answer != null) {
      setCompAnswer({
        value: props.answer?.answer || '',
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: '',
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  const updateDataForContent = (data: TextContentProps) => {
    const newComps = produce(props.item, (draft) => {
      draft.configuration.content = data;
    });
    dispatch(updateComponent(newComps));
  };

  const handleOnChange = (value: string) => {
    console.log('handleOnChange', value);

    if (!props.disabled) {
      updateAnswer(value, true);
    }
  };

  const updateAnswer = (ans: string, triggerChange: boolean) => {
    setCompAnswer({
      value: ans,
      triggerChange: triggerChange,
    });
  };

  const initMathEquationInline = (
    content: HTMLElement,
    layout: MathEquationLayoutEnum,
    disabled: boolean,
    value: string,
    handleOnChange: (val: string) => void
  ) => {
    const fibComps = HTMLUtils.findAncestor(
      content,
      CompConfiguration.INLINE_COMPONENT_CLASS
    );

    fibComps.forEach((tag) => {
      const fibInput = (
        <MathEquationInput
          id={props.item.id}
          disabled={disabled}
          initValue={value}
          activeTab={MathEquationTabEnum.operations}
          activeTabs={[MathEquationTabEnum.operations]}
          onChange={handleOnChange}
        />
      );
      ReactDOM.render(fibInput, tag);
    });
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      <CompFeedbackResultIcon answer={answer} />

      {mode === CompMode.COMPOSE ? (
        <>
          <ContentViewerEditable
            contentData={props.item.configuration.content}
            onChange={(data) => updateDataForContent(data as TextContentProps)}
          />

          <Divider plain>Component Preview</Divider>

          <MathEquationStyleWrap>
            <MathEquationInput
              id={props.item.id}
              disabled={props.disabled}
              initValue={answer.value}
              activeTab={MathEquationTabEnum.calculus}
              activeTabs={[MathEquationTabEnum.calculus]}
              onChange={(val) => handleOnChange(val)}
            />
          </MathEquationStyleWrap>
        </>
      ) : (
        <MathEquationStyleWrap>
          <div
            ref={mathEquationContent}
            className="comps-math-equation-input-group"
            dangerouslySetInnerHTML={{
              __html: props.item.configuration.content.data.replace(
                CompConfiguration.INLINE_COMPONENT,
                '<div class="' +
                  CompConfiguration.INLINE_COMPONENT_CLASS +
                  '"></div>'
              ),
            }}
          />

          {props.feedback && (
            <ComponentGrading
              className={ComponentGradingClassName.absolute_position}
              feedback={props.feedback}
            />
          )}
        </MathEquationStyleWrap>
      )}
    </CompFeedbackContextProvider>
  );
};

const MathEquationStyleWrap = styled.div`
  position: relative;

  .comps-math-equation-input-group {
    display: flex;
    align-items: baseline;
  }
`;
