import Input from '@components/input/input';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, notification } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { ClassRoomAPI } from '@modules/calendar/service/ClassRoomAPI';

export const EnterClassRoomAccessCodeForm = (props: {
  code: string;
  onSuccess: (sessionData: any) => void;
}) => {
  const { t } = useTranslation();

  const [accessClassRoom, { isLoading }] =
    ClassRoomAPI.endpoints.accessClassRoom.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      accessCode: '',
    },

    validationSchema: yup.object().shape({
      accessCode: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const payload = {
        code: props.code,
        accessCode: values.accessCode,
      };

      accessClassRoom(payload)
        .unwrap()
        .then((data) => {
          notification.success({
            message: t('class_room.warning.join_success'),
            description: t('class_room.warning.join_success_message'),
            placement: 'bottomRight',
          });

          props.onSuccess(data);
        })
        .catch(() => {
          notification.error({
            message: t('class_room.warning.join_error'),
            description: t('class_room.warning.join_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <div className={'enter-class-room-access-code'}>
      <h2>{t('class_room.warning.password_protected')}</h2>

      <FormikProvider value={formik}>
        <Input
          label={t('label.password')}
          name="accessCode"
          type="password"
          placeholder={t('label.password')}
          onChange={formik.handleChange}
          value={formik.values.accessCode}
        />

        <div className="submit-container">
          <ButtonGroup type={'center'}>
            <Button
              loading={isLoading}
              type={'primary'}
              shape={'round'}
              size={'large'}
              onClick={formik.submitForm}
            >
              {t('label.access')}
            </Button>
          </ButtonGroup>
        </div>
      </FormikProvider>
    </div>
  );
};
