import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { Statistic } from 'antd';
import styled from 'styled-components';
const { Countdown } = Statistic;

const default_delay = 0.5;

const AutoUpdateToolbar = (props: {
  lastChange: number;
  delay?: number;
  onTimeout?: () => void;
}) => {
  const [deadline, setDeadline] = useState(
    Date.now() + 1000 * (props.delay ?? default_delay)
  );
  const [remainTime, setRemainTime] = useState(0);

  useEffect(() => {
    setDeadline(Date.now() + 1000 * (props.delay ?? default_delay));
  }, [props.lastChange, props.delay]);

  const onChange = (val: any) => {
    if (val) {
      setRemainTime(Number(val));
    } else {
      setRemainTime(0);
    }
  };

  const handleOnTimeout = () => {
    setRemainTime(0);

    if (props.onTimeout != null) {
      props.onTimeout();
    }
  };

  return (
    <AutoUpdateToolbarStyle
      className={'auto-update-toolbar'}
      key={props.lastChange}
    >
      <Countdown
        title="Countdown"
        value={deadline}
        onFinish={handleOnTimeout}
        format={''}
        onChange={onChange}
      />

      {props.lastChange > 0 && remainTime > 0 && (
        <Progress
          percent={
            100 - (remainTime / ((props.delay ?? default_delay) * 1000)) * 100
          }
          steps={10}
          format={() => ``}
        />
      )}
    </AutoUpdateToolbarStyle>
  );
};

export default AutoUpdateToolbar;

const AutoUpdateToolbarStyle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .ant-progress {
    margin-top: 1em;
    font-size: 0px;
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: -20px;
  }

  .ant-statistic {
    font-size: 0px;
    opacity: 0;
    position: absolute;

    * {
      font-size: 0px !important;
      opacity: 0 !important;
      position: absolute !important;
    }
  }
`;
