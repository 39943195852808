import TablePagination from '@components/table/TablePagination';
import React, { useEffect, useState } from 'react';
import { SchoolAPI, SchoolRes } from '@services/private/SchoolAPI';
import { useTranslation } from 'react-i18next';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { Button, Dropdown, Space } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import { ViewSchoolPageActionEnum } from '@modules/setting/pages/ViewSchoolPage';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { InputGroup } from '@components/input/InputGroup';
import { H4 } from '@components/typography';
import { SchoolStatus } from '@components/status/SchoolStatus';
import styled from 'styled-components';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export const SchoolTable = (props: {
  action: {
    action: ViewSchoolPageActionEnum;
    params: number;
  };
  changeAction: (action: ViewSchoolPageActionEnum, params: number) => void;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [findAll, { data, isFetching }] =
    SchoolAPI.endpoints.findAll.useLazyQuery({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: '',
    direction: '',
  });

  useEffect(() => {
    if (props.action.action === ViewSchoolPageActionEnum.REFRESH) {
      findAll(params);
    }
  }, [props.action]);

  useEffect(() => {
    findAll(params);
  }, [params]);

  const refreshData = (params: PaginationReq) => {
    setParams({ ...params });
  };

  const onEditSchool = (schoolId: number) => {
    props.changeAction(ViewSchoolPageActionEnum.EDIT_SCHOOL, schoolId);
  };

  const onSettingSchool = (schoolId: number) => {
    props.changeAction(ViewSchoolPageActionEnum.SETTING_SCHOOL, schoolId);
  };

  const onEditUser = (
    type: 'school-admins' | 'teachers' | 'students' | 'parents',
    schoolId: number
  ) => {
    if (type === 'school-admins') {
      router.push(SiteMap.management.school_admins.admin_gen(schoolId));
    } else if (type === 'teachers') {
      router.push(SiteMap.management.teachers.admin_gen(schoolId));
    } else if (type === 'students') {
      router.push(SiteMap.management.students.admin_gen(schoolId));
    } else {
      router.push(SiteMap.management.parents.admin_gen(schoolId));
    }
  };

  const getSchoolAction = (record: SchoolRes) => {
    return (
      <Space size={12}>
        <Dropdown
          menu={{
            items: [
              {
                key: 'school-admins',
                label: t('school.actions.manage_school_admin'),
                icon: IconUtils.users,
                onClick: () => onEditUser('school-admins', record.schoolId),
              },
              {
                key: 'teachers',
                label: t('school.actions.manage_teacher'),
                icon: IconUtils.users,
                onClick: () => onEditUser('teachers', record.schoolId),
              },
              {
                key: 'students',
                label: t('school.actions.manage_student'),
                icon: IconUtils.users,
                onClick: () => onEditUser('students', record.schoolId),
              },
              {
                key: 'parents',
                label: t('school.actions.manage_parent'),
                icon: IconUtils.users,
                onClick: () => onEditUser('parents', record.schoolId),
              },
            ],
          }}
          placement="topRight"
          arrow
        >
          <Button shape="circle" type={'default'} icon={IconUtils.users} />
        </Dropdown>

        <Dropdown
          menu={{
            items: [
              {
                key: 'edit',
                icon: IconUtils.actions.edit,
                onClick: () => onEditSchool(record.schoolId),
                label: t('label.edit'),
              },
              {
                key: 'setting',
                icon: IconUtils.actions.setting,
                onClick: () => onSettingSchool(record.schoolId),
                label: 'Setting',
              },
            ],
          }}
          placement="topRight"
          arrow
        >
          <Button shape="circle" type={'default'} icon={IconUtils.more_icon} />
        </Dropdown>
      </Space>
    );
  };

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'clientUrl',
      key: 'clientUrl',
      width: 200,
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.address'),
      dataIndex: 'address',
      key: 'address',
      sorter: false,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      align: 'center',
    },
  ];

  const renderMobile = (record: SchoolRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <SchoolItemStyle className={'school-item'}>
          <div className={'school-item-info'}>
            <InputGroup label={record.clientUrl}>
              <H4>{record.name}</H4>
            </InputGroup>

            <InputGroup label={t('label.address')}>{record.address}</InputGroup>

            <InputGroup label={t('label.status')}>
              <SchoolStatus status={record.status} />
            </InputGroup>
          </div>

          <div className={'school-actions'}>{getSchoolAction(record)}</div>
        </SchoolItemStyle>
      </Card>
    );
  };

  const processDataRow = (record: SchoolRes) => {
    return {
      clientUrl: record.clientUrl,
      name: record.name,
      address: record.address,
      status: <SchoolStatus status={record.status} />,
      actions: getSchoolAction(record),
    };
  };

  return (
    <TablePagination
      params={params}
      isLoading={isFetching}
      columns={columns}
      data={data}
      refresh={refreshData}
      processDataRow={processDataRow}
      responsive={{
        screen: [ResponsiveScreen.xs, ResponsiveScreen.sm, ResponsiveScreen.md],
        render: renderMobile,
      }}
    />
  );
};

const SchoolItemStyle = styled.div`
  position: relative;

  .school-actions {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
