import { useEffect, useState } from 'react';
import { JsLib } from '@cms/script/LoadLibraryUtils';
import useScript from '@cms/script/useScript';

const useMathEquation = (onSuccess: () => void) => {
  const [state, setState] = useState<Record<string, boolean>>({
    [JsLib.math_equation.name]: false,
    [JsLib.guppy_osk.name]: false,
    [JsLib.guppy.name]: false,
  });

  useEffect(() => {
    let allCompleted = true;

    Object.keys(state).forEach((key) => {
      if (!state[key]) {
        allCompleted = false;
      }
    });

    if (allCompleted) {
      setTimeout(() => {
        onSuccess();
      }, 100);
    }
  }, [state]);

  useScript(JsLib.math_equation.url, JsLib.math_equation.id, () => {
    setState((prevState) => {
      return {
        ...prevState,
        ...{ [JsLib.math_equation.name]: true },
      };
    });
  });

  useScript(JsLib.guppy_osk.url, JsLib.guppy_osk.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.guppy_osk.name]: true } };
    });
  });

  useScript(JsLib.guppy.url, JsLib.guppy.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.guppy.name]: true } };
    });
  });
};

export default useMathEquation;
