import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

export enum AudioPlayerActionEnum {
  default = '',
  play = 'play',
  pause = 'pause',
  stop = 'stop',
  jumpTo = 'jump-to',
}

export const AudioPlayer = (props: {
  action?: { action: AudioPlayerActionEnum; params: any };
  title?: string;
  audioSrc: string;
  onChange?: (value: number) => void;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlay, setPlay] = useState(false);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    if (props.action) {
      if (props.action.action === AudioPlayerActionEnum.jumpTo) {
        setCurrentTimeAndPlay(props.action.params);
      } else if (props.action.action === AudioPlayerActionEnum.pause) {
        pauseAudio();
      } else if (props.action.action === AudioPlayerActionEnum.play) {
        playAudio();
      }
    }
  }, [props.action]);

  const getTimeDisplay = (time: number) => {
    const second = parseInt(time + '') % 60;
    const minute = (parseInt(time + '') - second) / 60;

    return `${minute < 10 ? '0' + minute : minute}:${
      second < 10 ? '0' + second : second
    }`;
  };

  const handleLoadedData = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
      if (isPlay) audioRef.current.play();
    }
  };

  const handlePausePlayClick = () => {
    if (audioRef.current) {
      if (isPlay) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlay(!isPlay);
    }
  };
  const getClickEventValue = (event: any, type: 'horizontal' | 'vertical') => {
    const bBox = event.target.getBoundingClientRect();
    const offset = {
      x: event.clientX - bBox.left,
      y: event.clientY - bBox.height,
    };

    let percent = 0;

    if (type === 'horizontal') {
      percent = offset.x / event.target.clientWidth;
    } else {
      percent = 1 - offset.y / event.target.clientHeight;
    }

    if (percent < 0) {
      return 0;
    } else if (percent > 1) {
      return 1;
    } else {
      return percent;
    }
  };

  const handleTimeSliderChange = (
    event: any,
    type: 'horizontal' | 'vertical'
  ) => {
    const percent = getClickEventValue(event, type);
    const newTime = percent * duration;
    setCurrentTimeAndPlay(newTime);
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
        setPlay(false);
      }
    }
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setCurrentTime(0);

      if (!isPlay) {
        setPlay(true);
        audioRef.current.play();
      }
    }
  };

  const setCurrentTimeAndPlay = (newTime: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);

      if (!isPlay) {
        setPlay(true);
        audioRef.current.play();
      }
    }
  };

  const handleOnTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleOnMoveMove = (event: any) => {
    handleTimeSliderChange(event, 'horizontal');
  };

  return (
    <AdaptAudioStyle className={'adapt-audio audio-viewer-comp'}>
      <span className={'audio-controller'}>
        <span className={'audio-button'} onClick={handlePausePlayClick}>
          {!isPlay && <PlayCircleOutlined />}
          {isPlay && <PauseCircleOutlined />}
        </span>

        <span className={'separator'} />

        <span className={'adapt-audio-progress'}>
          <span
            className={'adapt-audio-progress-background'}
            onClick={handleOnMoveMove}
            onTouchEnd={handleOnMoveMove}
          />
          <span
            className={'audio-progress'}
            style={{ right: 100 - (currentTime / duration) * 100 + '%' }}
          />
          <span
            className={'current-progress'}
            style={{ left: (currentTime / duration) * 100 + '%' }}
          />
        </span>

        <span className={'current-time'}>{getTimeDisplay(currentTime)}</span>
        <span className={'time-separator'}>/</span>
        <span className={'total-time'}>{getTimeDisplay(duration)}</span>

        <audio
          ref={audioRef}
          src={props.audioSrc}
          onLoadedData={handleLoadedData}
          onTimeUpdate={handleOnTimeUpdate}
          onEnded={() => setPlay(false)}
        />
      </span>
    </AdaptAudioStyle>
  );
};

const AdaptAudioStyle = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  .audio-controller {
    background: #3a383a;
    color: ${(props) => props.theme.color.white};
    border: 1px solid #8e8e93;
    display: inline-flex;
    align-items: center;
    padding: 0.125em 0.5em;
    border-radius: 1.6em;
    height: 1.6em;

    .separator {
      display: inline-block;
      border-left: 2px solid #fff;
      margin-left: 0.25em;
      margin-right: 0.5em;
      height: 1.6em;
    }

    .audio-button {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }

    .adapt-audio-progress {
      display: inline-block;
      min-width: 6em;
      height: 0.125em;
      background: #ccc;
      margin-left: 0.125em;
      margin-right: 0.5em;
      border-radius: 0.5em;
      position: relative;

      .adapt-audio-progress-background {
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: 0px;
        right: 0px;
        z-index: 2;
        cursor: pointer;
      }

      .audio-progress {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: ${(props) => props.theme.component.primary};
        z-index: 1;
      }

      .current-progress {
        width: 0.5em;
        height: 0.5em;
        border-radius: 100%;
        display: inline-block;
        background: ${(props) => props.theme.component.primary};
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 1;
      }
    }

    .current-time,
    .total-time,
    .time-separator {
      font-size: 0.75em;
    }
  }
`;
