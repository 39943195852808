import React, { createContext, ReactNode, useContext, useState } from 'react';

import {
  DragNDropItemCompProps,
  generatorDragNDropAns,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';

export interface SelectDragNDropItem {
  item: DragNDropItemCompProps | null;
  type: 'source' | 'target';
  target: DragNDropItemCompProps | null;
}

const InlineCompClickContext = createContext({
  selectItem: { item: null, type: 'source' } as SelectDragNDropItem,
  onSelectItem: (
    item: DragNDropItemCompProps,
    type: 'source' | 'target',
    target: DragNDropItemCompProps | null
  ) => {
    console.log(item, type, target);
  },
});

export const InlineCompClickContextProvider = (props: {
  onSelect: (answer: string) => void;
  onRemove: (answer: string) => void;
  children: ReactNode;
}) => {
  const [selectItem, setSelectItems] = useState<SelectDragNDropItem>({
    item: null,
    target: null,
    type: 'source',
  });

  const onSelectItem = (
    item: DragNDropItemCompProps,
    type: 'source' | 'target',
    target: DragNDropItemCompProps | null
  ) => {
    if (
      selectItem.item != null &&
      selectItem.item.label === item.label &&
      selectItem.type === type &&
      selectItem.target != null &&
      target != null &&
      selectItem.target.label === target.label
    ) {
      const answer = generatorDragNDropAns(
        selectItem.target.label,
        selectItem.item.label
      );
      props.onRemove(answer);

      setSelectItems({
        item: null,
        type: 'source',
        target: null,
      });
    } else if (
      selectItem.item != null &&
      selectItem.item.label === item.label
    ) {
      setSelectItems({
        item: null,
        target: null,
        type: 'source',
      });
    } else if (selectItem.item == null || selectItem.type === type) {
      setSelectItems({
        item: item,
        type: type,
        target: target,
      });
    } else {
      if (
        (selectItem.type === 'source' && type === 'target') ||
        (selectItem.type === 'target' && type === 'source')
      ) {
        if (selectItem.type === 'source' && type === 'target') {
          const answer = generatorDragNDropAns(
            item.label,
            selectItem.item.label
          );
          props.onSelect(answer);
        } else {
          const answer = generatorDragNDropAns(
            selectItem.item.label,
            item.label
          );
          props.onSelect(answer);
        }

        setSelectItems({
          item: null,
          type: 'source',
          target: null,
        });
      }
    }
  };

  return (
    <InlineCompClickContext.Provider
      value={{
        selectItem,
        onSelectItem,
      }}
    >
      {props.children}
    </InlineCompClickContext.Provider>
  );
};

export const useInlineCompClickContext = () => {
  const context = useContext(InlineCompClickContext);
  if (!context) {
    throw new Error(
      'You must wrap container by InlineCompClickContextProvider'
    );
  }
  return context;
};
