import React, { useState } from 'react';

import { Button } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import env from '@utils/config';
import axios from 'axios';
import { H2 } from '@components/typography';
import base64_encode from '@utils/EncodeUtils';
import { ViewOAuthAppStyle } from '@modules/lti/containers/oauth-app/ViewOauthApp';
import { InputGroup } from '@components/input/InputGroup';
import { NavLinkNewTab } from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { JwtRes } from '@modules/authorization/services/model';
import { IconUtils } from '@utils/IconUtils';

export default function LoginOauthTestingForm() {
  const { t } = useTranslation();

  const [loginData, setLoginData] = useState<JwtRes | null>(null);
  const [error, setError] = useState<string | null>(null);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      grantType: 'oauth-app',
      clientId: '',
      clientSecret: '',
    },

    validationSchema: yup.object().shape({
      clientId: yup.string().required(t('validation.required.field')),
      clientSecret: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      doLogin(values.clientId, values.clientSecret);
    },
  });

  const doLogin = async (clientId: string, clientSecret: string) => {
    axios({
      method: 'POST', //you can set what request you want to be
      url: env.SERVER_URL + '/v0/oauth2/token',
      headers: {
        Authorization: `Basic ${base64_encode(`${clientId}:${clientSecret}`)}`,
      },
    })
      .then((res: any) => {
        setLoginData(res.data as JwtRes);
      })
      .catch(() => {
        setError('Cannot find secret id and secret key.');
      });
  };

  const clearData = () => {
    formik.setFieldValue('clientId', '');
    formik.setFieldValue('clientSecret', '');
    setLoginData(null);
    setError(null);
  };

  return (
    <>
      {error && (
        <>
          <H2>{t('login.warning.error')}</H2>
          <InputGroup label={t('login.warning.error') + ':'}>
            <p>{error}</p>
          </InputGroup>

          <Button danger type={'primary'} shape={'round'} onClick={clearData}>
            {t('login.actions.try_again')}
          </Button>
        </>
      )}

      {loginData && (
        <>
          <H2>{t('login.warning.success')}</H2>

          <ViewOAuthAppStyle>
            <InputGroup label={t('login.label.access_token')}>
              <div className={'group-info'}>{loginData.access_token}</div>
            </InputGroup>

            <InputGroup label={t('login.label.expired_time')}>
              <div className={'group-info'}>{loginData.iat}</div>
            </InputGroup>

            <InputGroup label={t('login.label.expired_time')}>
              <div className={'group-info'}>{loginData.exp}</div>
            </InputGroup>

            <InputGroup label={t('login.label.response_data')}>
              <div className={'group-info'}>{JSON.stringify(loginData)}</div>
            </InputGroup>
          </ViewOAuthAppStyle>

          <ButtonGroup className="submit-container">
            <Button type={'primary'} shape={'round'} danger onClick={clearData}>
              {t('login.actions.retry')}
            </Button>

            {loginData && (
              <NavLinkNewTab href={SiteMap.lti.product_test}>
                <Button type={'primary'} shape={'round'}>
                  {t('oauth_app.actions.testing')}
                </Button>
              </NavLinkNewTab>
            )}
          </ButtonGroup>
        </>
      )}

      {error == null && loginData == null && (
        <FormikProvider value={formik}>
          <Input
            required={true}
            name="clientId"
            type="text"
            label={t('oauth_app.label.consumer')}
            placeholder={t('oauth_app.label.consumer')}
            onChange={formik.handleChange}
            value={formik.values.clientId}
          />

          <Input
            required={true}
            name="clientSecret"
            type="text"
            label={t('oauth_app.label.secret')}
            placeholder={t('oauth_app.label.secret')}
            onChange={formik.handleChange}
            value={formik.values.clientSecret}
          />

          <div className="submit-container">
            <ButtonGroup>
              <Button
                type={'primary'}
                shape={'round'}
                onClick={formik.submitForm}
                icon={IconUtils.login}
              >
                {t('button.login')}
              </Button>
            </ButtonGroup>
          </div>
        </FormikProvider>
      )}
    </>
  );
}
