import React, { useState } from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from 'antd/lib/button';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import TeacherUserTable from '../container/users/TeacherUserTable';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { CreateTeacherUser } from '@modules/users/component/users/CreateTeacherUserForm';
import { IconUtils } from '@utils/IconUtils';

export enum ManageUserActionEnum {
  default = '',
  invite_students = 'invite_students',
  view_info = 'view_info',
  add = 'add',
  update = 'update',
  delete = 'delete',
  link_to_student = 'link_to_student',
  reset_password = 'reset-password', // for system admin....
  update_permission = 'update-permission',
  view_product_license = 'view_product_license',
}

const ManageTeacherPage = (props: { schoolId?: number }) => {
  const { t } = useTranslation();

  const [reload, setReload] = useState({ reload: false, timestamp: -1 });

  const [action, setAction] = useState({
    action: ManageUserActionEnum.default,
    params: -1,
  });

  const reloadData = () => {
    setReload({ reload: true, timestamp: DateAndTimeUtils.getCurrentTime() });
    revertToDefault();
  };

  const revertToDefault = () => {
    setAction({ action: ManageUserActionEnum.default, params: -1 });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('teacher.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() =>
              setAction({ action: ManageUserActionEnum.add, params: -1 })
            }
          />
        </H1>
      </HeaderGroup>

      <TeacherUserTable reload={reload} schoolId={props.schoolId} />

      {action.action === ManageUserActionEnum.add && (
        <CustomModal
          header={t('teacher.actions.add')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateTeacherUser
              schoolId={props.schoolId}
              onSuccess={reloadData}
              onCancel={revertToDefault}
            />
          }
          onCloseFunc={revertToDefault}
        />
      )}
    </>
  );
};

export default ManageTeacherPage;
