import styled from 'styled-components';
import { Table } from 'antd';

export const TableStyle = styled.table`
  width: 100%;

  thead tr {
    th {
      font-size: 13px !important;
      text-transform: uppercase;
      font-weight: 900;
      background: ${(props) => props.theme.app.primary_bgr};

      &.ant-table-column-sort {
        background: ${(props) => props.theme.app.primary_bgr} !important;
        color: ${(props) => props.theme.app.primary} !important;
      }

      &.ant-table-column-has-sorters {
        // background: ${(props) => props.theme.app.primary_bgr} !important;
        // color: ${(props) => props.theme.app.primary} !important;

        &:hover,
        &:active {
          background: ${(props) => props.theme.app.primary_bgr} !important;
          color: ${(props) => props.theme.app.primary} !important;
        }
      }
    }
  }

  tr th {
    font-size: 13px !important;
  }

  tr th,
  tr td {
    .screen-xs &,
    .screen-sm & {
      padding: 8px;
    }

    .screen-md &,
    .screen-lg & {
      padding: 12px;
    }

    .screen-xxl &,
    .screen-xl & {
      padding: 16px;
    }
  }

  tbody {
    > tr > td {
      border-top: 4px solid ${(props) => props.theme.app.background};
      border-bottom: 4px solid ${(props) => props.theme.app.background};
    }
  }
`;

export const TableWrap = styled(Table)`
  //box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 10px;

  .ant-table-thead {
    .ant-table-cell {
      font-size: 13px !important;
      text-transform: uppercase;
      font-weight: 900;
      background: ${(props) => props.theme.app.primary_bgr};

      &.ant-table-column-sort {
        background: ${(props) => props.theme.app.primary_bgr} !important;
        color: ${(props) => props.theme.app.primary} !important;
      }

      &.ant-table-column-has-sorters {
        // background: ${(props) => props.theme.app.primary_bgr} !important;
        // color: ${(props) => props.theme.app.primary} !important;

        &:hover,
        &:active {
          background: ${(props) => props.theme.app.primary_bgr} !important;
          color: ${(props) => props.theme.app.primary} !important;
        }
      }
    }
  }

  .ant-table-container {
    //border: 1px solid #884ffb;
  }

  tr th {
    font-size: 13px !important;

    .ant-table-column-title {
      font-size: 13px !important;
    }
  }

  tr th,
  tr td {
    .screen-xs &,
    .screen-sm & {
      padding: 8px;
    }

    .screen-md &,
    .screen-lg & {
      padding: 12px;
    }

    .screen-xxl &,
    .screen-xl & {
      padding: 16px;
    }
  }

  tr.ant-table-expanded-row {
    > td {
      background: #f0f2f5;
    }

    .ant-table {
      .ant-table-tbody > tr > td {
        border-bottom: 4px solid #f0f0f0;
      }

      .ant-table-thead {
        .ant-table-cell {
          background: ${(props) => props.theme.app.background};
        }
      }
    }
  }

  .ant-table-tbody {
    > tr > td {
      border-top: 4px solid ${(props) => props.theme.app.background};
      border-bottom: 4px solid ${(props) => props.theme.app.background};

      .ant-modal-wrap & {
        border-top: 0px solid ${(props) => props.theme.app.background};
        border-bottom: 1px solid ${(props) => props.theme.app.background};
      }
    }

    > tr.ant-table-expanded-row > td {
      border-bottom: 24px solid ${(props) => props.theme.app.background};

      .ant-modal-wrap & {
        border-bottom: 1px solid ${(props) => props.theme.app.background};
      }
    }

    > tr.error-row > td {
      background: ${(props) => props.theme.warning.error_bgr} !important;
    }
  }
`;
