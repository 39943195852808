import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { GlossaryCompProps } from '@cms/comps/game/glossary/GlossaryComp';
import { GlossaryType } from '@cms/comps/game/GlossaryWordBank';

export const GlossarySampleData: GlossaryCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.GLOSSARY,

  configuration: {
    sourceItems: {
      id: COMPONENT_UTILS.generateUID(),
      word: {
        id: COMPONENT_UTILS.generateUID(),
        type: AssetsTypeEnum.TEXT,
        data: 'Banana',
      },
      description: {
        id: COMPONENT_UTILS.generateUID(),
        type: AssetsTypeEnum.TEXT,
        data: 'Banana Thumbnail',
      },
    },
  },
  setting: {
    autoScore: false,
    showTerm: GlossaryType.word,
  },
};
