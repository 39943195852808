import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ResponsiveSwitchContent } from '@components/responsive/Responsive';
import { ResponsiveScreen } from '@hooks/useMedia';
import { Tabs as AntdTabs, Collapse as AntdCollapse } from 'antd';
import styled from 'styled-components';
import { H5 } from '../typography';

const { Panel } = AntdCollapse;

const Tabs = styled(AntdTabs)`
  &.left-panel-align-right {
    > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
      text-align: right;
      justify-content: right;
    }
  }
`;

const Collapse = styled(AntdCollapse)`
  .ant-collapse-header {
    h5 {
      margin-bottom: 0px !important;

      svg {
        margin-right: 1ch;
      }
    }
  }
`;

export interface ResponsiveTabItemProps {
  icon?: ReactNode;
  key: string;
  label: string | ReactNode;
  visible?: boolean;
  children: ReactNode;
}

export interface ResponsiveTabProps {
  activeKey: string;
  icon?: ReactNode;
  screens: ResponsiveScreen[];
  className?: string;
  items: ResponsiveTabItemProps[];
  onChange?: (key: string) => void;
}

export enum ResponsiveTabsClass {
  default = '',
  left_panel_align_right = 'left-panel-align-right',
}

export const ResponsiveTabs = (props: ResponsiveTabProps) => {
  const [activeTab, setActiveTab] = useState<string>(props.activeKey);

  useEffect(() => {
    setActiveTab(props.activeKey);
  }, [props.activeKey]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(activeTab);
    }
  }, [activeTab]);

  const itemsDisplay = useMemo(() => {
    return props.items
      .filter((item) => {
        return item.visible == null || item.visible;
      })
      .map((item) => {
        return {
          ...item,
          label: (
            <>
              {item.icon ?? null} {item.label}
            </>
          ),
        };
      });
  }, [props.items]);

  return (
    <ResponsiveSwitchContent
      screens={props.screens}
      excludeScreenContent={
        <Tabs
          className={props.className}
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          tabPosition={'left'}
          items={itemsDisplay}
        />
      }
    >
      <Collapse
        accordion={true}
        className={props.className}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as string)}
        expandIconPosition={'end'}
      >
        {itemsDisplay.map((item) => {
          return (
            <Panel header={<H5>{item.label}</H5>} key={item.key}>
              {item.children}
            </Panel>
          );
        })}
      </Collapse>
    </ResponsiveSwitchContent>
  );
};

ResponsiveTabs.defaultProps = {
  screens: [ResponsiveScreen.xs, ResponsiveScreen.sm],
};
