import { Segmented } from 'antd';
import React, { useState } from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import styled from 'styled-components';
import { RoleDescriptionTable } from '@modules/users/component/permission/RoleDesciptionTable';
import {PermissionTable} from "@modules/users/component/permission/PermissionTableForm";

export const UserPermissionForm = (props: {
  roles: RoleEnum;
  schoolId?: number;
  userId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const [activeGroup, setActiveGroup] = useState('permission');

  return (
    <UpdatePermissionStyle className={'teacher-permission-form'}>
      <div className={'permission-group-header'}>
        <Segmented
          size={'large'}
          value={activeGroup}
          onChange={(val) => setActiveGroup(val as string)}
          options={[
            {
              value: 'permission',
              label: 'Permission',
            },
            {
              value: 'role',
              label: 'Roles',
            },
          ]}
        />
      </div>

      {activeGroup === 'permission' ? (
        <PermissionTable
          roles={props.roles}
          userId={props.userId}
          onCancel={props.onCancel}
          onSuccess={props.onSuccess}
        />
      ) : (
        <RoleDescriptionTable />
      )}
    </UpdatePermissionStyle>
  );
};

const UpdatePermissionStyle = styled.div`
  .permission-group-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }
`;
