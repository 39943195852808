import React from 'react';
import styled from 'styled-components';
import { Button, Layout, Space } from 'antd';
import HeaderNavigation from '@components/template/app-layout/header/HeaderNavigation';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { toggleMenu } from '@app/redux/slices/appLayoutSlice';
import { useDispatch, useSelector } from '@app/redux/hook';

const { Header } = Layout;

const HeaderApp = () => {
  const dispatch = useDispatch();

  const { collapsed } = useSelector((state) => state.appLayout);

  const toggleCollapsed = () => {
    dispatch(toggleMenu());
  };

  return (
    <HeaderWrapper>
      <div className={'app-header-group'}>
        <Space size={[16, 16]} wrap align={'center'}>
          <Button
            type={'default'}
            shape="default"
            onClick={toggleCollapsed}
            size={'large'}
            className={'toggle-menu-button'}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>

          {/*<SearchGroupStyle placeholder="Type search key" onSearch={onSearch} />*/}
        </Space>

        <HeaderNavigation />
      </div>
    </HeaderWrapper>
  );
};

export default HeaderApp;

const HeaderWrapper = styled(Header)`
  background: transparent;
  height: auto;
  line-height: 1.6;

  .app-header-group {
    display: flex;
    justify-content: space-between;
  }
`;
