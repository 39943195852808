import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { CheckAnswerPracticeButton } from '@cms/lesson-template/components/buttons/practices/CheckAnswerPracticeButton';
import {
  AnonymousViewMode,
  DoExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';
import { PreviousQuestionBtn } from '@cms/lesson-template/components/button-wrapper/PreviousQuestionBtn';
import { NextQuestionBtn } from '@cms/lesson-template/components/button-wrapper/NextQuestionBtn';

export const StepByStepQuestionNavigationHandle = (props: {
  sectionId: number;
  resources: ResourceProps[];
}) => {
  const { resource, section, updateNavigation } = useLessonNavigationContext();

  useEffect(() => {
    if (
      props.sectionId === section.sectionId &&
      (resource.type !== LessonNavigationType.default ||
        resource.resourceId < 0)
    ) {
      let isMatch = false;

      const _resourceId = getSelectedResource(
        props.resources,
        resource.resourceId,
        resource.type
      );

      let _preResourceId = -1;
      let _nextResourceId = -1;

      props.resources.forEach((rs) => {
        if (rs.resourceId === _resourceId) {
          isMatch = true;
        } else {
          if (isMatch && _nextResourceId < 0) {
            _nextResourceId = rs.resourceId;
          } else if (!isMatch) {
            _preResourceId = rs.resourceId;
          }
        }
      });

      if (isMatch) {
        updateNavigation(LessonNavigationAction.UPDATE_RESOURCE_NAV, {
          resourceId: _resourceId,
          previousResourceId: _preResourceId,
          nextResourceId: _nextResourceId,
          type: LessonNavigationType.default,
        });
      }
    }
  }, [props.resources, section.sectionId, resource.resourceId, resource.type]);

  return null;
};

export const StepByStepQuestionToolbar = () => {
  return (
    <StepByStepNavigationStyle
      className={'step-by-step-toolbar review-toolbar'}
    >
      <AnonymousViewMode>
        <span />
        <CheckAnswerPracticeButton />
      </AnonymousViewMode>

      <SelfCheckExerciseMode>
        <span />
        <CheckAnswerPracticeButton />
      </SelfCheckExerciseMode>

      <DoExerciseMode>
        <span />
        <CheckAnswerPracticeButton />
      </DoExerciseMode>

      <ReviewExerciseMode>
        <PreviousQuestionBtn />
        <NextQuestionBtn />
      </ReviewExerciseMode>
    </StepByStepNavigationStyle>
  );
};

const StepByStepNavigationStyle = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .button-group {
    width: 100%;
  }
`;

const getSelectedResource = (
  resources: ResourceProps[],
  defaultResourceId: number,
  type: LessonNavigationType
) => {
  if (resources.length === 0) {
    return -1;
  } else {
    if (type === LessonNavigationType.last) {
      return resources[resources.length - 1].resourceId;
    } else if (type === LessonNavigationType.first) {
      return resources[0].resourceId;
    } else {
      return defaultResourceId > 0
        ? defaultResourceId
        : resources[0].resourceId;
    }
  }
};
