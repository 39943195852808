import {
  LongOperationLayoutEnum,
  MathOperationEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import React, { useEffect, useMemo, useState } from 'react';
import {
  LongOperationColumnType,
  LongOperationRowType,
  LongOperationTableType,
  LongOperationTableWrapper,
} from './LongOperationTable';
import { PlaceValueHeader } from '@cms/comps/math/operation/table/PlaceValueHeader';

export const LongOperationTableEditable = (props: {
  layout: LongOperationLayoutEnum;
  operation: MathOperationEnum;
  expression: OperationExpProps[];
  answerExpression: OperationExpProps[];
  onChange: (index: number, expression: OperationExpProps) => void;
}) => {
  const handleOnChange = (index: number, expression: OperationExpProps) => {
    props.onChange(index, expression);
  };

  const rowType = useMemo(() => {
    return props.layout === LongOperationLayoutEnum.place_value ||
      props.layout === LongOperationLayoutEnum.place_value_block
      ? 'table'
      : 'block';
  }, [props.layout]);

  return (
    <LongOperationTableWrapper
      className={`long-operation-table-wrapper long-operation-table-editable`}
    >
      <LongOperationTableType
        rowType={rowType}
        className={`long-operation-table operation-table-${props.layout}`}
      >
        {props.expression.map((row, index) => {
          return (
            <React.Fragment
              key={JSON.stringify(row) + '_' + index + '_' + props.layout}
            >
              {props.layout === LongOperationLayoutEnum.vertical &&
                (row.type === 'sum' ||
                  row.type === 'difference' ||
                  row.type === 'product') && (
                  <div className={'long-division-row'} />
                )}

              <LongOperationRow
                rowType={rowType}
                layout={props.layout}
                operation={props.operation}
                expression={row}
                answerExpression={props.answerExpression[index]}
                onChange={(expression) => handleOnChange(index, expression)}
              />

              {props.layout === LongOperationLayoutEnum.vertical &&
                row.type === 'multiplicand' && (
                  <div className={'long-division-row'} />
                )}
            </React.Fragment>
          );
        })}
      </LongOperationTableType>
    </LongOperationTableWrapper>
  );
};

const LongOperationRow = (props: {
  rowType: 'table' | 'block';
  layout: LongOperationLayoutEnum;
  operation: MathOperationEnum;
  expression: OperationExpProps;
  answerExpression: OperationExpProps;
  onChange: (expression: OperationExpProps) => void;
}) => {
  const [inputType, setInputType] = useState<{
    value: string[];
    triggerChange: boolean;
  }>({
    value: [],
    triggerChange: false,
  });

  useEffect(() => {
    const _inputType = props.expression.exp.map((exp) => {
      return exp;
    });

    setInputType({
      value: _inputType,
      triggerChange: false,
    });
  }, [props.expression]);

  useEffect(() => {
    if (inputType.triggerChange) {
      const newExpression = {
        type: props.expression.type,
        exp: inputType.value,
      };
      props.onChange(newExpression);
    }
  }, [inputType]);

  const changeToLabel = (index: number) => {
    setInputType((prev) => {
      const inputFields = [...prev.value].map((val, idx) => {
        if (index === idx) {
          return props.answerExpression.exp[idx];
        } else {
          return val;
        }
      });

      return {
        value: inputFields,
        triggerChange: true,
      };
    });
  };

  const changeToExpression = (index: number) => {
    setInputType((prev) => {
      const inputFields = [...prev.value].map((val, idx) => {
        if (index === idx) {
          return OperationUtils.VARIABLE_CHARACTER;
        } else {
          return val;
        }
      });

      return {
        value: inputFields,
        triggerChange: true,
      };
    });
  };

  return (
    <LongOperationRowType type={props.expression.type} rowType={props.rowType}>
      {inputType.value.map((exp, colIndex) => {
        if (exp === OperationUtils.HIDDEN_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field long-operation-hidden'}
              key={exp + '-' + colIndex}
            >
              <span>&nbsp;</span>
            </LongOperationColumnType>
          );
        } else if (exp === OperationUtils.PLACE_VALUE_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field place-value-char'}
              key={exp + '-' + colIndex}
            >
              <span>
                <PlaceValueHeader
                  exp={props.expression.exp}
                  index={colIndex + 1}
                />
              </span>
            </LongOperationColumnType>
          );
        } else if (
          [
            OperationUtils.ADD_OPERATION,
            OperationUtils.SUB_OPERATION,
            OperationUtils.MUL_OPERATION,
            OperationUtils.DIV_OPERATION,
            OperationUtils.DIV_REMAIN_OPERATION,
            OperationUtils.EQUAL_OPERATION,
          ].includes(exp)
        ) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={`long-operation-field long-operation-operator  operation-${OperationUtils.getOperationTypeClassName(
                exp
              )}`}
              key={exp + '-' + colIndex}
            >
              <span>
                {exp === OperationUtils.ADD_OPERATION && <span>+</span>}
                {exp === OperationUtils.SUB_OPERATION && <span>&minus;</span>}
                {exp === OperationUtils.MUL_OPERATION && <span>&times;</span>}
                {exp === OperationUtils.DIV_OPERATION && (
                  <>
                    {props.layout === LongOperationLayoutEnum.vertical ? (
                      <span>)</span>
                    ) : (
                      <span>÷</span>
                    )}
                  </>
                )}
                {exp === OperationUtils.DIV_REMAIN_OPERATION && (
                  <span>{exp}</span>
                )}
                {exp === OperationUtils.EQUAL_OPERATION && <span>{exp}</span>}
              </span>
            </LongOperationColumnType>
          );
        } else if (exp === OperationUtils.DECIMAL_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field decimal-field'}
              key={exp + '-' + colIndex}
            >
              <span>
                <span>{exp}</span>
              </span>
            </LongOperationColumnType>
          );
        } else if (exp === OperationUtils.VARIABLE_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field long-operation-input'}
              key={exp + '-' + colIndex}
            >
              <span onClick={() => changeToLabel(colIndex)}>
                <span>
                  {props.answerExpression && (
                    <>{props.answerExpression.exp[colIndex]}</>
                  )}
                </span>
              </span>
            </LongOperationColumnType>
          );
        } else {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field long-operation-label'}
              key={exp + '-' + colIndex}
            >
              <span onClick={() => changeToExpression(colIndex)}>
                <span>{exp}</span>
              </span>
            </LongOperationColumnType>
          );
        }
      })}
    </LongOperationRowType>
  );
};
