import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { CompMode } from '@cms/ComponentInteface';
import Button from '@components/button';
import styled from 'styled-components';
import React from 'react';
import SectionItem from '@cms/section-bank/components/SectionItem';

export const SectionItemClickable = (props: {
  item: LessonSectionProps;
  onClick: (item: LessonSectionProps) => void;
}) => {
  return (
    <SectionItemClickableStyle className={'section-item-clickable'}>
      <SectionItem visible={true} item={props.item} mode={CompMode.COMPOSE} />

      <div className={'section-background-hover'}>
        <Button
          shape={'round'}
          type={'primary'}
          size={'small'}
          onClick={() => props.onClick(props.item)}
        >
          Select Section
        </Button>
      </div>
    </SectionItemClickableStyle>
  );
};

const SectionItemClickableStyle = styled.div`
  position: relative;
  margin-bottom: 2em;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 10px;

  .section-background-hover {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1em;
    background: rgba(255, 255, 255, 0.1);

    .ant-btn {
      display: none;
    }
  }

  &:hover {
    .section-background-hover {
      background: rgba(0, 0, 0, 0.2);

      .ant-btn {
        display: inline-flex;
      }
    }
  }
`;
