import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { ContainerSizeProps } from '@cms/lesson-template/layout/index';
import { useWindowSizeChange } from '@hooks/useWindowSize';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import { LessonLetterLayout } from '@cms/lesson-template/layout/LessonLetterLayout';
import { LessonTwoPageLayout } from '@cms/lesson-template/layout/LessonTwoPageLayout';
import { LessonPresentationLayout } from '@cms/lesson-template/layout/LessonPresentationLayout';
import { LessonResponsiveLayout } from '@cms/lesson-template/layout/LessonResponsiveLayout';
import styled from 'styled-components';

export const ViewLessonWrapper = (props: {
  className?: string;
  ref?: any;
  onChange?: (width: number, height: number) => void;
  children: ReactNode;
}) => {
  const { lessonType, isShowCorrectAnswer } = useViewLessonContext();
  const { config } = useLessonTemplateContext();

  const { section, resource } = useLessonNavigationContext();
  const elementRef = useRef<HTMLDivElement>(null);

  const [containerSize, setContainerSize] = useState<ContainerSizeProps | null>(
    null
  );
  const [styleConfig, setStyleConfig] = useState<ContainerSizeProps | null>(
    null
  );

  const [scale, setScale] = useState(1);

  useWindowSizeChange(() => {
    handleWindowChange();
  }, 500);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [section, resource]);

  useEffect(() => {
    const styleConfig = getLayoutSize(config.layout);
    setStyleConfig(styleConfig);
  }, [config, lessonType]);

  useEffect(() => {
    if (props.onChange && containerSize) {
      props.onChange(containerSize?.width, containerSize?.height);
    }
  }, [containerSize, props.onChange]);

  const handleWindowChange = () => {
    if (elementRef.current && styleConfig) {
      const scale_x = elementRef.current.offsetWidth / styleConfig.width;
      const scale_y = elementRef.current.offsetHeight / styleConfig.height;

      setScale(scale_x < scale_y ? scale_x : scale_y);

      setContainerSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }
  };

  return (
    <ViewLessonContentStyle
      ref={elementRef}
      className={`view-lesson-content-container ${
        isShowCorrectAnswer ? 'show-correct-answer' : ''
      } lesson-${config.layout}-layout`}
      style={
        styleConfig && styleConfig.fontSize
          ? { fontSize: scale * styleConfig.fontSize }
          : {}
      }
    >
      <SwitchCondition
        conditions={[
          {
            condition: config.layout === LessonTemplateLayoutEnum.letter,
            children: <LessonLetterLayout>{props.children}</LessonLetterLayout>,
          },
          {
            condition:
              config.layout === LessonTemplateLayoutEnum.letter_horizontal,
            children: <LessonLetterLayout>{props.children}</LessonLetterLayout>,
          },
          {
            condition: config.layout === LessonTemplateLayoutEnum.two_page,
            children: (
              <LessonTwoPageLayout>{props.children}</LessonTwoPageLayout>
            ),
          },
          {
            condition: config.layout === LessonTemplateLayoutEnum.presentation,
            children: (
              <LessonPresentationLayout>
                {props.children}
              </LessonPresentationLayout>
            ),
          },
          {
            condition: config.layout === LessonTemplateLayoutEnum.responsive,
            children: (
              <>
                <LessonResponsiveLayout>
                  {props.children}
                </LessonResponsiveLayout>
              </>
            ),
          },
        ]}
      />
    </ViewLessonContentStyle>
  );
};

export const ViewLessonContentStyle = styled.div`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  .allow-select-text {
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -o-user-select: auto;
    user-select: auto;
  }
`;

const getLayoutSize = (layout: LessonTemplateLayoutEnum) => {
  if (layout === LessonTemplateLayoutEnum.letter) {
    return {
      width: 612,
      height: 791,
      fontSize: 20,
    };
  } else if (layout === LessonTemplateLayoutEnum.letter_horizontal) {
    return {
      width: 791,
      height: 612,
      fontSize: 20,
    };
  } else if (layout === LessonTemplateLayoutEnum.two_page) {
    return {
      width: 612 * 2,
      height: 791,
      fontSize: 20,
    };
  } else if (layout === LessonTemplateLayoutEnum.presentation) {
    return {
      width: 1920,
      height: 1080,
      fontSize: 30,
    };
  } else {
    return null;
  }
};
