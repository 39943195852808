import React from "react";
import styled from "styled-components";

const TextWrapStyle = styled.div`
word-break: break-word;
`;

const TextWrap = (props: any) => {
  return (
    <TextWrapStyle {...props}>
      {props.children}
    </TextWrapStyle>
  );
};

export default TextWrap;