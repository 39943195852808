import React from 'react';
import DragNDropContext, {
  DragNDropAction,
  DragNDropActionType,
} from '@components/react-dnd-bt/DragNDropContext';
import { generatorDragNDropAns } from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import DnDDragItems from '@cms/comps/interact/drag-n-drop/DnDDragItems';
import DnDDropItems from '@cms/comps/interact/drag-n-drop/DnDDropItems';
import { useDragNDropCompContext } from '@cms/comps/interact/drag-n-drop/DragNDropCompContext';

const DragNDropDragEvent = () => {
  const { id, onDropData, disableTarget, onMoveData, onRemoveData } =
    useDragNDropCompContext();

  const handleOnChange = (action: DragNDropAction) => {
    if (
      action.action === DragNDropActionType.add &&
      action.params != null &&
      action.params.new != null
    ) {
      const answer = generatorDragNDropAns(
        action.params.new.droppableId,
        action.params.new.draggableId
      );

      onDropData(answer);
    } else if (
      action.action === DragNDropActionType.move &&
      action.params != null &&
      action.params.old != null &&
      action.params.new != null
    ) {
      const oldAnswer = generatorDragNDropAns(
        action.params.old.droppableId,
        action.params.old.draggableId
      );

      const newAnswer = generatorDragNDropAns(
        action.params.new.droppableId,
        action.params.new.draggableId
      );

      onMoveData(oldAnswer, newAnswer);
    } else if (
      action.action === DragNDropActionType.remove &&
      action.params != null &&
      action.params.old != null
    ) {
      const oldAnswer = generatorDragNDropAns(
        action.params.old.droppableId,
        action.params.old.draggableId
      );

      onRemoveData(oldAnswer);
    }
  };

  return (
    <div className={'dnd-workspace'}>
      <DragNDropContext
        id={id}
        disableDropIds={disableTarget}
        onChange={handleOnChange}
      >
        <DnDDragItems />
        <DnDDropItems />
      </DragNDropContext>
    </div>
  );
};

export default DragNDropDragEvent;

// <DragNDropContext id={props.item.id} onChange={handleOnChange}>

// </DragNDropContainer>
