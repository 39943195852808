import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  CnFlag,
  EnFlag,
  FnFlag,
  JpFlag,
  VnFlag,
} from '@components/icons/flags';

export enum LanguageEnum {
  'English' = 'en',
  'Vietnamese' = 'vi',
  'French' = 'fr',
  'Chinese' = 'zh',
  'Japanese' = 'ja',
}

export const LanguageIcon = (props: {
  language: LanguageEnum;
  showLabel?: boolean;
  width?: number;
  height?: number;
}) => {
  return (
    <LanguageIconStyle className={'language-icon'}>
      {props.language === LanguageEnum.English && (
        <EnFlag width={props.width} height={props.height} />
      )}

      {props.language === LanguageEnum.Vietnamese && (
        <VnFlag width={props.width} height={props.height} />
      )}

      {props.language === LanguageEnum.French && (
        <FnFlag width={props.width} height={props.height} />
      )}

      {props.language === LanguageEnum.Chinese && (
        <CnFlag width={props.width} height={props.height} />
      )}

      {props.language === LanguageEnum.Japanese && (
        <JpFlag width={props.width} height={props.height} />
      )}

      {props.showLabel && <LanguageLabel language={props.language} />}
    </LanguageIconStyle>
  );
};

export const LanguageLabel = (props: { language: LanguageEnum }) => {
  const { t } = useTranslation();

  return (
    <span className={'language-label'}>
      {t(('language.code.' + props.language) as any)}
    </span>
  );
};

export const getLanguageTextDisplay = (
  t: any,
  language: LanguageEnum
): string => {
  return t(('language.code.' + language) as any);
};

const LanguageIconStyle = styled.span`
  display: inline-flex;
  align-items: center;

  .language-label {
    margin-left: 0.5em;
  }
`;
