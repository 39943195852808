import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import NoSsr from '@components/Layout/NoSsr';
import { parseDropData } from '@components/react-dnd-bt/DropItem';
import {
  DragDataProps,
  parseDragData,
} from '@components/react-dnd-bt/DragItem';

export enum DragNDropActionType {
  init = '', // init
  add = 'add', // add drag item to column a (new)
  move = 'move', // move drag item from column a (old) to column b (new)
  remove = 'remove', // remove drag item of column a (old)
  order = 'order', // re-order drag item a (old) -> new order b (new)
}

export interface DragItemProps {
  className?: string;

  disabled?: boolean;
  draggableId: string;
  index: number;
  acceptType: string;
  hidePlaceholder?: boolean;

  children: any;
}

export interface DropItemProps {
  className?: string;

  droppableId: string;
  acceptType: string;
  disabledIds?: string[];
  placeHolder?: boolean;

  handleOnDrop?: (draggableId: string, index: number) => void;
  handleOnRemove?: (draggableId: string, index: number) => void;
  handleOnReorder?: (draggableId: string, index: number) => void;

  children: any;
}

export const reorderDragItem = (
  list: string[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const DragNDropSplitLabel = '--';

export interface DragNDropAction {
  action: DragNDropActionType;
  params: null | {
    old?: { draggableId: string; droppableId: string; index: number };
    new?: { draggableId: string; droppableId: string; index: number };
  };
}

export const DragNDropContextProvider = createContext({
  id: '' as string,
  dragItem: null as DragDataProps | null,
  staticDrag: false as boolean, // when dragging, create a clone and keep style static
  disableDropIds: [] as string[],
  disableDragIds: [] as string[],
  action: {
    action: DragNDropActionType.init,
    params: null,
  } as DragNDropAction,
});

const DragNDropContext = (props: {
  id: string;
  staticDrag?: boolean;
  disableDropIds?: string[];
  disableDragIds?: string[];
  onChange?: (action: DragNDropAction) => void;
  children: any;
}) => {
  const [action, setAction] = useState<DragNDropAction>({
    action: DragNDropActionType.init,
    params: null,
  });

  const [draggingItem, setDraggingData] = useState<DragDataProps | null>(null);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(action);
    }
  }, [action]);

  const onDragStart = (initial: DragStart) => {
    if (initial && initial.draggableId) {
      setDraggingData(parseDragData(initial.draggableId));
    }
  };

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    setDraggingData(null);
    const dragPart = parseDragData(draggableId);

    if (!destination) {
      const dropPart = parseDropData(source.droppableId);
      if (dropPart.droppableId === '') {
        // remove from object bank ---> do nothing...
        return false;
      } else {
        setAction({
          action: DragNDropActionType.remove,
          params: {
            old: {
              draggableId: dragPart.draggableId,
              droppableId: dropPart.droppableId,
              index: source.index,
            },
          },
        });
      }
    } else {
      const dragPart = parseDragData(draggableId);
      const dropPart = parseDropData(destination.droppableId);

      // drag from two difference container -> skip.
      if (dragPart.id !== dropPart.id) {
        return false;
      }

      // not accepted -> skip...
      if (
        dropPart.acceptType === '' ||
        dragPart.acceptType !== dropPart.acceptType
      ) {
        return false;
      }

      if (dragPart.droppableId !== dropPart.droppableId) {
        if (dragPart.droppableId === '') {
          setAction({
            action: DragNDropActionType.add,
            params: {
              new: {
                draggableId: dragPart.draggableId,
                droppableId: dropPart.droppableId,
                index: destination.index,
              },
            },
          });
        } else {
          const originPart = parseDropData(source.droppableId);
          setAction({
            action: DragNDropActionType.move,
            params: {
              old: {
                draggableId: dragPart.draggableId,
                droppableId: originPart.droppableId,
                index: source.index,
              },
              new: {
                draggableId: dragPart.draggableId,
                droppableId: dropPart.droppableId,
                index: destination.index,
              },
            },
          });
        }
        // diff group...
      } else {
        setAction({
          action: DragNDropActionType.order,
          params: {
            old: {
              draggableId: dragPart.draggableId,
              droppableId: dropPart.droppableId,
              index: source.index,
            },
            new: {
              draggableId: dragPart.draggableId,
              droppableId: dropPart.droppableId,
              index: destination.index,
            },
          },
        });
      }
    }
  };

  return (
    <NoSsr>
      <DragNDropContextProvider.Provider
        value={{
          id: props.id,
          staticDrag: props.staticDrag ?? false,
          disableDropIds: props.disableDropIds ?? [],
          disableDragIds: props.disableDragIds ?? [],
          dragItem: draggingItem,
          action,
        }}
      >
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          {props.children}
        </DragDropContext>
      </DragNDropContextProvider.Provider>
    </NoSsr>
  );
};

export const useDragNDropContext = () => {
  const context = useContext(DragNDropContextProvider);
  if (!context) {
    throw new Error('You must wrap container by DragNDropContextProvider');
  }
  return context;
};

export default DragNDropContext;
