import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';

export default function InProgressPage() {
  const { t } = useTranslation();

  return (
    <Result
      status="warning"
      title={t('incomplete.hint')}
      extra={
        <NavLink href={SiteMap.private.dashboard}>
          <Button type="primary" key="console">
            {t('menu.dashboard')}
          </Button>
        </NavLink>
      }
    />
  );
}
