import React, { useEffect } from 'react';
import { Button, notification } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  MyProfileAPI,
  UpdateZoomAccountReq,
} from '@services/private/MyProfileAPI';
import { IconUtils } from '@utils/IconUtils';
import { H2 } from '@components/typography';

export default function UpdateZoomAccountForm(props: {
  onSuccess: (newData: UpdateZoomAccountReq) => void;
}) {
  const { t } = useTranslation();
  const [getZoomAccountProfile, { data, isSuccess, isFetching }] =
    MyProfileAPI.endpoints.getZoomAccountProfile.useLazyQuery();

  const [updateZoomAccount, { isLoading }] =
    MyProfileAPI.endpoints.updateZoomAccount.useMutation({});

  useEffect(() => {
    getZoomAccountProfile({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setValues({
        zoomEmail: data.zoomEmail,
        zoomApiKey: data.zoomApiKey,
        zoomApiSecret: data.zoomApiSecret,
      });
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      zoomEmail: '',
      zoomApiKey: '',
      zoomApiSecret: '',
    },

    validationSchema: yup.object().shape({
      zoomEmail: yup.string().required(t('validation.required.field')),
      zoomApiKey: yup.string().required(t('validation.required.field')),
      zoomApiSecret: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request: UpdateZoomAccountReq = {
        zoomEmail: values.zoomEmail,
        zoomApiKey: values.zoomApiKey,
        zoomApiSecret: values.zoomApiSecret,
      };

      updateZoomAccount(request)
        .unwrap()
        .then((userData) => {
          notification.success({
            message: t('profile.warning.zoom_success'),
            description: t('profile.warning.zoom_success_message'),
            placement: 'bottomRight',
          });

          props.onSuccess(userData);
        })
        .catch(() => {
          notification.error({
            message: t('profile.warning.zoom_error'),
            description: t('profile.warning.zoom_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <>
      <H2>{t('profile.actions.update_zoom_account')}</H2>
      <FormikProvider value={formik}>
        <Input
          required
          type="text"
          name="zoomEmail"
          label={t('label.zoom.email')}
          placeholder={t('label.zoom.email')}
          onChange={formik.handleChange}
          value={formik.values.zoomEmail}
        />

        <Input
          required
          name="zoomApiKey"
          type="text"
          label={t('label.zoom.apiKey')}
          placeholder={t('label.zoom.apiKey')}
          onChange={formik.handleChange}
          value={formik.values.zoomApiKey}
        />

        <Input
          required
          name="zoomApiSecret"
          type="text"
          label={t('label.zoom.apiSecret')}
          placeholder={t('label.zoom.apiSecret')}
          onChange={formik.handleChange}
          value={formik.values.zoomApiSecret}
        />

        <ButtonGroup className="submit-container" type={'right'}>
          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            loading={isLoading || isFetching}
            onClick={formik.submitForm}
            icon={IconUtils.save}
          >
            {t('button.update')}
          </Button>
        </ButtonGroup>
      </FormikProvider>
    </>
  );
}
