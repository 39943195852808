import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import MyAssignments from '@router/pages/private/my-assignments/my_assignments';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import CreateAssignment from '@router/pages/private/my-assignments/create_assignment';
import EditAssignment from '@router/pages/private/my-assignments/edit_assignment';
import StartAssignment from '@router/pages/private/my-assignments/start_assignment';
import GradeAssignment from '@router/pages/private/my-assignments/grade_assignment';
import ReviewAssignment from '@router/pages/private/my-assignments/review_assignment';
import GradeExercise from '@router/pages/private/my_exercise/grade_exercise';
import ReviewExercise from '@router/pages/private/my_exercise/review_exercise';
import StartExercise from '@router/pages/private/my_exercise/start_exercise';
import ManageAssignment from '@router/pages/private/my-assignments/manage_assignments';

export const MyExerciseRouters: PageRouterProps[] = [
  {
    path: SiteMap.private.my_exercise.start,
    element: <PrivateRouter element={<StartExercise />} />,
  },

  {
    path: SiteMap.private.my_exercise.grade,
    element: <PrivateRouter element={<GradeExercise />} />,
  },

  {
    path: SiteMap.private.my_exercise.review,
    element: <PrivateRouter element={<ReviewExercise />} />,
  },

  {
    path: SiteMap.private.my_assignment.list,
    element: (
      <PrivateRouter
        roles={SiteMap.private.my_assignment.roles}
        element={<MyAssignments />}
      />
    ),
  },

  {
    path: SiteMap.private.my_assignment.start,
    element: (
      <PrivateRouter
        roles={SiteMap.private.my_assignment.roles}
        element={<StartAssignment />}
      />
    ),
  },

  {
    path: SiteMap.private.my_assignment.review,
    element: (
      <PrivateRouter
        roles={SiteMap.private.my_assignment.roles}
        element={<ReviewAssignment />}
      />
    ),
  },

  {
    path: SiteMap.assignments.list,
    element: (
      <PrivateRouter
        roles={SiteMap.assignments.roles}
        element={<ManageAssignment />}
      />
    ),
  },

  {
    path: SiteMap.assignments.create,
    element: (
      <PrivateRouter
        roles={SiteMap.assignments.roles}
        element={<CreateAssignment />}
      />
    ),
  },

  {
    path: SiteMap.assignments.edit,
    element: (
      <PrivateRouter
        roles={SiteMap.assignments.roles}
        element={<EditAssignment />}
      />
    ),
  },

  {
    path: SiteMap.assignments.grading,
    element: (
      <PrivateRouter
        roles={SiteMap.assignments.roles}
        element={<GradeAssignment />}
      />
    ),
  },

  {
    path: SiteMap.assignments.review,
    element: (
      <PrivateRouter
        roles={SiteMap.assignments.roles}
        element={<ReviewAssignment />}
      />
    ),
  },
];
