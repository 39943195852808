import JqueryUtils from '@cms/script/JqueryUtils';
import { CompGroupEnum } from '@cms/utils/CompProps';
import { generateMatchingItemUId } from '@cms/comps/interact/matching/MatchingComp';

export const CORRECT_ICON_URL = '/public/cms/feedback/correct.svg';
export const INCORRECT_ICON_URL = '/public/cms/feedback/incorrect.svg';

export interface MatchingUtilsProps {
  initBackground: (
    id: string,
    workspace: HTMLElement,
    onRemove: (answer: string) => void
  ) => void;
  onSizeChange: (width: number, height: number) => void;
  onFeedBackChange: (
    answer: string[],
    correct: string[],
    inCorrect: string[]
  ) => void;
  onAnswerChange: (answers: string[]) => void;
}

interface MatchingAnsProps {
  sourceId: string;
  targetId: string;

  answer: string;
  connectLine: any;
}

const parseMatchingAnswer = (answer: string[]): MatchingAnsProps[] => {
  const matchingAns: MatchingAnsProps[] = [];

  answer.forEach((answerPart) => {
    const source = answerPart.split(':')[0] as CompGroupEnum;
    const target = answerPart.split(':')[1] as CompGroupEnum;

    matchingAns.push({
      sourceId: source,
      targetId: target,

      answer: answerPart,
      connectLine: null,
    });
  });

  return matchingAns;
};

export class MatchingUtils implements MatchingUtilsProps {
  componentId = '' as string;
  component: any;
  workspace: any;
  paper: any;

  currentAns = [] as MatchingAnsProps[];
  correctParts = [] as string[];
  incorrectParts = [] as string[];

  onRemove: any;
  resizeFunc: any;

  initBackground(
    componentId: string,
    _workspace: HTMLElement,
    onRemove: (answer: string) => void
  ) {
    if (this.workspace == null) {
      this.workspace = _workspace;
      // @ts-ignore
      this.component = $(this.workspace).closest(`.comps-matching`);

      this.componentId = componentId;
      this.onRemove = onRemove;
      this.resizeFunc = null;

      const bgrWidth = this.workspace.clientWidth;
      const bgrHeight = this.workspace.clientHeight;
      // @ts-ignore
      this.paper = new Raphael(this.workspace, bgrWidth, bgrHeight);
      this.sync();
    }
  }

  onSizeChange = (width: number, height: number) => {
    if (this.paper != null) {
      const paper = this.paper;

      if (this.resizeFunc != null) {
        clearTimeout(this.resizeFunc);
      }

      this.resizeFunc = setTimeout(() => {
        paper.setSize(width, height);
        paper.setViewBox(0, 0, width, height, true);
        this.sync();
      }, 500);
    }
  };

  onFeedBackChange = (
    answer: string[],
    correct: string[],
    inCorrect: string[]
  ) => {
    this.reset();
    this.currentAns = parseMatchingAnswer(answer);
    this.correctParts = correct;
    this.incorrectParts = inCorrect;

    this.sync();
  };

  onAnswerChange = (answer: string[]) => {
    this.reset();
    this.currentAns = parseMatchingAnswer(answer);
    this.sync();
  };

  // updateAnswer() {
  //   if (!this.component) {
  //     return false;
  //   }
  //   // clear all object.
  //   this.reset();
  //
  //   // then draw again...
  //   this.answer.forEach((answerPart) => {
  //     const source = answerPart.split(':')[0] as CompGroupEnum;
  //     const target = answerPart.split(':')[1] as CompGroupEnum;
  //
  //     const sourceId = generateMatchingItemUId(
  //       this.componentId,
  //       CompGroupEnum.source,
  //       source
  //     );
  //     const targetId = generateMatchingItemUId(
  //       this.componentId,
  //       CompGroupEnum.target,
  //       target
  //     );
  //
  //     const sourceItem = this.component.find(`#` + sourceId);
  //     const targetItem = this.component.find(`#` + targetId);
  //
  //     if (
  //       sourceItem != null &&
  //       sourceItem.length > 0 &&
  //       targetItem != null &&
  //       targetItem.length > 0
  //     ) {
  //       const connectLine = this._connectLineBetweenTwoItem(
  //         answerPart,
  //         sourceItem,
  //         targetItem,
  //         this.component,
  //         () => {
  //           this.onRemove(answerPart);
  //         }
  //       );
  //
  //       this.currentAns.push({
  //         sourceId,
  //         targetId,
  //         answer: answerPart,
  //         connectLine,
  //       });
  //     } else {
  //       console.log('Missing item: updateAnswer', answerPart);
  //     }
  //   });
  // }

  sync() {
    this.currentAns.forEach((item) => {
      this._updateConnectLine(item);
    });
  }

  reset() {
    this.currentAns.forEach((res) => {
      if (res.connectLine != null) {
        res.connectLine.remove();
      }
    });

    this.currentAns = [];
  }

  _updateConnectLine(item: MatchingAnsProps) {
    if (!this.component) {
      return false;
    }

    const sourceId = generateMatchingItemUId(
      this.componentId,
      CompGroupEnum.source,
      item.sourceId
    );
    const targetId = generateMatchingItemUId(
      this.componentId,
      CompGroupEnum.target,
      item.targetId
    );

    const sourceItem = this.component.find(`#` + sourceId);
    const targetItem = this.component.find(`#` + targetId);

    if (
      sourceItem != null &&
      sourceItem.length > 0 &&
      targetItem != null &&
      targetItem.length > 0
    ) {
      if (item.connectLine != null) {
        item.connectLine.remove();
      }

      item.connectLine = this._connectLineBetweenTwoItem(
        item.answer,
        sourceItem,
        targetItem,
        this.component,
        () => {
          this.onRemove(item.answer);
        }
      );
    } else {
      console.log('Missing item: updateConnectLine', item);
    }
  }

  _connectLineBetweenTwoItem(
    answerPart: string,
    $sourceItem: any,
    $targetItem: any,
    $component: any,
    handleClickFunc: () => void
  ) {
    if ($sourceItem != null && $targetItem != null) {
      const sourceBBox = JqueryUtils.getBBox($sourceItem, $component);
      const targetBBox = JqueryUtils.getBBox($targetItem, $component);

      const color = $sourceItem[0].style.borderColor;

      let firstPoint;
      let secondPoint;
      // let spacing;
      // draw from left to right
      if (sourceBBox.x < targetBBox.x) {
        firstPoint = {
          x: sourceBBox.x + sourceBBox.width,
          y: sourceBBox.y + sourceBBox.height / 2,
        };
        secondPoint = {
          x: targetBBox.x,
          y: targetBBox.y + targetBBox.height / 2,
        };
        // spacing = secondPoint.x - firstPoint.x;
        // draw from right to left.
      } else {
        firstPoint = {
          x: targetBBox.x + targetBBox.width,
          y: targetBBox.y + targetBBox.height / 2,
        };
        secondPoint = {
          x: sourceBBox.x,
          y: sourceBBox.y + sourceBBox.height / 2,
        };
        // spacing = firstPoint.x - secondPoint.x;
      }

      if (this.paper != null) {
        const padding = 15;

        // if (padding > 20) {
        //   padding = 20;
        // } else if (padding < 15) {
        //   padding = 15;
        // }

        const pointArray = [];
        pointArray.push('M' + firstPoint.x + ',' + firstPoint.y);
        pointArray.push('L' + (firstPoint.x + padding) + ',' + firstPoint.y);
        pointArray.push('L' + (secondPoint.x - padding) + ',' + secondPoint.y);
        pointArray.push('L' + secondPoint.x + ',' + secondPoint.y);

        const connectLine = this.paper.set();
        const lineConnect = this.paper.path(pointArray).attr({
          'stroke-width': 2,
          stroke: color,
        });

        const centerPoint = {
          cx: lineConnect.getBBox().x + lineConnect.getBBox().width / 2,
          cy: lineConnect.getBBox().y + lineConnect.getBBox().height / 2,
        };

        const radius = (padding / 2) * 1.5;

        if (this.correctParts.length > 0 || this.incorrectParts.length > 0) {
          const isCorrect = this.correctParts.includes(answerPart);
          const isIncorrect = this.incorrectParts.includes(answerPart);

          if (isCorrect) {
            const circlePoint = this.paper.image(
              CORRECT_ICON_URL,
              centerPoint.cx - radius,
              centerPoint.cy - radius,
              radius * 2,
              radius * 2
            );

            connectLine.push(lineConnect);
            connectLine.push(circlePoint);
          } else if (isIncorrect) {
            const circlePoint = this.paper.image(
              INCORRECT_ICON_URL,
              centerPoint.cx - radius,
              centerPoint.cy - radius,
              radius * 2,
              radius * 2
            );
            connectLine.push(lineConnect);
            connectLine.push(circlePoint);
          }
        } else {
          const circlePoint = this.paper
            .circle(centerPoint.cx, centerPoint.cy, radius)
            .attr({
              'stroke-width': 5,
              stroke: color,
              fill: color,
              cursor: 'pointer',
            });

          const labelArray = [];
          const circlePadding = radius / 4;

          labelArray.push([
            'M',
            centerPoint.cx - radius + circlePadding,
            centerPoint.cy,
          ]);
          labelArray.push([
            'L',
            centerPoint.cx + radius - circlePadding,
            centerPoint.cy,
          ]);

          labelArray.push([
            'M',
            centerPoint.cx,
            centerPoint.cy - radius + circlePadding,
          ]);
          labelArray.push([
            'L',
            centerPoint.cx,
            centerPoint.cy + radius - circlePadding,
          ]);

          const timeLabel = this.paper
            .path(labelArray)
            .attr({
              'stroke-width': 2,
              stroke: '#FFF',
              cursor: 'pointer',
            })
            .transform('r45');

          connectLine.push(lineConnect);
          connectLine.push(circlePoint);
          connectLine.push(timeLabel);

          circlePoint.click(() => {
            handleClickFunc();
            return false;
          });

          timeLabel.click(() => {
            handleClickFunc();
            return false;
          });
        }

        return connectLine;
      }
    }
  }
}
