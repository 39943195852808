import { ReactNode } from 'react';
import {
  Carousel as AntdCarousel,
  CarouselProps as AntdCarouselProps,
} from 'antd';
import styled from 'styled-components';
import { ContainerRatio } from '@components/Layout/ContainerScale';

export interface CarouselItemProps {
  header: ReactNode;
  content: ReactNode;
}
export interface CarouselProps extends AntdCarouselProps {
  items: CarouselItemProps[];
}
export const Carousel = ({ items, ...props }: CarouselProps) => {
  return (
    <AntdCarouselWrapper className={'carousel-container'}>
      <AntdCarousel {...props}>
        {items.map((item, index) => {
          return (
            <div key={index}>
              <ContainerRatio ratio={'16 / 9'}>
                <div className={'carousel-item'}>
                  <div className={'carousel-header'}>{item.header}</div>
                  <div className={'carousel-content'}>{item.content}</div>
                </div>
              </ContainerRatio>
            </div>
          );
        })}
      </AntdCarousel>
    </AntdCarouselWrapper>
  );
};

const AntdCarouselWrapper = styled.div`
  .carousel-item {
    width: 100%;
    height: 100%;
    background: #ccc;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .slick-list {
    z-index: 1;
  }

  .slick-dots {
    z-index: 2;

    li {
      background: #ccc;
      min-width: 50px;

      button {
        min-height: 8px;
      }

      &.slick-active {
        background: ${(props) => props.theme.app.primary};
      }
    }
  }
`;
