import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ViewLessonError } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { ListSelectResourceBanks } from '@cms/resource-bank/ListResourceBank';

const AssignmentTemplateStyle = styled.div``;

export const ClassRoomTemplate = (props: {
  lessonId: number;
  selectedResources: number[];
  onSelectResource: (resources: ResourceProps) => void;
}) => {
  const [resources, setResources] = useState<ResourceProps[]>([]);

  const [getLesson, { data, isSuccess, isError, error }] =
    LessonAPI.endpoints.getLesson.useLazyQuery({});

  useEffect(() => {
    if (props.lessonId != null && props.lessonId > 0) {
      getLesson(props.lessonId);
    }
  }, [props.lessonId]);

  useEffect(() => {
    if (data && isSuccess) {
      const resources = ResourceUtils.getResources(data.sections);
      setResources(resources);
    }
  }, [data]);

  return (
    <AssignmentTemplateStyle className={'assignment-template'}>
      {data && isSuccess && (
        <>
          <div className={'exercise-template-layout'}>
            <ListSelectResourceBanks
              resources={resources}
              selectedResources={props.selectedResources}
              handleOnClick={props.onSelectResource}
            />
          </div>
        </>
      )}

      {isError && error && <ViewLessonError />}
    </AssignmentTemplateStyle>
  );
};
