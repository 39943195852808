import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useSecurity } from '@utils/authorization';
import { useSelector } from '@app/redux/hook';
import { getMenuItem } from '@components/template/common/MenuUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import i18next from 'i18next';
import { useProUser } from '@components/button/RequireProUserButton';
import { HeaderMenu } from '@components/template/app-layout/header/header-menu/HeaderMenuDisplay';

const UserManagementMenu = () => {
  const { t } = useTranslation();

  const proUser = useProUser();

  const { ifAnyGranted } = useSecurity();

  const { authorities } = useSelector((state) => state.profile);

  const menuItems = useMemo(() => {
    return [
      getMenuItem({
        key: NavigationMenuEnum.USERS_CLASSES,
        label: (
          <NavLink href={SiteMap.management.classes.path}>
            {t('menu.my_classes')}
          </NavLink>
        ),
        icon: IconUtils.menu.class,
        visible: ifAnyGranted(SiteMap.management.classes.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.USERS_STUDENTS,
        label: (
          <NavLink href={SiteMap.management.students.path}>
            {t('menu.students_users')}
          </NavLink>
        ),
        icon: IconUtils.menu.user,
        visible: ifAnyGranted(SiteMap.management.students.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.USERS_PARENTS,
        label: (
          <NavLink href={SiteMap.management.parents.path}>
            {t('menu.parents_users')}
          </NavLink>
        ),
        icon: IconUtils.menu.user,
        visible: ifAnyGranted(SiteMap.management.parents.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.USERS_TEACHERS,
        label: (
          <NavLink href={SiteMap.management.teachers.path}>
            {t('menu.teachers_users')}
          </NavLink>
        ),
        icon: IconUtils.menu.user,
        visible: ifAnyGranted(SiteMap.management.teachers.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.USERS_SCHOOL_SUPPORT,
        label: (
          <NavLink href={SiteMap.management.supports.path}>
            {t('menu.school_support_users')}
          </NavLink>
        ),
        icon: IconUtils.menu.user,
        visible: ifAnyGranted(SiteMap.management.supports.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.USERS_SCHOOL_ADMIN,
        label: (
          <NavLink href={SiteMap.management.school_admins.path}>
            {t('menu.school_admin_users')}
          </NavLink>
        ),
        icon: IconUtils.menu.user,
        visible: ifAnyGranted(SiteMap.management.school_admins.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.COURSES_COURSES,
        label: (
          <NavLink href={SiteMap.management.courses.path}>
            {t('menu.my_courses')}
          </NavLink>
        ),
        icon: IconUtils.menu.course,
        visible: ifAnyGranted(SiteMap.management.courses.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.COURSES_REQUESTS,
        label: (
          <NavLink href={SiteMap.management.courses.request}>
            {t('menu.course_requests')}
          </NavLink>
        ),
        icon: IconUtils.menu.course,
        visible: ifAnyGranted(SiteMap.management.courses.roles),
      }),

      getMenuItem({
        key: NavigationMenuEnum.COURSES_SURVEY,
        label: <NavLink href={SiteMap.survey.list}>{t('menu.survey')}</NavLink>,
        icon: IconUtils.menu.survey,
        visible: ifAnyGranted(SiteMap.survey.roles),
      }),
    ];
  }, [authorities, i18next.language, proUser]);

  return (
    <HeaderMenu
      group={NavigationMenuEnum.USERS}
      groupIcon={IconUtils.menu.user}
      className={'user-group-menu'}
      menuItems={menuItems}
    />
  );
};

export default UserManagementMenu;
