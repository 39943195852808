import styled from 'styled-components';

export const UserInforMobileStyle = styled.div`
  display: flex;
  gap: 1em;
  position: relative;

  .user-info-thumbnails {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 120px;

    .form-group {
      margin-top: 1em;
    }
  }

  .user-info-action {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;
