import React, { useEffect, useState } from 'react';
import { Button, List, Select } from 'antd';
import { StudentAPI } from '@modules/users/services/StudentAPI';
import { UserRes } from '@modules/users/services/model';
import { StudentInfoSimple } from '@components/info/StudentInfo';

import {
  addStudentById,
  initStudents,
  removeStudentById,
} from '@app/redux/slices/addStudentSlices';
import { InvitedStudentForm } from '@components/form/InviteStudentForm';
import { useTranslation } from 'react-i18next';
import { H4 } from '@components/typography';
import { useDispatch, useSelector } from '@app/redux/hook';

export default function InviteStudentToCourse(props: {
  courseId: number;
  selectedStudents: UserRes[];
  successHandle: () => void;
  onAddStudent: (
    studentId: number,
    callBack: (isSuccess: boolean) => void
  ) => void;
  onRemoveStudent: (
    studentId: number,
    callBack: (isSuccess: boolean) => void
  ) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { students, selectedStudents } = useSelector(
    (state) => state.addStudent
  );
  const [getStudents, { data, isSuccess }] =
    StudentAPI.endpoints.getStudents.useLazyQuery();

  const [selectStudent, setSelectStudent] = useState(undefined);

  useEffect(() => {
    getStudents({ selectAll: true });
  }, []);

  useEffect(() => {
    if (selectStudent != null && selectStudent > 0) {
      addStudent(selectStudent);
    }
  }, [selectStudent]);

  useEffect(() => {
    if (data && isSuccess) {
      const payload = {
        students: data.content,
        selectedStudent: props.selectedStudents,
      };
      dispatch(initStudents(payload));
    }
  }, [data]);

  const addStudent = (userId: number) => {
    props.onAddStudent(userId, (isSuccess) => {
      if (isSuccess) {
        setSelectStudent(undefined);
        dispatch(addStudentById(userId));
      }
    });
  };

  const onRemoveStudent = (userId: number) => {
    props.onRemoveStudent(userId, (isSuccess) => {
      if (isSuccess) {
        dispatch(removeStudentById(userId));
      }
    });
  };

  return (
    <InvitedStudentForm className={'invite-to-course'}>
      <div className={'invite-student-group'}>
        <H4>{t('invitation.select_students')}:</H4>

        <Select
          size={'large'}
          placeholder={t('invitation.select_students')}
          value={selectStudent}
          onChange={(value) => setSelectStudent(value)}
          style={{ width: '100%' }}
        >
          {students.map((item: UserRes) => (
            <Select.Option key={item.userId} value={item.userId}>
              <StudentInfoSimple className={'no-padding'} item={item} />
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={'invite-student-group'}>
        <H4>{t('class.accepted_students')}:</H4>

        <List
          size={'small'}
          bordered
          dataSource={selectedStudents}
          renderItem={(item) => (
            <List.Item>
              <div className={'selected-student'} key={item.userId}>
                <StudentInfoSimple className={'small-padding'} item={item} />

                <Button
                  size={'small'}
                  type={'primary'}
                  shape="round"
                  danger
                  onClick={() => onRemoveStudent(item.userId)}
                >
                  {t('button.remove')}
                </Button>
              </div>
            </List.Item>
          )}
        />
      </div>
    </InvitedStudentForm>
  );
}
