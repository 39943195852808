import { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';

export interface PieRecordData {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface PieChartProps<T> {
  className: string;
  size: number;
  content: T[];
  render: (data: T, index: number) => PieRecordData;
  getColor: (params: any) => string;
}

const PieChartReport = (props: PieChartProps<any>) => {
  const [data, setData] = useState<PieRecordData[]>([]);

  useEffect(() => {
    setData(
      props.content.map((it: any, index: number) => {
        return props.render(it, index);
      })
    );
  }, [JSON.stringify(props.content)]);

  return (
    <div
      className={props.className}
      style={{ width: '100%', height: props.size }}
    >
      <ResponsivePie
        data={data}
        // margin={{
        //   top: props.size / 5,
        //   right: props.size / 5,
        //   bottom: props.size / 5,
        //   left: props.size / 5,
        // }}
        innerRadius={0}
        padAngle={1}
        cornerRadius={0}
        activeOuterRadiusOffset={1.2}
        borderWidth={1}
        colors={props.getColor}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        // enableArcLabels={false}
        enableArcLinkLabels={false}

        // legends={[
        //   {
        //     anchor: 'bottom',
        //     direction: 'row',
        //     justify: false,
        //     translateX: 0,
        //     translateY: 56,
        //     itemsSpacing: 0,
        //     itemWidth: 100,
        //     itemHeight: 18,
        //     itemTextColor: '#999',
        //     itemDirection: 'left-to-right',
        //     itemOpacity: 1,
        //     symbolSize: 18,
        //     symbolShape: 'circle',
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemTextColor: '#000',
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </div>
  );
};

export default PieChartReport;
