export interface ColorDataProps {
  color: string;
  background: string;
  key: string;
}

const GlobalColor = [
  {
    key: 'red',
    color: '#ED1F24',
    background: '#f9d0d0',
  },
  {
    key: 'orange',
    color: '#f58220',
    background: '#fce0c5',
  },
  {
    key: 'yellow',
    color: '#ffcb05',
    background: '#f3dc79',
  },
  {
    key: 'green',
    color: '#00A14B',
    background: '#e1edd4',
  },
  {
    key: 'blue',
    color: '#0474BB',
    background: '#cde3f6',
  },
  {
    key: 'teal',
    color: '#009ead',
    background: '#cce8ec',
  },
  {
    key: 'purple',
    color: '#7956A4',
    background: '#e4d5ea',
  },
  {
    key: 'pink',
    color: '#FF00CC',
    background: '#F072AB',
  },
  {
    key: 'black',
    color: '#000000',
    background: '#999999',
  },
];

export const StandardColors: ColorDataProps[] = [
  {
    key: 'red',
    color: '#ED1F24',
    background: '#ED1F24',
  },
  {
    key: 'orange',
    color: '#f58220',
    background: '#f58220',
  },
  {
    key: 'yellow',
    color: '#ffcb05',
    background: '#ffcb05',
  },
  {
    key: 'green',
    color: '#00A14B',
    background: '#00A14B',
  },
  {
    key: 'blue',
    color: '#0474BB',
    background: '#0474BB',
  },
  {
    key: 'teal',
    color: '#009ead',
    background: '#009ead',
  },
  {
    key: 'purple',
    color: '#7956A4',
    background: '#7956A4',
  },
  {
    key: 'pink',
    color: '#FF00CC',
    background: '#FF00CC',
  },
  {
    key: 'black',
    color: '#000000',
    background: '#000000',
  },

  {
    key: '638475',
    color: '#638475',
    background: '#638475',
  },
  {
    key: '90E39A',
    color: '#90E39A',
    background: '#90E39A',
  },
  {
    key: 'DDF093',
    color: '#DDF093',
    background: '#DDF093',
  },
  {
    key: 'CE4760',
    color: '#CE4760',
    background: '#CE4760',
  },

  {
    key: '595A4A',
    color: '#595A4A',
    background: '#595A4A',
  },
  {
    key: 'B0FE76',
    color: '#B0FE76',
    background: '#B0FE76',
  },
  {
    key: '81E979',
    color: '#81E979',
    background: '#81E979',
  },
  {
    key: '8FBB99',
    color: '#8FBB99',
    background: '#8FBB99',
  },

  {
    key: '363635',
    color: '#363635',
    background: '#363635',
  },
  {
    key: '101D42',
    color: '#101D42',
    background: '#101D42',
  },
  {
    key: '232ED1',
    color: '#232ED1',
    background: '#232ED1',
  },
  {
    key: '6564DB',
    color: '#6564DB',
    background: '#6564DB',
  },
  {
    key: '89D2DC',
    color: '#89D2DC',
    background: '#89D2DC',
  },
];

export const ScoreColors = {
  low: '#ED1F24',
  medium: '#f58220',
  high: '#0474BB',
  good: '#00A14B',
};

const ColorUtils = {
  getColorArray: () => {
    return GlobalColor;
  },

  getDefaultColor: (index: number) => {
    let colorData = StandardColors[index % (StandardColors.length - 1)];

    if (colorData == null) {
      colorData = StandardColors[0];
    }

    return colorData;
  },

  getColorByScore: (score: number) => {
    if (score < 50) {
      return ScoreColors.low;
    } else if (score < 70) {
      return ScoreColors.medium;
    } else if (score < 80) {
      return ScoreColors.high;
    } else {
      return ScoreColors.good;
    }
  },

  convertRGBToHex: (r: number, g: number, b: number) => {
    const componentToHex = (c: number) => {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  },

  convertHexToRGBA: (hexCode: string, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  },
};

export default ColorUtils;
