import { useAudioCompContext } from '@cms/comps/content/audio/AudioCompContext';
import AudioInlineComp from '@cms/comps/content/audio/AudioInlineComp';
import React from 'react';
import AudioSubtitleComp from './AudioSubtitleComp';

export const AudioViewerComp = (props: {
  onChange: (time: number) => void;
}) => {
  const { modifiedTime, setting } = useAudioCompContext();

  if (setting && setting.layout === 'subtitle') {
    return <AudioInlineComp key={modifiedTime} />;
  } else {
    return <AudioSubtitleComp key={modifiedTime} onChange={props.onChange} />;
  }
};
