import { CompConfiguration, CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { FibWordProps } from '@cms/comps/interact/inline/FibWordComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';

export const FibWordExampleData: FibWordProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.FIB_WORD,

  configuration: {
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data:
            'This is ' +
            CompConfiguration.INLINE_COMPONENT +
            ', and ' +
            CompConfiguration.INLINE_COMPONENT +
            ', and ' +
            CompConfiguration.INLINE_COMPONENT +
            '.',
        },
      },
    ],
  },

  setting: {
    autoScore: true,
    maxLength: 10,
    autoCapital: false,
  },
};
