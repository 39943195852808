import { LessonTypeEnum } from '@modules/admin/service/model';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import {
  resetData,
  ResourceAnswerProps,
  ResourceGradingProps,
} from '@app/redux/slices/viewResource';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import {
  CompAnswerProps,
  CompGradingProps,
  CompMode,
} from '@cms/ComponentInteface';
import { useDispatch } from 'react-redux';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import produce from 'immer';
import { LessonNavigationContextProvider } from '@cms/lesson-template/context/LessonNavigationContext';
import { GradingExerciseContextProvider } from './GradingExerciseContext';
import {
  ExerciseRes,
  ExerciseResponseRes,
} from '@modules/assignments/service/exercise_model';
import {
  LessonRes,
  LessonSectionRes,
} from '@modules/product/services/lesson_model';

export interface ResourceNavigationProps {
  currentId: number;
  currentIndex: number;
  first: boolean;
  last: boolean;
  current: ResourceProps | null;
  currentData: { sectionId: number; resourceId: number };
  previous: { sectionId: number; resourceId: number };
  next: { sectionId: number; resourceId: number };
}

export enum ExerciseActionEnum {
  default = '',
  enable_edit_mode = 'enable_edit_mode',

  clear_correct_answer = 'clear-correct-answer',
  toggle_correct_answer = 'toggle_correct_answer',
  show_correct_answer = 'show_correct_answer',
  show_self_check_result = 'show_self_check_result', // check answer without saving...

  review = 'review',
  review_n_confirm_submit = 'review_n_confirm_submit', // confirm when submit assignment.

  request_review = 'request_review',
  request_save = 'request_save',
  request_submit = 'request_submit',
  request_grading = 'request_grading',
  request_exit_grading = 'request_exit_grading',

  submit = 'submit',
  complete_assignment = 'complete-assignment', // equal submit

  clear_answer = 'clear_answer',
  check_answer = 'check_answer',

  reset_all = 'reset-all',

  go_to_question = 'go-to-question',
  jump_to_question = 'jump-to-question',

  // for single mode
  show_question_feedback = 'show-question-feedback',
  hide_question_feedback = 'hide-question-feedback',
  clear_resource_answer = 'clear-resource-answer',

  // complete exercise and go back
  exit = 'exit',
}

export interface ExerciseActionProps {
  action: ExerciseActionEnum;
  params: any;
  timestamp: number;
}

const ViewLessonContext = createContext({
  type: CompMode.REVIEW as CompMode,

  lessonId: -1,
  lesson: {} as LessonRes,

  lessonType: LessonTypeEnum.empty as LessonTypeEnum,
  sections: [] as LessonSectionProps[],

  isEditableMode: false as boolean,
  isCheckAnswerMode: false as boolean,
  isShowCorrectAnswer: false as boolean,

  answers: {} as Record<number, CompAnswerProps[]>,
  feedBacks: {} as Record<number, ExerciseResponseRes>,
  gradings: {} as Record<number, CompGradingProps[]>,
  errors: {} as Record<number, boolean>,

  action: {} as ExerciseActionProps,

  dispatchExerciseAction: (action: ExerciseActionEnum, params: any) => {
    console.log(action, params);
  },

  exitExercise: () => {
    console.log('exit');
  },

  updateAnswer: (answer: ResourceAnswerProps) => {
    console.log(answer);
  },

  updateGradingResult: (grading: ResourceGradingProps) => {
    console.log(grading);
  },

  getResourceAnswers: (resourceIds: number[]): ResourceAnswerProps[] => {
    console.log(resourceIds);
    return [];
  },

  triggerBeforeNavigate: () => {
    console.log('trigger event before navigate each question or section....');
  },

  clearResourceAnswers: (resourceIds: number[]) => {
    console.log(resourceIds);
  },
});

export interface TriggerLessonAction {
  type: '' | 'restore';
  params: ViewLessonDataChange | null;
}

interface ViewLessonContextProps {
  type: CompMode;
  exercise?: ExerciseRes | null;

  // group data
  lesson: LessonRes;
  lessonType?: LessonTypeEnum;

  // lesson data
  answers: ResourceAnswerProps[];
  feedBacks: ExerciseResponseRes[];
  gradings: ResourceGradingProps[];

  // group actions
  exitExercise: () => void;
  onChange?: (data: ViewLessonDataChange) => void;
  action?: TriggerLessonAction;
  children: any;
}

const _restoreSections = (sections: LessonSectionRes[]) => {
  return ResourceUtils.getSections(sections);
};

export interface ViewLessonDataChange {
  checkAnswerMode: boolean;
  showCorrectAnswerMode: boolean;
  answers: Record<number, CompAnswerProps[]>;
  feedbacks: Record<number, ExerciseResponseRes>;
  gradings: Record<number, CompGradingProps[]>;
}

const _restoreAnswers = (
  answers: ResourceAnswerProps[]
): Record<number, CompAnswerProps[]> => {
  const answersMapping: Record<number, CompAnswerProps[]> = {};
  answers.forEach((ans) => {
    answersMapping[ans.resourceId] = ans.answers;
  });
  return answersMapping;
};

const _showFeedbackResult = (
  feedbacks: ExerciseResponseRes[]
): Record<number, ExerciseResponseRes> => {
  const feedbackMapping: Record<number, ExerciseResponseRes> = {};
  feedbacks.forEach((fb) => {
    feedbackMapping[fb.resourceId] = fb;
  });
  return feedbackMapping;
};

const _showGradingResult = (
  grading: ResourceGradingProps[]
): Record<number, CompGradingProps[]> => {
  const gradingMapping: Record<number, CompGradingProps[]> = {};
  grading.forEach((gd) => {
    gradingMapping[gd.resourceId] = gd.grading;
  });
  return gradingMapping;
};

export const ViewLessonContentProvider = (props: ViewLessonContextProps) => {
  const dispatch = useDispatch();

  const [checkAnswerMode, setCheckAnswerMode] = useState(false);
  const [showCorrectAnswerMode, setShowCorrectAnswerMode] = useState(false);
  const [editableMode, setEditableMode] = useState(
    props.type === CompMode.SELF_CHECK
  );

  const [resources, setResources] = useState(() => {
    return ResourceUtils.getResources(props.lesson.sections);
  });
  const [sections, setSections] = useState<LessonSectionProps[]>(() => {
    return _restoreSections(props.lesson.sections);
  });
  const [resourceErrors, setResourceErrors] = useState<Record<number, boolean>>(
    () => {
      return ResourceUtils.validateResources(resources);
    }
  );

  const [answers, setAnswers] = useState<Record<number, CompAnswerProps[]>>([]);
  const [feedBacks, setFeedback] = useState<
    Record<number, ExerciseResponseRes>
  >({});
  const [gradings, setGradings] = useState<Record<number, CompGradingProps[]>>(
    {}
  );

  useEffect(() => {
    const resources = ResourceUtils.getResources(props.lesson.sections);
    setResources(resources);
    setResourceErrors(ResourceUtils.validateResources(resources));
    setSections(_restoreSections(props.lesson.sections));
  }, [props.lesson.sections]);

  useEffect(() => {
    setAnswers(_restoreAnswers(props.answers));
  }, [props.answers]);

  useEffect(() => {
    setFeedback(_showFeedbackResult(props.feedBacks));
  }, [props.feedBacks]);

  useEffect(() => {
    setGradings(_showGradingResult(props.gradings));
  }, [props.gradings]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange({
        checkAnswerMode,
        showCorrectAnswerMode,
        answers: answers,
        feedbacks: feedBacks,
        gradings: gradings,
      });
    }
  }, [checkAnswerMode, showCorrectAnswerMode, answers, feedBacks, gradings]);

  useEffect(() => {
    if (
      props.action != null &&
      props.action.type === 'restore' &&
      props.action.params != null
    ) {
      setCheckAnswerMode(props.action.params.checkAnswerMode);
      setShowCorrectAnswerMode(props.action.params.showCorrectAnswerMode);
      setAnswers(props.action.params.answers);
      setFeedback(props.action.params.feedbacks);
      setGradings(props.action.params.gradings);
    }
  }, [props.action]);

  const [action, setAction] = useState<ExerciseActionProps>({
    action: ExerciseActionEnum.default,
    params: null,
    timestamp: DateAndTimeUtils.getCurrentTime(),
  });

  const dispatchExerciseAction = (action: ExerciseActionEnum, params: any) => {
    if (action === ExerciseActionEnum.show_self_check_result) {
      showSelfCheckResult(
        params as {
          feedBacks: ExerciseResponseRes[];
          answers: ResourceAnswerProps[];
        }
      );
    } else if (action === ExerciseActionEnum.show_correct_answer) {
      showCorrectAnswer();
    } else if (action === ExerciseActionEnum.clear_correct_answer) {
      resetAllState();
    } else if (action === ExerciseActionEnum.toggle_correct_answer) {
      if (showCorrectAnswerMode) {
        resetAllState();
      } else {
        showCorrectAnswer();
      }
    } else if (action === ExerciseActionEnum.enable_edit_mode) {
      enableEditMode();
    } else if (action === ExerciseActionEnum.show_question_feedback) {
      showQuestionFeedback(params as ShowQuestionFeedbackProps[]);
    } else if (action === ExerciseActionEnum.hide_question_feedback) {
      resetAllState();
    } else if (action === ExerciseActionEnum.clear_resource_answer) {
      clearResourceAnswers(params);
    } else if (action === ExerciseActionEnum.reset_all) {
      clearAllState();
    } else if (action === ExerciseActionEnum.exit && props.exitExercise) {
      props.exitExercise();
    } else {
      setAction({
        action: action,
        params: params,
        timestamp: DateAndTimeUtils.getCurrentTime(),
      });
    }
  };

  const showQuestionFeedback = (params: ShowQuestionFeedbackProps[]) => {
    setFeedback((prevState) => {
      return produce(prevState, (draft) => {
        params.forEach((par) => {
          draft[par.resourceId] = par.feedBack;
        });
      });
    });

    setAnswers((prevState) => {
      return produce(prevState, (draft) => {
        params.forEach((par) => {
          draft[par.resourceId] = par.answers;
        });
      });
    });
  };

  const showCorrectAnswer = () => {
    const resAnswer: ResourceAnswerProps[] = resources.map((rs) => {
      return {
        resourceId: rs.resourceId,
        answers: rs.correctAnswer,
      };
    });
    setAnswers(_restoreAnswers(resAnswer));
    setFeedback({});
    setGradings({});

    setShowCorrectAnswerMode(true);
    setCheckAnswerMode(false);
  };

  const resetAllState = () => {
    setCheckAnswerMode(false);
    setShowCorrectAnswerMode(false);

    setAnswers({});
    setGradings({});
    setFeedback({});

    dispatch(resetData());
  };

  const clearAllState = () => {
    setCheckAnswerMode(false);
    setShowCorrectAnswerMode(false);
    setEditableMode(false);

    setAnswers({});
    setFeedback({});
    setGradings({});
  };

  const enableEditMode = () => {
    setEditableMode(true);
  };

  const showSelfCheckResult = (params: {
    feedBacks: ExerciseResponseRes[];
    answers: ResourceAnswerProps[];
  }) => {
    const feedBacks = _showFeedbackResult(params.feedBacks);
    const answers = _restoreAnswers(params.answers);
    setFeedback(feedBacks);
    setAnswers(answers);
    setCheckAnswerMode(true);
  };

  const updateAnswer = (answer: ResourceAnswerProps) => {
    setAnswers((prevState) => {
      return produce(prevState, (draft) => {
        draft[answer.resourceId] = answer.answers;
      });
    });
  };

  const updateGradingResult = (grading: ResourceGradingProps) => {
    setGradings((prevState) => {
      return produce(prevState, (draft) => {
        draft[grading.resourceId] = grading.grading;
      });
    });
  };

  const getResourceAnswers = (resourceIds: number[]): ResourceAnswerProps[] => {
    const result: ResourceAnswerProps[] = [];
    resourceIds.forEach((rsId) => {
      if (answers[rsId]) {
        result.push({
          resourceId: rsId,
          answers: answers[rsId],
        });
      }
    });
    return result;
  };

  const clearResourceAnswers = (resourceIds: number[]) => {
    setAnswers((prevState) => {
      return produce(prevState, (draft) => {
        resourceIds.forEach((rsId) => {
          delete draft[rsId];
        });
      });
    });
  };

  const isDoAssignmentMode =
    props.type === CompMode.DO_ASSIGNMENT ||
    props.type === CompMode.DO_EXERCISE;

  const triggerBeforeNavigate = () => {
    if (
      props.type === CompMode.DO_EXERCISE ||
      props.type === CompMode.DO_ASSIGNMENT
    ) {
      dispatchExerciseAction(ExerciseActionEnum.request_save, false);
    } else if (
      props.type === CompMode.GRADING_EXERCISE ||
      props.type === CompMode.GRADING_ASSIGNMENT
    ) {
      dispatchExerciseAction(ExerciseActionEnum.request_grading, false);
    }
  };

  return (
    <ViewLessonContext.Provider
      value={{
        type: props.type,

        lessonId: props.lesson ? props.lesson.lessonId : -1,
        lesson: props.lesson,

        lessonType: props.lessonType ?? LessonTypeEnum.empty,
        sections: sections,

        isCheckAnswerMode: checkAnswerMode,
        isShowCorrectAnswer: showCorrectAnswerMode,
        isEditableMode: editableMode,
        errors: resourceErrors,

        answers: answers,
        feedBacks: feedBacks,
        gradings: gradings,

        updateAnswer,
        updateGradingResult,
        getResourceAnswers,
        clearResourceAnswers,
        triggerBeforeNavigate,

        action,
        dispatchExerciseAction,
        exitExercise: props.exitExercise,
      }}
    >
      <GradingExerciseContextProvider>
        <LessonNavigationContextProvider
          sectionId={
            isDoAssignmentMode &&
            props.exercise != null &&
            props.exercise.sectionId != null &&
            props.exercise.sectionId > 0
              ? props.exercise.sectionId
              : -1
          }
          resourceId={
            isDoAssignmentMode &&
            props.exercise != null &&
            props.exercise.resourceId != null &&
            props.exercise.resourceId > 0
              ? props.exercise.resourceId
              : -1
          }
        >
          {props.children}
        </LessonNavigationContextProvider>
      </GradingExerciseContextProvider>
    </ViewLessonContext.Provider>
  );
};

export const useViewLessonContext = () => {
  const context = useContext(ViewLessonContext);
  if (!context) {
    throw new Error('You must wrap container by ViewLessonContentProvider');
  }
  return context;
};

export interface ShowQuestionFeedbackProps {
  resourceId: number;
  feedBack: ExerciseResponseRes;
  answers: CompAnswerProps[];
}
