import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import React from 'react';
import { CompMode } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import { TextContentProps } from '@cms/content/ContentType';
import SectionItem from './SectionItem';
import { InputGroup } from '@components/input/InputGroup';

export const SectionItemEditable = (props: {
  item: LessonSectionProps;
  header: TextContentProps;
  footer: TextContentProps;
  onDataChange: (data: TextContentProps, group: 'header' | 'footer') => void;
}) => {
  return (
    <SectionItemEditableStyle className={'section-item-editable'}>
      <InputGroup label={'Header Content'}>
        <ContentViewerEditable
          mode={CompMode.COMPOSE}
          contentData={props.header}
          onChange={(data) =>
            props.onDataChange(data as TextContentProps, 'header')
          }
        />
      </InputGroup>

      <SectionItem visible={true} item={props.item} mode={CompMode.COMPOSE} />

      <InputGroup label={'Footer Content'}>
        <ContentViewerEditable
          mode={CompMode.COMPOSE}
          contentData={props.footer}
          onChange={(data) =>
            props.onDataChange(data as TextContentProps, 'footer')
          }
        />
      </InputGroup>
    </SectionItemEditableStyle>
  );
};

const SectionItemEditableStyle = styled.div`
  > * {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
`;
