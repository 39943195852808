import { Avatar, Image } from 'antd';
import React, { ReactNode } from 'react';
import { UserOutlined } from '@ant-design/icons';

export const UserAvatar = ({
  icon,
  src,
  size = 64,
}: {
  icon?: ReactNode;
  src?: string;
  size?: number;
}) => {
  if (src) {
    return (
      <Avatar
        size={size}
        icon={
          <Image
            src={src}
            style={{ width: size, height: size }}
            preview={false}
          />
        }
      />
    );
  } else {
    return <Avatar size={size} icon={icon ?? <UserOutlined />} />;
  }
};

export const CircleAvatar = ({
  size = 64,
  icon,
}: {
  size?: number;
  icon: ReactNode;
}) => {
  return <Avatar size={size} icon={icon} />;
};
