import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import styled from 'styled-components';

const LessonButtonStyle = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  padding: 0.1em 0.25em;
  min-width: 1.6em;
  min-height: 1.6em;
  line-height: 1.4em;

  .anticon {
    margin-right: 0.5em;
  }

  &.default-type-btn {
    background: #fff;
  }

  &.text-type-btn {
    background: transparent;
  }

  &.danger-btn {
    background: ${(props) => props.theme.warning.error};
    color: ${(props) => props.theme.color.white};
  }

  &.round-shape-btn {
    padding: 0.125em 1em;
    border-radius: 1em;
  }

  &.lesson-btn-icon-only {
    .anticon {
      margin-right: 0;
    }
  }
`;

export const LessonButtonNew = (props: AntButtonProps) => {
  const getClassName = () => {
    const className: string[] = ['lesson-button'];

    if (props.className) {
      className.push(props.className);
    }

    if (props.icon != null && props.children == null) {
      className.push('lesson-btn-icon-only');
    }

    if (props.type) {
      className.push(props.type + '-type-btn');
    }

    if (props.shape) {
      className.push(props.shape + '-shape-btn');
    }

    if (props.danger) {
      className.push('danger-btn');
    }

    if (props.disabled) {
      className.push('disabled-btn');
    }

    return className.join(' ');
  };

  return (
    <LessonButtonStyle className={getClassName()} onClick={props.onClick}>
      {props.icon && <span className={'lesson-button-icon'}>{props.icon}</span>}
      {props.children && <>{props.children}</>}
    </LessonButtonStyle>
  );
};

const AntLessonButtonStyle = styled(AntButton)`
  &.less-than-1-min-btn {
    background: ${(props) => props.theme.warning.error};
    color: ${(props) => props.theme.color.white};
  }

  &.remain-5-mins-btn {
    background: ${(props) => props.theme.warning.warning};
    color: ${(props) => props.theme.color.white};
  }

  &.remain-10-mins-btn {
    background: ${(props) => props.theme.warning.info};
    color: ${(props) => props.theme.color.white};
  }

  &.remain-15-mins-btn {
    background: ${(props) => props.theme.warning.success};
    color: ${(props) => props.theme.color.white};
  }
`;

const LessonButton = ({ className, size, ...props }: AntButtonProps) => {
  return (
    <AntLessonButtonStyle
      className={`${className ?? ''}`}
      size={'large'}
      {...props}
    />
  );
};

export default LessonButton;
