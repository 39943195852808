import { AssetsLibraryFilterTable } from '@cms/utils/AssetsLibraryComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import React from 'react';

const AssetsLibraryFilter = (props: {
  type?: AssetsTypeEnum;
  mode: 'edit' | 'select';
  onTypeChange?: (type: AssetsTypeEnum) => void;
  onCancel: () => void;
}) => {
  return (
    <AssetsLibraryFilterTable
      mode={props.mode}
      type={props.type}
      selectFile={undefined}
      onCancel={props.onCancel}
      onTypeChange={props.onTypeChange}
      onSelectFile={() => props.onCancel}
    />
  );
};

export default AssetsLibraryFilter;
