import React, { useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'antd/lib/button';
import { H2, H3 } from '@components/typography';
import Card, { CardClassEnum } from '@components/card';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import LessonListItem, {
  LessonSelectionEnum,
  ViewLessonListItem,
} from '@modules/product/components/lesson/SelectLessonItem';
import {
  SelectProductLessonContextProvider,
  useSelectProductLessonContext,
} from '@modules/product/context/SelectProductLessonContext';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import SelectLessonForm from '@modules/product/components/lesson/SelectLessonForm';
import { Default_Gutter } from '@components/grid';
import { LessonRes } from '@modules/product/services/lesson_model';

enum AssignmentFormActionEnum {
  default = '',
  select_lesson = 'select-lesson',
}

const AssignmentLessonForm = (props: {
  teacherId?: number;
  productId: number;
  productTocId: number;
  lessonIds: number[];
  onChange: (lessonIds: number[]) => void;
}) => {
  const { t } = useTranslation();

  const [action, setAction] = useState(AssignmentFormActionEnum.default);

  const handleOnChange = (lessons: LessonRes[]) => {
    const lessonIds = lessons.map((ls) => {
      return ls.lessonId;
    });

    setAction(AssignmentFormActionEnum.default);
    props.onChange(lessonIds);
  };

  const handleOnCancel = () => {
    setAction(AssignmentFormActionEnum.default);
  };

  return (
    <>
      <SelectProductLessonContextProvider
        productId={props.productId}
        productTocId={props.productTocId}
        lessonIds={props.lessonIds}
        onChange={handleOnChange}
      >
        <HeaderGroup className={'header-group'}>
          <H2>
            {t('assignment.actions.select_lesson')}

            <Button
              type={'primary'}
              shape="circle"
              icon={IconUtils.add_new}
              onClick={() => setAction(AssignmentFormActionEnum.select_lesson)}
            />
          </H2>
        </HeaderGroup>

        <SelectLessonContainer />

        {action === AssignmentFormActionEnum.select_lesson && (
          <CustomModal
            header={t('assignment.actions.select_lesson')}
            className={CustomModalClassEnum.full_size_modal}
            maskClosable={false}
            closable={false}
            content={
              <PickupLessonContainer
                teacherId={props.teacherId}
                onClose={handleOnChange}
                onCancel={handleOnCancel}
              />
            }
            onCloseFunc={handleOnCancel}
          />
        )}
      </SelectProductLessonContextProvider>
    </>
  );
};

export default AssignmentLessonForm;

const SelectLessonContainer = () => {
  const { t } = useTranslation();
  const { selectLessons, onRemoveLesson } = useSelectProductLessonContext();

  const generateTitleLesson = (name: string) => {
    return <h4>{name}</h4>;
  };

  return (
    <Card className={CardClassEnum.rectangle_box}>
      <H3>
        {t('assignment.warning.total_assignment', {
          numb: selectLessons.length,
        })}
      </H3>

      <List
        bordered
        dataSource={selectLessons}
        renderItem={(item) => (
          <List.Item>
            <LessonListItem
              item={item}
              type={LessonSelectionEnum.remove}
              generateTitle={generateTitleLesson}
              onRemove={onRemoveLesson}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

const PickupLessonContainer = (props: {
  teacherId?: number;
  onClose: (lessons: LessonRes[]) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { productId, selectLessons, onSelectLesson } =
    useSelectProductLessonContext();

  const [templateLessons, setTemplateLessons] = useState<LessonRes[]>([
    ...selectLessons,
  ]);

  const [tempSelectLessonIds, setTempSelectLessonIds] = useState<number[]>(
    () => {
      return selectLessons.map((ls) => {
        return ls.lessonId;
      });
    }
  );

  useEffect(() => {
    setTemplateLessons(selectLessons);
    setTempSelectLessonIds(
      selectLessons.map((ls) => {
        return ls.lessonId;
      })
    );
  }, [JSON.stringify(selectLessons)]);

  const onAddLesson = (lesson: LessonRes) => {
    setTemplateLessons((prev) => {
      return [...prev, lesson];
    });

    setTempSelectLessonIds((prev) => {
      return [...prev, lesson.lessonId];
    });
  };

  const onRemoveLesson = (lessonId: number) => {
    setTemplateLessons((preState) => {
      return preState.filter((ls) => {
        return ls.lessonId !== lessonId;
      });
    });

    setTempSelectLessonIds((preState) => {
      return preState.filter((ls) => {
        return ls !== lessonId;
      });
    });
  };

  const handleCloseModal = (select: boolean) => {
    if (select) {
      onSelectLesson(templateLessons);
      props.onCancel();
    } else {
      props.onCancel();
    }
  };

  return (
    <div className={'select-lesson'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <H3>Filter Lessons</H3>

          <SelectLessonForm
            selectProduct
            teacherId={props.teacherId}
            productId={productId}
            selectedLessonIds={tempSelectLessonIds}
            initLessonIds={[]}
            onSelect={onAddLesson}
            onRemove={onRemoveLesson}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <H3>{t('assignment.label.lessons')}</H3>

          <div style={{ maxHeight: 'calc(100vh - 300px)' }}>
            <List
              bordered
              size={'small'}
              dataSource={templateLessons}
              renderItem={(item) => (
                <List.Item>
                  <LessonListItem
                    item={item}
                    type={LessonSelectionEnum.remove}
                    onRemove={onRemoveLesson}
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <ButtonGroup type={'right'} className={'submit-container'}>
            <Button
              type={'default'}
              shape={'round'}
              size={'large'}
              onClick={() => handleCloseModal(false)}
            >
              {t('label.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              onClick={() => handleCloseModal(true)}
            >
              {t('label.select')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export const ViewAssignmentLesson = (props: { item: LessonRes }) => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderGroup className={'header-group'}>
        <H2>{t('assignment.actions.lesson_information')}</H2>
      </HeaderGroup>

      <Card className={CardClassEnum.rectangle_box}>
        <ViewLessonListItem items={[props.item]} />
      </Card>
    </>
  );
};
