import React from 'react';
import styled from 'styled-components';

const ColorSquareBox = styled.span`
  display: flex;
  align-items: center;

  .color-description {
    margin-left: 11px;
    padding-left: 11px;
    border-left: 1px solid #ccc;
  }
`;

const ColorSquareStyle = styled.span`
  display: inline-block;
  background-color: ${(props) => props.color};
  width: 1em;
  height: 1em;
  border: 1px solid #bebebe;
`;

const ColorSquare = ({ color }: any) => {
  return (
    <ColorSquareBox>
      <ColorSquareStyle color={color} />
      <span className={'color-description'}>{color}</span>
    </ColorSquareBox>
  );
};

export default ColorSquare;
