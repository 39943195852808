import React from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import { RegisterAPI } from '@modules/authorization/services/RegisterAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { userNameRegex } from '@modules/authorization/container/RegisteUserForm';
import env from '@utils/config';
import Alert from '@components/alert';
import NavLink from '@components/button/NavLink';
import { H4 } from '@components/typography';
import { SiteMap } from '@router/SiteMap';
import { useLoginSuccess } from '@modules/authorization/hook/useLoginSuccess';

export default function RegisterStudent(props: {
  disabled: boolean;
  successHandle: () => void;
  autoLogin: boolean;
  hideCancel?: boolean;
}) {
  const { onLoginSuccess } = useLoginSuccess();

  const { t } = useTranslation();

  const [studentRegister, { isLoading }] =
    RegisterAPI.endpoints.studentRegister.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      username: '',
      password: '',
      repeatPassword: '',
    },

    validationSchema: yup.object().shape({
      username: yup
        .string()
        .required(t('registration.required.email.valid'))
        .matches(userNameRegex, 'Please enter valid username'),
      password: yup.string().required(t('registration.required.password')),
      repeatPassword: yup
        .string()
        .required(t('registration.required.repeat_password'))
        .oneOf([yup.ref('password')], t('errors.password_must_match')),
    }),

    onSubmit: (values) => {
      const payload = {
        username: values.username,
        password: values.password,
        clientId: env.CLIENT_ID,
        autoLogin: props.autoLogin,
      };

      studentRegister(payload)
        .unwrap()
        .then((data) => {
          if (props.autoLogin) {
            onLoginSuccess(data);
            props.successHandle();

            notification.success({
              message: t('registration.alert.register_success'),
              description: t('registration.alert.register_success_ms'),
              placement: 'bottomRight',
            });
          } else {
            notification.success({
              message: t('registration.alert.register_success'),
              description: t('registration.alert.register_success_ms'),
              placement: 'bottomRight',
            });
            props.successHandle();
          }
        })
        .catch((error) => {
          if (error.data.error === 'user.name.exited') {
            notification.error({
              message: t('registration.teacher.warning.error'),
              description: t('registration.warning.user_name_exited'),
              placement: 'bottomRight',
            });
          } else {
            notification.error({
              message: t('registration.teacher.warning.error'),
              description: t('registration.teacher.warning.error_message'),
              placement: 'bottomRight',
            });
          }
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      {props.disabled && (
        <Alert
          type={'error'}
          message={<H4>{t('registration.warning.disabled_register')}</H4>}
          closable={true}
          description={t('registration.warning.disabled_register_message')}
        />
      )}

      <Input
        required={true}
        name="username"
        type="text"
        label={t('label.username')}
        placeholder={t('form.enter_user_name')}
        onChange={formik.handleChange}
        value={formik.values.username}
      />

      <Input
        required={true}
        name="password"
        type="password"
        label={t('label.password')}
        placeholder={t('form.enter_password')}
        onChange={formik.handleChange}
        value={formik.values.password}
      />

      <Input
        required={true}
        name="repeatPassword"
        type="password"
        label={t('label.password_again')}
        placeholder={t('form.enter_password_again')}
        onChange={formik.handleChange}
        value={formik.values.repeatPassword}
      />

      <ButtonGroup className="submit-container">
        <Button
          type={'primary'}
          shape={'round'}
          size={'large'}
          loading={isLoading}
          onClick={formik.submitForm}
          disabled={props.disabled}
        >
          {t('button.register')}
        </Button>

        {!props.hideCancel && (
          <NavLink href={SiteMap.index}>
            <Button type="default" size={'large'} shape={'round'}>
              {t('button.back')}
            </Button>
          </NavLink>
        )}
      </ButtonGroup>
    </FormikProvider>
  );
}
