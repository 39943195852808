export enum RoleEnum {
  ANONYMOUS = '',
  LOGON = 'ROLE_LOGON',

  STUDENT = 'ROLE_STUDENT',
  PARENT = 'ROLE_PARENT',
  TEACHER = 'ROLE_TEACHER',
  SUPPORT = 'ROLE_SUPPORT',
  SCHOOL = 'ROLE_SCHOOL',
  ADMIN = 'ROLE_ADMIN',

  // permission
  MANAGE_COURSE = 'ROLE_MANAGE_COURSE',
  MANAGE_CLASS = 'ROLE_MANAGE_CLASS',
  // MANAGE_STUDENTS_PARENTS = 'ROLE_MANAGE_STUDENTS_PARENTS',

  CREATE_CALENDAR = 'ROLE_CALENDAR',

  COMPOSE_LESSON = 'ROLE_COMPOSE_LESSON',
  CREATE_PRODUCT = 'ROLE_CREATE_PRODUCT',
  CREATE_LESSON_TEMPLATE = 'ROLE_CREATE_LESSON_TEMPLATE',

  BOOK_LICENSE = 'ROLE_BOOK_LICENSE',
  PUBLISH_LICENSE = 'ROLE_PUBLISH_LICENSE',

  MANAGE_OAUTH_APP = 'ROLE_MANAGE_OAUTH_APP',

  DESIGN_PAGE = 'ROLE_DESIGN_PAGE',
  PUBLISH_PAGE = 'ROLE_PUBLISH_PAGE',
  UPDATE_SCHOOL_SETTING = 'ROLE_UPDATE_SCHOOL_SETTING',
  MANAGE_PAYMENT = 'ROLE_MANAGE_PAYMENT',
}
