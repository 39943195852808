import React from 'react';
import OptionEditable from '@cms/editable/OptionEditable';
import MultipleChoiceOption from '@cms/comps/interact/multiple-choice/MultipleChoiceOption';
import { useMultipleChoiceCompContext } from '@cms/comps/interact/multiple-choice/MultipleChoiceCompContext';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';

const MultipleChoiceOptions = () => {
  const {
    modifiedTime,
    setting,
    answers,
    sourceItems,
    updateOptionType,
    updateOptionData,
    updateGroupOptions,
    onSelectOption,
    updateComponent,
  } = useMultipleChoiceCompContext();
  return (
    <>
      <div className={'mc-panel source-items'} key={modifiedTime}>
        {sourceItems &&
          sourceItems.map((option, index) => {
            return (
              <div
                className={`mc-option`}
                key={option.label + '-' + index + '-' + option.content.id}
              >
                <OptionEditable
                  type={option.content.type}
                  disabled={{
                    addOption: false,
                    removeOption: sourceItems.length === 1,
                  }}
                  onChangeType={(newType) =>
                    updateOptionType(newType, index, option)
                  }
                  onOperation={(operation) =>
                    updateGroupOptions(operation, index, option)
                  }
                >
                  <MultipleChoiceOption
                    option={option}
                    answer={answers}
                    setting={setting}
                    handleOnContentChange={(data) =>
                      updateOptionData(data, index, option)
                    }
                    onClick={onSelectOption}
                  />
                </OptionEditable>
              </div>
            );
          })}
      </div>

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </>
  );
};

export default MultipleChoiceOptions;
