import { EntityRequestEnum } from '@utils/Constant';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const PendingMessageDisplay = (props: { status: EntityRequestEnum }) => {
  const { t } = useTranslation();

  if (
    props.status === EntityRequestEnum.ACCEPT ||
    props.status === EntityRequestEnum.ACCEPT_N_REMOVED
  ) {
    return <>{t('label.pending.accepted')}</>;
  } else if (
    props.status === EntityRequestEnum.REJECT ||
    props.status === EntityRequestEnum.REJECT_N_REMOVED
  ) {
    return <>{t('label.pending.reject')}</>;
  } else if (props.status === EntityRequestEnum.REMOVED) {
    return <>{t('label.pending.removed')}</>;
  } else {
    return <>{t('label.pending.pending')}</>;
  }
};
