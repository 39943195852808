import React, { useEffect } from 'react';
import { EmailTemplateAPI } from '@services/setting/EmailTemplateAPI';

import { Skeleton } from 'antd';

import Title from 'antd/lib/typography/Title';

const ViewEmailTemplate = (props: {
  emailTemplateId: number;
  onCloseFunc: () => void;
}) => {
  const [findById, { data, isLoading }] =
    EmailTemplateAPI.endpoints.findById.useLazyQuery();

  useEffect(() => {
    findById(props.emailTemplateId);
  }, [props.emailTemplateId]);

  return (
    <>
      {isLoading && <Skeleton active />}

      {!isLoading && data && (
        <div className={'email-template-compose'}>
          <Title>{data.subject}</Title>

          <div
            className={'email-template-content'}
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </div>
      )}
    </>
  );
};

export default ViewEmailTemplate;
