import { ExerciseReportRes } from '@services/reports/LessonReport';
import ColorUtils from '@utils/ColorUtils';
import { BarGraphReportProp } from '@modules/reports/charts/bargraph/BarGraphChart';
import React, { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import BarGraphGroupChart from '@modules/reports/charts/bargraph/BarGraphGroupChart';
import { useTranslation } from 'react-i18next';
import { H3 } from '@components/typography';

const AssignmentStandardGroupScoreHistory = (props: {
  reports: ExerciseReportRes[];
}) => {
  const { t } = useTranslation();

  const [keys] = useState(() => {
    return props.reports[0].standards.map((std) => {
      return std.standard.code;
    });
  });

  const renderFunc = (data: ExerciseReportRes): BarGraphReportProp => {
    const recordData: BarGraphReportProp = {
      group: `${data.exercise.student?.displayName}`,
    };

    data.standards.forEach((std) => {
      const scoreByPercent = Math.floor((std.score * 100) / std.maxScore);
      recordData[std.standard.code] = scoreByPercent;
      recordData[std.standard.code + 'Color'] = ColorUtils.getDefaultColor(
        std.standard.standardId
      ).color;
    });

    return recordData;
  };

  const labelFormat = (e: any) => {
    return e.id;
  };

  const customGetColor = (params: { id: string; data: BarGraphReportProp }) => {
    return params.data[params.id + 'Color'];
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Standard Stack Score
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Standard Stack Score</h3>}
          content={
            <>
              <p>Report thể hiện điểm số từng kĩ năng của từng học sinh</p>

              <p>
                Giáo viên có thể đánh giá xem trong lớp kĩ năng nào cần cải
                thiện.
              </p>
            </>
          }
        />
      </H3>

      <BarGraphGroupChart
        keys={keys}
        className={'lesson-standard-group'}
        content={props.reports}
        size={500}
        maxValue={keys.length * 100}
        render={renderFunc}
        labelFormat={labelFormat}
        xAxisTitle={t('report.students')}
        yAxisTitle={t('report.scores')}
        getColor={customGetColor}
      />
    </LessonReportChartStyle>
  );
};

export default AssignmentStandardGroupScoreHistory;
