import { useAudioCompContext } from '@cms/comps/content/audio/AudioCompContext';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AudioPlayer, AudioPlayerActionEnum } from '@cms/content/AudioViewer';
import { TimelineProps } from '@cms/ComponentInteface';

const AudioInlineComp = () => {
  const [action, setAction] = useState<{
    action: AudioPlayerActionEnum;
    params: any;
  }>({
    action: AudioPlayerActionEnum.default,
    params: null,
  });

  const [currentTime, setCurrentFrom] = useState<{
    from: number | null;
    to: number | null;
  }>({
    from: null,
    to: null,
  });

  const { modifiedTime, audioContent, sourceItems } = useAudioCompContext();

  const handleOnClickData = (data: TimelineProps) => {
    setCurrentFrom({
      from: data.from,
      to: data.to,
    });
  };

  useEffect(() => {
    if (currentTime.from != null && currentTime.to != null) {
      setAction({
        action: AudioPlayerActionEnum.jumpTo,
        params: currentTime.from,
      });
    }
  }, [currentTime]);

  const handleOnChange = (time: number) => {
    if (currentTime.from != null && currentTime.to != null) {
      if (currentTime.to > 0 && currentTime.to < time) {
        setAction({
          action: AudioPlayerActionEnum.pause,
          params: currentTime.to,
        });

        setCurrentFrom({
          from: null,
          to: null,
        });
      }
    }
  };

  return (
    <AudioInlineCompStyle
      className={'comp comp-audio-inline'}
      key={modifiedTime}
    >
      <div className={'audio-content'}>
        {sourceItems &&
          sourceItems.map((data) => {
            return (
              <div
                className={'audio-content-item'}
                key={JSON.stringify(data)}
                onClick={() => handleOnClickData(data)}
              >
                <ContentViewer contentData={data.content} />
              </div>
            );
          })}

        <div className={'audio-viewer'}>
          <AudioPlayer
            action={action}
            audioSrc={audioContent.data}
            title={audioContent.name}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </AudioInlineCompStyle>
  );
};

export default AudioInlineComp;

const AudioInlineCompStyle = styled.div`
  .audio-content {
    .audio-content-item {
      cursor: pointer;

      &:hover {
        color: ${(props) => props.theme.component.primary};
      }

      &:not(:first-child) {
        margin-top: 0.5em;
      }
    }
  }

  .audio-viewer {
    display: none;
  }
`;
