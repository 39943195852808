import React, { useEffect } from 'react';
import { H2 } from '@components/typography';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';

export default function AppVersionPage(props: { version: string }) {
  const [getServerVersion, { data }] =
    AuthAPI.endpoints.getServerVersion.useLazyQuery();

  useEffect(() => {
    getServerVersion({});
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className={'app-version'} style={{ padding: '3em 0em' }}>
      <H2>
        Client version: <b>{props.version}</b>
      </H2>

      {data && (
        <H2>
          Server version: <b>{data.message}</b>
        </H2>
      )}
    </div>
  );
}
