export enum NavigationMenuEnum {
  EMPTY = '',

  ABOUT = 'ABOUT',
  PUBLIC_LANDING_PAGE = 'PUBLIC_LANDING_PAGE',

  PUBLIC_PRODUCTS = 'PUBLIC_PRODUCTS',
  PUBLIC_TEACHERS = 'PUBLIC_TEACHERS',
  PUBLIC_LESSONS = 'PUBLIC_LESSONS',
  PUBLIC_REGISTER = 'PUBLIC_REGISTER',

  PUBLIC_COURSE = 'PUBLIC_COURSE',
  PUBLIC_COURSE_REGISTRATION = 'PUBLIC_COURSE_REGISTRATION',
  LOGIN = 'LOGIN',

  // private router
  DASHBOARD = 'DASHBOARD',
  CALENDAR = 'CALENDAR',
  ASSIGNMENTS = 'ASSIGNMENTS',

  MY_CLASS = 'MY_CLASS',
  MY_LESSON = 'MY_LESSON',
  MY_LESSON_PRACTICE = 'MY_LESSON_PRACTICE',

  LIBRARY = 'LIBRARY',
  LIBRARY_LESSONS = 'LIBRARY_LESSONS',
  LIBRARY_PRODUCTS = 'LIBRARY_PRODUCTS',
  REPORTS = 'REPORTS',
  REPORTS_EXERCISES = 'REPORTS_EXERCISES',
  REPORTS_LESSONS = 'REPORTS_LESSONS',

  METADATA = 'METADATA',
  METADATA_SUBJECT = 'METADATA_SUBJECT',
  METADATA_CATEGORY = 'METADATA_CATEGORY',
  METADATA_GRADE = 'METADATA_GRADE',
  METADATA_LESSON_TYPE = 'METADATA_LESSON_TYPE',

  PRODUCTS = 'PRODUCTS',
  PRODUCTS_PRODUCT = 'PRODUCTS_PRODUCT',
  PRODUCTS_LESSON = 'PRODUCTS_LESSON',
  PRODUCTS_RESOURCE = 'PRODUCTS_RESOURCE',
  PRODUCTS_MY_STANDARD_SET = 'PRODUCTS_MY_STANDARD_SET',
  PRODUCTS_MY_STANDARDS = 'PRODUCTS_MY_STANDARDS',
  PRODUCTS_MY_ASSETS = 'PRODUCTS_MY_ASSETS',
  PRODUCTS_LESSON_TEMPLATE = 'PRODUCTS_LESSON_TEMPLATE',
  PRODUCTS_LESSON_TYPE = 'PRODUCTS_LESSON_TYPE',

  PAGES = 'PAGES',
  PAGES_STATIC_PAGE = 'PAGES_STATIC_PAGE',
  PAGES_STATIC_PAGE_CONTENT = 'PAGES_STATIC_PAGE_CONTENT',
  PAGES_PAGE_TEMPLATE = 'PAGES_PAGE_TEMPLATE',

  LESSON_EXAMPLE = 'LESSON_EXAMPLE',
  LESSON_EXAMPLE_COLLECTION = 'LESSON_EXAMPLE_COLLECTION',
  LESSON_EXAMPLE_LESSON = 'LESSON_EXAMPLE_LESSON',
  LESSON_EXAMPLE_PAGE = 'LESSON_EXAMPLE_PAGE',

  COURSES = 'COURSES',
  COURSES_COURSES = 'COURSES_COURSES',
  COURSES_SURVEY = 'COURSES_SURVEY',
  COURSES_REQUESTS = 'COURSES_REQUESTS',
  COURSES_INVITATIONS = 'COURSES_INVITATIONS',

  USERS = 'USERS',
  USERS_CLASSES = 'USERS_CLASSES',
  USERS_STUDENTS = 'USERS_STUDENTS',
  USERS_PARENTS = 'USERS_PARENTS',
  USERS_TEACHERS = 'USERS_TEACHERS',
  USERS_SCHOOL_SUPPORT = 'USERS_SCHOOL_SUPPORT',
  USERS_SCHOOL_ADMIN = 'USERS_SCHOOL_ADMIN',

  SETTING = 'SETTING',
  SETTING_INFO = 'SETTING_INFO',
  SETTING_SETTING = 'SETTING_SETTING',
  SETTING_SCHOOL = 'SETTING_SCHOOL',
  SETTING_SCHOOL_PROFILE = 'SETTING_SCHOOL_PROFILE',
  SETTING_LANGUAGE = 'SETTING_LANGUAGE',
  SETTING_PAYMENT_CURRENCY = 'SETTING_PAYMENT_CURRENCY',
  SETTING_PAYMENT_METHOD = 'SETTING_PAYMENT_METHOD',
  SETTING_EMAIL_SETTING = 'SETTING_EMAIL_SETTING',
  SETTING_EMAIL_TEMPLATE = 'SETTING_EMAIL_TEMPLATE',

  PROGRAM = 'PROGRAM',
  PROGRAM_PROGRAM = 'PROGRAM_PROGRAM',
  PROGRAM_CERTIFICATE = 'PROGRAM_CERTIFICATE',
  PROGRAM_SUBJECT = 'PROGRAM_SUBJECT',
  PROGRAM_CATEGORY = 'PROGRAM_CATEGORY',
  PROGRAM_GRADE = 'PROGRAM_GRADE',

  LICENSE = 'LICENSE',
  LICENSE_BOOKS = 'LICENSE_BOOKS',

  NOTIFICATION = 'NOTIFICATION',
  MESSAGE = 'MESSAGE',

  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_SCHOOL = 'ORGANIZATION_SCHOOL',

  MY_ACCOUNT = 'MY_ACCOUNT',
  MY_ACCOUNT_INFO = 'MY_ACCOUNT_INFO',
  MY_ACCOUNT_PUBLIC_PROFILE = 'MY_ACCOUNT_PUBLIC_PROFILE',
  MY_ACCOUNT_CERTIFICATE = 'MY_ACCOUNT_CERTIFICATE',
}
