import { CompAnswerProps } from '@cms/ComponentInteface';
import { ExerciseResourceAnswerReq } from '@modules/assignments/service/exercise_model';

export const collectExerciseAnswer = (
  currentAnswers: Record<number, CompAnswerProps[]>
): ExerciseResourceAnswerReq[] => {
  return Object.entries(currentAnswers).map((ans) => {
    const [resourceId, answers] = ans;

    return {
      resourceId: +resourceId,
      answer: answers != null ? JSON.stringify(answers, null, 2) : null,
    };
  });
};
