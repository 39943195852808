import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { SurveyStandardRes } from '@services/model/survey';

import {
  CreateSurveyReq,
  SurveyRes,
  UpdateSurveyReq,
} from '@modules/assignments/service/survey_model';

export const SurveyAPI = createApi({
  reducerPath: 'SurveyAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<SurveyRes>, PaginationReq>({
      query: (params) => ({
        url: `/surveys`,
        params,
      }),
    }),

    findById: builder.query<SurveyRes, number>({
      query: (surveyId) => ({
        url: `/surveys/${surveyId}`,
      }),
    }),

    viewReports: builder.query<SurveyStandardRes, number>({
      query: (surveyId) => ({
        url: `/surveys/${surveyId}/reports`,
      }),
    }),

    createSurvey: builder.mutation<SurveyRes, CreateSurveyReq>({
      query: (body) => ({
        url: '/surveys',
        method: 'POST',
        body,
      }),
    }),

    updateSurvey: builder.mutation<SurveyRes, UpdateSurveyReq>({
      query: (body) => ({
        url: `/surveys/${body.surveyId}`,
        method: 'PUT',
        body,
      }),
    }),

    publishSurvey: builder.mutation<BasicMessageRes, number>({
      query: (surveyId) => ({
        url: `/surveys/${surveyId}/publish`,
        method: 'PUT',
      }),
    }),

    closeSurvey: builder.mutation<BasicMessageRes, number>({
      query: (surveyId) => ({
        url: `/surveys/${surveyId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
