import { SelectProps, Skeleton } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { useTranslation } from 'react-i18next';
import { SmallColorCircle } from '@components/input/ColorCircle';
import { StyledInput } from '@components/input/style';
import Select from '@components/select/Select';
import i18next from 'i18next';
import { ClassRes } from '@modules/users/services/clazz';

export interface TeacherClassSelectProps extends SelectProps<number> {
  label?: string;
  mode?: 'multiple' | 'tags';
  handleSelectClass: (value: number[]) => void;
}

export const TeacherClassSelect = ({
  label,
  handleSelectClass,
  mode,
  ...props
}: TeacherClassSelectProps) => {
  const { t } = useTranslation();

  const [findAllClass, { data, isSuccess }] =
    ClassAPI.endpoints.findAllClass.useLazyQuery({});

  const handleChange = (value: any) => {
    if (mode != null && mode === 'multiple') {
      handleSelectClass(value);
    } else {
      handleSelectClass([value]);
    }
  };

  function onSearch(val: string) {
    console.log('search:', val);
  }

  useEffect(() => {
    findAllClass({});
  }, []);

  const options = useMemo(() => {
    const optionArr = [];

    if (!mode) {
      optionArr.push({
        key: '-1',
        value: -1,
        label: (
          <>
            <SmallColorCircle color={'#ccc'}> </SmallColorCircle>{' '}
            {t('label.selectClass')}
          </>
        ),
      });
    }

    if (data) {
      data.forEach((item: ClassRes) => {
        optionArr.push({
          key: item.classId,
          value: item.classId,
          label: (
            <>
              <SmallColorCircle color={item.color}>
                {item.code}
              </SmallColorCircle>{' '}
              &nbsp;
              {item.name}
            </>
          ),
        });
      });
    }

    return optionArr;
  }, [mode, data, i18next.language]);

  return (
    <>
      {!isSuccess && <Skeleton paragraph={{ rows: 2 }} />}

      {isSuccess && data && (
        <StyledInput className={'form-group'}>
          {label && <div className={'ant-form-label'}>{label}</div>}

          <div className={'ant-form-controls'}>
            <Select
              size={'large'}
              name={'student'}
              showSearch
              placeholder={t('label.selectClass')}
              optionFilterProp="children"
              onChange={handleChange}
              onSearch={onSearch}
              mode={mode}
              options={options}
              {...props}
            />
          </div>
        </StyledInput>
      )}
    </>
  );
};
