import { EntityStatusEnum } from '@services/model/common';
import React, { ReactNode } from 'react';
import { Tooltip } from '@components/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { EntityStatusStyle } from '@components/status/style';

export const ComposingStatus = (props: { status: EntityStatusEnum }) => {
  const { t } = useTranslation();

  if (props.status === EntityStatusEnum.PUBLISHED) {
    return (
      <EntityStatusStyle className={'entity-status published-status'}>
        {t('lesson.status.completed')}
      </EntityStatusStyle>
    );
  } else if (props.status === EntityStatusEnum.ACTIVE) {
    return (
      <EntityStatusStyle className={'entity-status active-status'}>
        {t('lesson.status.composing')}
      </EntityStatusStyle>
    );
  } else if (props.status === EntityStatusEnum.REMOVED) {
    return (
      <EntityStatusStyle className={'entity-status removed-status'}>
        {t('lesson.status.removed')}
      </EntityStatusStyle>
    );
  } else {
    return <></>;
  }
};

export const ComposingWithStatus = (props: {
  status: EntityStatusEnum;
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  if (props.status === EntityStatusEnum.PUBLISHED) {
    return (
      <EntityStatusStyle className={'entity-status published-status'}>
        <Tooltip title={t('label.compose_status.completed')}>
          {props.children}
        </Tooltip>
      </EntityStatusStyle>
    );
  } else if (props.status === EntityStatusEnum.ACTIVE) {
    return (
      <EntityStatusStyle className={'entity-status active-status'}>
        <Tooltip title={t('label.compose_status.composing')}>
          {props.children}
        </Tooltip>
      </EntityStatusStyle>
    );
  } else if (props.status === EntityStatusEnum.REMOVED) {
    return (
      <EntityStatusStyle className={'entity-status removed-status'}>
        <Tooltip title={t('label.compose_status.removed')}>
          {props.children}
        </Tooltip>
      </EntityStatusStyle>
    );
  } else {
    return <EntityStatusStyle>{props.children}</EntityStatusStyle>;
  }
};
