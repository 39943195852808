import React from 'react';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ClassStudentProgram } from '@modules/users/component/classes/program/ClassStudentProgram';
import { ClassTeacherProgram } from '@modules/users/component/classes/program/ClassTeacherProgram';
import { ClassRes } from '@modules/users/services/clazz';

export const ClassProgram = (props: { item: ClassRes }) => {
  return (
    <>
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.STUDENT, RoleEnum.PARENT]}
      >
        <ClassStudentProgram item={props.item} />
      </Authorization>

      <Authorization
        type={'ifNotGranted'}
        roles={[RoleEnum.STUDENT, RoleEnum.PARENT]}
      >
        <ClassTeacherProgram item={props.item} />
      </Authorization>
    </>
  );
};
