import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useSecurity } from '@utils/authorization';
import { useSelector } from '@app/redux/hook';
import { getMenuItem } from '@components/template/common/MenuUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import i18next from 'i18next';
import { useProUser } from '@components/button/RequireProUserButton';
import { RoleEnum } from '@app/redux/slices/roles';
import { FileTextOutlined } from '@ant-design/icons';
import { HeaderMenu } from '@components/template/app-layout/header/header-menu/HeaderMenuDisplay';

const ProductManagementMenu = () => {
  const { t } = useTranslation();

  const proUser = useProUser();
  const { ifAnyGranted } = useSecurity();
  const { authorities } = useSelector((state) => state.profile);

  const menuItems = useMemo(() => {
    return [
      getMenuItem({
        key: NavigationMenuEnum.PRODUCTS_PRODUCT,
        label: (
          <NavLink href={SiteMap.content.product.list}>
            {t('menu.my_products')}
          </NavLink>
        ),
        icon: IconUtils.menu.products,
        visible: ifAnyGranted([
          RoleEnum.CREATE_PRODUCT,
          RoleEnum.BOOK_LICENSE,
          RoleEnum.PUBLISH_LICENSE,
          RoleEnum.SCHOOL,
        ]),
      }),
      getMenuItem({
        key: NavigationMenuEnum.PRODUCTS_LESSON,
        label: (
          <NavLink href={SiteMap.content.lesson.list}>
            {t('menu.my_lesson')}
          </NavLink>
        ),
        icon: IconUtils.menu.lesson,
        visible: ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PRODUCTS_RESOURCE,
        label: (
          <NavLink href={SiteMap.content.resource.list}>
            {t('menu.my_resource')}
          </NavLink>
        ),
        icon: IconUtils.menu.resource,
        visible: ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PRODUCTS_MY_STANDARD_SET,
        label: (
          <NavLink href={SiteMap.content.resource.standards_set}>
            {t('menu.my_standards_set')}
          </NavLink>
        ),
        icon: IconUtils.menu.standard,
        visible:
          proUser && ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PRODUCTS_MY_ASSETS,
        label: (
          <NavLink href={SiteMap.content.resource.assets}>
            {t('menu.my_assets_files')}
          </NavLink>
        ),
        icon: IconUtils.menu.assets,

        visible:
          proUser && ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
      }),

      getMenuItem({
        key: NavigationMenuEnum.PRODUCTS_LESSON_TEMPLATE,
        label: (
          <NavLink href={SiteMap.content.metadata.lesson_template}>
            {t('menu.lesson_template')}
          </NavLink>
        ),
        icon: <FileTextOutlined />,
        visible:
          proUser &&
          ifAnyGranted([RoleEnum.CREATE_LESSON_TEMPLATE, RoleEnum.SCHOOL]),
      }),
    ];
  }, [authorities, i18next.language, proUser]);

  return (
    <HeaderMenu
      group={NavigationMenuEnum.PRODUCTS}
      groupIcon={IconUtils.menu.library}
      className={'product-group-menu'}
      menuItems={menuItems}
    />
  );
};

export default ProductManagementMenu;
