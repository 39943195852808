import React from 'react';
import { CompConfiguration } from '@cms/ComponentInteface';
import { DropdownInlineComp } from '@cms/comps/interact/dropdown/DropdownInlineComp';
import { useDropDownCompContext } from '@cms/comps/interact/dropdown/DropdownCompContext';
import styled from 'styled-components';
import { DropDownOptionProps } from '@cms/comps/interact/dropdown/DropdownComp';
import { useDropDownEditable } from '@cms/comps/interact/dropdown/DropdownEditable';
import {
  ContentActionEnum,
  ContentViewerInlineEditable,
} from '@cms/comps/content/ContentViewerComp';
import { CompGroupEnum } from '@cms/utils/CompProps';
import OptionEditable from '@cms/editable/OptionEditable';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import DOMPurify from 'dompurify';

const DropdownGroupItem = ({
  drop,
  index,
}: {
  drop: DropDownOptionProps;
  index: number;
}) => {
  const { targetItems, answers, handleOnAnswerChange } =
    useDropDownCompContext();

  const {
    editItem,
    changeToEdit,
    updateOptionData,
    updateOptionType,
    updateGroupOptions,
  } = useDropDownEditable();

  const getInlineAnswer = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans.split(':')[1];
      }
    });

    return inlineAns;
  };

  const textArray = drop.content.data.split(CompConfiguration.INLINE_COMPONENT);

  return (
    <div className={`dropdown-group-item group-${index}`}>
      {editItem === drop.label ? (
        <ContentViewerInlineEditable
          contentData={drop.content}
          onChange={(data) =>
            updateOptionData(data, index, drop, CompGroupEnum.target)
          }
          onCancel={() => changeToEdit('')}
        />
      ) : (
        <OptionEditable
          disabled={{
            addOption: targetItems.length === 15,
            removeOption: targetItems.length === 1,
          }}
          contentTypes={[ContentActionEnum.CHANGE_TO_TEXT]}
          type={drop.content.type}
          onChangeType={(newType) =>
            updateOptionType(newType, index, drop, CompGroupEnum.target)
          }
          onOperation={(operation) =>
            updateGroupOptions(operation, index, drop, CompGroupEnum.target)
          }
        >
          <DropDownGroupItemStyle className={`dropdown-group-part`}>
            {textArray.map((text, idx) => {
              const sourceData = `${drop.label}|${idx}`;

              return (
                <React.Fragment key={text + '_' + idx}>
                  {idx > 0 && (
                    <>
                      <div className={'dropdown-text'}>&nbsp;</div>

                      <DropdownInlineComp
                        sourceData={sourceData}
                        answer={getInlineAnswer(sourceData)}
                        onChange={(label) =>
                          handleOnAnswerChange(sourceData + ':' + label)
                        }
                      />
                    </>
                  )}

                  {text.trim() !== '' && (
                    <div
                      className={'dropdown-text'}
                      dangerouslySetInnerHTML={{
                        __html: '&nbsp;' + DOMPurify.sanitize(text),
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </DropDownGroupItemStyle>

          <ComposeModeContent>
            {editItem === '' && (
              <Button
                className={'edit-content-group'}
                size={'small'}
                type={'primary'}
                shape={'circle'}
                onClick={() => changeToEdit(drop.label)}
                icon={IconUtils.actions.edit}
              />
            )}
          </ComposeModeContent>
        </OptionEditable>
      )}
    </div>
  );
};

export default DropdownGroupItem;

const DropDownGroupItemStyle = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;
