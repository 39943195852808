import React from 'react';

export enum PlaceValueUnitEnum {
  empty = '',
  ones = 'ones',
  tens = 'tens',
  hundreds = 'hundreds',
  thousands = 'thousands',
}
export const PlaceValueHeader = (props: { exp: string[]; index: number }) => {
  const type = getPlaceValueHeader(props.exp, props.index);

  if (type === PlaceValueUnitEnum.ones) {
    return <span>Ones</span>;
  } else if (type === PlaceValueUnitEnum.tens) {
    return <span>Tens</span>;
  } else if (type === PlaceValueUnitEnum.hundreds) {
    return <span>Hundreds</span>;
  } else if (type === PlaceValueUnitEnum.thousands) {
    return <span>Thousands</span>;
  } else {
    return <span>{''}</span>;
  }
};

export const getPlaceValueHeader = (
  exp: string[],
  index: number
): PlaceValueUnitEnum => {
  const revertIndex = exp.length - index;

  if (revertIndex === 0) {
    return PlaceValueUnitEnum.ones;
  } else if (revertIndex === 1) {
    return PlaceValueUnitEnum.tens;
  } else if (revertIndex === 2) {
    return PlaceValueUnitEnum.hundreds;
  } else if (revertIndex === 3) {
    return PlaceValueUnitEnum.thousands;
  } else {
    return PlaceValueUnitEnum.empty;
  }
};
