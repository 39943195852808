import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { StandardRes } from '@modules/product/services/standard_model';
import { ExerciseResponseStatusEnum } from '@modules/assignments/service/exercise_model';
import {
  ProductRes,
  ProductTocRes,
} from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface ViewProductTrackingRes {
  productId: number;
  progress: number;
  createdDate: string;
  modifiedDate: string;
  completedDate: string;
}

export interface ViewProductLessonTrackingRes {
  viewProductLessonTracking: number;
  product: ProductRes;
  productToc: ProductTocRes;
  lesson: LessonRes;
  status: ExerciseResponseStatusEnum;
  score: number;
  maxScore: number;
  progress: number;
  createdDate: string;
  modifiedDate: string;
  completedDate: string;
}

export interface StandardDailyScoreRes {
  standardDailyScoreId: number;
  standard: StandardRes;
  percent: number;
  createdDate: string;
}

export interface StandardHistoryScoreRes {
  standardHistoryId: number;
  standard: StandardRes;
  percent: number;
  createdDate: string;
}

export interface ProductStandardDailyScoreRes {
  product: ProductRes;
  scores: StandardDailyScoreRes[];
}
export const ProgressReportAPI = createApi({
  reducerPath: 'ProgressReportAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getProductsTracking: builder.query<ViewProductTrackingRes[], unknown>({
      query: () => ({
        url: `/products/progress`,
      }),
    }),

    getProductProgress: builder.query<ViewProductLessonTrackingRes[], number>({
      query: (productId) => ({
        url: `/products/${productId}/progress`,
      }),
    }),

    getProductReport: builder.query<ProductStandardDailyScoreRes, number>({
      query: (productId) => ({
        url: `/products/${productId}/reports`,
      }),
    }),

    getProductReportForUser: builder.query<
      ProductStandardDailyScoreRes,
      { productId: number; userId: number }
    >({
      query: (params) => ({
        url: `/products/${params.productId}/users/${params.userId}/reports`,
      }),
    }),

    getStandardHistoryScore: builder.query<StandardHistoryScoreRes[], number>({
      query: (standardId) => ({
        url: `/products/standards/${standardId}/reports`,
      }),
    }),
  }),
});
