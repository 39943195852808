import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { PlaceValueCompProps } from '@cms/comps/math/operation/place-value/PlaceValueComp';
import { LongOperationLayoutEnum } from '@cms/comps/math/operation/OperationUtils';

export const PlaceValueExampleData: PlaceValueCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.PLACE_VALUE,

  configuration: {
    numbers: '575',
    expression: [
      {
        type: 'place-value',
        exp: ['U', 'U', 'U'],
      },
      {
        type: 'addend',
        exp: ['x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: LongOperationLayoutEnum.place_value,
  },
};

export const PlaceValueExampleBlockData: PlaceValueCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.PLACE_VALUE,

  configuration: {
    numbers: '575',
    expression: [
      {
        type: 'place-value',
        exp: ['U', 'U', 'U'],
      },
      {
        type: 'addend',
        exp: ['x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: LongOperationLayoutEnum.place_value_block,
  },
};
