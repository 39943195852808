import React from 'react';

import { Button } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';

import { IconUtils } from '@utils/IconUtils';

export default function LessonCreate(props: {
  onSubmit: (name: string) => void;
}) {
  const { t } = useTranslation();
  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().trim().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      props.onSubmit(values.name);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Input
        required
        name="name"
        type="text"
        label={t('label.name')}
        placeholder={t('label.name')}
        onChange={formik.handleChange}
        value={formik.values.name}
      />

      <ButtonGroup className="submit-container" type={'right'}>
        <Button
          type={'primary'}
          shape={'round'}
          onClick={formik.submitForm}
          icon={IconUtils.actions.save}
        >
          {t('button.create')}
        </Button>
      </ButtonGroup>
    </FormikProvider>
  );
}
