import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import { useEffect, useState } from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { LessonRes } from '@modules/product/services/lesson_model';

export const useViewLessonExample = (lessonExampleId?: number) => {
  const [viewLessonExampleContent, { data, isSuccess }] =
    LessonExampleAPI.endpoints.viewLessonExampleContent.useLazyQuery();

  const [relativeData, setRelativeData] = useState<LessonRes | null>(null);
  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    if (lessonExampleId != null && lessonExampleId > 0) {
      viewLessonExampleContent(lessonExampleId);
    }
  }, [lessonExampleId]);

  useEffect(() => {
    if (data && isSuccess) {
      setRelativeData(data);
      setResources(ResourceUtils.getResources(data.sections));
    }
  }, [data]);

  return {
    lessonExampleId,
    relativeData,
    resources,
  };
};
