import {
  ResourceAnswerProps,
  ResourceGradingProps,
  updateGrading,
  updateResourceAnswer,
} from '@app/redux/slices/viewResource';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CompAnswerProps,
  CompGradingProps,
  CompMode,
  CompProps,
} from '@cms/ComponentInteface';
import ComponentCompose from './ComponentCompose';
import { ComponentItem } from './ComponentItem';

import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { H5 } from '@components/typography';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { ExerciseResource } from '@cms/lesson-template/exercise/ExerciseResource';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import styled from 'styled-components';
import { ComponentClassName } from '@cms/context/ComponentClassName';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from '@app/redux/hook';
import { useTheme } from '@app/styled/StyledProvider';
import {
  ComponentResponseProps,
  ExerciseResponseRes,
} from '@modules/assignments/service/exercise_model';
import {StringUtils} from "@utils/StringUtils";

const ResourceContext = createContext({
  mode: CompMode.REVIEW as CompMode,
  ready: false as boolean, // component is complete rendering and ready for interact...

  disabledMode: false as boolean,
  resourceId: -1 as number, // component data

  items: [] as ComponentItemProps[],
  grading: [] as CompGradingProps[],
  scoring: null as ExerciseResponseRes | null,

  resourceReady: (isReady: boolean) => {
    console.log('resource is ready', isReady);
  },

  onCompChange: (newAns: CompAnswerProps) => {
    console.log('update resource answer....', newAns);
  },

  onGradingChange: (grading: CompGradingProps) => {
    console.log('update grading score....', grading);
  },

  clearAnswer: () => {
    console.log('clear answer....');
  },
});

export interface ComponentItemProps {
  id: string;
  comps: CompProps;
  answer: CompAnswerProps;
  feedBack: ComponentResponseProps;
}

const getResourceComponentItems = (
  components: CompProps[],
  answers: CompAnswerProps[],
  feedBacks?: ComponentResponseProps[]
): ComponentItemProps[] => {
  const mapAns = {} as any;
  const mapFeedback = {} as any;

  answers.forEach((ans) => {
    mapAns[ans.id] = ans;
  });

  if (feedBacks != null && feedBacks.length > 0) {
    feedBacks.forEach((ans) => {
      mapFeedback[ans.id] = ans;
    });
  }

  return components.map((comp) => {
    return {
      id: comp.id,
      comps: comp,
      answer: mapAns[comp.id] != null ? mapAns[comp.id] : [],
      feedBack: mapFeedback[comp.id] != null ? mapFeedback[comp.id] : null,
    };
  });
};

export const ResourceContextProvider = (props: {
  mode?: CompMode;
  resourceId: number;

  disabled?: boolean;
  comps: CompProps[];
  answer: CompAnswerProps[];
  feedback?: ComponentResponseProps[];
  scoring?: ExerciseResponseRes | null;

  onChange?: (newAns: ResourceAnswerProps) => void;
  onGradingChange?: (newGrading: ResourceGradingProps) => void;
  children: any;
}) => {
  const theme = useTheme();
  const [ready, setReady] = useState(false);
  const { isShowCorrectAnswer, errors } = useViewLessonContext();

  const [answers, setAnswers] = useState<{
    answer: CompAnswerProps[];
    triggerChange: boolean;
  }>({
    answer: props.answer,
    triggerChange: false,
  });

  const [grading, setGrading] = useState<{
    grading: CompGradingProps[];
    triggerChange: boolean;
  }>(() => {
    const comtGrading: CompGradingProps[] =
      props.feedback && props.feedback.length > 0
        ? props.feedback?.map((fb) => {
            return { id: fb.id, score: fb.score, comment: fb.comment };
          })
        : [];

    return {
      grading: comtGrading,
      triggerChange: false,
    };
  });

  const [feedbacks, setFeedbacks] = useState<ComponentResponseProps[]>(
    props.feedback ? props.feedback : []
  );

  const [componentItems, setComponentItems] = useState<ComponentItemProps[]>(
    () => {
      return getResourceComponentItems(
        props.comps,
        props.answer,
        props.feedback
      );
    }
  );

  useEffect(() => {
    setComponentItems(
      getResourceComponentItems(props.comps, props.answer, feedbacks)
    );
  }, [props.comps, props.answer, feedbacks]);

  useEffect(() => {
    setAnswers({
      answer: props.answer,
      triggerChange: false,
    });
  }, [StringUtils.toStr(props.answer)]);

  useEffect(() => {
    setFeedbacks(props.feedback ? props.feedback : []);
  }, [StringUtils.toStr(props.feedback)]);

  useEffect(() => {
    if (props.onChange && answers.triggerChange) {
      props.onChange({
        resourceId: props.resourceId,
        answers: answers.answer,
      });
    }
  }, [answers]);

  useEffect(() => {
    if (props.onGradingChange && grading.triggerChange) {
      props.onGradingChange({
        resourceId: props.resourceId,
        grading: grading.grading,
      });
    }
  }, [grading]);

  const resourceReady = (isReady: boolean) => {
    setReady(isReady);
  };

  const onCompChange = (answer: CompAnswerProps) => {
    if (props.mode !== CompMode.REVIEW) {
      // only change answer if not remove mode...
      setAnswers((prev) => {
        const removeList = [...prev.answer].filter((ans) => {
          return ans.id !== answer.id;
        });

        const ans = [...removeList, answer];

        return {
          answer: ans,
          triggerChange: true,
        };
      });
    }
  };

  const onGradingChange = (grading: CompGradingProps) => {
    setGrading((prev) => {
      const removeList = [...prev.grading].filter((fb) => {
        return fb.id !== grading.id;
      });

      return {
        grading: [...removeList, grading],
        triggerChange: true,
      };
    });
  };

  const clearAnswer = () => {
    setAnswers({
      answer: [],
      triggerChange: true,
    });
  };

  return (
    <ResourceContext.Provider
      value={{
        ready: ready,
        mode: props.mode || CompMode.VIEW,
        disabledMode: isShowCorrectAnswer || !!props.disabled,
        resourceId: props.resourceId,
        items: componentItems,
        grading: grading.grading,
        scoring: props.scoring ? props.scoring : null,

        resourceReady,
        onCompChange,
        onGradingChange,
        clearAnswer,
      }}
    >
      {props.mode === CompMode.SELF_CHECK &&
        errors[props.resourceId] != null &&
        errors[props.resourceId] && (
          <H5
            className={'resource-has-error'}
            style={{
              outline: `3px solid ${theme.warning.error}`,
              color: theme.warning.error,
              width: '100%',
            }}
          >
            Has Error!
          </H5>
        )}
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </ResourceContext.Provider>
  );
};

export const useResourceContext = () => {
  const context = useContext(ResourceContext);
  if (!context) {
    throw new Error('You must wrap container by ResourceContextProvider');
  }
  return context;
};

export const ResourceItem = () => {
  const { items, onCompChange } = useResourceContext();

  return (
    <ResourceItemStyle className={'resource-comps'}>
      {items.map((comp) => {
        return (
          <ComponentItem
            key={comp.id}
            item={comp.comps}
            answer={comp.answer}
            feedback={comp.feedBack}
            onChange={onCompChange}
          />
        );
      })}
    </ResourceItemStyle>
  );
};

const ResourceItemStyle = styled.div`
  > * {
    &:not(:first-child) {
      margin-top: var(--cms-padding-component, 0.5em);
    }
  }
`;

export const ResourceItemViewer = ({ item }: { item: ResourceProps }) => {
  const dispatch = useDispatch();

  const { type, answers, feedBacks, updateAnswer, updateGradingResult } =
    useViewLessonContext();

  const qAnswers = useMemo(() => {
    return answers[item.resourceId] ? answers[item.resourceId] : [];
  }, [StringUtils.toStr(answers)]);

  const qFeedBacks = useMemo(() => {
    return feedBacks[item.resourceId] ? feedBacks[item.resourceId].result : [];
  }, [StringUtils.toStr(feedBacks)]);

  const qScoring = useMemo(() => {
    return feedBacks[item.resourceId] ? feedBacks[item.resourceId] : null;
  }, [StringUtils.toStr(feedBacks)]);

  const handleOnAnswerChange = (newAns: ResourceAnswerProps) => {
    updateAnswer(newAns);
    dispatch(updateResourceAnswer(newAns));
  };

  const handleOnGradeChange = (grading: ResourceGradingProps) => {
    updateGradingResult(grading);
    dispatch(updateGrading(grading));
  };

  return (
    <ResourceContextProvider
      disabled={feedBacks[item.resourceId] != null}
      mode={type}
      resourceId={item.resourceId}
      comps={item.components}
      answer={qAnswers}
      feedback={qFeedBacks}
      scoring={qScoring}
      onChange={handleOnAnswerChange}
      onGradingChange={handleOnGradeChange}
    >
      <ExerciseResource item={item} questionNumber={item.questionNumber} />
    </ResourceContextProvider>
  );
};

export const ResourceItemComposeViewer = ({
  item,
}: {
  item: ResourceProps;
}) => {
  const dispatch = useDispatch();

  const { type, updateAnswer, updateGradingResult } = useViewLessonContext();

  const handleOnAnswerChange = (newAns: ResourceAnswerProps) => {
    updateAnswer(newAns);
    dispatch(updateResourceAnswer(newAns));
  };

  const handleOnGradeChange = (grading: ResourceGradingProps) => {
    updateGradingResult(grading);
    dispatch(updateGrading(grading));
  };

  return (
    <ResourceContextProvider
      mode={type}
      resourceId={item.resourceId}
      comps={item.components}
      answer={item.correctAnswer}
      feedback={[]}
      scoring={null}
      onChange={handleOnAnswerChange}
      onGradingChange={handleOnGradeChange}
    >
      <ExerciseResource item={item} questionNumber={item.questionNumber} />
    </ResourceContextProvider>
  );
};

export const ResourceCompose = () => {
  const { items, onCompChange } = useResourceContext();
  const { errors, modifiedDate } = useSelector(
    (state) => state.composeResource
  );

  return (
    <ResourceComposeStyle className={ComponentClassName.question_class}>
      {items.map((comp, index) => {
        return (
          <div
            className={ComponentClassName.component_class}
            key={comp.id + '_' + index + '_' + modifiedDate}
          >
            <ComponentCompose
              index={index}
              total={items.length}
              item={comp.comps}
              errors={errors != null ? errors[comp.id] : []}
            >
              <ComponentItem
                item={comp.comps}
                answer={comp.answer}
                feedback={comp.feedBack}
                onChange={onCompChange}
              />
            </ComponentCompose>
          </div>
        );
      })}
    </ResourceComposeStyle>
  );
};

export const ResourceWysiCompose = () => {
  const { t } = useTranslation();

  const { items, onCompChange } = useResourceContext();
  const { modifiedDate, errors } = useSelector(
    (state) => state.composeResource
  );

  return (
    <ResourceComposeStyle className={ComponentClassName.question_class}>
      {items.map((comp, index) => {
        const err = errors != null ? errors[comp.id] : null;

        return (
          <div
            className={ComponentClassName.component_class}
            key={comp.id + '_' + index + '_' + modifiedDate}
          >
            <ComponentItem
              item={comp.comps}
              answer={comp.answer}
              feedback={comp.feedBack}
              onChange={onCompChange}
            />

            {err && <div className={'content-error'}>{t(err as any)}</div>}
          </div>
        );
      })}
    </ResourceComposeStyle>
  );
};

const ResourceComposeStyle = styled.div`
  .${ComponentClassName.component_class} {
    &:not(:first-child) {
      margin-top: var(--cms-padding-component, 0.5em);
    }
  }

  .content-error {
    font-size: 66%;
    color: ${(props) => props.theme.warning.error};
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
`;
