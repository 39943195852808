import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const original_width = 24;
  const original_height = 24;

  const width = props.height
    ? (parseInt(props.height + '') * original_width) / original_height
    : original_width;
  const height = props.height ? props.height : original_height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        stroke={'#ff9800'}
        d="M10.5,17a2,2,0,0,1-1.416-.586L5.3,12.715a1,1,0,0,1,1.4-1.43l3.793,3.707,6.806-6.7a1,1,0,0,1,1.4,1.424l-6.793,6.707A1.983,1.983,0,0,1,10.5,17ZM24,19V12.34A12.209,12.209,0,0,0,12.836.028,12,12,0,0,0,.029,12.854C.471,19.208,6.082,24,13.083,24H19A5.006,5.006,0,0,0,24,19ZM12.7,2.024A10.2,10.2,0,0,1,22,12.34V19a3,3,0,0,1-3,3H13.083C7.049,22,2.4,18.1,2.025,12.716A10,10,0,0,1,12.016,2C12.243,2,12.472,2.009,12.7,2.024Z"
      ></path>
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
