import { LessonAPI } from '@modules/product/services/LessonAPI';
import React, { useEffect, useState } from 'react';

import ResourceStandardDisplay from '@modules/product/components/lesson/lesson-data/modal/ResourceStandardDisplay';
import { List } from 'antd';
import { H2 } from '@components/typography';
import { ResourceRes } from '@modules/product/services/resource_model';

interface LessonStandardItemProps {
  questionNumber: number;
  item: ResourceRes;
}

export default function LessonStandard(props: {
  lessonId: number;
  onCancel: () => void;
  onSuccess?: () => void;
}) {
  const [getResourceStandards, { data, isSuccess }] =
    LessonAPI.endpoints.getResourceStandards.useLazyQuery({});

  const [resources, setResources] = useState<LessonStandardItemProps[]>([]);
  const [selectedResource, setSelectedResource] = useState(-1);

  useEffect(() => {
    refreshStandards();
  }, [props.lessonId]);

  useEffect(() => {
    if (data && isSuccess) {
      let questionNumber = 0;
      const _resourceList: LessonStandardItemProps[] = [];

      data.resources.forEach((rs) => {
        if (rs.validation != null && rs.validation.interact) {
          questionNumber += 1;

          _resourceList.push({
            questionNumber: questionNumber,
            item: rs,
          });
        }
      });

      setResources(_resourceList);
    }
  }, [data]);

  const refreshStandards = () => {
    getResourceStandards(props.lessonId);
  };

  return (
    <div className={'update-lesson-standard'}>
      {data && isSuccess && (
        <div className={'lesson-info'}>
          <H2>{data.lesson.name}</H2>

          <div className={'resources'}>
            <List
              bordered
              dataSource={resources}
              renderItem={(rs) => (
                <List.Item>
                  <ResourceStandardDisplay
                    key={rs.item.resourceId + '_' + rs.item.modifiedDate}
                    item={rs.item}
                    questionNumber={rs.questionNumber}
                    selectedResource={selectedResource}
                    setSelectedResource={setSelectedResource}
                    reloadLessonStandard={refreshStandards}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
