import React from 'react';

import { useRouter } from '@hooks/useRouter';
import AssignmentReportPage from '@modules/reports/assignments/AssignmentReportPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const ViewAssignmentReport = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { assignmentInstanceId } = router.query as unknown as {
    assignmentInstanceId: number;
  };

  return (
    <ManagementLayout className={'view-assignment-report'}>
      <PageHeader
        menu={NavigationMenuEnum.REPORTS}
        subMenu={NavigationMenuEnum.REPORTS_EXERCISES}
        title={t('report.view_lesson_record')}
        description={t('report.view_lesson_record')}
      />
      <AssignmentReportPage assignmentInstanceId={assignmentInstanceId} />
    </ManagementLayout>
  );
};

export default ViewAssignmentReport;
