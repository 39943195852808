import React, { useEffect, useState } from 'react';
// import DocViewer from 'react-doc-viewer';
import NoSsr from '@components/Layout/NoSsr';

const DocViewerComp = (props: { title?: string; fileUrl: string }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <div className={'epub-viewer-component'}>
      {ready && (
        <NoSsr>
          <p>{props.fileUrl}</p>
        </NoSsr>
      )}
    </div>
  );
};

export default DocViewerComp;
