import React from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { SchoolUserSettingForm } from '../school-setting/SchoolUserSettingForm';
import { SchoolRegisterSettingForm } from '../school-setting/SchoolRegisterSettingForm';
import { SchoolLanguageSettingForm } from '@modules/setting/container/school-setting/SchoolLanguageSettingForm';
import { SchoolContentSettingForm } from '@modules/setting/container/school-setting/SchoolContentSettingForm';
import { SchoolRoleAndRegistration } from '@modules/setting/container/school-setting/SchoolRoleAndRegistration';
import Card, { CardClassEnum } from '@components/card';
import {
  ResponsiveTabs,
  ResponsiveTabsClass,
} from '@components/tabs/ResponsiveTabs';
import {
  KeyOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

enum SchoolSettingTab {
  register = 'register',
  permission = 'permission',
  language = 'language',
  users = 'users',
  content = 'content',
}

const SchoolSettingForm = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();

  const onSuccess = () => {
    notification.success({
      message: t('school_setting.warning.update_success'),
      description: t('school_setting.warning.update_success_message'),
      placement: 'bottomRight',
    });
    props.onSuccess();
  };

  const onError = () => {
    notification.error({
      message: t('school_setting.warning.update_error'),
      description: t('school_setting.warning.update_error_message'),
      placement: 'bottomRight',
    });
  };

  return (
    <Card className={CardClassEnum.rectangle_box}>
      <ResponsiveTabs
        className={ResponsiveTabsClass.left_panel_align_right}
        activeKey={SchoolSettingTab.register}
        items={[
          {
            key: SchoolSettingTab.register,
            icon: <UserOutlined />,
            label: 'Register',
            children: (
              <SchoolRegisterSettingForm
                schoolId={props.schoolId}
                onSuccess={onSuccess}
                onError={onError}
                onCancel={props.onCancel}
              />
            ),
          },
          {
            key: SchoolSettingTab.permission,
            icon: <KeyOutlined />,
            label: 'Teacher Permissions',
            children: (
              <SchoolRoleAndRegistration
                schoolId={props.schoolId}
                onSuccess={onSuccess}
                onCancel={props.onCancel}
              />
            ),
          },

          {
            key: SchoolSettingTab.language,
            icon: <VideoCameraOutlined />,
            label: 'Language',
            children: (
              <SchoolLanguageSettingForm
                schoolId={props.schoolId}
                onSuccess={onSuccess}
                onError={onError}
                onCancel={props.onCancel}
              />
            ),
          },
          {
            key: SchoolSettingTab.users,
            icon: <UserOutlined />,
            label: 'Users',
            children: (
              <SchoolUserSettingForm
                schoolId={props.schoolId}
                onSuccess={onSuccess}
                onError={onError}
                onCancel={props.onCancel}
              />
            ),
          },

          {
            key: SchoolSettingTab.content,
            icon: <UserOutlined />,
            label: 'Content',
            children: (
              <SchoolContentSettingForm
                schoolId={props.schoolId}
                onSuccess={onSuccess}
                onError={onError}
                onCancel={props.onCancel}
              />
            ),
          },
        ]}
      />
    </Card>
  );
};

export default SchoolSettingForm;
