export enum InvitationTypeEnum {
  SUBSCRIBLE = 0,
  CLASS = 1,
  COURSE = 2,
  SESSION = 3,
  SHARE_LESSON = 4,
  SHARE_PRODUCT = 5,
  ASSIGN_EXERCISE = 6,
}

export enum EntityRequestEnum {
  PENDING = 0,
  ACCEPT = 1,
  REJECT = 2,
  REMOVED = 3,
  ACCEPT_N_REMOVED = 4,
  REJECT_N_REMOVED = 5,
}

export const ScreenSize = {
  A4Size: {
    width: 8.3,
    height: 11.7,
  },

  4_3: {
    width: 4,
    height: 3,
  },

  16_9: {
    width: 16,
    height: 9,
  },

  1_1: {
    width: 1,
    height: 1,
  },
};
