import { createSlice } from '@reduxjs/toolkit';
import {
  MyProfileRes,
  PublicUserProfile,
} from '@services/private/MyProfileAPI';
import { UserInfoRes } from '@modules/users/services/model';

interface UserInformationProps extends PublicUserProfile {
  isReady: boolean;
  isLogin: boolean;
  proUser: boolean;
  students: UserInfoRes[];
}

const displayName = ['Cáo', 'Cóc', 'Chồn', 'Nhím', 'Mèo'];
const currentTime = new Date().getTime();

const initialState: UserInformationProps = {
  isLogin: false,
  isReady: false,

  userId: currentTime,
  uuid: '',

  username: '',
  firstName: '',
  lastName: '',

  email: '',
  phone: '',

  avatar: '',
  shortName: '',

  displayName: displayName[currentTime % displayName.length] + '(Ẩn Danh)',

  school: null,
  schools: [],
  students: [],

  proUser: false as boolean,
};

const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,

  reducers: {
    updateUserInfo: (state, action: { payload: MyProfileRes }) => {
      Object.assign(state, action.payload);

      if (action.payload.schools && action.payload.schools.length > 0) {
        state.schools = action.payload.schools;
      }
      state.school = action.payload.school;
      state.isReady = true;
      state.isLogin = true;
    },

    updateAnonymousUser: (
      state,
      action: {
        payload: {
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
        };
      }
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.isLogin = false;
    },

    updateReady: (state) => {
      state.isReady = true;
      state.isLogin = true;
    },

    updateCurrentSchool: (state, action) => {
      state.school = action.payload;
    },

    updateProUser: (state, action: { payload: boolean }) => {
      state.proUser = action.payload;
    },
  },
});

const { actions, reducer } = userInformationSlice;

export const {
  updateUserInfo,
  updateReady,
  updateCurrentSchool,
  updateProUser,
  updateAnonymousUser,
} = actions;

export default reducer;
