import { CompProps } from '@cms/ComponentInteface';
import React, { useRef } from 'react';
import { Tooltip } from '@components/tooltip/Tooltip';
import { Button, Space } from 'antd';
import { ComponentSetting } from '@cms/ComponentSetting';
import styled from 'styled-components';
import {
  cloneComponent,
  ComposeCompActionEnum,
  dispatchComposeAction,
  moveComponent,
  removeComponent,
  updateComponentSetting,
} from '@app/redux/slices/composeResource';

import { useTranslation } from 'react-i18next';

import { H4 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { useSelector, useDispatch } from '@app/redux/hook';

export default function ComponentItemToolbar(props: {
  item: CompProps;
  disabledPrevious: boolean;
  disabledNext: boolean;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { action } = useSelector((state) => state.composeResource);

  const ref = useRef(null);

  const moveComponentUp = () => {
    dispatch(moveComponent({ comp: props.item, moveUp: true }));
  };

  const moveComponentDown = () => {
    dispatch(moveComponent({ comp: props.item, moveUp: false }));
  };

  const onCloneComponent = () => {
    dispatch(cloneComponent(props.item));
  };

  const onRemoveComponent = () => {
    dispatch(removeComponent(props.item));
  };

  const onUpdateComponentSetting = (setting: any) => {
    const dif = JSON.stringify(setting) !== JSON.stringify(props.item.setting);

    if (dif) {
      dispatch(updateComponentSetting({ comp: props.item, setting: setting }));
    }
  };

  const showComponentSetting = () => {
    dispatch(
      dispatchComposeAction({
        action: ComposeCompActionEnum.show_setting,
        params: props.item.id,
      })
    );
  };

  const closeAllAction = () => {
    dispatch(
      dispatchComposeAction({
        action: ComposeCompActionEnum.default,
        params: props.item.id,
      })
    );
  };

  return (
    <ComponentEditableToolbarStyle className={'component-toolbar'}>
      <div className={'component-name'}>{props.item.type}</div>

      <div className={'component-toolbar-actions'}>
        <Space size={8}>
          <Tooltip title={t('component.actions.move_content_up')}>
            <Button
              className={'move-up-btn'}
              shape={'circle'}
              size={'small'}
              disabled={props.disabledPrevious}
              onClick={moveComponentUp}
              icon={IconUtils.compose.move_up}
            />
          </Tooltip>

          <Tooltip title={t('component.actions.move_content_down')}>
            <Button
              size={'small'}
              className={'move-down-btn'}
              shape={'circle'}
              disabled={props.disabledNext}
              onClick={moveComponentDown}
              icon={IconUtils.compose.move_down}
            />
          </Tooltip>

          <Tooltip title={t('component.actions.clone_component')}>
            <Button
              size={'small'}
              className={'clone-btn'}
              shape={'circle'}
              onClick={onCloneComponent}
              icon={IconUtils.compose.clone}
            />
          </Tooltip>

          <Tooltip title={t('component.actions.delete_component')}>
            <Button
              size={'small'}
              className={'delete-btn'}
              shape={'circle'}
              onClick={onRemoveComponent}
              icon={IconUtils.compose.delete}
              danger
              type={'primary'}
            />
          </Tooltip>

          <Button
            size={'small'}
            className={'setting-btn'}
            shape={'circle'}
            ref={ref}
            onClick={showComponentSetting}
            icon={IconUtils.compose.setting}
          >
            {action.action === ComposeCompActionEnum.show_setting &&
              action.params === props.item.id && (
                <ComponentToolbarStyle className={'toolbar-with-popup-inside'}>
                  <div className={'toolbar-header'}>
                    <H4>{t('component.actions.adjust_component_layout')}</H4>
                  </div>

                  <div className={'toolbar-content'}>
                    <ComponentSetting
                      item={props.item}
                      onChange={(setting) => onUpdateComponentSetting(setting)}
                      onCancel={closeAllAction}
                    />
                  </div>
                </ComponentToolbarStyle>
              )}
          </Button>
        </Space>
      </div>
    </ComponentEditableToolbarStyle>
  );
}

const ComponentToolbarStyle = styled.div`
  position: absolute;
  top: 0%;
  left: -10px;
  transform: translate(-100%, 0%);
  background: #fff;
  text-align: left;
  z-index: 999;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  width: 500px;

  .toolbar-header {
    padding: 0.5em 1.5em;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.25em;

    .ant-typography {
      margin-bottom: 0px;
    }
  }

  .toolbar-content {
    padding: 1em 1.5em;
  }
`;

const ComponentEditableToolbarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #fff;

  .has-error & {
    background: ${(props) => props.theme.warning.error_bgr};

    &:before {
      border-color: transparent transparent
        ${(props) => props.theme.warning.error} transparent;
    }
  }

  .component-name {
    font-size: 75%;
    min-width: 20em;
  }

  &:before {
    //content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 36px 36px;
    border-color: transparent transparent
      ${(props) => props.theme.warning.error_bgr} transparent;

    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(-100%, 0%);
  }
`;
