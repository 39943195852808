import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  ExerciseRes,
  SavePracticeAnswerReq,
} from '@modules/assignments/service/exercise_model';

export const GlossaryExerciseAPI = createApi({
  reducerPath: 'GlossaryExerciseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    saveStudentAnswer: builder.mutation<ExerciseRes, SavePracticeAnswerReq>({
      query: (body) => ({
        url: '/glossary-exercise/save',
        method: 'POST',
        body,
      }),
    }),
  }),
});
