import { useEffect } from 'react';

const library_ready_class = 'library-is-ready';

const useScript = (
  url: string,
  id: string,
  onLoaded: (url: string) => void
) => {
  useEffect(() => {
    const targetScript = document.getElementById(id);

    if (targetScript) {
      checkScriptLoaded(targetScript);
    } else {
      const script = document.createElement('script');
      script.id = id;
      script.src = url;
      script.onload = () => {
        script.classList.add(library_ready_class);
        onLoaded(url);
      };

      script.async = false;
      document.body.appendChild(script);
    }
  }, [url]);

  const checkScriptLoaded = (script: any) => {
    const loaded = script.className === library_ready_class;
    if (loaded) {
      onLoaded(url);
    } else {
      setTimeout(() => {
        checkScriptLoaded(script);
      }, 500);
    }
  };
};

export default useScript;
