import { useTranslation } from 'react-i18next';
import React from 'react';
import { Tabs } from 'antd';
import LoginForm from '@modules/authorization/container/LoginForm';
import RegisterUserForm from '@modules/authorization/container/RegisterStudentForm';
import styled from 'styled-components';
import { useSelector } from '@app/redux/hook';
import Card, { CardClassEnum } from '@components/card';

const { TabPane } = Tabs;

export const RequireLoginForm = (props: { loginSuccess: () => void }) => {
  const { t } = useTranslation();

  const { allowStudentRegister } = useSelector((state) => state.appSetting);

  return (
    <RequireLoginFormStyle className={'require-login-form'}>
      <p>{t('login.warning.require')}</p>

      <p>{t('login.warning.register')}</p>

      <div className={'login-register-tab'}>
        <Card className={CardClassEnum.rectangle_box}>
          <Tabs defaultActiveKey="1">
            <TabPane tab={t('header.login')} key="login">
              <LoginForm
                successHandle={props.loginSuccess}
                hideCancel={true}
                hideForgotPassword={true}
              />
            </TabPane>

            <TabPane tab={t('header.student_registration')} key="register">
              <RegisterUserForm
                disabled={!allowStudentRegister}
                successHandle={props.loginSuccess}
                hideCancel={true}
                autoLogin={true}
              />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </RequireLoginFormStyle>
  );
};

const RequireLoginFormStyle = styled.div`
  .login-register-tab {
    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        width: 50%;
        justify-content: center;
      }
    }

    .button-group {
      text-align: center;
    }
  }
`;
