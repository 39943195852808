import { Button } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BookOutlined,
  CopyOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LoginOutlined,
  TeamOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Menu } from 'antd';
import { showHideMenu, toggleMenu } from '@app/redux/slices/appLayoutSlice';
import useMediaQuery, { ResponsiveScreen } from '@hooks/useMedia';
import i18next from 'i18next';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { Responsive } from '@components/responsive/Responsive';
import NavLink from '@components/button/NavLink';
import { useDispatch, useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';

const PublicNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screen = useMediaQuery();

  const { collapsed } = useSelector((state) => state.appLayout);

  const { isAuth, authorities } = useSelector((state) => state.profile);
  const { menu, submenu } = useSelector((state) => state.appState);

  const selectedKeys = useMemo(() => {
    return [...menu, ...submenu];
  }, [menu, submenu]);

  useEffect(() => {
    dispatch(showHideMenu(false));
  }, [screen]);

  const toggleCollapsed = () => {
    dispatch(toggleMenu());
  };

  const menuItem = useMemo(() => {
    const menuItemsProps = [
      {
        key: NavigationMenuEnum.PUBLIC_PRODUCTS,
        onClick: toggleCollapsed,
        label: (
          <NavLink href={SiteMap.public.products}>
            <BookOutlined /> {t('menu.products')}
          </NavLink>
        ),
      },
      {
        key: NavigationMenuEnum.PUBLIC_LESSONS,
        onClick: toggleCollapsed,
        label: (
          <NavLink href={SiteMap.public.lessons}>
            <CopyOutlined /> {t('menu.lessons')}
          </NavLink>
        ),
      },
      {
        key: NavigationMenuEnum.PUBLIC_TEACHERS,
        onClick: toggleCollapsed,
        label: (
          <NavLink href={SiteMap.public.teachers}>
            <TeamOutlined /> {t('menu.teacher')}
          </NavLink>
        ),
      },

      // {
      //   key: NavigationMenuEnum.PUBLIC_COURSE,
      //   onClick: toggleCollapsed,
      //   label: (
      //     <NavLink href={SiteMap.public.courses}>
      //       <CopyOutlined /> {t('menu.course')}
      //     </NavLink>
      //   ),
      // },
    ];

    if (isAuth) {
      menuItemsProps.push({
        key: NavigationMenuEnum.DASHBOARD,
        onClick: toggleCollapsed,
        label: (
          <NavLink href={SiteMap.private.dashboard}>
            <DashboardOutlined /> {t('menu.dashboard')}
          </NavLink>
        ),
      });
    } else {
      menuItemsProps.push({
        key: NavigationMenuEnum.PUBLIC_REGISTER,
        onClick: toggleCollapsed,
        label: (
          <NavLink href={SiteMap.auth.login}>
            <LoginOutlined /> {t('menu.login')}
          </NavLink>
        ),
      });
    }
    return menuItemsProps;
  }, [isAuth, authorities, i18next.language]);

  return (
    <PublicNavigationStyle className={'header-navigation'}>
      <Responsive
        screens={[
          ResponsiveScreen.xs,
          ResponsiveScreen.sm,
          ResponsiveScreen.md,
        ]}
      >
        <Button type="default" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>

        {collapsed && (
          <Menu
            mode="inline"
            className={'menu-toggle menu-toggle-mobile'}
            openKeys={menu}
            selectedKeys={selectedKeys}
            items={menuItem}
          />
        )}
      </Responsive>

      <Responsive
        excludeScreens={[
          ResponsiveScreen.xs,
          ResponsiveScreen.sm,
          ResponsiveScreen.md,
        ]}
      >
        <Menu
          mode="horizontal"
          className={'menu-toggle'}
          openKeys={menu}
          selectedKeys={selectedKeys}
          items={menuItem}
        />
      </Responsive>
    </PublicNavigationStyle>
  );
};

export default PublicNavigation;

const PublicNavigationStyle = styled.div`
  .ant-menu.menu-toggle {
    background: transparent;
    border-bottom: none;
    color: ${(props) => props.theme.color.white};

    &.menu-toggle-mobile {
      position: absolute;
      left: 0px;
      top: 100%;
      max-width: calc(100vw - 20px);
      width: 250px;
      box-shadow: 0px 0px 5px 0px #ccc;
      z-index: 2;
      background: ${(props) => props.theme.color.white};
      white-space: nowrap;
      color: ${(props) => props.theme.color.black};

      .ant-menu-item {
        a {
          color: ${(props) => props.theme.color.black};
        }

        &.ant-menu-item-selected {
          color: ${(props) => props.theme.color.black} !important;
          font-weight: bold;
        }
      }
    }

    &:after,
    &:before {
      display: none;
    }

    .ant-menu-item {
      padding: 0px 10px;

      a {
        color: ${(props) => props.theme.color.white};
      }

      &.ant-menu-item-selected {
        color: ${(props) => props.theme.color.white} !important;
        font-weight: bold;
      }

      &:after {
        display: none;
      }

      &.active {
        font-weight: bold;

        .anticon {
          font-size: 120%;
        }
      }
    }

    .ant-menu-overflow-item-rest {
      display: none;
    }
  }
`;
