import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { LongDivisionCompProps } from '@cms/comps/math/operation/division/LongDivisionComp';
import { LongOperationLayoutEnum } from '@cms/comps/math/operation/OperationUtils';

export const LongDivisionExampleData: LongDivisionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.LONG_DIVISION,

  configuration: {
    dividend: '125',
    divisor: '3',
    expression: [
      {
        type: 'quotient',
        exp: ['_', '_', 'x', 'x', 'R', 'x'],
      },
      {
        type: 'divisor',
        exp: ['3', '/', '1', '2', '5', '_'],
      },
      {
        type: 'division-subtract',
        exp: ['_', '-', '1', '2', '_', '_'],
      },
      {
        type: 'division-remain',
        exp: ['_', '_', '_', 'x', 'x', '_'],
      },
      {
        type: 'division-subtract',
        exp: ['_', '_', '_', '-', 'x', '_'],
      },
      {
        type: 'division-remain',
        exp: ['_', '_', '_', '_', 'x', '_'],
      },
    ],
  },
  setting: {
    autoScore: true,
    removeZero: true,
    layout: LongOperationLayoutEnum.vertical,
  },
};
