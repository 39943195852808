import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';
import { CompMode } from '@cms/ComponentInteface';
import { useTranslation } from 'react-i18next';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { IconUtils } from '@utils/IconUtils';
import { useTheme } from '@app/styled/StyledProvider';

export const SaveExerciseButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { type, dispatchExerciseAction } = useViewLessonContext();

  return (
    <>
      {(type === CompMode.DO_EXERCISE || type === CompMode.DO_ASSIGNMENT) && (
        <LessonButton
          className={'save-exercise-btn'}
          type={'primary'}
          shape={'round'}
          icon={IconUtils.exercise.save}
          style={{ background: theme.exercise.save }}
          onClick={() =>
            dispatchExerciseAction(ExerciseActionEnum.request_save, true)
          }
        >
          {t('exercise.button.save')}
        </LessonButton>
      )}
    </>
  );
};
