import { useTranslation } from 'react-i18next';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProductTocAPI } from '@modules/product/services/ProductTocAPI';
import { notification } from 'antd';
import useConfirmModal from '@components/modal/ConfirmModal';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import {
  CreateProductTocReq,
  ProductRes,
  ProductTocRes,
  RemoveLessonFromTocReq,
} from '@modules/product/services/product_model';

export enum TableOfContentAction {
  default = '',
  refresh = 'refresh',

  select_unit = 'select-unit',

  add_unit = 'add_unit',
  update_unit = 'update-unit',
  insert_unit_below = 'insert-unit-below',
  remove_unit = 'remove-unit',

  move_up = 'move-up',
  move_down = 'move-down',

  insert_lesson_bellow = 'insert-lesson-bellow',
  add_lesson_to_unit = 'add-lesson-to-unit',
  remove_lesson_from_unit = 'remove_lesson_from_unit',

  move_lesson_up = 'move-lesson-up',
  move_lesson_down = 'move-lesson-down',

  view_lesson = 'view-lesson',
  view_lesson_standard = 'view-lesson-standard',
}

export interface ComposeTocActionProps {
  action: TableOfContentAction;
  params: any;
}

const ComposeTocContext = createContext({
  loading: false as boolean,
  productId: -1 as number,
  product: null as ProductRes | null,
  selectTocId: -1 as number,
  productTocs: [] as ProductTocRes[],
  selectedLessonIds: [] as number[],
  action: {} as ComposeTocActionProps,
  dispatchAction: (action: TableOfContentAction, params: any) => {
    console.log(action, params);
  },
});

export const ComposeTocProvider = (props: {
  productId: number;
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [getTableOfContent, { data, isSuccess, isLoading }] =
    ProductAPI.endpoints.getTableOfContent.useLazyQuery({});

  const [createProductToc] =
    ProductTocAPI.endpoints.createProductToc.useMutation({});
  const [insertProductToc] =
    ProductTocAPI.endpoints.insertProductToc.useMutation({});
  const [updateProductToc] =
    ProductTocAPI.endpoints.updateProductToc.useMutation({});
  const [removeProductToc] =
    ProductTocAPI.endpoints.removeProductToc.useMutation({});
  const [removeLessonFromTOC] =
    ProductTocAPI.endpoints.removeLessonFromTOC.useMutation({});

  const [addLessonToTOC] = ProductTocAPI.endpoints.addLessonToTOC.useMutation(
    {}
  );

  const [moveProductTocUp] =
    ProductTocAPI.endpoints.moveProductTocUp.useMutation({});
  const [moveProductTocDown] =
    ProductTocAPI.endpoints.moveProductTocDown.useMutation({});

  const [moveProductTocLessonUp] =
    ProductTocAPI.endpoints.moveLessonUp.useMutation({});
  const [moveProductTocLessonDown] =
    ProductTocAPI.endpoints.moveLessonDown.useMutation({});

  const [action, setAction] = useState({
    action: TableOfContentAction.default,
    params: -1 as any,
  });

  const [selectTocId, setSelectTocId] = useState(-1);
  const [product, setProduct] = useState<ProductRes | null>(null);
  const [productTocs, setProductTocs] = useState<ProductTocRes[]>([]);
  const [selectedLessonIds, setSelectedLessonIds] = useState<number[]>([]);

  useEffect(() => {
    reloadData();
  }, [props.productId]);

  useEffect(() => {
    if (isSuccess && data) {
      setProduct(data.product);
      setProductTocs(data.tableOfContent);

      const lessonIds: number[] = [];
      data.tableOfContent.forEach((unit) => {
        unit.lessons.forEach((ls) => {
          lessonIds.push(ls.lessonId);
        });
      });

      setSelectedLessonIds(lessonIds);
    }
  }, [data]);

  useEffect(() => {
    // click add a unit button
    if (action.action === TableOfContentAction.add_unit) {
      addUnit();

      // click edit icon on unit header to change to edit mode
    } else if (action.action === TableOfContentAction.select_unit) {
      setSelectedProduct(action.params as number);

      // click save in edit mode
    } else if (action.action === TableOfContentAction.update_unit) {
      updateUnit(action.params.productTocId, action.params.name);

      // click add a uniit on menu dropdown
    } else if (action.action === TableOfContentAction.insert_unit_below) {
      insertUnitBellow(action.params as ProductTocRes);

      // click remove unit
    } else if (action.action === TableOfContentAction.remove_unit) {
      removeUnit(action.params as number);

      // click move up icon
    } else if (action.action === TableOfContentAction.move_up) {
      moveTocUp(action.params as number);

      // click move down icon
    } else if (action.action === TableOfContentAction.move_down) {
      moveTocDown(action.params as number);

      // add new lesson to unit
    } else if (action.action === TableOfContentAction.add_lesson_to_unit) {
      addLessonToUnit(action.params.lessonId, action.params.productTocId);

      //remove lesson in unit
    } else if (action.action === TableOfContentAction.remove_lesson_from_unit) {
      removeLessonFromUnit(
        action.params.lessonId,
        action.params.productTocId,
        action.params.name
      );
    } else if (action.action === TableOfContentAction.move_lesson_up) {
      moveLessonUp(action.params.lessonId, action.params.productTocId);

      // click move down icon
    } else if (action.action === TableOfContentAction.move_lesson_down) {
      moveLessonDown(action.params.lessonId, action.params.productTocId);
    } else if (action.action === TableOfContentAction.refresh) {
      reloadData();
    }
  }, [action]);

  const reloadData = () => {
    getTableOfContent(props.productId);
  };

  const setSelectedProduct = (id: number) => {
    setSelectTocId(id);
  };

  const dispatchAction = (action: TableOfContentAction, params: any) => {
    setAction({
      action: action,
      params: params,
    });
  };

  const addUnit = () => {
    const request: CreateProductTocReq = {
      productTocId: -1,
      name: t('table_of_content.label', { unit: productTocs.length + 1 }),
      displayOrder: productTocs.length + 1,
      productId: props.productId,
    };

    createProductToc(request)
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const insertUnitBellow = (item: ProductTocRes) => {
    const request: CreateProductTocReq = {
      productTocId: item.productTocId,
      name: item.name + '(1)',
      displayOrder: item.displayOrder + 1,
      productId: props.productId,
    };

    insertProductToc(request)
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const updateUnit = (productTocId: number, name: string) => {
    const request = {
      productTocId: productTocId,
      productId: props.productId,
      name: name,
    };

    updateProductToc(request)
      .unwrap()
      .then(() => {
        reloadData();
        setSelectTocId(-1);
        warningMessage(true);
      })
      .catch(() => {
        setSelectTocId(-1);
        warningMessage(false);
      });
  };

  const removeUnit = (productTocId: number) => {
    confirm(
      'danger',
      t('table_of_content.actions.remove'),
      t('table_of_content.warning.remove'),
      t('label.remove'),
      t('label.cancel'),
      (r) => {
        if (r) {
          removeProductToc({
            productId: props.productId,
            productTocId: productTocId,
          })
            .unwrap()
            .then(() => {
              reloadData();
              notification.success({
                message: t('table_of_content.warning.remove_success'),
                description: t(
                  'table_of_content.warning.remove_success_message'
                ),
                placement: 'bottomRight',
              });
            })
            .catch(() => {
              notification.error({
                message: t('table_of_content.warning.remove_error'),
                description: t('table_of_content.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const moveTocUp = (productTocId: number) => {
    moveProductTocUp({
      productId: props.productId,
      productTocId: productTocId,
    })
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const moveTocDown = (productTocId: number) => {
    moveProductTocDown({
      productId: props.productId,
      productTocId: productTocId,
    })
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const moveLessonUp = (lessonId: number, productTocId: number) => {
    moveProductTocLessonUp({
      productId: props.productId,
      productTocId: productTocId,
      lessonId: lessonId,
    })
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const moveLessonDown = (lessonId: number, productTocId: number) => {
    moveProductTocLessonDown({
      productId: props.productId,
      productTocId: productTocId,
      lessonId: lessonId,
    })
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const addLessonToUnit = (lessonId: number, productTocId: number) => {
    const request = {
      productId: props.productId,
      productTocId: productTocId,
      lessonId: lessonId,
    };

    addLessonToTOC(request)
      .unwrap()
      .then(() => {
        reloadData();
        warningMessage(true);
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const removeLessonFromUnit = (
    lessonId: number,
    productTocId: number,
    name: string
  ) => {
    confirm(
      'danger',
      t('table_of_content.actions.remove_lesson'),
      <p
        dangerouslySetInnerHTML={{
          __html: t('table_of_content.warning.remove_lesson', {
            unitName: name,
          }),
        }}
      />,
      t('label.remove'),
      t('label.cancel'),
      (r) => {
        if (r) {
          const request: RemoveLessonFromTocReq = {
            lessonId: lessonId,
            productTocId: productTocId,
            productId: props.productId,
          };

          removeLessonFromTOC(request)
            .unwrap()
            .then(() => {
              reloadData();

              notification.success({
                message: t('table_of_content.warning.remove_lesson_success'),
                description: t(
                  'table_of_content.warning.remove_lesson_success_message'
                ),
                placement: 'bottomRight',
              });
            })
            .catch(() => {
              notification.error({
                message: t('table_of_content.warning.remove_lesson_error'),
                description: t(
                  'table_of_content.warning.remove_lesson_error_message'
                ),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const warningMessage = (isSuccess: boolean) => {
    if (isSuccess) {
      notification.success({
        message: t('table_of_content.warning.update_success'),
        description: t('table_of_content.warning.update_success_message'),
        placement: 'bottomRight',
      });
    } else {
      notification.error({
        message: t('table_of_content.warning.update_error'),
        description: t('table_of_content.warning.update_error_message'),
        placement: 'bottomRight',
      });
    }
  };

  return (
    <ComposeTocContext.Provider
      value={{
        loading: isLoading,
        productId: props.productId,
        product,
        selectTocId,
        productTocs,
        selectedLessonIds,
        action: action,
        dispatchAction,
      }}
    >
      {props.children}
    </ComposeTocContext.Provider>
  );
};

export const useComposeTocContext = () => {
  const context = useContext(ComposeTocContext);
  if (!context) {
    throw new Error('You must wrap container by ComposeTocProvider');
  }
  return context;
};
