import React, { useEffect } from 'react';
import { SocialVideoPlayerProps } from '@cms/comps/presentation/social-video/SocialVideoComp';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { InputGroup } from '@components/input/InputGroup';
import { Button, Select, Space } from 'antd';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import ButtonGroup from '@components/button/ButtonGroup';
import styled from 'styled-components';
import Input from '@components/input/input';
import { SocialMediaPlayerReview } from '@cms/comps/presentation/social-video/SocialMediaPlayeWorkspacer';

export const SocialMediaEditForm = (props: {
  item: SocialVideoPlayerProps | null;
  onCancel: () => void;
  onSubmit: (newAns: SocialVideoPlayerProps, type: 'create' | 'update') => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.item ?? {
      id: COMPONENT_UTILS.generateUID(),
      name: '',
      scale: 9 / 16,
      type: 'youtube' as 'youtube' | 'facebook' | 'twister',
      src: '',
    },
    onSubmit: (values) => {
      props.onSubmit(
        values as SocialVideoPlayerProps,
        props.item != null ? 'update' : 'create'
      );
    },
  });

  useEffect(() => {
    if (props.item != null) {
      formik.setValues({
        id: props.item.id,
        name: props.item.name,
        scale: props.item.scale,
        type: props.item.type,
        src: props.item.src,
      });
    } else {
      formik.setValues({
        id: COMPONENT_UTILS.generateUID(),
        name: '',
        scale: 9 / 16,
        type: 'youtube' as 'youtube' | 'facebook' | 'twister',
        src: '',
      });
    }
  }, [props.item]);

  return (
    <FormikProvider value={formik}>
      <SocialMediaFormStyle>
        <div className={'social-media-review-container'}>
          <SocialMediaPlayerReview
            type={formik.values.type}
            scale={formik.values.scale}
            src={formik.values.src}
          />
        </div>

        <div className={'social-media-form'}>
          <Input
            required
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <InputGroup label={'Media Type'} required>
            <Select
              size={'large'}
              placeholder={'Media Type'}
              onChange={(value) => formik.setFieldValue('type', value)}
              value={formik.values.type}
            >
              <Select.Option key={'youtube'} value={'youtube'}>
                Youtube
              </Select.Option>

              <Select.Option key={'facebook'} value={'facebook'}>
                Facebook
              </Select.Option>
            </Select>
          </InputGroup>

          <InputGroup label={'Scale'} required>
            <div className={'scale-box'}>
              <div
                className={`scale-box-item box-16-9 
              ${formik.values.scale === 9 / 16 ? 'active' : ''}
              `}
                onClick={() => formik.setFieldValue('scale', 9 / 16)}
              >
                16 x 9
              </div>
              <div
                className={`scale-box-item box-4-3
              ${formik.values.scale === 3 / 4 ? 'active' : ''}
              `}
                onClick={() => formik.setFieldValue('scale', 3 / 4)}
              >
                4 x 3
              </div>
              <div
                className={`scale-box-item box-21-9
                ${formik.values.scale === 9 / 21 ? 'active' : ''}
              `}
                onClick={() => formik.setFieldValue('scale', 9 / 21)}
              >
                21 x 9
              </div>
            </div>
          </InputGroup>

          <Input
            required
            name="src"
            type="text"
            label={'URL'}
            placeholder={'URL'}
            onChange={formik.handleChange}
            value={formik.values.src}
          />

          <ButtonGroup type={'right'}>
            <Space>
              <Button onClick={props.onCancel}>Close</Button>

              <Button type={'primary'} onClick={formik.submitForm}>
                {props.item ? (
                  <> {t('button.update')}</>
                ) : (
                  <> {t('button.create')}</>
                )}
              </Button>
            </Space>
          </ButtonGroup>
        </div>
      </SocialMediaFormStyle>
    </FormikProvider>
  );
};

const SocialMediaFormStyle = styled.div`
  display: flex;
  padding: 1em;
  width: 100%;
  font-size: 16px;

  .social-media-form {
    width: 35%;
    max-width: 350px;
  }

  .social-media-review-container {
    flex-grow: 1;
    margin-right: 1em;
    padding-right: 1em;
    border-right: 1px solid #ccc;
  }

  .scale-box {
    display: flex;
    flex-wrap: wrap;

    .scale-box-item {
      height: 50px;
      border: 1px solid #333;
      background: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 0.5em;
      }

      &.active {
        border: 1px solid ${(props) => props.theme.component.primary};
        background: ${(props) => props.theme.component.primary_bgr};
      }

      &.box-16-9 {
        width: ${(16 / 9) * 50}px;
      }

      &.box-4-3 {
        width: ${(4 / 3) * 50}px;
      }

      &.box-21-9 {
        width: ${(21 / 9) * 50}px;
      }
    }
  }
`;
