import { DropItem } from '@components/react-dnd-bt/DropItem';
import React, { ReactNode, useState } from 'react';
import { useSectionComposeContext } from '@cms/lesson-template/compose/sections/components/SectionCompose';
import { CompMode } from '@cms/ComponentInteface';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import {
  ResourceItemComposeViewer,
  ResourceItemViewer,
} from '@cms/context/ResourceContextProvider';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import styled from 'styled-components';
import Button from '@components/button';
import { NavLinkNewTab } from '@components/button/NavLink';
import { useLessonEditableContext } from '@cms/context/LessonEditableProvider';
import { useProUser } from '@components/button/RequireProUserButton';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';

export const SectionDropColumn = (props: {
  code: string;
  title: string;
  group: string;
  disabled: boolean;
  children: ReactNode;
}) => {
  const [showDropDown, setShowDropDown] = useState(true);
  const { onDropResource, onRemoveResource } = useSectionComposeContext();

  if (props.disabled) {
    return <>{props.children}</>;
  } else {
    return (
      <SectionDropColumnStyle className={'section-dropbox'}>
        <div
          className={'section-dropbox-header'}
          onClick={() => setShowDropDown((prev) => !prev)}
        >
          <div className={'header-section-group-title'}>{props.title}</div>

          <div className={'header-section-group-icon'}>
            {showDropDown
              ? IconUtils.actions.expand
              : IconUtils.actions.collapse}
          </div>
        </div>

        <div
          className={`section-dropbox-content ${
            showDropDown ? 'expand' : 'collapse'
          }`}
        >
          {showDropDown && (
            <DropItem
              droppableId={props.code + '_' + props.group}
              acceptType={'question'}
              className={'droppable-container drop-resource-container'}
              handleOnDrop={(draggableId, index) =>
                onDropResource(Number(draggableId), props.group, index)
              }
              handleOnRemove={(draggableId: string, index: number) =>
                onRemoveResource(Number(draggableId), props.group, index)
              }
            >
              {props.children}
            </DropItem>
          )}
        </div>
      </SectionDropColumnStyle>
    );
  }
};

export const SectionResourceDragItem = (props: {
  mode: CompMode;
  item: ResourceProps;
  index: number;
}) => {
  const proUser = useProUser();
  const { lessonId, productId, productTocId } = useLessonEditableContext();

  if (props.mode === CompMode.COMPOSE) {
    return (
      <SectionResourceDragStyle className={'question-dropbox'}>
        <div className={'question-dropbox-content show-correct-answer'}>
          <DragItem
            acceptType={'question'}
            index={props.index}
            draggableId={props.item.resourceId + ''}
            hidePlaceholder={true}
          >
            <div className={'question-item'}>
              <ResourceItemComposeViewer item={props.item} />

              <Button
                type={'primary'}
                shape={'circle'}
                className={'question-number'}
                size={'small'}
              >
                {props.index + 1}
              </Button>

              <NavLinkNewTab
                className={'compose-resource-link'}
                href={SiteMap.content.lesson.compose_resource_gen(
                  lessonId,
                  proUser,
                  props.item.resourceId,
                  productId,
                  productTocId
                )}
              >
                <Button
                  type={'primary'}
                  size={'small'}
                  shape={'circle'}
                  icon={IconUtils.actions.edit}
                />
              </NavLinkNewTab>
            </div>
          </DragItem>
        </div>
      </SectionResourceDragStyle>
    );
  } else {
    return <ResourceItemViewer item={props.item} />;
  }
};

const SectionResourceDragStyle = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-top: var(--cms-padding-question, 1.5em);
  }

  .section-dropbox-header {
    color: ${(props) => props.theme.component.disabled};
  }

  .question-item {
    position: relative;

    padding: 0.5em 2em 0.5em 0.5em;
    border: 2px dashed ${(props) => props.theme.component.disabled};
    background: rgba(0, 0, 0, 0.03) !important;

    .question ~ .question-number {
      position: absolute;
      top: 5px;
      right: 5px;
      font-weight: bold;
    }

    .question ~ a {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }

    ~ .empty-space {
      display: none;
    }
  }
`;

const SectionDropColumnStyle = styled.div`
  padding: 0.5em 0em;
  height: 100%;

  .section-dropbox-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 0.25em 0;
    color: ${(props) => props.theme.component.disabled};
    cursor: pointer;
  }

  .section-dropbox-content {
    padding: 1.5em;
    background: ${(props) => props.theme.app.background};
    outline: 1px solid ${(props) => props.theme.component.disabled};
  }
`;
