import styled from 'styled-components';
import React, { ReactNode } from 'react';

export const AuthorizeStyle = styled.div`
  font-size: 18px;

  .home-block-container {
    padding: 50px 0px;

    .is-iframe & {
      padding: 10px 0px;
    }
  }

  .container-fruid {
    padding: 25px 0px;
  }
`;

const PublicPageContainerStyle = styled.div`
  padding: 48px 0px;

  .screen-xs & {
    padding: 12px 0px;
  }

  .screen-sm & {
    padding: 12px 0px;
  }

  .screen-md & {
    padding: 16px 0px;
  }

  .screen-lg & {
    padding: 24px 0px;
  }

  .screen-xxl & {
    padding: 32px 0px;
  }

  .screen-xl & {
    padding: 48px 0px;
  }
`;

export const PublicPageContainer = (props: { children: ReactNode }) => {
  return (
    <PublicPageContainerStyle className={'page-container'}>
      {props.children}
    </PublicPageContainerStyle>
  );
};
