import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useAnnotationContext } from '@classroom/container/annotation/AnnnotationContext';
import {
  CoordinateComp,
  CoordinateProps,
} from '@cms/comps/interact/coordinate/CoordinateComp';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CoordinateGridType } from '@cms/comps/interact/coordinate/CoordinateUtils';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';

export const AnnotationWorkspace = () => {
  const { sectionData, enableAnnotation } = useClassRoomContext();
  const { updateAnnotation } = useAnnotationContext();

  const workspaceData: CoordinateProps = useMemo(() => {
    return {
      id: COMPONENT_UTILS.generateUID(),
      type: CompTypeEnum.COORDINATE,

      configuration: {
        sourceItems: [],
      },
      setting: {
        grid: {
          type: CoordinateGridType.blank_cover,
          snapToGrid: true,
          unit: null,

          xAxis: {
            from: 0,
            to: 160,
            step: 1,
            stepLabel: 5,
          },

          yAxis: {
            from: 0,
            to: 90,
            step: 1,
            stepLabel: 5,
          },
        },
        actions: [],
      },
    };
  }, []);

  const currentAnswer = useMemo(() => {
    return sectionData != null
      ? {
          id: workspaceData.id,
          type: workspaceData.type,
          answer: sectionData.annotation,
        }
      : null;
  }, [sectionData, workspaceData]);

  const handleOnChange = (value: any) => {
    updateAnnotation(value.answer, true);
  };

  return (
    <AnnotationWorkspaceStyle
      className={`annotation-workspace ${
        enableAnnotation ? 'visible' : 'invisible'
      }`}
    >
      <CoordinateComp
        disabled={!sectionData?.owner}
        item={workspaceData}
        answer={currentAnswer}
        feedback={null}
        onChange={handleOnChange}
      />
    </AnnotationWorkspaceStyle>
  );
};

const AnnotationWorkspaceStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;

  &.invisible {
    z-index: 1;

    .coordinate-graph-toolbar {
      display: none !important;
    }
  }

  .cms-component-block,
  .coordinate-graph-comp,
  .coordinate-graph-wrap,
  .coordinate-graph-workspace {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .coordinate-graph-workspace svg {
    background: transparent !important;
    outline: none !important;
  }

  .coordinate-graph-toolbar {
    position: fixed;
    top: 4em;
    right: 15px;
    padding: 1em;
    font-size: 20px;
  }

  .blockquote {
    font-size: 10px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 99999;
  }
`;
