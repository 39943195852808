import { Tooltip } from '@components/tooltip/Tooltip';
import { Button, Popover, Space } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoOutlined,
  UpOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from '@ant-design/icons';

import React, { useMemo, useState } from 'react';
import { EditResourceAction } from '@modules/product/components/resource/toolbar/MovingResourceItem';
import styled from 'styled-components';
import useModal from '@hooks/useModal';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import ResourceMetadataEditable, {
  ResourceMetadataEditableEnum,
} from '@modules/product/components/resource/metadata/ResourceMetadataEditable';
import { useTranslation } from 'react-i18next';
import { H5, H4 } from '@components/typography';
import { ResourceValidation } from '@cms/lesson-template/compose/validation';

const MovingResourceToolbar = (props: {
  item: ResourceProps;
  questionNumber: number;
  disablePrevious: boolean;
  disabledNext: boolean;
  onClick: (action: EditResourceAction) => void;
}) => {
  const { t } = useTranslation();

  const modal = useModal();

  const [activeTab, setActiveTab] = useState(
    ResourceMetadataEditableEnum.metadata
  );

  const changeTab = (tab: ResourceMetadataEditableEnum) => {
    setActiveTab(tab);
  };

  const titleModalDisplay = useMemo(() => {
    if (activeTab === ResourceMetadataEditableEnum.metadata) {
      return t('resource.actions.update_question_metadata');
    } else {
      return t('resource.actions.update_standards');
    }
  }, [activeTab]);

  return (
    <MovingResourceToolbarStyle className={'cms-edit-content-header'}>
      <div className={'question-number'}>
        <ResourceValidation resourceId={props.item.resourceId} />

        <Tooltip title={t('resource.actions.click_to_edit')}>
          <Button
            type={'text'}
            onClick={() => props.onClick(EditResourceAction.EDIT)}
          >
            <H5 style={{ marginBottom: 0 }}>
              {t('resource.question_n', {
                questionNumber: props.questionNumber,
              })}
            </H5>
            &nbsp;
            <EditOutlined />
          </Button>
        </Tooltip>
      </div>

      <div className={'resource-toolbar-actions'}>
        <Space size={10}>
          <Tooltip title={t('resource.actions.move_content_first')}>
            <Button
              size={'small'}
              shape={'circle'}
              disabled={props.disablePrevious}
              onClick={() => props.onClick(EditResourceAction.MOVE_TOP)}
            >
              <DoubleLeftOutlined style={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Tooltip>

          <Tooltip title={t('resource.actions.move_content_up')}>
            <Button
              size={'small'}
              shape={'circle'}
              type={'default'}
              disabled={props.disablePrevious}
              onClick={() => props.onClick(EditResourceAction.MOVE_UP)}
            >
              <UpOutlined />
            </Button>
          </Tooltip>

          <Tooltip title={t('resource.actions.move_content_down')}>
            <Button
              size={'small'}
              shape={'circle'}
              disabled={props.disabledNext}
              onClick={() => props.onClick(EditResourceAction.MOVE_DOWN)}
            >
              <DownOutlined />
            </Button>
          </Tooltip>

          <Tooltip title={t('resource.actions.move_content_last')}>
            <Button
              size={'small'}
              shape={'circle'}
              disabled={props.disabledNext}
              onClick={() => props.onClick(EditResourceAction.MOVE_BOTTOM)}
            >
              <DoubleRightOutlined style={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Tooltip>

          <Tooltip title={t('resource.actions.clone_question')}>
            <Button
              size={'small'}
              shape={'circle'}
              onClick={() => props.onClick(EditResourceAction.CLONE)}
            >
              <CopyOutlined />
            </Button>
          </Tooltip>

          <Tooltip title={t('resource.actions.delete_question')}>
            <Button
              size={'small'}
              shape={'circle'}
              onClick={() => props.onClick(EditResourceAction.DELETE)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>

          <Popover
            placement="bottomRight"
            showArrow
            content={
              <ResourceMetadataEditable
                activeTab={activeTab}
                onChange={changeTab}
                resourceId={props.item.resourceId}
                onSuccess={() => modal.hide()}
                onCancel={() => modal.hide()}
              />
            }
            title={<H4>{titleModalDisplay}</H4>}
            trigger="click"
            open={modal.visible}
            onOpenChange={() => modal.show()}
          >
            <Button shape={'circle'} size={'small'}>
              <InfoOutlined />
            </Button>
          </Popover>
        </Space>
      </div>
    </MovingResourceToolbarStyle>
  );
};

export default MovingResourceToolbar;

const MovingResourceToolbarStyle = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .resource-toolbar-actions {
    display: flex;
    align-items: center;
  }

  .question-number {
    display: flex;
    align-items: center;

    .ant-btn {
      border: none;
      padding: 4px 6px;
      margin-right: 6px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
