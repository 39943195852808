import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';

export interface UserRequest {
  uid: string;
  code: string;
}

export interface ForgotPwReq {
  email: string;
  clientId: string;
}

export interface ResetPwReq extends UserRequest {
  password: string;
}

export const UserRequestAPI = createApi({
  reducerPath: 'UserRequestAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    forgotPassword: builder.mutation<BasicMessageRes, ForgotPwReq>({
      query: (body: ForgotPwReq) => ({
        url: '/public/requests/forgot-password',
        body,
        method: 'POST',
      }),
    }),

    resetPassword: builder.mutation<BasicMessageRes, ResetPwReq>({
      query: (body: ResetPwReq) => ({
        url: '/public/requests/reset-password',
        body,
        method: 'POST',
      }),
    }),
  }),
});
