import React, { createContext, useContext } from 'react';
import { CompFeedbackProps } from '@cms/feedback/CompFeedback';
import styled from 'styled-components';

const CompFeedbackContext = createContext({
  feedBack: null as null | CompFeedbackProps,
});

export const CompFeedbackContextProvider = (props: {
  feedBack: null | CompFeedbackProps;
  children: any;
}) => {
  return (
    <CompFeedbackContext.Provider
      value={{
        feedBack: props.feedBack,
      }}
    >
      {props.children}
    </CompFeedbackContext.Provider>
  );
};

export const useCompFeedbackContext = () => {
  const context = useContext(CompFeedbackContext);
  if (!context) {
    throw new Error('You must wrap container by CompFeedbackContextProvider');
  }
  return context;
};

export const CompFeedbackModalStyle = styled.div`
  min-width: 400px;
  width: 400px;
  max-height: 400px;
  overflow-y: auto;

  .cms-component-block {
    max-width: 100% !important;
  }
`;
