import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';

import ManageCollectionPage from '@modules/product/pages/ManageCollectionPage';
import { PageHeader } from '@app/header/PageHeader';

const MyCollection = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-collection-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_LESSON}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('my_lesson.header')}
        description={t('my_lesson.header')}
      />
      <ManageCollectionPage />
    </ManagementLayout>
  );
};

export default MyCollection;
