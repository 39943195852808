import { UserProductLicenseAPI } from '@modules/license/services/UserProductLicenseAPI';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { Button, Col, notification, Row } from 'antd';
import { FormGroup, InputGroup } from '@components/input/InputGroup';
import { Default_Gutter } from '@components/grid';
import SelectProduct from '@modules/common/components/SelectProduct';
import DatePicker from '@components/date-n-time/DatePicker';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { ProductLicenseRes } from '@modules/license/services/model';

export const AddLicenseForUser = (props: {
  userId: number;
  onSuccess: (data: ProductLicenseRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [assignLicense] =
    UserProductLicenseAPI.endpoints.assignLicense.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      productId: -1,
      availableDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      expiredDate: DateAndTimeUtils.add(
        DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
        DateAndTimeFormat.YYYY_MM_DD,
        1,
        'year'
      ),
    },
    onSubmit: (values) => {
      if (values.productId) {
        handleOnSubmit(
          values.productId,
          values.availableDate,
          values.expiredDate
        );
      }
    },
  });

  const handleOnSubmit = (
    productId: number,
    availableDate: string,
    expiredDate: string
  ) => {
    const request = {
      userId: props.userId,
      productId: productId,
      availableDate: availableDate,
      expiredDate: expiredDate,
    };

    assignLicense(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('license.warning.add_success'),
          description: t('license.warning.add_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess(data);
      })
      .catch(() => {
        notification.error({
          message: t('license.warning.add_error'),
          description: t('license.warning.add_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const onProductChange = (productId: number) => {
    formik.setFieldValue('productId', productId);
  };

  return (
    <FormikProvider value={formik}>
      <FormGroup>
        <Row gutter={[Default_Gutter, Default_Gutter]} align={'bottom'}>
          <Col span={9}>
            <SelectProduct
              formik={formik}
              label={t('select.select_a_product')}
              placeholder={t('select.select_a_product')}
              name={'productId'}
              value={formik.values.productId ?? -1}
              onChange={onProductChange}
            />
          </Col>

          <Col span={5}>
            <InputGroup label={t('label.available_date')}>
              <DatePicker
                formik={formik}
                name="availableDate"
                value={formik.values.availableDate}
                handleChange={formik.handleChange}
              />
            </InputGroup>
          </Col>
          <Col span={5}>
            <InputGroup label={t('label.expired_date')}>
              <DatePicker
                formik={formik}
                name="expiredDate"
                value={formik.values.expiredDate}
                handleChange={formik.handleChange}
              />
            </InputGroup>
          </Col>
          <Col span={5}>
            <ButtonGroup type={'right'}>
              <Button
                type={'primary'}
                size={'large'}
                shape={'round'}
                icon={IconUtils.menu.license}
                onClick={formik.submitForm}
              >
                {t('license.actions.add_license')}
              </Button>

              <Button size={'large'} shape={'round'} onClick={props.onCancel}>
                {t('label.cancel')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </FormGroup>
    </FormikProvider>
  );
};
