import { Button, Col, notification, Row, Skeleton } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollToTop from '@components/feature/ScrollToTop';
import { PublishProductStyle } from '@modules/product/container/publish/ProductInformationStep';
import { ReviewProductContext } from '@modules/product/context/BuildProductContext';
import { PublishProductAPI } from '@services/publish/PublishProductAPI';
import {
  PublicProductInfo,
  PublicProductPreview,
} from '@modules/public/components/products/PublicProductInfo';
import { Common } from '@services/model/common';
import Card from '@components/card';
import ReviewProductPage from '@modules/product/pages/ReviewProductPage';
import { H2 } from '@components/typography';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';
import { ViewProductMode } from '@modules/product/components/view-toc/model';

export default function ProductReviewStep(props: {
  free: boolean;
  author: UserProfileRes;
  goPrevious: () => void;
  goNext: () => void;
}) {
  const { t } = useTranslation();
  const { productId } = useContext(ReviewProductContext);

  const [getReviewProductData, { data: productData }] =
    PublishProductAPI.endpoints.getReviewProductData.useLazyQuery();

  const [getReviewProductTocData, { data: productTocData }] =
    PublishProductAPI.endpoints.getReviewProductTocData.useLazyQuery();

  const [publishProduct] =
    PublishProductAPI.endpoints.publishProduct.useMutation({});

  useEffect(() => {
    getReviewProductData(productId);
    getReviewProductTocData(productId);
  }, []);

  const handlePublishProduct = () => {
    publishProduct({
      productId,
      free: props.free,
    })
      .unwrap()
      .then((result: Common) => {
        if (result.success) {
          warningMessage(true);
          props.goNext();
        } else {
          warningMessage(false);
        }
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const warningMessage = (isSuccess: boolean) => {
    if (isSuccess) {
      notification.success({
        message: `Thông báo`,
        description: 'Xuất bản Tài liệu thành công.',
        placement: 'bottomRight',
      });
    } else {
      notification.error({
        message: `Thông báo`,
        description: 'Xuất bản Tài liệu thất bại.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <ScrollToTop visible={false} />

      <PublishProductStyle className={'publish-product-section'}>
        <H2>{t('publish.basic_info.review')}</H2>

        <div className={'product-publish-content'}>
          {(!productData || !productTocData) && (
            <>
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
            </>
          )}

          {productData && productTocData && (
            <>
              <Row gutter={[Default_Gutter, Default_Gutter]}>
                <Col span={6}>
                  <PublicProductInfo item={productData.product} />
                </Col>

                <Col span={18}>
                  <Card>
                    <PublicProductPreview
                      item={productData}
                      author={props.author}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={[Default_Gutter, Default_Gutter]}>
                <Col span={24}>
                  <ReviewProductPage
                    productId={productData.product.productId}
                    type={ViewProductMode.compose_review}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>

        <div className={'product-publish-step'}>
          <Button
            type={'default'}
            shape={'round'}
            onClick={props.goPrevious}
            size={'large'}
          >
            {IconUtils.actions.previous} {t('button.step.previous')}
          </Button>

          <Button
            type={'primary'}
            shape={'round'}
            onClick={handlePublishProduct}
            size={'large'}
            icon={IconUtils.actions.publish}
          >
            {t('button.step.publish')}
          </Button>
        </div>
      </PublishProductStyle>
    </>
  );
}
