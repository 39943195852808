import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrinterOutlined } from '@ant-design/icons';
import { Tooltip } from '@components/tooltip/Tooltip';
import LessonButton from '@cms/lesson-template/components/buttons/style';

export const PrintLessonButton = () => {
  const { t } = useTranslation();

  const handlePrintLesson = () => {
    console.log('Print lesson');
  };

  return (
    <Tooltip title={t('exercise.button.print')}>
      <LessonButton
        className={'print-button'}
        type={'default'}
        shape={'circle'}
        onClick={handlePrintLesson}
        style={{ display: 'none' }}
        icon={<PrinterOutlined />}
      />
    </Tooltip>
  );
};
