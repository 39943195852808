import React from 'react';
import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { CreateAssignmentPage } from '@modules/assignments/pages/CreateAssignmentPage';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const CreateAssignment = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { lessonId, productId, productTocId } = router.query as unknown as {
    lessonId: string;
    productId: string;
    productTocId: string;
  };

  return (
    <ManagementLayout
      className={'create-assignment-page'}
      selectUser={'teacher'}
    >
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('assignment.create')}
        description={t('assignment.create')}
      />

      <CreateAssignmentPage
        lessonId={Number(lessonId)}
        productTocId={Number(productTocId)}
        productId={Number(productId)}
      />
    </ManagementLayout>
  );
};

export default CreateAssignment;
