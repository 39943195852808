import React, { useState } from 'react';

import { Divider, Tooltip } from 'antd';
import { ControlOutlined, ProfileOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import UpdateLessonDataModal, {
  UpdateLessonDataEnum,
} from '@modules/product/components/lesson/lesson-data/LessonDataModal';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import {
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import {
  RequireProUser,
  useProUser,
} from '@components/button/RequireProUserButton';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { IconUtils } from '@utils/IconUtils';
import { ComposeToolbarStyle } from '../layout';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ExitComposeBtn } from '@cms/lesson-template/compose/buttons/ExitComposeBtn';
import { EditResourceJSON } from '@cms/lesson-template/compose/resource/EditResourceJSON';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';

const ComposeSectionToolbar = (props: { type?: LessonTypeEnum }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const proUser = useProUser();

  const { lessonId, productId, productTocId, mode, changeToMode } =
    useLessonEditableContext();

  const [action, setAction] = useState<UpdateLessonDataEnum>(
    UpdateLessonDataEnum.DEFAULT
  );

  const resetAllActions = () => {
    setAction(UpdateLessonDataEnum.DEFAULT);
  };

  const goToMultipleMode = () => {
    router.push(
      SiteMap.content.lesson.compose_gen(
        lessonId,
        proUser,
        productId,
        productTocId
      )
    );
  };

  return (
    <>
      <ComposeToolbarStyle className={'lesson-toolbar'}>
        <div className={'toolbar-group left-group'}>
          <ExitComposeBtn />

          <Divider type={'vertical'} />

          <Tooltip
            title={t('lesson_compose.warning.show_components')}
            placement={'topLeft'}
          >
            <LessonButton
              type={
                mode === LessonMode.view_question_banks ? 'primary' : 'default'
              }
              shape={'round'}
              className={'action-button'}
              onClick={() =>
                changeToMode(
                  mode === LessonMode.view_question_banks
                    ? LessonMode.view
                    : LessonMode.view_question_banks
                )
              }
              icon={IconUtils.compose.component_banks}
            >
              {t('lesson_compose.actions.show_components')}
            </LessonButton>
          </Tooltip>

          {lessonId && lessonId > 0 && (
            <Authorization
              roles={[
                RoleEnum.TEACHER,
                RoleEnum.SUPPORT,
                RoleEnum.SCHOOL,
                RoleEnum.ADMIN,
              ]}
              type={'ifAnyGranted'}
            >
              <Tooltip
                title={t('lesson_compose.actions.metadata')}
                placement={'topLeft'}
              >
                <LessonButton
                  type={'default'}
                  shape={'circle'}
                  className={'action-button'}
                  onClick={() => setAction(UpdateLessonDataEnum.METADATA)}
                  icon={IconUtils.actions.view_info}
                />
              </Tooltip>

              <Tooltip
                title={t('lesson_compose.actions.review')}
                placement={'topLeft'}
              >
                <LessonButton
                  type={'default'}
                  shape={'circle'}
                  className={'action-button'}
                  onClick={() => setAction(UpdateLessonDataEnum.REVIEW)}
                  icon={IconUtils.lesson.review}
                />
              </Tooltip>
            </Authorization>
          )}

          <RequireProUser>
            <Tooltip
              title={t('lesson_compose.actions.view_json')}
              placement={'topLeft'}
            >
              <LessonButton
                type={'default'}
                shape={'circle'}
                className={'action-button'}
                onClick={() => setAction(UpdateLessonDataEnum.EXTRACT)}
                icon={<ControlOutlined />}
              />
            </Tooltip>
          </RequireProUser>

          <RequireProUser>
            <Tooltip
              title={t('lesson_compose.mode.multiple')}
              placement={'topLeft'}
            >
              <LessonButton
                type={'default'}
                shape={'circle'}
                className={'action-button'}
                onClick={goToMultipleMode}
              >
                <ProfileOutlined />
              </LessonButton>
            </Tooltip>
          </RequireProUser>
        </div>
      </ComposeToolbarStyle>

      {(action === UpdateLessonDataEnum.METADATA ||
        action === UpdateLessonDataEnum.STANDARDS) && (
        <CustomModal
          header={t('lesson_compose.actions.metadata')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <UpdateLessonDataModal
              type={'edit'}
              lessonType={props.type}
              action={action}
              lessonId={lessonId}
              onCancel={resetAllActions}
              onChange={(mode) => {
                setAction(mode);
              }}
            />
          }
          onCloseFunc={resetAllActions}
        />
      )}

      {action === UpdateLessonDataEnum.REVIEW && (
        <CustomModal
          header={t('lesson.actions.view')}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={lessonId} />}
          onCloseFunc={resetAllActions}
        />
      )}

      {action === UpdateLessonDataEnum.EXTRACT && (
        <CustomModal
          header={t('lesson_compose.actions.view_resource_data')}
          className={CustomModalClassEnum.full_size_modal}
          content={<EditResourceJSON onClose={resetAllActions} />}
          onCloseFunc={resetAllActions}
        />
      )}
    </>
  );
};

export default ComposeSectionToolbar;
