import { MyProfileAPI } from '@services/private/MyProfileAPI';
import React, { useEffect, useState } from 'react';
import { UserCertificateRes } from '@modules/admin/service/program';
import { EntityStatusEnum } from '@services/model/common';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { StudentCertificateScoreTable } from '@modules/admin/component/CertificateScoreTable';
import IconImage from '@components/images/IconImage';
import { ImageScale } from '@utils/ImageScale';
import styled from 'styled-components';
import { DateLabel } from '@components/text/DateLabel';

export const UserCertificateTable = () => {
  const [getCertificates, { data }] =
    MyProfileAPI.endpoints.getCertificates.useLazyQuery();

  useEffect(() => {
    getCertificates({});
  }, []);

  return (
    <UserCertificateTableStyle className={'user-certificate-table'}>
      {data &&
        data.map((cer) => {
          return (
            <UserCertificateDisplayItem
              key={cer.userCertificateId}
              item={cer}
            />
          );
        })}
    </UserCertificateTableStyle>
  );
};

const UserCertificateTableStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const UserCertificateDisplayItem = (props: { item: UserCertificateRes }) => {
  const [selectCer, setSelectCer] = useState(false);

  return (
    <>
      <UserCertificateDisplayStyle
        className={`user-certificate-item ${
          props.item.status === EntityStatusEnum.PUBLISHED
            ? 'completed'
            : 'in-progress'
        }`}
        onClick={() => setSelectCer(true)}
      >
        <IconImage
          src={props.item.certificate.icon}
          width={ImageScale.certificate.width * 2}
          height={ImageScale.certificate.height * 2}
        />

        {props.item.publishedDate && (
          <DateLabel label={props.item.publishedDate} />
        )}
      </UserCertificateDisplayStyle>

      {selectCer && (
        <CustomModal
          header={'View Certificate'}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <StudentCertificateScoreTable
              items={props.item.certificate.scores}
              score={props.item}
            />
          }
          onCloseFunc={() => setSelectCer(false)}
        />
      )}
    </>
  );
};

const UserCertificateDisplayStyle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &.in-progress {
    filter: grayscale(1);
  }

  .date-label {
    font-size: 20px;
    text-align: center;
    margin-top: 0.5em;
  }
`;
