import { FormikProvider, useFormik } from 'formik';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import Checkbox from '@components/input/Checkbox';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import React, { useEffect } from 'react';
import { useLoadSchoolSetting } from '@modules/setting/container/school-setting/useLoadSchoolSetting';
import { useTranslation } from 'react-i18next';
import {
  SchoolSettingAPI,
  UpdateSchoolContentSettingRes,
} from '@services/private/SchoolSettingAPI';
import { H2 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

export const SchoolContentSettingForm = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onError: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();
  const { data, isSuccess, isFetching } = useLoadSchoolSetting(props.schoolId);

  const [updateContentSetting, { isLoading }] =
    SchoolSettingAPI.endpoints.updateContentSetting.useMutation({});

  const [updateContentSettingAdmin, { isLoading: isAdminLoading }] =
    SchoolSettingAPI.endpoints.updateContentSettingAdmin.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,

    initialValues: {
      enableClassRoom: false,
      enableStandard: false,
      allowUsedLessonBank: false,
      allowUpdateScoring: false,
    },

    onSubmit: (values) => {
      if (props.schoolId != null && props.schoolId > 0) {
        updateSchoolSettingAdmin({ ...values, schoolId: props.schoolId });
      } else {
        updateSchoolSetting(values);
      }
    },
  });

  useEffect(() => {
    if (data && isSuccess) {
      formik.setValues({
        enableClassRoom: data.enableClassRoom,
        enableStandard: data.enableStandard,
        allowUsedLessonBank: data.allowUsedLessonBank,
        allowUpdateScoring: data.allowUpdateScoring,
      });
    }
  }, [data]);

  const updateSchoolSetting = (request: UpdateSchoolContentSettingRes) => {
    updateContentSetting(request)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  const updateSchoolSettingAdmin = (
    setting: Required<UpdateSchoolContentSettingRes>
  ) => {
    updateContentSettingAdmin(setting)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  return (
    <>
      <HeaderGroup style={{ marginTop: 0, marginBottom: 20 }}>
        <H2>Quản lý tính năng và nội dung</H2>
      </HeaderGroup>

      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <Checkbox
              name={'enableClassRoom'}
              checked={formik.values.enableClassRoom}
              value={formik.values.enableClassRoom}
              formik={formik}
              onChange={formik.handleChange}
            >
              Enable Class room
            </Checkbox>

            <Checkbox
              name={'enableStandard'}
              checked={formik.values.enableStandard}
              value={formik.values.enableStandard}
              formik={formik}
              onChange={formik.handleChange}
            >
              Support Standard Resources
            </Checkbox>

            <Checkbox
              name={'allowUsedLessonBank'}
              checked={formik.values.allowUsedLessonBank}
              value={formik.values.allowUsedLessonBank}
              formik={formik}
              onChange={formik.handleChange}
            >
              Using components to build lesson
            </Checkbox>

            <Checkbox
              name={'allowUpdateScoring'}
              checked={formik.values.allowUpdateScoring}
              value={formik.values.allowUpdateScoring}
              formik={formik}
              onChange={formik.handleChange}
            >
              Allow modify Scoring
            </Checkbox>
          </Col>
        </Row>

        <div
          className="d-flex submit-container"
          style={{ justifyContent: 'space-between' }}
        >
          <ButtonGroup>
            <Button
              type="primary"
              shape={'round'}
              onClick={formik.submitForm}
              icon={IconUtils.save}
              loading={isFetching || isLoading || isAdminLoading}
            >
              <>{t('button.update')}</>
            </Button>

            {props.onCancel && (
              <Button type="default" shape={'round'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </FormikProvider>
    </>
  );
};
