import React from 'react';
import Head from '@app/header';
import { H1 } from '@components/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SocialVideo from '@cms/content/SocialVideoPlayList';

const SocialVideoExample = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('organization')} - PDF Viewer</title>
        <meta name="description" content="PDF Viewer" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div>
        <H1>PDF VIEWER.</H1>

        <TemplateStyle className={'template-component'}>
          <SocialVideo
            type="facebook"
            src="https://www.facebook.com/100069806454064/videos/1543175626146822/"
          />
        </TemplateStyle>

        <TemplateStyle className={'template-component'}>
          <SocialVideo
            type="youtube"
            src="https://www.youtube.com/watch?v=-xOYekYN-oU"
          />
        </TemplateStyle>
      </div>
    </>
  );
};

export default SocialVideoExample;

const TemplateStyle = styled.div`
  width: 1600px;
  height: 900px;
  position: relative;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;
