import { useCallback, useRef, useState } from 'react';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';

import sanitizeHtml from 'sanitize-html';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from 'react-i18next';
import { CompConfiguration } from '@cms/ComponentInteface';

export const ContentInlineEditable = (props: {
  hideInput: boolean;
  value: string;
  onChange: (val: string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);

  const [focus, setFocus] = useState(false);
  const [content, setContent] = useState(props.value);

  const handleOnSave = () => {
    props.onChange(content);
    setFocus(false);
  };

  const handleOnCancel = () => {
    setContent(props.value);
    props.onCancel();
  };

  const onContentChange = useCallback((evt: any) => {
    const sanitizeConf = {
      allowedTags: ['b', 'i', 'a', 'p'],
      allowedAttributes: { a: ['href'] },
    };
    setContent(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf));
  }, []);

  const handleOnFocus = () => {
    setFocus(true);
  };

  const handleOnBlur = (evt: any) => {
    onContentChange(evt);
  };

  const handleInsertInput = () => {
    insertHTML(' ' + CompConfiguration.INLINE_COMPONENT);

    if (container.current) {
      setContent(container.current.innerHTML);
    }
  };

  return (
    <ContentInlineEditableStyle className={'content-inline-editor'}>
      <ContentEditable
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={onContentChange}
        html={content}
        innerRef={container}
      />

      <div className={'button-group'}>
        {props.hideInput ? (
          <span />
        ) : (
          <Button
            shape={'round'}
            onClick={handleInsertInput}
            type={'primary'}
            disabled={!focus}
          >
            Add input
          </Button>
        )}

        <div className={'button-group-item'}>
          <Button
            shape={'round'}
            type={'default'}
            onClick={handleOnCancel}
            icon={IconUtils.actions.revert}
          >
            {t('label.cancel')}
          </Button>

          <Button
            shape={'round'}
            type={'primary'}
            onClick={handleOnSave}
            icon={IconUtils.actions.save}
          >
            {t('button.save')}
          </Button>
        </div>
      </div>
    </ContentInlineEditableStyle>
  );
};

function insertHTML(html: string) {
  try {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const temp = document.createElement('div');
      const insertion = document.createDocumentFragment();

      temp.innerHTML = html;

      while (temp.firstChild) {
        insertion.appendChild(temp.firstChild);
      }

      range.deleteContents();
      range.insertNode(insertion);
    }
  } catch (z) {
    try {
      if (document.getSelection()) {
        // @ts-ignore
        document.getSelection().createRange().pasteHTML(html);
      }
    } catch (z) {}
  }
}

const ContentInlineEditableStyle = styled.div`
  [contenteditable='true'] {
    padding: 0.25em;
    background: rgba(0, 0, 0, 0.03) !important;

    &:focus,
    &:hover {
      outline: none;
      background: rgba(0, 0, 0, 0.15) !important;
      color: ${(props) => props.theme.color.black};
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    .button-group-item {
      display: flex;
      justify-items: flex-end;

      .ant-btn {
        margin-left: 0.5em;
      }
    }
  }
`;
