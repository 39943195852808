import React, { useEffect, useState } from 'react';
import { ReviewProductContext } from '../context/BuildProductContext';
import { Col, Row, Steps } from 'antd';
import ProductInformationStep from '../container/publish/ProductInformationStep';
import ProductIntroduceStep from '@modules/product/container/publish/ProductIntroduceStep';
import { useTranslation } from 'react-i18next';
import ProductLessonIntroduceStep from '@modules/product/container/publish/ProductLessonIntroduceStep';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import ProductReviewStep from '../container/publish/ProductReviewStep';
import { LeftOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import ProductMultipleLicenseStep from '@modules/product/container/publish/ProductMultipleLicenseStep';
import { UserProfileAPI } from '@services/private/UserProfileAPI';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { ProductTocRes } from '../services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

const { Step } = Steps;

export enum PublicProductStepEnum {
  INFORMATION = 1,
  INTRODUCTION = 2,
  INTRODUCTION_LESSON = 3,
  LICENSE_TYPE = 4,
  REVIEW = 5,
}

export default function PublishProductPage(props: {
  productId: number;
  free: boolean;
}) {
  const router = useRouter();
  const { t } = useTranslation();

  const [getProduct, { data }] = ProductAPI.endpoints.getProduct.useLazyQuery(
    {}
  );

  const [getUserProfile, { data: userProfile, isSuccess }] =
    UserProfileAPI.endpoints.getUserProfile.useLazyQuery();

  const [currentState, setCurrentState] = useState<PublicProductStepEnum>(
    PublicProductStepEnum.INFORMATION
  );

  const [selectLessonId, setSelectLessonId] = useState(-1);
  const [selectedProductTocId, setSelectedProductTocId] = useState(-1);
  const [productTocs, setProductTocs] = useState<ProductTocRes[]>([]);

  const setSelectLesson = (lesson: LessonRes, productToc: ProductTocRes) => {
    setSelectLessonId(lesson.lessonId);
    setSelectedProductTocId(productToc.productTocId);
  };

  const [warningCreateSchool, setWarningCreateSchool] = useState(false);

  useEffect(() => {
    getUserProfile({});
    getProduct(props.productId);
  }, [props.productId]);

  useEffect(() => {
    if (userProfile && isSuccess) {
      if (
        userProfile.userProfileId != null &&
        userProfile.userProfileId > 0 &&
        userProfile.publishedDate != null
      ) {
        // do nothing....
      } else {
        setWarningCreateSchool(true);
      }
    }
  }, [userProfile]);

  const getStatusType = (status: PublicProductStepEnum) => {
    return status === currentState
      ? 'process'
      : status < currentState
      ? 'finish'
      : 'wait';
  };

  const handlePublishedAction = () => {
    if (data) {
      router.push(SiteMap.public.product_gen(data.productId, data.name));
    }
  };

  const viewPublishSite = () => {
    router.push(SiteMap.management.school.info_review);
  };

  const handleGoBack = () => {
    router.push(SiteMap.content.product.list);
  };

  return (
    <>
      {data && (
        <>
          {warningCreateSchool && (
            <Modal
              open={warningCreateSchool}
              title={t('alert.warning')}
              footer={[
                <Button key="submit" type="primary" onClick={viewPublishSite}>
                  {t('button.create_public_site')}
                </Button>,
              ]}
            >
              <p>{t('product.warning.create_profile_first')}</p>
            </Modal>
          )}

          <HeaderGroup className="header-group">
            <H1>
              <Button
                type={'default'}
                shape={'circle'}
                onClick={handleGoBack}
                style={{ marginLeft: 0 }}
                icon={<LeftOutlined />}
              />
              &nbsp;{t('product.actions.publish')}:
              <span className={'text-default'}>
                &nbsp;{data?.name}{' '}
                {props.free && (
                  <span className={'primary-text'}>({t('label.free')})</span>
                )}
              </span>
            </H1>
          </HeaderGroup>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
              <Steps>
                <Step
                  status={getStatusType(PublicProductStepEnum.INFORMATION)}
                  title={t('publish.basic_info.header')}
                />
                <Step
                  status={getStatusType(PublicProductStepEnum.INTRODUCTION)}
                  title={t('publish.basic_info.introduction')}
                />

                <Step
                  status={getStatusType(
                    PublicProductStepEnum.INTRODUCTION_LESSON
                  )}
                  title={t('publish.basic_info.introduction_lesson')}
                />

                {!props.free && (
                  <Step
                    status={getStatusType(PublicProductStepEnum.LICENSE_TYPE)}
                    title={t('publish.basic_info.price')}
                  />
                )}

                <Step
                  status={getStatusType(PublicProductStepEnum.REVIEW)}
                  title={t('publish.basic_info.review')}
                />
              </Steps>
            </Col>
          </Row>

          <div style={{ marginTop: '2em' }}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <ReviewProductContext.Provider
                value={{
                  productId: props.productId,
                  selectLessonId,
                  selectedProductTocId,
                  setSelectLesson,
                  productTocs,
                  setProductTocs,
                }}
              >
                {currentState === PublicProductStepEnum.INFORMATION && (
                  <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
                    <ProductInformationStep
                      goNext={() =>
                        setCurrentState(PublicProductStepEnum.INTRODUCTION)
                      }
                    />
                  </Col>
                )}

                {currentState === PublicProductStepEnum.INTRODUCTION && (
                  <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
                    <ProductIntroduceStep
                      goPrevious={() =>
                        setCurrentState(PublicProductStepEnum.INFORMATION)
                      }
                      goNext={() =>
                        setCurrentState(
                          PublicProductStepEnum.INTRODUCTION_LESSON
                        )
                      }
                    />
                  </Col>
                )}

                {currentState === PublicProductStepEnum.INTRODUCTION_LESSON && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <ProductLessonIntroduceStep
                      goPrevious={() =>
                        setCurrentState(PublicProductStepEnum.INTRODUCTION)
                      }
                      goNext={() =>
                        setCurrentState(
                          props.free
                            ? PublicProductStepEnum.REVIEW
                            : PublicProductStepEnum.LICENSE_TYPE
                        )
                      }
                    />
                  </Col>
                )}

                {currentState === PublicProductStepEnum.LICENSE_TYPE &&
                  !props.free && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <ProductMultipleLicenseStep
                        productId={data.productId}
                        goPrevious={() =>
                          setCurrentState(PublicProductStepEnum.INTRODUCTION)
                        }
                        goNext={() =>
                          setCurrentState(PublicProductStepEnum.REVIEW)
                        }
                      />
                    </Col>
                  )}

                {userProfile &&
                  currentState === PublicProductStepEnum.REVIEW && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <ProductReviewStep
                        free={props.free}
                        author={userProfile}
                        goPrevious={() =>
                          setCurrentState(
                            props.free
                              ? PublicProductStepEnum.INTRODUCTION_LESSON
                              : PublicProductStepEnum.LICENSE_TYPE
                          )
                        }
                        goNext={handlePublishedAction}
                      />
                    </Col>
                  )}
              </ReviewProductContext.Provider>
            </Row>
          </div>
        </>
      )}
    </>
  );
}
