import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import React, { useEffect, useMemo, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import Card, { CardClassEnum } from '@components/card';
import { notification } from 'antd';
import TablePagination from '@components/table/TablePagination';
import { ResponsiveScreen } from '@hooks/useMedia';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import {
  ClassActionDropdown,
  ClassInfoEditable,
  ClassRosterGroup,
} from '@modules/users/component/classes/ClassInfo';
import { ManageClassActionEnum } from '@modules/users/pages/ManageClassPage';
import ColorCircle from '@components/input/ColorCircle';
import { ManageClassModal } from './ManageClassModal';
import { EntityWithStatus } from '@components/status/EntityStatus';
import { useSelectUser } from '@hooks/useSelectUser';
import {
  ClassRes,
  ClassRosterEnumType,
  DisabledClassReq,
  FilterClassReq,
} from '@modules/users/services/clazz';
import { CertificateDisplay } from '@modules/admin/component/CertificateDisplay';
import { ProgramDisplay } from '@modules/admin/component/ProgramDisplay';

export const ManageClassTable = (props: { action: ManageClassActionEnum }) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const selectUserId = useSelectUser();

  const [getMyClasses, { data, isFetching }] =
    ClassAPI.endpoints.getMyClasses.useLazyQuery();

  const [activeClass] = ClassAPI.endpoints.activeClass.useMutation();

  const [action, setAction] = useState({
    action: ManageClassActionEnum.default,
    params: -1,
  });

  const [params, setParams] = useState<FilterClassReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
    teacherId: selectUserId,
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  useEffect(() => {
    setParams((prev) => {
      return { ...prev, teacherId: selectUserId };
    });
  }, [selectUserId]);

  useEffect(() => {
    if (props.action === ManageClassActionEnum.refresh) {
      refreshData();
    }
  }, [props.action]);

  useEffect(() => {
    return () => {
      setParams({
        page: 1,
        size: MAX_PAGE_SIZE,
        sort: 'createdDate',
        direction: 'descend',
      });
    };
  }, []);

  const handleOnSuccess = () => {
    refreshData();
    setAction({
      action: ManageClassActionEnum.default,
      params: -1,
    });
  };

  const handleOnCancel = () => {
    refreshData();
    setAction({
      action: ManageClassActionEnum.default,
      params: -1,
    });
  };

  const refreshData = () => {
    getMyClasses(params);
  };

  const headerDisplay = useMemo(() => {
    if (action.action === ManageClassActionEnum.add) {
      return t('classes.add_class');
    } else if (action.action === ManageClassActionEnum.update_metadata) {
      return t('classes.update_class');
    } else if (action.action === ManageClassActionEnum.update_student) {
      return t('class.students');
    } else if (action.action === ManageClassActionEnum.invite_student) {
      return t('classes.actions.invite_students');
    } else {
      return '';
    }
  }, [action.action]);

  const columns = [
    {
      title: '#',
      dataIndex: 'code',
      key: 'code',
      align: 'code',
      width: 60,
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: true,
    },
    {
      title: t('class.label.teachers'),
      dataIndex: 'teachers',
      key: 'teachers',
      width: 120,
    },
    {
      title: t('class.label.rosters'),
      dataIndex: 'rosters',
      key: 'rosters',
    },
    {
      title: 'Program',
      dataIndex: 'program.name',
      key: 'program.name',
      width: 180,
      sorter: true,
      align: 'center',
    },
    {
      title: 'Certificate',
      dataIndex: 'certificate.name',
      key: 'certificate.name',
      width: 180,
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const renderMobile = (item: ClassRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <ClassInfoEditable
          item={item}
          onEdit={onEdit}
          onActivateClass={onActivateClass}
        />
      </Card>
    );
  };

  const processDataRow = (item: ClassRes) => {
    return {
      key: item.classId,
      code: (
        <ColorCircle color={item.color}>
          {item.code.substring(0, 3)}
        </ColorCircle>
      ),
      name: (
        <EntityWithStatus status={item.status}>{item.name}</EntityWithStatus>
      ),
      teachers: (
        <ClassRosterGroup
          type={[
            ClassRosterEnumType.MAIN_TEACHER,
            ClassRosterEnumType.SECONDARY_TEACHER,
          ]}
          rosters={item.rosters}
          max={4}
        />
      ),
      rosters: (
        <ClassRosterGroup
          type={[ClassRosterEnumType.STUDENT]}
          rosters={item.rosters}
          max={12}
        />
      ),
      'program.name': item.program ? (
        <ProgramDisplay item={item.program} />
      ) : null,

      'certificate.name': item.certificate ? (
        <CertificateDisplay item={item.certificate} />
      ) : null,
      actions: (
        <ClassActionDropdown
          item={item}
          onEdit={onEdit}
          onActivateClass={onActivateClass}
        />
      ),
    };
  };

  const onEdit = (classId: number) => {
    setAction({
      action: ManageClassActionEnum.update_metadata,
      params: classId,
    });
  };

  const onActivateClass = (classId: number, isActive: boolean) => {
    confirm(
      isActive ? 'warning' : 'danger',
      t('header.confirm'),
      isActive ? t('class.warning.activated') : t('class.warning.disabled'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          const request: DisabledClassReq = {
            classId: classId,
            disabled: isActive,
          };

          activeClass(request)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('classes.warning.success'),
                description: t('classes.warning.delete_success'),
                placement: 'bottomRight',
              });
              refreshData();
            })
            .catch(() => {
              notification.error({
                message: t('classes.warning.error'),
                description: t('classes.warning.delete_failure'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const handleOnTabChange = (action: ManageClassActionEnum) => {
    setAction((prevState) => {
      return { ...prevState, action: action };
    });
  };

  const onParamsChange = (params: PaginationReq) => {
    setParams(params);
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={onParamsChange}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {(action.action === ManageClassActionEnum.update_metadata ||
        action.action === ManageClassActionEnum.update_student ||
        action.action === ManageClassActionEnum.invite_student) && (
        <CustomModal
          className={CustomModalClassEnum.full_size_modal}
          header={headerDisplay}
          content={
            <ManageClassModal
              action={action.action}
              onChangeTab={handleOnTabChange}
              classId={action.params}
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};
