import { ManageClassActionEnum } from '@modules/users/pages/ManageClassPage';
import { useTranslation } from 'react-i18next';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { ResponsiveScreen } from '@hooks/useMedia';
import { IconUtils } from '@utils/IconUtils';
import InviteStudentToClass from '@components/form/InviteStudentForm';
import React from 'react';
import { ClassEditForm } from '@modules/users/component/classes/ClassEditForm';

export const ManageClassModal = (props: {
  action: ManageClassActionEnum;
  classId: number;
  onChangeTab: (action: ManageClassActionEnum) => void;
  successHandle: () => void;
  onCloseFunc: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ResponsiveTabs
      screens={[ResponsiveScreen.xs, ResponsiveScreen.sm]}
      activeKey={props.action}
      onChange={(act) => props.onChangeTab(act as ManageClassActionEnum)}
      items={[
        {
          key: ManageClassActionEnum.update_metadata,
          label: t('class.info'),
          icon: IconUtils.actions.edit_metadata,
          children: (
            <ClassEditForm
              classId={props.classId}
              successHandle={props.successHandle}
              onCloseFunc={props.onCloseFunc}
            />
          ),
        },
        {
          key: ManageClassActionEnum.update_student,
          label: t('class.actions.add_student'),
          icon: IconUtils.actions.users,
          children: (
            <InviteStudentToClass
              classId={props.classId}
              successHandle={props.successHandle}
            />
          ),
        },
        // {
        //   key: ManageClassActionEnum.assign_teacher,
        //   label: t('class.actions.assign_teacher'),
        //   icon: IconUtils.actions.assign,
        //   visible: ifAnyGranted([RoleEnum.SUPPORT, RoleEnum.SCHOOL]),
        //   children: <div>.....</div>,
        // },
      ]}
    />
  );
};
