export enum DragItemTypeEnum {
  COMPONENT_ITEM = 'COMPONENT_ITEM',
  RESOURCE_ITEM = 'RESOURCE_ITEM',
  LESSON_ITEM = 'LESSON_ITEM',

  MATCHING_ITEM = 'MATCHING_ITEM',
  DRAG_N_DROP_ITEM = 'DRAG_N_DROP_ITEM',
  DRAG_N_DROP_ITEM_INLINE = 'DRAG_N_DROP_ITEM_INLINE',
  COUNTER_ITEM = 'COUNTER_ITEM',
}
