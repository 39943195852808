import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export const PermissionCheckBox = (props: {
  value: RoleEnum;
  selectValues: RoleEnum[];
  onChange: (value: RoleEnum, isChecked: boolean) => void;
}) => {
  const activated = useMemo(() => {
    return props.selectValues.includes(props.value);
  }, [props.selectValues.join(','), props.value]);

  return (
    <PermissionCheckBoxItem
      activated={activated}
      onClick={() => props.onChange(props.value, !activated)}
    />
  );
};

export const PermissionCheckBoxItem = (props: {
  activated: boolean;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <PermissionCheckBoxStyle
      className={`permission-checkbox' ${props.activated ? 'activated' : ''}`}
      onClick={props.onClick}
    >
      {props.activated ? t('label.activated') : t('label.inactivated')}
    </PermissionCheckBoxStyle>
  );
};

const PermissionCheckBoxStyle = styled.span`
  display: inline-flex;
  padding: 0.25em 1em;
  border-radius: 1em;
  cursor: pointer;
  background: ${(props) => props.theme.app.secondary};
  color: #fff;
  font-size: 85%;
  text-transform: uppercase;

  &.activated {
    background: ${(props) => props.theme.app.primary};
    color: #fff;
  }
`;
