import { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  BarGraphProps,
  BarGraphReportProp,
} from '@modules/reports/charts/bargraph/BarGraphChart';

const BarGraphGroupChart = (props: BarGraphProps<any>) => {
  const [data] = useState<BarGraphReportProp[]>(() => {
    return props.content.map((it: any, index: number) => {
      return props.render(it, index);
    });
  });

  const [tickValues] = useState<number[]>(() => {
    if (props.yAxisValues) {
      return props.yAxisValues;
    } else {
      return [0, 25, 50, 75, 100];
    }
  });

  return (
    <div
      className={props.className}
      style={{ width: '100%', height: props.size }}
    >
      <ResponsiveBar
        data={data}
        keys={props.keys}
        indexBy={'group'}
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        colors={props.getColor}
        groupMode={'grouped'}
        // indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'nivo' }}
        // defs={[
        //   {
        //     id: 'dots',
        //     type: 'patternDots',
        //     background: 'inherit',
        //     color: '#38bcb2',
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        //   },
        //   {
        //     id: 'lines',
        //     type: 'patternLines',
        //     background: 'inherit',
        //     color: '#eed312',
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        //   },
        // ]}
        // fill={[
        //   {
        //     match: {
        //       id: 'fries',
        //     },
        //     id: 'dots',
        //   },
        //   {
        //     match: {
        //       id: 'sandwich',
        //     },
        //     id: 'lines',
        //   },
        // ]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 8,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.xAxisTitle,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 8,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.yAxisTitle,
          legendPosition: 'middle',
          legendOffset: -40,
          tickValues: tickValues,
        }}
        labelSkipWidth={12}
        labelSkipHeight={4}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        gridYValues={tickValues}
        // legends={[
        //   {
        //     dataFrom: 'keys',
        //     anchor: 'bottom-right',
        //     direction: 'column',
        //     justify: false,
        //     translateX: 120,
        //     translateY: 0,
        //     itemsSpacing: 2,
        //     itemWidth: 100,
        //     itemHeight: 20,
        //     itemDirection: 'left-to-right',
        //     itemOpacity: 0.85,
        //     symbolSize: 20,
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemOpacity: 1
        //         }
        //       }
        //     ]
        //   }
        // ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return props.labelFormat(e);
          // return (
          //   e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
          // );
        }}
      />
    </div>
  );
};

export default BarGraphGroupChart;
