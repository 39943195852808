import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import LogoutPage from '@modules/authorization/pages/LogoutPage';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const Logout = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('login.title')}
        description={t('login.title')}
      />

      <LogoutPage />
    </PublicLayout>
  );
};

export default Logout;
