import React from 'react';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ComposeTocProvider } from '@modules/product/components/toc/ComposeTocProvider';
import TableOfContentPage from '@modules/product/pages/TableOfContentPage';

const LtiProductTocPage = (props: { productId: number }) => {
  return (
    <div className={'block-container'}>
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.TEACHER, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <ComposeTocProvider productId={props.productId}>
          <TableOfContentPage />
        </ComposeTocProvider>
      </Authorization>
    </div>
  );
};

export default LtiProductTocPage;
