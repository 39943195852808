import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import LicenseProducts from '@modules/product/container/student/LicenseProducts';

const MyLibraryProduct = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-products-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={NavigationMenuEnum.LIBRARY_PRODUCTS}
        title={t('library.header')}
        description={t('library.header')}
      />

      <LicenseProducts />
    </ManagementLayout>
  );
};

export default MyLibraryProduct;
