import React from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { Col, Row } from 'antd';
import UserProfileForm from '../container/my-profile/PublicStudentProfileForm';
import { Default_Gutter } from '@components/grid';

const PublicProfileStudentPage = () => {
  const { t } = useTranslation();

  const handleOnSuccess = () => {
    alert('success');
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('profile.public_profile')}</H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={20}>
          <UserProfileForm onSuccess={handleOnSuccess} />
        </Col>
      </Row>
    </>
  );
};

export default PublicProfileStudentPage;
