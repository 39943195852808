import {
  MathOperationEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import Button from '@components/button';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { SyncOutlined } from '@ant-design/icons';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { LongEditableStyle } from '../addition/LongAdditionEditable';
import Input from 'antd/lib/input/Input';
import { AreaOperationTableEditable } from '../table/AreaOperationTableEditable';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { useTranslation } from 'react-i18next';

export const AreaMultiplicationEditable = (props: {
  multiplier: string;
  multiplicand: string;
  removeZero: boolean;
  expression: OperationExpProps[];
  onChange: (
    multiplier: string,
    multiplicand: string,
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => void;
}) => {
  const { t } = useTranslation();

  const [multiplier, setMultiplier] = useState(props.multiplier);
  const [multiplicand, setMultiplicand] = useState(props.multiplicand);
  const [expression, setExpression] = useState<OperationExpProps[]>([]);
  const [answerExpression, setAnswerExpression] = useState<OperationExpProps[]>(
    []
  );

  useEffect(() => {
    setExpression([...props.expression]);
  }, [props.expression]);

  useEffect(() => {
    const answer = OperationUtils.parseAreaMultiplication(
      props.multiplier,
      props.multiplicand
    );
    setMultiplier(props.multiplier);
    setMultiplicand(props.multiplicand);
    setAnswerExpression(answer.results);
  }, [props.multiplier, props.multiplicand]);

  const handleOnClick = () => {
    const answer = OperationUtils.parseAreaMultiplication(
      multiplier,
      multiplicand
    );

    setExpression(answer.results);
    setAnswerExpression(answer.results);
  };

  const handleOnExpressionChange = (
    index: number,
    expression: OperationExpProps
  ) => {
    setExpression((prev) => {
      return COMPONENT_UTILS.updateAtIndex(prev, index, expression);
    });
  };

  const handleOnChange = (type: 'multiplier' | 'multiplicand', val: string) => {
    if (type === 'multiplicand') {
      setMultiplicand(val);
    } else {
      setMultiplier(val);
    }
  };

  const onUpdateEditableData = () => {
    props.onChange(multiplier, multiplicand, expression, answerExpression);
  };

  return (
    <>
      <LongEditableStyle className={'long-multiplication-editable'}>
        <Row>
          <Col flex={'200px'}>
            <InputGroup
              label={t('component.operation.multiplier')}
              key={'multiplier '}
            >
              <Input
                name="multiplier"
                type="number"
                onChange={(val) =>
                  handleOnChange('multiplier', val.target.value)
                }
                value={multiplier}
              />
            </InputGroup>

            <InputGroup
              label={t('component.operation.multiplicand')}
              key={'multiplicand '}
            >
              <Input
                name="multiplicand"
                type="number"
                onChange={(val) =>
                  handleOnChange('multiplicand', val.target.value)
                }
                value={multiplicand}
              />
            </InputGroup>
          </Col>

          <Col flex={'100px'}>
            <div className={'button-center-group'}>
              <Button type={'primary'} shape={'circle'} onClick={handleOnClick}>
                <SyncOutlined />
              </Button>
            </div>
          </Col>

          <Col flex={'auto'}>
            <AreaOperationTableEditable
              operation={MathOperationEnum.area_multiplication}
              expression={expression}
              answerExpression={answerExpression}
              onChange={handleOnExpressionChange}
            />
          </Col>
        </Row>
      </LongEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={onUpdateEditableData}
      />
    </>
  );
};
