import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { DragNDropCompProps } from '@cms/comps/interact/drag-n-drop/DragNDropComp';

export const DragNDropExampleData: DragNDropCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.DRAG_N_DROP,

  configuration: {
    sourceItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
      },
      {
        label: '2',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
      },
      {
        label: '3',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
      },
    ],
    targetItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Tao',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Chuoi',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Cam',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'horizontal',
    multipleDrag: false,
    multipleDrop: true,
  },
};

export const DragNDropVerticalExampleData: DragNDropCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.DRAG_N_DROP,

  configuration: {
    sourceItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
      },
      {
        label: '2',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
      },
      {
        label: '3',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
      },
    ],
    targetItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Tao',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Chuoi',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Cam',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'vertical',
    multipleDrag: false,
    multipleDrop: true,
  },
};
