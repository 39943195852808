import React, { useEffect, useState } from 'react';

import { Button, Col, notification, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  CreateEmailTemplateCommand,
  EmailTemplateAPI,
  EmailTemplateRes,
} from '@services/setting/EmailTemplateAPI';
import { InputGroup } from '@components/input/InputGroup';
import ContentEditor from '@components/editor/WysyEditor';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';

const initialValue: CreateEmailTemplateCommand = {
  code: '',
  subject: '',
  description: '',
  content: '',
};

export function EditEmailTemplateForm(props: {
  emailTemplateId?: number;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess, isFetching }] =
    EmailTemplateAPI.endpoints.findById.useLazyQuery();

  const [create, { isLoading: isCreateLoading }] =
    EmailTemplateAPI.endpoints.create.useMutation({});

  const [update, { isLoading: isUpdateLoading }] =
    EmailTemplateAPI.endpoints.update.useMutation({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.emailTemplateId != null && props.emailTemplateId > 0) {
      findById(props.emailTemplateId);
    } else {
      setIsReady(true);
    }
  }, [props.emailTemplateId]);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setIsReady(true);
    }
  }, [isSuccess]);

  const setFormData = (data: EmailTemplateRes) => {
    formik.setValues({
      code: data.code,
      subject: data.subject,
      description: data.description,
      content: data.content,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      code: yup.string().required(t('validation.required.field')),
      subject: yup.string().required(t('validation.required.field')),
      content: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.emailTemplateId != null && props.emailTemplateId > 0) {
        update({ ...values, emailTemplateId: props.emailTemplateId })
          .unwrap()
          .then(() => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.update_success_message'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotUpdate'),
              placement: 'bottomRight',
            });
          });
      } else {
        create(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.createSuccessful'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })

          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotCreate'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <Input
                required={true}
                name="code"
                type="text"
                label={t('label.code')}
                placeholder={t('label.code')}
                onChange={formik.handleChange}
                value={formik.values.code}
                disabled={
                  props.emailTemplateId != null && props.emailTemplateId > 0
                }
              />

              <Input
                required={true}
                name="subject"
                type="text"
                label={t('setting.email_template.subject')}
                placeholder={t('setting.email_template.subject')}
                onChange={formik.handleChange}
                value={formik.values.subject}
              />

              <Input
                name="description"
                type="textarea"
                label={t('label.description')}
                placeholder={t('label.description')}
                onChange={formik.handleChange}
                value={formik.values.description}
                maxLength={512}
                row={4}
              />

              <InputGroup label={t('label.content')} required={true}>
                <ContentEditor
                  initValue={data ? data.content : ''}
                  onChange={(value: string) =>
                    formik.setFieldValue('content', value)
                  }
                />
              </InputGroup>

              <ButtonGroup type={'right'} className="submit-container">
                <Button
                  type="default"
                  size={'large'}
                  shape={'round'}
                  onClick={props.onCloseFunc}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  type={'primary'}
                  size={'large'}
                  shape={'round'}
                  loading={isCreateLoading || isUpdateLoading || isFetching}
                  onClick={formik.submitForm}
                  icon={IconUtils.actions.save}
                >
                  {props.emailTemplateId != null && props.emailTemplateId > 0
                    ? t('button.update')
                    : t('button.create')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}
