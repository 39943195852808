import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { Button, Divider, notification } from 'antd';
import {
  DeleteOutlined,
  FormOutlined,
  GlobalOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import TablePagination from '@components/table/TablePagination';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { LanguageAPI, LanguageRes } from '@services/setting/LanguageAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { EntityStatus } from '@components/status/EntityStatus';
import { EntityStatusEnum } from '@services/model/common';
import { LanguageEditForm } from '../../container/language/LanguageEditForm';
import { LanguageEnum, LanguageIcon } from '@components/language/LanguageIcon';

export enum LanguageActionEnum {
  default = 'default',
  add = 'add',
  edit = 'edit',
}

export const LanguageManagementPage = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [findAll, { data, isFetching }] =
    LanguageAPI.endpoints.findAll.useLazyQuery();

  const [deleteItem] = LanguageAPI.endpoints.delete.useMutation({});
  const [publishItem] = LanguageAPI.endpoints.publish.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'displayOrder',
    direction: 'ascend',
  });

  const [action, setAction] = useState({
    action: LanguageActionEnum.default,
    params: -1,
  });

  useEffect(() => {
    findAll(params);
  }, [params]);

  const refreshData = (params: any) => {
    setParams(params);
  };

  const handleOnRemove = (languageId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('language.warning.delete'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteItem(languageId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('language.warning.delete_success'),
                description: t('language.warning.delete_success_message'),
                placement: 'bottomRight',
              });
              handleOnSuccess();
            })
            .catch(() => {
              notification.error({
                message: t('language.warning.delete_error'),
                description: t('language.warning.delete_error_message'),
                placement: 'bottomRight',
              });
              handleOnCancel();
            });
        }
      }
    );
  };

  const handleOnPublish = (languageId: number, status: EntityStatusEnum) => {
    confirm(
      'warning',
      t('header.confirm'),
      status === EntityStatusEnum.PUBLISHED
        ? t('language.warning.unpublish')
        : t('language.warning.publish'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          publishItem(languageId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('language.warning.publish_success'),
                description: t('language.warning.publish_success_message'),
                placement: 'bottomRight',
              });
              handleOnSuccess();
            })
            .catch(() => {
              notification.error({
                message: t('language.warning.publish_error'),
                description: t('language.warning.publish_error_message'),
                placement: 'bottomRight',
              });
              handleOnCancel();
            });
        }
      }
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      width: '50px',
      sorter: true,
    },
    {
      title: t('language.label.code'),
      dataIndex: 'code',
      key: 'code',
      width: '200px',
      sorter: true,
    },
    {
      title: t('language.label.name'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: true,
    },
    {
      title: t('language.label.status'),
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  const processDataRow = (record: LanguageRes) => {
    return {
      key: record.languageId + '_' + record.status,
      code: (
        <LanguageIcon
          language={record.code as LanguageEnum}
          height={20}
          showLabel
        />
      ),
      name: record.name,
      displayOrder: record.displayOrder,
      status: <EntityStatus status={record.status} />,
      actions: (
        <ButtonGroup>
          <Button
            type={'text'}
            title={t('label.edit')}
            onClick={() => handleOnEdit(record.languageId)}
          >
            <FormOutlined />
          </Button>

          <Button
            type={'text'}
            title={t('label.remove')}
            danger
            onClick={() => handleOnRemove(record.languageId)}
          >
            <DeleteOutlined />
          </Button>

          <Divider type={'vertical'} />

          <Button
            type={'text'}
            title={t('label.publish')}
            onClick={() => handleOnPublish(record.languageId, record.status)}
          >
            <GlobalOutlined />
          </Button>
        </ButtonGroup>
      ),
    };
  };

  const handleOnEdit = (languageId: number) => {
    setAction({
      action: LanguageActionEnum.edit,
      params: languageId,
    });
  };

  const handleOnSuccess = () => {
    findAll(params);
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setAction({
      action: LanguageActionEnum.default,
      params: -1,
    });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('language.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() =>
              setAction({
                action: LanguageActionEnum.add,
                params: -1,
              })
            }
          />
        </H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
      />

      {action.action === LanguageActionEnum.add && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={<>{t('language.actions.add')}</>}
          content={
            <LanguageEditForm
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === LanguageActionEnum.edit && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={<>{t('language.actions.edit')}</>}
          content={
            <LanguageEditForm
              languageId={action.params}
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};
