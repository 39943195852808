import React, { ReactNode, useState } from 'react';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { CloseOutlined, HighlightOutlined } from '@ant-design/icons';
import {
  ClassRoomInteractAction,
  ClassRoomWorkspaceEnum,
} from '@classroom/context/actions';
import { useSelector } from '@app/redux/hook';
import {
  ClassRoomHost,
  ClassRoomParticipant,
} from '@classroom/context/ClassRoomMode';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ShowRaiseHandModal } from '@classroom/components/ShowRaiseHandModal';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { Badge } from 'antd';

export const ClassRoomToolbar = () => {
  const { uuid } = useSelector((state) => state.profile);

  const [toolbarAction, setToolbarAction] = useState('');

  const {
    workspace,
    session,
    sectionId,
    exerciseId,
    enableAnnotation,
    onEnableAnnotation,
  } = useClassRoomContext();

  const {
    recording,
    openChatBox,
    action,
    raiseHandIds,
    presentingIds,
    onActionChange,
    onOpenChatBox,
    onAllowPresent,
    onRaiseHand,
  } = useClassRoomInteractContext();

  const onShowParticipants = () => {
    onActionChange(ClassRoomInteractAction.VIEW_PARTICIPANTS, '');
  };

  const onExitClassRoom = () => {
    onActionChange(ClassRoomInteractAction.QUIT, '');
  };

  const onShowSessionInfo = () => {
    onActionChange(ClassRoomInteractAction.SHOW_INFO, '');
  };

  const onPresent = () => {
    onActionChange(ClassRoomInteractAction.ALLOW_PRESENT, uuid);
  };

  const onStopPresent = () => {
    onActionChange(ClassRoomInteractAction.STOP_PRESENT, uuid);
  };

  const onOpenVideo = () => {
    if (session.meetingUrl != null && session.meetingUrl !== '') {
      window.open(session.meetingUrl);
    }
  };

  const onToggleAnnotation = () => {
    onEnableAnnotation(!enableAnnotation);
  };

  const onToggleChatting = () => {
    onOpenChatBox(!openChatBox);
  };

  const showRaiseHandModal = () => {
    setToolbarAction('show-raise-hand');
  };

  const onSelectRaiseHand = (raiseHand: boolean) => {
    onRaiseHand(!raiseHand, uuid);
  };

  const onAllowUserPresent = (allowPresent: boolean, _uuid: string) => {
    if (allowPresent) {
      onAllowPresent(true, _uuid);
      onRaiseHand(false, _uuid);
    } else {
      onAllowPresent(false, _uuid);
      onRaiseHand(false, _uuid);
    }
  };

  const handleOnCancel = () => {
    setToolbarAction('');
  };

  return (
    <>
      <ClassRoomToolbarStyle className={'class-room-toolbar'}>
        <div className={'toolbar-group left-group'}>
          <ClassRoomHost>
            <ClassRoomButton label={'Participants'}>
              <Button
                type={
                  action.action === ClassRoomInteractAction.VIEW_PARTICIPANTS
                    ? 'primary'
                    : 'default'
                }
                size={'large'}
                icon={IconUtils.class_room.participants}
                onClick={onShowParticipants}
              />
            </ClassRoomButton>
          </ClassRoomHost>

          <ClassRoomButton label={'Video'}>
            <Button
              size={'large'}
              icon={IconUtils.class_room.record}
              onClick={onOpenVideo}
              disabled={!session.meetingUrl}
            />
          </ClassRoomButton>

          <ClassRoomButton label={'Info'}>
            <Button
              size={'large'}
              icon={IconUtils.class_room.session_info}
              onClick={onShowSessionInfo}
            />
          </ClassRoomButton>
        </div>

        <div className={'toolbar-group center-group'}>
          <ClassRoomHost>
            {!recording &&
              workspace === ClassRoomWorkspaceEnum.presentation &&
              (sectionId > 0 || exerciseId > 0) && (
                <>
                  <ClassRoomButton label={'Present'}>
                    <Button
                      size={'large'}
                      icon={IconUtils.class_room.join_classroom}
                      onClick={onPresent}
                      type={'default'}
                    />
                  </ClassRoomButton>

                  <ClassRoomButton label={'Annotation'}>
                    <Button
                      type={enableAnnotation ? 'primary' : 'default'}
                      size={'large'}
                      icon={
                        enableAnnotation ? (
                          <CloseOutlined />
                        ) : (
                          <HighlightOutlined />
                        )
                      }
                      onClick={onToggleAnnotation}
                    />
                  </ClassRoomButton>
                </>
              )}
          </ClassRoomHost>

          {recording && workspace === ClassRoomWorkspaceEnum.presentation && (
            <>
              <ClassRoomButton label={'Stop'}>
                <Button
                  size={'large'}
                  danger={true}
                  icon={IconUtils.class_room.join_classroom}
                  onClick={onStopPresent}
                  type={'primary'}
                />
              </ClassRoomButton>

              <ClassRoomButton label={'Annotation'}>
                <Button
                  type={enableAnnotation ? 'primary' : 'default'}
                  size={'large'}
                  icon={
                    enableAnnotation ? <CloseOutlined /> : <HighlightOutlined />
                  }
                  onClick={onToggleAnnotation}
                />
              </ClassRoomButton>
            </>
          )}
        </div>

        <div className={'toolbar-group right-group'}>
          <ClassRoomButton label={'Chatting'}>
            <Button
              type={openChatBox ? 'primary' : 'default'}
              size={'large'}
              icon={IconUtils.class_room.chatting}
              onClick={onToggleChatting}
            />
          </ClassRoomButton>

          <ClassRoomHost>
            <ClassRoomButton label={'Raise Hand'}>
              <Badge size="default" count={raiseHandIds.length}>
                <Button
                  type={raiseHandIds.length > 0 ? 'primary' : 'default'}
                  size={'large'}
                  icon={IconUtils.class_room.raise_hand}
                  onClick={showRaiseHandModal}
                />
              </Badge>
            </ClassRoomButton>
          </ClassRoomHost>

          <ClassRoomParticipant>
            <ClassRoomButton label={'Raise Hand'}>
              <Button
                type={raiseHandIds.includes(uuid) ? 'primary' : 'default'}
                size={'large'}
                icon={IconUtils.class_room.raise_hand}
                onClick={() => onSelectRaiseHand(raiseHandIds.includes(uuid))}
              />
            </ClassRoomButton>
          </ClassRoomParticipant>

          <ClassRoomButton label={'End class'}>
            <Button
              type={'primary'}
              size={'large'}
              icon={IconUtils.class_room.end_class}
              onClick={onExitClassRoom}
              danger={true}
            />
          </ClassRoomButton>
        </div>
      </ClassRoomToolbarStyle>

      {toolbarAction === 'show-raise-hand' && (
        <CustomModal
          header={'User Requests'}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ShowRaiseHandModal
              sessionId={session.sessionId}
              raiseHandIds={raiseHandIds}
              presentingIds={presentingIds}
              onSelect={onAllowUserPresent}
              onClose={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

const ClassRoomButton = (props: { label: ReactNode; children: ReactNode }) => {
  return (
    <ClassRoomButtonStyle className={'class-room-btn-group'}>
      {props.children}
      <span className={'button-group-label'}>{props.label}</span>
    </ClassRoomButtonStyle>
  );
};

const ClassRoomButtonStyle = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .ant-btn {
    padding: 0.5em 0.25em !important;
    height: auto;
    min-width: 70px;

    .anticon {
      font-size: 24px;
    }
  }

  .ant-badge-count {
    font-size: 12px;
    line-height: 1em;
  }

  span.button-group-label {
    font-size: 10px;
    white-space: nowrap;
    margin-top: 0.5em;
  }
`;

const ClassRoomToolbarStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0.5em 2em;
  margin: 0.5em 1em;

  .toolbar-group {
    width: 33%;
    //background: rgba(255, 255, 255, 0.5);
    border-radius: 0.5em;
    display: inline-flex;
    gap: 1em;

    &.left-group {
      justify-content: flex-start;
    }

    &.center-group {
      justify-content: center;
    }

    &.right-group {
      justify-content: flex-end;
    }
  }
`;
