import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import { useEffect } from 'react';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompMode } from '@cms/ComponentInteface';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';

const useHandleExerciseAction = () => {
  const { t } = useTranslation();

  const { onSave, onSubmit, onGrading } = useExerciseServiceContext();
  const { type, action, dispatchExerciseAction } = useViewLessonContext();

  useEffect(() => {
    if (action.action === ExerciseActionEnum.request_save) {
      onSave(() => {
        if (action.params) {
          notification.success({
            message: t('exercise.warning.save_success'),
            description: t('exercise.warning.save_success_message'),
            placement: 'bottomRight',
          });
        }
      });
    } else if (action.action === ExerciseActionEnum.request_grading) {
      onGrading(() => {
        if (action.params) {
          notification.success({
            message: t('exercise.warning.save_success'),
            description: t('exercise.warning.save_success_message'),
            placement: 'bottomRight',
          });
        }
      });
    } else if (action.action === ExerciseActionEnum.request_exit_grading) {
      if (action.params) {
        onGrading(() => {
          dispatchExerciseAction(ExerciseActionEnum.exit, null);
        });
      } else {
        dispatchExerciseAction(ExerciseActionEnum.exit, null);
      }
    } else if (action.action === ExerciseActionEnum.request_review) {
      if (type === CompMode.DO_ASSIGNMENT || type === CompMode.DO_EXERCISE) {
        onSave(() => {
          dispatchExerciseAction(ExerciseActionEnum.review, null);
        });
      } else {
        dispatchExerciseAction(ExerciseActionEnum.review, null);
      }
    } else if (action.action === ExerciseActionEnum.request_submit) {
      onSave((data) => {
        handleBeforeSubmit(data!);
      });
    } else if (action.action === ExerciseActionEnum.complete_assignment) {
      onSubmit();
    } else if (action.action === ExerciseActionEnum.submit) {
      onSubmit();
    }
  }, [action, type]);

  const handleBeforeSubmit = (data?: ExerciseRes) => {
    if (data && data.progress && data.progress < 100) {
      dispatchExerciseAction(ExerciseActionEnum.review_n_confirm_submit, null);
    } else {
      onSubmit();
    }
  };
};

export default useHandleExerciseAction;
