import { useTranslation } from 'react-i18next';

import { useProUser } from '@components/button/RequireProUserButton';
import { useSecurity } from '@utils/authorization';

import React, { useMemo } from 'react';
import NavLink from '@components/button/NavLink';
import { IconUtils } from '@utils/IconUtils';
import { RoleEnum } from '@app/redux/slices/roles';
import {
  AppstoreAddOutlined,
  AuditOutlined,
  CloudServerOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HomeOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import i18next from 'i18next';
import { updateSelectMenu } from '@app/redux/slices/appSlice';
import styled from 'styled-components';
import { Menu } from 'antd';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useDispatch, useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';
import {
  getItem,
  getMenuItem,
  MenuItem,
} from '@components/template/common/MenuUtils';

const NavigationMenu = (props: { onClick?: () => void }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const proUser = useProUser();

  const { ifAnyGranted } = useSecurity();

  const { isAuth, isReady, authorities } = useSelector(
    (state) => state.profile
  );
  const { menu, submenu } = useSelector((state) => state.appState);

  const getUserMenu = (): MenuItem[] => {
    const menuItems: MenuItem[] | any[] = [];

    const my_dashboard = getMenuItem({
      key: NavigationMenuEnum.DASHBOARD,
      label: (
        <NavLink href={SiteMap.private.dashboard}>
          {t('menu.dashboard')}
        </NavLink>
      ),
      icon: IconUtils.menu.dashboard,
    });

    const my_calendar = getMenuItem({
      key: NavigationMenuEnum.CALENDAR,
      label: (
        <NavLink href={SiteMap.private.my_calendar}>
          {t('menu.my_calendar')}
        </NavLink>
      ),
      icon: IconUtils.menu.calendar,
    });

    const my_assignment = ifAnyGranted(SiteMap.assignments.roles)
      ? getMenuItem({
          key: NavigationMenuEnum.ASSIGNMENTS,
          label: (
            <NavLink href={SiteMap.assignments.list}>
              {t('menu.my_assignments')}
            </NavLink>
          ),
          icon: IconUtils.menu.assignment,
        })
      : getMenuItem({
          key: NavigationMenuEnum.ASSIGNMENTS,
          label: (
            <NavLink href={SiteMap.private.my_assignment.list}>
              {t('menu.my_assignments')}
            </NavLink>
          ),
          icon: IconUtils.menu.assignment,
        });

    const my_product = getMenuItem({
      key: NavigationMenuEnum.LIBRARY,
      label: (
        <NavLink href={SiteMap.private.my_library.products}>
          {t('menu.my_products')}
        </NavLink>
      ),
      icon: IconUtils.menu.products,
    });

    const my_collection = getMenuItem({
      key: NavigationMenuEnum.MY_LESSON,
      label: (
        <NavLink href={SiteMap.private.my_lesson.list}>
          {t('menu.my_lesson')}
        </NavLink>
      ),
      icon: IconUtils.menu.collection,
    });

    const my_class = getMenuItem({
      key: NavigationMenuEnum.MY_CLASS,
      label: (
        <NavLink href={SiteMap.private.my_class.list}>
          {t('menu.my_class')}
        </NavLink>
      ),
      icon: IconUtils.menu.class,
    });

    const my_students = getMenuItem({
      key: NavigationMenuEnum.USERS_STUDENTS,
      label: (
        <NavLink href={SiteMap.management.students.path}>
          {t('menu.students_users')}
        </NavLink>
      ),
      icon: IconUtils.menu.user,
      visible: ifAnyGranted(SiteMap.management.students.roles),
    });

    menuItems.push(my_dashboard);
    menuItems.push(my_calendar);
    menuItems.push(my_assignment);
    menuItems.push({
      type: 'divider', // Must have
    });
    menuItems.push(my_product);
    menuItems.push(my_collection);
    menuItems.push(my_class);
    menuItems.push(my_students);

    return menuItems;
  };

  const getAdminMenu = (): MenuItem[] => {
    const menuItems: MenuItem[] | any[] = [];

    const my_dashboard = getMenuItem({
      key: NavigationMenuEnum.DASHBOARD,
      label: (
        <NavLink href={SiteMap.private.dashboard}>
          {t('menu.dashboard')}
        </NavLink>
      ),
      icon: IconUtils.menu.dashboard,
    });

    const exampleMenu = getItem(
      t('menu.lesson_example'),
      NavigationMenuEnum.LESSON_EXAMPLE,
      <FileTextOutlined />,
      [
        getItem(
          <NavLink href={SiteMap.content.example.collection}>
            {t('menu.lesson_example_collection')}
          </NavLink>,
          NavigationMenuEnum.LESSON_EXAMPLE_COLLECTION,
          <FileTextOutlined />
        ),
        getItem(
          <NavLink href={SiteMap.content.example.lesson}>
            {t('menu.lesson_example_lesson')}
          </NavLink>,
          NavigationMenuEnum.LESSON_EXAMPLE_LESSON,
          <FileTextOutlined />
        ),
        getItem(
          <NavLink href={SiteMap.content.example.page}>
            {t('menu.lesson_example_page')}
          </NavLink>,
          NavigationMenuEnum.LESSON_EXAMPLE_PAGE,
          <FileTextOutlined />
        ),
      ]
    );

    const adminProductMenu = getItem(
      t('menu.my_resource'),
      NavigationMenuEnum.PRODUCTS,
      <ReadOutlined />,
      [
        getItem(
          <NavLink href={SiteMap.content.metadata.lesson_type}>
            {t('menu.lesson_type')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_LESSON_TYPE,
          <AuditOutlined />
        ),

        getItem(
          <NavLink href={SiteMap.content.metadata.lesson_template}>
            {t('menu.lesson_template')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_LESSON_TEMPLATE,
          <FileTextOutlined />
        ),

        {
          type: 'divider', // Must have
        },

        getItem(
          <NavLink href={SiteMap.content.resource.list}>
            {t('menu.my_resource')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_RESOURCE,
          <AppstoreAddOutlined />
        ),

        getItem(
          <NavLink href={SiteMap.content.resource.assets}>
            {t('menu.my_assets_files')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_MY_ASSETS,
          <CloudServerOutlined />
        ),
      ]
    );

    const oganizationMenu = getItem(
      t('menu.school'),
      NavigationMenuEnum.ORGANIZATION,
      <HomeOutlined />,
      [
        getItem(
          <NavLink href={SiteMap.management.school.list}>
            {t('menu.school')}
          </NavLink>,
          NavigationMenuEnum.ORGANIZATION_SCHOOL,
          <HomeOutlined />
        ),
      ]
    );

    const settingMenu = getItem(
      t('menu.setting'),
      NavigationMenuEnum.SETTING,
      IconUtils.menu.setting,
      [
        getItem(
          <NavLink href={SiteMap.management.setting.language}>
            {t('menu.language')}
          </NavLink>,
          NavigationMenuEnum.SETTING_LANGUAGE,
          <GlobalOutlined />
        ),

        {
          type: 'divider', // Must have
        },

        getItem(
          <NavLink href={SiteMap.management.setting.currency}>
            {t('menu.payment_currency')}
          </NavLink>,
          NavigationMenuEnum.SETTING_PAYMENT_CURRENCY,
          <DollarOutlined />
        ),
        getItem(
          <NavLink href={SiteMap.management.setting.payment_method}>
            {t('menu.payment_method')}
          </NavLink>,
          NavigationMenuEnum.SETTING_PAYMENT_METHOD,
          <CreditCardOutlined />
        ),
      ]
    );

    menuItems.push(my_dashboard);
    menuItems.push(exampleMenu);
    menuItems.push(adminProductMenu);
    menuItems.push(oganizationMenu);
    menuItems.push(settingMenu);

    return menuItems;
  };

  const getManageMenu = (): MenuItem[] => {
    const menuItems: MenuItem[] | any[] = [];

    const my_dashboard = getMenuItem({
      key: NavigationMenuEnum.DASHBOARD,
      label: (
        <NavLink href={SiteMap.private.dashboard}>
          {t('menu.dashboard')}
        </NavLink>
      ),
      icon: IconUtils.menu.dashboard,
    });

    const my_calendar = getMenuItem({
      key: NavigationMenuEnum.CALENDAR,
      label: (
        <NavLink href={SiteMap.private.my_calendar}>
          {t('menu.my_calendar')}
        </NavLink>
      ),
      icon: IconUtils.menu.calendar,
    });

    const assignment = getMenuItem({
      key: NavigationMenuEnum.ASSIGNMENTS,
      label: (
        <NavLink href={SiteMap.assignments.list}>
          {t('menu.my_assignments')}
        </NavLink>
      ),
      icon: IconUtils.menu.assignment,
    });

    if (ifAnyGranted([RoleEnum.SUPPORT])) {
      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(assignment);
      menuItems.push({
        type: 'divider', // Must have
      });
    } else if (ifAnyGranted([RoleEnum.SCHOOL])) {
      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(assignment);
      menuItems.push({
        type: 'divider', // Must have
      });
    }
    return menuItems;
  };

  const items: MenuItem[] = useMemo(() => {
    if (ifAnyGranted([RoleEnum.STUDENT, RoleEnum.PARENT, RoleEnum.TEACHER])) {
      return getUserMenu();
    } else if (ifAnyGranted([RoleEnum.ADMIN])) {
      return getAdminMenu();
    } else {
      return getManageMenu();
    }
  }, [authorities, i18next.language, proUser]);

  const handleOnChange = (params: any[]) => {
    dispatch(updateSelectMenu(params));
  };

  const handleOnSelect = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const selectedKeys = useMemo(() => {
    return [...menu, ...submenu];
  }, [menu, submenu]);

  if (isAuth && isReady) {
    return (
      <>
        <NavigationMenuStyle
          mode="inline"
          multiple={false}
          style={{ borderRight: 0 }}
          openKeys={menu}
          selectedKeys={selectedKeys}
          onSelect={handleOnSelect}
          onOpenChange={handleOnChange}
          items={items}
        />
      </>
    );
  } else {
    return null;
  }
};

export default NavigationMenu;

const NavigationMenuStyle = styled(Menu)`
  &.ant-menu {
    font-size: var(--default-menu-size, 16px);
    font-weight: 600;

    .ant-menu-item-icon {
      font-size: 20px;
    }

    .ant-menu-item {
      border-radius: 0.5em;
      height: auto;

      padding: 0.25em 16px !important;
      margin: 5px 0 !important;
      margin-right: 0 !important;
      width: 100% !important;

      > .ant-menu-title-content {
        font-size: var(--default-menu-size, 16px);
        font-weight: 600;
        line-height: 2.2;
      }

      &:hover,
      &:active {
        background: ${(props) => props.theme.menu.background};
        color: ${(props) => props.theme.menu.text};
      }

      &.ant-menu-item-active {
        background: ${(props) => props.theme.menu.background};
        color: ${(props) => props.theme.menu.text};
      }

      &.ant-menu-item-selected {
        background: ${(props) => props.theme.menu.active.background};
        color: ${(props) => props.theme.menu.active.text};

        &:after {
          border-right-color: ${(props) => props.theme.menu.active.text};
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        border-radius: 0.5em;
        height: auto;
        padding: 0.25em 16px !important;
        margin: 5px 0 5px 0 !important;
        width: 100% !important;

        .ant-menu-title-content {
          font-size: var(--default-menu-size, 16px);
          font-weight: 600;
          line-height: 2.2;
        }
      }

      .ant-menu {
        .ant-menu-item {
          .ant-menu-title-content {
            font-size: var(--default-submenu-size, 15px);
          }

          &:hover,
          &:active {
            background: ${(props) => props.theme.menu.active.background};
            color: ${(props) => props.theme.menu.active.text};
          }
        }
      }

      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
        }

        .ant-menu {
          background: ${(props) => props.theme.menu.active.submenu_bgr};
          border-radius: 0.75em;
          padding: 0 8px;

          .ant-menu-item {
            margin: 8px 0 8px 0 !important;
          }
        }
      }

      &.ant-menu-submenu-active {
        background: ${(props) => props.theme.menu.active.background};
        color: ${(props) => props.theme.menu.active.text};
      }
    }

    .ant-menu-item-divider {
      border-color: #cccccc;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`;
