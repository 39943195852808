import { FormikProvider } from 'formik';
import { Button } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import React from 'react';
import { FontSizeSetting } from '../setting/FontSizeSetting';
import { H4 } from '@components/typography';
import { SpaceSetting } from '../setting/SpaceSetting';
import { ColorSetting } from '@cms/design-template/setting/ColorSetting';
import { LessonTemplateLayoutEditable } from '@cms/design-template/layout/LessonTemplateLayoutEditable';
import Card, { CardClassEnum } from '@components/card';
import { useTranslation } from 'react-i18next';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import { LessonTemplateConfigProps } from '@cms/design-template/layout/props';

export const getDefaultTemplateConfig = (): LessonTemplateConfigProps => {
  return {
    singleMode: false,
    singleQuestionMode: false,
    stepByStep: false,
    layout: LessonTemplateLayoutEnum.responsive,
  };
};

export default function LessonTemplateContextSetting(props: {
  formik: any;
  onNext: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const handleOnChange = (
    content: string,
    type: 'header' | 'footer' | 'script' | 'stylesheet'
  ) => {
    if (type === 'header') {
      props.formik.setFieldValue('header', content);
    } else if (type === 'footer') {
      props.formik.setFieldValue('footer', content);
    } else if (type === 'stylesheet') {
      props.formik.setFieldValue('stylesheet', content);
    } else {
      props.formik.setFieldValue('script', content);
    }
  };

  return (
    <FormikProvider value={props.formik}>
      <Card className={CardClassEnum.rectangle_box}>
        <div>
          <H4>{t('lesson_template.label.font_size_setting')}</H4>
          <FontSizeSetting formik={props.formik} />
        </div>

        <div>
          <H4>{t('lesson_template.label.space_setting')}</H4>
          <SpaceSetting formik={props.formik} />
        </div>

        <div>
          <H4>{t('lesson_template.label.color_setting')}</H4>
          <ColorSetting formik={props.formik} />
        </div>

        <LessonTemplateLayoutEditable
          setting={props.formik.values}
          onChange={handleOnChange}
        />
      </Card>

      <ButtonGroup className="mt-5" type={'space-between'}>
        <Button
          type="default"
          size={'large'}
          shape={'round'}
          onClick={props.onCancel}
        >
          {t('lesson_template.label.back_to_config_step')}
        </Button>

        <Button
          type="primary"
          size={'large'}
          shape={'round'}
          onClick={props.onNext}
        >
          {t('lesson_template.label.go_to_review_step')}
        </Button>
      </ButtonGroup>
    </FormikProvider>
  );
}
