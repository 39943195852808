import styled from 'styled-components';

export const LinkSample = (props: { href: string }) => {
  return (
    <LinkSampleStyle href={props.href} target={'_blank'}>
      {props.href}
    </LinkSampleStyle>
  );
};

const LinkSampleStyle = styled.a`
  color: ${(props) => props.theme.app.primary};
  font-weight: 600;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
