import React from 'react';
import { Col, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '@modules/authorization/container/ResetPasswordForm';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export default function ResetPasswordPage(props: {
  uid: string;
  code: string;
}) {
  const { t } = useTranslation();

  const router = useRouter();

  const handleSuccess = () => {
    router.push(SiteMap.index);
  };

  return (
    <>
      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            <H1>{t('reset_password.header')}</H1>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <p>{t('reset_password.direction_1')}</p>

                <p>{t('reset_password.direction_2')}</p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <ResetPasswordForm
                  uid={props.uid}
                  code={props.code}
                  successHandle={handleSuccess}
                />
              </Col>
            </Row>
          </div>
        </div>
      </AuthorizeStyle>
    </>
  );
}
